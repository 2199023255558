
// import { Tile, Group } from "ol/layer";
// import { XYZ , OSM} from "ol/source";
// import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from './map-utils.js'
// import { transformExtent } from 'ol/proj';

import { Vector as VectorLayer,VectorImage } from "ol/layer";
import { Vector as VectorSource, Cluster} from "ol/source";
import { setInfractionStyle } from './map-styles.js';


/**
 * Create a layer on the map for each of the infraction types
 * @param {*} _name : name to reference the layer
 * @param {*} _stateRef : reference to the calling state object, used to check visibility flags
 * @param {*} _mapRef : reference to the map object, if supplied the layer will be added to the map
 * @param {*} _addLayerFn : callback function with the layers returned
 * @param {*} _options : additional options
 *      - style: specify the function to be called when the render is invoked on the layer
 *      - visible: true|false if the layer should be visible by default
 */
const createInfractionLayers=(_infractionList, _stateRef, _mapRef, _addLayerFn)=>{
    // console.log("Create layers: ",this.props);
    (_infractionList || []).forEach( type_ =>{
        // console.log("Type: ",type_, type_===this.state.selectedInfraction)
        createVectorLayer(type_,_mapRef,_addLayerFn,{style: setInfractionStyle,visible: _stateRef.bShowInfractions && type_===_stateRef.selectedInfraction});
    })//end iteration loop
    //Add an extra layer on top of all to show all infractions
    createVectorLayer('All',_mapRef,_addLayerFn,{style: setInfractionStyle,visible: _stateRef.bShowInfractions && 'All'===_stateRef.selectedInfraction});
}//end parseDataEntry


/**
 * Create a layer on the map for each of the alert types
 * @param {*} _name : name to reference the layer
 * @param {*} _stateRef : reference to the calling state object, used to check visibility flags
 * @param {*} _mapRef : reference to the map object, if supplied the layer will be added to the map
 * @param {*} _addLayerFn : callback function with the layers returned
 * @param {*} _options : additional options
 *      - style: specify the function to be called when the render is invoked on the layer
 *      - visible: true|false if the layer should be visible by default
 */
const createAlertLayers=(_list, _stateRef, _mapRef, _addLayerFn)=>{
    //Add layers for each of the Telematics layers
    (_list|| []).forEach( type_ =>{ 
        createVectorLayer(type_,_mapRef,_addLayerFn,{style: setInfractionStyle,visible: _stateRef.bShowInfractions && type_===_stateRef.selectedInfraction});
    })//end of loop
    
}//end parseDataEntry


/**
 * Create a layer for the map using the VectorImage Layer type 
 * @param {*} _name : name to reference the layer
 * @param {*} _mapRef : reference to the map object, if supplied the layer will be added to the map
 * @param {*} _addLayerFn : callback function with the layers returned
 * @param {*} _options : additional options
 *      - style: specify the function to be called when the render is invoked on the layer
 *      - visible: true|false if the layer should be visible by default
 */
const createVectorImageLayer=(_name, _mapRef, _addLayerFn, _options)=>{
   //Create a source for the features, features are loaded on the source and 
//    console.log("Create image layer with options:" ,_options)
    //rendered onto the Layer
    var layer_source = new VectorSource({});
    var cluster_source = null
    if(_options && _options.enableClustering){
        cluster_source = new Cluster({
                            distance: 50, 
                            minDistance: 20,
                            source: layer_source
                        })
    }

    

    layer_source.set('named',`${_name}-source`) //add a name to the source, only used in debugging layers
    //Define the layer to render the Features
    //Use a vectorImage instead of a VectorLayer (this should be faster than a VectorLayer, but at the cost of less accurate rendering)
    //  better to use on layers where animations or images are loaded
    var layer_vector = new VectorImage({ 
        source: cluster_source||layer_source, //define where the features are loaded from
        visible:true,  //set if the layer should be visible (default to true)
        displayInLayerSwitcher: false, //determine if the layer is part of the switcher interface control
        minZoom: 14,
    });    
    //Update Layer configuration based on options:
    //If the style is defined, then use this function to render each of the features
    if(_options && _options.style){layer_vector.setStyle(_options.style)}
    //If the visibile option is defined, update the visibile state of the layer
    if(_options && _options.visible){layer_vector.setVisible(_options.visible)}

    // if(_options && _options.maxZoom){layer_vector.setMaxZoom(_options.maxZoom) } 

    //Send the name, source, and layer to the callback (this adds them to arrays for easier access)
    _addLayerFn(_name,layer_vector,layer_source);
    //If the map was loaded, then load the layer into the map
    if(_mapRef){_mapRef.addLayer(layer_vector);}
}//end createVectorImageLayer


/**
 * Create a layer for the map using the Vector Layer type 
 * @param {*} _name : name to reference the layer
 * @param {*} _mapRef : reference to the map object, if supplied the layer will be added to the map
 * @param {*} _addLayerFn : callback function with the layers returned
 * @param {*} _options : additional options
 *      - style: specify the function to be called when the render is invoked on the layer
 *      - visible: true|false if the layer should be visible by default
 */
const createVectorLayer=(_name, _mapRef, _addLayerFn,_options)=>{
    //Create a source for the features, features are loaded on the source and 
    //rendered onto the Layer
    var layer_source = new VectorSource({});
    layer_source.set('named',`${_name}-source`) //add a name to the source, only used in debugging layers
    //Define the layer to render the Features
    var layer_vector = new VectorLayer({
        source: layer_source, //define where the features are loaded from
        visible:true,  //set if the layer should be visible (default to true)
        displayInLayerSwitcher: false, //determine if the layer is part of the switcher interface control
        minZoom: 14,
    });
    //Update Layer configuration based on options:
    //If the style is defined, then use this function to render each of the features
    if(_options && _options.style){layer_vector.setStyle(_options.style)}
    //If the visibile option is defined, update the visibile state of the layer
    if(_options && _options.visible){layer_vector.setVisible(_options.visible)}

    //Send the name, source, and layer to the callback (this adds them to arrays for easier access)
    _addLayerFn(_name,layer_vector,layer_source);
    //If the map was loaded, then load the layer into the map
    if(_mapRef){_mapRef.addLayer(layer_vector);}
    // console.log("Layers: ",_name)
}//end createVectorImageLayer

export {createInfractionLayers,createAlertLayers
            ,createVectorLayer, createVectorImageLayer}


