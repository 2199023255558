
import React, {  PureComponent } from 'react';
import { setupPerf, perfDidClick  } from '../Perf.js';
import { getNoteCardFromInfractionID,allowedGroups } from '../Util.js';

import * as moment from 'moment';
import './VideoReviewer.css';
import './VideoClipPlayer.css';

import IconArrowPrev  from '../assets/arrowbox_prev.png';
import IconArrowNext  from '../assets/arrowbox_next.png';
import IconReject     from '../assets/reject-icon.png';
import IconUndo       from '../assets/undo.png'
import IconROI from '../assets/pip.png'

import { VideoClipPlayerBase } from './VideoClipPlayerBase.js';
import {CanvasedVideo} from './CanvasedVideo.js'
/*
* @brief Handle the playback of a collection of clips, The notecards on the first tab displayed as a collection of clips
*  
* The parsing of the array indices, triggering the request to download, and playback state management are handled below
*/
const ENABLE_PLAYBACK_PROFILE = false;


// export class VideoClipPlayerCollection extends VideoClipPlayerBase {
//   constructor(props) {
//     super(props);
//   }
//   UNSAFE_componentWillMount(){this.didMount();}
//   componentWillUnmount(){this.didUnMount()}
//   render() {
//     return (<div></div>)
//   }
// }

export class VideoClipPlayerCollection extends VideoClipPlayerBase {
    constructor(props) {
        super(props);
        this.overlayButtonClick = this.overlayButtonClick.bind(this);
        this.roiButtonClick = this.roiButtonClick.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onReady = this.onReady.bind(this);
        this.autoPlayVideo = this.autoPlayVideo.bind(this);
        this.getPreviousTag = this.getPreviousTag.bind(this);
        this.setCurrentTag = this.setCurrentTag.bind(this);
        this.setNextTag = this.setNextTag.bind(this);
        this.setPrevTag = this.setPrevTag.bind(this);
        this.getNextTag = this.getNextTag.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.skipToClip = this.skipToClip.bind(this);
        this.skipNextClick = this.skipNextClick.bind(this);
        this.skipPrevClick = this.skipPrevClick.bind(this);
        this.ejectClick = this.ejectClick.bind(this);
        this.undoEjectClick = this.undoEjectClick.bind(this);
        this.findUnHiddenClip = this.findUnHiddenClip.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.shouldLoad = this.shouldLoad.bind(this);
        this.loadThrottle = this.loadThrottle.bind(this);
        

        this.state = {
            ...this.state, //load in the base class' variables
            loadCount: 0,
            loadedIDs:[],
            displayedInfraction:{},
            playerRef: null,            
            infractionTags:{},
            playlistOrder: 0,
            firstLoad: true,
            prevOrder: null,
            bookmarkLoad: this.props.bookmarkLoad,
            ejectmaskStream:[],
            ejectmaskID:[],
            ejectInfractionIDS:[],
            playtime:0,
            // players:null,
            lastEjectedIDs: null,
            timeSinceRefresh: new Date(),
            mLoadingWindowSize: 15,
            refreshCount:0,
            hideTimeout:null,
        };
        this.perfName = 'ExpandedCard';
        // Define a single references for the <video> used on infraction clips
        this.singlePlayerRef =  React.createRef(null);
    }

 
    
    /* @brief Called when new properties (input params) are passed to the class from the caller
    */
    UNSAFE_componentWillReceiveProps(newProps) {
      // console.log("New Props?: ",newProps);
      //Check if the new properties have a list of ejected infractionids that are due to action on other tabs:
      if(newProps.ejectedIDs !== this.state.lastEjectedIDs){
        const timeElapse = new Date() - this.state.timeSinceRefresh;
        if(timeElapse > 500){ //add a timer so this doesn't fire all the time
          // console.log("Got new ejectedIDS in the video player:", newProps.ejectedIDs)
          const ejectInfractionIDS = this.state.ejectInfractionIDS;
          //Check if these infractionids are ejected for this type of player (i.e. are they for the cellphone category)
          Object.keys(newProps.ejectedIDs).forEach(typeKey_ => {
            if(this.props.currentClip.chosenName === typeKey_){
              //Run through the ejected infractionids and check if we are already tracking this id, if not add it to the list
              (newProps.ejectedIDs[typeKey_].infractionIds || []).forEach(infractionID_ => {
                if(!ejectInfractionIDS.includes(infractionID_)){
                  ejectInfractionIDS.push(infractionID_);
                  // console.log("Adding: ",typeKey_,infractionID_,this.state);
                }
              })
            }//only do this if the types match
          });

          //Send the updated state request:
          this.setState({lastEjectedIDs:newProps.ejectedIDs,timeSinceRefresh: new Date(),ejectInfractionIDS:ejectInfractionIDS},
            ()=>{//Check if the current clip needs to be skipped:
              if(ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,this.state.playlistOrder))){
                if(this.isHidden(1)){this.skipNextClick();}
                else{this.skipPrevClick();}
              }
          });//end setState 
        }//end timer constraint
      }//end new props check
      
      //  if(newProps.bLoadAllowed !== this.props.bLoadAllowed){
      // //   const timeElapse = new Date() - this.state.timeSinceRefresh;
      // //   if(timeElapse > 500){ //add a timer so this doesn't fire all the time
      //     console.log("Change allowed load:",this.props.currentClip.chosenName)
      //     this.setState({timeSinceRefresh:new Date()})
      // //   }
      //  }
    }//end UNSAFE_componentWillReceiveProps

    componentDidUpdate(newProps){
      if(this.props.chosen !== newProps.chosen ){
        // console.log("Triggered chosen update", this.props.chosen,newProps.chosen)
        //check if the current asset is selected.
        if(this.props.chosen !== this.props.currentClip.chosenName){  //not selected
          // console.log("Turn off? ",this.props.name);
          this.setState(prevState => {            
          });
        }else{ //asset selected: autoplay:
          // console.log("Set autoplay ",this.props.chosen)
          this.setState(prevState => {
             return {mLoadingWindowSize:15};
          },
          //force the playback to be called without using event listeners on the video
            () => {   this.autoPlayVideo("didUpdate");   }
          );
          
        }
      }
    }
   
    /* @brief Called one time on load of the lcass, set the initial displayed infraction state.
    */
    componentDidMount() {
      
      window.addEventListener("optimizedResize", this.windowResized);
      
      this.setState({
        winSize : {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        }
      });

      // console.log("State: ",this.state, this.props);
      let displayedInfraction = {};
      if(this.state.infractionTags && this.state.infractionTags.current){
          displayedInfraction = this.state.infractionTags.current[this.props.currentClip.chosenName] || {};
      }
      //Use this playlist order if we are showing a set of clips:
      if(this.props.currentClip.type==='playlist'){          
        displayedInfraction = this.props.currentClip.set[this.state.playlistOrder];
        this.setCurrentTag(this.props.currentClip.set[this.state.playlistOrder]);
      }

      this.setState({displayedInfraction: displayedInfraction});

      this.didMount();

    }

    /* @brief Run once when the class is leaving
    */
    componentWillUnmount(){
      // console.log("Unmount Triggered in parent");
      this.didUnMount();
      window.removeEventListener("optimizedResize", this.windowResized);
      this.handleStop();
      if(this.props.currentClip.type==='playlist'  ){
        if(this.players){
          [...this.players].forEach(player=>{
            try {
              if(player && player.props && player.props.url){  URL.revokeObjectURL(player.props.url)      }    
            } catch (error) {
              console.log("Error in VideoClipPlayer release of playlist: ",error);  
            }
          })
        }        
      }else{
        try {
          if(this.players && this.players.props.url){       URL.revokeObjectURL(this.players.props.url)      }  
        } catch (error) {
          console.log("Error in VideoClipPlayer release of combined: ",error);
        }
      }
      this.players = null;
    }

    /* @brief Actions to apply to the player when the component unmounts
    */
    handleStop(){
      // console.log("Stop: ",this.props.currentClip.chosenName);
      // The Canvased player will take care of releasing the memory
      // try{
      //   const playerRef = this.singlePlayerRef;
      //   //Loop through all players, the infractions tab, can have multiple player references
      //   if(playerRef){
      //     // console.log("refs2: ",playerRef[key], this.props.currentClip.chosenName)
      //     let internalPlayer = playerRef.current;
      //     // console.log("player src: ",internalPlayer.src);
      //     internalPlayer.src =null;
      //     internalPlayer.load();
      //   } //end iterate over the entries in the object array
      // }catch(e){
      //   console.log("Didn't trigger stop on : ",this.state.chosenName)
      //   // console.log("Failed to trigger player stop? ",e);
      // }
      this.setState({playerRef: null});
    }

    /* @brief Notify the player that a new clips is available and needs to be updated
    */
    findUnHiddenClip(_data){
      try {
        if(this.props.currentClip.type !== 'playlist'){ return;}
          // console.log("Find unhidden for: ",this.props.currentClip.chosenName, this.state.playlistOrder, this.state.ejectmaskID);
          //Find the clip that isn't hidden?
          let newVal = this.state.playlistOrder;
          while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
          // while(this.state.ejectmaskID.includes(newVal)){
            newVal +=1;
          }
          //Check if this is the end of the set, if so look backwards
          if(this.getInfractionID(this.props.currentClip,newVal)===0){
            newVal -=1;
            while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
            // while(this.state.ejectmaskID.includes(newVal)){
              newVal -=1;
            }
          }
          //Set the clip number to the first that is visible: (dont autoplay the clip)
          this.skipToClip(newVal,this.state.playlistOrder,true);
      } catch (error) {
      }
    }

    /* @brief A helper function for the action that should be taken when the overlay button
    *        is clicked
    */
    overlayButtonClick(){
      // console.log("Button click: ",this.state.displayedInfraction.InfractionID)
      let disableButton = false;
      (this.props.filter.role || []).forEach(role=>{
        if(role==='SiteManager'){disableButton=true;}
      })
      if(disableButton){return;}
      let details = {
        chosenName : this.props.currentClip.chosenName,
        Tag : this.state.displayedInfraction.Tag,
        infractionID : this.state.displayedInfraction.InfractionID,
        type: this.props.currentClip.type,
      }

      this.onPause();
      
      this.props.overlayButtonClick(details);
     
    } //end overlay button click

      /*
    * @brief A helper function for the action that should be taken when the roi button
    *        is clicked
    */
   roiButtonClick(){
    try{
      console.log("ROICLICK: ",this.props,this.state);
      let details = {};
      if(this.props.currentClip.type ==='playlist'){
        // console.log("Videoid:",this.props.currentClip.set[this.state.playlistOrder] ,this.props.currentClip.set[this.state.playlistOrder].infractionID)
        details = {
          chosenName : this.props.currentClip.chosenName,
          videoid : this.props.currentClip.set[this.state.playlistOrder].InfractionID,
          timeoffset: this.state.playtime,      
        }
      }else{
        details = {
          chosenName : this.props.currentClip.chosenName,
          s3key : this.props.currentClip.s3key,
          timeoffset: this.state.playtime,      
        }
      }
      
      // console.log("To Send: ",details);
      this.onPause();    
      this.props.roiSelectClicked(details);
    }catch(e){console.log("Error on ROI click: ",e,this.props);}
    
   
  } //end overlay button click
  
  /* Receive callback when the video is ready to play*/
  onPlay(_data){

    try {
      if(ENABLE_PLAYBACK_PROFILE){
        if(this.props.chosen === this.props.currentClip.chosenName){
          window.performance.measure("StartPlayAfterSkip",this.props.currentClip.chosenName+'_PlaybackStart');
          // console.log(performance.getEntriesByType("measure"));
          console.log("Start playback: ",window.performance.getEntriesByName('StartPlayAfterSkip', 'measure')[0].duration);
          window.performance.clearMarks();
          window.performance.clearMeasures();
        }
      }    
    } catch (error) {
       console.log("Failed to get time?",error);
    }
    

    if(this.state.firstLoad){  return;}
    
  }
  /* Receive callback when the video playback is paused*/
  onPause(){
    
    if(this.props.currentClip.streamURL === 'playlist'){      
      let currentVal = this.state.playlistOrder;
      // console.log("OnPause called: ", currentVal,this.state.prevOrder)
      if(!this.state.prevOrder){ //needs to handle the case when skipping backwards, without the video won't autoplay
      }else{
        this.setState({prevOrder:null}) ;
      }        
    }
    else{
    }
  }
  /* Special call back to force the single player <video> tag to autoplay the video*/
  autoPlayVideo(_callSrc){
    // console.log("AutoPlay called by: ",_callSrc)
    // return;
    if(this.props.currentClip.streamURL === 'playlist'){  //limit to only the infraction clips, not the highlight videos
      // console.log("AutoPlay infraction?");
      if(this.props.chosen === this.props.currentClip.chosenName){ //not hidden      

        if(this.singlePlayerRef.current){
          // console.log("Calling on ", this.props.currentClip.chosenName, this.singlePlayerRef)
          try {
            // this.singlePlayerRef.current.load();
            var playPromise = this.singlePlayerRef.current.play();
            if (playPromise !== undefined) {
              playPromise.then(_ => {})
              .catch(error => {
                // Auto-play was prevented              
              });
            }  
          } catch (error) {}
          
        }else{
          console.log("No ref")
        }
        
      }else{}
    }
  }
  /* Receive callback when the video is loaded and ready to play*/
  onReady(data){
    // console.log("Ready: ",data,this.state.loadCount);
    if(this.state.firstLoad){
     window.setTimeout(() => { this.setState({firstLoad:false}) }, 100);
     if(this.props.currentClip.streamURL === 'playlist'){ 
      // window.setTimeout(() => { this.props.loadStarted(this.props.currentClip.chosenName); }, 3000); 
     }
    }
  }
  
  /* Helper method to return the previous tag from the metadata*/
  getPreviousTag(_metadata, _currentTag){
    if(!_metadata){return null;}
    if(_currentTag < 2){ return null;} //need to convert tag to 0 based index
    // this.setState(prevState => {tagsreviewed: prevState.tagsreviewed+1});
    return  this.props.currentClip.metadata[_currentTag-2];
  }
      
  /* Helper method to return the current tag from the metadata*/
  setCurrentTag(_currentTag){
    if(this._isMounted){
      this.props.markViewed(this.props.currentClip.chosenName,_currentTag);
    }
    
    if(this._isMounted){
      this.setState(
        prevState => {
          let infractionTags = prevState.infractionTags;
          if(!infractionTags.current){ infractionTags.current = []; }
          infractionTags.current[this.props.currentClip.chosenName]= _currentTag;
      
          let displayedInfraction = {};
          if(infractionTags && infractionTags.current){
              displayedInfraction = infractionTags.current[this.props.currentClip.chosenName] || {};
          }
          return{tagsreviewed: prevState.tagsreviewed+1, displayedInfraction: displayedInfraction, infractionTags: infractionTags}
        }              
      );  
    }
  }  
  setNextTag(_Tag,_name){
    this.state.infractionTags.next[_name] = _Tag;
  }  
  setPrevTag(_Tag,_name){
    this.state.infractionTags.prev[_name] = _Tag;
  }  
  /* Helper method to return the next tag from the metadata*/
  getNextTag(_metadata, _currentTag){
    if(!_metadata){return null;}
    if(_currentTag  > _metadata.length-1){ return null;}
    // console.log("Set next tag: ",_cu)
    // this.setState(prevState => {tagsreviewed: prevState.tagsreviewed+1});
    return  this.props.currentClip.metadata[_currentTag];
  }
  /* callback from video when onSeek is called*/
  onSeek(progress){
    // console.log("On seek called,",progress);
  }      

  /* @brief Handle skipping to the specified clip, used by both the skipNext/skipPrev
  */
  skipToClip(_number,_oldNumber, _dontPlay){
    if(this.props.currentClip.streamURL !== 'playlist' && !this.props.currentClip.set){return;}
    if(ENABLE_PLAYBACK_PROFILE){
      if(this.props.chosen === this.props.currentClip.chosenName){
        window.performance.mark(this.props.currentClip.chosenName+'_PlaybackStart');
      }
    }
    if(_number >= 0 && _number <this.props.currentClip.set.length){
      this.setCurrentTag(this.props.currentClip.set[_number]);
      
      // console.log("Trigger the update canvas")
          this.setState(prevState => {
            if(_dontPlay){
              return {playlistOrder:_number};
            }else{
               return {playlistOrder:_number};
            }
              
          },
          //force the playback to be called without using event listeners on the video
          () => {   setTimeout(this.autoPlayVideo("skip"),25);   }
          );
    }
    
  }

    

  /* @brief Handle the skip to next clip button press
  */
  skipNextClick(){
    try {
        if(this.props.currentClip.streamURL === 'playlist'){
          // console.log("Skip next received", this.state.ejectmaskID, this.state.ejectInfractionIDS)
          // console.log("Previous inf: ",this.getInfractionID(this.props.currentClip,this.state.playlistOrder))
          let currentVal =this.state.playlistOrder;

            let newVal =this.state.playlistOrder+1;
            // console.log("Check inf: ",this.getInfractionID(this.props.currentClip,newVal))
            while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
              // console.log("Had to skip: ",newVal);
              newVal +=1;
            }
            if(newVal <this.props.currentClip.count){
              // console.log("Nex inf: ",this.getInfractionID(this.props.currentClip,newVal))
              this.skipToClip(newVal,currentVal);
            }
        }
        else{
          // console.log("Skip :", this.state);
            const currentMeta = this.state.infractionTags.current[this.props.currentClip.chosenName];
            const currentNext = this.state.infractionTags.next[this.props.currentClip.chosenName];
            if(!currentNext){return;}
    
            // let currentIndex = parseInt(currentMeta.Tag,10);
            let nextIndex = parseInt(currentNext.Tag,10);
            //Update the current prev and next after the click
            this.setCurrentTag(currentNext);
            this.setNextTag(this.getNextTag(this.props.currentClip.metadata,nextIndex),this.props.currentClip.chosenName)
            this.setPrevTag(currentMeta,this.props.currentClip.chosenName)
    
            let skipTime = currentNext.TimeOffset;
            this.state.playerRef.seekTo(skipTime,'seconds');
            
            this.setState({skipClickTimer: moment(),playtime:skipTime});
        }
    } catch (error) { console.log("Skip next error: ",error)  }
  }
      /* @brief Handle the skip to previous clip button press
      */
  skipPrevClick(){
    try{
        if(this.props.currentClip.streamURL === 'playlist'){
          // console.log("SkipPrev Enter states: ",this.state);
            let newVal =this.state.playlistOrder-1;
            while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
            // while(this.state.ejectmaskID.includes(newVal)){
              // console.log("Had to skip: ",newVal);
              newVal -=1;
            }
            let currentVal = this.state.playlistOrder;
            //  console.log("PrevClick new,curr: ",newVal, currentVal);
            this.setState({prevOrder:currentVal});
            this.skipToClip(newVal,currentVal);
        }
        else{
            const currentMeta = this.state.infractionTags.current[this.props.currentClip.chosenName];
            const currentPrev = this.state.infractionTags.prev[this.props.currentClip.chosenName];
    
            // let currentIndex = parseInt(currentMeta.Tag,10);
            let prevIndex = parseInt(currentPrev.Tag,10);
            //Update the current prev and next after the click
            this.setCurrentTag(currentPrev);
            this.setPrevTag(this.getPreviousTag(this.props.currentClip.metadata,prevIndex),this.props.currentClip.chosenName);
            this.setNextTag(currentMeta,this.props.currentClip.chosenName);
    
            let skipTime = currentPrev.TimeOffset;
            this.state.playerRef.seekTo(skipTime,'seconds');
            
            this.setState({skipClickTimer: moment(),playtime:skipTime});
        }
      
    } catch (error) {   }
  }

    /* @brief Handle the eject button press, add current tag to list
    */
    ejectClick(){
      const ejectInfractionIDS = this.state.ejectInfractionIDS;
      // console.log("Eject Click: ",this.state)
        try{
          if(this.props.currentClip.type==='playlist'){
            //Need to remove the infraction from the notecard, and or delete the note card if there aren't more infractions
            const infractionID = this.props.currentClip.set[this.state.playlistOrder].InfractionID;
            const card = getNoteCardFromInfractionID(this.props.cardsByType[this.props.currentClip.chosenName],infractionID);

            let tmpName = this.props.currentClip.chosenName;
            if(this.props.currentClip.flag && this.props.currentClip.flag == 2){
              tmpName += "-DF";
            }
            
            let ejectMaskID = this.state.ejectmaskID;
            ejectMaskID.push(this.state.playlistOrder);

            //add the current infractionID to the list of IDS that have been ejected.
            if(infractionID){ejectInfractionIDS.push(infractionID)}
            

            try {
              if(!card.video && this.props.currentClip.type ==='playlist'){
                card.video = this.props.currentClip.set[this.state.playlistOrder].streamURL;
              }  
            } catch (error) {   }


            //Trigger the update to capture the current video's frame, then send the request through to be processed
              this.props.ejectClick({
                cardID: card.cardID,
                infractionTags: card.infractionTags.filter(tag_ => tag_!==this.props.currentClip.chosenName),
                infractionID:infractionID,
                infractionType: card.infractionType,
                toRemove: this.props.currentClip.chosenName,
                streamURL: card.video,
                tag: card.tag,
                hiddenIDs: ejectMaskID,
                ejectedIDs: ejectInfractionIDS,
                name: tmpName,
              })

              // console.log("Eject Hidden Check: ",this.isHidden(1));
              if(this.isHidden(1)){this.skipNextClick();}
              else{this.skipPrevClick();}
              // console.log("Eject stream push: ",card);
              const ejectMaskStream = this.state.ejectmaskStream;
              ejectMaskStream.push(card.video)
              this.setState({ejectmaskStream: ejectMaskStream,ejectmaskID:ejectMaskID,ejectInfractionIDS:ejectInfractionIDS});
            
            return;
          }
          // console.log("Enter eject clicK",this.state);
          let metaData = this.state.infractionTags.current[this.props.currentClip.chosenName];
          // console.log("MetaData",metaData);
          
          
          let bFound = false;
          try {
            const card = getNoteCardFromInfractionID(this.props.cardsByType[this.props.currentClip.chosenName],metaData.InfractionID);
            // console.log("Card: ",card);
            if(card){
              bFound = true;
              let tmpName = this.props.currentClip.chosenName;
              if(this.props.currentClip.flag && this.props.currentClip.flag == 2){
                tmpName += "-DF";
              }


              // console.log("Add to eject list: ",metaData.InfractionID)
              ejectInfractionIDS.push(metaData.InfractionID)
              // console.log("Ids: ",ejectInfractionIDS);
              this.setState({ejectInfractionIDS:ejectInfractionIDS});  

              
              if (window.confirm("Do you want to eject this clip? The associated notecard will be deleted.")) {

                //Trigger the update to capture the current video's frame, then send the request through to be processed
                  // console.log("Canvas updated-> process eject")
                  this.props.ejectClick({
                    metaData : metaData,
                    name : tmpName,
                    cardID: card.cardID,
                    // infractionTags: card.infractionTags.filter(tag_ => tag_!==this.props.currentClip.chosenName),
                    infractionTags: card.infractionTags,
                    infractionID:metaData.InfractionID,
                    infractionType: card.infractionType,
                    tag: card.tag,
                    toRemove: this.props.currentClip.chosenName,
                    delete : true,
                  })
  
                  
              }//end of confirmation
            }//end if card
          }catch (error) {
             console.log("Error on cards: ",error);
          }
          finally{
            
          }
          
          if(!bFound){
            let tmpName = this.props.currentClip.chosenName;
            if(this.props.currentClip.flag && this.props.currentClip.flag == 2){
              tmpName += "-DF";
            }
            this.props.ejectClick({
              metaData : metaData,
              name : tmpName,
            })
          }
        
          this.skipNextClick();
        } catch (error) { console.log("Error on eject click: ",error)   }
    } //end eject
    /* @brief Mark the clip as available, remove the ejection
    */
    undoEjectClick(){
      try{
        let tmpName = this.props.currentClip.chosenName;
        if(this.props.currentClip.flag && this.props.currentClip.flag == 2){
          tmpName += "-DF";
        }
        this.props.undoEjectClick({
          metaData : this.state.infractionTags.current[this.props.currentClip.chosenName],
          name : tmpName,
        })
      
      } catch (error) { console.log("Erorr on undo",error)  }
    } //end undo eject
    
    /* @brief helper method to find the infraction id in the current set of clips based on the index order
    */
    getInfractionID(_currentClip,_position){
      let infractionID = 0;
      try {
        infractionID = _currentClip.set[_position].InfractionID;
      } catch (error) {
      //  console.log("Failed to get infractionid: ",error); 
      }
      return infractionID;
    }

    /* @brief Check if the current playing clip has been marked as ejected
    */
    isHidden(_direction){
      if(_direction>0){
        let newVal =this.state.playlistOrder+1;
        while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
          // console.log("Had to skip: ",newVal);
          newVal +=1;
        }
        if(newVal <this.props.currentClip.count){
          return true;
        }
        return false;
      }else{
        let newVal =this.state.playlistOrder-1;
        while(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,newVal))){
          // console.log("Had to skip: ",newVal);
          newVal -=1;
        }
        if(newVal >=0){
          return true;
        }
        return false;
      }
    }


    /* Limit the number of clips based on a windows around the current index */
  shouldLoad(idx,_iCount){
    //count values before current index
    try {
      let iCount = 0;    
      let playlistLocation = this.state.playlistOrder;
      // console.log("Start:", playlistLocation)
      //Look back
      while(iCount < _iCount && playlistLocation >= 0){
        // console.log("Test: ",playlistLocation,idx,iCount)
        //Don't count the ejected clips:
        if(!this.state.ejectmaskID.includes(playlistLocation)){iCount++;}      
        playlistLocation--;
      }
      if( idx < playlistLocation && playlistLocation >= 0){
        // if(this.props.chosen === 'TK501'){console.log("No Show bw: ",playlistOrder,playlistLocation,idx)}
        return false;
      }
      //Look forward
      iCount =0;
      playlistLocation = this.state.playlistOrder;
      while(iCount < _iCount && playlistLocation < this.props.currentClip.set.length){
        // console.log("Test: ",playlistLocation,idx,iCount)
        //Don't count the ejected clips:
        if(!this.state.ejectmaskID.includes(playlistLocation)){iCount++;}              
        playlistLocation++;
      }
      if(idx > playlistLocation){
        //  if(this.props.chosen === 'TK501'){console.log("No Show fw: ",playlistOrder,playlistLocation,idx)}
        return false;}
      
      // if(this.props.name === 'TK501' ){console.log("Show: ",playlistOrder,playlistLocation,idx,this.props.currentClip.set.length)}
      return true;
      
    
    } catch (error) {
      console.log("Failed on load check")
      return true;
    }
    
  }

   /* Look at which clips have been loaded, try to limit the queueing of new clips to those closest to our current position */
   loadThrottle(_testIdx,_iTotalLimit){
    //count values before current index    
    try {
      //  console.log("Test : ",_testIdx);
      let forwardThresh = Math.round(_iTotalLimit *.75);
      let backwardThresh = Math.round(_iTotalLimit *.25);
      // console.log("Threshes: ",_testIdx,forwardThresh,backwardThresh)
      const currentIdx = this.state.playlistOrder;
      let forwardLimit = currentIdx;
      let backwardLimit = currentIdx;
      if(this.state.playerRef){return true;}
      //Scan forward through the clips:
      // (this.props.currentClip.set || []).forEach( (element,idx_) => {
      if(_testIdx >= currentIdx){
        for(var idx_=currentIdx; idx_<this.props.currentClip.set.length; idx_++){
          if(!this.state.playerRef){ //this is not loaded
            // console.log("Not loaded: ",idx_)
            forwardThresh--;
          }
          // console.log("Test on: ",idx_, forwardThresh);
          if(forwardThresh <0){
            forwardLimit = idx_;
            // console.log("Test on: ",_testIdx,forwardLimit,idx_,this.state.playerRef);
            break;  
          }
          forwardLimit = idx_;
          
        };
        
        if(_testIdx > forwardLimit){
          // console.log("limit: ",_testIdx);
          return false;
        }
        // console.log("Testin: ",_testIdx, forwardLimit);
      }else{
        for(var idx_=currentIdx; idx_>=0; idx_--){
          if(!this.state.playerRef){ //this is not loaded
            backwardThresh--;
          }
          // console.log("Test on: ",idx_, forwardThresh);
          if(backwardThresh <0){
            backwardLimit = idx_;
            break;
          }
          backwardLimit = idx_;
          // console.log("Test on: ",idx_);
        };
        // console.log("limit: ",forwardLimit);
        if(_testIdx < backwardLimit){
          return false;
        }
      }
      return true;
      
    
    } catch (error) {
      console.log("Failed on load check")
      return true;
    }
    
  }

      
    /*
    * @brief Render the content of the card:
    */
    render() {
      
      // console.log("Triggered player render", this.props.currentClip.chosenName,this.props.bLoadAllowed)
      if(this.props.bLoadAllowed === false){
          return (<div className={"video-reviewer-player" + " " + hiddenClass}
                    key={"player-" + this.props.currentClip.chosenName}>   </div>);
      }
      // if(this.props.currentClip.chosenName === 'Posture'){

      if(this.props.chosen === this.props.currentClip.chosenName){
        // console.log("State check: ",this.props, this.state);
      }
          
      // }
      
        const hidden = this.props.chosen !== this.props.currentClip.chosenName;
        const hiddenClass = hidden ? "hidden-video" : "";
        
        
       // choose if we should be hidden based on what the currently chosen video is
        // console.log("State: ",this.state);
        // console.log("Props: ",this.props);
        let displayedInfraction = this.state.displayedInfraction;
        
        

        //-----------------Create skip buttons-----------------------------------
        let prevStyle = {visibility:"hidden"};
        let nextStyle = {visibility:"hidden"};
        let ejectStyle = {visibility:"hidden"};
        let undoStyle = {visibility:"hidden"};      
        let roiSelectStyle = {visibility:"hidden"};      
        // if(this.props.currentClip.chosenName ==='Headphones'){
        //   console.log("Hidden? ",hidden,this.props);
        // }
        
        if(this.props.currentClip.chosenName==='original' ){
          if(this.props.username && (this.props.username.includes('_user') || this.props.username ==='testing_uploader')){
            roiSelectStyle.visibility = "visible"; 
          }
        }
        // 
        // console.log("Render: ",this.props);
        if(!hidden && this.props.currentClip.count > 0){
            if(this.props.currentClip.chosenName!=='original' ){
                if( this.props.currentClip.chosenName !== 'highlights'){
                

                if(this.props.currentClip.streamURL === 'playlist'){
                    //if(this.state.playlistOrder<this.props.currentClip.count-1 ){  nextStyle.visibility="visible"; }
                    //if(this.state.playlistOrder>0 ){  prevStyle.visibility="visible"; }
                    
                    //Check the current playing order
                    try {
                      // this.state.playlistOrder       
                      if(displayedInfraction){
                        //Check that the player order is matching the displayed infraction id
                        let matchIdx = (this.props.currentClip.set||[]).findIndex(elem_ => {return elem_.InfractionID === displayedInfraction.InfractionID});
                        if(matchIdx >=0){ //yes in the set, keep the old contact state
                          if(this.state.playlistOrder !== matchIdx){
                            // console.log("Playlist at: ",this.state.playlistOrder,matchIdx);
                            this.setState({playlistOrder:matchIdx});
                            return (<div/>);
                          }
                        }
                      }                                     
                    } catch (error) {
                      
                    }
                    if(this.isHidden(1)){  nextStyle.visibility="visible"; }
                    if(this.isHidden(-1)){  prevStyle.visibility="visible"; }
                }else{
                    if(this.state.infractionTags && this.state.infractionTags.next && this.state.infractionTags.next[this.props.currentClip.chosenName] ){  nextStyle.visibility="visible"; }
                    if(this.state.infractionTags && this.state.infractionTags.prev && this.state.infractionTags.prev[this.props.currentClip.chosenName] ){  prevStyle.visibility="visible"; }
                }

                //if(this.props.group ==="testinggroup" || this.props.group ==="drive_test_group" || this.props.group ==="reviewgroup"){
                if(allowedGroups.includes(this.props.groupconfig.group)){
                // if(this.props.group ==="testinggroup" ||  this.props.group ==="reviewgroup"){
                    ejectStyle.visibility="visible";  
                }//end group check
                }//end highlight
            }

            


        }
        //----------------------------------------------------------------------

        let reviewText =null;
        
    

        let overlayButton =null;
        //Make sure the displayedInfraction is set when a new clip is added
        if(!displayedInfraction && this.props.currentClip.count > 0 ){
          if(this.props.currentClip.type==='playlist'){          
            this.setCurrentTag(this.props.currentClip.set[this.state.playlistOrder]);
          }
        }
        
        // if(this.props.currentClip.chosenName === 'ejected'){console.log("Props:" ,this.props, displayInfraction)}
        if(this.props.currentClip.chosenName !== 'original' && this.props.currentClip.chosenName !== 'highlights' &&  displayedInfraction && displayedInfraction.Tag && this.props.currentClip.count > 0){
          //Add a condition to mark the overlay button when an card exists
          let bDisableButton =  false;
          let reviewedButton = false;
          //Check if the overlaybutton needs to be disabled because the clips has been ejected?
          if(this.props.ejectSets && this.props.ejectSets[this.props.ejectSetName] && this.props.ejectSets[this.props.ejectSetName].ejectList.includes(displayedInfraction)){
            bDisableButton = true;
            undoStyle.visibility = "visible"; 
          }

          let overlayText = <div><div>{displayedInfraction.Tag}</div></div>
          //Disable 24hr clips?
          if(this.props.currentClip.type==='playlist' && this.props.currentClip.set[this.state.playlistOrder]){          
            if(this.props.currentClip.set[this.state.playlistOrder].reviewstatus && this.props.currentClip.set[this.state.playlistOrder].reviewstatus==='reviewed'){
              bDisableButton = false;//Mark as red  
              reviewedButton = true;         
              ejectStyle.visibility="hidden";    
              // reviewText = <div className="vcp-reviewtext" >Reviewed by: {this.props.currentClip.set[this.state.playlistOrder].username}</div>;

              overlayText = <div className='overlaybutton-text'>
                              <div className='overlay-number'>{displayedInfraction.Tag}</div>
                              <div className="overlay-reviewer">{this.props.currentClip.set[this.state.playlistOrder].username}</div>
                            </div>


            }
          }
          
          
          // if(false && this.props.cardsByType && this.props.cardsByType[this.props.currentClip.chosenName] && displayedInfraction.InfractionID && this.props.cardsByType[this.props.currentClip.chosenName][displayedInfraction.InfractionID] ){
          if(this.props.cardsByType && this.props.cardsByType[this.props.currentClip.chosenName] && displayedInfraction.InfractionID && this.props.cardsByType[this.props.currentClip.chosenName][displayedInfraction.InfractionID] ){
            bDisableButton = false;  
            overlayButton =  <button className="overlay-button" disabled={bDisableButton} data-card="true" onClick={this.overlayButtonClick} > {overlayText} </button>;
              
          }
          else{
              if(reviewedButton){
                // console.log("Button: ",bDisableButton);
                overlayButton =  <button className="overlay-button" reviewed-clip="true" disabled={bDisableButton} onClick={this.overlayButtonClick}> {overlayText} </button>;
              }else{
                overlayButton =  <button className="overlay-button" disabled={bDisableButton} onClick={this.overlayButtonClick}> {overlayText} </button>;
              }
              
          }
          //Hide the overlay button if the video clip is marked for removal:
          // if(this.state.ejectmaskID.includes(this.state.playlistOrder)){
          //   overlayButton=null;
          //   ejectStyle.visibility="hidden"; 
          // }

          if(this.state.ejectInfractionIDS.includes(this.getInfractionID(this.state.playlistOrder))){
              overlayButton=null;
              ejectStyle.visibility="hidden"; 
          }
        }

          let playerConfig = {};
          let players = this.players;
          if(this.props.currentClip.streamURL === 'playlist'){
              // console.log("Props on clips: ",this.props);
              // console.log("Load? ",this.props.currentClip.chosenName,this.props.bLoadAllowed);            
              let URLToLoad= null;
              // let shouldPlay = false;
              let hiddenTrigger = null;
              // console.log("Enter filter: ",new Date())
              let set = this.props.currentClip.set;
              if(this.props.bMemoryOptimized === false){
                
                set = this.props.currentClip.set.filter( (elem_,idx) => {
                   if(this.props.interruptTimer){ //adding this timer check is pausing the background load?
                    // console.log("Time since: ",new Date() - this.props.interruptTimer)
                     if((new Date() - this.props.interruptTimer) < 1000)  {
                        // console.log(" Return: ",idx,this.state.loadedIDs.length,idx <this.state.loadedIDs.length, (idx -this.state.loadedIDs.length) < 10)
                        return  (idx -this.state.loadedIDs.length) < 4; //need to allow a buffer or else the background loading gets locked out
                        
                     }else{
                  //     console.log("Out of interrupt?")                    
                     }
                    
                  }
                  // console.log("Filter: ",elem_,idx,this.state.loadedIDs.length)
                  return (idx -this.state.loadedIDs.length) < 20;
                })
              }
              // console.log("Set: ",set);
              
              // players =this.props.currentClip.set.map( (elem_,idx) => {
               let test1 = set.map( (elem_,idx) => {
                // for (const [idx, elem_] of this.props.currentClip.set.entries()) {
                // this.props.currentClip.set.forEach( (elem_, idx)=> {


                    if(this.props.bMemoryOptimized === false){ //Memory available -> direct download

                      let loadedCount = this.state.loadedIDs.length;

                      // this.props.chosen === this.props.currentClip.chosenName
                      if(this.props.currentClip.chosenName === 'Drowsiness'){
                        // console.log("Load count: ",this.props.currentClip.chosenName, loadedCount, idx, this.props.loadCount);                          
                      }
                      
                      
                      
                      // if(this.props.interruptTimer){
                      //   if((new Date() - this.props.interruptTimer) < 1000)  {
                      //     console.log("Time since: ",new Date() - this.props.interruptTimer)
                      //     return ;           
                      //   }else{}
                        
                      // }else{}

                      
                      // if(this.props.currentClip.chosenName === 'Drowsiness'){
                      //   let queueDepth = idx - loadedCount;
                      //   if(queueDepth > 0){console.log("Queue depth: ",idx,loadedCount,queueDepth, this.state.loadCount)}
                      // }
                      
                      // if(idx - loadedCount >= 5){
                      //     // console.log("Load count: ",this.props.currentClip.chosenName, this.state.loadCount, idx, this.props.loadCount);                          
                      //     return;
                      // }

                      

                      if(!elem_.blob){
                        if(this.props.bLoadAllowed===false){
                          return (<div/>);
                        }
                        // console.log("Send Load callback? ",this.props.currentClip.chosenName,idx)
                        this.props.handleLoad({
                          InfractionID: elem_.InfractionID,
                          name: this.props.currentClip.chosenName,
                          idx:idx,
                        },true);
                      }
                      if(!elem_.blob || elem_.blob === 'pending'){  return (<div/>);}

                      
                    // let loadedCount = this.state.loadCount;  
                      // console.log("Loaded: ",this.props.currentClip.chosenName,idx,loadedCount,this.props.bMemoryOptimized)
                      if(this.props.currentClip.set.length === loadedCount){
                        this.props.loadComplete({
                          name: this.props.currentClip.chosenName,
                          type: this.props.currentClip.type,
                        })
                      }

                      // console.log("Load count: ",loadedCount,this.props.currentClip.set.length, this.props.currentClip.chosenName)
                      // console.log("Loaded? ",this.props.currentClip.set.length,this.state.loadCount,this.props.currentClip.chosenName)

                    }else{ //need to limit memory-> use the stream URLs and limit the number of URLs per asset
                      // Define a loading window to limit the number of players/videos held in memory
                      let loadingWindowSize = this.state.mLoadingWindowSize;
                      this.props.handleLoad(elem_,false);
                      // let loadingWindowSize = 40;
                      // if(this.props.chosen !== this.props.name){
                      //   loadingWindowSize = 5;
                      // }
                      // if(idx === this.state.playlistOrder){console.log("windowSize: ",loadingWindowSize,this.props.name)}
                      if(!this.shouldLoad(idx,loadingWindowSize)){
                        return (<div/>);
                      }
                      // console.log("Idx: ",idx);

                      let loadedCount = this.state.loadedIDs.length;
                    // let loadedCount = this.state.loadCount;  
                      // console.log("Finished? ",(this.state.playlistOrder + idx +1),idx, loadedCount,this.props.currentClip.set.length)
                      if((this.state.playlistOrder + idx +1) === loadedCount){
                          this.props.loadComplete({
                            name: this.props.currentClip.chosenName,
                            type: this.props.currentClip.type,
                          })  
                      }
                      
                    }//end optimized loading loop

                    // let URLToLoad = elem_.blob;
                    
                    //Set the URL to load:
                    if(!URLToLoad)
                    {
                      if(idx===this.state.playlistOrder){
                        if(elem_.blob){
                          URLToLoad = elem_.blob;
                        }else if(this.props.bMemoryOptimized){
                          URLToLoad = elem_.streamURL;
                        }
                        if(this.props.chosen === this.props.currentClip.chosenName && this.state.playlistOrder === idx){ 
                          // console.log("loaded blob:",JSON.stringify(URLToLoad),JSON.stringify(elem_.blob), this.state.playlistOrder, this.props.bMemoryOptimized);          
                        }
                        
                      }
                    }

                    //Are the videos loaded?
                    let infractionID = this.getInfractionID(this.props.currentClip,idx);
                    if(URLToLoad && !this.state.loadedIDs.includes(infractionID)){
                      // console.log("Update load? ",URLToLoad,this.props.currentClip.chosenName,this.state.loadedIDs);
                      this.setState(prevState => {       
                        const loaded = [...prevState.loadedIDs];
                        if(!loaded.includes(infractionID)){
                          loaded.push(infractionID);
                        }
                        return {loadedIDs:loaded, loadCount: prevState.loadCount+1};
                      });
                    }
                  
                  
                  //If this is a local blob file, then set this to force video:
                  if(elem_.streamURL.substring(0,4)==='blob'){ 
                    playerConfig={
                      file: {
                          attributes: { preload: 'auto' },
                          forceVideo: true,
                          forceDASH: true,
                      }
                    } 
                  }
                  if(hiddenTrigger !== 'hidden-video'){
                    if(this.props.filename.includes("LiveReview")){
                      // hiddenTrigger = 'react-playerLR'
                      hiddenTrigger = 'react-player'
                    }else{  hiddenTrigger = 'react-player'}

                    let data = {}
                    try{
                      data = {  meta: this.props.currentClip.set[this.state.playlistOrder],
                                chosenName: this.props.currentClip.chosenName
                              }
                    }catch(e){}
                    this.props.currentlyPlaying(data);
                    
                  }
                  let height = '425px'//'100%'
                  if(this.props.chosen === this.props.currentClip.chosenName && this.state.playlistOrder === idx){ 
                    //  console.log("Playing video: ",this.props.name,shouldPlay,this.state.playlistOrder,URLToLoad)
                    // console.log("Load order: ",this.props.name, this.props.currentClip)
                  }
                                       
              })//end map

              // const ref2 = player => {
              //   this.state.playerRef = player
              // }//end ref handle
              
              if(!hidden){
                // console.log("Send to Player: ",this.props.currentClip.chosenName,URLToLoad);
                // console.log("Players: ",this.props.currentClip.chosenName,this.props.currentClip.set, displayedInfraction.InfractionID)
                
              }

              //If we don't have anything to show yet, then don't render the video player
              if(!URLToLoad){return <div/>}
              
              //Configure a fixed size window for the video playback:
              let widthVal = 900;
              let heightVal = 530;
              try {
                // widthVal = Math.max(this.state.winSize.width *0.5,712) + "px";
                // heightVal = this.state.winSize.width *.565 + "px";
                widthVal = Math.max(this.state.winSize.width *0.5,712);
                heightVal = widthVal *.565;
              } catch (error) {
              }

              
              
              //Define the play to show the video
              players=  <CanvasedVideo className='fixed-size'
                          ref = {this.singlePlayerRef}
                          note = {this.props.currentClip.chosenName}
                          preload="auto"
                          controls
                          onCanPlay={this.onPlay}
                          src= {URLToLoad}
                        />
                  

          }

          
        if(!allowedGroups.includes(this.props.groupconfig.group)){
            ejectStyle.visibility="hidden";  
        }
        undoStyle.visibility="hidden";  
         
        let controlButtons =  <div className="playbuttonsVR">
                                <div className="blank-spacer"  />
                                <img  src={IconArrowPrev} className="skipPrev-button"  style={prevStyle} onClick={this.skipPrevClick}/> 
                                <img  src={IconROI} className="roi-button"  style={roiSelectStyle} onClick={this.roiButtonClick} /> 
                                <img  src={IconUndo} className="eject-undo-button"  style={undoStyle} onClick={this.undoEjectClick}  /> 
                                <img src={IconReject} className="reject-button" style={ejectStyle} onClick={this.ejectClick} /> 
                                <img src={IconArrowNext} className="skipNext-button" style={nextStyle}   onClick={this.skipNextClick}/>   
                                 <div className="blank-spacer"  />
                              </div>

        const res =(
        <div className={"video-reviewer-player" + " " + hiddenClass}
                    key={"player-" + this.props.currentClip.chosenName}>
            {players}
            {reviewText}
            {overlayButton}
            {controlButtons}
        </div>);
        return res;
    }
}//end class definition


    
 