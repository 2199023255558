import React, { useState} from 'react';
import {API} from 'aws-amplify';
import './RegisteredLogin.css';
import { getCookie } from '../Util-access';
import {Spinner } from '../ApiCaller.js';

/* 
 * Hook into the Auth event callback when the state changes, this is triggered when the Signin occurs
 * After the sign has been processed by AWS cognito, check if additional security checks are required.
 * The Cognito user has a custom attribute (custom:restricted). If this is set then the user may only log in from a 
 * registered computer
 * There are two types of restricted users:
 *  - restricted=*
 *  - restricted=register 
 * If the restricted attribute is set to register than the user is allowed to register a new PC to be used by restricted user accounts
 * @param {*} _state: Authorization state returned from AWS Auth()
 * @param {*} _cognitoUser: congito details from the user login
 * @param {*} _authCallback: callback to process the Authorization event in the main application
 * @param {*} _appCallback: callbcak to update states in the main application
 */
export function isRegistrationRequired(_state, _cognitoUser, _authCallback, _appCallback) {
  // console.log("Input: ",_state, _cognitoUser);
  if(_state !== 'signedIn'){_authCallback(_state, _cognitoUser);} //Don't process the log out notifications
  //Is this a restricted user: (restricted users are only allowed to log in from a registered pc)
  try {
    //check if the resticted flag has been set
    if(_cognitoUser.attributes && (_cognitoUser.attributes['custom:restricted'] && _cognitoUser.attributes['custom:restricted'].length>0)){
        //The restricted attribute is set, check what needs to be done for the user account
        checkRegistrationStatus(_state, _cognitoUser, _authCallback,_appCallback); 
    }//end check for restricted user
    else{ // restrictied attributes is not set so pass the callback and process normally
      _authCallback(_state, _cognitoUser);
    }
  } catch (error) { //always fallback to authentication with the password on failure
    _authCallback(_state, _cognitoUser);
  }
}//end isRegistrationRequired

/**
 * The user account requires the extra registration security check. Load the registered-token cookie value from the browswer
 * If this doesn't exist, then create one (if the user has the register value set)
 * The registration process will trigger a two-factor authenication using an email address, if this passes then the token is 
 * written to the cookie and the user is allowed to proceeed
 * @param {*} _state: Authorization state returned from AWS Auth()
 * @param {*} _cognitoUser: congito details from the user login
 * @param {*} _authCallback: callback to process the Authorization event in the main application
 * @param {*} _appCallback: callbcak to update states in the main application
 */
function checkRegistrationStatus(_state, _cognitoUser,_authCallback,_appCallback){
  //Read the cookies
  let registeredToken = getCookie('registered-token');
  // console.log("Checking registation: ", _cognitoUser, registeredToken);
  //Registration token is found
  if(registeredToken){ 
    console.log("Token found: ",registeredToken);
    //Validate the token against the API:
    let validatePromise = validateToken(registeredToken,_cognitoUser);
    validatePromise.then(_response=>{
      console.log("Validate returned: ",_response);
      _authCallback('signedIn', _cognitoUser);
      return;
    })
    validatePromise.catch(_error=>{
      console.log("Failed to validate: ",_error);      
      if(_cognitoUser.attributes['custom:restricted']==='register'){
        _authCallback('register', _cognitoUser);
      }else{
        _authCallback('blocked', _cognitoUser);
        _appCallback('blocked');
        logFailures('blocked',_cognitoUser.username);
      }
      
    })
  }
  //Registration token is not found, this browser/pc needs to be registered
  else{ 
    //Are the attributes set for the current user to allow registration?
    if(_cognitoUser.attributes['custom:restricted']==='register'){
      //Start the register process by passing the state as register back to the main app
      //This will be logged in a login_state variable and be used by Registeredlogin to display the new UI
      _authCallback('register', _cognitoUser);
    }
    //If not permitted, then return not registered (this will display a get manager message)
    else{
      _authCallback('notRegistered', _cognitoUser);
      logFailures('not_registered',_cognitoUser.username);
    }
  }
}
/** Log signin failures due to pc registation */
function logFailures(_event,_username){
      let apiName = "TrifectaCoreAPI";
      let path = "/handleLogin";
      try {
        let myInit = {
          body: {
            mode: 'log',
            event: _event,
            username: _username,
            token: getCookie('registered-token')
          }//end body declaration
        };
        console.log("post to API: ")
        return API.post(apiName, path, myInit);  
      } catch (error) {
        console.log("Failed to post: ",error);
      }
}//end logFailures

/**
 * Send the found token to the API and validate that it is authentic and active
 * @param {*} _token: security token read from the cookie
 * @param {*} _cognitoUser:congito details from the user login
 */
function validateToken(_token, _cognitoUser){
   // Query the API to authenticate against restrictions:
   return new Promise((resolve, reject) => {
      let apiName = "TrifectaCoreAPI";
      let path = "/handleSecureLogin";
      let myInit = {
          body: {              
            mode: 'signin',
            username: _cognitoUser.username,
            token: _token
          }
      };
      let apiPromise = API.post(apiName, path, myInit);
      apiPromise.then((data) => { 
        // console.log("API returned: ",data);
        //Did the API respond with accepted:
        if(data && data.state && data.state === 'accepted'){
          resolve('success'); //return success and enter Cloud Service
        }
        //Failed validation
        else{
          //Reject the login attempt and trigger error message
          reject({cause: 'failed', error: null});
        }
        
      });
      apiPromise.catch((error) => {
        reject({error: error});
      }); 
  });
   
}//end validateToken



/**
 * Wrap the App with the additional login requirements, the child is normal app after login
 * @param {*} child 
 */
export const RegisteredLogin = ({child,
                                ...props}) =>{


    const [isRegistering, setRegister] = useState(false);
    //state of the registration form
    // -register: Start the registration process (Register button)
    // -incorrect: Incorrect OTP was entered into the field
    // -expired: OTP timed out before the user entered the code
    const [registrationState, updateState] = useState('register'); 
    const [enteredOTP, setInput] = useState(null); //The OTP that was entered into the input field

    //First execution 
    React.useEffect(()=>{
      // console.log("Loaded with props: ",props);
      return () => {}
    },[]) //[] -> execute once


    /**
     * Handle clicks on buttons on the UI
     * @param {*} _data: name of the button that was clicked 
     */
    function handleClick(_data){
      console.log("Process: ",_data);
      switch(_data){
        //Start button (register) when clicked the form is opened 
        //to show the input field and submit button
        case 'register':{
          setRegister(true); //toggle the form
          apiMessage({mode: 'sendOTP'}); //define the message to send to the API
        }break;
        //Submit button: send the entered code to the API to validate that the 2FA is correct
        case 'submit':{
          apiMessage({mode: 'validate', otp: enteredOTP}); //define the message to send to the API
          updateState('submitted'); //change button state
        }break;
        //Try Again button: 2FA validation failed, re-enter and try again
        case 'try_again':{
          setRegister(false); //toggle the form
          updateState('register'); //reset the error message
        }break;
      }//end switch statement
    } //end handleClick

    /**
     * Send data to the API
     * @param {*} _sendObj: Object to attach to the message body to send to the API
     */
    function apiMessage(_sendObj){
      // Query the API to authenticate against restrictions:
      let apiName = "TrifectaCoreAPI";
      let path = "/handleSecureLogin";
      let myInit = {
        //by default send along the current username with all messages
        body: {username: props.userInfo.username }
          
      };
      myInit.body = Object.assign(myInit.body,_sendObj);//merge new data into the send request

      let apiPromise = API.post(apiName, path, myInit);
      apiPromise.then((data) => { handleAPIResponse(data);}); //no errors so send the response to be processed
      apiPromise.catch((error) => {
          console.error("Failed on Secure Login; ",error); 
      }); 
    }
    /**
     * Process the API data that returned from the OTP validatation activities
     * @param {*} _response: response from the API
     */
    function handleAPIResponse(_response){
      // console.log("Secure Login request return: ",_response);
      //check if an error was reported, if so then restart the process:
      if(_response.error){console.log("Failed in registration: ",_response); updateState('register'); return;}
      //Validate the returned data format and exit early if not complete:
      if(!_response.state){ 
        console.log("No state returned? ",_response);
        return; 
      } //there should be a state associated
      //Handle the states in the message
      switch(_response.state){
        //sucess save the security token to the cookie
        case 'registered':{ 
          //Success
          document.cookie = `registered-token=${_response.token};expires=Tue, 19 Jan 2038 04:14:07 GMT`
          
          //Update the login state to allow normal page load:
          if(props.stateCallback)(props.stateCallback({login_state:null}))
        }
      }
      //Update the state in the component as returned from the API: 
      updateState(_response.state);
    }

    /**
     * Error mesasge to display to a user without permission to register a computer
     * But still requires an authorized computer to log in
     * @returns HTML code to render the message
     */
    const render_notRegistered=()=>{
      return(
              <div className='registeredlogin modal fade show' >
                <div className='md-float-material'>
                  <div className='auth-box'>
                    <div className='input_group'>
                      <div className="col-md-12">
                        <h3 className="text-center txt-primary">Unauthorized Computer, Please contact your Manager.</h3>
                      </div>
                    </div>
                  </div>  
                </div>    
              </div>
            )
    }//end render_notRegistered

    /**
     * Break out the HTML render for the registration form, this simplifies the render loop to keep this organized in sections
     * @param {*} _message: error message to display on the form
     * @param {*} _flag: flag to change the button type from submit to try again
     * @returns the HTML code to render the form
     */
    const render_registerForm=(_message,_flag)=>{
      return (<React.Fragment>
                {_message &&  <div className="error-message-div form-control-danger">{_message}</div> }
                <div className="input-group">                  
                  <input className={`form-control ${_message?'form-control-danger':null}`} placeholder="OTP" type="text" name="otp" disabled={false}
                          onChange={e => setInput(e.target.value)}
                  />
                    <span className="md-line"></span>
                </div>
                <div className="row m-t-15"><div className="col-md-12">
                    {_flag===3?
                      <Spinner/>
                      :
                      <button className="btn btn-primary btn-md btn-block waves-effect text-center" type="submit" disabled={false} 
                                onClick={()=>{handleClick(_flag===2?'try_again':'submit')}}>
                          {_flag===2?'Try Again':'Submit'}
                        </button>
                    }
                      
                </div></div>
              </React.Fragment>
            )
    }//end render_registerForm
    /**
     * Helper method called when the registration form is open, Depending on the state of the component this 
     * helper will forward along error messages and change button configurations
     * @returns HTML code to render the form
     */
    const renderForm=()=>{
      // console.log("Render form:" ,registrationState);
      switch(registrationState){
        default:{return render_registerForm();}
        case 'incorrect':{ return render_registerForm('Incorrect OTP entered, please re-enter to try again',1);}
        case 'expired':{ return render_registerForm('An expired code has been entered, please click try again.',2);}
        case 'submitted':{ return render_registerForm(null,3);}
      }
    }//end renderForm

    /**
     * Render the Register form for the user run the 2FA process, this primarily formats the surrounding 
     * components so that the form can mimic the shape and style of the login form
     * @returns HTML code to render the form
     */
    const render_register=()=>{
      return(
                <div className='registeredlogin modal fade show' >
                  <div className='md-float-material'>
                    <div className='auth-box'>
                      <div className='input_group'>
                        <div className="col-md-12"><h3 className="text-center txt-primary">Register PC?</h3></div>

                        {/* Toggle between register vs registration form */}
                        {isRegistering?
                            renderForm()
                          :
                            <div className="row m-t-15 m-b-20"><div className="col-md-12">
                                  <button  className="btn btn-primary btn-md btn-block waves-effect text-center" type="submit" 
                                      onClick={()=>{handleClick('register')}}>
                                        Register
                                  </button>
                            </div></div>
                          
                        }
                      </div>
                    </div>  
                  </div>    
                </div>
            )
    }//end render_register

    /**
     * Abstract the render loop for clear organization, this is returned by the component below
     * The component accepts a "child" component which is in this case the actual normal remainder of the Cloud Service
     * This "child" is hidden until the login is completed
     * @returns HTML code to render
     */
    const render= ()=>{
      // console.log("Call the child",props.login_state)
      switch(props.login_state){
        default:              return (child);
        case 'notRegistered': return render_notRegistered();
        case 'register':      return render_register();
      }
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();
  }//end RegisteredLogin component