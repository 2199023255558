
import React, {  PureComponent } from 'react';
import { setupPerf  } from '../Perf.js';
import {  filenameAlphaStripper } from '../Util.js';

import * as moment from 'moment';
import '../VideoReview/VideoReviewer.css';
import axios from 'axios';

import { VideoClipPlayer } from './DVRVideoClipPlayer.js';


/*
* @brief A component for displaying multiple videos that can be switched between for review
*/
export class VideoReviewerType extends PureComponent {
  constructor(props) {
    super(props);
    setupPerf(this, 'VideoReviewer', () => true);

    this.getPlayer = this.getPlayer.bind(this);

    this.handleLoadFetch = this.handleLoadFetch.bind(this);
    this.handleLoadAxios = this.handleLoadAxios.bind(this);
    this.fetchClipData = this.fetchClipData.bind(this);
    this.handleLoadComplete = this.handleLoadComplete.bind(this);


    this.currentlyPlaying = this.currentlyPlaying.bind(this);
    this.markViewed = this.markViewed.bind(this);
    this.overlayButtonClick = this.overlayButtonClick.bind(this);
    
    this.state = {
    //  typeData : JSON.parse(JSON.stringify(this.props.data)), //deep copy
     typeData : this.props.data, //shallow copy
     loadedReturns:[],
     skipClickTimer:moment(),
     infractionLoadIndex: this.props.infractionLoadIndex,
    };

  }

  /* @brief Run once when the class is loaded
  */
  componentDidMount() {
    
    this.setState({skipClickTimer:new moment()}); //need to trigger they prop change in the video player so that the loading of the clips will begin
  
  } //end compenentDidMount

  
  


  UNSAFE_componentWillMount() { 
    this._isMounted = true;
  }
  /* @brief Catch when the class is leaving, navigating away
  */
  componentWillUnmount() { //catches back button and back to analytics
  }

  UNSAFE_componentWillReceiveProps(newProps) {

    // if(newProps.lastLoadComplete !== this.props.lastLoadComplete){
    //   // console.log("Load on another? ",this.props.data.chosenName,newProps.lastLoadComplete - this.props.lastLoadComplete)

    //   let loadingAllowed = this.props.data.loadOrder? this.props.data.loadComplete ? true: this.props.data.loadOrder === newProps.infractionLoadIndex : true;
    //   console.log("Load allowed:",this.props.data.chosenName,this.props.infractionLoadIndex,this.props.data.loadOrder,loadingAllowed)
    //   this.setState({skipClickTimer: new Date()});
    // }
    if(newProps.infractionLoadIndex !== this.props.infractionLoadIndex){
      // if(this.props.data.chosenName==='Cellphone'){console.log("Load on another? ",this.props.data.chosenName,newProps.lastLoadComplete - this.props.lastLoadComplete)}

      let loadingAllowed = this.props.data.loadOrder? this.props.data.loadComplete ? true: this.props.data.loadOrder === newProps.infractionLoadIndex : true;
      
      // if(this.props.data.chosenName==='Cellphone'){console.log("Load allowed:",this.props.data.chosenName,this.props.infractionLoadIndex,this.props.data.loadOrder,loadingAllowed)};
      this.setState({skipClickTimer: new Date(), infractionLoadIndex:newProps.infractionLoadIndex});
    }

    // if(newProps.data !== this.props.data){
    //   console.log("New data received: ",newProps.data);
    // }
    if(newProps.data.set !== this.props.data.set){
      // console.log("New data set received: ",newProps.data.chosenName, newProps.idx,newProps.data.set);
      this.setState({typeData : newProps.data})
    }

    // if(newProps.interruptTimer !== this.props.interruptTimer){
    //   // console.log("Interrupt timer change: ",this.props.data.chosenName, newProps.interruptTimer, this.props.interruptTimer);
    // }
    // if(newProps.lastLoadComplete !== this.props.lastLoadComplete){
    //   // console.log("Load on another? ",this.props.data.chosenName,newProps.lastLoadComplete - this.props.lastLoadComplete)
    //   this.setState({skipClickTimer: new Date()});
    // }
  }//end UNSAFE_componentWillReceiveProps


  onClipReturn(_details){
    this.setState(prevState => {
      let data = prevState.typeData;
      // let foundElem = data.set.find(obj => obj.InfractionID === data_.infractionid);
      let foundElem = data.set.find(obj => obj.InfractionID === _details.infractionid);
      if(foundElem){
        let toSet = {};
        //Add the blob to the object:
        toSet.blob = _details.blob;    
        //update the state
        toSet.loaded = true;
        foundElem = Object.assign(foundElem, toSet); //Update the object so the shallow copy is refreshed?
      }
      //  console.log("loaded: ",_details.InfractionID);                    
      // if(this.props.data.chosenName==='Cellphone'){console.log("loaded: ",configData.infractionid);}
      return {typeData:data};                    
    },//end setState
      ()=>{this.setState({skipClickTimer:new Date()});} //send after state has been applied
    );
  }

  fetchClipData(_details){
     if(_details){

            // console.log("Start loading? ",bTriggerAxios.streamURL);
            let data_ = _details;
            fetch(data_.streamURL
            )
            .then(response => {
              if (!response.ok) {
                throw Error(`HTTP error: ${response.status}`);
              }
              return response.blob();
            })
            .then(res => {
                  // console.log("Found data: ",res, _details);

                  if(res){

                    let tmpBlob = res;
                    const url2 = window.URL.createObjectURL(tmpBlob);

                    //Add the clip to the caching
                    if(this.props.cacheDB){
                      let addPromise= this.props.cacheDB.clips.add({
                        infractionid : _details.InfractionID,
                        urlBlob: res,
                        date: new Date()
                      })
                      addPromise.then( result=> {
                        //Save to cache succesful:
                      })
                      addPromise.catch(error => {
                        console.log("Failed to add with error: ",error);
                      })
                    }
                    

                    this.onClipReturn({infractionid: _details.InfractionID, blob: url2});
                  
                    // //Don't wait for the cache update
                    // // console.log("Returned: ",configData);
                    // this.setState(prevState => {
                    //   let data = prevState.typeData;
                    //   // let foundElem = data.set.find(obj => obj.InfractionID === data_.infractionid);
                    //   let foundElem = data.set.find(obj => obj.InfractionID === _details.InfractionID);
                    //   if(foundElem){
                    //     let toSet = {};
                    //     //Create the blob
                    //     // const url2 = window.URL.createObjectURL(new Blob([res.data]));
                        
                    //     // console.log("Blob made: ",tmpBlob,url2);
                    //     //Add it to the object:
                    //     toSet.blob = url2;    
                    //     //update the state
                    //     toSet.loaded = true;
                    //     foundElem = Object.assign(foundElem, toSet); //Update the object so the shallow copy is refreshed?
                    //   }
                    //   //  console.log("loaded: ",_details.InfractionID);                    
                    //   // if(this.props.data.chosenName==='Cellphone'){console.log("loaded: ",configData.infractionid);}
                    //   return {typeData:data};                    
                    // },//end setState
                    //   ()=>{this.setState({skipClickTimer:new Date()});} //send after state has been applied
                    // );
                  }//end res returned     
            })
            .catch((error) => {
                console.log('Fetch error? ', error);
            });
          }//end check on bTriggerAxios
  }

  handleLoadFetch(_details,_bLoad){
    // if(this.props.data.chosenName==='Cellphone'){console.log("Call load ",_details);}
    // console.log("Call load ",_details, new Date() - this.props.interruptTimer);
    let bTriggerAxios =false;
    this.setState(prevState => {
      let data = prevState.typeData;
      // console.log("Load: ",_details,_bLoad,data);
      if(data.streamURL !== 'playlist'){return {};} //don't process other rows
      if(data.chosenName !== _details.name){return {};} //make sure we are matching the types

      let foundElem = data.set.find(obj => obj.InfractionID === _details.InfractionID);
      if(!_bLoad){
        foundElem.loaded = true;
        foundElem.loading = true;
        // this.setState({buttonData: buttonData}) //set data?
        return {typeData: data};
      }

      if(foundElem && (!foundElem.blob )){
        // console.log("Loading: ",_details,foundElem,data.chosenName)
         
        //Check if this file is handled as a DASH mpd, if so then don't attempt to download it:

          try{
            if(foundElem.streamURL.substring(0,4)==='blob'){
              foundElem.blob = foundElem.streamURL;
              foundElem.loaded = true;
              // this.setState({buttonData:buttonData,skipClickTimer:moment()});
              return {typeData:data, skipClickTimer:new Date()};
            }
          } catch (error) {
            console.log("Clip: ",foundElem,foundElem.streamURL,error,prevState.typeData);   
          }


        //Mark the clip as downloading
        foundElem.blob = 'pending';
        foundElem.loading = true;

        //Check if the cached copy exist?
        if(this.props.cacheDB){
          try {
            let startQuery = new Date();
            let isLocalPromise = this.props.cacheDB.clips.where('infractionid').equals(_details.InfractionID).toArray();
            isLocalPromise.then(_data=>{
                // console.log("Cache return:" ,_data);
                if(_data && _data.length>0){
                  // console.log("Found in cache: ",_data, new Date() - startQuery);
                  let urlBlob = window.URL.createObjectURL(_data[0].urlBlob);
                  this.onClipReturn({infractionid: _details.InfractionID, blob:urlBlob});
                }
                else{
                  // console.log("Not found in cache: ",_details.InfractionID);
                  // the url is for the full stream - trigger download:
                  this.fetchClipData(JSON.parse(JSON.stringify(foundElem)));
                }
            });   
            isLocalPromise.catch(_error=>{});  
          } catch (error) {            
             console.log("Datbase fetched failed?")
            this.fetchClipData(JSON.parse(JSON.stringify(foundElem)));
          }   
                
        }else{
          // console.log("Datbase fetched failed?")
          this.fetchClipData(JSON.parse(JSON.stringify(foundElem)));
        }
        return {typeData:data, skipClickTimer:new Date()};     
      }//end check for empty blob on found element

    },//end setState
    ()=>{//execute on state update:
      
    });//end setState    
    return;
  }
  
  
  handleLoadAxios(_details,_bLoad){
    // if(this.props.data.chosenName==='Cellphone'){console.log("Call load ",_details);}
    // console.log("Call load ",_details, new Date() - this.props.interruptTimer);
    let bTriggerAxios =false;
    this.setState(prevState => {
      let data = prevState.typeData;
      // console.log("Load: ",_details,_bLoad,data);
      if(data.streamURL !== 'playlist'){return {};} //don't process other rows
      if(data.chosenName !== _details.name){return {};} //make sure we are matching the types

      let foundElem = data.set.find(obj => obj.InfractionID === _details.InfractionID);
      if(!_bLoad){
        foundElem.loaded = true;
        foundElem.loading = true;
        // this.setState({buttonData: buttonData}) //set data?
        return {typeData: data};
      }

      if(foundElem && (!foundElem.blob )){
        // console.log("Loading: ",_details,foundElem,data.chosenName)
         
        //Check if this file is handled as a DASH mpd, if so then don't attempt to download it:

          try{
            if(foundElem.streamURL.substring(0,4)==='blob'){
              foundElem.blob = foundElem.streamURL;
              foundElem.loaded = true;
              // this.setState({buttonData:buttonData,skipClickTimer:moment()});
              return {typeData:data, skipClickTimer:new Date()};
            }
          } catch (error) {
            console.log("Clip: ",foundElem,foundElem.streamURL,error,prevState.typeData);   
          }

        
        
        
        // the url is for the full stream - trigger download:
        foundElem.blob = 'pending';
        foundElem.loading = true;
// this.setState({buttonData:buttonData});
        // console.log("Found elem ",foundElem);
        if(foundElem.InfractionID === '40ac0179-283c-48a5-a777-30827f815bf6'){
          console.log("Found elem ",foundElem);
        }
        // console.log("Save to trigger: ",foundElem);
        bTriggerAxios = JSON.parse(JSON.stringify(foundElem));
        return {typeData:data, skipClickTimer:new Date()};
      }
    },
    ()=>{

          // console.log("What is this: ",bTriggerAxios);
        if(bTriggerAxios){
            // console.log("Start loading? ",bTriggerAxios);
            let data_ = bTriggerAxios;
            axios({
                url: data_.streamURL,
                method: 'GET',
                responseType: 'blob', // important
                data: {
                  infractionid: data_.InfractionID,
                  streamURL: data_.streamURL,     
                  idx: _details.idx,
                },
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  // 'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
              })
              .catch(error =>{
                try {
                  let configData = JSON.parse(error.config.data);
                  // if(_details.name ==='Headphones'){ console.log("Axios return E ?", configData)}
                    // foundElem.blob = configData.streamURL;
                    // foundElem.loaded = true;
                    // foundElem.blob = configData.streamURL;
                    // foundElem.loaded = true;
                    // console.log("Failed to load:", configData.infractionid);
                    this.setState(prevState => {
                      let data = prevState.typeData;
                      let foundElem = data.set.find(obj => obj.InfractionID === configData.infractionid);
                      if(foundElem){
                        let toSet = {};
                        //Create the blob                        
                        //Add it to the object:
                        // toSet.blob = null;    
                        // //update the state
                        // toSet.loaded = false;
                         toSet.blob = configData.streamURL;    
                        // //update the state
                         toSet.loaded = true;
                        
                        foundElem = Object.assign(foundElem, toSet); //Update the object so the shallow copy is refreshed?
                      }
                      // console.log("loaded: ",configData.infractionid);                    
                      // if(this.props.data.chosenName==='Cellphone'){console.log("loaded: ",configData.infractionid);}
                      return {typeData:data};                    
                    },//end setState
                      ()=>{
                        // console.log("Updated date: ",JSON.parse(JSON.stringify(this.state.typeData)));
                        this.setState({skipClickTimer:new Date()});
                      } //send after state has been applied
                    );
                    // return {typeData:data, skipClickTimer:moment()};
      //  this.setState({buttonData:buttonData,skipClickTimer:moment()}); //is this failing to trigger the next stage on Safari?
                } catch (error) {
                  
                }
                    
      
                // console.log(error_.response.data)
              })
              .then(res => {
                if(res && res.data){
                  let configData = JSON.parse(res.config.data);
                  // console.log("Returned: ",configData);
                  this.setState(prevState => {
                    let data = prevState.typeData;
                    let foundElem = data.set.find(obj => obj.InfractionID === configData.infractionid);
                    if(foundElem){
                      let toSet = {};
                      //Create the blob
                      let tmpBlob = new Blob([res.data]);
                      const url2 = window.URL.createObjectURL(tmpBlob);
                      // console.log("Blob made:",tmpBlob,url2);
                      //Add it to the object:
                      toSet.blob = url2;    
                      //update the state
                      toSet.loaded = true;
                      foundElem = Object.assign(foundElem, toSet); //Update the object so the shallow copy is refreshed?
                    }
                    // console.log("loaded: ",configData.infractionid);                    
                    // if(this.props.data.chosenName==='Cellphone'){console.log("loaded: ",configData.infractionid);}
                    return {typeData:data};                    
                  },//end setState
                    ()=>{this.setState({skipClickTimer:new Date()});} //send after state has been applied
                  );
                  
                }                         
              });//end axios calls
          }//end check on bTriggerAxios
    });//end setState
    
    return;
  }

  handleLoadComplete(_data){
    // console.log("Complete: ",_data);
    // if(this._isMounted){
      if(this.state.typeData.loadComplete===true){ return;}

      this.setState(prevState => {
        let data = prevState.typeData;
        // let button = data.find(elem_ => (elem_.chosenName === _data.name && elem_.type == _data.type));
        if(data.loadComplete===false){
          // console.log("load complete",_data,new Date() - this.state.timeDebug)
          // console.log("load complete",_data);
        }
        data.loadComplete = true;
        return({typeData: data});
         
      },()=>{
        if(this.props.loadComplete){this.props.loadComplete({
          loadOrder:this.props.data.loadOrder,
          type:this.props.data.type,
          name: this.props.data.chosenName,          
        })}  
      }
      );
    
    // }
  }

  currentlyPlaying(_details) {
    
    // console.log("Currently Playing:" ,_details);
    return; 
    // if(_details.chosenName === this.state.chosen){ //make sure the correct clip is returned
    //   //  console.log("Currently Playing:" ,_details);
    //    if(_details && _details.meta){
    //     if(this._isMounted){this.setState({currentPlayingID: _details.meta.InfractionID});} //extract the ID
    //    }
      
    // }
  }

  markViewed(_type,_tag) {
    if(this.props.markViewed){this.props.markViewed(_type,_tag)}
    //  console.log("mark viewed: ",_type,_tag);
     return;
    // if(!this.state.progressTally[_type]){ console.log("don't count tagged infractions ",this.state.progressTally);return;} //don't mark viewed on the tagged infraction videos?


  }

   /* @brief Callback when the overlay button is clicked, this is passed up from the players
  */
   overlayButtonClick(_details){
    
     console.log("Click on button: ",_details);
     return;

  }//end overlay button click

  getPlayer(){
    let data = this.state.typeData;
    // console.log("Called get player ",this.props.data.chosenName);
    // //  console.log("Test load: ",row.chosenName,row.loadOrder,iInfractionLoadIndex, row.loadOrder? row.loadOrder===iInfractionLoadIndex : true)
    Object.values(data.cards || {}).forEach(card => {
        card.video = data.byID[card.infractionID];
    });

    // //Only pass the relevant eject set to the player:
    let tmpSetName = data.chosenName;
    if(data.flag===2){ tmpSetName=tmpSetName+"-DF"; }

    let countToDisplay = data.count;
     if(data.streamURL === 'playlist'){
      countToDisplay = data.set.length; //don't modify, this is used to determine the number of available clips, impacts the arrows.    
     }
    //Get a reference to the VideoClipPlayer so we can execute updates on the child methods
    // const ref = player => {
    //       this.state.playerRef[row.chosenName] = player
    // }//end ref handle
    // if(row.chosenName === "Seatbelt"){
    //   console.log("Player row:",row,this.state);
    // }
    //check if the infraction clips have had chance to queue into the load sequence, prioritize them first
    if(this.state.loadedReturns.length>0 && data.streamURL !== 'playlist'){
      
    }
    // if(row.streamURL !== 'playlist'){
    //   return;
    // }
    let className ="video-reviewer-player "+data.chosenName+" "+"hidden-video";
    //Don't pass the empty buttons to the VideoClipPlayer - this will create empty video player references
    if(data.streamURL === 'playlist' && data.set.length ===0){
      //  console.log("Non playlist row length: ",data.chosenName);
      return (<div className={className}></div>);
    }
    //Don't pass empty buttons to the videoClipPlayer - this rejects non infraction/clip players
    if(data.streamURL !== 'playlist' && !data.streamURL){
      //  console.log("Non playlist row: ",row);
      return (<div className={className}></div>);
    }
    
    let loadingAllowed = data.loadOrder? data.loadComplete ? true: data.loadOrder===this.state.infractionLoadIndex : true;
    // if(data.chosenName==='Cellphone'){console.log("load allowed:" ,data.loadOrder, this.state.infractionLoadIndex, loadingAllowed)}
    // if(loadingAllowed){
    //   console.log("render triggered on ",data.chosenName)
    // }

    

    return (

      <VideoClipPlayer  className={"VideoClipPlayer"}
    //                     ref={ref}  
                        currentClip={data}                               
                        key={data.chosenName+'-'+data.idx}
                        filter={this.props.filter}
                        groupconfig= {this.props.groupconfig}
                        username = {this.props.username}  
                        filename = {this.props.filename}
                        notecards ={this.props.notecards}
                        markViewed={this.markViewed}
                        chosen={this.props.chosen}
                        overlayButtonClick = {this.props.overlayButtonClick}
                        roiSelectClicked = {this.props.roiSelectClicked}                              
                        ejectClick = {this.props.ejectClick}
                        currentlyPlaying = {this.currentlyPlaying}  
                        bLoadAllowed ={loadingAllowed}   
                        // bLoadAllowed = {false}
                        loadComplete = {this.handleLoadComplete}
    //                     // bMemoryOptimized = {true}      
    //                     // bMemoryOptimized = {iTotalBlobs > 150?true:false}    
                        bMemoryOptimized = {false}      
                        // handleLoad = {this.handleLoadAxios} 
                        handleLoad = {this.handleLoadFetch} 
    //                     // refreshCount = {this.state.forcePlayerRefresh}   
                        refreshTrigger = {this.state.skipClickTimer}
                        interruptTimer = {this.props.interruptTimer}
        />  
    )
    // return (<div></div>)
  }

  /* @brief Render the content of the current page, automatically called when react determines the page needs refreshing.
  */
  render() {
    return ( this.getPlayer()  );
  }
}
