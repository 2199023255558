import React, {  } from 'react';
// import '../AdminTab/AdminUserManagement.css';
import './TrainControl.css';
import { DriverDetail } from '../ExpandedNotecard/DriverDetails';
import { StatefulButton } from '../VideoReview/vid-util';
import { Auth, API } from 'aws-amplify';

/*
* @brief 
*/
export const EvalDriver = ({    groupconfig
                                ,possibleFilters
                                ,driverCandidates
                                ,imgData 
                                ,onChange = ()=>{console.log("EvalDriver onChange not defined")}
                            }) => {


    /** Opened with the first render, call once */
    React.useEffect(()=>{
       
    },[]) //[] -> execute once

    /** Call the API with the attached photo, get the top 3 matches in response */
    function evaluateDriverAPI(){

    }

    //** Process the top 3 match response */
    function updateData(_data){
        
        let candidates = [];
        candidates.push(<DriverDetail className='driverdetail'
                            driverid = {'2856A'}
                            confidence = {0.5}
                            key={'nc-driverid-'+'2856A'}
                        />
                        )
        candidates.push(<DriverDetail className='driverdetail'
                            driverid = {'288A8'}
                            confidence = {0.5}
                            key={'nc-driverid-'+'2856A2'}
                        />
                        )
        candidates.push(<DriverDetail className='driverdetail'
                            driverid = {'28FD3'}
                            confidence = {0.5}
                            key={'nc-driverid-'+'2856A2'}
                        />
                        )

        onChange({type:'matches',data:[...candidates]});
    }

    function handleStart(){
        //Execute a query to the AWS Lambda:
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaCoreAPI";
                let path = "/handleDriverTraining";
                let body = {
                    token: auth.idToken.jwtToken,
                    site:'PintoValleyMine',
                    mode: 'eval',         
                    photo: imgData 
                }
                return API.post(apiName, path, {body:body});
        })
        fetchPromise.then(data_=>{console.log("returned: ",data_);
            // updateData();
        })
        fetchPromise.catch(error_=>{console.log("error catch: ",error_);
            // updateData();
        })
        
    }

    function handleNoMatchConfirm(_changeEvent){
        console.log("Received: ",_changeEvent.target.value, _changeEvent.target.checked);
        try {
            if(_changeEvent.target.value === 'NoMatch'){
                onChange({type:'novisual',data:_changeEvent.target.checked});
                // setTrainEnabled(_changeEvent.target.checked);
            }
        } catch (error) {
            console.log("Failed to handle checkbox: ",error);
        }
    }

    //Define the returned rendered HTML:
    function render(){
        console.log("ImgData: ",imgData?true:false);
        return (
            <div className='eval-driver'>
                {/* Check if the top 3 matches have returned, if not show the button to start */}
                {driverCandidates? 
                    <React.Fragment>
                        <div className='driverDisplay'>
                                                {driverCandidates.map((driver_) =>  {return(driver_)})}
                        </div>
                        {/* Add a checkbox showing that there are no matches in the list of drivers  */}
                        <div>
                            <input type="checkbox"  value="NoMatch" id='nomatch-eval'  onChange={handleNoMatchConfirm} />
                            <label for="nomatch-eval">No visual matches found in DriverID set</label>
                        </div>
                        
                    </React.Fragment>
                :
                    <StatefulButton className="btn btn-primary v-center" onClick={handleStart} disabled={imgData?false:true}> Evaluate Driver Photo </StatefulButton>
                    
                }   
                
            </div>
        )
    }
       
    return render();
};



