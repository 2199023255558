import React, { Component,PureComponent } from 'react';

import './RiskStats.css';

// import { RiskCard } from './Risk-card.js';
import { DriverTile } from './DriverTile.js';
import {severityFormatter, percentageToColor, pictureFormatter, radialFormatter, noteFormatter, PieChart} from './RiskUtils.js'
import { Spinner } from '../ApiCaller';


/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class RiskStats extends Component {
  constructor(props) {
    super(props);
    
    this.onLoadingState = this.onLoadingState.bind(this);

    this.state = {
        radialCard: null,
        
    };
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }

  componentDidMount(){
    // console.log("On mount ",this.props)
  }

  componentWillUnmount(){
    // console.log("Will unmount")
    // console.log("Got the call")
    // this.props.onExit();
  }
  

  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {
 
    //Compute the data for the piechart:
    //Get the data for the Top10 display
    let top10Percent = 0;
    let top20Percent = 0;
    let bRenderPies = true;

    let top10Data = {
      total: this.props.totalInfractions,
      type: [{label: 'Unavailable',count: parseInt(this.props.totalUnavailable,10),  slice:{color: 'rgb(141,198,63)'}}],      
    }
    if(this.props.top10){
      const tempArray = Object.values(this.props.top10);
      if(tempArray.length>0){
        const top10Sum = tempArray.reduce((total, elem_) => {
          // console.log("Elem: ",elem_,elem_.infcount,parseInt(elem_.infcount,10),  total);
          return total + parseInt(elem_.infcount,10);
        },0 );   
        top10Data.type.push({label: 'Top 10', count:top10Sum, slice:{color: 'rgb(237,28,36)', offset: 0.2}});
        top10Data.type.push({label: 'Rest of Drivers', count:top10Data.total-top10Sum-parseInt(this.props.totalUnavailable,10),slice:{color: 'rgb(0,174,239)'}});

        top10Percent = top10Sum/top10Data.total;
      }     
    }
    //Get the data for the Top20 display
    let top20Data = {
      total: this.props.totalInfractions,
      type: [{label: 'Unavailable', count: parseInt(this.props.totalUnavailable,10),slice:{color: 'rgb(141,198,63)'}}],      
    }
    if(this.props.top20){
        // console.log("Top20: ",this.props.top20)
      const tempArray = Object.values(this.props.top20);       
      if(tempArray.length>0){
        const top20Sum = tempArray.reduce((total, elem_) => {
          // console.log("Elem: ",elem_,elem_.infcount,parseInt(elem_.infcount,10),  total);
          return total + parseInt(elem_.infcount,10);
        },0 );   
        top20Data.type.push({label: 'Top 20', count:top20Sum ,slice:{color: 'rgb(247,150,70)', offset: 0.2}});
        top20Data.type.push({label: 'Rest of Drivers', count:top10Data.total-top20Sum-parseInt(this.props.totalUnavailable,10),slice:{color: 'rgb(128,100,162)'}});

        top20Percent = top20Sum/top20Data.total;
      }     
      
      if(top20Data.total === 0){
        bRenderPies = false;
      }
    }
    
    //Create the driver photo layout:
    let driverCards = [];
    // let driverSet = this.props.top10;
    let driverSet = this.props.top20;
    let bDisplayTable = true;
    if(driverSet){
      const tmpArray = Object.values(driverSet);      

      if(tmpArray.length===0 && this.props.apiReturned){
        bDisplayTable = false;
      }else{

        tmpArray.sort( (a,b) =>{   return a.infcount>b.infcount?-1:1  }); //sort in desc order
          // console.log("Sort? ",tmpArray);
          driverCards=( tmpArray||[]).map( (driver_,idx_) =>{

            //Find the current loading progress:
            let allowToLoad = false;
            try {
              let loadedIdx = this.props.loadState.findIndex(elem_=>{ return elem_===false});
              if(loadedIdx<0 || idx_ < loadedIdx+1){
                allowToLoad=true;
              }
            } catch (error) {
              allowToLoad = true;
            }
            
            return <DriverTile {...driver_} loadAllowed ={allowToLoad} total = {this.props.totalInfractions} onClick = {this.props.onClick} 
                  />
          });//end drivertile populate

      }
    }
    
    
    
    // {
    //   // console.log("DriverID set: ",this.props.activeFilters,this.props.activeFilters.driver)
    // }
    
    
    // console.log("Top 10: ",driverCards);

    
    if(this.props.activeFilters.driver){
      bRenderPies = false;
    }
    if(this.props.totalInfractions === 0){
      bRenderPies = false;
    }
    if(driverCards.length===0){
      bRenderPies = false;
    }
    
    return (
      <div className="riskstats">
        
            {/* {!bDisplayTable?<div className='nodata'>No data available, please update the filter selection</div>: */}
            {this.props.noDataMessage?this.props.noDataMessage:
              <div>
                {driverCards.length===0?<Spinner/>:
                
                <div className='drivertable'>
                  {driverCards}
                </div>
                }
             </div>
            }
            {bRenderPies&&
              <div className='piecharts'>
                <PieChart
                    titleText="top 10 chart"
                    graph_id="stats-piechart"
                    summary = {top10Data}
                    width = "100%" height = "40vh"

                />
                <PieChart
                  titleText="top 20 chart"
                  graph_id="stats-piechart"
                  summary = {top20Data}
                  width = "100%" height = "40vh"
                />
              </div>
            }
            
            {(bRenderPies && this.props.totalDrivers)&&
              <div className='stats-foot'>
                  <div> 10 of {this.props.totalDrivers} drivers are responsible for {(top10Percent*100).toFixed(1)}% of all {this.props.activeFilters.infraction} events</div>
                  <div> 20 of {this.props.totalDrivers} drivers are responsible for {(top20Percent*100).toFixed(1)}% of all {this.props.activeFilters.infraction} events</div>
              </div>
            }
        
       
      </div>
    );
  }
}

export { RiskStats };