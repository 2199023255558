import React, { Component } from 'react';

/*
* @brief Main documentation page of the app
*/
class DocumentationView extends Component {
    componentDidMount() {
        console.log(this.constructor.name + " mounting time:", Date.now());
    }
    render() {
        return (
            <div>Documentation page coming soon</div>
        );
    }
}

export { DocumentationView };