
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';


import '../ExpandedCard.css';
import '../NoteCard-Comments.css'
import './NoteCard-Expanded.css';


import { SEVERITIES, getStreamURL,OTHERHWTAGS,reviewGroups } from '../Util.js';

import {NCTitle, NCLeftBottom, NCLeftTop, NCVideo, NCComments, NCFooter, getTypeAndTagText} from './NoteCard-Components.js'
import { NCBack } from './NoteCard-back.js';

import * as moment from 'moment';

import { Auth, API } from 'aws-amplify';


// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

// See https://momentjs.com/docs/#/displaying/ for formatting syntax
const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';


/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/

export class ExpandedCard extends PureComponent {
    constructor(props) {
        super(props);

        //Fetching Functions to get data from the API
        this.getApiCall = this.getApiCall.bind(this); //get the expanded card data
        this.updateData = this.updateData.bind(this); //process the data returned from getAPICall
        this.updateDriver = this.updateDriver.bind(this); //process the DriverID return from the API call
        this.getDriverCall = this.getDriverCall.bind(this); //get the Top3 DriverID options from the API
        this.getMapCall = this.getMapCall.bind(this); //get the GPS map from the API
        this.getDriverID = this.getDriverID.bind(this); //make another query to get just the DriverID from the API

        //Handle updating the stored comments on the card
        this.updateComment = this.updateComment.bind(this);
        //Add/Remove infraction tags and change the severity on the card        
        this.infractionTagChanged = this.infractionTagChanged.bind(this);
        this.checkSeverityFromTags = this.checkSeverityFromTags.bind(this);        
        this.severityChanged = this.severityChanged.bind(this);
        //Handle updating the driverid when the review selects a new face image
        this.driverIDChanged = this.driverIDChanged.bind(this);
        //Switch between the back and front of the notecards
        this.hamburgerToggle = this.hamburgerToggle.bind(this);

        this.state = {
            comments: [], //array of comment objects (text, time, username)
            video: null, //URL of the video file (or local blob)
            shown: this.props.cacheDB?true:false,
            gpsmap: null, //the img data to diplay the map
            lat: null, //hold the latitude of the clip
            lon: null, //hold the longitude of the clip
            speed: null, //hold the speed recorded with the clip
            bShowFront: true, //show the front of the notecard, will be false to show the back
            currentUsername:null, //current user that is logged in (API auth sets this)
            infractionTags:this.props.infractionTags||[],            
            infractionTagsList: this.props.groupconfig.infractionTags,
            startTime : this.props.openTimer || new Date(),
            metadata: this.props.metadata, //metadata returned from the VideoCards SQL table (contains gforce, alerts, etc.)
            // name:this.props.name, //driverid (or name if available)
            top3Data: null, //top 3 driver candidates returned from API query
            timeOfDay: this.props.timeOfDay, // time the card was recorded
            linkedVidID: this.props.id, //the video id to reference in the JOBS SQL table
            infractionid: this.props.infractionID || this.props.infractionid, //infractionid to reference the Highlighgts,Notecards.... SQL tables
            dlLink: null, //URL to download the clip
            severity: this.props.severity, //The severity of the tagged infractions
        };
        // this.perfName = 'ExpandedCard';
    }
    /* Lifecycle method called once when the Class is created (after the first pass of the render()*/
    componentDidMount() { 
        // console.log("Time since load: ",new Date() - this.state.startTime);
        // console.log("Card mount: ",this.props)
        console.log("Card mount:" ,new Date() - this.state.startTime);

        if(!this.props.cacheDB){
            //Delay opening/showing the notecard
            window.setTimeout(() => {
                this.setState({shown: true});
            }, EXPANDED_CARD_SHOW_LATENCY);
        }
        

        try {
            Auth.currentSession().then((auth) => 
            {
                    this.setState({currentUsername: auth.idToken.payload['cognito:username']});
            });    
        } catch (error) {
        }

        //Check local cache for video:
        if(this.props.cacheDB && this.props.cacheDB.db){
            let db = this.props.cacheDB.db;
            let table = this.props.cacheDB.table;
            let key = this.props.cacheDB.key;
            let isLocalPromise = db[table].where(key).equals(this.state.infractionid).toArray();
            isLocalPromise.then(_data=>{
                //  console.log("Cache return:" ,this.state.infractionid);
                if(_data && _data.length>0){
                  // console.log("Found in cache: ",_data, new Date() - startQuery);
                  let urlBlob = window.URL.createObjectURL(_data[0].urlBlob);
                  this.setState({video: urlBlob})
                }
                else{
                   console.log("Not found in cache: ",this.state.infractionid);
                }
            });   
            isLocalPromise.catch(_error=>{});
        }
        
        //Sanitize the infractionTags type
        let infractionTypes = this.props.groupconfig.infractionTags;        
        if(!infractionTypes.find(tag_ => tag_.type === ' ')){
            infractionTypes.unshift({type:" ",severity:"IRRELEVANT"});
        }

        let updateObject = {infractionTagsList: infractionTypes};
        //Check if the DriverID was passed in, and not Pending 
        //If this is a known DriverID then set it to the notecard state
        if(this.props.name && this.props.name!=="Pending"){
            updateObject.name=this.props.name;
        }
        //Update the notecard state
        this.setState(updateObject);

        //Debug
        // {
        //     let that = this;
        //     setTimeout(() => {   
        //         that.getMapCall() 
        //         let apiPromise =  that.getApiCall();
        //         apiPromise.then(_data =>{that.updateData(_data)});
        //     }, 6000)
        // }
        this.getMapCall();
        let apiPromise =  this.getApiCall();
        apiPromise.then(_data =>{this.updateData(_data)});
        

        // Trigger the loading of the Top3 Drivers
        // console.log("Props loaded:" ,this.props, reviewGroups);
        if(this.props.groupconfig && reviewGroups.includes(this.props.groupconfig.group)&&this.props.driverChange){
            this.getDriverCall();
        }
    }//end ComponentDidMount
    
    UNSAFE_componentWillMount(){ReactModal.setAppElement('body'); } //Compatibility warning?
    componentWillUnmount(){

        if(this.state.fetchMapTimeID){clearTimeout(this.state.fetchMapTimeID)}//cancel the map download request
        if(this.state.fetchDriverID){clearTimeout(this.state.fetchDriverID)}//cancel the query timeout

        // console.log("Closed the expanded card");
        //Force release of large strings, this should be done automatically
        //Helped to remove these review the heap snapshots 
        this.state.gpsmap = null;
        this.state.dlLink = null;
        this.state.infractionTagsList = null;
        this.state.video = null;
        this.state.comments = null;
        this.state.timeOfDay = null;
        
    }

    /*
    * @brief Updating function that is called when the props passed from the calling class are changed.
    */
    UNSAFE_componentWillReceiveProps(nextProps) {        
        const timePassed = new Date() - this.state.startTime;
        //Restrict this to only function during the first 500ms of the notecards lifespan
        if(timePassed > 500){return;} 
        // console.log("Time since start: ",timePassed);//in ms
        //Check if the default infractiontags is set, if so then added it to the tag changed
        if (nextProps.infractionTags_default && nextProps.infractionTags_default.length > 0 
            && !this.state.infractionTags.includes(nextProps.infractionTags_default[0])) {
            try {
                if(nextProps.infractionTags_default.length === 1){
                    //  console.log("Pass tags to: ",nextProps.infractionTags_default[0]);
                     this.infractionTagChanged({value:nextProps.infractionTags_default[0]},true);
                }    
            } catch (error) { }
        }
    }

    /*  @brief Fetch the Top3 Driver data from the SQL table to present as potential choices
    */
    getDriverCall(){
        // console.log("Get Top3 DriverIDs");
        //Query the journey_raw SQL table to get the top3 pending DriverIDS
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDriverInfo";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        infractionid: this.props.infractionID || this.props.infractionid,
                        mode: 'fetchtop3',              
                    }
                };
                return API.post(apiName, path, myInit);
        })
        fetchPromise.then(_top3response=>{
            //Process the DriverID data:
            //----------
            //  console.log("Top response: ",_top3response);
            if(_top3response && _top3response.data){
                //Need to combine all possible candidates:

                let candidateList = new Set();
                const seen = new Set();
                // console.log("Loop over: ",top3response.data);
                for(const set_ of _top3response.data){
                    try {
                    //   console.log("Process: ",set_)                
                    for(const candidate_ of (set_.candidates)){
                        // console.log("Test: ",candidate_.id, seen.has(candidate_.id))
                        let bFound = seen.has(candidate_.id);
                        seen.add(candidate_.id);
                        if(!bFound){
                            candidateList.add(candidate_);
                        }
                    }                    
                    } catch (error) {}
                    
                }
                // console.log("LIst: ",candidateList);
                let candidatesScored = [...candidateList];
                candidatesScored = candidatesScored.sort((a,b) => a.confidence > b.confidence?-1:0); //sort by highest confidence
                if(candidatesScored.length>3){
                    candidatesScored.splice(3);
                }            
                // console.log("Scored: ",candidatesScored);
                let inputData={
                    driverid: null,
                    confidence: null,
                    candidates:candidatesScored, 
                }
                // console.log("Update top3:" ,inputData);
                this.setState({top3Data: inputData})
            }
        });
        fetchPromise.catch(_error=>{console.log("Failed to get the Top3: ",_error)});
    }//end getDriverCall

    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        // console.log("Load card: ",new Date() - this.state.startTime);
        console.log("Get notecard data for: ",this.props.infractionID);
        // console.log("Get card data with: ",this.props);
        let typeAndTag = getTypeAndTagText(this.props)
        // Create the API call + promise wrapper that will get the video highlights
        const dataPromise = Auth.currentSession().then((auth) => 
            {
                let apiName = "AuthLambda";
                let path = "/getExpandedCardData";  
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        fetchThumbnail: this.props.photo?false:true, //should the query fetch the thumbnail?
                        // fetchThumbnail: true, //debug - always fetch
                        card:{
                            id: this.props.cardID,
                            infractionid: this.props.infractionID || this.props.infractionid,
                            typeAndTag: typeAndTag,
                            timeofday: this.props.timeOfDay,
                        }
                    }
                };
            return API.post(apiName, path, myInit);
        });
        return dataPromise;
    }

    /**
     * Make a follow-up call to the API to get the DriverID. This is only called when the initial call to the API
     * returns null or "Pending". The notecard data is updated with the DriverID when the notecard is created. If the call
     * to fetch the DriverID from the notecard is made before the SQL table can be updated then the DriverID needs to be refetched
     * to show the actual value
     */
    getDriverID() {
        // console.log("Get card data with (DriverID): ",this.props);
        const dataPromise = Auth.currentSession().then((auth) => 
            {
                let apiName = "AuthLambda";
                let path = "/getExpandedCardData";  
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: "driverid",                            
                        card:{
                            id: this.props.cardID,
                            infractionid: this.props.infractionID || this.props.infractionid,
                        }
                    }
                };
            return API.post(apiName, path, myInit);
        });
        dataPromise.then(_data => {
            // console.log("DriverID query returned: ", _data);
            this.updateDriver(_data,true);
        })
        dataPromise.catch(function(error) {
            console.log("Fetch DriverID error: ",error);
        });
    }

    /*
    * @brief Called to on the return of getAPICall to update the data we're displaying
    */
    updateData(_data) {
        // console.log("Expanded Notecard data returned: ",_data);
        if(_data==undefined || _data == null){return;}
        // Process the notecard data:
        if(!_data.gpsmap && _data.lat && _data.lon){         
            this.setState({fetchMapTimeID: setTimeout(() => {   this.getMapCall() }, 6000)});
        }
        // Check if the DriverID didn't get returned, if missing then try to refetch
        if(!_data.driverid || _data.driverid === "Pending"){         
            this.setState({fetchDriverID: setTimeout(() => {   this.getDriverID() }, 750)});
        }

        // Group all updated state variables together
        let stateObj = {
            comments: _data.comments,                 
            gpsmap:_data.gpsmap,
            lat:_data.lat,lon:_data.lon,speed:_data.speed, 
            dlLink: _data.dlurl,            
            timeOfDay: moment.parseZone(_data.timeofday),
        }
        //Don't overwrite the metadata, only save if currently empty
        if(!this.state.metadata){stateObj.metadata = _data.metadata; }

        // If the video wasn't loaded from cache, then link it from the API return
        let streamURL = getStreamURL(_data.video);
        if(!this.state.video){stateObj.video = streamURL; }

        // Set the linked video ID if it is not known
        if(!this.state.linkedVidID && _data.parentid){
            stateObj.linkedVidID = _data.parentid;
        }

        // Update the local state variables
        this.setState(stateObj, 
            // ()=>{console.log("State updated:" ,this.state)}
        );

        // Pass the infractionID and stream URL to the details listener
        if(this.props.newDetails){
            this.props.newDetails({
                infractionID:this.props.infractionID,
                streamURL: streamURL,
            })
        }

        //Check if the thumbnail was returned, if so , then 
        //return it to the calling class
        if(_data.thumbnail){
            //update the calling card:
            if(this.props.updateCard){
                this.props.updateCard({
                    infractionID: this.props.infractionID,
                    status: this.props.status,
                    photo:_data.thumbnail
                    });
            }
        }

        //If DriverID was returned then update the data in the State object
        if(_data.driverid){ this.updateDriver(_data); }
    }; //end updateData

    /**
     * Update the DriverID in the state, and trigger the callback to update the parent's copy
     */
    updateDriver(_data, _bFinal=false) {
        // console.log("UpdateDriver called: ",_data);
        if(_data==undefined || _data == null){return;}
        if(!_data.driverid){
            // console.log("DriverID returned: ",_data.driver);
            return;
        }

        //Early escape, don't accept pending unless this is the last chance
        if(_data.driverid ==='Pending' && !_bFinal){
            // console.log("discard early pending id");
            return;
        }
        // Group all updated state variables together
        let stateObj = {
        }
        if(_data.driverid){
            stateObj.name= _data.driverid;
            // Update the local state variables
            this.setState({name:_data.driverid}, 
            //  ()=>{console.log("State updated:" ,this.state)}
            );
            // Pass any new driver ID to the parent class
            if(this.props.driverChange && _data.driverid != null){
                // console.log("Driver change requested from update data")
                this.props.driverChange({
                    cardID: this.props.cardID,
                    infractionID:this.props.infractionID,
                    infractionType: this.props.infractionType,
                    name: _data.driverid,
                    driverID: _data.driverid,
                });   
            }
        }
        
        
    }; //end updateDriver

    /*
    * @brief Call the SQL database and get only the map from the highlights
    */
    getMapCall() {
        // Create the API call + promise wrapper that will get the video highlights
        const mapPromise = Auth.currentSession().then((auth) => 
            {
                let apiName = "AuthLambda";
                let path = "/getExpandedCardData";  
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        card:{
                            id: this.props.cardID,
                            infractionid: this.props.infractionID,
                            timeofday: this.props.timeOfDay,
                            gpsMapRequested: true,
                        }
                    }
                };
            return API.post(apiName, path, myInit);
        });
        mapPromise.then(data => {
            this.setState({gpsmap:data.gpsmap});
        })
        mapPromise.catch(function(error) {
            console.log("Map promise error: ",error);
        });
        
    }
   
    /*
    * @brief A callback for when the currently-being-added comment is updated
    */
    updateComment(_comment) {
        // console.log("Comment action: ",_comment);
        if(!_comment){return;}
        if(!_comment.action){return;}
        //handle both adding and editing comments
        try {
            switch(_comment.action){
                case 'add':{
                    const newComments = this.state.comments.slice(); //copy the current array of comments
                    
                    //Set up the object to add to the current comments                    
                    let objToAdd = {text: _comment.text, timestamp: moment().utc().format()}
                    if(this.state.currentUsername){ //add the username if we have it
                        objToAdd.username = this.state.currentUsername
                    }

                    //Add the new comment to the exisiting comments
                    newComments.push(objToAdd)
                    //Save the comments to the class state
                    this.setState({comments:newComments})
                }break;
                case 'edit':{
                    //Iterate over the comments and build a new array (map give us the new array)
                    //Need to have a new array so that the state will change to trigger the render 
                    //The previous version modified the array in place and the relied on a window timeout to change another parameter
                    //This is bad appraoch and is incompatible with the new components that are listening for the array change
                    let newComments = (this.state.comments||[]).map( (comment_)=>{
                        //Compare what is currently in the comments array against the new data we want to change
                        if(comment_.text === _comment.originalText){ //Find the commment we are modifying
                            comment_.text = _comment.text; //update the comment
                        }
                        return comment_; //Return the element to put in the new array
                    });
                    this.setState({comments:newComments}); //save the update to the class state
                }break;
            }//end of switch
            //Pass the new comment along to the calling class
            if(_comment.action==='add' && this.props.cardChange){
                this.props.cardChange({
                    comment:  _comment.text, //must include to pass the valid card change check in App.js                     
                    cardID: this.props.cardID, //must include to pass the valid card change check in App.js
                    tag: this.props.tag,
                    infractionID:this.props.infractionID,
                    infractionType: this.props.infractionType
                });
            }
        } catch (error) {
            console.log("Failed to update comment: ",error);
        }
        return;
    } //end updateComment


    /*
    * @brief Notify the callback and SQL table of a severity change
    */
    severityChanged({value}) {
        // console.log("Set serverity ", value);
        this.setState({severity:value});
        if(!this.props.cardChange){return;}
        this.props.cardChange({
            severity: value,
            cardID: this.props.cardID,
            infractionType: this.props.infractionType,
            tag: this.props.tag,
            infractionID:this.props.infractionID,
        });
    }
    /*
    * @brief Determine what the severity should be set to based on the infractions tagged on the card
    */
    checkSeverityFromTags(_tags){
        if(_tags.length===0){
            this.severityChanged({value:"LOW"});
            return;
        }
        // console.log("Check severity: ",_tags);
        //What is a highest severity?
        // let matchedSeverity = this.props.groupconfig.infractionTags.filter(s => s.type === value);
        let currentSeverity = SEVERITIES.indexOf("IRRELEVANT");      //SEVERITIES.indexOf(this.props.severity);
        let newSeverity = currentSeverity;
        (_tags || []).forEach((infraction_) => {
            let matchedSeverity = (this.state.infractionTagsList||[]).filter(s => s.type === infraction_);
            // let matchedSeverity = this.props.groupconfig.infractionTags.filter(s => s.type === infraction_);
            let otherSeverity = OTHERHWTAGS.filter(s => s.type === infraction_);
            matchedSeverity = matchedSeverity.concat(otherSeverity);
            

            if(matchedSeverity && matchedSeverity.length>=1){
                let testSeverity = SEVERITIES.indexOf(matchedSeverity[0].severity);
                if(SEVERITIES.indexOf(matchedSeverity[0].severity) < newSeverity){
                    newSeverity = testSeverity;
                }
            }            
        });
        this.severityChanged({value:SEVERITIES[newSeverity]});           
    }//end checkSeverityFromTags
    // /*
    // * @brief A new infraction has been tagged, and added to the card
    // */
    infractionTagChanged({value, action}) {
        //  console.log("Tag recieved: ",value,confirmed);
        if(!value){return;}
        if(this.props.noEdit){return;} //read only mode enabled

        if(value==="" || value===" "){return;}

        let infractionsToSend = null;
        //Update the set of infraction tags:
        if(action && action ==='remove'){ //check if we need to remove a tag
            infractionsToSend = this.state.infractionTags.filter(tag_ => tag_!==value)
        }else{ //otherwise process the add action
            //Build the list of the infractions:
            infractionsToSend = this.state.infractionTags;
            //Handle reset with Irrelevant
            if(value === 'Irrelevant'){
                infractionsToSend = ['Irrelevant']; //clear the other options
            }else{ //otherwise add the tag
                infractionsToSend.push(value);
                infractionsToSend = [...new Set(infractionsToSend)]; //run through a set to prevent any duplicates
            }
        }

        if(!infractionsToSend){return;} //Sanity check the value returned from the updates

        this.checkSeverityFromTags(infractionsToSend);
        //Notify the parent classes of the changes:
        if(this.props.cardChange){
            this.props.cardChange({
                infractionTags: infractionsToSend.toString(),
                infractionType: this.props.infractionType,
                cardID: this.props.cardID,
                infractionID:this.props.infractionID,
                tag: this.props.tag,
            });
        }
        if(this.props.tagChange){
            this.props.tagChange({
                infractionTags:infractionsToSend,
                infractionID:this.props.infractionID,
                cardID: this.props.cardID,
                infractionType: this.props.infractionType,
                tag: this.props.tag,
                timeCreated: this.props.timeCreated,
                }
            )    
        }
        
        //Update the infractions in the class state
        this.setState({infractionTags:infractionsToSend});
    }

    /*
    * @brief Handle when a driverid is changed, need to set the state to allow the render to be triggered
             and forward the result on to the class that opened the card
    */
    driverIDChanged(_data) {
          console.log("Changed driverid: ",_data.driverid);
          this.setState({name:_data.driverid})
        // //Notify the parent classes of the changes:
        if(this.props.driverChange){
            // console.log("Pass to calling function ");
            this.props.driverChange({
                cardID: this.props.cardID,
                infractionID:this.props.infractionID,
                infractionType: this.props.infractionType,
                name: _data.driverid,
                driverID: _data.driverid,
            });   
        }
        
    }

    /*
    * @brief Options button is pressed, 
    */
    hamburgerToggle(){
        // console.log("Click on the burger");
        if(this.state.bShowFront){ this.setState({bShowFront:false}); }
        else{ this.setState({bShowFront:true}); }
    }

    /*
    * @brief Render the content of the card:
    */        
    render() {
        
        //Handle what happens when the user click off the card
        const confirmClose = () => {
            // console.log("InfractionTags: ",this.state.infractionTags);
            if( this.state.infractionTags && 
                this.state.infractionTags.length>0){ //make sure there is at least one tagged infractions 
                    this.props.handleClose();
                    return;                   
            }
            //if not display a message to the user:
            if (window.confirm("Please select a clip type before closing the notecard?")) {}
        }//end confirmClose
        
        const style = {}; //Style (legacy) to pass to the modal, have not changed to prevent change in notecard size
        if (this.props.scale && this.props.scale !== 1) {     style.transform = 'scale(' + this.props.scale + ')';  }

        let styleOverride = {};
        // console.log("OpenCard: ",this.state,this.props);
        return (
            <div className="expanded-card" id='expanded-card-id' >
                <ReactModal isOpen={this.state.shown} className="modal-dialog expanded-card-modal" ariaHideApp={false}
                            shouldCloseOnOverlayClick={true}
                            //  onRequestClose={ this.props.handleClose || (() => {console.log("Requested close");})}
                            onRequestClose={confirmClose}
                >
                    <div className="modal-content" with_hw= {'display'} style={style}>
                        {/* Construct the display of the front side of the notecard: */}   
                        <div className="modal-body expanded-card" style={styleOverride}>
                            {this.state.bShowFront? 
                                <React.Fragment>
                                    <NCTitle props = {this.props} timeOfDay= {this.state.timeOfDay} severity={this.state.severity} onClick={this.hamburgerToggle}/>
                                    <div className="card-upper">
                                        <div className="card-basics">  
                                            <NCLeftTop  props = {this.props} driverName={this.state.name} top3Data={this.state.top3Data} infractionTags={this.state.infractionTags}
                                                         onDriverChange={this.driverIDChanged} videoURL = {this.state.video} timeOfDay= {this.state.timeOfDay}/>
                                            <NCLeftBottom props = {this.props} infractionTags={this.state.infractionTags} infractionTagList={this.state.infractionTagsList}
                                                            onTagChange={this.infractionTagChanged} />
                                            
                                        </div>
                                        <NCVideo props = {this.props} videoURL = {this.state.video} startTime={this.state.startTime}/>                                   
                                    </div>  
                                    <NCComments props = {this.props} comments = {this.state.comments} username={this.state.currentUsername} onUpdate={this.updateComment}/> 
                                    <NCFooter props = {this.props} downloadLink={this.state.dlLink} linkedVidID={this.state.linkedVidID} infractionTags={this.state.infractionTags}
                                        onTagChange={this.infractionTagChanged} onUpdateComment={this.updateComment}/>

                                </React.Fragment>                                
                                :
                                <NCBack props = {this.props} timeOfDay={this.state.timeOfDay} gpsLoc={{lat:this.state.lat, lon:this.state.lon}} metadata={this.state.metadata} 
                                    infractionTags={this.state.infractionTags} gpsmap={this.state.gpsmap} speed= {this.state.speed}
                                    onToggle={this.hamburgerToggle} /> 
                            }
                        </div> 
                         
                    </div>
                </ReactModal>
            </div>
        );
    } //end of render()
} //end of the ExpandedCardClass
   
