
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';

import './geoFenceCard.css';

import { displayInfraction, displayWorkflow, SEVERITIES, severityCompare, delayPromise, getStreamURL,translateUserName,getAudioAlertIcon } from './Util.js';

import { DropDown } from './BaseComponents.js';

import { setupPerf } from './Perf.js';

import { List } from 'react-virtualized';

import * as moment from 'moment';


import hamburger from './assets/hamburger-menu-icon-small.png';

import { ApiCaller } from './ApiCaller.js';

import { Auth, API } from 'aws-amplify';

import { motion } from "framer-motion"

import EditIcon     from './assets/pencil-edit.svg';
import EditableLabel from 'react-inline-editing';
import { Multiselect } from 'react-widgets'

import 'react-widgets/styles.css';


const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/
export class GeoFenceCard extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.submitNewAction = this.submitNewAction.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
        this.deleteRegion = this.deleteRegion.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.updateActionSQL = this.updateActionSQL.bind(this);
        this.editAction  = this.editAction.bind(this);
        // this.handleCommentEdit = this.handleCommentEdit.bind(this, 'comment');
        this.state = {
            actionList: this.props.events_monitored||[],
            shown: false,
            timestamp:null,
            currentUsername:null,
            startTime : new Date(),
            regionName: this.props.name,
            id:this.props.id,
            addFormOpen: false,
            dataToEdit: null,
            mRegionConflict: 0,
        };
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    componentDidMount() {
        // console.log("Open region: ",this.props, this.state.actionList,this.state.actionList.length);
        window.setTimeout(() => {
            this.setState({shown: true});
        }, EXPANDED_CARD_SHOW_LATENCY);
        //auto open the configuration if no actions are selected
        if(this.state.actionList.length===0){
            this.setState({addFormOpen:true});
        }
        
    }
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        // Create the API call + promise wrapper that will get the video highlights
        // console.log("Get card data for: ",this.props.infractionID);
       
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        //   console.log("GeoFence data: ",data,this.props);
 
    }
    //Callback when the delete region button is pressed
    deleteRegion(){
        if(window.confirm("Please confirm that you wish to delete this GeoFenced Region")){
            Auth.currentSession().then(
                (auth) => {
                  let myInit = {
                    body: {
                      token: auth.idToken.jwtToken,
                      apiName: "handleGeoFence",
                      mode: "deleteRegion",
                      id:this.props.id,
                    }
                  };
                  return API.post("AuthLambda", "/apiRouter", myInit);
            });
            this.props.handleClose(this.state,true);
        }
    }
    
    //Called with the delete button is pressed on the action fields, must remove from the local copy and update SQL
    deleteAction(_data){
        // console.log("action deleted: ",_data);

        const actionList = this.state.actionList||[];
        let actionsToSubmit = [];
        //push each json object onto the display list
        (actionList||[]).forEach(action_=>{
            if(action_.type !== _data.type){
                actionsToSubmit.push(action_);
            }
        });
        this.setState({actionList:actionsToSubmit})
        this.updateActionSQL(actionsToSubmit);

    }
    //callback when a new action has been added
    submitNewAction(_data){
        // console.log("actions submitted: ",_data);
        let actionsToSubmit = [];
        //Create json object for each type
        (_data.type||[]).forEach(type_=>{
            let newAction = {
                type: type_,
                action: _data.action,
                assets: _data.assets,
                contact: _data.contact, 
                bounds: null,
                // assetCount:_data.assetCount,
            }
            //add the type specific bounds:
            switch(type_){
                case 'Speed':
                    newAction.bounds= _data.speeding;
                    break;
                case 'Dwell':
                    newAction.bounds= _data.dwell;
                    break;
                case 'AssetCount':
                    newAction.bounds= _data.assetCount;
                    break;
            }

            actionsToSubmit.push(newAction)
        })
        const actionList = this.state.actionList||[];
        //push each json object onto the display list
        (actionList||[]).forEach(action_=>{    
            //make sure it is unique        
            let bFound = false;
            (actionsToSubmit||[]).forEach(elem_=>{
                if(elem_.type === action_.type){
                    bFound = true;
                }
            });
            if(!bFound){
                actionsToSubmit.push(action_);
            }
            
        });
        // console.log("Actions To submit: ",actionsToSubmit)
        this.setState({actionList:actionsToSubmit})

        // this.setState(prevState => {
        //     const actionList = prevState.actionList||[];
        //     //push each json object onto the display list
        //     (actionToSubmit||[]).forEach(action_=>{
        //         actionList.push(action_);
        //     });
        //     return {actionList: actionList};
        // });


        this.updateActionSQL(actionsToSubmit);
         
    }
    //Update the SQL table's stored actions
    updateActionSQL(_data){
        Auth.currentSession().then(
            (auth) => {
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  apiName: "handleGeoFence",
                  mode: "addAction",
                  regionName: this.state.regionName,
                  id:this.props.id,
                  actions: JSON.stringify(_data),
                }
              };
              return API.post("AuthLambda", "/apiRouter", myInit);
        });
    }

    handleFocusOut(text) {
        // console.log("Title Changed: ",text);
        let regionName = this.state.regionName;

        try {
            if(this.state.regionName!==text){ //Were changes made
                //Check if the values is in the list of known sites:
                if(this.props.regionList.includes(text.toLowerCase())){
                    // console.log("Found in list");
                    this.setState({mRegionConflict:1}); //Indicate that we are in conflict on the site name
                    return;
                }
                if(text.length > 1){
                    // console.log("Setting new title: ",text);
                    this.setState({regionName:text,mRegionConflict:0});            
                    this.props.handleClose({id:this.state.id,regionName:text},false,'name');
                }else{
                    this.setState({regionName:this.state.regionName,mRegionConflict:0});
                }
            }
            this.setState({mRegionConflict:0}); // reset the warning message
        } catch (error) {
        }
    }

    //Trigger edit on an action
    editAction(_data){
        // console.log("edit this: ",_data);
        this.setState({addFormOpen:true,dataToEdit:_data});
    }
    //Trigger edit on an action
    validateEdit(_data){
        // console.log("edit this: ",_data);
        return (_data.length > 0 && _data.length < 64);
    }
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        
        //Handle what happens when the user click off the card
        const confirmClose = () => {
            // console.log("Close requested?",this.state)
            //Prompt the user to delete the region if there are no actions:
            // if(this.state.actionList.length===0){
            //     if(window.confirm("Regions without associated actions should be deleted, Would you to delete this GeoFenced Region")){
            //         Auth.currentSession().then(
            //             (auth) => {
            //               let myInit = {
            //                 body: {
            //                   token: auth.idToken.jwtToken,
            //                   apiName: "handleGeoFence",
            //                   mode: "deleteRegion",
            //                   id:this.props.id,
            //                 }
            //               };
            //               return API.post("AuthLambda", "/apiRouter", myInit);
            //         });
            //         this.props.handleClose(this.state,true);
            //     }
            // }else{
                this.props.handleClose(this.state);
            // }
        }//end confirmClose
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        //Is the user allowed to edit the content
        let bEditAllowed = true;
        if(this.props.permissions && this.props.permissions==='read'){bEditAllowed=false;}

        //  console.log("List: ",this.state.actionList)
        let actionList = this.state.actionList.map((comment,index_) => 
            <Comment    {...comment} 
                        key={index_} 
                        cardid={this.props.cardID} 
                        siteconfig = {this.props.siteconfig}
                        editAction={this.editAction} 
                        currentUser={this.state.currentUsername} 
                        index={index_} 
                        totalCount={this.state.actionList.length} 
                        deleteAction = {this.deleteAction}
                        bEditAllowed = {bEditAllowed}
            />
        ).reverse();
        // console.log("display: ",actionList,this.state.addFormOpen)
        if(this.state.addFormOpen){actionList=null;}


        return (
            <div className="geofence-card" style={style}>
                <ReactModal isOpen={this.state.shown} className="modal-dialog geofence-card-modal"
                            shouldCloseOnOverlayClick={false}
                            // onRequestClose={confirmClose}
                >
                    <div className="geofence-modal" style={style} >
                        {/* <div className='c'>Card Data</div> */}
                        <div className = 'gf-title'>
                             <EditableLabel 
                                text={this.state.regionName}
                                labelClassName='title2'
                                inputClassName='title2'
                                inputWidth='100%'
                                inputHeight='auto'
                                inputMaxLength={50}
                                labelFontWeight='bold'
                                inputFontWeight='bold'
                                // onFocus={this._handleFocus}
                                onFocusOut={this.handleFocusOut}
                            /> 
                            {this.state.mRegionConflict===1 && <div className = 'sc-title-warning'>Duplicate region name, please change</div>}
                        </div>
                        
                        <div className="action-list">
                            {actionList}
                        </div>
                        
                        <div className="gf-footer">
                           
                            {(this.state.addFormOpen || !bEditAllowed)?null:
                                <motion.button  className="btn btn-danger delete-region" 
                                    onClick={this.deleteRegion} 
                                > Delete Region</motion.button>
                            }
                            {this.state.addFormOpen?null:<div className="blank-spacer"  />}

                            {!bEditAllowed?null:
                                <AddAction addFormOpen={this.state.addFormOpen}
                                    disableEdits={this.props.noEdit}
                                    currentActions ={this.state.actionList}
                                    dataToEdit = {this.state.dataToEdit}
                                    assets = {this.props.assets}
                                    siteconfig = {this.props.siteconfig}
                                    // setComment={(comment)=>this.setState({addingComment: comment})}
                                    formOpen={(bOpen)=>{
                                        this.setState({addFormOpen: bOpen,dataToEdit:null});
                                        }
                                    }
                                    cancelComment={this.cancelComment}
                                    regionConflict= {this.state.mRegionConflict}
                                    submitAction={this.submitNewAction}
                                /> 
                            }
                            {this.state.addFormOpen?null:
                                <motion.button  className="btn btn-success close-region" 
                                        onClick={confirmClose} 
                                    > Close</motion.button>
                            }
                             
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    }
}

/*
* @brief Configure an individual action
*/
class Comment extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { 
            showEdit: false ,
            originalText: '',
            text: '',
        };
    }
    /*
    * @brief Called when created
    */
    componentDidMount() {
        this.setState({showEdit:false,originalText:this.props.text,text:this.props.text});
        // console.log("Comment props: ",this.props);
    }
   
    /*
    * @brief Draw the comment content
    */
    render() {
        

        const updateCommentConfirm = () => {
            try{
               
                // this.props.text = this.state.text;
                const oldText = this.state.originalText;
                const newText = this.state.text;
                // Send request to modify the SQL table:
                this.setState({ showEdit: false , originalText: this.state.text});
                
            } catch (error) {   }

            this.props.updateComment(this.state);
        } //end updateCommentConfirm

        let contactList = this.props.contact.split(',');

        

        let boundsDiv = null;
        switch(this.props.type){
            case 'Speed':
                //Check for speed warnings:
                let speedWarning = null;
                let speedUnit = "km/h";
                // console.log("Props: ",this.props);
                if(this.props.siteconfig && this.props.siteconfig[0]){
                    if( this.props.siteconfig[0].speedlimit){
                        if(this.props.bounds.speed > this.props.siteconfig[0].speedlimit)
                        speedWarning = "Speed set above the Site speed limit";
                    }
                    //set a warning if the speed is not over the limit and the time is
                    // console.log("Time check: ",speedWarning,this.props.siteconfig[0].speedtime,this.props.bounds)
                    if(!speedWarning && this.props.siteconfig[0].speedtime ){
                        if(this.props.bounds.time > this.props.siteconfig[0].speedtime)
                        speedWarning = "Time set above the Site time limit";
                    }
                    if(this.props.siteconfig[0].site){
                        //Show the unit of speed as mph if in tempe or pintovalley
                        if(this.props.siteconfig[0].site.toLowerCase().includes('tempe') 
                            ||this.props.siteconfig[0].site.toLowerCase().includes('pintovalley') 
                            ||this.props.siteconfig[0].site.toLowerCase().includes('demo_group') ){
                            speedUnit = "mph";
                        }
                    }
                }

                boundsDiv = <div className="action-line">
                            <div>Speed: </div>
                            <div>{this.props.bounds.speed+" "+speedUnit}</div> 
                            <div>Time: </div>
                            <div>{(this.props.bounds.time||0)+" secs"}</div> 
                            <div style={{color: "#D8000C"}}>{speedWarning}</div> 
                        </div>
                break;
            case 'Dwell':
                    boundsDiv = <div className="action-line">
                                <div>Min: </div>
                                <div>{this.props.bounds.min+" mins"}</div> 
                                <div>Max: </div>
                                <div>{this.props.bounds.max+" mins"}</div> 
                            </div>
                    break;
            case 'AssetCount':
                boundsDiv = <div className="action-line">
                            <div>Min: </div>
                            <div>{this.props.bounds.min}</div> 
                            <div>Max: </div>
                            <div>{this.props.bounds.max}</div> 
                        </div>
                break;

        }


        let textElement =   <div className="action-element">
                                <div className="action-line">
                                    <div>Type: </div>
                                    <div>{this.props.type}</div>
                                </div>
                                {boundsDiv}
                                <div className="action-line">
                                    <div>Assets: </div>
                                    <div>{this.props.assets.include}</div> 
                                    {this.props.assets.exclude.length>0? <div>excluding</div> :null}
                                    {this.props.assets.exclude.length>0? <div>{this.props.assets.exclude}</div>:null}
                                </div>                               
                                <div className="action-line">
                                    <div>Contact: </div>
                                    <div className="contact-list">{contactList.join(', ')}</div>
                                </div>
                            </div>
        //Construct the actual return from the render method
        let style={backgroundColor: '#00AEEF33'} //change the color to show disabled   
        if(this.props.index % 2){
            style.backgroundColor = '#00AEEF10' //change the color to show disabled   
        }
        return (
            <div className="monitored-action" key={this.props.timestamp} style={style}>
                {/* {usernameDiv} */}
                {/* <div className="comment-timestamp"><pre>{moment(this.props.timestamp).format(CARD_COMMENT_DATE_FORMAT)}</pre></div> */}
                
                {textElement}
                {this.props.bEditAllowed===false? null: <motion.button className="btn btn-danger delete-action" onClick={()=>{this.props.deleteAction(this.props)}}>Delete</motion.button>}
                {this.props.bEditAllowed===false? null: <motion.button className="btn btn-warning edit-action" onClick={()=>{this.props.editAction(this.props)}}>Edit</motion.button>}
                
                {/* {!this.props.disableEdits && 
                        <motion.button src={EditIcon} className="edit-comment-button" onClick={()=>{
                            this.setState({ showEdit: true,originalText:this.props.text, text:this.props.text });
                            }} 
                        style={EditStyle} />} */}
            </div>
        );
    }
}

/*
* @brief A helper Component for allowing the user to add comments
*/
class AddAction extends PureComponent {

    constructor(props) {
        super(props);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleActionChange = this.handleActionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openEditConfig = this.openEditConfig.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.state = { 
            disabledTypes: [],
            selectedType: [],
            selectedAction: 'Email',
            addContact:'',
            contactList:[],
            assetList:{
                include:['All'],
                exclude:[],
            },
            startedOnce:false,
            showForm: this.props.addFormOpen,
            dwell:{
                min:'',
                max:'',
            },
            assetCount:{
                min:'',
                max:'',
            },
            speeding:{
                speed:'',
                time:'',
            },
        };
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        // updateFilters(){
        
         if (this.props.filters === newProps.filters ) {
            //  console.log("New props on card?: ",newProps);
            //make sure we are passing new data, if not the don't update, otherwise this will overwrite the user changes
            if(newProps.dataToEdit === this.props.dataToEdit){
                return;
            }
            // console.log("New props on card?: ",newProps);
            
            if(newProps.dataToEdit){
                let {selectedType, contactList,assetList, speeding, dwell, assetCount} =this.state;
                selectedType = [];
                contactList=[];
                dwell={min:'', max:'', }
                assetCount={min:'',max:'', }
                speeding={speed:'',time:10, }
                
                // const selectedType = this.state.selectedType;
                selectedType.push(newProps.dataToEdit.type)
                assetList.include = newProps.dataToEdit.assets.include.split(',').filter(String);
                assetList.exclude = newProps.dataToEdit.assets.exclude.split(',').filter(String);
                // console.log("Set to ",newProps.dataToEdit.contact);
                contactList = newProps.dataToEdit.contact.split(',').filter(String);
                 //add the type specific bounds:
                switch(newProps.dataToEdit.type){
                    case 'Speed':
                        speeding.speed = newProps.dataToEdit.bounds.speed;
                        speeding.time = newProps.dataToEdit.bounds.time;
                        break;
                    case 'Dwell':
                        dwell.min = newProps.dataToEdit.bounds.min;
                        dwell.max = newProps.dataToEdit.bounds.max;
                        break;
                    case 'AssetCount':
                        assetCount.min= newProps.dataToEdit.bounds.min;
                        assetCount.max= newProps.dataToEdit.bounds.max;
                        break;
                }
                //Disable all but our current type:
                let knownTypes=['Enter','Exit','Speed','Dwell','AssetCount'];
                let disabledTypes=[];
                for(const action_ of knownTypes){
                    if(newProps.dataToEdit.type !== action_){
                        disabledTypes.push(action_);
                    }
                    
                };
                //Update the class state to force a refresh
                this.setState({ selectedType:selectedType, contactList:contactList,
                                assetList:assetList, speeding:speeding, dwell:dwell, 
                                assetCount:assetCount, disabledTypes:disabledTypes});
                // this.setState({selectedType:selectedType, contactList:contactList, assetList:newProps.assets, speeding:speeding, dwell:dwell, assetCount:assetCount});
            }

             this.setState({showForm:newProps.addFormOpen, startedOnce: true});
         }
    }

    handleTypeChange(changeEvent) {
        try {
            // console.log("Selected: ",changeEvent.target.value);
            let types = [...this.state.selectedType];
            if(types.includes(changeEvent.target.value)){
                types = types.filter(item => item !== changeEvent.target.value);
            }else{
                types.push(changeEvent.target.value);
            }
            //Update the stored values, triggers the reset
            this.setState({
                selectedType: types
            });
        } catch (error) {
            console.log("Failed to update checkboxes? ",error)
        }
       
    }
    handleActionChange(changeEvent) {
        // console.log("Selected: ",changeEvent.target.value);
        this.setState({
            selectedAction: changeEvent.target.value
        });
    }
    handleCancel(){

        //Reset the states
        this.setState({ selectedType:[], contactList:[],
            assetList:{include:['All'],exclude:[]}, speeding:{speed:'',time:10, }, dwell:{min:'', max:'', }, 
            assetCount:{min:'',max:'', }, disabledTypes:[],
            showForm:false});
        this.props.formOpen(false);                            
    }
    handleSubmit(){

        // const localState = this.state;
        // localState.contactList
        // console.log("Submit hit with state: ",this.state);

      
        //Clicked submit - need to sanity check each value
        try {
            let errorsToFix=[];
            try{
                if(this.state.speeding.speed.length>0){
                    if(this.state.speeding.speed <= 0){
                        errorsToFix.push({text:"- Speed must be a positive non-zero value"})
                    }
                    if(this.state.speeding.speed > 150){
                        errorsToFix.push({text:"- Speed must be less than 150 kmph"})
                    }
                }else{
                    if(this.state.selectedType.includes('Speed')){
                        errorsToFix.push({text:"- Speed cannot be empty"})
                    }
                }
                
            }catch(e){}
            try{
                if(this.state.dwell.min.length>0 || this.state.dwell.max.length>0){
                    try{
                        if(this.state.dwell.min.length>0 && this.state.dwell.min <= 0){  errorsToFix.push({ text:"- Min Dwell time must be greater than 0"})  }
                    }catch(e){}
                    try{
                        if(this.state.dwell.max.length>0 && this.state.dwell.max <= 0){ errorsToFix.push({ text:"- Max Dwell time must be greater than 0" }) }
                    }catch(e){}
                        try{
                        if(this.state.dwell.max < this.state.dwell.min){  errorsToFix.push({ text:"- Max Dwell time must be greater than Min Dwell time" })  }
                    }catch(e){}
                }
                else{
                    if(this.state.selectedType.includes('Dwell')){
                        errorsToFix.push({text:"- Must specify a min Dwell time"})
                    }
                }
                
            }catch(e){}
            try {
                if(this.state.assetCount.min.length>0 || this.state.assetCount.max.length>0)
                {
                    try{
                        if(this.state.assetCount.min.length>0 && this.state.assetCount.min <= 0){
                            errorsToFix.push({ text:"- Min Asset Count must be greater than 0" })
                        }
                    }catch(e){}
                    try{
                        if(this.state.assetCount.max.length>0 && this.state.assetCount.max <= 0){
                            errorsToFix.push({ text:"- Max Asset Count time must be greater than 0" })
                        }
                    }catch(e){}
                    try{
                        if(this.state.assetCount.max.length>0 && this.state.assetCount.max < this.state.assetCount.min){
                            errorsToFix.push({ text:"- Max Asset Count must be greater than Min Asset Count" })
                        }
                    }catch(e){}
                }
                else{
                    if(this.state.selectedType.includes('AssetCount')){
                        errorsToFix.push({text:"- Must specify a min Asset Count"})
                    }
                }
            } catch (error) {
                
            }
            
            try{
                // console.log("contact length: ",this.state.contactList.length);
                //only check that the list is valid if not empty:
                if(this.state.contactList.length > 0){
                    const regExp = new RegExp(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/);
                    if(!regExp.test(this.state.contactList)){
                        // console.log("Email test => ", result);
                        errorsToFix.push({ text:"- Contact list must contain valid email addresses (username@hostname.com)" })
                    }
                }
                
                
            }catch(e){console.log("Failed to check contact list: ",e)}
                
            if(errorsToFix.length>0){
                let toFixString = "\n";
                for(const elem_ of errorsToFix){
                    toFixString += elem_.text+"\n";
                }
                if(window.confirm("Please correct the following entries: "+toFixString)){
                }
    
            }else{
                //Send the data to the card:
                this.props.submitAction({
                    type: this.state.selectedType,
                    action: this.state.selectedAction,
                    assets: {
                        include: this.state.assetList.include.join(","),
                        exclude: this.state.assetList.exclude.join(","),
                    },
                    contact: this.state.contactList.join(","),  
                    dwell:this.state.dwell,
                    assetCount:this.state.assetCount,
                    speeding:this.state.speeding,
                });
                this.setState({showForm:false})
                this.props.formOpen(false);
                
            }
        } catch (error) {
            console.log("Failed submit: ",error)
        }

        
    }
    resetState(){

    }
    openEditConfig(){
        if(!this.props.disableEdits){
            // console.log("Existing: ",this.props.currentActions,this.props.currentActions[0])
            // let disabledTypes = [...this.state.disabledTypes]
            //Fill in the state with the current actions from the card:
            let disabledTypes=[];
            for(const action_ of this.props.currentActions){
                // console.log("Action: ",action_);
                disabledTypes.push(action_.type);
            };
            this.setState({ selectedType:[], contactList:[],
            assetList:{include:['All'],exclude:[]}, speeding:{speed:'',time:10, }, dwell:{min:'', max:'', }, 
            assetCount:{min:'',max:'', }, disabledTypes:[],
            showForm:false});    

            if(disabledTypes.length>=5){
                this.setState({ disabledTypes:disabledTypes})
            }else{
                this.setState({showForm:true, disabledTypes:disabledTypes})
                this.props.formOpen(true)
            }
            
            
        }
    }

    render() {
        //Style the edit button based on the currently selected actions:
        let EditButtonName = "btn btn-primary add-comment"
        if(this.props.currentActions && this.props.currentActions.length >=5){
            EditButtonName = "btn btn-light add-comment"
        }

        const adding = this.props.comment !== null;
        let returnDiv = <motion.button  className={EditButtonName} 
            onClick={this.openEditConfig} 
            style={this.props.disableEdits?{opacity:"0.2"}:{opacity:"1"}} > Edit Configuration</motion.button>

        // console.log("Render call: ",this.state.selectedType, this.state, this.state.assetList.exclude, this.state.assetList.exclude.filter(String));
        let allAssets = [...this.props.assets]
        allAssets.unshift("All")


        //Check for speed warnings:
        let speedWarning = null;
        if(this.state.selectedType.includes('Speed')){
            // console.log("Props: ",this.props);
            if(this.props.siteconfig && this.props.siteconfig[0] && this.props.siteconfig[0].speedlimit){
                if(this.state.speeding.speed > this.props.siteconfig[0].speedlimit)
                speedWarning = "Speed set above the Site speed limit";
            }
            //set the warning that the time is above threshold, if the speed warning is not displayed
            if(!speedWarning && this.props.siteconfig && this.props.siteconfig[0] && this.props.siteconfig[0].speedtime ){
                if(this.state.speeding.time > this.props.siteconfig[0].speedtime)
                speedWarning = "Time set above the Site time limit";
            }
        }
        
        //  console.log("Test:" ,this.state,this.props)        
        
        if(this.state.showForm){
            returnDiv = 
            <div className="add-action">
                    <div className="adding-comment">
                        <div className="type-group">
                            <div className="title2">Type: </div>
                            <div className={this.state.disabledTypes.includes('Enter')?"dwelldisable":"dwell"}> 
                                <div className="radio">
                                        <label>
                                            {this.state.disabledTypes.includes('Enter')?
                                                <input type="checkbox"  value="Enter" disabled />
                                            :
                                                <input type="checkbox"  value="Enter" checked={this.state.selectedType.includes('Enter')}  onChange={this.handleTypeChange} />
                                            }
                                            Enter
                                        </label>
                                </div>
                            </div>
                            <div className={this.state.disabledTypes.includes('Exit')?"dwelldisable":"dwell"}> 
                                <div className="radio">
                                        <label>
                                            {this.state.disabledTypes.includes('Exit')?
                                                <input type="checkbox"  value="Exit" disabled />
                                            :
                                                <input type="checkbox"  value="Exit" checked={this.state.selectedType.includes('Exit')}  onChange={this.handleTypeChange} />
                                            }
                                            
                                            Exit
                                        </label>
                                    
                                </div>
                            </div>
                            
                            
                            <div className={this.state.disabledTypes.includes('Dwell')?"dwelldisable":"dwell"}> 
                                <div className="radio">
                                        <label>
                                            {this.state.disabledTypes.includes('Dwell')?
                                                <input type="checkbox"  value="Dwell" disabled />
                                            :
                                                <input type="checkbox"  value="Dwell" checked={this.state.selectedType.includes('Dwell')}  onChange={this.handleTypeChange} />
                                            }
                                            
                                            Dwell
                                        </label>
                                    
                                </div>                  
                                {this.state.selectedType.includes('Dwell') ? 
                                    <input type="text" className="entry-field" value={this.state.dwell.min}  placeholder="Min"
                                        onChange={(e) => {this.setState({dwell:{ min: e.target.value, max: this.state.dwell.max}})}}
                                    /> :null}

                                {this.state.selectedType.includes('Dwell') ?
                                        <input type="text" className="entry-field" value={this.state.dwell.max}  placeholder="Max"
                                            onChange={(e) => {this.setState({dwell:{ min: this.state.dwell.min, max: e.target.value}})}}
                                        /> 
                                        :null}
                                {this.state.selectedType.includes('Dwell') ?
                                        <div className="title-units">minutes</div>  
                                        :null}
                            </div>
                            
                           
                            <div className={this.state.disabledTypes.includes('Speed')?"dwelldisable":"dwell"}>

                                <div className="radio">
                                
                                        <label>
                                            {this.state.disabledTypes.includes('Speed')?
                                                <input type="checkbox"  value="Speed"  disabled/>
                                            :
                                                <input type="checkbox"  value="Speed" checked={this.state.selectedType.includes('Speed')}  onChange={this.handleTypeChange} />
                                            }
                                            Speed
                                        </label>
                                </div>
                                {this.state.selectedType.includes('Speed') ? 
                                    <input type="text" className="entry-field" value={this.state.speeding.speed}  placeholder="Speed"
                                        onChange={(e) => {this.setState({speeding:{ speed: e.target.value, time: this.state.speeding.time}})}}
                                    /> :null}
                                {this.state.selectedType.includes('Speed') ? 
                                    <input type="text" className="entry-field" value={this.state.speeding.time}  placeholder="Time"
                                        onChange={(e) => {this.setState({speeding:{ speed: this.state.speeding.speed, time: e.target.value}})}}
                                    /> :null}
                                {this.state.selectedType.includes('Speed') ?
                                        <div className="title-units">seconds</div>  
                                        :null}
                                {this.state.selectedType.includes('Speed') ?
                                        <div className="title-comments">{speedWarning}</div>  
                                        :null}
                                
                            </div>
                            
                            
                                
                            <div className={this.state.disabledTypes.includes('AssetCount')?"dwelldisable":"dwell"}>

                                <div className="radio">
                                    <label>
                                        {this.state.disabledTypes.includes('AssetCount')?
                                            <input type="checkbox"  value="AssetCount" disabled />
                                            :
                                            <input type="checkbox"  value="AssetCount" checked={this.state.selectedType.includes('AssetCount')}  onChange={this.handleTypeChange} />
                                        }
                                        
                                        Asset Count
                                    </label>
                                </div>
                                {this.state.selectedType.includes('AssetCount') ? 
                                    <input type="text" className="entry-field" value={this.state.assetCount.min}  placeholder="Min"
                                    onChange={(e) => {this.setState({assetCount:{ min: e.target.value, max: this.state.assetCount.max}})}}
                                    />  :null}
                                {this.state.selectedType.includes('AssetCount') ? 
                                    <input type="text" className="entry-field" value={this.state.assetCount.max}  placeholder="Max"
                                    onChange={(e) => {this.setState({assetCount:{ min: this.state.assetCount.min, max: e.target.value}})}}
                                    /> :null}
                                
                            </div>
                            
                            
                            
                        </div>
                        {/* <div className="spec">
                            <div className="title-wide">Asset Count</div>
                                <input type="text" className="entry-field" value={this.state.assetCount.min}  placeholder="Min"
                                    onChange={(e) => {this.setState({assetCount:{ min: e.target.value, max: this.state.assetCount.max}})}}
                                /> 
                                <input type="text" className="entry-field" value={this.state.assetCount.max}  placeholder="Max"
                                    onChange={(e) => {this.setState({assetCount:{ min: this.state.assetCount.min, max: e.target.value}})}}
                                /> 
                            
                        </div> */}
                        <div className="spec-tall">
                            <div className="title-wide">Assets</div>
                           
                            <Multiselect className="entry-field-wide"
                                dropUp
                                // data={["All","E3100", "E3101", "E3102","E3103", "E3104", "E3105","E3106", "E3107", "E3108"]}
                                data={allAssets}
                                value={this.state.assetList.include}
                                onChange={(value) => {this.setState({assetList:{ include: value, exclude: this.state.assetList.exclude }})}}
                                // defaultValue={}
                                
                            />
                            <div className="title">excluding</div>
                            <Multiselect className="entry-field-wide"
                                dropUp
                                data={[...this.props.assets]}
                                value={this.state.assetList.exclude}
                                onChange={(value) => {this.setState({assetList:{ include: this.state.assetList.include, exclude: value}})}}
                                // defaultValue={}
                            />                          
                        </div>
                        
                     
                        <div className="spec-2r">
                            <div className="title-wide">Contact</div>
                            <input type="text" id="emailInput" className="entry-field-wide"
                                value={this.state.addContact}
                               onChange={(e) => {this.setState({addContact:e.target.value})}}/>
                            <button className="btn btn-success" 
                                onClick={()=>{
                                    this.setState({
                                        contactList: [...this.state.contactList, this.state.addContact],  // select new option
                                        addContact: '',
                                    });
                                }}
                            >Add</button>

                            <Multiselect className="entry-field-wide2"
                                dropUp
                                data={[...this.state.contactList]}
                                value={this.state.contactList}
                                onChange={(value) => {this.setState({contactList:value})}}
                                open={false}
                                // allowCreate="onFilter"
                                // allowCreate={true}
                                // defaultValue={}
                                onKeyPress={value=>{console.log("Key press",value,value.key)}}
                                onCreate={ value =>{
                                    // console.log("Add to list:" ,value);
                                    // console.log("New list? ",[...this.state.contactList, value])
                                    this.setState({
                                        contactList: [...this.state.contactList, value],  // select new option
                                    // people: [...people, newOption] // add new option to our dataset
                                    })
                                }}
                            />
                            
                        </div>
                        <div className="empty-bottom"/>                        
                        
                        
                    <div className="adding-buttons">
                        <button className="btn btn-danger" onClick={
                            this.handleCancel}>Cancel</button>
                        {/* <button className="btn btn-primary" 
                            onClick={this.handleSubmit}
                        >Submit</button> */}

                        {this.props.regionConflict===0 ?
                            <button className="btn btn-primary" 
                                onClick={this.handleSubmit}
                            >Submit</button>
                            :<button className="btn btn-secondary" disabled
                                onClick={this.handleSubmit}
                            >Submit</button>
                        }
                        
                    </div>
                </div>
            </div>
        }
        return returnDiv;
    }
}


