import React, { useState, useEffect} from 'react';
import {useLongPress} from '../Util/useLongPress.js';
import { CameraControlInterface } from './CameraControlInterface.js';

/**
 * Style and control the button that shows the review progress and asset name for the Live Tab
 * @param {*}: configuration object with callbacks and properties from the LiveAsset 
 * @returns 
 */
export const LiveButton = ({   onClick
                              ,onLongPress=()=>{console.log("onLongPress callback not defined");}
                              ,style
                              ,assetIndex
                              ,disableLongPress //(state enum: 0 - allowed+enabled, 1: allowed+disabled, 2: not allowed)
                              ,counts = {iUnviewed: 0,iViewed: 0, iTotal: 0}
                              ,...rest}) => {
                                
  const [cameraControl, setCameraControl] = useState(false); //Hold the state to show that the camera control should be shown

  //Add a hook to react to a long press on the button
  const longPressAttributes = useLongPress(
      (e) => {
        // onLongPress({id: assetIndex}); //trigger a callback when the long press occurs
        if(disableLongPress!==2){setCameraControl(true);} //enable the view fo the camera controller, if allowed
      }
      , 250); //define how long the press has to last to count as "long"

  //React to a change in the properties that are passed to the button
  useEffect(() => {
    try {
      if(rest.id === assetIndex){
        // console.log("Camera control updated: ", rest.id);
      } else{
        setCameraControl(false);
      }
    } catch (error) {
    }
  }, [rest.id]); //listen for the id property to be received

  //Handle what occurs when the button is clicked:
  function handleClick(_event) {
    // console.log("Button clicked");
    if (onClick) { //Only process the state if we have a defined onClick method
      onClick(_event); 
    }
  }
  //2 Render 
  return (
    <React.Fragment>
        <button className= 'asset-button' key={'asset-button'+assetIndex} 
          style={style}
          onClick={handleClick}
          {...rest}
          {...longPressAttributes}
        >
          <React.Fragment>
            <div className="tabtitle">
                {assetIndex}
                {counts.iViewed > 0? <span className="badge badge-default">{counts.iViewed}</span>:<span className="empty-badge"></span>}
                {counts.iUnviewed > 0?<span className="badge badge-success">{counts.iUnviewed}</span>:<span className="empty-badge"></span>}
            </div>
          </React.Fragment>


        </button>
        {/*If enabled, show the camera controller on the screen */}
        {cameraControl &&
          <CameraControlInterface        
            onClose={()=>{setCameraControl(null)}}
            assetid = {assetIndex}
            {...cameraControl}
            disabled = {disableLongPress===1} //is the state allowed+disabled, if yes then disable the control
            controlCallback={onLongPress}
          />
        }
    </React.Fragment>
  );
}
