import React, {  } from 'react';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

// import '../AdminTab/AdminUserManagement.css';
import './TrainControl.css';
import { EvalDriver } from './EvalDriver';
import { TrainDriver } from './TrainDriver';


/*
* @brief 
*/
export const TrainControl = ({ groupconfig,possibleFilters,userInfo,imgData }) => {

    //define the callbacks to handle setting the active view
    const [viewTypes, setViewTypes] = React.useState(['evaluate']);
    const [activeView, setView] = React.useState('users');
    let [driverCandidates,setDriverCandidates] = React.useState(null);
    let [isTrainingAllowed,setTrainEnabled] = React.useState(false);

    //List the buttons to be drawn
    
    //Fix non available button:
    if(!viewTypes.includes(activeView)){
        setView(viewTypes[0]);
    }

    //Handle data updates from the evaluation and training tabs
    //Keep the data localized on the parent so that switching between the tabs doesn't reset the state
    function onChange(_change){
        console.log("Change received: ",_change);
        switch(_change.type){
            case 'matches':{
                setDriverCandidates(_change.data)
            }break;
            case 'novisual':{
                setTrainEnabled(_change.data)
                if(_change.data){
                    setViewTypes(['evaluate','train']);
                }else{
                    setViewTypes(['evaluate']);
                }
            }break;        
        }//end switch
    }//end onChange



    //Define the returned rendered HTML:
    return (
        // <div className='AdminView'>
        <React.Fragment>
            <Tabs className='viewSelection'
                defaultActiveKey={activeView} unmountOnExit={true} mountOnEnter={true} 
                id='uncontrolled-tab-example' 
                    activeKey={activeView} 
                    onSelect={k => setView(k)}
            >
                {viewTypes.map((type) => {
                    return(  <Tab key={type} eventKey={type} title={type}/> )
                })} 
            </Tabs>
            <div className='selectedcontent'>
                {activeView==='evaluate' && 
                    <EvalDriver
                        groupconfig= {groupconfig}
                        possibleFilters = {possibleFilters}
                        userInfo = {userInfo}
                        onChange={onChange}
                        driverCandidates={driverCandidates}
                        imgData = {imgData}
                    />
                }
                {activeView==='train' && 
                    <TrainDriver
                        groupconfig= {groupconfig}
                        possibleFilters = {possibleFilters}
                        userInfo = {userInfo}
                        onChange={onChange}
                        imgData = {imgData}
                    />
                }
            </div>
            
        </React.Fragment>
        // </div>
    );
};



