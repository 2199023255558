import React from 'react';
import ReactEcharts from 'echarts-for-react';

import * as moment from 'moment';
import {EDGE3_PRIMARY_COLORS,EDGE3_SECONDARY_COLORS,EDGE3_TERTIARY_COLORS,commaizeNumber} from '../Util.js'

///////////////////////////////////////////////////////
// FunctionalComponents
///////////////////////////////////////////////////////
/**
 * @brief Fuctional component encapsulating a labeled checkbox
 */ 
const Checkbox = ({ label, value, onChange }) => {
    // Required return wraping a label and a checkbox
    return (
        <label className = "cbox">
            <input type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
    );
};

/**
 * Functional component encapsulating the Comparison checkbox
 */
const ComparisonCheckbox = ({className, onBoxSelected, boxState, view}) => {
    /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [checkedComparison, setCheckedComparison] = React.useState(false); // Internal state of the comparison checkbox

    /**
     * @brief Called when the comparison checkbox is checked or unchecked
     */
    const handleChangeComparison = () => {
        // Notify the dropdowns of filter selection
        onBoxSelected('comparison', {
            e3person: 'All',                        // staff filter not allowed
            secondaryAxis: !checkedComparison,
        });

        // Update the state with the change in the comparison filter
        setCheckedComparison(!checkedComparison); 
    };

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        // Ignore the initial state
        setCheckedComparison(boxState);
    },[boxState,view]) //listen for changes to dates

    // Return elements to render
    return (
        <div className = {className}>                 
        {view === 'resp' && <Checkbox                       
            label="Comparison"
            value={checkedComparison}
            onChange={handleChangeComparison}
        />}
        </div>
    );
};

/**
 * @brief Fuctional component encapsulating multiple mutually exclusive checkboxes
 */ 
const DateFilterCheckboxes = ({className, onBoxSelected, boxStates, view}) => {
     /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [checkedThisQuarter, setCheckedThisQuarter] = React.useState(false); // Internal state of the last week checkbox
    const [checkedLastMonth, setCheckedLastMonth] = React.useState(false);  // Internal state of the last month checkbox
    const [checkedThisMonth, setCheckedThisMonth] = React.useState(false);   // Internal state of the this month checkbox
    const [checkedThisYear, setCheckedThisYear] = React.useState(false);    // Internal state of the this year checkbox
  
    /**
     * @brief Called when the this quarter checkbox is checked or unchecked
     */
    const handleChangeThisQuarter = () => {
        // Only one filter can be set at a 
        // Check before set because local var doesn't update
        if(!checkedThisQuarter) { 
            setCheckedLastMonth(false);
            setCheckedThisMonth(false);
            setCheckedThisYear(false);

            // Notify the dropdowns of filter selection
            onBoxSelected('on',{
                startDate: moment().startOf('month').subtract(3,'months'), // shift the start date 3 months
                endDate: moment().startOf('month').subtract(1,'days'), // shift the end date to end of previous month
                checkedThisQuarter: true,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        } else {
            // Notify the parent of the box state
            onBoxSelected('off',{
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        }
        // Update the state with the change in the this quarter filter
        setCheckedThisQuarter(!checkedThisQuarter); 
    };

    /**
     * @brief Called when the last month checkbox is checked or unchecked
     */
    const handleChangeLastMonth = () => {
        // Only one filter can be set at a time
        // Check before set because local var doesn't update
        if(!checkedLastMonth) { 
            setCheckedThisQuarter(false);
            setCheckedThisMonth(false);
            setCheckedThisYear(false);

            // Notify the dropdowns of filter selection
            onBoxSelected('on',{
                startDate: moment().startOf('month').subtract(1,'days').startOf('month'), // shift the start date to start of the preivous month
                endDate: moment().startOf('month').subtract(1,'days'), // shift the end date to end of the previous month
                checkedThisQuarter: false,
                checkedLastMonth: true,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        } else {
            // Notify the parent of the box state
            onBoxSelected('off',{
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        }
        // Update the state with the change in the last month filter
        setCheckedLastMonth(!checkedLastMonth);
    };

    /**
     * @brief Called when the this month checkbox is checked or unchecked
     */
    const handleChangeThisMonth = () => {
        // Only one filter can be set at a time
        // Check before set because local var doesn't update
        if(!checkedThisMonth) { 
            setCheckedThisQuarter(false);
            setCheckedLastMonth(false);
            setCheckedThisYear(false);

            // Notify the dropdowns of filter selection
            onBoxSelected('on',{
                startDate: moment().startOf('month'), // shift the start date to start of the this month 
                endDate: null,  // clear the endDate
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: true,
                checkedThisYear: false,
            });
        } else {
            // Notify the parent of the box state
            onBoxSelected('off',{
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        }
        // Update the state with the change in the this month filter
        setCheckedThisMonth(!checkedThisMonth);
    };

    /**
     * @brief Called when the this year checkbox is checked or unchecked
     */
    const handleChangeThisYear = () => {
        // Only one filter can be set at a time
        // Check before set because local var doesn't update
        if(!checkedThisYear) { 
            setCheckedThisQuarter(false);
            setCheckedLastMonth(false);
            setCheckedThisMonth(false);
            
            // Notify the dropdowns of filter selection
            onBoxSelected('on',{
                startDate: moment().startOf('year'), // shift the start date to start of this year, 
                endDate: null,  // clear the endDate
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: true,
            });
        } else {
            // Notify the parent of the box state
            onBoxSelected('off',{
                checkedThisQuarter: false,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
            });
        }
        // Update the state with the change in the this year filter
        setCheckedThisYear(!checkedThisYear);
    };

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        setCheckedThisQuarter(boxStates.checkedThisQuarter);
        setCheckedLastMonth(boxStates.checkedLastMonth);
        setCheckedThisMonth(boxStates.checkedThisMonth);
        setCheckedThisYear(boxStates.checkedThisYear);
    },[boxStates, view]) //listen for changes to dates
  
    // Return elements to render
    return (
      <div className = {className}>                 
        <Checkbox                       
          label="Previous 3 Months"
          value={checkedThisQuarter}
          onChange={handleChangeThisQuarter}
        />
        {view === 'resp' && <Checkbox                       
          label="Last Month"
          value={checkedLastMonth}
          onChange={handleChangeLastMonth}
        />}
        {view === 'resp' && <Checkbox                       
          label="This Month"
          value={checkedThisMonth}
          onChange={handleChangeThisMonth}
        />}
        <Checkbox                       
          label="YTD"
          value={checkedThisYear}
          onChange={handleChangeThisYear}
        />
      </div>
    );
};

/**
 * @brief Helper function to maximum number of characters in a list of labels
 */ 
const getMaxChars = function(_xData) {
    let maxChars = 0;
    _xData.forEach(item => maxChars = Math.max(maxChars,item.length));
    return maxChars;
}; //end getMaxChars

// /**
//  * @brief Helper function to maximum value in a list of labels
//  */ 
// const getMaxValue = function(_yData) {
//     let maxValue = 0;
//     _yData.forEach(item => maxValue = Math.max(maxValue,item));
//     return maxValue;
// }; //end getMaxValue

/**
 * @brief Helper function to get the x-axis name from the filer settings
 */ 
const getXAxisTitle = function(_bin) {
    switch(_bin) {
        case 'Staff':
            return 'Staff Member';
        case 'Month':
            return 'Month';
        case 'Week':
            return 'Week';
        case 'Day':
            return 'Date';
        default:
            return _bin;
    } //end switch
}; //end getXAxisTitle

/**
 * @brief Helper function to trim data to the input min and max
 */ 
const trimData = function(_data, _xmin, _xmax, _smin, _smax){
    let data = {};
    // Loop over all dataseries
    for (const [key] of Object.entries(_data)) {
        // Handle the secondary axis
        if(key === 'xdatasecondary') {
            data[key] = _data[key] == null ? null : _data[key].slice(_smin,_smax); 
        } else{
            // Auto scale is disabled for multiple axes
            data[key] = _data[key] == null ? null : _data[key].slice(_xmin,_xmax);
        }
    }
    
    return data;
}

/**
 * @brief Helper function to configure an echarts option block with common styling
 */ 
const getCommonOptions = function(_title, _data, _bin, _staff, _xmin, _xmax) {
    // Get the offset of the x and y axes
    let xOffset = getMaxChars(_data.xdata);
    let yOffset = 6;//String(getMaxValue(_data.total)).length;

    // Compute local flags
    let bSecondary = _data.xdatasecondary != null;                    // We have a secondary axis
    let bHorizontal= bSecondary                                       // We do not want to rotate the axis
        || _bin === 'Week'
        || _data.xdata.length <= 15;   
    let bLong  = !bSecondary && (_data.xdata.length >= 42             // We have an axis with too much data
        || (_bin ==='Week' && _data.xdata.length >= 7));
    let bStaff = _staff != null && _staff !== 'All';                  // We have a staff filter
    let bSplitWeek = _bin === 'Week' && (bLong || 
        (bSecondary && _data.xdatasecondary.length >= 7));            // Split the week label into rows
    let bBigLabel = _bin === 'Staff';                                 // Increase the size of the label

    // Define an axis label formatter
    let weekFormatter = (value) => {
        return value.replace(/ – /g, "\n– ") 
    };

    // Define options shared across all charts
    let options = {
        title : {
            text: [_title 
                + (bStaff ? ` by ${_staff}` : '') 
                + (_bin === 'Staff' ? ' per Staff Member' : (bSecondary ? ' per Staff Member over Time' : ' over Time'))],
            left: 'center',
            backgroundColor: 'rgb(255, 255, 255)',
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            textStyle: {fontSize : 16},
        },
        legend: {
            z:4,
            top: '35',
            textStyle: {fontSize : 14},
            backgroundColor: 'rgb(255, 255, 255)',
        },
        grid: {
            top: 80,
            bottom: bSecondary ? xOffset*16.5 : (bSplitWeek ? 87.5 : (bHorizontal || bLong ? 67.5 : xOffset*8.75)),
            left: 80,
            right: 40,
        },
        xAxis:[{
            type: 'category',
            data: _data.xdata,
            name: (bSecondary ? "Staff Member and ": "") + getXAxisTitle(_bin),
            nameLocation: 'center',
            nameTextStyle: { fontWeight: 'bold', fontSize: 16 },
            nameGap: bSecondary ? xOffset*13.5 : (bSplitWeek ? 55 :(bHorizontal || bLong ? 40 : xOffset*6)),
            position: 'bottom',
            ...(bLong && {axisTick : {alignWithLabel: true}}),
            axisLabel: {
                fontSize: bBigLabel ? 18 : 16,
                ...(bSecondary && {fontWeight : 'bold'}),
                fontFamily: 'monospace',
                ...(!bLong && {interval: 0}),
                ...(bSecondary && {rotate : 90}),               //rotate primary axis vertical if secondary exists
                ...(!bHorizontal && !bLong && {rotate: 30}),    //apply 30 degree rotation when not horizontal
                margin: bSecondary ? 10 : (bHorizontal ? 15 : xOffset),
                ...(bSplitWeek && {formatter: weekFormatter}),
            }
        }],
        yAxis: {
            type: 'value',
            nameLocation: 'center',
            nameTextStyle: { fontWeight: 'bold', fontSize: 16 },
            nameGap: 30+yOffset*4,
            axisLabel: {
              fontSize: 16,
              fontFamily: 'monospace',
            }
        },
    };

    // Conditionally add the second axis
    if(bSecondary) {
        options.xAxis.push({
            type: 'category',
            data: _data.xdatasecondary,
            position: 'bottom',
            axisLabel: {
                interval: 0,
                inside: true,
                margin: bSplitWeek ? -xOffset*12.75 : -xOffset*12.5,
                fontSize: 16,
                ...(bSplitWeek && {formatter: weekFormatter}),
            },
            axisTick:  {
                length: xOffset*11,
            }
        });
    } 
    return options;
}; //end getCommonOptions

/**
 * @brief Helper function append the data labels
 */ 
const addDataLabels = function(_options, _data, _bin) {
    // Do not add data labels
    if(_data.xdata.length >= 42) {return _options;}

    // Define a formatter to remap the label of the last series to the series sum
    const totalFormatter = (series) => {
        return (param) => { return Number(series.total[param.dataIndex]) > 0 ? commaizeNumber(series.total[param.dataIndex]) : ''}
    };

    // Define a formatter to use thousands separator and hide zeros
    const seriesFormatter = () => {
        return (param) => { return Number(param.value) > 0 ? commaizeNumber(param.value) : '' }
    };

    // Add the data labels
    if(_bin !== 'Month' || _data.xdata.length > 8) {
        // Add a sum to the last series
        _options.series[Math.max(_options.series.length -1,0)].label = {
            show: true,
            formatter: totalFormatter(_data),
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'monospace',
            color: 'black',
            position: 'top'
        }
    } else {
        // Add a total to all series
        _options.series.forEach((item) => {
            item.label = {
                formatter: seriesFormatter(),
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                fontFamily: 'monospace',
                color: 'black',
                position: 'top'
            }
        })
    }
    return _options;
}

/**
 * @brief Fuctional component encapsulating false positives chart
 */ 
const FalsePositiveChart = ({data, bin, staff, xmin, xmax, smin, smax}) => {
    /*
     * @brief Helper function to define the options array given the inputs
     */
    const defineOptions = function(_data, _bin, _staff, _xmin, _xmax, _smin, _smax, _selected) {
        // Trim the data
        let data = trimData(_data, _xmin, _xmax, _smin, _smax);

        // Define the default options
        let options = getCommonOptions('DVR Errors',data, _bin, _staff, _xmin, _xmax, _smin, _smax);

        // Add this plots colors
        options.color = ['#F79646','#8064A2','#FCEA04','#ED1C24'];

        // Name the y-axis
        options.yAxis.name = 'Total Errors';

        // Add the series
        let bStack = _bin !== 'Month' || data.xdata.length > 8;
        let barWidth = 50;
        options.series = [
            {
                name: 'Other False Negatives',
                data: data.fn,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            },
            {
                name: 'Other False Positives',
                data: data.fp,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            },
            {
                name: 'Severe Drowsiness False Negatives',
                data: data.sdfn,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            },
            {
                name: 'Severe Drowsiness False Positives',
                data: data.sdfp,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            }
        ];

        // Add an invisible series for the labels
        if(bStack) {
            options.series.push({
                name: '',
                data: new Array(data.xdata.length).fill(0),
                type: 'bar',
                ...(bStack && {stack: 'yes'}),
                tooltip: {
                    show: false
                }
            })
        }

        // Toggle series
        if(_selected) {
            options.legend.selected = _selected;
        }


        // Return the options with data labels
        return addDataLabels(options, data, _bin);
    }

    /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [chartOptions, setchartOptions] = React.useState(defineOptions(data, bin, staff, xmin, xmax, smin, smax));

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        // Pass the changes to the local function
        let options = defineOptions(data, bin, staff, xmin, xmax, smin, smax);

        //Set the state
        setchartOptions(options);
    },[data, bin, staff, xmin, xmax, smin, smax]) //listen for changes to inputs

    /**
     * @brief Listen for series selection
     */
    const eventsMap = {
        legendselectchanged: function(params) {
            // Copy the data series
            let updated = data;
            updated.total = new Array(data.xdata.length).fill(0);

            // Redefine the total
            if(params.selected["Other False Negatives"]) {
                data.fn.forEach((val,index)=>{updated.total[index]+=val});
            }
            if(params.selected["Other False Positives"]) {
                data.fp.forEach((val,index)=>{updated.total[index]+=val});
            }
            if(params.selected["Severe Drowsiness False Negatives"]) {
                data.sdfn.forEach((val,index)=>{updated.total[index]+=val});
            }
            if(params.selected["Severe Drowsiness False Positives"]) {
                data.sdfp.forEach((val,index)=>{updated.total[index]+=val});
            }

            // Pass the changes to the local function
            let options = defineOptions(updated, bin, staff, xmin, xmax, smin, smax, params.selected);

            //Set the state
            setchartOptions(options);
        }
    }
   
    return <ReactEcharts option={chartOptions} onEvents={eventsMap} notMerge={true} />;
};

/**
 * @brief Fuctional component encapsulating call errors chart
 */ 
const CallErrorsChart = ({data, bin, staff, xmin, xmax, smin, smax}) => {
    /*
     * @brief Helper function to define the options array given the inputs
     */
    const defineOptions = function(_data, _bin, _staff, _xmin, _xmax, _smin, _smax, _selected) {
        // Trim the data
        let data = trimData(_data, _xmin, _xmax, _smin, _smax);

        // Define the default options
        let options = getCommonOptions('Total No Calls and Late Calls',data, _bin, _staff, _xmin, _xmax, _smin, _smax);

        // Add this plots colors
        options.color = [EDGE3_PRIMARY_COLORS[0],EDGE3_SECONDARY_COLORS[0],EDGE3_PRIMARY_COLORS[1]];

        // Name the y-axis
        options.yAxis.name = 'Total Call Errors';

        // Add the series
        let bStack = _bin !== 'Month' || data.xdata.length > 8;
        let barWidth = 50;
        options.series = [
            {
                name: 'No Calls',
                data: data.nocall,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            },
            {
                name: 'Late Calls',
                data: data.latecall,
                type: 'bar',
                barMaxWidth: barWidth,
                ...(bStack && {stack: 'yes'}),
            },
            // {
            //     name: 'No Call Escalations',
            //     data: data.noescalate,
            //     type: 'bar',
            //     barMaxWidth: barWidth,
            //     ...(_bin != 'Month' && {stack: 'yes'}),
            // }
        ];

        // Add an invisible series for the labels
        if(bStack) {
            options.series.push({
                name: '',
                data: new Array(data.xdata.length).fill(0),
                type: 'bar',
                ...(bStack && {stack: 'yes'}),
                tooltip: {
                    show: false
                }
            })
        }

        // Toggle series
        if(_selected) {
            options.legend.selected = _selected;
        }

        // Return the options with data labels
        return addDataLabels(options, data, _bin);
    }

    /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [chartOptions, setchartOptions] = React.useState(defineOptions(data, bin, staff, xmin, xmax, smin, smax));

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        // Pass the changes to the local function
        let options = defineOptions(data, bin, staff, xmin, xmax, smin, smax);

        //Set the state
        setchartOptions(options);
    },[data, bin, staff, xmin, xmax, smin, smax]) //listen for changes to inputs

    /**
     * @brief Listen for series selection
     */
    const eventsMap = {
        legendselectchanged: function(params) {
            // Copy the data series
            let updated = data;
            updated.total = new Array(data.xdata.length).fill(0);

            // Redefine the total
            if(params.selected["No Calls"]) {
                data.nocall.forEach((val,index)=>{updated.total[index]+=val});
            }
            if(params.selected["Late Calls"]) {
                data.latecall.forEach((val,index)=>{updated.total[index]+=val});
            }
            // if(params.selected["No Call Escalations"]) {
            //     data.noescalate.forEach((val,index)=>{updated.total[index]+=val});
            // }

            // Pass the changes to the local function
            let options = defineOptions(updated, bin, staff, xmin, xmax, smin, smax, params.selected);

            //Set the state
            setchartOptions(options);
        }
    }
   
    return <ReactEcharts option={chartOptions} onEvents={eventsMap} notMerge={true} />;
};

/**
 * @brief Fuctional component encapsulating call errors chart
 */ 
const TotalCallsChart = ({data, bin, staff, xmin, xmax, smin, smax, stafflist, sitelist}) => {
    /*
     * @brief Helper function to define the options array given the inputs
     */
    const defineOptions = function(_data, _bin, _staff, _xmin, _xmax, _smin, _smax, _selected, _sitelist) {
        // Trim data to the min and max
        let data = {
            xdata : _data.xdata.slice(_xmin,_xmax),
            xdatasecondary : _data.xdatasecondary ? _data.xdatasecondary.slice(_smin,_smax) : null,
            series : new Array(_data.series.length).fill(null),
            total : _data.total.slice(_xmin,_xmax),
        };
        // Handle the trimming of the nested array
        for( const index in data.series) {
            data.series[index] = _data.series[index].slice(_xmin,_xmax)
        }

        // Define the default options
        let options = getCommonOptions('Total Call-ins',data, _bin, _staff, _xmin, _xmax, _smin, _smax);

        // Add this plots colors
        options.color = [EDGE3_PRIMARY_COLORS[0],EDGE3_PRIMARY_COLORS[1],EDGE3_PRIMARY_COLORS[2],EDGE3_SECONDARY_COLORS[0],EDGE3_SECONDARY_COLORS[2],
        EDGE3_SECONDARY_COLORS[5],EDGE3_TERTIARY_COLORS[9],EDGE3_TERTIARY_COLORS[3],
        EDGE3_TERTIARY_COLORS[0],EDGE3_SECONDARY_COLORS[1]];

        // Name the y-axis
        options.yAxis.name = 'Total Call-ins';

        // Add the series
        options.series = [];
        let bStack = _bin !== 'Month' || data.xdata.length > 8;
        let barWidth = 50;
        _sitelist.forEach((_val, _index) => {
            options.series.push(
                {
                    name: _val,
                    data: data.series[_index],
                    type: 'bar',
                    barMaxWidth: barWidth,
                    ...(bStack && {stack: 'yes'}),
                }
            )
        });

        // Add an invisible series for the labels
        if(bStack) {
            options.series.push({
                name: '',
                data: new Array(data.xdata.length).fill(0),
                type: 'bar',
                ...(bStack && {stack: 'yes'}),
                tooltip: {
                    show: false
                }
            })
        }

        // Toggle series
        if(_selected) {
            options.legend.selected = _selected;
        }

        // Return the options with data labels
        return addDataLabels(options, data, _bin);
    }

    /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [chartOptions, setchartOptions] = React.useState(defineOptions(data, bin, staff, xmin, xmax, smin, smax, null, sitelist));

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        // Pass the changes to the local function
        let options = defineOptions(data, bin, staff, xmin, xmax, smin, smax, null, sitelist);

        //Set the state
        setchartOptions(options);
    },[data, bin, staff, xmin, xmax, smin, smax, sitelist]) //listen for changes to inputs

    /**
     * @brief Listen for series selection
     */
    const eventsMap = {
        legendselectchanged: function(params) {
            // Copy the data series
            let updated = data;
            updated.total = new Array(data.xdata.length).fill(0);

            // Update each site
            sitelist.forEach((site, idx) => {
                if(params.selected[site]) {
                    data.series[idx].forEach((val,index)=>{updated.total[index]+=val});
                }
            });

            // Pass the changes to the local function
            let options = defineOptions(updated, bin, staff, xmin, xmax, smin, smax, params.selected, sitelist);

            //Set the state
            setchartOptions(options);
        }
    }
   
    return <ReactEcharts option={chartOptions} onEvents={eventsMap} notMerge={true} />;
};

/**
 * @brief Fuctional component encapsulating call errors chart
 */ 
const CallLatencyChart = ({data, bin, staff, xmin, xmax, smin, smax, stafflist, sitelist}) => {
    /*
     * @brief Helper function to define the options array given the inputs
     */
    const defineOptions = function(_data, _bin, _staff, _xmin, _xmax, _smin, _smax) {
        // Trim the data
        let data = trimData(_data, _xmin, _xmax, _smin, _smax);

        // Define the default options
        let options = getCommonOptions('Severe Drowsiness Call Latency',data, _bin, _staff, _xmin, _xmax, _smin, _smax);

        // Add this plots colors
        options.color = [EDGE3_SECONDARY_COLORS[2]];

        // Define a formatter to remap the label of the last series to the series sum
        let boxFormatter = () => {
            // Color span replicates the suppressed bulleted list when using a custom tooltip formatter
            let colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
            return (param) => { 
                return param[0].data[1] == null ? param[0].name : [ 
                    param[0].name,
                    colorSpan(EDGE3_SECONDARY_COLORS[2]) + " Min: " + Number(param[0].data[1]).toFixed(1) + " min",
                    //"Q1: " + Number(param[0].data[2]).toFixed(1) + " min",
                    colorSpan(EDGE3_PRIMARY_COLORS[0]) + " Median: " + Number(param[0].data[3]).toFixed(1) + " min",
                    //"Q3: " + Number(param[0].data[4]).toFixed(1) + " min",
                    colorSpan(EDGE3_SECONDARY_COLORS[2]) + " Max: " + Number(param[0].data[5]).toFixed(1) + " min"
                ].join("<br/>");
             }
        };

        // Add the toolip formatter
        options.tooltip.formatter = boxFormatter();

        // Name the y-axis
        options.yAxis.name = 'Latency (minutes)';
        options.yAxis.min = 0;
        options.yAxis.max = 20;

        // Hide data above the axis
        options.graphic = [
            {
                type: 'rect',
                z:3,
                shape: {
                    x: 80,
                    width: window.innerWidth,
                    height: 80,
                },
                style: {
                    fill: 'rgba(255, 255, 255)'
                }
            }
        ];

        // Define the box width
        let width = Math.min(Math.max(3,60.35177 - 0.79629*data.xdata.length),50)

        // Add the series
        options.series = [{
            name: "Severe Drowsiness",
            type: "boxplot",
            data: data.boxplot,
            boxWidth: [width,width],
            itemStyle: {borderWidth : 2},
            markPoint: {
                symbol: 'rect',
                symbolSize: [width+2, 2],
                data: [],
            }
        }]

        data.boxplot.forEach((value,index) => {
            options.series[0].markPoint.data.push(
                { 
                    yAxis: value[2], //This is the median in array of values at this index (min, q1, median,q3, max)
                    xAxis: index,    //This is the xaxis index
                    itemStyle: {color: EDGE3_PRIMARY_COLORS[0]},
                },
            )}
        );

        // Return the options with data labels
        return options;
    }

    /**
     * @brief Internal state defintion [variable, setter()] = useState(InitialValue);
     */
    const [chartOptions, setchartOptions] = React.useState(defineOptions(data, bin, staff, xmin, xmax,smin, smax));

    /**
     * @brief Listen for changes to the input data or filters and update the internal state
     */
    React.useEffect(() => {
        // Pass the changes to the local function
        let options = defineOptions(data, bin, staff, xmin, xmax,smin, smax);

        //Set the state
        setchartOptions(options);
    },[data, bin, staff, xmin, xmax, smin, smax]) //listen for changes to inputs
   
    return <ReactEcharts option={chartOptions} notMerge={true} />;
};

///////////////////////////////////////////////////////
// Extenal exports
///////////////////////////////////////////////////////
export { DateFilterCheckboxes, FalsePositiveChart, CallErrorsChart, TotalCallsChart, CallLatencyChart, ComparisonCheckbox };