import React, { Component,PureComponent } from 'react';

import { Auth, API } from 'aws-amplify';

import { PolarGraph } from '../BaseComponents.js';

import * as moment from 'moment';
import './RiskTab.css';
// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import blankAvatar from '../assets/avatar-blank-large.png';
import { Commenter,Comment } from '../NoteCard-Comments.js';


/*
* @brief Display the card opened when clicking on a risk profile
*/
class RiskTab extends PureComponent {
    
  constructor(props) {
      super(props);
      this.setComment = this.setComment.bind(this);
      this.updateComment = this.updateComment.bind(this);
      this.cancelComment = this.cancelComment.bind(this);
      this.submitComment = this.submitComment.bind(this);
      this.handleCommentDelete = this.handleCommentDelete.bind(this);

      this.state = {
          shown: true,
          comments:[],
          currentUsername:null,
      };
  }

  componentDidMount(){

    // console.log("Risk: ",this.props);

    const notePromise = Auth.currentSession().then(
      (auth) => {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
          let apiName = "TrifectaAPI";
          let path = "/handleNote";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  driverid: this.props.driverid,
                  type: 'riskprofile',
                  mode: 'fetch',              
              }
          };
          return API.post(apiName, path, myInit);
      }
      )
      .catch((error) => {
          console.error("UserManagent api call; ",error); 
      }); 
      notePromise.then(data => {
          // console.log("Data",data)
          if(data.notes){            
            //Iterate over the returned SQL data, format it to match the display
            let notes  = (data.notes||[]).map(note_=>{
                return({
                    text:note_.text,
                    timestamp:note_.created,
                    username:note_.username,
                    noteid:note_.noteid,
                })
            })
            // console.log("Comments: ",notes);
            this.setState({comments:notes});
            
        }
      });
  }
     
   //callback when a comment is deleted
   handleCommentDelete(_data){
    //collect the data that needs to be saved:
    // console.log("Comment deleted: ",_data)

    // return;
    //Get the comments from the the current state
    const comments = this.state.comments;
    //remove the current referenced comment:
    let filtered = (comments || []).filter(comment_=>{ return comment_.noteid !== _data.noteid})

    this.setState({comments:filtered});

    //Remove the comment from the database
    const realPromise = Auth.currentSession().then(
    (auth) => {
        let apiName = "TrifectaAPI";
        let path = "/handleNote";
        let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                noteid: _data.noteid,
                type: 'riskprofile',
                mode: 'delete',              
            }
        };            
        return API.post(apiName, path, myInit);
    }
    )
    .catch((error) => {
        console.error("Delete comment failed api call; ",error); 
    }); 
    return;
    }

    /*
    * @brief A callback for when the currently-being-added comment is updated
    */
    setComment(comment) {  this.setState({addingComment: comment});  }
    /*
    * @brief A callback for when the currently-being-added comment is updated
    */
    updateComment(_comment) {
        // return;
        // console.log("Update: ",_comment)
        try {
        var updateIndex = -1;
        let commentID = null;
        this.state.comments.forEach((comment_,index) => {
            if(comment_.text === _comment.originalText){
                updateIndex = index;
            }
        });
        if(updateIndex>=0){
            this.setState(prevState => {
                const newcomments = prevState.comments;                    
                newcomments[updateIndex].text =_comment.text;      
                newcomments[updateIndex].timestamp= moment().utc().format();     
                commentID = newcomments[updateIndex].noteid;
                return {comments:newcomments};
            },()=>{
                try {
                
                } catch (error) {
                
                }
                
            });
        }
        
        var that = this;
        window.setTimeout(() => {         that.setState({timestamp:moment()});          }, 250);
        const realPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleNote";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        text: _comment.text,
                        noteid: _comment.noteid,
                        timestamp: moment().utc().format(),
                        driverid: this.props.driverid,
                        type: 'riskprofile',
                        mode: 'update',              
                    }
                };
                // console.log("Send: ",myInit);
                return API.post(apiName, path, myInit);
            }
            )
            .catch((error) => {
                console.error("Submit comment api call; ",error); 
            }); 
        
        
    } catch (error) {
        console.log("Update error: ",error);
    }
    }
    /*
    * @brief A callback for when the currently-being-added comment is cancelled
    */
    cancelComment() {
        this.setState({addingComment: null});
    }

    /*
    * @brief A callback for when the currently-being-added comment is finished
    */
    submitComment() {
        // return;
        const toAdd = this.state.addingComment && this.state.addingComment.trim();
        if (toAdd) {
            const comments = this.state.comments.slice();
            if(this.state.currentUsername){
                comments.push({text: toAdd, timestamp: moment().utc().format(),username: this.state.currentUsername});
            }else{
                comments.push({text: toAdd, timestamp: moment().utc().format()});
            }
            console.log("Comments:" ,comments);
            this.setState({addingComment: null, comments: comments});

            //Send the comment to the Database
            const realPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleNote";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        text: toAdd,
                        timestamp: moment().utc().format(),
                        driverid: this.props.driverid,
                        type: 'riskprofile',
                        mode: 'submit',              
                    }
                };
                console.log("Send: ",myInit);
                return API.post(apiName, path, myInit);
            }
            )
            .catch((error) => {
                console.error("Submit comment api call; ",error); 
            }); 


        } else {
            this.setState({addingComment: null});
        }
        var that = this;
        window.setTimeout(() => {         that.setState({timestamp:moment()});          }, 250);

        

        return;
    }
 
  /*
  * @brief Draw the photo and driverid
  */
  render() {

    let infractionsByHour = this.props.infractionsbyhour;
    let labels = this.props.labels;
    let maxVal = this.props.maxValue;
    if(this.props.updatedRadial && this.props.updatedRadial.length>0){
        // console.log("New data to display? ",this.props.updatedRadial[0].radial)
        infractionsByHour = this.props.updatedRadial[0].radial.infractionsbyhour;
        labels = this.props.updatedRadial[0].radial.labels;
        maxVal = this.props.updatedRadial[0].radial.maxValue;
    }
    // console.log("Render radial card:" ,this.props);
    const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

    let titleText = this.props.infractiontype.toUpperCase() + ' INFRACTIONS BY TIME OF DAY';

    let comments = this.state.comments.map((comment,index_) =>             
        <Comment {...comment} key={index_} cardid={this.props.cardID} infractionID={this.props.infractionID} updateComment={this.updateComment} currentUser={this.state.currentUsername} 
                    commentindex={index_} totalCount={this.state.comments.length} disableEdits={false} deleteCallback={this.handleCommentDelete}/>
    ).reverse();  

    return (
        <div className="radialcard-modal-content" style={style} > 
          <div className='radialTop'>
            <div className='radial-left'>
              <div className='radial-name'  >DriverID: {this.props.driverid} </div>
              <img className="radial-photo" src={this.props.photo || blankAvatar} alt=''  />
              
            </div>
            <PolarGraph title={titleText} dataTitle={'infractions'} legend={this.props.infractiontype}
                                    data={infractionsByHour}  xLabels={labels}
                                    maxValue={maxVal}
                                    onDataClick={this.dataClick} 
                                    filterCount={this.props.filterCount}
                        />
          </div>
          <div className='radialBottom'>
            { comments.length > 0 && 
                [<div className="comments-title">Notes</div>,
                <div className="comments">
                    {comments}
                </div>,
                ]
            }
            <Commenter comment={this.state.addingComment}
                                disableEdits={this.props.noEdit}
                                setComment={this.setComment}
                                cancelComment={this.cancelComment}
                                submitComment={this.submitComment}
                                addLabel = 'Add Note'
                                submitLabel = 'Submit Note'
                                cancelLabel = 'Cancel Note'
                        />

          </div>
        </div>
    )
  }
}

export { RiskTab };