
import React, {  PureComponent } from 'react';
import { useState,useEffect } from "react";

import './vid-util.css'

/*  @Brief Wrap a simple button to allow for tracking that the button has been clicked
*   If an Onclick method is supplied, then the button will be disabled until the onClick has returned 
*   className: required, this tags the Button so that it can be styled by the css
*   onClick: optional, define what will happen when the button is clicked
*   rest: all additional props defined will be pass directly to the HTML button
*/
export const StatefulButton = ({ className, onClick,disabled,  ...rest}) => {
  //1 Logic
  //Define the hooks
  ///     variable, setter()    useState(InitialValue);
  const [clicked, setClicked] = useState(false);  //initialize the state to false

  //Handle what occurs when the button is clicked:
  function handleClick(_event) {
    // console.log("Button clicked");
    if (onClick) { //Only process the state if we have a defined onClick method
      // console.log("Button clicked: ",onClick);
      setClicked(true);// record that we have clicked the button
      let returnVal =  onClick(_event); 
      if(returnVal || (returnVal && returnVal.then)){ // the promise detects as undefined? 
        if(returnVal.then){ //check if this is a promise
          returnVal.then(_data=>{ //then promise completed and returned the data
            //  console.log("Returned from promise:" ,_data);
            setClicked(false);
          })
          returnVal.catch(_data=>{ //handle the error case
            //  console.log("Returned from promise:" ,_data);
            setClicked(false);
          })
        }else{ //not a promise, reset the click state
          //  console.log("Handle non promise?");
          setClicked(false);
        }        
      }else{
        // console.log("No return val ?",returnVal);
        setClicked(false);//reset click state if no return value defined
      } 
    }
  }

  // console.log("Button disabled? ",className,clicked||disabled,clicked,disabled);
  //2 Render 
  return (
      
      // add the statefulbutton classname to any input classes to allow for a default styling to be applied 
      <button className={`statefulbutton ${className}`} disabled={clicked||disabled} {...rest} onClick={handleClick} />
    
  );
}


 /* @brief Parse through the buttonData set and extract the metadata based on the infraction id
  */
export const getMetaDataByID = ( buttonData,id ) => {  
  let metaReturn = null;
  try{
    buttonData.forEach(row => {
      // console.log("MetaData: ",row,_id);
        (row.metadata || []).forEach((meta,index) => {
          if (meta.InfractionID === id && row.classification !== "Common") {
            metaReturn = {data: meta, type: row.classification};
          } //end if
        });//end loop      
    });
  }
  catch(e){}
  // console.log("ID: ",_id,metaReturn);
  return metaReturn;
};

/* @brief get the total count for each button
  */
export const getTotalLessEjections = (_ejectedIDs, _row, allEjects)=>{
  let count = 0;
  
  let ejectedIds = [];//(_ejectedIDs.infractionIds)||[];
  let nonEjected =[];
  try {
    if(_ejectedIDs){
      ejectedIds = _ejectedIDs.infractionIds
    }  
  } catch (error) {
    
  }
  //  console.log("process: ",_ejectedIDs,_row.set,_row,allEjects);
  //Find values not in the ejectedIDS set:
  try {
    // console.log("Check: ",_set, ejectedIds);
    nonEjected =  _row.set.filter(set_ => {
      //  console.log("in set: ",set_.InfractionID);
       return (ejectedIds.includes(set_.InfractionID))? false: true;
      });
    //  console.log("_set: ",_row.set, nonEjected);  
  } catch (error) {
    console.log("Error on filter: ",error);
  }
  
  return nonEjected.length;
}


/* @brief get the css classes to apply to the button
  */
export function getButtonCSSClass (_chosenName,_chosen,_row) {
  // Including CSS classes depending on the state
    let classes = [
      "btn",
      "video-choice",
      "video-choice-" + _chosenName,
    ];
    if (_chosenName === _chosen) {
      classes.push("chosen");
      classes.push("btn-primary");
      if(_row.loadComplete===false){classes.push("is-loading");}
    } else if (!_row.streamURL) {
      // console.log("Disable? ",_chosenName,_row)
      classes.push("btn-disabled btn-outline-disabled disabled");
    } else if (_row.set && _row.set.length===0) {
      classes.push("btn-disabled btn-outline-disabled disabled");
    } 
    else if(_row.loadComplete===false){
      // classes.push("btn-warning");
      classes.push("btn-inverse btn-outline-inverse");
      classes.push("is-loading");
    }
    else {
      classes.push("btn-inverse btn-outline-inverse");
    }
    // if(_chosenName === 'MicroSleep'){
    //   console.log("Button Classes: ",_chosenName, classes,_row);
    // }
    
    
    return classes;
  
}; //end getButtonCSSClass


/* @brief Create the name for the button
*/
export function getButtonDisplayname(_row, _ejectionIds)
{
  const chosenName = _row.chosenName;
  let displayName = _row.displayName;
  // Add in the infraction count for the relevant buttons
  if (chosenName !== "original") {
    //This is a workaround to address the count prop not updating correctly
    let tmpCount = _row.count;
    
    if(_row.set){
        tmpCount = _row.set.length ;
        try {
          tmpCount = getTotalLessEjections(_ejectionIds[_row.chosenName],_row);
        } catch (error) {
          
        }
    }
    else if(_row.metadata){ tmpCount = _row.metadata.length;}
    if(chosenName === 'highlights'){tmpCount = _row.count;} //highlights should show the combined total of all the clips

    tmpCount = Math.max(tmpCount,0);
    displayName += " ( " + tmpCount + " )"; 
    
    try {
      // console.log("Button: ",displayName,_row.set.length);  
    } catch (error) { }
    
  }
  return displayName
}

/* @brief Get card from the card set
  */
export function getCard( _cardSet,_cardData ) {  

  //Try to get from the infraction type first 
  const thisCardType = Object.assign({}, _cardSet[_cardData.infractionType] || {});
  // console.log("Card Type: ",thisCardType,_cardData.infractionType,_cardSet);
  const thisCard = Object.assign({}, thisCardType[_cardData.infractionID]);
  // console.log("Found this card: ",thisCard, _cardData.infractionID );
  //Make sure this card is valid:
  if(thisCard.infractionID && thisCard.cardID){
    //Success return card
    return{
      key: _cardData.infractionType,
      card: thisCard
    }
  }
  //failed to find the card from the infraction type
  else{
    //Can we find it in the infraction tags:
    //Find the card using the infractionid:
    for(const key_ of Object.keys(_cardSet)){
      // console.log("Checking cards: ",key_,_cardSet[key_]);
      //Find the infractionid:
      const thisCardID = Object.assign({}, _cardSet[key_][_cardData.infractionID]);
      // console.log("InfractionID?: ",_cardSet[key_][_cardData.infractionID])
      if(thisCardID.infractionID && thisCardID.cardID){
        // console.log("found card by infractionID ",thisCardID);
        //Success return card
        return{
          key: _cardData.infractionType,
          card: thisCardID
        }
      }//end if
    }//end key loop
  }//end else
};

