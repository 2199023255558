import React, { PureComponent } from 'react';

import './InputTime.css';


/**
 * Break a HH:MM:SS into seconds 
 * @param {*} _time 
 * @returns 
 */
export function timeToSeconds(_time){
    try {
        const [hours, minutes, seconds] = _time.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;    
    } catch (error) {
        return 0;
    }
    
}
/**
 * Helper function to format seconds into the HH:MM:SS time format
 * Does not wrap after 24 hours like the Date() approach
 * @param {*} _seconds 
 * @returns 
 */
export function secondsToTime(_seconds){
    // console.log("Input time: ",_seconds)
    if(_seconds==='Unknown'){return "Unknown";}
    try {
        const hours = Math.floor(_seconds / 3600);
        const minutes = Math.floor((_seconds % 3600) / 60);
        const remainingSeconds = Math.floor(_seconds % 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    } catch (error) {
        console.log("Error on convert:" ,error);
        return "00:00:00"   
    }
}
/* @Brief Component handle the input of a time field
*/
export const InputTime = ({className,type,label,onValidate,onChange,...props}) =>{

    const [boundTime, setBoundTime] = React.useState({time:0,bound:false});
    const inputRef = React.useRef();
    const lastPosition = React.useRef(0);

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
    },[]) //[] -> execute once

    //Handle editing and updating values in our HH:MM:SS formate
    function editTimeString(_inputString,_action,_location){
        lastPosition.current = _location;
        // console.log("Edit timestring: ",_location)
        try {
            let [hoursStr, minutesStr, secondsStr] = _inputString.split(':'); //get the hour/min/sec out of the string (hh:mm:ss)
            let  sanitizedString = '';
            let  manipulatedString = '';

            for(const char_ of _inputString){
                // console.log("Char: ",char_,is_numeric(char_)||char_===':');
                let allowChar = is_numeric(char_)||char_===':';
                if(allowChar){sanitizedString+=char_}
                else{ }
            }

            //  console.log("Action: ",sanitizedString,_inputString,_location);
            //Handle backspace
            switch(_action){
                default:
                case 'insertText':{}break;
                case 'deleteContentForward':{
                    //  console.log("deleteContentForward",sanitizedString,secondsToTime(boundTime.time));
                    if(_location ===2 || _location ===5  ){return secondsToTime(boundTime.time);} //don't delete the : in the string
                }
                case 'deleteContentBackward':{
                    console.log("deleteContentBackward",secondsToTime(boundTime.time),sanitizedString,_location);
                    
                    // if(_location ===2 || _location ===5  ){return secondsToTime(boundTime.time);} //don't delete the : in the string
                    if(_location ===2 || _location ===5  ){
                            sanitizedString = secondsToTime(boundTime.time);
                            let  newString = sanitizedString.slice(0, _location-1) + '0' + sanitizedString.slice(_location);
                            console.log("(:) deleteContentBackward",sanitizedString,newString);
                            return newString;
                    } //don't delete the : in the string
                    if(_location ===0){return secondsToTime(boundTime.time);}
                    //Replace the value with 0:
                    let  newString = sanitizedString.slice(0, _location) + '0' + sanitizedString.slice(_location);
                    console.log("deleteContentBackward",sanitizedString,newString);
                    return newString;
                }break;
            }

            //hh:mm:ss
            try {// location: 12:45:78 index: 01:34:67
                switch(_location){
                    case 1:{hoursStr = sanitizedString[0]+sanitizedString[2] }break;
                    case 2:{hoursStr = sanitizedString[0]+sanitizedString[1] }break;
                    case 3:{hoursStr = sanitizedString[0]+sanitizedString[2] }break;
                    case 4:{minutesStr = sanitizedString[3]+sanitizedString[5] }break;
                    case 5:{minutesStr = sanitizedString[3]+sanitizedString[4] }break;
                    case 6:{minutesStr = sanitizedString[3]+sanitizedString[5] }break;
                    case 7:{secondsStr = sanitizedString[6]+sanitizedString[8] }break;
                    case 8:{secondsStr = sanitizedString[6]+sanitizedString[7] }break;
                    case 9:{secondsStr = sanitizedString[6]+sanitizedString[8] }break;
                    
                }   //end switch
                //Fall back to the old value if the new one is bad
                let [hoursStrPrev, minutesStrPrev, secondsStrPrev] = secondsToTime(boundTime.time).split(':');
                //check valid number on each string, use the previous value if fail
                if(isNaN(Number(secondsStr))){secondsStr=secondsStrPrev;}
                if(isNaN(Number(minutesStr))){minutesStr=minutesStrPrev;}
                if(isNaN(Number(hoursStr))){hoursStr=hoursStrPrev;}
                //  console.log("Check prev", hoursStrPrev,minutesStrPrev, secondsStrPrev);
            } catch (error) {
                console.log("Bad location", _location,sanitizedString,secondsStr, Number(secondsStr));
            }
            
            manipulatedString = `${hoursStr}:${minutesStr}:${secondsStr}`;
            return manipulatedString;
        } catch (error) {
            return null;            
        }
    }

    /**
     * Take the string from the input field and extract the seconds 
     * @param {*} _data string
     * @param {*} _type name of the current component (pre/post)
     */    
    function validateTimeBounds(_data,_type){
        let  manipulatedString = _data.target.value; //value entered
        if(onChange){ onChange(_data,type); } //pass back for generic re-use?

        try {
            //Handle modifying the string:
            manipulatedString = editTimeString(manipulatedString,_data.nativeEvent.inputType,_data.target.selectionStart)
        } catch (error) {
        }
        let tmpBoundTime = boundTime;
        if(onValidate){ //check if supplied a function to validate the results
            tmpBoundTime=onValidate(timeToSeconds(manipulatedString),type)
        }

        setBoundTime(tmpBoundTime); //will trigger the render
    }
    //Called after the changes are completed
    function afterAction(_data,_type){
        //  console.log("Change:",lastPosition.current,_data.key);
        // console.log("ref: ", lastPosition.current) 
        try {
             //Add special case for all arrows
             if(_data.key.includes("Arrow")){  return; }
            switch(_data.key){
                default:{
                    switch(lastPosition.current){//handle delete around the : (positions 2,5)
                        case 1:
                        case 4:{
                            if(inputRef.current){ 
                                // console.log("Shift forward? ",lastPosition.current+1)
                                inputRef.current.setSelectionRange(lastPosition.current+1,lastPosition.current+1);
                                return;
                            }    
                        }break;
                    }
                }
                case 'Delete':{
                    switch(lastPosition.current){//handle delete around the : (positions 2,5)
                        case 1:
                        case 4:{
                            if(inputRef.current){ 
                                // console.log("(:)DEL Shift forward? ",lastPosition.current+2)
                                inputRef.current.setSelectionRange(lastPosition.current+2,lastPosition.current+2);
                                return;
                            }    
                        }break;
                        default:{
                            if(inputRef.current){ 
                            // console.log("DEL Shift forward? ",lastPosition.current+1)
                            inputRef.current.setSelectionRange(lastPosition.current+1,lastPosition.current+1);
                            return;
                            }
                        }
                            
                    }
                    
                    //  console.log("deleteContentForward",sanitizedString,secondsToTime(boundTime.time));
                    // console.log("delete: ", lastPosition.current) 
                }break;
                case 'Backspace':{
                    switch(lastPosition.current){//handle delete around the : (positions 2,5)
                        case 2:
                        case 5:{
                            if(inputRef.current){ 
                                console.log("Back Shift forward? ",lastPosition.current-1)
                                inputRef.current.setSelectionRange(lastPosition.current-1,lastPosition.current-1);
                                return;
                            }    
                        }break;
                    }
                    
                    //  console.log("deleteContentForward",sanitizedString,secondsToTime(boundTime.time));
                    // console.log("delete: ", lastPosition.current) 
                }break;
            }
            //Otherwise set to the current location: (not end of the string)
            if(inputRef.current){ inputRef.current.setSelectionRange(lastPosition.current,lastPosition.current);}    
        } catch (error) {
            console.log("Fail on set input ref: ",error);            
        }
    }//end afterAction
    function is_numeric(str){
        return /^\d+$/.test(str);
    }
    

    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        //Return the HTML code to display:
        return (
            <div className={'label-input-pair '+{className}}>
                <div className='text-label'> {label}</div>
                <input ref={inputRef} className="settime" type="text" size='9' 
                    clamped= {boundTime.bound?'true':'false'}
                    value = {secondsToTime(boundTime.time)}
                    placeholder={'--:--:--'}                                                
                    onInput={(_change)=>{validateTimeBounds(_change,'pre')}}                    
                    onKeyUp={(_change)=>{afterAction(_change,'pre')}}
                />                                
            </div>
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}//end of the CutVideoNotecard

