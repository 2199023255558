import React, { PureComponent } from 'react';

import './StoryTile.css';

import {blankAvatar} from '../RiskTab/RiskUtils.js';
import { StemPlot } from './StemPlot';



/*
* @brief Display a tile to render in a table
*/
class StoryTile extends PureComponent {
    
  constructor(props) {
      super(props);

      this.state = {
          shown: true,
      };
  }

  componentDidMount(){
    // console.log("Tile loaded: ",this.props);
  }
  /*
  * @brief Draw the photo and driverid
  */
  render() {

    // console.log("Props to render: ",this.props);

   

    const onClick = (() => {
      //  console.log("Click recived",this.props)
      if(this.props.onClick){this.props.onClick(this.props)}
    });

    return(
      <div className="storytile-content" key={"storytile-"+ this.props.idx} > 
        <div className="tiletable" onClick={onClick}> 
            <div className='date'>{this.props.data.date} </div>
              <StemPlot
                title='Title' dataTitle='data'
                class = 'chartWrap'
                data={this.props.data}
                interactive={false}
                groupconfig = {this.props.groupconfig}
                chartStyle={{
                  height: '100%',
                  width:'100%',
                }}
              />
        </div>
      </div>

    )
  }
}


export { StoryTile };