import React, { Component, useState, useEffect} from 'react';
// import {useLongPress} from '../Util/useLongPress.js';
import { ButtonWarnings } from './ButtonWarnings';
import { LiveButton } from './LiveButton';
import {getUnviewedCount} from '../Util.js';
import './LiveAsset.css';
import { LiveClipPlayer } from './LiveClipPlayer.js';
import { clipReviewUpdate} from './util-LiveTab.js';
import { LiveClipInterface } from './LiveClipInterface.js';
import { useClipDownloader } from './useClipDownloader.js';
import AccumulatedTime from './AccumulatedTime.js';

//Notecard includes:
import { generateUniqueID, SEVERITIES } from '../Util.js';
import * as moment from 'moment-timezone';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';

/**
 * Handle all clips and interactions with concerning a single asset, this includes downloading, tracking
 * playing the clips, and notecard interactions
 * @param {*} param0 
 * @returns 
 */
export const LiveAsset = ({  onClick = () => {console.log("no click callback")}
                            // ,onNewClip = () => {console.log("Clip Injest not attached")}
                            ,config
                            ,idx
                            ,db
                            ,user=null
                            ,filter = {}
                            ,onReview=()=>{}
                            ,onClipLoad=()=>{}
                            ,onCardChange=()=>{console.log("Notecard API callback not linked")}
                            ,warningSet
                            ,offlineSet
                            ,activeButton,...rest}) => {

  const [assetTitle, setAssetTitle] = useState(config.title);  
  const [style, setStyle] = useState({backgroundColor:config.color});
  const [reviewCount, updateReviewCount] = useState({iUnviewed: 0,iViewed: 0, iTotal: 0});
  const [trackedClips, updateClips] = useState({  set: [],
                                                  type: 'playlist',
                                                  classification: "tag",            
                                                  count: 0,
                                                  loadcount:0,
                                                  streamURL: 'playlist', });
  const [topLevelClassName, updateClassName] = useState('live-reviewer-players');
  const [expandedCard, setExpandedCard] = useState(null);
  // const [cardsByInfID.current, updateNotecardState] = useState({});
  const cardsByInfID = React.useRef({});
  const currentPlayingID = React.useRef();
  const [isBeforeCommandTime, setBeforeCommandTime] = useState(2);
  const [isSkippingDisabled, setSkipRestriction] = useState(true); //default to false

  const { downloadFile } = useClipDownloader(db,onDownloaded);

  /**
   * Track which clips have been viewed
   * @param {*} _details: Clip data returned from the LiveClipPlayer, includes the infractionid
   */
  function markViewed (_details) {
    try {
      //  console.log("Mark viewed called: ",_details.meta.Tag, _details)
      // console.log("Markviewed: ",_details.meta.Tag);
      let iClipIndex = parseInt(_details.meta.Tag,10);
      //Mark viewed for all clips in set up to the current progress callback from the player
      for (let i = 0; i < trackedClips.set.length && i<=iClipIndex; i++) {
        trackedClips.set[i].viewed = true;
      }
        updateClips(trackedClips);
        let returnedCount =  getUnviewedCount(trackedClips,assetTitle);
        updateReviewCount(returnedCount);
    }
    catch(e){
      console.log("Failed ot mark in asset: ",e);
    }
    //Report back to the API to record update to SQL table
    onReview(_details);
      
  }//end markViewed

  

  /**
   * Callback after the download has completed, only unique clips should have ben returned at this point
   * @param {*} _data: details of the downloaded file
   */
  function onDownloaded(_data){
    // console.log("File is downloaded ", _data);

    //Attach the time tracker to the clip:
    _data.timereviewed= new AccumulatedTime()

    //Append to the tracked clips set
    updateClips(previousClips=>{ //get the current value of the state variable
      previousClips.set.push(_data); //append the new clip to the set
      previousClips.count = previousClips.set.length; //increment the length
      previousClips.loadcount = previousClips.set.length; //increment the lenght
      return previousClips; //return to update the state value
    })
    let returnedCount =  getUnviewedCount(trackedClips,assetTitle);
    updateReviewCount(returnedCount);

    //Special case to create notecards on Severe Drowsiness tags from the Asset:
    
    //Add a special case for the sudden infractions, these have had notecards generated on the backend automatically
    if(_data.classification.toLowerCase().includes('sudden')){
      // console.log("Sudden clip:",_clip)
      //Call the generate notecard, if the cardid is not null (backend generated) then a special case is triggered
      //otherwise if already loaded it will find the existing card and no action is taken
      this.generateNoteCard({
        Tag: _data.tag,
        classification: _data.classification,
        infractionID: _data.infractionid,
        id: _data.id,
        assetid: _data.assetid,
        siteid: _data.siteid,
        infractionTags: _data.infractiontags? _data.infractiontags.split(','): [],
        type: 'playlist',
        clientid: _data.clientid,
        cardid: _data.cardid,
      },true); 

    }

    //Send the update to the Cloud to track that the clip is available on the Live Tab
    onClipLoad(_data);
  }//end onDownloaded()

  /**
   * Subcribe to the new clip notification published from the LiveTab
   * @param {*} _event: Details of the new clip that were sent
   */
  function onNewClip(_event) {
    // console.log("new clip?: ",_event);
    if(_event.clip){
      //Make sure it is unique, 
      let bDuplicate = null;
      try {
        if(trackedClips && trackedClips.set){
          let foundReturn = trackedClips.set.find(elem=>{return elem.infractionid === _event.clip.infractionid})
          if(foundReturn){
            bDuplicate = true;
          }
        }
      } catch (error) {
        console.log("error on scan: ",error);
      }
      if(bDuplicate){
        // console.log("escape on duplicate:");
        return;
      }

      //Download the file, once downloaded, the file will be added to the trackedClips state variable
      downloadFile(_event.clip,true);
    }
    if(_event.notecard){
      try {
        if(cardsByInfID.current[_event.notecard.infractionid]){
          // console.log("Card already found")
          return;
        }
      }catch(eror){}
        // console.log("Add card: ",_card);
        //Reformat the SQL table data to match the expected local names:
        let renamedCard = {
          tag: _event.notecard.tag,
          severity: _event.notecard.severity,
          id: _event.notecard.videoid || _event.notecard.id,
          notes: _event.notecard.notes,
          infractionType: _event.notecard.infractiontype,
          infractionID: _event.notecard.infractionid,
          status: _event.notecard.status,
          driverID: _event.notecard.driverid || "DriverID: Unavailable",
          timeReceived: moment.parseZone(_event.notecard.timereceived),        
          photo: _event.notecard.thumbnail,
          cardID: _event.notecard.videocardid,
          siteID: _event.notecard.siteid,
          vehicleID: _event.notecard.vehicleid,
          timeCreated: _event.notecard.creationtime,
          infractionTags: _event.notecard.infractiontags,
          metadata: _event.notecard.metadata,
        };
        //Split the csv to an array
        if(renamedCard.infractionTags){ renamedCard.infractionTags = renamedCard.infractionTags.split(",");}
        else{renamedCard.infractionTags = [];}
        
  
        //Add the new card to the list of cards held on the page
        cardsByInfID.current[renamedCard.infractionID] = Object.assign(cardsByInfID.current[renamedCard.infractionID] || {}, renamedCard);
        if(!cardsByInfID.current[renamedCard.infractionID].name){cardsByInfID.current[renamedCard.infractionID].name = "DriverID: Unavailable";}
    }
    
  } //end onNewClip

  //Handle the effect of creating the component, executes one time
  React.useEffect(()=>{
    // console.log("New button: ",rest.userInfo);
    LiveClipInterface.subscribe({name:assetTitle,callback:onNewClip});
  },[]) //[] -> execute once

  /**
   * Monitor for changes in the selected asset button
   */
  React.useEffect(()=>{
    //Update the color if the button is active
    if(activeButton === assetTitle){ //Is this the active button
      setStyle({backgroundColor:config.activeColor}); //update the selected color
      updateClassName("live-reviewer-players")
    }else{//if this is not the active button
      setStyle({backgroundColor:config.color}); //change back to the default color
      updateClassName("live-reviewer-players hidden-video") //update the class name to hide the player and control buttons
    }

    //If currently tracking a clip and the asset button changes, then track the skip action:
    if(currentPlayingID.current){ //Is this the active button with a loaded video
      // console.log("Record skip on clip ", activeButton,assetTitle);
       clipReviewUpdate("livetab_review",{infractionid: currentPlayingID.current,username:user?user.username:null},'skip',trackedClips);
    }else{
      // console.log("Record skip on clip -no current id ", activeButton,assetTitle);
    }

    //The asset has changed, release the currentPlayingID
    //This is reset by the onPlay callback:
    currentPlayingID.current = null;

  },[activeButton]) //listen for changes to the activeButton property

  React.useEffect(()=>{
    // console.log("New command time: ",config.lastcommand);
    if(currentPlayingID.current){isCameraControlAvailable(currentPlayingID.current)}
  },[config.lastcommand])

  //Update the skip restrictions if the username/userinfo is available
  React.useEffect(()=>{
    // console.log("User data received: ",user,rest.userInfo);
    let bDisableSkipping = true;
    //If this is a test user account: beirut_user, pv_user etc: then allow skipping (original rule)
    try {
      if(user.username.includes('_user')){bDisableSkipping=false;}
    } catch (error) {}
    //If the user has been configured to allow it in the cognito attributes, then allow it
    try {
      if(rest.userInfo && rest.userInfo.disableSkipRestrict && rest.userInfo.disableSkipRestrict==='true'){
        bDisableSkipping=false;
      }
    } catch (error) {}
    //Update the state:
    setSkipRestriction(bDisableSkipping);
    
  },[user, rest.userInfo]) //listen for the changes the username or userinfo
  

  /** HANDLE PLAYBACK CALLBACKS FROM THE LIVECLIPPLAYER */
  /**
   * Callback triggered by LiveClipPlayer when the clip starts playing.
   * Add the playstart timestamp to the queue'd object 
   */
  function onPlay(_data){
    //  console.log("LiveTab: onPlay: ",_data, user, user.username);
    clipReviewUpdate("livetab_review",{..._data,username:user?user.username:null},'play',trackedClips);
    //Track the current playing clip' infractionid
    currentPlayingID.current = _data.infractionID||_data.infractionid;
    isCameraControlAvailable(currentPlayingID.current);
  }
  

 /**
  * Evaluate the current clips' play time against the last command that was triggered: 
  * @param {*} _infractionid: id of the current clip
  */
  function isCameraControlAvailable(_infractionid){
    try {
      //Get the current clip's timestamp from the tracked data:
      let clip = trackedClips.set.find(elem=>{return elem.infractionid === _infractionid})
      if(clip){
        //Check if there is a last known command time, if not then allow controls:
        if(!config.lastcommand){ setBeforeCommandTime(0); return;}
        //Need to convert the clip and command into the same timezone. 
        // The clip is recorded without timezone always show the actual local time
        // The command is recorded at UTC time (apply the site's timezone to the command to get the matching timezone)
        //Recast these after converting the time zone so that the object doesn't hold the wrong internal UTC time
        let commandTime = moment(moment.tz(config.lastcommand,'America/Phoenix').format('YYYY-MM-DD HH:mm:ss'));
        let clipTime = moment(moment.parseZone(clip.timerecorded).format('YYYY-MM-DD HH:mm:ss'));
        clipTime.add(4,'seconds');
        //Compare the clips to check if it was recorded before the command was sent to the asset
        // console.log("Command before clip?", commandTime.format("DD HH:mm:ss"), clipTime.format("DD HH:mm:ss"),clipTime.isBefore(commandTime));
        if(clipTime.isBefore(commandTime)){
          // console.log("Command before clip?",config.lastcommand, commandTime.format("DD HH:mm:ss"), clipTime.format("DD HH:mm:ss"),clipTime.isBefore(commandTime));
          setBeforeCommandTime(1); //set the state to prevent loading the camera controls
        }else{
          setBeforeCommandTime(0); //reset to allow controls
        }
        
  
        // console.log(`Clip ${clip.timerecorded} Command: ${config.lastcommand} `,clipTime.format("MM-DD HH:mm:ss"),commandTime.format("MM-DD HH:mm:ss"),clipTime.isBefore(commandTime))
        // console.log("Test true: ",moment("2024-09-09 17:12:10").isBefore(moment("2024-09-09 19:12:10")))
        // console.log("Test false: ",moment("2024-09-09 17:12:10").isBefore(moment("2024-09-09 17:12:09")))
      }
    } catch (error) {
      console.log("Failed to compare timestamps: ",error);
      setBeforeCommandTime(0);
    }
  }

  /**
   * Callback triggered by LiveClipPlayer when the clip comes to the end 
   * Add the playstop timestamp to the queue'd object
   * Set the status to complete if the video finished playing before the skip was pressed
   */
  function onStop(_data){
    // console.log("LiveTab: onStop: ",_data,user);
    clipReviewUpdate("livetab_review",{..._data,username:user?user.username:null},'stop',trackedClips);
  }
  /**
   * Callback triggered by LiveClipPlayer when the next button is clicked.
   * Add the skip timestamp to the queue'd object
   * Set the status to skipped if the button was pressed before the video finished playing  
   */
  function onSkip(_data){
    // console.log("LiveTab: onSkip: ",_data);
    clipReviewUpdate("livetab_review",{..._data,username:user?user.username:null},'skip',trackedClips);
  }
  /**
   * Callback triggered by clicking on an icon to make a call on the LiveTab
   * Add the call timestamp to the queue'd object
   */
  function onCall(_data){
    try {
      if(currentPlayingID.current){clipReviewUpdate("livetab_review",{infractionid: currentPlayingID.current,username:user?user.username:null},'call',trackedClips);}
    } catch (error) {}
  }

  /** HANDLE INTERACTIONS WITH NOTECARDS */
  /**
   * Callback when the overlay button is clicked, this is passed up from the players
   * @param {*} _details: details of the clip that is used to create the notecard 
   * @param {*} _noShow: toggle to determine if the notecard should be shown after creation
   */
 function generateNoteCard(_details,_noShow){
  //  console.log("gen notecard called: ",_details, config, user);
  if(!_details.infractionID){console.log("Notecard fail, no infractionid: ",_details,assetTitle); return;}
  if(_details.type != 'playlist'){return;}

  //Callback received by the wrong asset, don't process this data
  if(activeButton !== _details.assetid){
    console.log("Notecard fail infractionid not from current asset?",_details, activeButton, assetTitle);     
    return;
  }
  
  try {
    //Log that a notecard was created in the auditing object
    clipReviewUpdate("livetab_review",{infractionid: _details.infractionID,username:user?user.username:null},'notecard',trackedClips);  
  } catch (error) {}


  try {
      //Check if this card already exists
      if (cardsByInfID.current[_details.infractionID]) {
        //  console.log("Card found?",cardsByInfID.current[_details.infractionID],this.props.possibleFilters.Sites );
        if(_noShow){} //do nothing if the noShow flag is set
        else{
          //get the known notecard:
          let cardToView = cardsByInfID.current[_details.infractionID];
          //Make sure the notecard has Site information attached:
          if(!cardToView.siteID){
            cardToView.siteID = config.siteid;
            cardToView.vehicleID= cardToView.vehicleID|| _details.assetid;
          }
          // console.log("Open existing card: ",cardToView);
          setExpandedCard(cardToView)//pass the object to be viewed
        }
        return; // processed an existing notecard, exit the function early
      }
      //Create the new card (fallthrough)
      if(!_details.clientid){  
        console.log("No clientid set, abort");
        return; 
      }
      
      //Get the known clip details from the tracking object
      let clipDetails = null;
      try {
        let foundClip = (trackedClips.set||[]).filter(clip_=>{return clip_.infractionid === _details.infractionID})
        // console.log("Found clip details: ",foundClip)
        clipDetails = foundClip[0];
      } catch (error) {
      }

      //Set up the driverID, prefer the tagged infraction, default to the global video id
      let driver = null;//"DriverID: Unavailable";
      if(clipDetails && clipDetails.driverid){
        driver = clipDetails.driverid
      }
      if(driver){ driver = driver.replace('_', ' ');} //sanitize the name in case of underscores

      let cardID =  _details.cardid || generateUniqueID();
      let currentSeverity = SEVERITIES.indexOf("IRRELEVANT");      
      let newSeverity = currentSeverity;
      
      //Update the severity of the notecard based on the type of infractiontags that are set
      if(_details.infractionTags && _details.infractionTags.length>0){
        //What is a highest severity?
        (_details.infractionTags || []).forEach((infraction_) => {
            let matchedSeverity = user.groupconfig.infractionTags.filter(s => { return s.type === infraction_;});
            // console.log("Matched Severity: ",matchedSeverity);
            if(matchedSeverity && matchedSeverity.length>=1){
                let testSeverity = SEVERITIES.indexOf(matchedSeverity[0].severity);
                if(SEVERITIES.indexOf(matchedSeverity[0].severity) < newSeverity){
                    newSeverity = testSeverity;
                }
            }            
        });
      }
      //make sure the clip has the needed details
      //get the siteid from the tab
      let siteid = config.siteid;
      // console.log("Create note card with ",_details);
      const card = {
        tag: _details.Tag,
        infractionType: _details.classification,
        infractionID: _details.infractionID,
        status: 'FleetReview',
        severity: SEVERITIES[newSeverity],
        //  timeReceived: moment(this.props.parentVideo.received),
        timeReceived: moment(),
        timeCreated: moment(),
        cardID:cardID,
        parentID: _details.id,
        vehicleID: _details.assetid,
        siteID: siteid,
        notes: "",    
        name: driver,
        infractionTags: [],
        live: true,
        clientid: _details.clientid,
        timeOfDay: moment.parseZone(_details.timerecorded),
        vehicletype: (_details.clientid.includes('bis')||_details.clientid.includes('devgroup'))?'24Review':null,         
      };
       
      //Is this a special case passed from the websocket?
      if(_details.cardid){
          if(_details.infractionTags){
            card.infractionTags = _details.infractionTags;
          }
          // console.log("Create card? : ",card);
          updateNotecard({
            cardID: cardID,
            infractionID: _details.infractionID,
            infractionTags: _details.infractionTags, 
            tag: _details.Tag,
          },_noShow);
          //Don't sync to the cloud, we already have it there
          return;

      }else{
        cardChange(card,_noShow); //save the changes to local reference, and push the data to the Cloud API
      }
      // If the notecard was created with infraction tags, then send the infration Tags right away:
      if(_details.infractionTags ){
        updateNotecard({
          cardID: cardID,
          infractionID: _details.infractionID,
          infractionTags_default: _details.infractionTags, 
          tag: _details.Tag,
        },_noShow);
        //Needs to be in string to send to the SQL:
        if(onCardChange){
          onCardChange({
            cardID: cardID,
            infractionID: _details.infractionID,
            infractionTags: _details.infractionTags.toString(), 
            infractionType: _details.classification,
            tag: _details.Tag,
          });
        }
      }
      return;
  } catch (error) {
    console.log("card create error", error);
  }
  }//end generateNotecard

 /**
  * Callback when a card is updated, and the changes need to be pushed to the API
  * @param {*} _inCard: Card details
  * @param {*} _noShow: if false, the notecard we be displayed on the screen
  */
  function cardChange(_inCard,_noShow=false) {
    // console.log("Card change: ",_inCard,_noShow);
    _inCard.live = true;
    if(onCardChange){
      onCardChange(_inCard);
    }
    //Append to the tracked clips set
    updateNotecard(_inCard,_noShow);
  }//end cardChange

  /**
   * Update the notecard data held by the component
   * @param {*} _inCard: notecard details to update
   * @param {*} _noShow: show the notecard be shown after the update is made?
   */
  function updateNotecard(_inCard,_noShow=false) {
    //Append to the tracked clips set
    cardsByInfID.current[_inCard.infractionID] = Object.assign(cardsByInfID.current[_inCard.infractionID] || {}, _inCard);
    //Update the viewed card if noshow is false
    if(!_noShow){
      setExpandedCard(cardsByInfID.current[_inCard.infractionID]);//trigger the show
    }
  }

 /**
  * Callback when a card is created, this passes back the streamURL for the tagged infractions
  * Just a helper function to catch the event if needed
  * @param {*} _inCard: details of the current clip to create a notecard
  */
  function onCardCreate(_inCard){
      // console.log("onCardCreate: ",_inCard);
    //Added a new card:
    updateNotecard(_inCard,true);
  } //end onCardCreate

  /**
   * Triggered when the notecard is closed, if the notecard was closed due to a delete request the _incard is not null
   * @param {*} _inCard: details of the notecard that should be deleted
   */
  function unexpandCard(_inCard){
    // console.log("Close card");
    // perfDidClick('unexpand-card-click');
    if(_inCard && _inCard.delete){
      delete cardsByInfID.current[_inCard.infractionID];
    } //end delete handle
    setExpandedCard(null);
  } //end unexpandCard


  /**
   * Layout the render, this is executed everytime the state changes
   * Logic and computations should be avoided in this loop
   */
  const render= ()=>{
    return (
      <div className='asset-elem' key={'asset-elem'+idx}>
        {/* Render the System Warning and Offline icons */}
        <ButtonWarnings assetIndex={assetTitle} warningSet = {warningSet} offlineSet={offlineSet} 
                        config={config} groupconfig = {user?user.groupconfig:null}  onCall={onCall}/>
        {/* Button to click on*/}
        <LiveButton onClick={(e)=>{onClick({assetid:assetTitle})}}
                    onLongPress={()=>{onClick({longpress:assetTitle})}}
                    assetIndex={assetTitle}
                    style={style}
                    counts = {reviewCount}
                    disableLongPress = {isBeforeCommandTime}
          />
          {/* Create the video player, add it to a floating div so that it can be detached from the buttons */}
          <div className='live-float'>
            <div className={topLevelClassName}>
              <LiveClipPlayer   className={"VideoClipPlayer"}
                                currentClip={trackedClips}
                                loadedClips={trackedClips.set}
                                clientid = {config.clientid}                               
                                key={assetTitle+idx}
                                filter={filter}
                                groupconfig= {user?user.groupconfig:null}
                                notecards={cardsByInfID.current}
                                markViewed={markViewed} 
                                chosen={activeButton}
                                name = {assetTitle}
                                overlayButtonClick = {generateNoteCard}
                                bMemoryOptimized = {false}
                                loadCount = {reviewCount.iUnviewed+reviewCount.iViewed}
                                onPlay = {onPlay}
                                onStop = {onStop}
                                onSkip = {onSkip}
                                enableSkipRestrict = {isSkippingDisabled}
                                
                                // enableSkipRestrict = {true} //debugging 
                            
            />  
            </div>
          </div> {/*End of the floating div that holds the VideoClipPlayer  */}

          {/* Display the notecard if the expandedCard state variable is set */}
          { expandedCard &&
            <ExpandedCard handleClose={unexpandCard}
                          {...expandedCard}
                          cardChange={(_data)=>cardChange(_data,true)}
                          tagChange={(_data)=>updateNotecard(_data,true)}
                          driverChange = {(_data)=>updateNotecard(_data,true)}
                          newDetails= {onCardCreate}
                          scale={1}   
                          noLink={true}                     
                          // noEdit={disableEdits}
                          filter={filter}
                          groupconfig = {user?user.groupconfig:null}
                          siteDetails = {config.siteDetails}
            />
          }
          
      </div> //end of element
    );
  }
  //Function component is expecting the return, add return to the render() call
  return render();
  //2 Render 
  
}
