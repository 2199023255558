import React from 'react';

export function dvrdeleted_message () {
    return  (
                <div className='dvrdeleted'>
                    <div>File no longer available. Old data is automatically deleted based on the data retention policy.</div>
                    <br/>
                    <div>If you feel this message has been received in error, please contact EDGE3, or your local Admin, to configure the data retention policy</div>
                </div>
            );
}
