
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
// import './VideoCard.css';
import './ROISelector.css';
import { setupPerf } from './Perf.js';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ApiCaller } from './ApiCaller.js';
import { Auth, API } from 'aws-amplify';
import { motion } from "framer-motion"

import IconProcessing from './assets/processing-gears.png'
import IconUpdate from './assets/imgUpdate.png'

/*
* @brief .
*/
export class ROISelector extends PureComponent{
    constructor(props) {
        super(props);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onProcessClick = this.onProcessClick.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.updateData = this.updateData.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.state = {
            m_img: null,
            m_roi: null,
            shown: false,
            crop: {
                unit: '%',
                width: 30,
                // aspect: 16 / 9,
            },
            imgDims:null,
            retryCount:0, //passed to the APICaller, if updated the API caller will re-trigger an API query
            
        };
        this.perfName = 'ROISelector';
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    
    componentDidMount() {
        window.setTimeout(() => {
            this.setState({shown: true});
        }, 150);
    }

     /*
    * A handler used by the ApiCaller
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
     /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
         console.log("Props: ",this.props);
        // Create the API call + promise wrapper that will get frame from the video
        const dataPromise = Auth.currentSession().then(
            (auth) => {
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  apiName: "getVideoFrame",
                  mode: "fetch",
                  s3key: this.props.s3key,
                  timeoffset: Math.max(this.props.timeoffset,1),
                  id: this.props.id,
                  videoid: this.props.videoid,
                  roi: null,
                //   testtimeout: this.state.retryCount<2?true:null,
                }
              };     
              return API.post("AuthLambda", "/apiRouter", myInit);
            });
        return dataPromise;
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        console.log("ROI data: ",data);
        // if(this.state.retryCount<2){data = null;} --debug testing

        //Check that we received data, input can be null if the API call crashed or timedout
        if(!data){ 
            if(this.state.retryCount<3){ //attempt to retry 3 times?                
                setTimeout(() => {this.setState({retryCount: this.state.retryCount+1});}, 1*1000); //1 second (accepts ms times)
                return;
            }else{
                this.props.handleClose(); //otherwise just close the attempt
                return;
            }
        }
        
        let crop = {};
        let imgDims = null;
        let roi = null;
        if(data.imgdim){
            let dims = data.imgdim.split('x');
            imgDims = {
                width : parseInt(dims[0],10),
                height : parseInt(dims[1],10)
            }
        }
        if(data.roi && imgDims){
            try {
               roi =  JSON.parse(data.roi);
               crop = {
                    unit: "%",
                    x: (roi[0]/imgDims.width)*100,
                    y: (roi[1]/imgDims.height)*100,
                    height: (roi[3]/imgDims.height)*100,
                    width: (roi[2]/imgDims.width)*100,
                }
            } catch (error) {}
        }
        if(data.imgdim && data.imgdata){ 
            
              this.setState({m_img:data.imgdata,crop:crop,imgDims:imgDims, roi:roi});
            //test bad data impact conditions
            //  data.imgdata= 'blasdf'; //test boundary conditions
            // this.setState({m_img:data.imgdata,crop:null,imgDims:imgDims});
            //  this.setState({m_img:data.imgdata,crop:crop,roi:roi});
            //  this.setState({m_img:data.imgdata,imgDims:imgDims,roi:roi});
            //  this.setState({m_img:data.imgdata,imgDims:imgDims});
            //  this.setState({m_img:data.imgdata,roi:roi});
            //  this.setState({m_img:data.imgdata,crop:crop});
            
        }
        
    }
    
    /*
    * @brief Handle the user clicking on update
    */
    onUpdateClick(){
    //    console.log("Update click",this.state.crop,this.state.imgDims);
       try{
        let roi = "[";
        roi += parseInt((this.state.crop.x/100)*this.state.imgDims.width,10)+",";
        roi += parseInt((this.state.crop.y/100)*this.state.imgDims.height,10)+",";
        roi += parseInt((this.state.crop.width/100)*this.state.imgDims.width,10)+",";
        roi += parseInt((this.state.crop.height/100)*this.state.imgDims.height,10)+"]";
        console.log("ROI to submit: ",roi);
        if (window.confirm("Do you want to commit the selected ROI to the Default ROI list: ",roi)) {
            Auth.currentSession().then(
                (auth) => {
                  let myInit = {
                    body: {
                      token: auth.idToken.jwtToken,
                      apiName: "updateROI",                 
                      mode: "Update",
                      id: this.props.id,
                      roi: roi,
                    }
                  };     
                  return API.post("AuthLambda", "/apiRouter", myInit);
                });            
        }//end confirm check
       }catch(e){
           console.log("Error on update click: ",e);
       }
       this.props.handleClose();
    } //end onUpdateClick

    /*
    * @brief Handle the user clicking on process
    */
    onProcessClick(){
         try {
            let roi = "[";
            roi += parseInt((this.state.crop.x/100)*this.state.imgDims.width,10)+",";
            roi += parseInt((this.state.crop.y/100)*this.state.imgDims.height,10)+",";
            roi += parseInt((this.state.crop.width/100)*this.state.imgDims.width,10)+",";
            roi += parseInt((this.state.crop.height/100)*this.state.imgDims.height,10)+"]";

            if (window.confirm("Please confirm that you would like to reprocess this video")) {
                var bReprocess = true;
                if(this.props.hasResults){bReprocess = false;}
                if(!bReprocess && window.confirm("This will delete all current results for this video")) {
                    bReprocess = true;
                }
                if(bReprocess){ 
                    console.log("Sending command to reprocess video")
                    Auth.currentSession().then(
                        (auth) => {
                          let myInit = {
                            body: {
                              token: auth.idToken.jwtToken,
                              apiName: "updateROI",                 
                              mode: "Process",
                              id: this.props.id,
                              roi: roi,
                            }
                          };     
                          return API.post("AuthLambda", "/apiRouter", myInit);
                        });            
                }
            }//end confirm check
        } catch (error) {
            console.log("highlights link error: ",error);
        }
        this.props.handleClose();
    }//end onProcessClick

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        // this.imageRef = image;
    };

    onCropComplete = crop => {
        // this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        // percentCrop.x -= 20;
        //  console.log("Crop change:",crop,percentCrop);
        this.setState({ crop:percentCrop });
    };
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        //Set a flag to disable edits if the input property is true
        const { crop, m_img } = this.state;

        //Handle what happens when the user click off the card
        const confirmClose = () => {
            this.props.handleClose();
        }//end confirmClose
        let roiUpdateStyle = {visibility:"hidden"};      
        if(this.state.roi){
            roiUpdateStyle.visibility = "visible"; 
        }
        return (
            


            <div className="roi-selector">      
                {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} retryCount = {this.state.retryCount} notifyOnLong = {true}/>:null}                          
                <ReactModal isOpen={this.state.shown} className="modal-dialog roi-selector-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={confirmClose}
                >
                    <div className="modal-content">

                        {m_img && (
                            <ReactCrop
                                src={m_img}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        
                        )}
                            <div className="roibuttons">
                            {/* <div className="blank-spacer"  /> */}
                                <motion.img  src={IconProcessing} className="process-button" onClick={this.onProcessClick}   whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}} />                                             
                                <motion.img  src={IconUpdate} className="update-button"  style = {roiUpdateStyle} onClick={this.onUpdateClick} whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}} />                                         
                            </div>
                        {/* Construct the display of the front side of the notecard: */}   
                        
                        {/* <div className="modal-body expanded-card">
                        </div>        */}
                    </div>
                </ReactModal>
            </div>
        );
    }
}