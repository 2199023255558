//OpenLayers imports:
import {LineString} from 'ol/geom';
import {Polyline} from 'ol/format';
import Feature from 'ol/Feature';
// import { fromLonLat} from 'ol/proj'; //save for animation disabled case
import { transform } from 'ol/proj';

//Other imports
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from '../map-utils.js'
import { getGreatCircleBearing} from 'geolib';

const gAnimationPathLengthMS = 3000; //expected time to complete the animation path
/**
 *  Extend the functionality of the Feature class, the Feature is used to render all Assets, Infractions, alerts.. on the Map
 *  The AssetFeature extends the OpenLayers class to include functions and attributes specific to the assets. This
 *  enables simpler interaction with the assets, and allows for better organization of the code responsiblities
 */
export class AssetFeature extends Feature{
    //Constructor: pass in the properties that are forwarded to the base class
    constructor(geometryOrProperties){
        //Pass the properties to the base class. This allows the AssetFeature to 
        //have all the functionality ofthe Feature class
        super(geometryOrProperties); 

        //Path animation
        this.mPath = null; //the path to animate the assets location
        this.mPathGPSPoints = []; //Tracked GPS points defining the animation path
        this.mAnimationProgress = 0; //the current progress along the path to render
        //Bearing calculation
        this.mPreviousPositionGPS = null;//Previous tracked GPS location used in the Bearing calculation
        //IIR filter
        this.mPreviousFilteredLocaion = null; //Previous GPS location
        //Mean filter
        this.filterArray = []; //array of points
        this.filterLength = 15; //lenght of array
    }

    /**
     * Process the API data return. Update the Assets stored state 
     * @param {*} _gps : GPS location stored in the update return
     * @param {*} _asset : remaining details from the update return (time, speed, inside...)
     */
    apiUpdate(_gps,_asset){ 
        //6 decimal is approximately 11.12cm accuracy
        let gpsLocation = {lat: parseFloat(_gps.lat,10).toFixed(6),lon: parseFloat(_gps.lon,10).toFixed(6) }
        this.updateLocation(gpsLocation); //update the GPS location
        this.set('time',_asset.time); //record the time of the last updated
        this.set('Speed',_asset.speed); //record the last know speed
        this.set('InsideGeoFence',_asset.inside);//indicate asset is in a marked region
    }//end apiUpdate

    /**
     * Update GPS location of the asset
     * @param {*} _loc: the GPS location {lon,lat} 
     */
    updateLocation(_loc){ 
        //Get the path from the asset:
        let lineString = null;
        if(!this.mPath){ //no path found: set the start to the new GPS location
            this.mPathGPSPoints=[[ _loc.lon, _loc.lat]];
        }else{ //set the current position as the start of the path
            //Get the current location: 
            let currentLoc = transform(this.getGeometry().getCoordinates(), COORDTYPE_MERCATOR, COORDTYPE_GPS); //Convert to GPS
            this.mPathGPSPoints = [currentLoc];
        }
        //Add the newly received GPS location to the set of points
        this.mPathGPSPoints.push([ _loc.lon, _loc.lat]);

        //Reset the animation progress:
        this.mAnimationProgress =0;
        this.filterArray=[];//clear the filtering array
        
        //Create a linestring from the GPS points
        lineString = new LineString(this.mPathGPSPoints); //same start and end point
        //Pass the lineString into a polyline to store as the mPath
        var pathInput = new Polyline({factor: 1e6}).writeGeometry(lineString);
        //Extract the polyline and  transform to the mercator projection for the display
        this.mPath = new Polyline({factor: 1e6,}).readGeometry(pathInput, {dataProjection: COORDTYPE_GPS, featureProjection: COORDTYPE_MERCATOR});

        //Update the position of the rendered asset: (don't remove, this must be uncommented if animation is disabled)
        // this.getGeometry().setCoordinates(fromLonLat([ _loc.lon, _loc.lat]));

        //store the updated location in GPS coordinates (for reference only, does not affect position)
        this.set('Lat',_loc.lat);
        this.set('Lon',_loc.lon);

        //Compute the difference from the last know GPS location
        let prevPos = {lat:this.get('prevLat'), lon: this.get('prevLon') }
        let dLon = _loc.lon-prevPos.lon
        let dLat = _loc.lat-prevPos.lat
        //Compute a bearing based of a previous GPS point
        //Only update the prev values once threshold met
        if(Math.abs(dLon) > 0.0000005 || Math.abs(dLat) > 0.0000005){ //how much change is this
            //  console.log("Diff reached: ",dLon,dLat,featureToUpdate.get('name'))
            this.set('prevLat',_loc.lat-dLat/2);
            this.set('prevLon',_loc.lon-dLon/2);
            this.updateBearing(prevPos,_loc);
            
        }//end bearing update
    }//end update location

    /**
     * Update the rotation of the feature based on the current and previous location of the asset
     * The greater circle bearing is computed from GPS locations to determine the correct location
     * @param {*} _prevLoc : previous known location
     * @param {*} _currentLoc : current known location
     */
    updateBearing(_prevLoc, _currentLoc){
        if(!_prevLoc){ //if no previous location is known then save the current location as previous
            if(!this.mPreviousPositionGPS){this.mPreviousPositionGPS = _currentLoc;}
            _prevLoc = this.mPreviousPositionGPS;
        }

        //Compute the change in location (lat and lon)
        let dLon = _currentLoc.lon-_prevLoc.lon
        let dLat = _currentLoc.lat-_prevLoc.lat
        //Check if the position has changed more than (50mm) (6 decimal places is 111mm in accuracy)
        if(Math.abs(dLon) > 0.0000005 || Math.abs(dLat) > 0.0000005){ 
            this.mPreviousPositionGPS = _currentLoc; //update the previous location
            //Compute the bearing 
            let bearing = getGreatCircleBearing({ latitude:_prevLoc.lat, longitude:_prevLoc.lon },{latitude:_currentLoc.lat,longitude:_currentLoc.lon });
            bearing *=  (Math.PI/180); //convert the degress to radians
            this.set('bearing',bearing); //store the updated rotation in the feature
        }
    }//end updateBearing

    /**
     * Apply a mean filter to the last n points 
     * (the number of points is configured in the filterLength member variable)
     * @param {*} _newValue : the new position to be added to the old position
     * @returns Mean of the last n points
     */
    meanFilterPt(_newValue){
        
        try {
            this.filterArray.push(_newValue);
            if(this.filterArray.length <=1){return _newValue;} //escape mean calculation if only one value is known
    
            //fix the length of the stack
            if (this.filterArray.length >= this.filterLength) {
                this.filterArray.shift(); //remove the first element
            } 
    
            //Compute the sum on Latitude 
            let summedValuesLat = (this.filterArray).reduce(function(accumulator_, item_) {      
                accumulator_ += (item_ && !isNaN(item_[0]))?item_[0]:0; //count each item that has the property value defined
                return accumulator_; //return the accumulated value to be used in the next cycle
            }, 0);
            //Compute the sum on Longitude 
            let summedValuesLon = (this.filterArray).reduce(function(accumulator_, item_) {      
                accumulator_ += (item_ && !isNaN(item_[1]))?item_[1]:0; //count each item that has the property value defined
                return accumulator_; //return the accumulated value to be used in the next cycle
            }, 0);

            //Compute the mean value
            let filteredReturn = [0,0];
            filteredReturn[0] =summedValuesLat/this.filterArray.length;
            filteredReturn[1] =summedValuesLon/this.filterArray.length;
            
            return filteredReturn;
        } catch (error) {
            console.log("mean error: ",error,_newValue);
            return _newValue;
        }
    }//end meanFilterPt

    /**
     * Compute a simple IIR filter using a bias weight
     * @param {*} _newPosition: the new position to be added to the old position
     * @param {*} _weight: the bias wieght to be applied to the new position
     * @returns wieghted average of the old position and the new position
     */
    simpleIIR(_newPosition, _weight){
        if(!this.mPreviousFilteredLocaion){
            this.mPreviousFilteredLocaion = this.getGeometry().getCoordinates();
        }
        let returnPt = [0,0];
        returnPt[0] = _newPosition[0]*_weight + this.mPreviousFilteredLocaion[0]*(1-_weight);
        returnPt[1] = _newPosition[1]*_weight + this.mPreviousFilteredLocaion[1]*(1-_weight); 
        this.mPreviousFilteredLocaion = returnPt;
        return returnPt;
    }//end simpleIIR

    /** Method to get the position of the asset given an animation step, this is called from the animateAssets
     *  method that is triggered by the OpenLayers render stack 
     */
    getPathPosition(_step){
        if(!this.mPath){return this.getGeometry().getCoordinates();} //return the current position if a path is not defined

        //Constrain the step and the progress to keep the values within the 0,1 range
        _step = Math.max(0,_step);
        _step = Math.min(1,_step);
        this.mAnimationProgress+=_step; //accumulate the progress in the iterpolated path
        this.mAnimationProgress = Math.max(0,this.mAnimationProgress);
        this.mAnimationProgress = Math.min(1,this.mAnimationProgress);

        try {
            let returnPt = this.mPath.getCoordinateAt((this.mAnimationProgress)); //get the interpolated value between the two GPS points            
            // let filteredPt = this.simpleIIR(returnPt,0.5); //apply a simple filter to try to reduce jitter
            let filteredPt = this.meanFilterPt(returnPt); //apply a mean filter on the point to reduce jitter
            return filteredPt;
        } catch (error) {
            // console.log("Failed on interp:" ,error);
            return this.getGeometry().getCoordinates(); //on failure return the current point of the feature
        }
    }//end getPathPosition
}//end AssetFeature

let lastTime = Date.now(); //track the last time the post-render callback was triggered 
/** Create a callback function to trigger after the map has finished rendering, this sets up a loop for animations (initial testing has it rendering at ~ 60fps) */
export const animateAssets = (_event, _map, _layers)=> {
    //Get the elapsed time between animation steps
    const time = _event.frameState.time;
    _event.frameState.animate = true;
    const elapsedTimeMS = time - lastTime; //in ms
    lastTime = time; //update the last time with the current rendered time
    //Need to get the array of assets
    let assets = _layers['asset'].getFeatures(); 
    if(assets.length ===0){return;} //No assets loaded to animate (return from callback)    
    let filteredAssets = (assets||[]).filter(item => { 
        return item.get('onSite') === true} //only update the animation for the assets that are tagged to the site
    );
    if(filteredAssets.length ===0){return;} //No assets loaded to animate (return from callback)    
    //Iterate over all the assets:
    (filteredAssets || []).forEach( (asset_) =>{
        try {
            //Compute the elapsed time since last update (step)
            let animationStep = (elapsedTimeMS / gAnimationPathLengthMS);
            //Get the position along the path (interpolate between the closest points on the path)
            let position = asset_.getPathPosition(animationStep);
            let assetGeometry = asset_.getGeometry(); //get the link to the geometry (point) of the feature
            //Check how far the marker has moved since the last update to the position
            let delta = [0,0];
            let lastMarkerFeaturePosition = assetGeometry.getCoordinates(); //get the last know position of the feature
            //Compute the delta in X,Y coordinates
            delta[0] = Math.abs(lastMarkerFeaturePosition[0] - position[0]);
            delta[1] = Math.abs(lastMarkerFeaturePosition[1] - position[1]);
            let minDelta = _event.frameState.viewState.resolution; //get the resolution of the map from the current zoom level
            minDelta*=1.1;
            //If the change is greater than the resolution then update the position
            //If the position is updated and the change is less than the resolution then the map will shift the coordinate to fit the resolution
            //This causes jittering in the position
            if(delta[0] > minDelta || delta[1] > minDelta){
                assetGeometry.setCoordinates(position);
            }
        } catch (error) {
            console.log("Error in feature anim: ",error);
        }
    });//end iteration of the assets
    _map.render(); //trigger a rerender on the map (maybe only do this if there were any active assets updated)
}//end animateAssets
