import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';

import { ApiCaller } from '../ApiCaller.js';
import { displayStatus} from '../Util.js';
import { DriverCard } from './DriverCard.js';

import { useImperial,gpsStringToCoords } from '../UtilOpenLayers.js';
import * as moment from 'moment';
// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import '../Analytics/VideoLister.css';
import './AdminDriverManagement.css';
import IconEnable from '../assets/add.png'

//Define the formatters:
// this just displays a dash instead of empty cells when there's no data
const nullableFormatter = (thing, row, rowIndex, extraData) => {
    if (thing === undefined || thing === null || thing === '' || thing === ' ') {
        // return <span>&mdash;</span>;
        return "None";
    }
    return thing;
}

const dateFormatter = (_date,_row) => {
    // console.log("speed: ",_speed,_row)
    let date = _date;
    
     try {
    //     //     driver_.assigned = moment(driver_.assigned).format('MMM-DD, YYYY');    
    date = moment(_date).format('MMM-DD, YYYY');    
     } catch (error) {}
    
    return date;
}//end speedFormatter

const statusFormatter = (_status, row, rowIndex, extraData) => {
    if(_status === 'DISABLED'){
        return <span className = 'disabledAccount'>{_status}</span>;    
    }
    return _status;
}//end statusFormatter



export class AdminDriverManagement extends Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);

        this.handleSearch = this.handleSearch.bind(this);
        this.updateAssignmentInSQL = this.updateAssignmentInSQL.bind(this);
        this.confirmRow = this.confirmRow.bind(this);
        this.driveridFormatter = this.driveridFormatter.bind(this);

        this.disableDriver = this.disableDriver.bind(this);
        this.handleDisableDriver = this.handleDisableDriver.bind(this);
        this.handleShowDisabledToggle = this.handleShowDisabledToggle.bind(this);

        this.state = {
            driverList: [],
            baseDriverList:[],
            columns:[],
            retryCount: 0,
            driverCard:null,
            bShowDisabled: false,
        };
      // setupPerf(this, 'VideoLister', () => {return this.state.videos && this.state.videos.length > 0;});
    }
    onLoadingState(state) {
      this.setState({loadingState: state});
    }
    
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        //  console.log("Get Asset data : ",this.props);
        //define the list of allowed sites to return:
        let siteList = [];
        if(this.props.possibleFilters && this.props.possibleFilters.Sites){
            //Extract the name of the sites from the site,gps pair
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
        } 
        // console.log("Pass sitelist: ",siteList,this.props.possibleFilters)

        const realPromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleDrivers";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    clientid: this.props.groupconfig.group,
                    sites: siteList,
                    mode: 'fetch',              
                      
                }
            };
           return API.post(apiName, path, myInit);
        }
       )
       .catch((error) => {
         console.error("UserManagent api call; ",error); 
       }); 
        
         return realPromise;
    }
  
   
    /*
    * @brief Takes care of updating the list with new data when we receive it
    */
    updateData(data) {
        //  console.log("driver Data: ",data);
        if(!data.drivers){return;}
        try {
            let filteredAssets = (data.drivers||[]).filter(driver_ => {
                if(this.state.bShowDisabled===false){
                    return driver_.status === 'disabled'? false:true;
                }else{return true;}
            
            })
            // console.log("Filtered assets:", filteredAssets);
            // console.log("user: ",this.props)
            
            let driverList = (filteredAssets||[]).map(driver_ => {
                // if(!driver_.status){driver_.status = 'enabled'}
                driver_.confirmFlag = false;
                //Are the registered site and the trained site different?
                if(driver_.site !== driver_.foundsite){
                    if(this.props.username ==='bis_user'){
                        driver_.confirmFlag = true;
                    }
                    //No registered site but trained:
                    driver_.site = driver_.site || driver_.foundsite;
                    
                }
                driver_.alias = driver_.site;
                if(driver_.site){                        
                    try {
                        //If we have a list of sites, match the name to the list to make sure the formatting of the name is the same:
                        if(this.props.possibleFilters && this.props.possibleFilters.Sites){
                        
                            let matchedSite = this.props.possibleFilters.Sites.filter(site_=> site_.site.toLowerCase()===driver_.site.toLowerCase())
                            if(matchedSite[0]){
                                // console.log("Found match: ",matchedSite[0]);
                                // assetSet[asset_.assetid].siteid = matchedSite[0].site;
                                driver_.alias = matchedSite[0].alias;
                            }
                        }    
                    } catch (error) {}
                }
                

                driver_.reactKey = driver_.driverid + '-' + driver_.clientid;

                return driver_;        
            });
            // console.log("driverlist: ",driverList);

            //User list is display, base user list is used to reset
            this.setState({driverList:driverList,baseDriverList:driverList });
        } catch (error) {
            console.log("Failed to parse assets: ",error);   
        }
    }

    /*@brief Send the updateto the SQL table via the AuthLambda API
    */
    updateAssignmentInSQL(_id,_site){
        Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleDrivers";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    driverid: _id,
                    site: _site,
                    mode: 'updateSite',              
                        
                }
            };
            return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("UserManagent api call; ",error); 
        }) 
        .then((_data)=>{//wait for the promiste to complete
            console.log("Success on confirm:" ,_data,_data.error,_data.error && _data.error === false);
            try {
                if(_data.error === false){
                    console.log("Trigger update");
                    this.setState({retryCount:this.state.retryCount+1});
                }    
            } catch (error) {}
            
            
        });   
    }

    /*@brief handle the click on the assign button
    */
    confirmRow(e,cell,row){
        try{
        e.stopPropagation(); //console.log("Click on button",e,cell,row);
        console.log("Clicked",row);
        //Trigger the update to SQL table
        this.updateAssignmentInSQL(row.driverid,row.site);
        }catch(e){
        // console.log("Click handle error: ",e);
        }
    }

     // Format the display of the driverid
    driveridFormatter(cell, row, rowIndex, extraData){
        
        //Break this into a stacked div so we can also render the exclamation mark
        // className='confirmButton' 
        return  <div className='dm-driveridFormat'> 
                {row.confirmFlag? <button type="button" className="btn confirmButton" 
                                    onClick={(e)=>this.confirmRow(e,cell,row)}
                                >Confirm</button>:null}
                <div className='driverid-name'>
                    <span className={'driverid-span'}>{cell}</span>
                </div>
                
                </div>;
    };//end driverIDFormatter
  
    
    /* @brief Run once when the class is leaving
    */
    componentWillUnmount(){
    }
    /*
    * @brief The runs once when the component first mounts
    */
    componentDidMount(){

        // console.log("Mount Drivers tab: ",this.props);

        let siteList = [];
        if(this.props.possibleFilters && this.props.possibleFilters.Sites 
            && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
            //Extract the name of the sites from the site,gps pair
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
        } 

     
        /* Describe the columns of the table
        * 'dataField' is the name of the key in 'data' list that it will read from
        * 'text' is what it will display on the webpage for the column header
        * 'formatter' is a custom formatting function it will use for that column to transform from
        *             the data in the list to what it displays
        * 'sort' set to true to allow the column to be sorted
        * 'hidden' is if the column should be hidden from view, this is useful so we can get a
        *          react 'key' from a column that we don't actually want to show to the user
        */

        //Check on the permissions, assume write permission for legacy support:
        let bPermissionHide = false;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.users ==='read'){bPermissionHide = true;}

        let cols = [
           
            {dataField: 'name', text: 'Name',editable:false, sort:true,formatter: this.driveridFormatter, headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'ein', text: 'EIN',editable:false, sort:true,formatter: this.driveridFormatter, headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'alias', text: 'Site',editable:false,sort:true, formatter: statusFormatter,headerStyle: () => {return { width: "18%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'driverid', text: 'Trifecta ID',editable:false, sort:true,formatter: this.driveridFormatter, headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},            
            {dataField: 'assigned', text: 'Date Trained',editable:false,sort:true, formatter: dateFormatter,headerStyle: () => {return { width: "18%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'remove', text: 'Enable / Disable',editable:false, hidden:bPermissionHide, formatter:this.disableDriver, headerStyle: () => {return { width: "6%",whiteSpace:"unset",textAlign:"center"};}},
          ];
        
          //set the classnames for each column:
          cols.map(col => {
            col.classes = 'dm-' + col.dataField;
            return col;
          });
        this.setState({columns: cols})
    }

     /*
    * @brief Define what is rendered in the remove user column
    */
     disableDriver(cell,row){
        
        if(row.status && row.status.toLowerCase() === 'disabled'){
            return  <div>
                        {/* <img src={IconEnable} className="enableUser" onClick={(e)=>this.handleDisableDriver(e,row,{type:'enabled'})}/>  */}
                        <button type="button" className="enableDriver" onClick={(e)=>this.handleDisableDriver(e,row,{type:'enabled'})}>
                            enable
                        </button>
                    </div>  
        }else{
            return  <div>
                        <button type="button" className="disableDriver"  onClick={(e)=>this.handleDisableDriver(e,row,{type:'disabled'})}>
                            disable
                        </button>
                    </div>  
        }
    };

    //  // Format the display of the driverid
    // assetFormatter(cell, row, rowIndex, extraData){
        
    //     //Break this into a stacked div so we can also render the exclamation mark
    //     // className='confirmButton' 
    //     return  <div className='am-assetFormat'> 
    //             {!row.siteknown? <button type="button" className="btn confirmButton" 
    //                                 onClick={(e)=>this.confirmRow(e,cell,row)}
    //                             >Confirm</button>:null}
    //             <div className='asset-name'>
    //                 <span className={'asset-span'}>{cell}</span>
    //             </div>
                
    //             </div>;
    // };//end driverIDFormatter



    /*@brief handle the click on the remove user button
    */
    handleDisableDriver(e,row,_options){
        
        try{
            e.stopPropagation(); 
             console.log("Click on remove? ",e,row,_options);              
            
            // return;
            if(!row.driverid){return;}

            let displayString ="Please confirm that you wish to ";
            if(_options.type==='disabled'){
                displayString +="disable";
            } else{
                displayString +="enable";
            }
            displayString +=" "+row.driverid;

            if(!window.confirm(displayString)){
                return;
            }
            
            //Dispatch the update to the SQL table:
            const removePromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDrivers";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'updateStatus',
                        driverid: row.driverid,
                        status: _options.type,
                    }
                };
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("UserManagent remove site Fail; ",error); 
            })
            .then((_data)=>{//wait for the promiste to complete
                console.log("Remove site returned success:" ,_data);
                this.setState({retryCount:this.state.retryCount+1});
            });         
        }catch(e){
            // console.log("Click handle error: ",e);
        }
    }//end handleRemoveSite

     /*
    * @brief Called on update to the show disabled users checkbox
    */
     handleShowDisabledToggle(){
        this.setState({bShowDisabled: !this.state.bShowDisabled,retryCount:this.state.retryCount+1})
    }


    /*
    * @brief Called on update to the input search field, filter the listed assets:
    */
    handleSearch(_data){
        let udpatedSearchValue = _data.target.value;
        udpatedSearchValue = udpatedSearchValue.toLowerCase();
        // console.log("Name to filter: ",updatedSearchName);
        //Filter the list of assets by the received value:
        let filteredUsers = (this.state.baseDriverList || []).filter(driver_=>{
            try {
                return driver_.driverid.toLowerCase().includes(udpatedSearchValue)    
            } catch (error) {
                return false;
            }
            
        });
        //also apply filter to the site
        let filteredEmail = (this.state.baseDriverList || []).filter(driver_=>{
            try {
                return driver_.site.toLowerCase().includes(udpatedSearchValue)    
            } catch (error) {
                return false;
            }
        });
        //also apply filter to the name
        let filteredName = (this.state.baseDriverList || []).filter(driver_=>{
            try {
                return driver_.name.toLowerCase().includes(udpatedSearchValue)    
            } catch (error) {
                return false;
            }
        });
        //also apply filter to the EIN
        let filteredEIN = (this.state.baseDriverList || []).filter(driver_=>{
            try {
                return driver_.ein.toLowerCase().includes(udpatedSearchValue)    
            } catch (error) {
                return false;
            }
        });
        //Merge the two list - use the set to remove duplicates, use the spread to convert to array:
        let merged = [...new Set([...filteredUsers ,...filteredEmail,...filteredName,...filteredEIN])];
        this.setState({driverList: merged});
    }

    /*
    * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
    */
    render() {

        const driverData = this.state.driverList;
        const tableColumns = this.state.columns;
        
        //Define the table interaction callbacks:
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                // console.log("Row clicked: ",e,row,rowIndex);
        //    this.props.videoClicked(row);
                const card = {
                    driverid: row.driverid,
                    clientid: this.props.groupconfig.group,
                };
                this.setState({driverCard:card});
            }
        };
        //  {/* <ProgressBar className="testpb" striped variant="success" now={35} key={1} label={`30%`}  /> */}
        const rowClasses = (row, rowIndex) => {
            return 'driver-lister-row row-status-' + displayStatus(row.status).toLowerCase();
        };
    
        /*
            Set style for the rows in the table     
        */
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){
             rowcolor = '#00afed20'
            }
            return{
             backgroundColor: rowcolor,
            }
        };

        
        let scale = 1;
        if (this.state.winWidth && this.state.divWidth) {
            scale = this.state.winWidth / this.state.divWidth;
        }
        const mapStyle = {
            backgroundColor: '#cccccc',
            height: "400px",
             width: "400px" 
        }

        //Pagination options:        
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{
              text: '10', value: 10
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        //Check on the permissions, assume write permission for legacy support:
        let bAllowEdits = true;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.assets ==='read'){bAllowEdits = false;}

        // console.log("AssetData:" ,this.state,driverData);
        return (
            <div className="asset-lister">
                {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} retryCount = {this.state.retryCount}/>:null}
                <div className='topRow'>
                    <div className='totalassets'>{'Total Drivers: '+driverData.length}</div>

                    <div className="show-disabled-users">
                        <input className= 'disabledCheck' type="checkbox"  value="Show Disabled" checked={this.state.bShowDisabled===true}  onChange={this.handleShowDisabledToggle} />
                        <label className= 'disabledText'>
                            {this.state.bShowDisabled?"Hide Disabled Drivers":"Show Disabled Drivers"} 
                        </label>
                    </div>

                    <div className='search-title'>Driver Search: </div>
                    <input type="text" id="usernameSearch" className='usernameSearch'
                                    onChange = {this.handleSearch}
                    />
                </div>

                {tableColumns.length>0 && 
                    <BootstrapTable 
                                keyField='reactKey' // a react specific thing that sets the 'key' for each row in the table
                                                    // react uses keys to keep track of identity when things change
                                data={driverData} // <-- IMPORTANT: this is the actual data being displayed
                                columns={tableColumns}
                                defaultSorted={[
                                                {dataField: 'driverid', order: 'asc'}, // how things should be sorted by
                                                {dataField: 'site', order: 'asc'}
                                                ]} // default when first displayed
                                striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                // keep track of what data belongs to what row when scanning across
                                rowStyle={ rowStyle}
                                hover={true}   // sets a hover effect, so the background color of a row changes when the
                                //                  // mouse is over it. This signals to the user that the row is clickable
                                classes={"asset-lister"} // sets a CSS class so we can style this table specifically
                                rowEvents={bAllowEdits?rowEvents:null} // set what events we want to happen on rows, right now just the click
                                rowClasses={rowClasses}
                                // cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                                bootstrap4 = {true}
                                // expandRow={ expandRow }
                                detailView={true}
                                detailViewIcon={false}
                                detailViewByClick={true}
                                pagination={ paginationFactory(paginationOptions) }
                    />
                }
                { this.state.driverCard &&
                    <DriverCard handleClose={(_data,_bSubmit)=>{
                                            // console.log("Closed driverCard", _data,_bSubmit,this.state);
                                            //If submitted, then update the data in the list:
                                            if(_bSubmit){this.setState({retryCount:this.state.retryCount+1});}
                                            this.setState({driverCard:null})
                                        }}
                                    {...this.state.driverCard}
                                    assets = {this.props.possibleFilters.Assets}
                                    users = {this.state.userList}
                                    scale={scale}   
                                    groupconfig = {this.props.groupconfig}
                                    handleSubmit={
                                         this.handleCardReturn
                                    }
                    />
                }
               

            </div>
        );
    }//end of render
  }
  
  