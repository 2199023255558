
import React, { useState } from 'react';
import ReactModal from 'react-modal';

import './NotecardDriverID.css';
import './DriverDetails.css';

import blankAvatar from '../assets/avatar-blank-large.png';

import { ApiCaller,Spinner } from '../ApiCaller.js';

import { Auth, API } from 'aws-amplify';


export const DriverDetail = ({  
                                driverid
                                ,confidence
                                ,selected
                                ,onClick= () =>{console.log("DriverDetail - No click callback defined")}
                                ,asset
                            }) =>{

    const [bApiReturned, setAPIReturned] = useState(false);  
    const [driverName, setDriverName] = useState(null);  
    const [driverPhoto, setDriverPhoto] = useState(null);  

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //   console.log("load driver detail: ",driverid);
          if(driverid.length ===5){
            let fetchPromise = fetchDriverPhotos();
            fetchPromise.then(_data=>{
                updateData(_data);
            });
            fetchPromise.catch(error=>{console.log("Failed to fetch: ",error);})
          }else{

            setAPIReturned(true);
          }
          
    },[driverid]) //[] -> execute once

    React.useEffect(()=>{
        // console.log("Call DriverDetail: ",driverid);
    },[]) //[] -> execute once

    /*
    * @brief Fetch the data from the database given the driverid
    */
    function fetchDriverPhotos(){
        //Make an API call to download some photos:
        //Call in parallel to allow for quicker update to the render?
        let idx =0;
        //Dispatch the update to the SQL table:
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDriverInfo";
                let myInit = {};
                myInit.body = {
                    token: auth.idToken.jwtToken,
                    index: idx++,
                    driverid: driverid,
                    mode: 'fetch',              
                }
                if(asset){
                    myInit.body.assetid = asset;
                    myInit.body.mode = 'fetchbyasset'
                }
                return API.post(apiName, path, myInit);
        })
        return fetchPromise;

    }
    /*
    * @brief handle database return information
    */
    function updateData(_data) {
        // console.log("Data return: ",_data);
        if(!_data || !_data.driverinfo){return;}

        try {
            let photoData = _data.driverinfo.photo;
            if(!photoData.includes('data:image')){            //Is it tagged?
                console.log("Attach tag")
                photoData = 'data:image/jpeg;base64,'+_data.driverinfo.photo;
            }
    
            let driverName = _data.driverinfo.name;
            setDriverName(driverName);
            setDriverPhoto(photoData);
            setAPIReturned(true);
        } catch (error) {
            console.log("Failed to load driver details: ",error,_data);
        }
        
    }
    /*
    * @brief Handle clicking on the driver photo
    */
    function handleClick() {
        // console.log("Clicked: ",this.props);
        onClick(driverid)
    }

    /*
    * @brief Draw the photo and driverid
    */
    function render() {
        let isSelected = driverid===selected?'true':'false';
        let backImgStyle = {};
        backImgStyle.backgroundImage = `url(${blankAvatar})`;
        if(driverPhoto){
            backImgStyle.backgroundImage = `url('${driverPhoto}')`;
        }
        return (
            <div className='driver-select' selected-driver={isSelected} onClick={handleClick} key={'driver-details-'+driverid}>
                {bApiReturned?
                    <div className="driver-details">
                        {confidence? <div className='cell-label'>Conf: {confidence}/10</div> : <div/>}
                        <div className="driverphoto" blank={driverPhoto?"false":"true"} style = {backImgStyle}/>
                        <div className='cell-label'>{driverid}</div>
                    </div>
                
                :
                    <Spinner />
                }
                
            </div>
             
        )
    }

    return render();
}