
/**
 * Enumarate the states and messages displayed to the UserConfirm from the CutVideoNotecard
 */
export class MESSAGE_ENUM {
    // Private Fields
    static #CUT_CONFIRM = 1;
    static #ARCHIVED = 2;
    static #AVAILABLE = 3;
    static #UNAVAILABLE = 4;
    static #PENDING = 5;    
    static #OVERLAP = 6;
    static #BOUND  = 7;

    // Accessors for "get" functions only (no "set" functions)
    static get CUT_CONFIRM() { return this.#CUT_CONFIRM; }
    static get AVAILABLE() { return this.#AVAILABLE; }
    static get UNAVAILABLE() { return this.#UNAVAILABLE; }
    static get PENDING() { return this.#PENDING; }
    static get ARCHIVED() { return this.#ARCHIVED; }
    static get OVERLAP() { return this.#OVERLAP; }
    static get BOUND() { return this.#BOUND; }

    static STRING(_number){
        switch(_number){
            case this.#CUT_CONFIRM:{ return 'CUT_CONFIRM'};
            case this.#AVAILABLE:{ return 'AVAILABLE'};
            case this.#UNAVAILABLE:{ return 'UNAVAILABLE'};
            case this.#PENDING:{ return 'PENDING'};
            case this.#ARCHIVED:{ return 'ARCHIVED'};
            case this.#OVERLAP:{ return 'OVERLAP'};
            case this.#OVERLAP:{ return 'BOUND'};
        }
    }
    static TEXT(_number){
        switch(_number){
            case this.#CUT_CONFIRM:{ return 'CUT_CONFIRM'};
            case this.#AVAILABLE:{ return 'AVAILABLE'};
            case this.#UNAVAILABLE:{ return 'DVR data is unavailable for this time frame, a custom length video cannot be cut.'};
            case this.#PENDING:{ return 'Data upload is pending. Please connect the Asset to the Internet to continue DVR data upload. Would you like to continue?'};
            case this.#ARCHIVED:{ return 'DVR must be restored from Archive before processing can complete, this will require an additional 24 hours. Would you like to proceed?'};
            case this.#OVERLAP:{ return 'A Video Cut already exists that covers this time period, would you like cut this new video with the current timeframe?'};
            case this.#BOUND:{ return 'The time before and after have been clamped due to available DVR data and maximum video cut length of 1 hour, are these time acceptable?'};
        }
    }
}
