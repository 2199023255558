import React, { Component } from 'react';

import './navbar.css';
import './userprofile.css'

/*
* @brief A widget for user menu items
*/
class UserProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.state = {
            showMenu: false,
        };
    }
    onMenuClick(e) {
        this.setState({showMenu: !this.state.showMenu});
        e.preventDefault();
    }
    render() {
        if (!this.props.username) {
            return null;
        }
        const subClass = "show-notification profile-notification" + (this.state.showMenu ? " shown-menu" : "");
        return (
            <li className="user-profile header-notification" onClick={this.onMenuClick} >
                <a href="#!" onClick={this.onMenuClick} >
                    <span>{this.props.username}</span>
                    <i className="ti-angle-down"></i>
                </a>
                <ul className={subClass}>
                    <li onClick={this.props.onSignOut} >
                        <a href="#signout" onClick={(e) => {e.preventDefault(); this.props.onSignOut();}} >
                            <i className="ti-layout-sidebar-left"></i> Logout
                        </a>
                    </li>
                </ul>
            </li>
        );
    }
}


// Only exporting the FlatNavBar, the other pieces are considered internal details
export { UserProfileMenu};
