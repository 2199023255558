import React,{useState,useEffect} from 'react';
import { Auth, API } from 'aws-amplify';

import { DashboardFiltersView} from '../BaseComponents.js';
import { useDashDownloader } from './useDashDownloader.js';
//Wrap the components with the loading animation logic
import { withLoadingAnimation } from '../Wrappers/withLoadingAnimation.js';
import {TopDriverFunc} from '../BaseComponents.js';
import {InfractionPolarFunc} from '../DashboardWidgets.js'
const TopDriverWithLoading = withLoadingAnimation(TopDriverFunc);
const PolarWithLoading = withLoadingAnimation(InfractionPolarFunc); //
  


export const DashboardRow2 = ({ 
                                    onFilterSelected=()=>{console.log("Row2 failed to link to eventNotify")}
                                    ,onApply=()=>{console.log("Row2 failed to link to onApply")}
                                    ,filter
                                    ,defaultFilter
                                    ,possibleFilters
                                    ,groupconfig
                                    ,username
                                    ,options
                                    ,filteredData
                                    ,dataReady
                                    ,startDate,endDate
                                    ,driverData
                                    ,polarData
                                    ,...rest}) => {


    const [displayedFilters, setFiltersDisplay] = useState({});
    const [activeFilters, setActiveFilters] = useState({
        quant: defaultFilter.quant,
        driver: defaultFilter.driver,
        infractions: 'Severe Drowsiness',
    });
    const [polarFilters, setPolarFilters] = useState(activeFilters)

    
    

    
    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //  console.log("Row 2 loaded: ",groupconfig, defaultFilter);
        //  onApply(activeFilters); //initial load, start a query to load first data set
        //  fetchQuantizedData(activeFilters);
        onApply(activeFilters);
        setPolarFilters(activeFilters); 
    },[])


//     React.useEffect(()=>{
//         console.log("Row 2 data recieved: ",polarData);
//    },[polarData])

    
    
    React.useEffect(()=>{
        let filteredInfractions = (options.infractions||[]).filter(elem=>{return elem!=='Speeding'});
        let trifectaSites = [...new Set((possibleFilters.AssetsTrifecta||[]).map(elem_ => {return elem_.site}))]
        let sites = (possibleFilters.Sites||[]).filter(elem_ => {
            if(trifectaSites.includes(elem_.site.toLowerCase())){return true;}                
            return false;
        });
        let tmp = {
            infractions: new Set((filteredInfractions||[]).map(elem_ => {return elem_.value})),
            driver: new Set((options.drivers||[]).map(elem_ => {return elem_.value})),
            quant: new Set([5,10,15,30,60]),
            sites:new Set(sites),
        }
        // console.log("Set tmp:" ,tmp);
        setFiltersDisplay(tmp);
   },[options])


   function onFilterChange(kind, value, _whichFilter){
        // console.log("Filter selected: ",kind, value.value,_whichFilter);
        switch(kind){
            case 'sites':
                if(value.value === 'All'){value.value = null;}
                break;
            case 'driver':
                if(value.value === 'All'){value.value = null;}
                break;
            default:
                break;
        }

        const filtersCopy = Object.assign({}, activeFilters);
        filtersCopy[kind] = value.value;
        if (!value.value) {
            delete filtersCopy[kind];
        }
        // console.log("Set active filters to: ",filtersCopy);
        setActiveFilters(filtersCopy)
   }
    function driverClicked(driverID) {
        // console.log("Driver click:", driverID);
        //Don't allow clicking on the graph for pintovalley
        return;
    }

    function applyFilter(){ 
        // fetchQuantizedData(activeFilters); 
        onApply(activeFilters);
        setPolarFilters(activeFilters); 
    }


     //Call Render 
     function render(){
        // console.log("Polar render: ",{...filteredData});
        //Return the formatted HTML:            
        return(
            <div className="dashboard-row-limity">
                {(groupconfig && groupconfig.driverid)? 
                    null 
                    : 
                    <div className="col-xl-4">
                        <div className="card counter-card-1">
                            <div className="card-block-big top-driver-wrapper">
                                <TopDriverWithLoading filter={filter} isWorst limit={3}
                                    onDriverClick={driverClicked} groupconfig={groupconfig} driverData = {driverData}/>
                            </div>
                        </div>
                    </div>
                }
                
                <div>
                    <DashboardFiltersView  
                                className='dashboard-filters-2ndrow' 
                                filters={displayedFilters}
                                options={{infractions:'noall'}}
                                activeFilters = {activeFilters}
                                onFilterSelected={(type,value)=>{ onFilterChange(type,value,'second')} } 
                                groupconfig={groupconfig} 
                                startDate={startDate} 
                                endDate={endDate}                     
                                onApply={()=>{applyFilter(activeFilters,'second')}}
                    />
                    
                    <PolarWithLoading serviceType='TRIFECTA' 
                                    filter={polarFilters}
                                    groupconfig={groupconfig} 
                                    hourly={(polarData.live.hour||[])}  
                                    infractionsToDisplay = {options.infracionsList||[]}
                                    // onDayClick={this.timelineDayClicked}
                                    filterCount={Object.keys((displayedFilters||{})).length}
                    />
                            
                </div>
            </div>  
        );
    }

    return render();

}//end DashboardView