
import React, {  PureComponent } from 'react';
import { setupPerf, perfDidClick  } from '../Perf.js';
import { displayInfraction, SEVERITY, generateUniqueID, filenameAlphaStripper,VIDEO_STATUS} from '../Util.js';
import { VIDEOREVIEW_NOREPORT } from '../Util-access.js';
import {  driveGroups,allowedGroups,VideoReviewGroups } from '../Util.js';
import { CardListFunc} from '../VideoCard.js';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import { ROISelector } from '../ROISelector.js';
import { Auth, API } from 'aws-amplify';
import { ReviewTabs } from '../VideoReview/ReviewTabs.js';

import * as moment from 'moment';
import '../VideoReview/VideoReviewer.css';

import IconRepeat     from '../assets/repeat-icon.png';
import { VideoReviewerType } from './DVRVideoReviewerType.js';
import { VideoReviewerButton } from './DVRVideoReviewerButton.js';
import {cleanDBCache} from '../DBUtil.js'
import Dexie from 'dexie'

import { StatefulButton } from '../VideoReview/vid-util.js';
import {getMetaDataByID, getTotalLessEjections, getButtonCSSClass, getButtonDisplayname, getCard} from '../VideoReview/vid-util.js'
import {dvrdeleted_message} from '../VideoReview/video-messages.js';


/*
* @brief A component for displaying multiple videos that can be switched between for review
*/
export class VideoReviewerClass extends PureComponent {
  constructor(props) {
    super(props);
    setupPerf(this, 'VideoReviewer', () => true);
    this.pauseVideos = this.pauseVideos.bind(this);
    this.shouldInitiallySend = this.shouldInitiallySend.bind(this);

    this.expandCard = this.expandCard.bind(this); 
    this.unexpandCard = this.unexpandCard.bind(this);
    this.deleteCardsWithTags = this.deleteCardsWithTags.bind(this);

    this.addCard = this.addCard.bind(this);
    this.cardTag = this.cardTag.bind(this);
    this.cardChange = this.cardChange.bind(this);
    this.changeDriverID = this.changeDriverID.bind(this);
    this.openNotecard = this.openNotecard.bind(this);
    this.closeNotecard = this.closeNotecard.bind(this);

    this.getVideoUpdate = this.getVideoUpdate.bind(this);
    // this.refreshVideoUpdate = this.refreshVideoUpdate.bind(this);
    this.updateButtons = this.updateButtons.bind(this);
    this.getButtonDataByType = this.getButtonDataByType.bind(this);
    this.markViewed = this.markViewed.bind(this);
    this.updateProgressAPI = this.updateProgressAPI.bind(this);
    this.handleLeave  = this.handleLeave.bind(this);
    this.overlayButtonClick = this.overlayButtonClick.bind(this);
    this.onCardCreate = this.onCardCreate.bind(this);
    this.ejectClick = this.ejectClick.bind(this);
    this.undoEjectClick = this.undoEjectClick.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.openROI = this.openROI.bind(this);
    this.closeROI = this.closeROI.bind(this);
    this.currentlyPlaying = this.currentlyPlaying.bind(this);
    this.initProgressTally = this.initProgressTally.bind(this);

    this.confirmPublish = this.confirmPublish.bind(this);
    this.clickUnusable = this.clickUnusable.bind(this);
    
    this.state = {
      // If there are no highlights, start with the Original Video, otherwise start with the
      // highlights
      
      play_states: {},
      workaround_playing: {},
      shouldPause:[],
      cardsByType: this.props.cardsByType || {},
      videos: this.props.videos,
      // chosen: this.props.videos.highlights ? "highlights" : "original",
      infractionLoadIndex: 100,
      chosen:null,
      activeTabKey: 'infractions',
      expandedCard: null,
      roiselector: null,
      firstload: true,
      playerRef:{},
      rejectionList:[],
      rejectionByType:{},
      updateTimer:null,
      skipClickTimer:moment(),
      playReady:[],
      processInfractionVideoTime:null,
      ejectSets:{},
      ejectionIds:{},
      playerKey:0,
      published:false,
      m_bReturned:false,
      m_bSubmitted: false,
      markedUnusable:this.props.video.status===VIDEO_STATUS.UNUSABLE,
      progressTally:{},
      progressTallyInFlight:new Set(),
      tagsreviewed:0,
      totalClips: 0,
      bookmark:{},
      bookmarkLoad:{},
      buttonData:[],
      highlightTabCount:0,
      m_initiallySent: new Set(),
      hasResults: false,
      tabs: [ {eventKey:"infractions",set: null, last:null,title:'Clips'},
              {eventKey:"highlights",set: null, last:null,title:'Highlights'},
              {eventKey:"driverfacing",set: null, last:null,title:'All'}],
      currentPlayingID: null,
      playerRef:[],
      loadedReturns:[],
      timeDebug: new Date(),
      forcePlayerRefresh: 0,
      ejectedClipsInFlight: new Set(),
      videoClientID:null,


      m_dataByCategory:[],
      singleLoadComplete: new Date(),
    };

    this.db = new Dexie('ReviewClip');
    this.db.on("blocked", function() {
      console.log ("Database upgrading was blocked by another window. Please close down any other tabs or windows that has this page open");
    });
    this.db.on("ready", function() {
      // console.log ("ready Please close down any other tabs or windows that has this page open");
    });
    this.db.on("versionchange", function() {
      console.log ("Version change Please close down any other tabs or windows that has this page open");
    });

    // this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    

    // Keep track of which videos have been seen so far, this is used in the chooser buttons
    // this.m_seenVideos = new Set();

    // Keep track of which videos have been initially sent to the server
    // this.m_initiallySent = new Set();
  }

  

  /* @brief Break out the update method for populating the button data.
  * Process the videos member variable passed from VideoHighlights.
  */
  updateButtons() {
    // console.log("UpdateButtons: ",this.props);
    const videos = this.props.videos;
    const byType = videos.byType;
    const clips = videos.clips;

    let hasResults = false;
    try{
      if(Object.entries(this.props.videos.clips).length !== 0){
        hasResults = true;
      }
    }catch(e){ }
    

    let totalInfractions = 0;
    // console.log("UpdateButtons(Statetype):",this.state.videos.byType);
    // console.log("UpdateButtons: ",this.state.videos);

    var progressTally = {};
    var infractionData = [];
   

  // Build a Set containing all infractions in the individual highlights table
  // console.log("Types: ",this.props.groupconfig);
    //Loop through the defined infraction set and add it to the total if in the seen set
    let iLoadIdx = this.props.groupconfig.infractionTags.length;
    this.props.groupconfig.infractionSet.forEach(inf => {
        infractionData.push({
          classification: inf,
          count: 0,
          streamURL: null,
          flag:0,
          loadOrder: iLoadIdx++,
          loadComplete : false,
        });
    });
    //add the ejected type for review:    
    if(this.state.videos.byType && this.state.videos.byType['ejected']){
      infractionData.push({
        classification: 'ejected',
        count: 0,
        streamURL: null,
        flag:0,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    

    //Loop through the defined driver facing infraction and set them to placeholders:
    let classIdx = 0;
    this.props.groupconfig.driverInfractionSet.forEach(inf => {
        infractionData.push({
          classification: inf,
          label: "Class "+classIdx++,
          count: 0,
          streamURL: null,
          flag: 2,
          loadOrder: iLoadIdx++,
          loadComplete : false,
        });
    });
    if(this.state.videos.byType && this.state.videos.byType['IngressClip']){
      infractionData.push({
        classification: 'IngressClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    if(this.state.videos.byType && this.state.videos.byType['EgressClip']){
      infractionData.push({
        classification: 'EgressClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    if(this.state.videos.byType && this.state.videos.byType['OtherHighGClip']){
      infractionData.push({
        classification: 'OtherHighGClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }

    // console.log("InfractionData: ",infractionData);

    //Loop through the defined tagged infraction and set them to placeholders:
    let testArray = [];
    iLoadIdx =0;
    (this.props.groupconfig.infractionTags || []).forEach(_tag=>{
      // console.log(_tag);
      if(_tag.type === 'Irrelevant'){ return;}
      let tmpObj = {
        set: [],
        classification: _tag.type,            
        count: 0,
        type: 'playlist',streamURL: 'playlist',
        loadOrder: iLoadIdx++,
        loadComplete : false,
      };
      infractionData.push(tmpObj);
    });  
    // console.log("InfractionData: ",infractionData)
    
    
    // console.log("ByType: ",byType);
    //Iterate over byType
    Object.values(byType||[]).forEach(v => {
      if(v.count ===0){
        //Handle a non returned count from the /ListVideos call
        if(clips[v.classification]){
          v.count =clips[v.classification].length;
        }
      }  
      if(v.set && v.count !== v.set.length){
        // console.log("Fix the count");
        v.count = v.set.length;
      }
      var index = -1;
      infractionData.forEach((row,idx) => {
        if(row.classification.toLowerCase() === v.classification.toLowerCase()){ //find the matching classification
          
          if(v.type === 'playlist'){ //these don't have the flag?
            // console.log("Row classification: ",idx,v,row);
            index = idx;
          }
          else{
            if(row.flag === v.flag){
              // console.log("Row classification: ",idx,v,row);
              index = idx;
            } 
          }
          if(row.classification === 'ejected'){
            // console.log("V: ",v);
             v.count = v.metadata.length;
          }
        }
        
      });
      if(index!==-1){
        // console.log("Set with byt")
        infractionData[index] = Object.assign(infractionData[index],v);
      } 
    });
    

    // return;

    infractionData.forEach(row => {
      //  console.log("Row count:" ,row.classification, row.count,row);
      if(row.type!=='playlist'){
        totalInfractions += row.count || 0;
      }      
    });
    // console.log("Videos: ",videos);
    // console.log("Infractiondata: ",infractionData);
    // Fill in a minimal highlights row so the greyed out button can be displayed for it
    const emptyHighlights = {
      classification: "Common",
    }


    if(this.props.review24Hr){
      //Loop through the clips, add them to the driverfacing types:
      Object.values(( clips || {})).forEach(typeArray => {
        // console.log("Clip type:" ,typeArray)
        typeArray.forEach(clip_=> {
          try {
            let typeRef = (infractionData||[]).filter(inf_ =>{return inf_.classification === clip_.classification});
            typeRef = typeRef[0];
            // console.log("REf: ",typeRef,clip_.classification,typeRef.count);
          if(typeRef.count ===0){ //make sure we load the count
              if(clips[typeRef.classification]){
                typeRef.count =clips[typeRef.classification].length;
              }
          }    
          } catch (error) {
            // console.log("Failed on: ",clip_.classification)  
          }
        })
      })
    }
   



    // console.log("infractionData ", infractionData);
    let buttonData = JSON.parse(JSON.stringify(infractionData));
    buttonData.unshift(videos.highlights || emptyHighlights);
    
    if(driveGroups.includes(this.props.groupconfig.group) && !videos.original){
    // if( !videos.original){
    }else{
      
        if(videos.original){buttonData.unshift(videos.original);}
        //force the original video to load on the 24Review page
        else if(this.props.video.vehicleType === '24Review'){
          buttonData.unshift({
            classification: 'source',
            type: 'original',
            count: 0,
            streamURL: null,
            flag: 0,
            loadOrder: iLoadIdx++,
            loadComplete : false,
          });
        }
    }

    let loadedReturns = [];
    const tabs = this.state.tabs;
    buttonData.forEach(row => {
      // console.log("Row to load: ",row);
      let chosenName;
      let displayName;
      try{ 
        if (row.type === "original") {
          chosenName = row.type;
          displayName = "Original Video";
          row.loadComplete = false;
          row.loadOrder = 51;

        } else if (row.classification === "Common") {
          chosenName = "highlights";
          displayName = "Overall Highlights";
          row.count = totalInfractions;
          row.loadComplete = false;
          row.loadOrder = 50;
        } else {
          chosenName = row.classification;
          //Change the display name for the infractions based on the loaded translation:
          displayName = displayInfraction(this.props.groupconfig.displayNameSet,row.classification)
        }
        if(row.label){displayName = row.label}
        // if(row.label === 'EgressClip'){displayName = row.label}


        row.chosenName = chosenName;
        row.displayName = displayName;

        //Set a default button to select for each tab group:
        if(row.type === 'playlist' && row.count > 0){
          tabs[0].last = chosenName;
          loadedReturns.push(chosenName);
        }
        if(row.type !== 'playlist' && row.flag===0 && row.count > 0){
          tabs[1].last = chosenName;
        }
        if(row.flag===2 && row.count > 0){
          tabs[2].last = chosenName;
        }


      }catch(e){}
    });

    let ejectionSets = this.state.ejectSets;

    // console.log("Shouldenter: ",this.state.progressTally)
     if(!this.state.progressTally.length){
      //  console.log("Not set entered progress tally init")
       try {
        let progressTemp = JSON.parse(this.props.reviewStatus[0].tagsviewed);
        // console.log("Resume: ",progressTemp)
        Object.keys(progressTemp).forEach(function(key) {
          // console.log("Key: ",key);
          if(!progressTally[key]){progressTally[key]={viewed:new Set(progressTemp[key].viewed)}}
        });
        // progressTally = JSON.parse(this.props.reviewStatus[0].tagsviewed) 
       } catch (error) {
        // console.log("Failed to resume: ",error);
        Object.values(( clips || {})).forEach(typeArray => {
          typeArray.forEach(clip => {
            let metadataPair = getMetaDataByID(buttonData,clip.infractionID);
            if(metadataPair){
              if(!progressTally[metadataPair.type]){progressTally[metadataPair.type]={viewed:new Set()}}
            }
          });
        }); 
       }//end catch
      
      this.state.progressTally = progressTally;
      //this.setState({progressTally:progressTally},console.log("New state: ",this.state));
    }

    //Scan through the objects and populate the eject sets based on the status:
    Object.values(( clips || {})).forEach(typeArray => {
      typeArray.forEach(clip => {
        if(clip.status){
          if(clip.status==='ToEject' ){
            let metadataPair = getMetaDataByID(buttonData,clip.infractionID);
            if(metadataPair){
              // console.log("Add to list: ",metadataPair,clip)
              let tmpName = metadataPair.type
              if(clip.flag && clip.flag == 2 ){
                tmpName += "-DF";
              }
              if( !ejectionSets[tmpName] ) {ejectionSets[tmpName]={ejectList:[], processing:false, processTime: moment()}} //create the default structure
              if(!ejectionSets[tmpName].ejectList.includes(metadataPair.data)){
                ejectionSets[tmpName].ejectList.push(metadataPair.data);
              }

              if(metadataPair.type!=='highlights'){
                if( !ejectionSets['highlights'] ) {ejectionSets['highlights']={}; 
                ejectionSets['highlights'].processing=false; 
                ejectionSets['highlights'].processTime=moment();
                ejectionSets['highlights'].ejectList=[]; } //create the default structure
                ejectionSets['highlights'].ejectList.push( metadataPair.data);
              }
            }//end null check
          }//end ToEject check
        }
        
      });



    });
    // console.log("EjectSets: ",ejectionSets);
    // console.log("Set ButtonData: ",buttonData,this.props.cardsByType,this.state.videos);
    // console.log("Review test: ",this.state.videos.byReviewer['d7d1dd1f-6029-47fe-b4f0-fb2c9dacd0d8']);

    
    if(this.props.reviewStatus && this.props.reviewStatus[0]){
      try {
        if(this.props.reviewStatus[0].bookmark  ){          
          this.state.bookmarkLoad = JSON.parse(this.props.reviewStatus[0].bookmark);
          // console.log("BookMark: ",this.state.bookmarkLoad);
          if(this.state.bookmarkLoad.tag){
            this.state.chosen = this.state.bookmarkLoad.type;
          }
        }
      } catch (error) {
        console.log("Bookmark load error: ",error);
      }
    }
    this.setState({ m_dataByCategory:buttonData,  cardsByType: this.props.cardsByType || {},

                    tabs:tabs , ejectSets:ejectionSets,hasResults:hasResults, updateTimer: moment(),
                    playerKey: this.state.playerKey+1, 
                    loadedReturns: loadedReturns});
  }


  

  /* @brief Run once when the class is loaded
  */
  componentDidMount() {
    console.log("Loaded the DVR version of the videoreviewer",this.props.videoType);
    // console.log("Start cards:" ,JSON.parse(JSON.stringify(this.props.cardsByType)));
    // console.log("VR props: ",this.props);
    this.updateButtons();
    //Scan through video: which should be set as chosen:
    let chosen = null
    let activeTabKey = 'infractions'
    // console.log(this.props.videos.byType);
    let clipCount = 0;
    Object.values(this.props.videos.byType||[]).forEach(v => {        
      if(v.set && v.set.length>0){
        if(!chosen){
          chosen = v.classification;
        }
      }            
      clipCount += v.count;
    });
    if(!chosen){
      chosen=this.props.videos.highlights ? "highlights" : "original"
      if(this.props.video.vehicleType === '24Review'){
        activeTabKey = 'infractions'
      }else{
        activeTabKey = 'highlights'
      }
      
      if(this.state.bookmarkLoad && this.state.bookmarkLoad.tag){
        // chosen=this.state.bookmarkLoad.type;
      }
    } 
    //Redirect to the correct button, needs to be delayed to allow
    // the icons to load correctly
    window.setTimeout(() => {
      // console.log("Redirecting to :" ,chosen, activeTabKey);
      this.setState({chosen:chosen,activeTabKey:activeTabKey});
    } , 1000); 

    this.setState({chosen:null,activeTabKey:activeTabKey,totalClips:clipCount});


    //Retrieve the video's clientid:
    let realPromise = Auth.currentSession().then(
      (auth) => {
      this.setState({currentUsername: auth.idToken.payload['cognito:username']});
      let apiName = "TrifectaAPI";
      let path = "/getVideoOwner";
      let myInit = {
          body: {
              token: auth.idToken.jwtToken,              
              videoid: this.props.parentID,
          }
      };
      return API.post(apiName, path, myInit);
    });
    realPromise.then(_data=>{ //process the owner based on the assetid
      //  console.log("Returned: ",_data);
      if(_data && _data.data){
        let clientid = null;
        for(const entry_ of _data.data){
          if(entry_.clientid){
            if(entry_.clientid.toLowerCase()!=='devgroup'){clientid =entry_.clientid.toUpperCase() }
            else{ if(!clientid) {clientid =entry_.clientid.toUpperCase()}   }
          }          
        }
        console.log("Set video owner: ",clientid);
        if(clientid){this.setState({videoClientID:clientid});}
      }
    })

    this.db.version(1).stores(  { clips: "infractionid" } );
    let openPromise = this.db.open();
    openPromise.then(function (db) {       }) //database opened without error
    openPromise.catch (function (err) { console.log("Failed to open db: ",err);});   // Error occurred 
    cleanDBCache(this.db,{table:'clips', key:'infractionid', maxSize: 5000, maxTimeHours: 24});

    if (navigator.storage && navigator.storage.estimate) {
      let estimation = navigator.storage.estimate();
      estimation.then( data=>{
        try{
          let qoutaVal = (data.quota/1024/1024).toLocaleString();
          let usageVal = (data.usage/1024/1024).toLocaleString();
          console.log(`Quota: ${qoutaVal}MB, Usage: ${usageVal}MB`);
        }catch(err){}
        
      })
      
    } else {
      console.error("StorageManager not found");
    }


  } //end compenentDidMount

  

  /* @brief On exit from page, trigger a bookmark update
  */
  handleLeave(){
    // this.props.refreshList();
    if(this.state.published){return;}
    let bookmarkString = JSON.stringify(this.state.bookmark);
    Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/updateReviewProgress";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            review: {
              id: this.props.parentID,
              username: auth.idToken.payload['cognito:username'],
              bookmark: bookmarkString,
              
            }
          }
        };
        return API.post(apiName, path, myInit);
      });
  }


  UNSAFE_componentWillMount() { 
    this._isMounted = true;
  }
  /* @brief Catch when the class is leaving, navigating away
  */
  componentWillUnmount() { //catches back button and back to analytics
    this._isMounted = false;
    this.handleLeave();
    const videos = this.state.videos;
    const cards = this.state.cardsByType;
    // console.log("Unmount cards: ",this.state.cardsByType);
    
    
    if(videos.original && videos.original.streamURL){URL.revokeObjectURL(videos.original.streamURL)}
    if(videos.highlights && videos.highlights.streamURL){URL.revokeObjectURL(videos.highlights.streamURL)}
    Object.values(( videos.clips || {})).forEach(typeArray => {
      typeArray.forEach(clip => {
        if(clip.streamURL){URL.revokeObjectURL(clip.streamURL)}
      });
    }); 
    
    Object.values(( videos.byType || {})).forEach(type => {
      if(type.set ){
        (type.set || []).forEach(elem=>{
          if(elem.streamURL){URL.revokeObjectURL(elem.streamURL)}
        })
      }else{
        if(type.streamURL){URL.revokeObjectURL(type.streamURL)}
      }
    }); 
    videos.original = null;
    videos.highlights = null;
    videos.clips = null;
    videos.byType = null;
    videos.byID = null;
    videos.byDriver = null;

    //Release any url blobs on the cards:
    Object.keys(cards).forEach(_key => {
      Object.values(cards[_key]).forEach(_card => {
        if(_card && _card.photo){_card.photo =null;}
        if(_card && _card.video){URL.revokeObjectURL(_card.video)}
      });
    });

    //Remove blobs from the buttonData:
    for(const row of this.state.m_dataByCategory){
      if(row.type === 'playlist' ){
        for(const elem_ of row.set){
          if(elem_ && elem_.blob){
            // console.log("Remove blob?: ",elem_.blob);
            try { URL.revokeObjectURL(elem_.blob) } catch (error) {console.log("Failed to remove blob? ",error)}
          }
        }
      }
    }


    try{
        // var es = document.getElementsByClassName("video-reviewer-player");
        var es = document.getElementsByClassName("video-reviewer");
        // console.log("ES: ",es);
        [...es].forEach(e=>{
          // console.log("Elem: ",e)
          var child = e.lastElementChild;  
          while (child) { 
              e.removeChild(child); 
              child = e.lastElementChild; 
          } 
        });
        
    }catch(e){
      console.log("Error on VR release: ",e);
    }

    this.setState({videos:videos, cardsByType: cards});
    this.setState({videos:null, cardsByType:null});
    this.setState({playerRef: {}});
  }
  /* @brief Send updated stats of the review progress to the AQL table
  */
  updateProgressAPI(){
    if(VIDEOREVIEW_NOREPORT){return;}
    // console.log("Progress API called", this.state.progressTally, this.state.published);
    if(this.state.published){return;}
    // console.log("Progress API called", this.state.progressTally);
    //get the number of tags from the set:
    const progressTally = this.state.progressTally;
    
    let countTotal = 1;
    Object.keys(progressTally).forEach(function(key) {
      countTotal = countTotal + progressTally[key].viewed.size;
    });

    // console.log("Update tally: ",countTotal, progressTally,this.state.progressTallyInFlight);

    let progessAsObject = {};
    
    Object.keys(progressTally).forEach(function(key) {
      if(!progessAsObject[key]){progessAsObject[key]={viewed: Array.from(progressTally[key].viewed)}}
    });

    let bookmarkString = JSON.stringify(this.state.bookmark);
    let inflightIds = null;
    try {
      inflightIds = JSON.stringify(Array.from(this.state.progressTallyInFlight))  ;  
    } catch (error) {
      console.log("Failed to get inflight: ",error);  
    }

    if(this._isMounted){this.setState({progressTallyInFlight: new Set()});}

    // return;
    Auth.currentSession().then(
      (auth) => {
        try {
          let apiName = "AuthLambda";
          let path = "/updateReviewProgress";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              review: {
                id: this.props.parentID,
                username: auth.idToken.payload['cognito:username'],
                tagsreviewed: countTotal,
                tagsviewed: progessAsObject,
                bookmark: bookmarkString,
                inflightids: inflightIds,
              }
            }
          };
          return API.post(apiName, path, myInit);
        } catch (error) {
          console.log("Failed to send API request: ",error);
        }
      
      });
  }

  initProgressTally(){
    //Get the button data:
    let buttons = this.state.m_dataByCategory;
    const progressTally = this.state.progressTally;
    Object.entries(( buttons || {})).forEach( ([key, value]) => {
      // console.log("Clip: ",key,value);
      try {
        if(!value){return;}
        if(!progressTally[value.chosenName]){progressTally[value.chosenName]={viewed:new Set()}}        
      } catch (error) {}
    });
    if(this._isMounted){this.setState({progressTally:progressTally});}
  }
  /* @brief Track which highlight clips have been viewed 
  */
  markViewed(_type,_tag) {
      // console.log("mark viewed: ",_type,_tag);
    // if(!this.state.progressTally[_type]){ console.log("don't count tagged infractions ",this.state.progressTally);return;} //don't mark viewed on the tagged infraction videos?
    // return;
    const progressTally = this.state.progressTally;
    let progressTallyInFlight_ = this.state.progressTallyInFlight;
    try {
      if(_tag && _tag.InfractionID && _tag.Tag){
        progressTallyInFlight_.add(_tag.InfractionID);
        if(progressTally[_type]&&progressTally[_type].viewed){                    
          progressTally[_type].viewed.add(_tag.Tag);
          // this.setState({progressTally:progressTally,progressTallyInFlight:progressTallyInFlight_}, console.log("InFlight",this.state.progressTallyInFlight.size));
          if(this._isMounted){this.setState({progressTally:progressTally,progressTallyInFlight:progressTallyInFlight_});}
        }else{
          this.initProgressTally();
          // console.log("Failed to add type to progressTally: ",progressTally,_type)
        }
      }
    } catch (error) {
      console.log("tally update error: ",error);
    }

    //update the bookmark with the current:
    if(this._isMounted){this.setState({bookmark:{type:_type,tag:_tag}});}

    try {
     //if(this.state.tagsreviewed %5 == 0){
    
    let threshold = 5;
    if(this.state.totalClips < 10 ){threshold =2;}
    if(this.state.firstload){
      // console.log("Mark viewed tally firstload: ",this.state.firstload,this.state.progressTallyInFlight.size);
      if(this._isMounted){this.setState({firstload:false});}
      threshold = 0;
    }
    // console.log("mark viewed tally: ",this.state.progressTallyInFlight.size,threshold)
    if(this.state.progressTallyInFlight.size>=threshold){
        this.updateProgressAPI();
     }
    } catch (error) {
      console.log("tally size error: ",error);
    }

  }

  /* @brief Parse through the buttonData set and return the button data associated with an infraction type
  */
  getButtonDataByType(_buttonData,_type) {
    let buttonReturn = null;
    _buttonData.forEach(row => {
      if(_type.includes("-DF")){
        if(row.chosenName === _type.replace("-DF","") && row.flag===2){
          buttonReturn = row;
        }
      }else{
        if( (row.chosenName === _type && row.flag===0) || (row.chosenName === _type && row.chosenName==='highlights' )){
          // if(row.s3key){buttonReturn = row;}
          buttonReturn = row;
        }  
      }
    });
    return buttonReturn;
  }

  /* @brief Expand a given card for user viewing and editing (from the card list column)
  */
  expandCard(cardData) {
      // perfDidClick('expand-card-click');
      // console.log("Expand card:", cardData);

      cardData.openTimer = new Date();
      let cardOpenTime= new Date();
      // console.log("Opened notecard: ",cardOpenTime);
      this.openNotecard(cardData);
      // this.setState({expandedCard: cardData, cardOpenTimer:new Date()}, ()=>{console.log("Open timer: ",this.state.cardOpenTimer)});
  }

  /* @brief Process the openROI request
  */
  openROI(_details) {
    console.log("Got details on roi: ",_details);
    _details.id = this.props.parentID;
    this.setState({roiselector: _details});
  }
  /* @brief Process the closeROI request
  */
  closeROI(_details) {
    this.setState({roiselector: null});
  }
  /* @brief Return the infractionID of the currently playing clip
  */
  currentlyPlaying(_details) {
    
    // console.log("Currently Playing:" ,_details);
    if(_details.chosenName === this.state.chosen){ //make sure the correct clip is returned
      //  console.log("Currently Playing:" ,_details);
       if(_details && _details.meta){
        if(this._isMounted){this.setState({currentPlayingID: _details.meta.InfractionID});} //extract the ID
       }
      
    }
  }


  //Called when deleting the card, but not deleting the clp 
  deleteCardsWithTags(inCard,_deleteCardFromType){

    this.setState(state => {

        if(inCard.toRemove){inCard.infractionTags.push(inCard.toRemove)}
        // console.log("DeleteCardWithTags card: ",inCard);
        let updateButtons = false;        
        const buttons = state.m_dataByCategory;
        const videos = Object.assign({}, state.videos);
        let cardsByType = Object.assign({}, state.cardsByType);
        // console.log("Cards in delete: ",cardsByType,inCard.infractionTags);
        (inCard.infractionTags||[]).forEach(_infTag=>{ //iterate over all tags in the card
          // console.log("Delete inftag: ",_infTag);
          if(_infTag === 'Irrelevant'){return;}
          const thisType = Object.assign({}, videos.byType[_infTag] || {});
          if(thisType.set && !inCard.ejected){ //Also remove the button?
            // console.log("Eject from button: ", _infTag);
            //Remove from the video set
            let foundButton = buttons.find(obj => obj.chosenName === _infTag);
            if(foundButton){
              let editedSet = (foundButton.set||[]).filter(clip_ => clip_.InfractionID !== inCard.infractionID);
              // console.log("Edit set: ",_inCard.toRemove,editedSet,_inCard.infractionID)
              foundButton.set = editedSet;
              foundButton.count = editedSet.length;
              updateButtons = true;//Set this to include the buttons in the state update
            }
          }

          
        }); //end iterate over all tag types

        let returnObj = {cardsByType:cardsByType};
        if(updateButtons){returnObj.m_dataByCategory=buttons;}

        //Also delete the card from the type, this happens on the second tab:
        if(_deleteCardFromType){
          // console.log("And remove from all types");
          cardsByType = this.removeCardFromCardsByCardID(cardsByType,inCard);
        }else{ //only remove from the infraction tags:
          // console.log("Only remove from infraction tags");
          (inCard.infractionTags||[]).forEach(_infTag=>{ //iterate over all tags in the card
            //remove from the cardsbytype (local set populating the infractions tab)
            // let cardsByType = this.removeCardFromCardsByType(cardsByType,inCard);
            const thisCardType = Object.assign({}, cardsByType[_infTag] || {});
            // console.log("Cards filtered by type: ",thisCardType,_infTag);
            if(thisCardType){
              
              Object.keys(thisCardType).forEach(key => {
                // console.log("Keys:" ,key,thisCardType[key]);
                try {
                  // console.log("compare: ",thisCardType[key].cardID,thisCardType[key].infractionID,inCard.cardID,inCard.infractionID)
                  if (thisCardType[key].cardID === inCard.cardID){
                    // console.log("Found card: ",key,inCard)
                    delete thisCardType[key];
                    return;
                  }                
                  if (thisCardType[key].infractionID === inCard.infractionID){
                    // console.log("Found card infractionid: ",key,inCard)
                    // delete thisCardType[key];
                    return;
                  }
                } catch (error) {
                  console.log("Failed to delete on: ",thisCardType[key], inCard.cardID, inCard.infractionID, error);
                }
              });
              cardsByType[_infTag] = thisCardType;
              // console.log("CardsbyType, ",_infTag,cardsByType[_infTag]);
            }
          }); //end iteration

        }
        
        //  return {videos: videos, m_dataByCategory:buttons, cardsByType:cardsByType, playerKey: this.state.playerKey+1};
        // console.log("Unexpand: ",videos,buttons,cardsByType);
        return returnObj;
        // return {videos: videos, cardsByType:cardsByType};
    },()=>{ //handle after the display update
        // console.log("After card delete: ",JSON.parse(JSON.stringify(this.state.m_dataByCategory)),JSON.parse(JSON.stringify(this.state.cardsByType)));
        this.closeNotecard();
        // this.setState({expandedCard: null});
    });

  }

 
  /* @brief Close the expanded card
  */
  unexpandCard(_inCard) {
      
      let inCard = Object.assign({}, _inCard);
      
      perfDidClick('unexpand-card-click');
      if(inCard && inCard.delete){

        this.deleteCardsWithTags(_inCard)        
        
      } //end delete handle
      else{
        this.closeNotecard();
        // this.setState({expandedCard: null});
      }
      
  }
  /* @brief add the id of cards that need to be submitted
    */
  shouldInitiallySend(id) {
    const ret = !this.state.m_initiallySent.has(id);
    this.state.m_initiallySent.add(id);
    return ret;
  }

  /* @brief Callback when the overlay button is clicked, this is passed up from the players
  */
  overlayButtonClick(_details){
    // console.log("Recieved overlay button: ",_details);

    let disableButton = false;
    (this.props.filter.role || []).forEach(role=>{
      if(role==='SiteManager'){disableButton=true;}
    })
    if(disableButton){return;}
    
    if(_details.type === 'Combined'){
      // this.props.pauseVideos([_details.chosenName]);
          try{
            const cardsByType = Object.assign({}, this.state.cardsByType);
            const thisType = Object.assign({}, cardsByType[_details.chosenName] || {});
            
            //Check if this card already exists
            if (thisType[_details.infractionID]) {
              this.openNotecard(thisType[_details.infractionID]);
              // this.setState({expandedCard: thisType[_details.infractionID]});
              return;
            }

            //Don't allow access to create a card if in read-only
            if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "read"){
              return;
            }
  
            //Doesn't exist: start creating a card:
            this.renderMap(_details.infractionID);
            //Set up the driverID, prefere the tagged infraction, default to the global video id
            let driver = this.props.videos.byDriver[_details.infractionID]
            
            if(!driver && this.props.driver && this.props.driver.driverID){ driver =" DriverID: " + this.props.driver.driverID}
            if(!driver || driver.includes(',')){ driver ="DriverID: Unavailable"} //everything is null?
            driver = driver.replace('_', ' ');
            
            const card = {
              tag: _details.Tag,
              infractionType: _details.chosenName,
              infractionID: _details.infractionID,
              status: 'FleetReview',
              severity: SEVERITY.MEDIUM,
              timeReceived: moment(this.props.parentVideo.received),
              timeCreated: moment(),
              cardID: generateUniqueID(),
              parentID: this.props.parentID,
              vehicleID: this.props.video.vehicleId,
              siteID: this.props.video.siteid,
              notes: "",    
              name: driver,
              infractionTags : [],
              live: this.props.review24Hr
            };
            this.addCard(card);
          } catch (error) {
            console.log("New card error: ",error);  
          }
    } //end handle combined
    else if(_details.type === 'playlist'){
      try{
        
        const cardsByType = Object.assign({}, this.state.cardsByType);
        const thisType = Object.assign({}, cardsByType[_details.chosenName] || {});
        //Check if this card already exists
        
        let foundCard = null;
        Object.values(thisType||[]).forEach(obj => {        
          if(obj.infractionID === _details.infractionID){foundCard=obj;}
        });
        // console.log("Overlay click:",foundCard);
        if(!foundCard){ //card doesn't exist: so create?

           //Set up the driverID, prefere the tagged infraction, default to the global video id
           let driver = this.props.videos.byDriver[_details.infractionID]

           if(!driver && this.props.driver && this.props.driver.driverID){ driver =" DriverID: " + this.props.driver.driverID}
           if(!driver || driver.includes(',')){ driver ="DriverID: Unavailable"} //everything is null?
           driver = driver.replace('_', ' ');

           const card = {
              tag: _details.Tag,
              infractionType: _details.chosenName,
              infractionID: _details.infractionID,
              status: 'FleetReview',
              severity: SEVERITY.MEDIUM,
              timeReceived: moment(this.props.parentVideo.received),
              timeCreated: moment(),
              cardID: generateUniqueID(),
              parentID: this.props.parentID,
              vehicleID: this.props.video.vehicleId,
              siteID: this.props.video.siteid,
              notes: "",    
              name: driver,
              infractionTags : [],
              live: this.props.review24Hr,
              video: this.state.videos.byID[_details.infractionID],              
           };
           this.addCard(card);

        }else{
          // console.log("Found card: ",foundCard);
          this.openNotecard(foundCard);
          // this.setState({expandedCard: foundCard, cardOpenTimer:new Date()});
        }

        return;
      } catch (error) {
        console.log("New card error: ",error);  
      }
    }

    // signal a performance mark for the button click, so we can use that as the starting
    // point for a timing event
    perfDidClick("infraction-overlay-button");
    return;
    
  }//end overlay button click
 /* @brief Tell the backend to generate a map for the notecard
  */
  renderMap(_infractionID){
    Auth.currentSession().then(
        
      (auth) => {
        // console.log("Got Auth: ",auth);
        let apiName = "AuthLambda";
        let path = "/triggerMapGenerataion";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            review: {
              id: this.props.parentID,            
              infractionid: _infractionID,
            }
          }
        };
        return API.post(apiName, path, myInit);
      });
  }

  


 /*
  * @brief Poll the Highlight SQL table to determine when the reprocessing request has completed
  */
  getVideoUpdate(_data){
    //Check if there are pending updates for any of the types:
    try {
        let ejectionSets = this.state.ejectSets || {};
        var that = this;
        if(_data){
          // console.log("Check on status for single",_data)
          const realPromise = Auth.currentSession().then(
            (auth) => {
              let apiName = "AuthLambda";
              let path = "/getReprocessStatus";
              let type_ = _data;
              type_ = type_.replace("-DF","");
              if(type_ === 'highlights'){type_ = 'Common';}
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  rejectList: {              
                    parentID: that.props.parentID,
                    type: type_,              
                  }
                }
              };
              return API.post(apiName, path, myInit);
            }
          ); //end promise definition
          realPromise.then(data => that.handleReprocessStatus(data))
          realPromise.catch(function(error) {
            console.log(error);
          });
          
        }else{ //otherwise get for everyone:
          Object.keys(ejectionSets).forEach(function(key) {
          
            if(ejectionSets[key].processing){ //If the state is set as processing - check for update:
                //Need to query:
                // console.log("Check on status for ",key)
                const realPromise = Auth.currentSession().then(
                  (auth) => {
                    let apiName = "AuthLambda";
                    let path = "/getReprocessStatus";
                    let type_ = key.replace("-DF","");
                    if(type_ === 'highlights'){type_ = 'Common';}
                    let myInit = {
                      body: {
                        token: auth.idToken.jwtToken,
                        rejectList: {              
                          parentID: that.props.parentID,
                          type: type_,              
                        }
                      }
                    };
                    return API.post(apiName, path, myInit);
                  }
                ); //end promise definition
                realPromise.then(data => that.handleReprocessStatus(data))
                realPromise.catch(function(error) {
                  console.log(error);
                });
            }//end check if processing
          });//end loop
        }
    }catch(err){
      console.log("video Update error: ",err);
    }
  }

  /*
  * @brief Trigger a refresh request on the parent class (VideoHighlight). The
  * Highlight data is reloaded given the current unique ID.
  */
  // refreshVideoUpdate(data){
  //   //Complete returned: update eject sets:
  //   this.setState(prevState => {
  //     try{
        
  //       let type = data.type;
  //       if(type === 'Common'){type = 'highlights';}
  //       const ejectionSets = Object.assign({}, prevState.ejectSets);
  //       if(!ejectionSets[type]){  type = data.type+"-DF"; }//Check if this is a driver facing type
  //       ejectionSets[type].processing=false;
  //       ejectionSets[type].ejectList=[];
  //       ejectionSets[type].processTime= moment();
  //       return {ejectSets:ejectionSets};
  //     }catch(err){
  //       console.log("Reject click error: ",err,data);
  //     }
  //   }); 

  //   //Are we ready to refresh? -everyone check in?
  //   try {
  //     let ejectionSets = this.state.ejectSets;
  //     var processingCount = 0;
  //     Object.keys(ejectionSets).forEach(function(key) {
  //       if(ejectionSets[key].processing){ //If the state is set as processing - check for update:
  //         processingCount++;
  //       }
  //     }); 
  //     // console.log("Processing Count: ",processingCount);
  //     if(processingCount === 0){
  //       this.props.onRefresh(data);
  //     }
  //   } catch (error) {
  //     console.log("Refresh on eject fail: ",error);
  //   }
    
  // }

  /*
  * @brief A helper to pause videos by name (the chosenName used to refer to each video)
  * 
  * This is plural because each video you're going to pause at one time should be done in a group,
  * with a single call to this function. There is an issue with some of the pauses not happening
  * otherwise.
  */
  pauseVideos(names) {
    // console.log("Should pause names: ",names);
    this.setState(prevState => {
      let clonedStates = Object.assign({}, prevState.play_states);
      let clonedWorkaround = Object.assign({}, prevState.workaround_playing);
      if (names) {
        names.forEach(name => {
          if (clonedStates[name] !== undefined) {
            delete clonedStates[name];
          }
          if (clonedWorkaround[name] !== undefined) {
            delete clonedWorkaround[name];
          }
        });
      } else {
        clonedStates = {};
        clonedWorkaround = {};
      }
      return {play_states: clonedStates, workaround_playing: clonedWorkaround, shouldPause:[]};
    });
  }

  openNotecard(_inCard){
    // console.log("Open the card: ",_inCard, this.props);
    this.setState({expandedCard: _inCard, cardOpenTimer: new Date()});
  }
  closeNotecard(){
    // console.log("Close the opened card: ");
    this.setState({expandedCard: null});
  }

  /* @brief Callback when a card is updated
  */
  addCard(_inCard) {
    // console.log("Add a card to the sets: ",_inCard);
    this.props.cardChange(_inCard);
    this.setState(state => {
      const cardsByType = Object.assign({}, state.cardsByType);
      const thisType = Object.assign({}, cardsByType[_inCard.infractionType] || {});      //create empty if not already set
      thisType[_inCard.infractionID] = _inCard;
      // console.log("Add card to type: ",thisType);
      cardsByType[_inCard.infractionType] = thisType; //reassign back to the group
      
      // return {cardsByType: cardsByType, expandedCard: thisType[_inCard.infractionID], cardOpenTimer:new Date()};
      return {cardsByType: cardsByType};
    },
    ()=>{
      let cardToOpen = getCard(this.state.cardsByType,_inCard);
      // console.log("Pass card to open: ",cardToOpen);
      if(cardToOpen){
        this.openNotecard(cardToOpen.card);
      }
      
    }
      
    // ()=>{console.log("Updated CardsByType: ",JSON.parse(JSON.stringify(this.state.cardsByType)))}
    );
  }

  /* @brief Handler for when a card receives a change
  *
  * This happens when the user:
  *   - enters a comment on the expanded card
  *   - deletes a card
  *   - sets the severity in an expanded card
  */
  cardChange(_inCard) {
    // console.log("Card change? ",_inCard);
    let inCard = Object.assign({}, _inCard);
    if(inCard && inCard.infractionTags && Array.isArray(inCard.infractionTags)){
      inCard.infractionTags = inCard.infractionTags.toString(); //set as string to pass to SQL doesn't accept the array
    }
    // console.log("Card change:" ,inCard);  
    this.props.cardChange && this.props.cardChange(inCard);
    if (inCard.delete) {
      // console.log("delete called from cardChange:" ,inCard);
      this.setState(state => {
        let cardsByType = Object.assign({}, state.cardsByType);
        //Delete the card:
        // console.log("Call remove by cardID:")
        cardsByType = this.removeCardFromCardsByCardID(cardsByType,inCard);
        // return {cardsByType: cardsByType, expandedCard: null};
        return {cardsByType: cardsByType};
      },this.closeNotecard());
    } else {
      
      this.setState(state => {

        const cardsByType = Object.assign({}, state.cardsByType);
        //Get the card from the current set
        let cardReturn = getCard(cardsByType,inCard);
        if(!cardReturn){console.log("Failed to find card");return;}
        let thisCard = cardReturn.card;
        let cardKey = cardReturn.key;
        //update the stored card details        
        if(thisCard.infractionID && thisCard.cardID){
          if(inCard.comment){   thisCard.notes    = inCard.comment;}
          if(inCard.severity){  thisCard.severity = inCard.severity;}
          if(inCard.notes){     thisCard.notes    = inCard.notes; }
          cardsByType[cardKey] = cardsByType[cardKey] || {};
          cardsByType[cardKey][inCard.infractionID] = thisCard;
        }
        //Update the values on the opened card
        const retVal = {cardsByType: cardsByType};
        if (state.expandedCard) {
          const expandedCard = Object.assign({}, state.expandedCard);
          if(inCard.severity){expandedCard.severity = inCard.severity;}
          if(inCard.notes){   expandedCard.notes    = inCard.notes;}
          if(inCard.comment){ expandedCard.notes    = inCard.comment;}
          retVal.expandedCard = expandedCard;
        }
        return retVal;

      });
    }
  }

  changeDriverID(_inCard) {
    // console.log("Change driver id: ",_inCard)
    this.setState(state => {

      const cardsByType = Object.assign({}, state.cardsByType);
      //Get the card from the current set
      let cardReturn = getCard(cardsByType,_inCard);
      if(!cardReturn){console.log("Failed to find card");return;}
      let thisCard = cardReturn.card;
      let cardKey = cardReturn.key;
      // console.log("This Card: ",cardReturn)
      //update the stored card details        
      if(thisCard.infractionID && thisCard.cardID){
        
        if(_inCard.driverID){   thisCard.driverID    = _inCard.driverID;}
        if(_inCard.name){  thisCard.name = _inCard.name;}
        // console.log("Update card: ",thisCard.driverID, _inCard.driverID,cardsByType,cardKey,_inCard.infractionID)
        cardsByType[cardKey] = cardsByType[cardKey] || {};
        cardsByType[cardKey][_inCard.infractionID] = thisCard;
      }


      //Handle the tagged infractions, update the details of the cards stored in the per infraction arrays
      const videos = Object.assign({}, state.videos);
      (thisCard.infractionTags||[]).forEach(_infTag=>{
        // console.log("Tags: ",_infTag);
        if(_infTag === 'Irrelevant'){return;}
        if(!videos.byID[_inCard.infractionID]){return;}
        cardsByType[_infTag] = cardsByType[_infTag] || {};
        cardsByType[_infTag][_inCard.infractionID] = thisCard;
        
      }); //end adding new infraction tags
      //Update the values on the opened card
      const retVal = {cardsByType: cardsByType};
      return retVal;
    });
    
  }


  /* @brief Callback when a card is created, this passes back the streamURL for the tagged infractions
  */
  onCardCreate(_inCard){
    // console.log("Card Create: ",_inCard);
    this.setState(state => {
      const videos = state.videos;
      videos.byID[_inCard.infractionID] = _inCard.streamURL;
      return {videos:videos}
    });
    

  }

  

  /* @brief handle the callback when a tag is changed in the notecard.
  * This refreshes the displayed cards in the list
  */
  cardTag(_inCard) {
    // console.log("Card tag: ",_inCard);

    this.setState(prevState => {
        let prevCardsByType_ = Object.assign({}, prevState.cardsByType);
        let prevVideos = Object.assign({}, prevState.videos);
        //Get the card
        let cardReturn = getCard(prevCardsByType_,_inCard); 
        //Make sure a notecard exists, we expect to modify not create in this function
        if(!cardReturn){console.log("Failed to find card");return;}
        
        let categories = prevState.m_dataByCategory; //get the categories that render the buttons

        //get the tracked tags
        const trackedTags = this.getTagsfromInfractionID(this.state.cardsByType,_inCard);

        //Compare the current tags on the card against the tags that are tracked by CardsByType
        let tagsToAdd = (_inCard.infractionTags||[]).filter( tag_ =>{return !trackedTags.includes(tag_)});
        let tagsToRemove = (trackedTags||[]).filter( tag_ =>{return !_inCard.infractionTags.includes(tag_)});
        //Need to add the explicit ToRemove case:
        let newSet = null;
        if(_inCard.toRemove){
          try {
            tagsToRemove.push(_inCard.toRemove);
            // Add a unique check to prevent trying to remove twice
            let newSet = new Set(tagsToRemove); 
            tagsToRemove = Array.from(newSet);
          } catch (error) { }
        }
        // console.log("Tags tracked:" ,trackedTags, tagsToAdd,tagsToRemove);
      
        //Handle adding tagged infractions:
        let addReturn = this.handleAddingTagsOnCard(prevCardsByType_,categories, prevVideos,_inCard,tagsToAdd);
        // console.log("Add return: ",JSON.parse(JSON.stringify(addReturn)));

        // //Handle removing tagged infractions:
        let removeReturn = this.handleRemoveTagsOnCard(addReturn.cards,addReturn.categories,_inCard,tagsToRemove);
        // console.log("Remove return: ",JSON.parse(JSON.stringify(addReturn)));

        //Update the state:
        return {cardsByType: removeReturn.cards, m_dataByCategory:removeReturn.categories,videos: addReturn.videos};
    },
    //  ()=>{console.log("Cards/Buttons updated: ",JSON.parse(JSON.stringify(this.state.cardsByType)),JSON.parse(JSON.stringify(this.state.m_dataByCategory)))}
    
    );
    return;
  }

  /* @brief Add the tag to the notecard, and the tracked set of notecards
  */
  handleAddingTagsOnCard(_prevCardsByType,_categories,_prevVideos,_inCard,_toAdd){

    let cardReturn = getCard(_prevCardsByType,_inCard);
    //Add the infraction tags to the card:
    let thisCard = Object.assign(cardReturn.card,_inCard); //merge the old card data with the new data
    

    // console.log("Run the tags: ",JSON.parse(JSON.stringify(_inCard.infractionTags)),this.state.videos.byType);
    (_toAdd||[]).forEach(_infTag=>{
      // if(_infTag === 'Irrelevant'){return;}
      if(!this.state.videos.byID[_inCard.infractionID]){return;} //video clip not found, exit
      
      //Get the category using the tag:
      let foundButton = _categories.find(obj => obj.chosenName === _infTag);
      
      //Create object to add to the set:
      let newToAdd = {
        InfractionID: _inCard.infractionID,
        Tag:  this.state.videos.byType[_infTag]? this.state.videos.byType[_infTag].count+1:1,
        TimeOffset: 0,
        streamURL: this.state.videos.byID[_inCard.infractionID],
        reviewstatus: this.state.videos.byReviewer[_inCard.infractionID].status, 
        username: this.state.videos.byReviewer[_inCard.infractionID].username,
        infractionTags: _inCard.infractionTags||[]
      };
      //Add the tag to the type
      if(!foundButton){ //doesn't exist, create the new button details

        //Keep a list of all videos that is not altered:
        _prevVideos.byType[_infTag]={set:[], count:1}
        _prevVideos.byType[_infTag].set.push({InfractionID: _inCard.infractionID});

        //Update the data passed to the buttons:           
        foundButton={
          set: [], classification: _infTag,            
          type: 'playlist', streamURL: 'playlist',
          cards:{}, count: 0,
          chosenName:_infTag, displayName:_infTag,
          loadCount :1,
          loadComplete: false,
        }

        //Add the clip to the set to play
        foundButton.set.push(newToAdd)
        foundButton.count=foundButton.set.length; //increment the count
        // console.log("Didn't find the button: ", JSON.parse(JSON.stringify(foundButton)));
        //Add the new button into the categories:
        _categories.push(foundButton);
      }
      else{//only push this if not already found in the set:
        //Add to the list of known items
        _prevVideos.byType[_infTag].set.push({InfractionID: _inCard.infractionID});
        _prevVideos.byType[_infTag].count++;
        
        //Update the data in the button (if unique test passes)
        let foundClip = (foundButton.set||[]).find(obj => obj.InfractionID === _inCard.infractionID);            
        //  console.log("Find the clip in set? ",_inCard.infractionID,JSON.parse(JSON.stringify(foundClip)));            
        if(!foundClip){       
          foundButton.set.push(newToAdd)
          foundButton.loadComplete= false; //update the complete state
          foundButton.count=foundButton.set.length; //increment the count
        }           
      }

      //Add to a set based on the infraction tags:
      _prevCardsByType[_infTag] = _prevCardsByType[_infTag] || {};
      _prevCardsByType[_infTag][_inCard.infractionID] = thisCard;

    }); //end tag iteration

    //Update the type array (renders list on the All tab)
    try{
      _prevCardsByType[thisCard.infractionType][_inCard.infractionID] = thisCard;
    }catch(e){}

    return {cards: _prevCardsByType, categories:_categories, videos:_prevVideos};
  }//end processing the tag additions


  /* @brief Remove the tag from the notecard and the tracked set of notecards
  */
  handleRemoveTagsOnCard(_prevCardsByType,_categories,_inCard,_toRemove){
    // return {cards: _prevCardsByType, categories:_categories};
    (_toRemove||[]).forEach(_removeTag=>{
      //Removing an infraction from the set:
      let foundButton = _categories.find(obj => obj.chosenName === _removeTag);
      if(foundButton && foundButton.set && foundButton.set.length>0){
        //remove the clip from the set:
        let editedSet = (foundButton.set||[]).filter(clip_ => clip_.InfractionID !== _inCard.infractionID);
        // console.log("Edit set: ",_inCard.toRemove,editedSet,_inCard.infractionID)
        foundButton.set = editedSet;
        foundButton.count = editedSet.length;
      }else{
        // console.log("Didn't find to remove:" ,_removeTag);
      }

      //Update the tags in the cards held by type:
      for(const key_ of Object.keys(_prevCardsByType)){
        const thisCardID = Object.assign({}, _prevCardsByType[key_][_inCard.infractionID]);
        if(thisCardID.infractionID && thisCardID.cardID){
          _prevCardsByType[key_][_inCard.infractionID].infractionTags = _inCard.infractionTags;
        }
      }          

      //Remove from the cardsByType based on the tag name:
      _prevCardsByType = this.removeCardFromCardsByTag(_prevCardsByType,_inCard,_removeTag);
    });//end removing tags from the notecards
    return {cards: _prevCardsByType, categories:_categories};
  } //end processing the tag removals


  /* @brief Helper function, get a list of all the tags associated with the card
  */
  getTagsfromInfractionID(_cardsByType, _inCard){
    if(!_cardsByType){return Object.assign({}, _cardsByType);}
    if(!_inCard){return Object.assign({}, _cardsByType);};

    let foundTags = new Set();
    //Iterate over the cardsByType and find if the infractionID is in the set
    for(const [key_,value_] of Object.entries(_cardsByType)){
      if(key_===_inCard.infractionType){continue;} //Don't consider the type of video clip, manual, peripheral etc. only the tags      
      if(value_[_inCard.infractionID]){foundTags.add(key_);}
    }
    return [...foundTags]; //Return the list of unique values
  }

   /* @brief Helper function to remove a note card from a single type of list
    */
   removeCardFromCardsByTag(_cardsByType, _inCard, _tag){
    if(!_cardsByType){return Object.assign({}, _cardsByType);}
    if(!_inCard){return Object.assign({}, _cardsByType);};
    // console.log("Remove card:" ,_cardsByType,_inCard,_tag);
     
    if(_cardsByType[_tag]){
      //Just delete anything with the cardID or INfractionID:
      delete _cardsByType[_tag][_inCard.infractionID];
      delete _cardsByType[_tag][_inCard.cardID]; 
    }else{
      // console.log("Not found in cardsByType")
    }
    return _cardsByType;
  } //end of removeCardFromCardsByTag



  /* @brief Helper function to remove a note card from all lists
    */
  removeCardFromCardsByCardID(_cardsByType, _inCard){

    if(!_cardsByType){return Object.assign({}, _cardsByType);}
    if(!_inCard){return Object.assign({}, _cardsByType);};
    // console.log("Remove  by id:" ,_cardsByType,_inCard);
    // const thisCardType2 = Object.assign({}, _cardsByType[_inCard.toRemove] || {});
    Object.keys(_cardsByType).forEach(typeKey_ => {
      Object.keys(_cardsByType[typeKey_]).forEach(propKey_ => {
        if (_cardsByType[typeKey_][propKey_].cardID === _inCard.cardID){
          // console.log("Found to delete")
          delete _cardsByType[typeKey_][propKey_];  
        }
      });
    });
    return _cardsByType;
  }
  

   /* @brief Handle the eject button press, add current tag to list
    */
   ejectClick(_eject){
    try{
      //  console.log("Eject received: ",JSON.parse(JSON.stringify(_eject)));
      //  return;
      if(_eject.toRemove){
            
            if(_eject.infractionTags.length === 0){
              // console.log("Empty just delete:")
              _eject.delete = true;
            }
            //This from an eject request,not a delete card request
            if(!_eject.metaData){ //ejected from the infraction clips, not from the highlights (highlights will have metadata)
              //this flag is passed to preserve the set order, if eject removes this then the skip breaks the play order
              //in the videoClipPlayer 
              _eject.ejected = true; 
            }  

            if(_eject.delete ){
                  //And delete the cards:
                  this.cardTag(_eject);
                  this.cardChange(_eject);  //card change
                  this.unexpandCard(_eject); //handle close
             }else{ //not deleting the card, just removing a tag
            //   //  console.log("Eject clip");
               this.cardTag(_eject);
               this.cardChange(_eject);
            }


          if(!_eject.notecardOnly){
            this.setState(prevState => {
              try{
                //get the data from the category:
                let data = prevState.m_dataByCategory;
                let foundType = data.find(elem_ => (elem_.chosenName === _eject.toRemove && elem_.type == _eject.type));
                if(foundType){
                  //  console.log("Delete from set:" ,foundType);
  
                   //Remove the infractionid from the set
                   let editedSet = (foundType.set||[]).filter( (elem_)=>{ return elem_.InfractionID !== _eject.infractionID});
                   //update the set linked to the data
                   foundType.set = editedSet;
                  //  foundType.set = JSON.parse(JSON.stringify(editedSet));
                }
                // console.log("New data:" ,data);
                // TODO - IS THIS STILL NEEDED?
                // return {m_dataByCategory:JSON.parse(JSON.stringify(data))};
                return {m_dataByCategory:data};
  
              }catch(err){
                // console.log("eject ids udpate error: ",err);
              }
             
            }); //end set state:

          }
          
        // return;
      }
      if(_eject.notecardOnly){
        // _eject.ejected = true;
        this.deleteCardsWithTags(_eject,true);
        this.props.cardChange && this.props.cardChange(_eject);//need to notify the backend of the change
      }
      return;
    } catch (error) {   }
} //end eject
/* @brief Mark the clip as available, remove the ejection
*/
undoEjectClick(_data){
  try{
      //Need to undo on current:
    const metadataValue = _data.metaData;
    if(metadataValue){
      // Send request to modify the SQL table:
      Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda";
          let path = "/updateHighlightClipStatus";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              review: {
                id: this.props.parentID,
                infractionid: metadataValue.InfractionID,
                username: auth.idToken.payload['cognito:username'],
                status: null,
              }
            }
          };
          return API.post(apiName, path, myInit);
        });
    }

    this.setState(prevState => {
        try{
        const name = _data.name;
        const ejectionSets = Object.assign({}, prevState.ejectSets);
        if( !ejectionSets[name] ) {ejectionSets[name]={ejectList:[], processing:false, processTime: moment()}} //create the default structure
        //Loop through the list and remove the current:
        for( var i = 0; i < ejectionSets[name].ejectList.length; i++){ 
          if ( ejectionSets[name].ejectList[i].InfractionID === metadataValue.InfractionID) { //is this the one we want to undo?
            ejectionSets[name].ejectList.splice(i, 1); 
          }
        }
        for( var j = 0; j < ejectionSets['highlights'].ejectList.length; j++){ 
          if ( ejectionSets['highlights'].ejectList[j].InfractionID === metadataValue.InfractionID) { //is this the one we want to undo?
            ejectionSets['highlights'].ejectList.splice(j, 1); 
          }
        }
        return {ejectSets:ejectionSets};
      }catch(err){
        console.log("Reject click error: ",err);
      }
    }); //end set state:
  } catch (error) { console.log("Erorr on undo",error)  }
} //end undo eject

/*
    * @brief Callback for the publish button, prompt the user to enter the ClientID and confirm desire to publish
    */
confirmPublish(){
  // console.log("Publish: ",this.props,this.state.videoClientID)
  return new Promise((resolve, reject) => {
    // if(this.props.groupconfig.group ==="reviewgroup"){
    if(VideoReviewGroups.includes(this.props.groupconfig.group)){
      if(window.confirm("Submit video?")){
        let directedDestination = "TESTINGGROUP";        
        if(this.props.review24Hr){
          directedDestination = "DEVGROUP";
        }
        // directedDestination = "BETAGROUP";
        // console.log("Publish: ",directedDestination);
        // return;
  
        const realPromise = Auth.currentSession().then(
          (auth) => {
            let apiName = "AuthLambda";
            let path = "/publishVideo";
            let myInit = {
              body: {
                token: auth.idToken.jwtToken,
                id: this.props.parentID,
                client: directedDestination,
                from: this.props.groupconfig.group,   
                type: "24review" ,
                // mode: 'test'
              }
            };
            return API.post(apiName, path, myInit);
          }
        ); //end promise definition
        realPromise.then(data => {
          // this.handlePublish({id:this.props.parentID})
          if(data && data.error && data.error === true ){             
            window.confirm("Request interrupted, please check your network connection and try again. \n\n") 
          }else{
            this.setState({published: true, m_bSubmitted:true});
          }
          resolve({error: false});          
        })
        realPromise.catch(function(error) {            
          window.confirm("Request interrupted, please check your network connection and try again.")
          console.log(error);
          reject({error: true});
        });
      }
      else{ //canceled
        // return {error: false};
        resolve({error: false});
      }
  
    }else{
      //Prompt the user to enter the ID, default to BIS 
      let suggestedDestination = this.state.videoClientID || "BIS";      
      if(!this.state.videoClientID && this.props.video.filename){
        console.log("Unknown clientid, check filename?")
        if(this.props.video.filename.includes("TK")){            
          suggestedDestination = "PINTOVALLEY";
        }
      }
      
      // let suggestedDestination = "BIS";      
      var promptReturn = window.prompt("ClientID to publish to:",suggestedDestination);
      if (promptReturn) { //if anything was entered
        //Ask the user to confirm that the clientid is correct and they want to publish
        promptReturn = promptReturn.toUpperCase();
        if(window.confirm("Publish to "+promptReturn)){
  
          let queryBody = {
            token: null,
            id: this.props.parentID,
            client: promptReturn,  
            from: this.props.groupconfig.group, 
            type: "24review",
            // mode: 'test'
          }
          // console.log("Starting publish")
          const realPromise = Auth.currentSession().then(
            (auth) => {
              queryBody.token = auth.idToken.jwtToken;
              let apiName = "AuthLambda";
              let path = "/publishVideo";
              let myInit = {
                body:queryBody
              };
              return API.post(apiName, path, myInit);
            }
          ); //end promise definition
          realPromise.then(data => {
            // console.log("Publish returned: ",data)
            if(data && data.error && data.error === true ){             
              window.confirm("Request interrupted, please check your network connection and try again. \n\n") 
            }else{
              this.setState({published: true, m_bSubmitted:true});
            }
            resolve({error: false});
          })
          realPromise.catch(function(error) {
            //auto resend if the connection is missed:
            console.log("Resending publish request", error);
            const realPromise2 = Auth.currentSession().then(
              (auth) => {
                queryBody.token = auth.idToken.jwtToken;
                let apiName = "AuthLambda";
                let path = "/publishVideo";
                let myInit = {
                  body: queryBody
                };
                return API.post(apiName, path, myInit);
              }
            ); //end promise definition
            realPromise2.then(data => {
              console.log("Publish(2) returned: ",data)
              // this.handlePublish({id:this.props.parentID})
              this.setState({published: true, m_bSubmitted:true});
              // return {error: false};
              resolve({error: false});
            })
            realPromise2.catch(function(error2) {
              window.confirm("Request interrupted, please check your network connection and try again. \n\n"+error2)
              console.log(error2);
              // return {error: true};
              resolve({error: true});
            });
          });
        }
      }//end prompt return
      else{ //canceled
        // return {error: false};
        resolve({error: false});
      }
    }//end group else check
  });
}//end confirmPublish

/*Callback method when the Unusable/Available button is clicked */
clickUnusable(){

  console.log("Clicked unusable: ",VIDEOREVIEW_NOREPORT)
  return new Promise((resolve, reject) => {
    let statusToSet = this.state.markedUnusable? 200: 201; //define the status based on the current state
    //Process the click with the API
    const realPromise = Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/updateStatus";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            id: this.props.parentID,
            status: statusToSet,  
          }
        };
        return API.post(apiName, path, myInit);
      }
    ); //end promise definition
    realPromise.then(data => {
      this.setState({markedUnusable: !this.state.markedUnusable}); //toggle the unusable state
      resolve({error:false});
    })
    realPromise.catch(function(error) {
      console.log(error);
      reject({error:true});
    });
    
  });
  
}//end clickUnusable


  /* @brief Render the content of the current page, automatically called when react determines the page needs refreshing.
  */
  render() {
    // console.log("Triggered Render");
    // const buttonData = this.m_buttonData;
    const buttonData = this.state.m_dataByCategory
    const chosen = this.state.chosen;
    // const clips = this.m_clips || {};
    const byID = this.state.videos.byID;
    // console.log("Render: ",this.state,this.props);
   
    
    let players = null;
    let canReprocess = false;
    // let reprocessStyle = {visibility:"hidden"};   

    // let reprocessStyle = {visibility:"visible"};   
    let procIcon = IconRepeat;
    let isProcessing = false;

    // buttonData holds each row of video that we want to create a button for,
    // each one also has an associated video player for it (though only one video player
    // will be shown at a time)
    
    const tabs = this.state.tabs;

    /* Create the video reviewer section for each button */
    const categoryData = this.state.m_dataByCategory;
    if(categoryData && tabs){

      let iIndexToLoad = this.state.infractionLoadIndex;
      // console.log("Start looking at index:" ,iIndexToLoad);
      categoryData.forEach( (row_)=>{
      // for (const row_ of categoryData) {
        if(row_.loadComplete===true){ return;} //already finished loading
        if(row_.type === 'playlist' && row_.set.length === 0){return;}//empty set
        if(row_.type === undefined){return;} //no data loaded into button

        // console.log("Test on :", row_.chosenName, row_.loadOrder);
        if(row_.loadOrder < iIndexToLoad){
          // console.log("Update with: ",row_.loadOrder, row_.chosenName, row_.type);
          iIndexToLoad = row_.loadOrder;
          // return;
        }
        // iIndexToLoad++;
      })      
      
      // console.log("Load the reviewers: ",this.state.cardOpenTimer)
      players = categoryData.map( (row,idx) => { 
        if(row.type==='original' && this.props.videos && this.props.videos.originalDeleted===true){          
            return dvrdeleted_message();
        }else{
          return (<VideoReviewerType  data = {row} idx = {idx} filter={this.props.filter}
            key={row.classification + "-" + row.loadOrder+"-cats"}
            possibleFilters = {this.props.possibleFilters}
            groupconfig = {this.props.groupconfig} 
            byID = {this.state.videos.byID}
            username = {this.props.username}
            filename = {this.props.filename}
            chosen = {this.state.chosen}
            infractionLoadIndex = {iIndexToLoad}
            notecards = {this.state.cardsByType[row.chosenName]||null}
            ejectClick = {this.ejectClick}
            cacheDB = {this.db}
            roiSelectClicked = {this.openROI}                              

            overlayButtonClick = {this.overlayButtonClick}
            markViewed={this.markViewed}
            // interruptTimer = {this.state.expandedCard? this.state.expandedCard.openTimer:null}
            interruptTimer = {this.state.expandedCard? this.state.cardOpenTimer:null}

            lastLoadComplete = {this.state.singleLoadComplete}
            loadComplete = {(_response)=>{
              // console.log("Complete returned from: ",_response);
              this.setState(prevState => {
                let data = prevState.m_dataByCategory;
                let foundType = data.find(elem_ => (elem_.chosenName === _response.name && elem_.type == _response.type));
                if(foundType){
                  // console.log("Found:" ,foundType,foundType.loadComplete);
                  foundType.loadComplete = true;
                  // console.log("Increment infractionLoadIdex")
                  console.log("load complete",_response.name,new Date() - this.state.timeDebug)
                  return({infractionLoadIndex: prevState.infractionLoadIndex+1, singleLoadComplete: new Date()});
                }
              });
            }}//end load complete message received
          />);//end of VideoReviewerType
        } //end else
      });


      // console.log("Data", categoryData);

      //Define the onclick for the Video Category Buttons
      const typeButtonClick = (_chosenName) => {
        // Pause the video, and then set in the state that we've chosen a new video
        // if (row.streamURL) {
          // console.log("Clicked on button: ",_chosenName);
          this.setState({chosen: _chosenName});
        //   perfDidClick("video-reviewer-choice-button");
        // }
      }

      tabs[0].set = categoryData.filter(row=>{ //Apply a filter first to the returned list
        if(row.classification === 'Irrelevant'){return false;}
        if( (row.type === 'playlist' && row.flag!=2) ||  row.type === 'playlist' && row.count===0){return row;}
        //If we are only doing one column then add the original when it is available:
        if(this.props.video.vehicleType ==='24Review'){ if(row.type === 'original'){return row;} }
      }).map(row=>{

        return (<VideoReviewerButton data={row} ejectionIds={this.state.ejectionIds} ejectSet = {this.state.ejectSets[row.chosenName]}
                                      chosen={this.state.chosen} groupconfig = {this.props.groupconfig} onClick = {typeButtonClick}
                                      key={row.classification + "-" + row.type+"-top0"}
                  />)
      }); //end button creation/map
       /* Create the buttons for each data item */
       tabs[1].set = buttonData.filter(row=>{ //Apply a filter first to the returned list
        if(row.type !== 'playlist' && row.flag===0 ){
               return row;
        }
      }).map(row=>{
        return (<VideoReviewerButton data={row} ejectionIds={this.state.ejectionIds} ejectSet = {this.state.ejectSets[row.chosenName]}
                  chosen={this.state.chosen} groupconfig = {this.props.groupconfig} onClick = {typeButtonClick}
                  key={row.classification + "-" + row.type+"-top1"}
                />)
      }); //end button creation/map

      tabs[2].set = buttonData.filter(row=>{ //Apply a filter first to the returned list
        if(row.flag===2){
              return row;
        }
      }).map(row=>{
        return (<VideoReviewerButton data={row} ejectionIds={this.state.ejectionIds} ejectSet = {this.state.ejectSets[row.chosenName+"-DF"]}
          chosen={this.state.chosen} groupconfig = {this.props.groupconfig} onClick = {typeButtonClick}
          key={row.classification + "-" + row.type+"-top2"}
        />)
      });
    }

    

    /* @brief Callback to confirm that the video should be published
    */
    const confirmReviewPublish = () => {

        if(window.confirm("Publish to REVIEWGROUP")){
          const realPromise = Auth.currentSession().then(
            (auth) => {
              let apiName = "AuthLambda";
              let path = "/publishVideo";
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  id: this.props.parentID,
                  client: "REVIEWGROUP",  
                  from: this.props.groupconfig.group,    
                }
              };
              return API.post(apiName, path, myInit);
            }
          ); //end promise definition
          realPromise.then(data => {
            // this.handlePublish({id:this.props.parentID})
            this.setState({published: true, m_bReturned:true});
          })
          realPromise.catch(function(error) {
            console.log(error);
          });
        }//end window confirm
    }//end confirmPublsih

    /* @brief callback when the completed button is pressed
    */
    const confirmReviewComplete = () => {

      if(window.confirm("Mark the video review as Complete?")){
        Auth.currentSession().then(
          (auth) => {
            let apiName = "AuthLambda";
            let path = "/updateReviewProgress";
            let myInit = {
              body: {
                token: auth.idToken.jwtToken,                        
                review: {
                  id: this.props.parentID,
                  username: auth.idToken.payload['cognito:username'],
                  status: "300",      
                }
              }
            };
            return API.post(apiName, path, myInit);
          }
        ); //end promise definition        
      }//end of if       
    }//end confirmReviewComplete

    /*
      * @brief Callback for the publish button, prompt the user to enter the ClientID and confirm desire to publish
      */
    

  /*
    * @brief Callback for the eject button, not currently called
    */
    const displayCards = chosen !== 'original' && chosen !== 'highlights';
    let publishStyle = {visibility:"hidden"};      
    // let ejectVideoStyle = {visibility:"hidden"};      
    let publishText = "Publish";
    if(VideoReviewGroups.includes(this.props.groupconfig.group)){publishText = "Submit";}

    let reviewStyle = {visibility:"hidden"}
    let completeStyle = {visibility:"visible"}
    let reviewText = "Return";
    if(this.state.m_bReturned){reviewText = "Returned";}
    if(this.state.m_bSubmitted){
      if(VideoReviewGroups.includes(this.props.groupconfig.group)){publishText = "Submitted";}
      else{publishText = "Published";}
    }
    

    if(allowedGroups.includes(this.props.groupconfig.group)){
      if(this.props.groupconfig.group!=="drive_test_group"){
        completeStyle = {visibility:"hidden"};
      }
    }

    if(allowedGroups.includes(this.props.groupconfig.group)){
      if(this.props.groupconfig.group!=="reviewgroup" && this.props.groupconfig.group!=="drive_test_group"){
        reviewStyle = {visibility:"visible"};      
      }
      if(isProcessing||canReprocess){
        publishStyle = {visibility:"visible",opacity: "0.5"};     
      }else{
        publishStyle = {visibility:"visible",opacity: "1"};
      }
      
      // ejectVideoStyle = {visibility:"visible"};     
    }

     //Check the roles assigned to the user:
     let disableEdits = false;
     (this.props.filter.role || []).forEach(role=>{
       if(role==='SiteManager'){disableEdits=true;}
     })

    /*@brief helper method to set which tab has been selected
    */
    const setActiveKey = (_key) => {
      const chosenName = this.state.chosen;
      if(_key ==='highlights'){
         if(this.state.highlightTabCount===0 && this.state.bookmarkLoad && this.state.bookmarkLoad.tag){
          this.setState({chosen:this.state.bookmarkLoad.type,hightlightTabCount: 1})
         }
      }
      const lastActiveTab = this.state.activeTabKey;
      const tabs = this.state.tabs;
      //Remember where we were last on the tab set:
      for(const tab of tabs){
        if(lastActiveTab === tab.eventKey){
          tab.last = chosenName;          
        }        
      }
      //Switch back to the last location
      for(const tab of tabs){
        if(_key === tab.eventKey){
          if(tab.last){            
            this.setState({chosen:tab.last});
          }      
        }        
      }
      this.setState({activeTabKey:_key, tabs:tabs})
    }

    let fileNameTitle = this.props.filename;
    if(this.props.groupconfig.group && this.props.groupconfig.group==="reviewgroup"){fileNameTitle = filenameAlphaStripper(this.props.filename);}
    
    let playerStyle ={};
    
    //Resize the video player for the PM1251 DVR Event AI files:
    if(this.props.filename.toLowerCase().includes("pm1251")){ 
      let playingFileFlag = 0;
      if(this.state.currentPlayingID){
        try{
          playingFileFlag = this.props.videos.byFlag[this.state.currentPlayingID];
        }catch(e){
        }
      }
      if(playingFileFlag != 2){
        playerStyle.maxWidth = "50%";
      }
    } //end resize player check
    // Create the main components of the Reviewer, which are the chooser buttons, the players, and the CardLists
    // console.log("Cards to display: ",this.state.chosen,this.state.cardsByType[this.state.chosen])

    let bAllowControl = true;
    if(this.props.groupconfig.permissions){
      bAllowControl = false;
      if(this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "write"){
        bAllowControl = true;
      }
      //Check if the user permission is read=only
      if(this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "read"){
        disableEdits = true;
      }
      
    }

    return (
      <div className="video-reviewer">
        <div className="video-reviewer-top">
          
          <ReviewTabs  tabContent={tabs}  videoType={this.props.videoType} groupconfig={this.props.groupconfig}             
            activeTabKey={this.state.activeTabKey} onTabChange={setActiveKey}
          />

          <div className="video-reviewer-players" style={playerStyle}>
            {this.props.filename && <h5 className="video-filename">{fileNameTitle}</h5>}
            {players}
          </div>
          { displayCards ?
           <CardListFunc cards={Object.values(this.state.cardsByType[this.state.chosen] || {})}
                     onCardClick={this.expandCard}
                     sortByCreated={true}
                     disableDrag={true}
                     groupconfig = {this.props.groupconfig}
           /> :
           <CardListFunc style={{opacity: '0'}} />
          }
        </div>
        <div className="video-reviewer-bottom">
          {bAllowControl?
            <div className="controlbuttons">
              {/* <img src={procIcon} className="reprocess-button" onClick={reprocessClick} style={reprocessStyle} />  */}
              <button className="complete-button"  onClick={confirmReviewComplete} style={completeStyle}> Review Complete </button> 

              <StatefulButton className="publish-button"  onClick={this.state.published ? ()=>{} : isProcessing||canReprocess? ()=>{}:this.confirmPublish}
                style={publishStyle} > {publishText} </StatefulButton>
              <button className="review-button"  onClick={this.state.published ? ()=>{} : confirmReviewPublish} style={reviewStyle} > {reviewText}  </button> 
              <StatefulButton className="unusable-button"  onClick={ this.clickUnusable} style={reviewStyle}> {this.state.markedUnusable?"Mark Available":"Mark Unusable"} </StatefulButton> 
            </div>  
            :null          
          }
          
         
        </div>
        { this.state.expandedCard &&
          <ExpandedCard handleClose={this.unexpandCard}
                        {...this.state.expandedCard}
                        cardChange={this.cardChange}
                        tagChange={this.cardTag}
                        driverChange ={this.changeDriverID}
                        newDetails= {this.onCardCreate}
                        // driverChange ={()=>{console.log("Driver change requested")}}
                        scale={1}   
                        noLink={true}                     
                        noEdit={disableEdits}
                        filter={this.props.filter}
                        groupconfig = {this.props.groupconfig}
                        cacheDB = {{db: this.db, table:'clips', key:'infractionid'}}
                        siteDetails = {(this.props.possibleFilters.Sites||[]).filter(filt_ => {
                            if(this.state.expandedCard && filt_.site){
                              let filtValue = filt_.site.toLowerCase();
                              let cardValue = this.state.expandedCard.siteID.toLowerCase();
                              return filtValue===cardValue;
                            }
                            return false;
                          })}                                    
          />
        }
        { this.state.roiselector &&
          <ROISelector  handleClose={this.closeROI}
                        {...this.state.roiselector}
                        hasResults = {this.state.hasResults}                        
                        groupconfig = {this.props.groupconfig}
          />
        }
      </div>
    );
  }
}

/*
* @brief Create a functional component wrapper for the class call
*/
const VideoReviewer = ({  video, videos, filename, filter, possibleFilters, groupconfig, cardsByType
                          ,reviewStatus,parentID,refreshList,onRefresh,cardChange,tagChange,updateVideos
                          ,driver,username,reviewerName,review24Hr,videoType}) => {
  // console.log("Received:" ,video,groupconfig, filter)
  return <VideoReviewerClass
          // video = {{
          //     status: VIDEO_STATUS.AVAILABLE,
          //     uniqueKey: 'ad4ef557-349c-47bf-b6c7-bb220ada7755'
          // }}
          video = {video}
          videos = {videos}
          videoType = {videoType}
          filename = {filename}
          groupconfig= {groupconfig}
          filter = {filter}
          possibleFilters = {possibleFilters}
          cardsByType = {cardsByType}
          parentVideo={video}

          reviewStatus={reviewStatus}
          parentID={parentID} 
          refreshList= {refreshList }
          onRefresh={onRefresh}
          cardChange={cardChange}                             
          tagChange = {tagChange}
          vidChange = {updateVideos}
          driver={driver}
          username = {username}
          reviewerName = {reviewerName}
          review24Hr = {review24Hr}
  
  />
 };

export { VideoReviewer };
 
