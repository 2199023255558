import { Auth, API } from 'aws-amplify';
import { processRoad,routeBuilder } from '../map-utils';
import axios from 'axios';

//OpenLayers imports:
import {LineString,Point} from 'ol/geom';
import {Polyline} from 'ol/format';
import Feature from 'ol/Feature';
import { fromLonLat} from 'ol/proj';

//Other imports
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from '../map-utils.js'


/**
 *  Class to fetch and process the road data 
 */
class RoadFactory{
  /**
   * Initialize the member variables that are tracked by the class
   */
  constructor(){}

  /**
   * Simple method to delete the previously loaded roads
   * @param {*} _sourceLayer: Reference to the map layer that hold the roads
   */
  async clearRoads(_sourceLayers){ 
    if(!_sourceLayers){return;}
    for(const layer_ of Object.values(_sourceLayers)){
      // console.log("Layers: ",_sourceLayers, layer_,);
      layer_.clear(); //call clear to remove all features (roads)
    }
    
  }

  /**
   * Load the roads that are tagged for the given site
   * @param {*} _site : the desired site
   * @param {*} _props : Props including the group config(clientid)
   * @param {*} _sourceLayer : Reference to the map layer that hold the roads
   */
  async loadRoads(_site, _props, _sourceLayers){ 
    if(!_sourceLayers){return;}
    //Remove the old roads
    await this.clearRoads(_sourceLayers);
    //Get the list of roads for the site
    let queryResponse = await this.queryRoads(_site,_props);
    //Fetch each road and render to the map
    for(const road_ of queryResponse.roads){
        // if(!road_.gpsdata){ console.log("Missing gpsdata file for: ",road_); break;}
        this.fetchRoads(road_,_site,_sourceLayers.roads);
        //Get the markers if available
        this.addSigns(road_,_sourceLayers.signs);    
    } //end journey loop
    
  }//end loadRoads

  /**
   * Download the file with the GPS coordinates, filter the GPS points to extract the longest
   * continuous part of the road
   * Add the points to a PolyLine and render as the Road feature
   * @param {*} _site : the desired site
   * @param {*} _props : Props including the group config(clientid)
   * @param {*} _sourceLayer : Reference to the map layer that hold the roads
   */
  async fetchRoads(road_,_site,_sourceLayer){
    if(road_.type !=='road'){return;}
    axios({ //don't specify the response type -> returns the JSON object
      url: road_.gpsdata,
      method: 'GET',
        // responseType: 'blob', // important
    })
    .then(res => {
        try {
          // console.log("response: ",res,road_);
            //segments - return as journeySegments to display on map
            let roadReturn = processRoad(res.data, 1);
            // console.log("Returned roads:" ,roadReturn);

            

            //Add each Journey to the map:
            //Set details for the current journey 
            let roadIdx = 0;
            for(const roadElem_ of roadReturn){
                try {
                  // console.log("Road returned:" ,road_);
                  //Add to the line and project to map coordinates:
                  let lineString = new LineString(roadElem_.gps)
                  //Transform to the mercator projection for the display
                  // lineString.transform(COORDTYPE_GPS, COORDTYPE_MERCATOR);
                  
                  // console.log("Route to add: ", route);
                  let lineString2 = new LineString(roadElem_.gps)
                  lineString2.transform(COORDTYPE_GPS, COORDTYPE_MERCATOR);
        
                  //Add the line to the polyLine
                  var pathInput = new Polyline({factor: 1e6}).writeGeometry(lineString);
                  //Extract the polyline
                  const polyLineGeom = new Polyline({factor: 1e6,}).readGeometry(pathInput, {dataProjection: COORDTYPE_GPS, featureProjection: COORDTYPE_MERCATOR,});

                  let route = routeBuilder(roadElem_,lineString2,roadIdx++);
                  //Create the feature to add to the map:                 
                  let featureDetails = {
                    type:'road'
                    ,siteid:_site
                    ,routeData:[]                    
                    ,routes:0
                  }
                  featureDetails.routeData.push(route);
                  featureDetails.routes=featureDetails.routes++;
                  const roadFeature = new Feature(featureDetails);
                  roadFeature.setGeometry(polyLineGeom);

                  // Add the feature to the map:
                  _sourceLayer.addFeature(roadFeature);        

                } catch (error2) {
                    console.log("No roads? ",roadReturn, error2);
                }
            }//end processing the journeys
        } catch (error) {
            console.log("Failed to process roads:" ,error);
        }
    });//end handling then(after download completed)
  }//end fetchRoads

  // /**
  //  * Download the file with the marker coordinates, add the markers to the road feature
   
  //  * @param {*} _site : the desired site
  //  * @param {*} _props : Props including the group config(clientid)
  //  * @param {*} _sourceLayer : Reference to the map layer that hold the roads
  //  */
  // async fetchMarkers(road_,_sourceLayer){
  //   if(!road_.markers){return;}
  //   axios({ //don't specify the response type -> returns the JSON object
  //     url: road_.markers,
  //     method: 'GET',
  //   })
  //   .then(res => {
  //       try {
  //         console.log("Marker response: ",res.data);
  //           //segments - return as journeySegments to display on map

  //           //Add markers to the feature:
  //           for(const marker_ of res.data){
  //             console.log("Marker: ",marker_);
  //             try {
  //               try {
  //                 //Separate GPS:
  //                 let dataValues = marker_.loc.split(",");
  //                 marker_.lon= parseFloat(dataValues[0]);
  //                 marker_.lat= parseFloat(dataValues[1]);
  //               }catch(error1){
  //                 console.log("Failed to parse GPS: ",error1, marker_);
  //               }
  //               //Add a new feature to the Road layer
  //               var featureToAdd = new Feature({
  //                   geometry: new Point(fromLonLat([ marker_.lon, marker_.lat])),                    
  //               });
  //               featureToAdd.set('Lat',marker_.lat);
  //               featureToAdd.set('Lon',marker_.lon);
  //               featureToAdd.set('type','sign'); //set the type as alert for the popup rendering
  //               featureToAdd.set('classification',marker_.type);

  //               //Add the feature to the map:
  //               _sourceLayer.addFeature(featureToAdd);       
  //             } catch (error) {
  //               console.log("Marker json: ",error, marker_);
  //             }
  //           }//end loop
            
  //       } catch (error) {
  //           console.log("Failed to process roads:" ,error);
  //       }
  //   });//end handling then(after download completed)
  // }//end fetchMarkers

  /**
   * Download the file with the marker coordinates, add the markers to the road feature
   
   * @param {*} _site : the desired site
   * @param {*} _props : Props including the group config(clientid)
   * @param {*} _sourceLayer : Reference to the map layer that hold the roads
   */
  async addSigns(road_,_sourceLayer){
    // for(const road_ of queryResponse.roads){
    if(road_.type !=='sign'){return;}
      // console.log("Marker: ",road_);
      try {
        let marker = {type:road_.attributes.type};
        try {
          //Separate GPS:
          let dataValues = road_.attributes.loc.split(",");
          marker.lon= parseFloat(dataValues[0]);
          marker.lat= parseFloat(dataValues[1]);
        }catch(gpsError){
          console.log("Failed to parse GPS: ",gpsError, marker,road_);
        }
        if(!marker.lon){return;} //don't process a bad GPS position

        //Add a new feature to the Road layer
        var featureToAdd = new Feature({
            geometry: new Point(fromLonLat([ marker.lon, marker.lat])),                    
        });
        featureToAdd.set('Lat',marker.lat);
        featureToAdd.set('Lon',marker.lon);
        featureToAdd.set('type','sign'); //set the type as alert for the popup rendering
        featureToAdd.set('classification',marker.type);

        // console.log("Add feature:" ,marker);
          //Add the feature to the map:
        _sourceLayer.addFeature(featureToAdd);       
      } catch (error) {
          console.log("Marker json: ",error, road_);
      }
  }//end addSigns

  /**
   * Call the API to get a list of the roads that are attached to the site
   * @param {*} _site : the desired site
   * @param {*} _props : Props including the group config(clientid)
   */
  async queryRoads(_site, _props){
    //Declare a promise, so that the caller can wait for this to complete
      return new Promise( async (resolve,reject) => {
        const authResponse = await Auth.currentSession();
        if(authResponse){
            let apiName = "TrifectaMapAPI";
            let path = "/handleRoads";
            let myInit = {};
            myInit.body = {
              token: authResponse.idToken.jwtToken,
              mode: 'fetch',
              site: _site,
              clientid: _props.groupconfig? _props.groupconfig.group:null
            }
            let apiPromise = API.post(apiName, path, myInit);
            apiPromise.catch(error=>{
              console.log("Road API error: ",error, error.message);
              if (API.isCancel(error)) {
                  console.log("Road Api promise is canceled");
              }              
              reject({error:true, msg: error})
            })
            apiPromise.then(response=>{
              // console.log("Road return: ",response)
              resolve(response); //complete the promise with the query returns
            })
        }else{
          reject({error:true, msg: authResponse})
        }
      });//end promise
  }//end queryRoads
}//end RoadFactory

//Return the instance to the MapView to query the roads
export const RoadBuilder = new RoadFactory(); //return a singleton instance to the callers




