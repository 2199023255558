
import React from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

/**
 * A Simple tab manager for the VideoReviewer
 *   Based on the type of video that was loaded the set of visible tabs is changed
 *   Video types supported: DVR,OFFLINE,DVRREVIEW
 *   Tab visibity is controlled by the tabs Title attribute, this must be defined in the content propery
 */
const ReviewTabs = ({  tabContent,activeTabKey,onTabChange,videoType,groupconfig,...props}) => {

    //State variables to track for the duration of the component
    const [allowedTabs, setAllowedTabs] = React.useState(['Clips']);    //The allowed views, default to common demoninator (Clips)
    const [reviewedByTitle, setReviewedByTitle] = React.useState(null); //Title to show the reviewers name, default to NULL
    const [reviewedByStyle, setReviewedByStyle] = React.useState({});   //Style to apply the reviwers name, default to empty

    /**
     * React to the changes in the type of video
     * Based on the video type the tabs that are displayed should change
     */
    React.useEffect(() => { 
      // console.log("Type:" ,videoType);
      switch(videoType){
        default:
        case 'DVR':{ setAllowedTabs('Clips');}break;
        case 'OFFLINE':{setAllowedTabs('Clips,Highlights');}break;
        case 'DVRREVIEW':{ setAllowedTabs('Clips,All');}break;
      }  
    }, [videoType])

    /**
     * React to changes in the group permissions and reviewerName, if set
     * Add a title above the tabs shows who reviewed the video
     */
    React.useEffect(() => { 
        if(!groupconfig || !props.reviewerName){return;}

        let titleText = null;
        // Add a reviewed by tag to the top of the video based
        if(props.reviewerName && (props.reviewerName && groupconfig.group==="testinggroup")){titleText = "Reviewed By: "+this.props.reviewerName;}
        if(props.reviewerName && (props.username && props.username==="review_user")){titleText = "Reviewed By: "+this.props.reviewerName;}
        //If a text is formatted, then save it to the state to render
        if(titleText){
          setReviewedByStyle({marginBottom:"0.5em"}); //add a margin at the bottom
          setReviewedByTitle(titleText); //save the title to render on the page
        }


    }, [groupconfig, props.reviewerName])

  //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
      // Create a tabs group to dipslay the tabs on the left side of the screen
      return (
        <div className="video-chooser"> 
          {/* Add the reviewed by tag for offline files: */}
          <h5 className="video-reviewby" style={reviewedByStyle}>{reviewedByTitle}</h5>  
          {/* Create the tabs parent component, this holds all tabs */}
          <Tabs defaultActiveKey='infractions' unmountOnExit={true} mountOnEnter={true} 
              id="uncontrolled-tab-example" activeKey={ activeTabKey } onSelect={ (k) => onTabChange(k)}
          >
            {/* Iterate over the tab content if it exists */}
            {tabContent && (tabContent||[]).map((tab_) => {
                //For each element in the content array create a tab and display it
                let titleText = tab_.title||tab_.eventKey; //get the title, if not defined, fall back on the eventkey
                if(!allowedTabs.includes(titleText)){return;} //Don't render a tab that is not in the allowed list
                //Return the tab to render
                return(  
                  <Tab key={tab_.eventKey} eventKey={tab_.eventKey} title={titleText}> 
                      {tab_.set}
                  </Tab>
                )
            })} {/* End of the content map */}
          </Tabs> 
        </div>
        
      );
  };//end of render()

  //Function component is expecting the return, add return to the render() call
  return render();
 };

export { ReviewTabs };
 
