import React, { PureComponent } from 'react';

import './DriverTile.css';

import {blankAvatar} from './RiskUtils.js';
import { Spinner } from '../ApiCaller';

/*
* @brief Display a tile to render in a table
*/
class DriverTile extends PureComponent {
    
  constructor(props) {
      super(props);

      this.state = {
          shown: true,          
          divStyle:{
            '--x': 0,
            '--y': 0
          }
      };
  }

  componentDidMount(){
    //  console.log("Tile: ",this.props);
  }
  /*
  * @brief Draw the photo and driverid
  */
  render() {

    // console.log("Props to render: ",this.props);

    let percentDisplay = '';
    if(this.props.infcount && this.props.total){
      try {
        // console.log("Parse percent: ",parseInt(this.props.infcount,10),parseInt(this.props.total,10),parseInt(this.props.infcount,10)/parseInt(this.props.total,10))
        percentDisplay = parseInt(this.props.infcount,10)/parseInt(this.props.total,10);        
        percentDisplay = (percentDisplay*100).toFixed(1);
      } catch (error) {}
    }

    
    const onClick = (() => {
      //  console.log("Click recived",this.props)
      if(this.props.onClick){this.props.onClick(this.props)}
    });

    if(this.props.loadAllowed){
      return(
        <div className='drivertile' onClick={onClick} >
          <div className="dtrow dttitle" >{this.props.driverid}</div>
          <div className='dtrow dtphoto'>
            <img className="driverphoto" src={this.props.photo || blankAvatar} alt=''  />
          </div>
          <div className="dtrow dtcount">{this.props.infcount}</div>
          <div className="dtrow dtpercent">{percentDisplay+"%"}</div>
        </div>
  
      )
    }else{

      // console.log("Not allowed to load: ",this.props.idx)
      return(
        <div className='drivertile-loading'>
          <div className="dtrow dttitle" >{this.props.driverid}</div>
          <Spinner/>
        </div>
  
      )
      
    }

    
  }
}


export { DriverTile };