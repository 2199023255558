import React, { PureComponent } from 'react';

import { Filters } from './BaseComponents.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {  toTitleCase, severityNumber } from './Util.js';

//Displayed filters:
export const filterKeys = {
    'siteID': {
        'title': 'Site',
    },
    'severity': {
        'display': toTitleCase,
        'title': 'Severity',
        'sortKey': severityNumber,
    },
    'vehicleID': {
        'title': 'Asset',
    },
    'asset': {
        'title': 'Asset',
    },
    'infractionType': {
        'title': 'Clip',
    },
    'driverID': {
        'title': 'Driver',
    },
    'vehicle': {
        'title': 'Asset',
    },
    'driver': {
        'title': 'Driver',
    },
    'site':{
        'title': 'Site',
    },    
    'e3person':{
        'title': 'EDGE3 Person',
    },    
    'infraction': {
        'title': 'Clip',
        // 'defaultValue': this.props.activeFilters['infractions'] ,
        // 'defaultValue': 'Severe Drowsiness'
    },
};

// A special value used in dropdowns when the 'null' value is a valid filter, displaying 'null'
// to the user wouldn't be understandable.
const UNSET = 'unset';

export class HrReviewFilters extends PureComponent {
    constructor(props) {
        super(props);
        // keep track of the start and end date specially because the DatePickers
        // need to know (so they can display a range)
        this.state = {
            'startDate': null,
            'endDate': null,
            checked: false,
        };
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        
    }
    /* @brief Called once the elements of the page are loaded, one time trigger
    */
    componentDidMount() {
        // console.log("HR filters: ",this.props);
        if(this.props.startDate){
            this.setState({'startDate': this.props.startDate});
        }
        if(this.props.endDate){
            this.setState({'endDate': this.props.endDate});
        }
    }
    /* @brief Callbacks for the startDate DatePickers, recieved when the selected date changes
    */
    handleStartDateChange(date) {
        // console.log("start props: ",this.props,date,moment.utc(date).format(),date.format());
        this.setState({'startDate': date});
        this.props.onFilterSelected('startDate', {value: date});
    }
    /* @brief Callbacks for the endDate DatePickers, recieved when the selected date changes
    */
    handleEndDateChange(date) {
        // console.log("Set end date:", date);
        this.setState({'endDate': date});
        this.props.onFilterSelected('endDate', {value: date});
    }
    //Listen for changes to the properties, so we can set the updated start date when the time filter is selected.
    UNSAFE_componentWillReceiveProps(newProps) {

        if( (this.props.startDate !== newProps.startDate) || (this.props.endDate !== newProps.endDate)){
            // console.log("Date changed:" ,newProps.startDate, newProps.endDate);
            this.setState({startDate:newProps.startDate,endDate:newProps.endDate});
        }

        //  if(this.props.modifiedTime !== newProps.modifiedTime){
        //     // console.log("modified time: ",newProps)               
        //     this.setState({modified: new Date()})
        //  }
        

    }//end will receive props
    /* @brief A component to handle the Filter dropdowns on the HR Review page
    *
    * Note: this component is currently not effective in filtering the HR Review page,
    *       it has been implemented to get feedback on the look and feel of the HR Review
    *       page in total.
    */
    render() {
        
        const filters = this.props.filters || {};

        //Declare the contents for the dropdown filters:
        const filterData = {};
        //  console.log("Filters: ",filters)
        Object.entries(filters).forEach(([name, elementSet]) => {
            // console.log("filters: ",name,elementSet);
            const elements = Array.from(elementSet.values());
            let filterKeysLocal = this.props.filterKeys || filterKeys;

            // console.log("elements:" ,name,elements,elements.length,filterKeysLocal[name]);
            try {
                if (elements.length > 1) {
                    const { display, title, sortKey, defaultVal, order } = filterKeysLocal[name];
                    const options = elements.map(element => {
                        // create the 'text' and 'value' for each option in the dropdown
                        // The 'text' is what is displayed, the value is an implementation detail in HTML
                        // const text = (display || ((x) => x))(element === null ? UNSET : element);
                        // const value = element === null ? UNSET : element;
                        // return {
                        //     text: text,
                        //     value: value,
                        // };

                        switch(name){
                            case 'vehicleID':{
                                // console.log("Element:",element);
                                let text = (display || ((x) => x))(element === null ? UNSET : element);
                                let value = element === null ? UNSET : element;
                                if(element.asset){
                                    text = (display || ((x) => x))(element === null ? UNSET : element.alias||element.asset);
                                    value = element === null ? UNSET : element.asset;
                                }
                                return {
                                    text: text,
                                    value: value,
                                };
                            };
                            case 'siteID':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.alias);
                                const value = element === null ? UNSET : element.site;
                                return {
                                    text: text,
                                    value: value,
                                };
                            };
                            default:{
                                const text = (display || ((x) => x))(element === null ? UNSET : element);
                                const value = element === null ? UNSET : element;
                                return {
                                    text: text,
                                    value: value,
                                };
                            };
                        }//end switch 


                        
                    });
                    // Sort the options in the desired order by the sortKey, if any is specified
                    // console.log("Options: ",options)
                    

                    // Custom sort hwtags
                    if(name!=='hwtags'){
                        options.sort((a, b) => {
                            const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                            if (sortBy(a) < sortBy(b)) {
                                return -1;
                            }
                            if (sortBy(a) > sortBy(b)) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    // console.log("Options:" ,title,defaultVal,options)
                    // Add an 'All' option
                    if(name==='infractionType'){
                        options.unshift({text: 'None', value: 'None',});
                    }
                    if(name==='driverID'){
                        options.unshift({text: 'Pending', value: 'Pending',});
                    }
                    options.unshift({text: 'All', value: 'All',});
                    // if(name==='infractionType'){ 
                    //     options.push({text: 'System Tampering', value: 'System Tampering',})
                    // }
                    // And then create the actual object that the Filters component will expect
                    filterData[name] = {
                        options: options,
                        default: defaultVal||options[0],
                        title: title || name,
                    }
                    if(order){
                        filterData[name].order = order;
                    }
                }
            } catch (error) {
                console.log("Error on: ",name,error,elements);
            }

           
        });
        //Handle the audio alert check box:
        let audioAlertFilter = null;
        if(filters.audioalert){ //are there cards to be filtered?
            audioAlertFilter = <div className ="audioalert-filter">
                {/* <label>Audio Alert: </label> */}
                <div>Audio Alert:</div>
                <input type="checkbox"  defaultChecked={this.state.checked} onChange={() =>{
                    //signal the new state back to the calling class
                    this.props.handleCheck({name: 'audio',state: !this.state.checked})
                    //update the internal tracking of the check box:
                    this.setState({checked: !this.state.checked});
                }}/>
            </div>
            
        }

        return (
            <div className={this.props.className}>

                {audioAlertFilter}
                
                {/* <motion.button className="apply-filters"  disabled={!this.props.hasChanged} onClick={this.props.onApply} whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}}> {"Apply"} </motion.button>  */}
                <button className="apply-filters"  disabled={!this.props.hasChanged} onClick={this.props.onApply} > {"Apply"} </button>

                <div className="filter-dropdown-container">
                    <ul className="pcoded-item pcoded-right-item pcoded-right-align filter-drop-container">
                        <li className="label-wrap">
                            <div className="label-text">Date</div>
                        </li>
                    </ul>
                </div>

                
                
                <DatePicker selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleStartDateChange}
                            placeholderText="Start date"
                            isClearable={true}
                            className="date-pick date-start"
                            dateFormat="DD/MM/YYYY"
                />
                <DatePicker selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            placeholderText="End date"
                            isClearable={true}
                            className="date-pick date-end"
                            dateFormat="DD/MM/YYYY"
                />
                <Filters filters={filterData} onFilterSelected={this.props.onFilterSelected} />
                
            </div>
        );
    }
}

export class CallinFiltersView extends PureComponent {
    constructor(props) {
        super(props);
        // keep track of the start and end date specially because the DatePickers
        // need to know (so they can display a range)
        this.state = {
            'startDate': null,
            'endDate': null,
            startedOnce: false,
            showGroupsHelpPopup: false,
            currentSite:null,
            modifiedTime: new Date(),
        };
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        
    }

    // Callbacks for the startDate DatePickers being changed
    handleStartDateChange(date) {
        this.setState({'startDate': date});
        this.props.onFilterSelected('startDate', {value: date});
    }
     // Callbacks for the endDate DatePickers being changed
    handleEndDateChange(date) {
        this.setState({'endDate': date});
        this.props.onFilterSelected('endDate', {value: date});
    }

    // Execute on startup, set the initial values from the properties
    componentDidMount(){
        // console.log("Start filters:" ,this.props);
        if(this.props.startDate){
            this.setState({'startDate': this.props.startDate});
        }
        if(this.props.endDate){
            this.setState({'endDate': this.props.endDate});
        }
    }

    //Listen for changes to the properties, so we can set the updated start date when the time filter is selected.
    UNSAFE_componentWillReceiveProps(newProps) {
        if( (this.props.startDate !== newProps.startDate) || (this.props.endDate !== newProps.endDate)){
            // console.log("Date changed:" ,newProps.startDate, newProps.endDate);
            this.setState({startDate:newProps.startDate,endDate:newProps.endDate});
        }

        if(this.props.modifiedTime !== newProps.modifiedTime){
            // console.log("modified time: ",newProps);              
            this.setState({modifiedTime: newProps.modifiedTime})
        }
    }//end will receive props

    /* @brief A component to handle the Filter dropdowns on the HR Review page
    *
    * Note: this component is currently not effective in filtering the HR Review page,
    *       it has been implemented to get feedback on the look and feel of the HR Review
    *       page in total.
    */
    render() {       
        const filters = this.props.filters || {};

        

        const filterData = {};
        let iOrder = 1;
        //  console.log("Filters: ",filters)
        Object.entries(filters).forEach(([name, elementSet]) => {            
            let elements = Array.from(elementSet.values());

            
            //Apply a site filter to the vehicle dropdown:
            if(this.props.currentSite && name === 'vehicle' && this.props.currentSite !== 'None'){
                //Filter the list of elements, only return ones where the asset's site matches the selected site
                elements = elements.filter(value_ => {
                    return value_.site.toLowerCase() === this.props.currentSite.toLowerCase()
                });
            }
            
            try {
                elements = (elements||[]).filter(elem_ => !elem_.includes('no-')); 
            } catch (error) {}

            
            try{
                if (elements.length > 0) {
                    let filterKeysLocal = this.props.filterKeys || filterKeys;
                    const { display, title, sortKey, defaultValue,order,disabled=false} = filterKeysLocal[name];
                    
                    let options = elements.map(element => {
                        // create the 'text' and 'value' for each option in the dropdown
                        // The 'text' is what is displayed, the value is an implementation detail in HTML
                        
                        switch(name){
                            case 'vehicle':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.asset);
                                let value = element === null ? UNSET : element.asset;
                                
                                return {
                                    text: text,
                                    value: value,
                                };
                            };

                            case 'driver':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.driverid);
                                let value = element === null ? UNSET : element.driverid;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            };

                            case 'site':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.alias);
                                const value = element === null ? UNSET : element.site;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            };

                            default:{
                                let text = (display || ((x) => x))(element === null ? UNSET : element);
                                let value = element === null ? UNSET : element;
        
                                if(name === 'quant'){
                                    text += ' mins'
                                }
                                return {
                                    text: text,
                                    value: value,
                                }; 
                            };
                        }
                        
                    });

                    // Do not sort the hardware tags or bin
                    if(name!=='hwtags' && name!=='bin'){
                        options.sort((a, b) => {
                            const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                            if (sortBy(a) < sortBy(b)) {
                                return -1;
                            }
                            if (sortBy(a) > sortBy(b)) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    switch(name){
                        case 'quant':
                        case 'bin':
                            break;   

                        case 'driver':
                            options = (options||[]).filter(elem_=>elem_.text!=='TOOBIG'); //remove place holders for empty arrays
                            options = (options||[]).filter(elem_=>elem_.value!== undefined); //no empty spaces
                            options.unshift({text: 'All', value: 'no-driver-selected',});
                            break;                        
                        case 'infraction':
                            options.unshift({text: 'None', value: 'None',});                                    
                            options.unshift({text: 'All', value: 'All',});                                
                            break;
                        default:
                            options.unshift({text: 'All', value: 'All',});
                            break;
                    }
                    
                    filterData[name] = {
                        options: options,
                        default: defaultValue || options[0],
                        title: title || name,
                        order: iOrder++,    
                        disabled: disabled,                
                    }
                    if(order){
                        filterData[name].order = order;
                    }
                  
                    
                    if(defaultValue){
                        let tmpDefault = options.filter(item => item.value === defaultValue)
                        if(tmpDefault && tmpDefault.length>0){
                            filterData[name].default= tmpDefault[0].value;
                        }
                    }

                    if(this.props.selectedFilters){
                        // console.log("Selected:" ,this.props.selectedFilters[name])
                        if(this.props.selectedFilters[name]){
                            filterData[name].selected = this.props.selectedFilters[name];
                        }
                    }


                }else{
                    // console.log("empty element set: ",name, elementSet)
                }
            }catch(error){}
            
        });

        if(filterData.quant){
            filterData.quant.order=4
        }
      
        let journeyCount = null;
        try {
            if(filterData['journeys']){
                journeyCount = filterData['journeys'].options.length;
            }    
        } catch (error) {}
        
         console.log('filterdata', this.props.filterKeys || filterKeys, filterData, this.props.selectedFilters);
        //    console.log('filters to display', filters);
        //  console.log('props', this.props);
        return (
            <div className={this.props.className}>
                
                

                {/* <motion.button className="apply-filters"  onClick={this.props.onApply} whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}}> {"Apply"} </motion.button>  */}
                <button className="apply-filters"  onClick={this.props.onApply} > {"Apply"} </button>  
                
                <div className="filter-dropdown-container">
                    <ul className="pcoded-item pcoded-right-item pcoded-right-align filter-drop-container">
                        <li className="label-wrap">
                            <div className="label-text">Date</div>
                        </li>
                    </ul>
                </div>
                <DatePicker selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleStartDateChange}
                            placeholderText="Start date"
                            isClearable={true}
                            className="date-pick date-start"
                            dateFormat="DD/MM/YYYY"
                />
                <DatePicker selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            placeholderText="End date"
                            isClearable={true}
                            className="date-pick date-end"
                            dateFormat="DD/MM/YYYY"                           
                            // popperPlacement="top-end"
                />
                <Filters filters={filterData} onFilterSelected={this.props.onFilterSelected} sort={false} button={this.props.button}/>

                
                
                {journeyCount && <div className = "journey-count">
                                    <div>Journeys: </div>
                                    {journeyCount}
                                 </div>        
                }

                
                
            </div>
        );
    }
}