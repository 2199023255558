
/**
 *  Utilitiy class to auto optimize a query by splitting the date parameters 
 *  Old data that is not expected to change can be cached and not requeried in future refreshes
 */
class LiveClipClass{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.subscribers=[]; //Set instances that will be notified of a new message on the websocket
        this.username = 'unset';
    }

    //Forward the clip to all listeners
    addClip(_clip){
        //Iterate through the listeners to see who want this event type:
        for(const sub_ of this.subscribers){
            if(_clip.assetid===sub_.name){
                sub_.callback(_clip);
            }
            
        }
    }// end init
    
    /**
     * Register a component/class with the websocket, this will enable any message of the desired type
     * to be forwarded to the registered class
     * @param {*} _data : object holding details of the registration:
     *  -name: unique name for the connection (example: LiveTab)
     *  -onMessage: callback function to recieve the message
     *  -type: types of messages to listen for (comma seperated list of multiple types)
     * @returns 
     */
    subscribe(_data){
        // console.log("Try Register listener:" ,_data, _data.name);
        //Check the min details are met
        if(!_data.name){return;}
        if(!_data.callback){return;}
        //If met then add to the list
        this.subscribers.push(_data);
        // console.log("Subs: ",this.subscribers);
    }//end register()

    /**
     * remove a component from the registerd listeners
     * @param {*} _data : the name of the class/component to remove
     */
    release(_data){
        //Iterate over the registered users and remove the requested user:
        const index = (this.subscribers||[]).findIndex(elem_ => elem_.name === _data.name);
        if (index > -1) { // only splice array when item is found
            this.subscribers.splice(index, 1); // 2nd parameter means remove one item only
        }
    }//end release()
    /**
     * Close out the websocket interface (disconnect from the server)
     */
    close(){
        console.log("Closed LiveClipClass called");
    }//end close

}//end LiveClipClass


export const LiveClipInterface = new LiveClipClass(); //return a singleton instance to the callers



