
import React from 'react';
import ReactModal from 'react-modal';

import { Spinner } from '../ApiCaller.js';
import { Auth, API } from 'aws-amplify';
import { StatefulButton } from '../VideoReview/vid-util.js';

import { UserConfirm } from './UserConfirm.js';
import {InputTime,secondsToTime} from './InputTime.js'
import { MESSAGE_ENUM } from './VideoCutUtil.js';
import { WebsocketInterface } from '../WebsocketInterface.js';

import './CutVideoNotecard.css';


/* @Brief Component to render the CutVideo choices (specify the time window before and after to cut the video)
*/
export const CutVideoNotecard = ({details,handleClose,...props}) =>{

    //Toggle to show the CutVideoNotecard content
    const [showModal, setShowModal] = React.useState(false);
    
    //Control the popup confirmation box
    const [userConfirmMessage, setUserConfirmMessage] = React.useState('no message');
    const [affirmativeLabel, setAffirmativeLabel] = React.useState("Start Cut");
    const [negativeLabel, setNegativeLabel] = React.useState("Cancel");
    const [showUserConfirm, toggleUserConfirm] = React.useState(false); //open first as the card loads?
    const [userMessageProgress, updateMessageProgress] = React.useState(0);
    
    //Details for the card:
    const [dvrStatus, setDVRStatus] = React.useState(null); 
    const [clipDetails, setClipDetails] = React.useState(''); 

    //Interactions with the notecard
    const [cutBoundary, setCutBoundary] = React.useState({pre:null, post: null, total: 1*60*60});
    const [overlapOverride, setOverlapOverride] = React.useState(false);    
    const [errorMessage, setErrorMessage] = React.useState(0);

    //Render feedback
    const [completeTimeEstimate, setTimeEstimate] = React.useState(0);
    const [renderFeedbackState, setFeedbackState] = React.useState(0);
    
    //Captured status to send to the API
    const [preLength, updatePreLength] = React.useState({time:0,bound:false});
    const [postLength, updatePostLength] = React.useState({time:0,bound:false});
    const [timesAreClamped, setClampedApplied] = React.useState(true);

    //Data returned from the API
    const cutRequest = React.useRef();

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        setUserConfirmMessage('Would you like to cut a longer video from this notecard?');
        toggleUserConfirm('CUT_CONFIRM');
        //Registerd the listener for the messages:
        WebsocketInterface.register({name:'CutVideo',onMessage:onMessage, type: 'videocut'});
        return ()=>{ //Release the listener when the notecard is closed
            WebsocketInterface.release({name:'CutVideo'});
        }
    },[]) //[] -> execute once

    //Wait until we get the details from the infraction notecard
    React.useEffect(()=>{
        // console.log("Received from notecard:" ,details);
        setClipDetails(details);
        if(!clipDetails){
            //Trigger the request for the details on the notecard
            fetchData({infractionid:details.infractionID})
        }
    },[details]) //listen for the details of the parent notecard

    /** 
     * Handle what to do when the dvrStatus is returned, switch between the messages
     * based on the returned value
     */
    React.useEffect(()=>{
        if(userMessageProgress===0){return;}
        // console.log("Process dvrstatus: ",userMessageProgress,dvrStatus,userMessageProgress>MESSAGE_ENUM.CUT_CONFIRM);
        // return;
        switch(dvrStatus){
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.AVAILABLE):{
                return;
            }break;
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.UNAVAILABLE):{
                if(userMessageProgress>MESSAGE_ENUM.CUT_CONFIRM){break;}
                setUserConfirmMessage(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.UNAVAILABLE));
                toggleUserConfirm(dvrStatus);
            }break;
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.PENDING):{
                if(userMessageProgress>MESSAGE_ENUM.CUT_CONFIRM){break;}
                setUserConfirmMessage(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.PENDING));
                toggleUserConfirm(dvrStatus);
            }break;
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.ARCHIVED):{
                console.log("in case archived");
                if(userMessageProgress>MESSAGE_ENUM.CUT_CONFIRM){break;}
                console.log("Passed the progress value:",userMessageProgress )
                setUserConfirmMessage(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.ARCHIVED));
                toggleUserConfirm(dvrStatus);
            }break;            
            // The desired cut length has been modified to fit the available data, is the modified length acceptable?
            //An existing Video Cut exists that contains this time frame. Would you like to Cut it again?
        }//end switch
    },[dvrStatus,userMessageProgress]) //listen for changes to the DVR status

    React.useEffect(()=>{
        if(!completeTimeEstimate){return}

        setNegativeLabel('Close');
        setAffirmativeLabel(null);


    },[completeTimeEstimate]) //time estimate is available
    

    //Helper function to fetch the Driver photo and name from the API
    const fetchData = async (_data) => {
        // console.log("Fetch photo for :",_driverID)
        const authReturn = await Auth.currentSession();
        //Set up the API request:
        let apiName = "VideoCutAPI";
        let path = "/VideoCutInquiry";
        let myInit = {
            body: {
                token: authReturn.idToken.jwtToken,
                infractionid: _data.infractionid,
                mode: 'status',              
            }
        };
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            try {
                // console.log("Data return: ",apiReturn);
                let dvrDetails = apiReturn.data.dvr;

                //Keep a reference to the data so that the API doesn't have to recompute it.
                cutRequest.current = apiReturn.data;

                //Extract the pre/post boundaries:
                if(apiReturn.data.clipJourney){
                    let newBoundary = Object.assign({},cutBoundary);
                    newBoundary.pre = apiReturn.data.clipJourney.maxPre;
                    newBoundary.post = apiReturn.data.clipJourney.maxPost>0?apiReturn.data.clipJourney.maxPost:null;
                    setCutBoundary(newBoundary);
                }
                // console.log("User message test: ",userMessageProgress, MESSAGE_ENUM.CUT_CONFIRM, userMessageProgress<=MESSAGE_ENUM.CUT_CONFIRM);
                if(userMessageProgress <= MESSAGE_ENUM.CUT_CONFIRM){
                    setDVRStatus(dvrDetails.status);
                }
            } catch (error) {
                console.log("fetch error: ",error);
            }
        }//end API return check
    };//end fetchData()

    /**
     * Websocket message returned
     * @param {*} _data : message from the websocket
     */
    function onMessage(_data){
        // console.log("Notification Message: ",_data, cutRequest);
        if(!cutRequest.current.videocutid){
            console.log("Missing known videocutID (pk_id)");
            return;
        }
        if(!_data.message){console.log("no message: ",_data); return;}
        

        //Check if the message id matches the id on the opened notecard:
        let messageid = _data.message.videocutid || _data.message.pk_id
        if(cutRequest.current.videocutid == messageid){
            // console.log("Received a matched message ", _data);
            //handle the type of message received:
            let messageType = _data.message.messagetype;
            if(messageType.includes("FAIL")){ //Handle all FAIL types as an eror condition
                messageType = 'ERROR';
            }
            switch(messageType){
                default:{
                    console.log("Message error, type not specified: ",_data.message);
                }break;
                case 'COMPLETE':{
                    setFeedbackState(1); //Update the message on the card, add a redirect button
                }break;                
                case 'ERROR':{                    
                    setErrorMessage(true); //update the mesage on the card
                }break;
            }//end switch
        }
    }//end onMessage()

    /**
     * Handle the return from the UserConfirm dialog
     * Switch based on the type of confirm dialog that was open
     * @param {*} _response 
     */
    function handleUserConfirmClose(_response){
        // console.log("Close return: ",_response);
        toggleUserConfirm(false); //close the confirm popup

        let type = _response.type;
        switch(type){
            default:
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.CUT_CONFIRM):{
                if(_response.selected ===true){
                    setShowModal(true);
                    updateMessageProgress(MESSAGE_ENUM.CUT_CONFIRM);
                    // setTimeout(()=>{return toggleDelay(true);},1000);
                }else{ closeCard(false);}
            }break;
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.OVERLAP):{
                updateMessageProgress(MESSAGE_ENUM.OVERLAP);
                if(_response.selected ===true){
                    setOverlapOverride(true); //allow the cut to be submitted with the overlap values
                    submitCutRequest({overlap_override: true}); //trigger the submit request
                }else{ 
                    // closeCard(false); //return to the cutVideo card to change times
                }
            }break;
            case MESSAGE_ENUM.STRING(MESSAGE_ENUM.BOUND):{
                if(_response.selected ===true){
                    // setShowModal(true);
                    updateMessageProgress(MESSAGE_ENUM.BOUND);
                    submitCutRequest();
                }else{ closeCard(false);}
            }break;
        }//end switch
    } //end handleUserConfirmClose

    /**
     * Handle the submit button return (this is the start cut/ close button)
     * This changes the actions taken based on the response from the API
     * @param {*} _data 
     */
    function handleSubmitReturn(_data){
        // console.log("Submit Return(handle): ",_data)
        //Update the tracked API data:
        cutRequest.current = _data.data;
        //Look at the status of the return:
        switch(_data.status){
            case 'OVERLAP':{
                console.log("In Overlap case")
                //Found a clip that already exists in the is time frame, need to 
                //Ask the user to confirm the a new clip is desired
                setUserConfirmMessage(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.OVERLAP));
                // console.log("Set the status to : ",_data.status);
                toggleUserConfirm(_data.status);
            }break;
            case 'DUPLICATE':{
                //Found a clip with the exact same start/stop time
                //This clip's review status will be reset and the user notified that it will require 5 seconds to process:
                let timeEstimate = 5;
                setTimeEstimate(timeEstimate);
            }break;
            case 'INSERTED':{ 
                //New clip created
                //Dispay the estimated time to complete:
                let timeEstimate = _data.data.estimatedComputeTime;
                if(dvrStatus === MESSAGE_ENUM.STRING(MESSAGE_ENUM.ARCHIVED)){
                    timeEstimate += 24*60*60;// add 24 hours estimate delay for archived videos
                }
                if(dvrStatus === MESSAGE_ENUM.STRING(MESSAGE_ENUM.PENDING)){
                    timeEstimate = "Unknown";// must wait for the upload to complete
                }

                setTimeEstimate(timeEstimate);                
            }break;
            case 'error':{ 
                setErrorMessage(true);                
            }break;
            default:{
                console.log("Uncaught return type? ",_data)
            }break;
        }

    }
    /**
     * Forward the request to the API after the submit button was clicked
     * @param {*} _data 
     */
    const submitCutRequest = async (_data) => {
        // console.log("Submit Request :",cutRequest);
        const authReturn = await Auth.currentSession();

        let bodyParams= {
            token: authReturn.idToken.jwtToken,
            infractionid: clipDetails.infractionID,
            prelength: preLength.time,
            postlength: postLength.time,
            username: authReturn.idToken.payload['cognito:username'],
            overlap_override: overlapOverride,
            mode: 'submit',              
            prevCutRequest: cutRequest.current,
        }

        //Set up the API request:
        let apiName = "VideoCutAPI";
        let path = "/VideoCutInquiry";
        let myInit={};
        myInit.body = Object.assign(bodyParams,_data)
            
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            try {
                // console.log("Submit return: ",apiReturn);
                //Handle new overlap case?
                handleSubmitReturn(apiReturn);
                
            } catch (error) {
                console.log("fetch error: ",error);
            }
        }//end API return check
    };//end fetchData()

    //Handle changes to the time before or time after cut length
    React.useEffect(()=>{
        let clampInAffect = true;

        let isBoundSet = preLength.bound || postLength.bound;
        let isTimeSet = preLength.time>0 || postLength.time>0;

        if(!isBoundSet && isTimeSet){
            clampInAffect = false;
        } //end check if bound is set
        // console.log("Set the generate state: ",preLength,postLength,disabledToSet);
        setClampedApplied(clampInAffect);
    },[preLength, postLength]) //listen for changes 

    /**
     * The footer buttons have been clicked (either cancel or cut/close)
     * @param {*} _data 
     */
    function closeCard(_data){
        // console.log("Close called")
        if(_data){ //if set to true, generate was called
            if(timesAreClamped){ //disabled due to bound time inputs;
                setUserConfirmMessage(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.BOUND));
                // console.log("Set the status to : ",MESSAGE_ENUM.BOUND);
                toggleUserConfirm(MESSAGE_ENUM.TEXT(MESSAGE_ENUM.BOUND));
            }else{
                submitCutRequest();
            }
            
            return true;
        }else{ //cancel was clicked
            if(handleClose){handleClose();}
        }
    }

    /**
     * Redirect to the VideoCut notecard to review the result
     */
    function redirectCard(){
        if(!cutRequest.current){return;}
        closeCard(false);//close the current notecard
        //Send the message to open the new notecard
        if(props.eventNotify){
            props.eventNotify({
                type: 'popup',
                data: {
                  href: 'videocut',                                    
                  extra: {
                    pk_id: cutRequest.current.videocutid,
                  }
                },
              });
        }
    }//end redirectCard()
  
    /**
     * Callback to apply the time boundaries to the InputTime fields
     * @param {*} _time : time set in the field
     * @param {*} _type : type of field ('pre/post')
     */
    function applyBounds(_time,_type){
        let boundTimeObj = {
            time: _time,
            bound: false
        }
        //Apply the min bounds, based on the Journey data:
        switch(_type){
            case 'pre':{
                if(!cutBoundary.pre){break;}                
                if(cutBoundary.pre<_time){
                    boundTimeObj.time = Math.min(cutBoundary.pre,_time);
                    boundTimeObj.bound = true;
                }
            }break;
            case 'post':{
                if(!cutBoundary.post){break;}
                if(cutBoundary.post<_time){
                    boundTimeObj.time = Math.min(cutBoundary.post,_time);
                    boundTimeObj.bound = true;
                }
            }break;
        }
        //Apply the total time limit (1hr)        
        let returnObject = applyTimeLimit(boundTimeObj,_type);
        
        
        //Keep track of the value to evaluate the generate criteria
        switch(_type){
            case 'pre':{ updatePreLength(returnObject); }break;
            case 'post':{ updatePostLength(returnObject);}break;
        }

        //Apply the max total length: (1hr)
        return returnObject
    }

    /**
     * Constrain the total time requested for the cut video to 1 hour in length
     * Called after the time before/after have already been clamped to the Journey boudaries
     * @param {*} _boundTimeObj - bound time object (time, bound:true|false)
     * @param {*} _type type of field ('pre/post')
     */
    function applyTimeLimit(_boundTimeObj,_type){
        // console.log("timelimit:", _boundTimeObj,_type);
        let maxTotalCutTime = (1*60*60); //1 hour in seconds
        let maxRemainingTime = maxTotalCutTime;
        
        switch(_type){
            case 'pre':{
                maxRemainingTime-= (postLength.time||0);
            }break;
            case 'post':{
                maxRemainingTime-= (preLength.time||0);
            }break;
        }
        //Is the combined time more than the max allowed time?
        //Then update the last edited to fit the max time:
        if(_boundTimeObj.time > maxRemainingTime){
            _boundTimeObj.time = maxRemainingTime;
            _boundTimeObj.bound = true;
        }else{ //check if the change affected the alternate
            let totalTime =  _boundTimeObj.time;
            switch(_type){
                case 'pre':{
                    if(!postLength.bound){break;}
                    totalTime+= (postLength.time||0);
                    if(totalTime < maxTotalCutTime){
                        postLength.bound = false;
                        updatePostLength(postLength);
                    }
                }break;
                case 'post':{
                    if(!preLength.bound){break;}
                    totalTime+= (preLength.time||0);
                    if(totalTime < maxTotalCutTime){
                        preLength.bound = false;
                        updatePreLength(postLength);
                    }
                }break;
            }
        }
        return _boundTimeObj;
    }//end applyTimeLimit


    /**
     * Helper function to render the message after the submit button is pressed
     * @returns 
     */
    function renderFeedback(){
        //Handle the error message case
        if(errorMessage){
            return (
                <div className='cutfeedback errormsg'>
                    <div className='text-label'>An error occured during the Video Cut request, please contact EDGE3</div>
                </div>
            )
        }
        //Change the feedback rendered on the card based on the state (estimating time (0), completed message (1))
        switch(renderFeedbackState){
            default: //time estimate, waiting for the complete message
            case 0:{
                //Render the time to complete and notification message
                if(completeTimeEstimate > 0){
                    return (
                        <div className='cutfeedback'>
                            <div className='text-label'>{"Estimated time until the Video Cut is available: "+secondsToTime(completeTimeEstimate) }</div>
                            <div className='text-label'>A browser notification and email will be received when the Video Cut is available.</div>
                            <Spinner/>
                        </div>
                    )
                }
            }break;
            case 1:{
                //Render the time to complete and notification message
                return (
                    <div className='cutfeedback'>
                        <div className='text-label'>Video Cut complete</div>
                        <p className='text-label'>The video cut can be reviewed immediately by click on the "View Video Cut" button below.
                                                    Additionally, all video cuts are available in the Notifications section.</p>
                    </div>
                )
            }break;
        }
        return (<div/>);
    }//end renderFeedback
    

    
    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        //Return the HTML code to display:
        return (
            <div className="cutvideonotecard" >
                <ReactModal isOpen={showModal} className="modal-dialog cutvideonotecard-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="content-modal" >
                        <div className = 'title'>  Cut Video  </div>
                        {/* Define the top half of the notecard show the AssetID, Date,  Speed Stats, and Time Stats */}
                        <div className='header-block'>
                            <div className='dvr-status'>
                                <div className='text-label'>DVR Status:</div>
                                {/* <React.Fragment> */}
                                {!dvrStatus? <div className='FETCHING'> {<Spinner/>} </div>
                                            :<div className={dvrStatus}> {dvrStatus} </div>
                                }
                            </div>
                        </div>
                        <div className='top-block'>
                            <div className='section'>
                                <div className='section-title'>Configure the length of the new video cut:</div>
                                <div className='section-content'>
                                    <div className='cutwindow'>
                                        <InputTime
                                            className='pre-length'
                                            type='pre'
                                            label='Time Before:'
                                            onValidate={applyBounds}
                                        />
                                        <InputTime
                                            className='post-length'
                                            type='post'
                                            label='Time After:'
                                            onValidate={applyBounds}
                                        />
                                    </div>
                                    {renderFeedback()}
                                </div>
                            </div>
                        </div>
                       
                        {/* Add the cancel and submit buttons to the bottom of the Notecard */}
                        <div className="footer">
                                {negativeLabel      && <StatefulButton className={"btn btn-danger"} onClick={ ()=>closeCard(false)} > {negativeLabel}   </StatefulButton>}
                                {/* If video cut is complete, then show the redirect button, with a spacer to keep things centered */}
                                {renderFeedbackState===1  && <StatefulButton className={"btn btn-success"} onClick={ ()=>redirectCard()} > View video cut   </StatefulButton>}                                
                                {renderFeedbackState===1  && <div className="blank-spacer"/>}                                
                                {affirmativeLabel   && <StatefulButton className={"btn btn-primary"} onClick={ ()=>closeCard(true)} > {affirmativeLabel} </StatefulButton>}
                        </div>
                    </div>
                    
                </ReactModal>
                {/* Show the user confirm message box */}
                {showUserConfirm&&  
                    <UserConfirm 
                        groupconfig = {props.groupconfig}
                        message = {userConfirmMessage}
                        onClose={handleUserConfirmClose}
                        type={showUserConfirm}
                    />    
                }
            </div>
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}//end of the CutVideoNotecard

