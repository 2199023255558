
import React, { PureComponent, useEffect } from 'react';
import { unmountComponentAtNode } from "react-dom"
import ReactModal from 'react-modal';


import './VideoCard.css';
import './NoteCard-Comments.css';

import {translateUserName } from './Util.js';


import * as moment from 'moment';


import { Auth, API } from 'aws-amplify';

import { StatefulButton } from './VideoReview/vid-util';


import EditIcon     from './assets/pencil-edit.svg';



// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)

// See https://momentjs.com/docs/#/displaying/ for formatting syntax
const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';







  const EditTextArea = ({ text, onChange,  onClick}) => {
    //1 Logic
    const [editText, setText] = React.useState(text);  //initialize the state to false

    React.useEffect(()=>{
        setText(text);
    },[text]);

    React.useEffect(() => {
        // document.getElementById('testme111').focus();
        console.log("Mounted the edit text");

        return () => {
            // document.getElementById('testme111').blur();
            console.log("Unmount on edit text");
        }
    },[])
    //2 Render 
    return (
        <div className="editing-comment">
                <textarea id = 'testme111' value={editText} 
                    autoFocus
                    onChange={onChange}
                />
                <button className="editing-confirm" onClick={onClick}>
                    <div className="buttonText">✓</div>
                </button>                                                        
        </div>
    );
  }

//   if(this.state.showEdit ){
//     textElement =  
//     EditStyle= {visibility:"hidden"}; 
// }

/*
* @brief A Comment to be displayed, with text and a timestamp
*/
export class Comment extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { 
            showEdit: false ,
            originalText: '',
            text: '',
            noteid: this.props.noteid,
        };
    }
    /*
    * @brief Called when created
    */
    componentDidMount() {        
        // console.log("Comment mount: ",this.props);
        this.setState({showEdit:false,originalText:this.props.text,text:this.props.text});
    }

    /*
    * @brief Draw the comment content
    */
    render() {
        //Set up the behavior of images
        // const PosedImg = posed.img({
        //     pressable:true,
        //     init: { scale: 1 },
        //     press: { scale: 0.9,  },        
        // });

        const updateCommentConfirm = () => {
            let promiseReturn = null;
            try{
                // console.log("Card at update comment? ",document.getElementById('expanded-card-id'))
                // console.log("Update comment: ",this.props.infractionID)
                const oldText = this.state.originalText;
                const newText = this.state.text;
                
                // Send request to modify the SQL table:
                promiseReturn = Auth.currentSession().then(
                (auth) => {
                    let apiName = "AuthLambda";
                    let path = "/updateComment";
                    let myInit = {
                        body: {
                            token: auth.idToken.jwtToken,
                            cardid: this.props.cardid,
                            infractionid: this.props.infractionID,
                            oldtxt: oldText,
                            nextxt:newText,
                            type:this.props.notecardtype,
                        }
                    };
                return API.post(apiName, path, myInit);
                });

                //Handle the promise return from the API:
                if(promiseReturn){
                    promiseReturn.then(_data =>{
                        // console.log("API returned? ",_data);
                        // console.log("Write to which: ",this.props, this.props.notecardtype);
                        //notify that the text has been updated to the parent
                        this.props.updateComment({noteid: this.state.noteid , action:'edit', originalText:oldText, text:newText });
                    })
                } 
                
            } catch (error) {   }
            
            this.setState({ showEdit: false,originalText: this.state.text}); 
            
            
        } //end updateCommentConfirm
        // let mapFilter

        //<pre> means pre formatted text, can include space and new lines etc.
        let textElement =   <pre>{this.props.text}</pre>
        let EditStyle = {visibility:"visible"};      

        let allowEdit = false;
        
        if(this.props.username && translateUserName(this.props.username)===translateUserName(this.props.currentUser)){
            allowEdit = true;
        }
        
        if(this.props.commentindex  < this.props.totalCount-1){
            allowEdit = false;
        }
        
        if(this.state.showEdit ){
            // textElement =  <div className="editing-comment">
            //                     <textarea value={this.state.text} 
            //                     autoFocus 
            //                     onChange={(e) => this.setState({text: e.target.value})}/>
            //                     <button className="editing-confirm" onClick={updateCommentConfirm}>
            //                         <div className="buttonText">✓</div>
            //                     </button>                                                        
            //                 </div>
            EditStyle= {visibility:"hidden"}; 
        }

        if(!allowEdit){
            // EditStyle= {visibility:"hidden"}; 
        }

        let usernameDiv = null;
        if(this.props.username){
            usernameDiv = <div className="comment-username"><pre>{translateUserName(this.props.username)+" added a comment on: "}</pre></div>;
        }
        //Construct the actual return from the render method
        return (
             <div className="comment" key={"comment-"+this.props.timestamp}>
             
                <div className='comment-block'>
                    <div className='comment-top'>
                        
                        <div className='comment-nametime'>
                            {usernameDiv}
                            <div className="comment-timestamp"> <pre>{moment(this.props.timestamp).format(CARD_COMMENT_DATE_FORMAT)}</pre> </div>
                        </div>
                        {this.props.deleteCallback &&
                            <div className="comment-delete"> 
                                <button type="button" className="deleteComment" onClick={()=>{this.props.deleteCallback(this.props)}}>X</button>
                            </div>
                        }
                    </div>
                    {this.state.showEdit ? <EditTextArea text={this.state.text} onChange={(e) => this.setState({text: e.target.value})} onClick={updateCommentConfirm} />:
                      <pre>{this.props.text}</pre>
                    }
                    {/* {textElement}     */}
                </div>
                
                {!this.props.disableEdits && 
                        <StatefulButton className="edit-comment-button" onClick={()=>{
                            this.setState({ showEdit: true,originalText:this.props.text, text:this.props.text });
                            }} 
                        style={EditStyle} ><img src={EditIcon}/> </StatefulButton>
                }
            </div>
        );
    }
}

/*
* @brief A helper Component for allowing the user to add comments
*/
export class Commenter extends PureComponent {
    render() {
        
        const adding = this.props.comment !== null;
        let returnDiv = <button     className="btn btn-primary add-comment" 
                                    onClick={() => { if(!this.props.disableEdits){this.props.setComment(" ")} }} 
                                    style={this.props.disableEdits?{opacity:"0.2"}:{opacity:"1"}} 
                                    disabled = {this.props.disableEdits}
            > {this.props.addLabel||'Add Comment'}</button>

        if(adding){
            returnDiv = 
            <div className="commenter">
                    <div className="adding-comment">
                    <textarea value={this.props.comment} autoFocus
                            onChange={(e) => {this.props.setComment(e.target.value)}}
                    >
                    </textarea>
                    <div className="adding-buttons">
                        <button className="btn btn-primary" onClick={this.props.submitComment}>{this.props.submitLabel||'Submit Comment'}</button>
                        <button className="btn btn-danger" onClick={this.props.cancelComment}>{this.props.cancelLabel||'Cancel Comment'}</button>
                    </div>
                </div>
            </div>
        }
        return returnDiv;
    }
}


