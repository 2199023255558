import React, { Component } from 'react';

import { AdminUserManagement } from './AdminTab/AdminUserManagement.js';
import { AdminSiteManagement } from './AdminTab/AdminSiteManagement.js';
import { AdminAssetManagement } from './AdminTab/AdminAssetManagement.js';
import { AdminDriverManagement } from './AdminTab/AdminDriverManagement.js';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import './AdminTab/AdminUserManagement.css';



/*
* @brief Create a simple view with the selection buttons, based on button selected change the called class.
*/
const AdminTabView = ({ groupconfig,possibleFilters,userInfo,username }) => {

    //define the callbacks to handle setting the active view
    const [activeView, setView] = React.useState('users');

    //List the buttons to be drawn
    let viewTypes = [];
    //  console.log("GroupConfig: ",groupconfig,userInfo,possibleFilters);
    //Enable permission sets, only show the button if the user may see it
    if(groupconfig.permissions && groupconfig.permissions.admin){
        viewTypes = [];
        if(groupconfig.permissions.admin.users){viewTypes.push('users');}
        if(groupconfig.permissions.admin.sites){viewTypes.push('sites');}
        if(groupconfig.permissions.admin.assets){viewTypes.push('assets');}
        if(groupconfig.permissions.admin.drivers){viewTypes.push('drivers');}

    }else{ //handle users with old permissions
        viewTypes = ['users','sites','assets','drivers'];
    }
    
    //Fix non available button:
    if(!viewTypes.includes(activeView)){
        setView(viewTypes[0]);
    }


    //Define the returned rendered HTML:
    return (
        <div className='AdminView'>

            <Tabs className='viewSelection'
                defaultActiveKey={activeView} unmountOnExit={true} mountOnEnter={true} 
                id='uncontrolled-tab-example' 
                    activeKey={activeView} 
                    onSelect={k => setView(k)}
            >
                {viewTypes.map((type) => {
                    return(  <Tab key={type} eventKey={type} title={type}/> )
                })} 
            </Tabs>
            
            {activeView==='users' && 
                <AdminUserManagement
                    groupconfig= {groupconfig}
                    possibleFilters = {possibleFilters}
                    userInfo = {userInfo}
                />
            }
            {activeView==='sites' && 
                <AdminSiteManagement
                    groupconfig= {groupconfig}
                    possibleFilters = {possibleFilters}
                    userInfo = {userInfo}
                />
            }
            {activeView==='assets' && 
                <AdminAssetManagement
                    groupconfig= {groupconfig}
                    possibleFilters = {possibleFilters}
                    userInfo = {userInfo}
                    username = {username}
                />
            }
            {activeView==='drivers' && 
                <AdminDriverManagement
                    groupconfig= {groupconfig}
                    possibleFilters = {possibleFilters}
                    userInfo = {userInfo}
                    username = {username}
                />
            }
            
            {/* {activeView==='assets' && 
                <div className="message1"> In Progress </div>
            } */}
    
        </div>
        );
    };
export { AdminTabView };



