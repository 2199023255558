
import React from 'react';
import { useState,useEffect } from "react";

import {Spinner } from '../ApiCaller.js';

/*  @Brief Wrap a component with a LoadingComplete state. A loading animation is automatically played in
*   in place of the component until the data is loaded
*   The component must populate the loadingComplete callback to disable the loading animation
*   Also the component is responsible for hiding/disabling the render of the component until the hideRender prop is false
*/
export const withLoadingAnimation = ComponentToWrap => ({ ...props}) => {
  //1 Logic
  //Define the hooks
        ///     variable,        setter()        useState(InitialValue);
    const [loadIsComplete, updateLoadComplete]  = useState(false); //Boolean if the loading is completed
    const [timeSinceLoad, updateTimeSinceLoad]  = useState(0); //Since since the loading started
    const [showSpinner, updateShowSpinner]      = useState(false); //Should the spinner be shown 

    //Enter if the loading state based on the the value of the time since load, and load complete
    useEffect(() => {
        let timeoutInterval = null; //track the interval to allow releasing
        if (!loadIsComplete) { //if the loading isn't completed
            if(timeSinceLoad > 500){ updateShowSpinner(true);   }
            //Set up a timeout to check the state again in 100ms
            timeoutInterval = setInterval(() => {
                updateTimeSinceLoad(timeSinceLoad => timeSinceLoad + 100);
            }, 100);
        //Load is complete - clear the interval if the timer has been set
        } else if (loadIsComplete && timeSinceLoad !== 0) { 
            clearInterval(timeoutInterval);
        }
        //Destructor on the setInterval
        return () => clearInterval(timeoutInterval);
    }, [loadIsComplete, timeSinceLoad]); //monitor changes in the loadIsComplete or timeSinceLoad states

    //Handle when the the loadisComplete state changes:
    useEffect(() => {
        if(loadIsComplete){ //If load is complete: disable spinner and reset timer
            updateShowSpinner(false);
            updateTimeSinceLoad(0);
        }
        //Spinner is re-enabled by the timer effect
    }, [loadIsComplete]); //follow the change of state of the loadIsComplete

    //handled what happens when the component completes loading
    function onLoadCompleteCallback(_event) {  updateLoadComplete(_event);  }

    //2 Render  - call our wrapped component
    // Inject the callback and hideRender into the props
    // The wrapped component will need to incorporate these to be compatible with the wrapper
    //  console.log("Loading props: ",loadIsComplete)
    return (
        <React.Fragment>
            <ComponentToWrap {...props} loadingComplete={onLoadCompleteCallback} hideRender={!loadIsComplete} />
            {showSpinner && <Spinner />}
         </React.Fragment>
    );
}//end withLoadingAnimation

