
import * as moment from 'moment';

import * as uuidv4 from 'uuid/v4';

import HeartIcon    from './assets/heart.png'
import AudioIcon    from './assets/bell_red.png'
import VibeIcon    from './assets/vibeIcon.png'
import iconSeatbelt from './assets/seatbelt_red.png';
import iconSmoking from './assets/smoking_red.png';
import iconCellphone from './assets/cellphone_red.png';
import iconDrowsy from './assets/drowsy_red.png';
import iconTrash from './assets/trash_red.png';





export function makeFilenameSafe(filename) {
  return filename.replace(new RegExp("[:\\^`\\/\\\\><{}\\[\\]#%\"'~|&@,$=+?;!() ]", "g"), "-");
}


export const PERIPHERAL_TIMEOUT = 1*60*60; //reported disconnected for more than 1 hours?
export const CHECKIN_TIMEOUT = 30*60; //hasn't checked in in 30 minutes, trigger alert color
export const OFFLINE_TIMEOUT = 2*24*60*60; //report truck offline - off for more than 2 days

export const STORYLINE_DATE_FORMAT = 'MMM DD, YYYY [at] HH:mm';

/*
* @brief Add commas in the correct place to a number (for thousands, millions, etc.)
*
* Also works on numbers represented as strings.
*
* Will do locale-specific handling if the locale doesn't use commas (eg. uses '.' instead)
*/
export function commaizeNumber(number) {
  return parseFloat(number).toLocaleString();
}

// kilo, mega, giga, tera, peta
const SUFFIXES = [
  ['K'],
  ['M'],
  ['G'],
  ['T'],
  ['P'],
];

// export const allowedGroups = ["testinggroup","reviewgroup","devgroup","drive_test_group"];
export const allowedGroups = ["testinggroup","reviewgroup","devgroup","drive_test_group",'betagroup'];
export const VideoReviewGroups = ["reviewgroup","betagroup"]; //Video review: submit vs publish groups
export const driveGroups = ["drive_test_group","bis_drive","faurecia_drive","pintovalley","reviewgroup_beirut"];

export const reviewGroups = ["devgroup","reviewgroup_beirut","betagroup"];

export const OTHERHWTAGS = [
  {type: "Blurry Cabin Camera", severity:"MEDIUM"},
  {type: "Dark Cabin Camera", severity:"MEDIUM"},
  {type: "Off Cabin Camera", severity:"MEDIUM"},
  {type: "On/Off Cabin Camera", severity:"MEDIUM"},
  {type: "Out of Position Cabin Camera", severity:"MEDIUM"},
  {type: "Blurry Driver Camera", severity:"MEDIUM"},
  {type: "Off Driver Camera", severity:"MEDIUM"},
  {type: "On/Off Driver Camera", severity:"MEDIUM"},
  {type: "Out of Position Driver Camera", severity:"MEDIUM"},
  {type: "Blurry Egress Camera", severity:"MEDIUM"},
  {type: "Out of Position Egress Camera", severity:"MEDIUM"},
  {type: "System Tampering", severity:"HIGH"},
  {type: "Loose IR", severity:"MEDIUM"},
  {type: "Rotated-Tilted IR", severity:"MEDIUM"},
  {type: "Camera Blocking", severity:"HIGH"},
  {type: "Cluttered Dashboard", severity:"LOW"},
]

/*
* @brief Convert a number into a shorter version using SI suffixes to give the correct magnitude
*
* Example: shortenNumber(2341020433) === 2.34G
*/
export function shortenNumber(number) {
  number = number.toString();
  if (number.length <= 4) {
    return number;
  }
  // figure out what the biggest suffix we can use is
  let digitsLeft = number.length;
  let currentSuffix = null;
  SUFFIXES.map(suff => {
    if (digitsLeft > 3) {
      digitsLeft -= 3;
      currentSuffix = suff;
    }
  });
  if (currentSuffix) {
    // and then determine the part before the decimal
    let current = number.slice(0, digitsLeft);
    // after the decimal
    if (digitsLeft < 3) {
      current += "." + number.slice(digitsLeft, digitsLeft + (3 - digitsLeft));
    }
    // and add on the actual suffix
    return current + currentSuffix;
  } else {
    // if there wasn't a possible suffix, just return the original
    // this shouldn't ever happen
    return number;
  }
}

export const ALL_DRIVERS = "no-driver-selected";

export const DRIVER_FILTER_FIXED_OPTIONS =
    [
        {text: "All", value: ALL_DRIVERS},
    ];

export const WORKFLOWS = [
  'FleetReview',
  'HrReview',
  'PlanIntervention',
  'AwaitTraining',
  'Completed',
  'GoodDrivingReward',
];

export const IN_FLIGHT_WORKFLOWS = WORKFLOWS.filter(flow => true);

export const displayWorkflow = (workflow) => {
  return WORKFLOW_NAME_MAP[workflow] || workflow || "Unknown Workflow Status";
};

const WORKFLOW_NAME_MAP = {
  'FleetReview': 'Fleet Manager Review',
  'HrReview': 'HR Review',
  'PlanIntervention': 'Intervention Planning',
  'AwaitTraining': 'Training Scheduled',
  'GoodDrivingReward': 'Positive Driver Behavior',
  'Completed': 'Review Completed',
}

/*
* @brief Default infraction types to load
*/
export const DefaultInfractionSet = [
    "LookAway",
    "HandsOffWheel",
    "ElbowsOnWheel",
    "LeanForward",
    "NoDriver",
    "HandToFace",
    "Occlusion",
];
/*
* @brief Default name translations for infraction types to display names
*/
export const DefaultDisplayNameSet = {
}

const INFRACTION_NAME_MAP = {
  Occlusion: 'CameraBlocked',
}

const INFRACTION_NAME_REV_MAP = {};

Object.keys(INFRACTION_NAME_MAP).map(name => {
  INFRACTION_NAME_REV_MAP[INFRACTION_NAME_MAP[name]] = name;
});

/*
* @brief Reverse a map, swap the keys with the values
*/
function swap(json){
  var ret = {};
  for(var key in json){
    ret[json[key]] = key;
  }
  return ret;
}

/*
* @brief Allow displaying a different name for an infraction type
*/
export function displayInfraction(_mapToApply,_inputToRemap) {
  let MapToApply = _mapToApply || {};
  return MapToApply[_inputToRemap] || _inputToRemap || "Unknown";  
}
/*
* @brief Translate a displayed name to the infraction type
*/
export function reverseDisplayInfraction(_mapToApply,_inputToRemap) {
  let ReverseNameMap = swap(_mapToApply);
  return ReverseNameMap[_inputToRemap] || _inputToRemap;
}


/*
* @brief Undo a display-only change for an infraction type
*
* This is needed because some components will give us back the same infraction name
* that we gave them, like the Infraction Proportions chart on the Dashboard.
*/
export function undisplayInfraction(inf) {
  return INFRACTION_NAME_REV_MAP[inf] || inf;
}

export const VIDEO_STATUS = {
  PENDING: 1,
  AVAILABLE: 2,
  REVIEW: 3,
  REJECTED: 4,
  SIMULATED: 5,
  REVIEWED: 6,
  UNUSABLE: 7,
  INPROGRESS: 8,
  PROCESSING: 9,
  NEWASSET: 10,
  WAITINGFORUPLOAD: 1,
  BUILDING: 12,
};

export const VIDEO_STATUS_NAMES = {};

// Populate the VIDEO_STATUS_NAMES map
Object.entries(VIDEO_STATUS).forEach(([name, value]) => {
  const displayName = name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
  VIDEO_STATUS_NAMES[value] = displayName;
});

/* @brief Convert an internal Status value to a displayable string
*/
export function displayStatus(status, defaultName) {
 
  return VIDEO_STATUS_NAMES[status] || defaultName || 'unknown-status';
}

export const EDGE3_PRIMARY_COLORS = ['#E60024', '#00AFED',  '#83c63f']; //blue, red, green #83c63f? //83c63f
// export const EDGE3_SECONDARY_COLORS = ['#B0B14D', '#61BD7D', '#00AFED', '#ED6B53', '#AFD47B','#c63fc6']; //mustard, bluegreen, blue, light red, light green
export const EDGE3_SECONDARY_COLORS = ['#eacb00', '#23e600', '#4022d6', '#81b591', '#AFD47B','#c63f82']; //mustard, bluegreen, blue, light red, light green
export const EDGE3_TERTIARY_COLORS = [ '#bd6961', '#53edb2', '#d47baf','#d2ab36', '#cd5c9a', '#29ea46', '#e04441', '#5540dd','#c25165','#2aedac']; //purple, redish, redorange, teal, light purple
// '#bd6961','#bd6961',

export const EDGE3_REGION_COLORS = ['#eacb0050', '#23e60040', '#4022d650', '#81b59180', '#AFD47Ba0','#c63f8250']; //mustard, bluegreen, blue, light red, light green

export const SECONDS_IN_HOUR = 60 * 60;

/* @brief Match a color to a infraction type by name
*/
export function getColorByType(_infractionType) {
  let returnVal = 'gray';
  switch(_infractionType){
      case "Cellphone":           returnVal = EDGE3_PRIMARY_COLORS[2];    break;
      case "Smartwatch":          returnVal = EDGE3_PRIMARY_COLORS[1];    break;
      case "Severe Drowsiness":   returnVal = EDGE3_PRIMARY_COLORS[0];    break;
      case "Seatbelt":            returnVal = EDGE3_SECONDARY_COLORS[0];    break;
      case "Microsleep":          returnVal = EDGE3_SECONDARY_COLORS[1];    break;
      case "Smoking/vaping":      returnVal = EDGE3_SECONDARY_COLORS[1];    break;
      case "Headphones":          returnVal = EDGE3_SECONDARY_COLORS[2];    break;
      case "Other":               returnVal = EDGE3_SECONDARY_COLORS[3];    break;
      case "Other - Unsafe":      returnVal = EDGE3_SECONDARY_COLORS[3];    break;
      case "Sudden Stop":         returnVal = EDGE3_SECONDARY_COLORS[4];    break;
      case "Sudden Swerve":       returnVal = EDGE3_SECONDARY_COLORS[5];    break;
      case "System Tampering":    returnVal = EDGE3_TERTIARY_COLORS[0];    break;
   
      case "Drowsiness":          returnVal = EDGE3_TERTIARY_COLORS[1];    break;
      case "Littering":           returnVal = EDGE3_TERTIARY_COLORS[2];    break;
      case "Posture":             returnVal = EDGE3_TERTIARY_COLORS[3];    break;
      case "Irrelevant":          returnVal = EDGE3_TERTIARY_COLORS[4];    break;

      case "Positive Behaviour":  returnVal = EDGE3_TERTIARY_COLORS[5];    break;
      case "Ingress":             returnVal = EDGE3_TERTIARY_COLORS[6];    break;
      case "Egress":              returnVal = EDGE3_TERTIARY_COLORS[7];    break;

      case "EgressInfraction":    returnVal = EDGE3_TERTIARY_COLORS[8];    break;
      case "Speeding":            returnVal = EDGE3_TERTIARY_COLORS[9];    break;

      default:
          returnVal='#00AFED'
      break;

  }
  // console.log("Set color: ",_type,returnVal);
  return returnVal;
}

/*
* @brief Given a Promise, return a promise that delays the result by the specified number of milliseconds
*
* This is for debugging/testing use and has no known production use
*/
export function delayPromise(promise, delay) {
  return new Promise((resolve, reject) => {
    promise.then(res => {
      setTimeout(resolve, delay, res);
    }).catch(err => {
      setTimeout(reject, delay, err);
    });
  });
}

export function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}


var failsSoFar = 0;
/*
* @brief Given a Promise, return a promise that will fail a specified number of times before succeeding
*
* This is for debugging/testing use and has no known production use
*/
export function failablePromise(promise, numFails) {
  return new Promise((resolve, reject) => {
    promise.then(res => {
      if (failsSoFar >= numFails) {
        resolve(res);
      } else {
        failsSoFar += 1;
        reject("Rejecting time number:", failsSoFar, "of", numFails);
      }
    }).catch(err => {
      reject(err);
    });
  });
}

/*
* @brief A Promise that always fails
*
* This is for debugging/testing use and has no known production use
*/
export function alwaysFail() {
  return new Promise((resolve, reject) => {
    reject("Rejecting as always");
  });
}

/*
* @brief Convert a filter selection (week/month/quarter) into a starting time for filtering
*/
export function filterToTime(filter) {
    const dayStart = moment().startOf('day');
    switch (filter) {
        case 'week':
            return dayStart.subtract(7, 'days').add(1, 'day');
        case 'month':
            return dayStart.subtract(1, 'month').add(1, 'day');
        case 'quarter':
            return dayStart.subtract(3, 'months').add(1, 'day');
        case 'default':
            console.log("Unknown filter value!:", filter);
            // fall through
        case 'all':
            return moment(0); // unix epoch
        default:
          if(filter.startDate){
             return moment(filter.startDate,'YYYY-MM-DD');
          }
          // if(filter.endDate){
          //   return moment(0); // unix epoch
          // }
          return null;
    }
}

/*
* @brief Normalize a value into a [lo, hi] range so that it is between 0 and 1
*/
export function normalizeToRange(lo, hi, value) {
  return Math.min(1, Math.max(0, (value - lo) / (hi - lo)));
}

/*
* @brief output all data recorded in the user timings API to the console
*
* These timings should mostly be coming from the helper functions in Perf.js
*/
export function doPerformanceOutput() {
  const entries = window.performance.getEntriesByType('measure');
  const byName = {}
  entries.map(entry => {
    byName[entry.name] = byName[entry.name] || [];
    byName[entry.name].push(entry.duration);
  });
  // Object.keys(byName).map(key => {
  //    console.log(key + "perf entries:");
  //    console.log(byName[key].join("\n"));
  // });
  // console.log(byName);
  window.performance.clearMarks();
  window.performance.clearMeasures();
}

/*
* @brief Pluralize a standard English word (just add 's' for plural counts)
*/
export function pluralizeSimple(word, count) {
  if (count === 1) {
    return word;
  }
  return word + "s";
}

/*
* @brief Capitalize the first letter of a title
*/
export function toTitleCase(s) {
  const lower = s.toLowerCase();
  return lower.slice(0, 1).toUpperCase() + lower.slice(1);
}

/*
* @brief Generate a universally unique ID
*/
export function generateUniqueID() {
  return uuidv4();
}

/*
* @brief An enumeration of the possible severities
*/
export const SEVERITY = {
    IRRELEVANT: 'IRRELEVANT',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    GOOD: 'GOOD',
};

/*
* @brief An ordered list of the severities, when order matters
*/
export const SEVERITIES = [
  SEVERITY.HIGH,
  SEVERITY.MEDIUM,
  SEVERITY.LOW,
  SEVERITY.GOOD,
  SEVERITY.IRRELEVANT,
];

/*
* @brief Get the number of a severity, for use when a string is insufficient
*
* This is used particularly when sorting by severities
*/
export const severityNumber = (card) => SEVERITIES.indexOf(card.severity || card);

/*
* @brief A helper function to compare severities, eg when sorting by them
*/
export const severityCompare = (a, b) => {
    return severityNumber(a) - severityNumber(b);
};

/*
* @brief Enable the method to load either a pre-signed URL to the manifest file, 
* or construct a local URL from Manifest file data
*/
export function getStreamURL(_inputData) {
  // console.log("Stream URL: ",_inputData);
  if(!_inputData){return null;}
  if(_inputData.substring(0,5)==='https'){
      //console.log("Recieved URL: ",_inputData);
      return _inputData.replace(/&amp;/g,"&");
  }else{
    // console.log("Recieved BLOB: ",_inputData);
    let returnOBJ = URL.createObjectURL(new Blob([_inputData], {type: 'text/plain'}) );
    return  returnOBJ;
  }
}

 /*
    * @brief Change the user name for our *_user names
    */
const aiAuditUsers = ["user","testing_","kqasim","-rg","jobata"];
const aiAuditUsersExclude = ["whitehaven_user"];
export function translateUserName(_string) {
  try {
    const lower = _string.toLowerCase();
    let bFoundUser = false;
    //Check against the whitelist of AiAudit users
    aiAuditUsers.forEach(elem=>{
      if(lower.includes(elem)){
          bFoundUser = true;
        }  
    })
    //Check against the blacklist of exceptions to the rule
    if(aiAuditUsersExclude.includes(lower)){
      bFoundUser = false;
    }

    if(bFoundUser){
      return "AiAudit";
    }
    return lower;
  } catch (error) {
    console.log("Failed to translate: ",error, _string)
  }
    
}

 /*
    * @brief Change the user name for our *_user names
    */
  export function filenameAlphaStripper(_string) {
    var pattern = /(PM|R|P)(\d+)(?=[-_\]\[])/i;
    // var tmpString = _string.replace(pattern,"");
    // var dateString = tmpString.replace(/([a-zA-Z._])/g,"")
    // return tmpString.replace(/([a-zA-Z._])/g,"");
  
    var vehicleIDArray = _string.match( pattern );
    var vehicleID = '';
    // Return the first pattern match
    if(vehicleIDArray) {   vehicleID= vehicleIDArray[0];  }
  
    var tmpString = _string.replace(pattern,"");
    var dateString = tmpString.replace(/([a-zA-Z._])/g,"")
    var vehicleString = vehicleID.replace(/([a-zA-Z._])/g,"")

    // LiveReview_2022-02-21_15:57:50_ON
    if(_string.includes("LiveReview")){
      return _string.replace("LiveReview_","");
    }
    
    return vehicleString+"-"+dateString;
    // return _string;
}

export function getNoteCardFromInfractionID(_cardsByType,_infractionID) {
  if(!_cardsByType){return null;}
  let returnValue = null
  Object.entries(_cardsByType).forEach(([type, value]) => {
    //check if the infractionID matches the ejection request:
    if(value.infractionID===_infractionID){
      returnValue= value;
    }//end matchin ID check            
  });//end card id check  
  return returnValue;
}

export function getAlertIcon(_metadata,_type) {
  let iconSrc = null;
  if(!_metadata){return null;}
  try {
    // console.log("type:" ,_metadata.audioAlert)
    if(_type==='audio' && _metadata.audioAlert){
      switch(_metadata.audioAlert){
        case 'drowsy':          iconSrc = iconDrowsy;       break;
        case 'electronics':     iconSrc = iconCellphone;    break;
        case 'smoking':         iconSrc = iconSmoking;      break;
        case 'seatbelt':        iconSrc = iconSeatbelt;     break;
        case 'music':           iconSrc = null;             break;        
        case 'littering':       iconSrc = iconTrash;        break;        
      }//end switch  
      //all set to audio alert
      iconSrc = AudioIcon;  
    }

    if(_type==='vibe' &&_metadata.vibrationAlert){
      switch(_metadata.vibrationAlert){
        case 'drowsy':          iconSrc = iconDrowsy;       break;
        case 'electronics':     iconSrc = iconCellphone;    break;
        case 'smoking':         iconSrc = iconSmoking;      break;
        case 'seatbelt':        iconSrc = iconSeatbelt;     break;
        case 'music':           iconSrc = null;             break;        
        case 'littering':       iconSrc = iconTrash;        break;        
      }//end switch  
      //all set to audio alert
      iconSrc = VibeIcon;  
    }
  } catch (error) {
    console.log("Caught error: ",error);
  }
  return iconSrc;
}

export function getHBIcon(_metadata) {
  let iconSrc = null;
  try {
    // console.log("type:" ,this.props.metadata.audioAlert)
    if(_metadata.heartrate){
      //all set to audio alert
      iconSrc = HeartIcon;
    }//end switch  
  } catch (error) {

  }
  return iconSrc;
}

export function formatSecondsTime(_seconds){
  var dur = moment.duration({'seconds':_seconds});
  var hours = Math.floor(dur.asHours());
  var mins  = Math.floor(dur.asMinutes()) - hours * 60;
  var sec   = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;
  // console.log("Seconds: ",_seconds,hours);
  var hoursTXT = ("0" + hours).slice(-2);
  if(hours > 99){hoursTXT =("0" + hours).slice(-3);}
  var minsTXT = ("0" + mins).slice(-2);
  var secTXT = ("0" + sec).slice(-2);

  var result = hoursTXT + ":" + minsTXT + ":" + secTXT;
  return result;
}




//======helper methods for the key press navigation:=====
// === LiveTab - get the location in the grid an asset by Name
export const getPosition = (_set, _assetid) => {
  if(!_assetid){return;} if(!_set){return;}
  let position = {};
  (_set||[]).forEach( (col_, colIdx_) =>{
    (col_||[]).forEach( (row_, rowIdx_) =>{
      if(row_.title === _assetid){
        // console.log("Found match: ", _assetid);
        position.row =rowIdx_; position.col = colIdx_
      }  
    });//end searching in rows
  });//end searching in columns
  // console.log("Position: ",position);
  if(position.row||position.col) return position;
  return null;
}
// === LiveTab - test a grid position, get the video count and check valid position
export const getDetailsAtPos = (_set, _pos, _videoSet) => {
  if(!_pos){return;} if(!_set){return;}
  try {
    // console.log("Test at: ",_pos);
    //boundary protect:
    if(_pos.row >= _set.length){ return null;} //can't scroll down past the last element
    if(_pos.row < 0 ){ return null;} //can't scroll up past the first element
    //get the button data:
    _pos.button = _set[_pos.row];
    
    // let returnedCount =  getUnviewedCount(_videoSet,_pos.button.title);
    let hasClips = _videoSet[_pos.button.title];
    // console.log("Has clips: ",_pos.button.title, hasClips);  
    if(!hasClips){ //empty button
      return null;
    }
    // console.log("Test not empty: ",_pos);
    return _pos;

  } catch (error) {
    console.log("failed to get details from position: ",_pos,error);
    return null;
  }
 
}
// === LiveTab - scroll through all rows of the column, get the closest one to the current location
export const closestInCol = (_set, _pos, _videoSet, _maxScans) => {
  try {
    if(!_pos){return;} if(!_set){return;}
    //  console.log("Enter closest at : ",_pos);
    let position = {row: _pos.row, col: _pos.col};
    let colSet = _set[_pos.col];  
    if(!colSet){return;}
    //Scan in up/down pattern until a non zero is found:
    let maxScans =  _maxScans || colSet.length*2;
    let bNewPositionFound = false;
    let scanCount=1;
    while(!bNewPositionFound && scanCount < maxScans){
      
      //try above:
      position.row = _pos.row+ (-1*scanCount);
      // console.log("Top test: ",position, scanCount )
      let aboveButton = getDetailsAtPos(colSet, position,_videoSet);
      if(!aboveButton){
        
        position.row = _pos.row+ (1*scanCount);
        // console.log("Button test: ",position,scanCount)
        // position.row += 1*scanCount;
        let belowButton = getDetailsAtPos(colSet, position,_videoSet);
        if(belowButton){
          //  console.log("Found below:" ,position);
          return position;
        }
      }else{
        //  console.log("Found above:" ,position);
        bNewPositionFound = true;
        return position;
      }
      scanCount++; //limit the loop 
    }
    // console.log("End of loop ");
    return null;

  } catch (error) {
    console.log("Closest col error: ",error,_pos);
    return null;
  }
}//end closest in column check
// === LiveTab - return the number of clips loaded for the asset
export const getUnviewedCount = (_videos,_assetid,_loadedClips) => {
  let returnCount = {
    iUnviewed: 0,
    iViewed: 0,
    iTotal: 0,
  }
  try {
    if(!_videos){
      // console.log("Early exit(1): ",_videos); 
      return returnCount;
    }
    //either the full object with all assets is passed, or a single asset data:
    if(!_videos.set && !_videos[_assetid] ){ 
      // console.log("Early exit: ",_videos);
      return returnCount;
    }
    
    let videoSet = null;
    if(_videos.set){ //link the single asset data
      videoSet = _videos.set;
    }else{
      videoSet =_videos[_assetid].set; //link the asset data from the full set
    }
    // console.log("To Count: ",videoSet[0].loaded,_assetid, videoSet.length)
    returnCount.iTotal = videoSet.length;
    videoSet.forEach(clip_=>{
      // console.log("CLip:" ,clip_)
      if(clip_.loaded){
        if(!clip_.viewed){returnCount.iUnviewed++;}
        else{    returnCount.iViewed++;  }
      }
      // if(clip_.loading){
      //   returnCount.iTotal++;
      // }
    })
    // console.log("REturn count: ",returnCount);
    return returnCount;
  } catch (error) {
    console.log("Failed to get view count: ",error)
    return returnCount;
  }
}

export const getNodePosition = (_node)=>{
  try {
    let rect=_node.getBoundingClientRect();
    let left=_node.offsetLeft;
    let top=_node.offsetRight;
    return {x:rect.left,y:rect.top,left:left, top: top, error:false};  
  } catch (error) {
    return {x:0,y:0,error:true}
  }
}

export const TAB_STALE_TIME = 10; // in minutes
export const TAB_EXPIRE_TIME = 60; // in minutes
/* 
* Clean up the stored tab records in the Local and Session Storage
* If the _delete flag is passed this will remove the item from the storage
* the index.js runs the delete on expire when the page is first loaded
*/
export const cleanOldTabRecordsFromStorage = (_storage,_staleTime, _expireTime,_delete)=>{
  let returnObj = {
    staleTabsPresent: false,
    staleTabs: [],
    expiredTabs: [],
  }
  for( const [key_,value_] of Object.entries(_storage)){
    if (key_.startsWith('tab-record')) {
        try {
            const value = parseInt(value_,10);
            //Check for stale tabs?
            if (((Date.now() - value)   > _staleTime *60 * 1000) && (value <= Date.now())) {
              returnObj.staleTabsPresent = true;
              returnObj.staleTabs.push(key_); //add to a return list
            }
          // Clean up old tab records:
            if (((Date.now() - value)   > _expireTime *60 * 1000) && (value <= Date.now())) {
              // console.log("Delete old tab: ",key_);
                if(_delete){_storage.removeItem(key_);} //delete from storage
                returnObj.expiredTabs.push(key_); //add to a return list
            }            
            
        } finally {}
    }
  }
  return returnObj;
}

/*
  * @brief Save a URL to the local computer
  * @param _URL to download: the location of the file to download
  * @param _name: name to apply to the local file
  * @param _replaceText: replace occurences of null in the name with the provided string
*/
export const saveBlob = (_url,_name,_replaceText)=>{
  let filename = _name;
  //Replace any occurence of "null" with the replacement text, if specified
  if(_replaceText){  filename = filename.replace("null",_replaceText); }
  
  //Check if the url supplied is a blob or a RUL
  if(_url.search('blob:') == -1){ //URL detected
    fetch(_url //Download from the URL
      )
      .then(response => {
          if (!response.ok) {
          throw Error(`HTTP error: ${response.status}`);
          }
          return response.blob(); //return the downloaded blob data
      })
      .then(res => {
              if(res){ 
                  let tmpBlob = res;
                  const url2 = window.URL.createObjectURL(tmpBlob);  //create a local url for the blob resource
                  var a = document.createElement("a"); //create an anchor element to download from
                  document.body.appendChild(a); //add the element to the DOM
                  a.style = "display: none"; //set sytle to none, so it doesn't show on the DOM
                  a.href = url2; //set the anchor to link to the URL to download to comnputer
                  a.download = filename; //set the desired name of the file
                  a.click(); //virtually click on the element
                  window.URL.revokeObjectURL(url2); //release the temporary URL to the blob resource
                  document.body.removeChild(a); //release the download anchor element
              }//end res returned     
      })
      .catch((error) => {
          console.log('Fetch error? ', error);
      });
  }else{ //Blob file input
    console.log("Download from blob");
    var a = document.createElement("a"); //create an anchor element to download from
    document.body.appendChild(a); //add the element to the DOM
    a.style = "display: none"; //set sytle to none, so it doesn't show on the DOM
    a.href = _url; //set the anchor to link to the URL to download to comnputer
    a.download = filename; //set the desired name of the file
    a.click(); //virtually click on the element
    document.body.removeChild(a); //release the download anchor element
  }

  return filename;
};

