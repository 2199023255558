import React, { PureComponent } from 'react';

import './StoryLine.css';

import { StemPlot } from './StemPlot';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import { Auth, API } from 'aws-amplify';
import moment from 'moment';
import {displayInfraction,STORYLINE_DATE_FORMAT} from '../Util.js';
import { Spinner } from '../ApiCaller';


import { StoryLineCall } from './StoryLineCall';
import { CardListFunc } from '../VideoCard';
import { StoryReport } from './StoryReport';

import { cardChange, cardTag, unexpandCard } from '../NoteCard-utils';

/*
* @brief 
*/
class StoryLine extends PureComponent {
    
  constructor(props) {
      super(props);

      this.getNotecard = this.getNotecard.bind(this);
      this.getCallCard = this.getCallCard.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.onBack = this.onBack.bind(this);
      this.onReport = this.onReport.bind(this);

      this.fetchCalls = this.fetchCalls.bind(this);
      this.fetchNotecards = this.fetchNotecards.bind(this);

      this.state = {
          shown: true,
          expandedCard: null,
          callCard: null,
          reportCard: null,
          cardsByInfractionid:{},
          callsByInfractionid:{},
          loadingPlot :-1,
          cardsLoaded: false,
          callsLoaded: false,
          startTimeText:this.props.data.journeyStart,
          endTimeText:this.props.data.journeyEnd,
          storylineData: this.props.data,         
          storyUpdate: new Date(), 
      };
  }

  componentDidMount(){
    // console.log("StoryLine mount: ",this.props);
    //on mount, we need to fetch the notecards for the story so they can be opened without delay:
    //1. get list of all infractionids that need to be loaded
    //2. pass the list to the API
    //3. store return in local state variable
    
    try {
      //1. Get the unique infractionids:
      let infractionidSet = new Set();
      let callIds = new Set();
      Object.entries(this.state.storylineData).forEach( ([name, value])=> {
        //Scan the input for arrays
        if(Array.isArray(this.state.storylineData[name])){
          // console.log("Entries: ",name, value);
          //Look fo rthe infractionids in the events:
          (this.state.storylineData[name]||[]).forEach( (entry_)=> {
            try {        
              // console.log("IDS:", entry_);      
              infractionidSet.add(entry_.infractionid);
              if(name==='Calls'){
                callIds.add(entry_.infractionid);
              }
            } catch (error) {}
          });//end loop over entries
        }
      });


      //Format the start and end times:
      let startTimeText = this.state.storylineData.journeyStart;
      let endTimeText = this.state.storylineData.journeyEnd;
      try {
        startTimeText = moment.parseZone(this.state.storylineData.journeyStart).format(STORYLINE_DATE_FORMAT);
        endTimeText = moment.parseZone(this.state.storylineData.journeyEnd).format(STORYLINE_DATE_FORMAT);
      } catch (error) {
        
      }
      //Format an object to set the state with the new updates
      let stateObj = {
        startTimeText: startTimeText,
        endTimeText: endTimeText,
      }

      this.fetchNotecards([...infractionidSet]);
      let callsToFetch = [...callIds];
      if(callsToFetch.length>0){
        this.fetchCalls(callsToFetch);
      }else{
        stateObj.callsLoaded = true;
        // this.setState({callsLoaded:true})
      }

      this.setState(stateObj);
      

    } catch (error) {
    }
  }

  

  fetchNotecards(_infractionIDs){
    //2. Pass the list to the API
      // console.log("Query with ids: ",[..._infractionIDs])
      const cardPromise = Auth.currentSession().then((auth) => 
      {
          let apiName = "AuthLambda";
          let path = "/getCardsForReview";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  infractionids: _infractionIDs
              }
          };
      return API.post(apiName, path, myInit);
       });
      cardPromise.catch( (error)=>{console.log("Failed to get cards: ",error);  this.setState({cardsByInfractionid: {}, cardsLoaded:true})});
      cardPromise.then( (_data)=>{
        // console.log("Cards returned:  ",_data)
        const cardsByInfID = this.state.cardsByInfractionid;

        if(_data.cards){
            // console.log("Process Card Data: ",_data.cards,_data.cards.length);
          let cards = _data.cards.filter( card_=>{
            return card_.infractionTags.includes("Drowsiness");
          });

          (cards||[]).forEach(card => {
              //The timereceived should already have the timezone applied, don't let it apply another time zone:
              card.timeReceived = moment(card.timeReceived,'YYYY-MM-DDTHH:mm:ss.SSSS'); //this loads without timezone parsing
              card.timeOfDay = moment.parseZone(card.timeOfDay);
  
              if(card.infractionTags){ card.infractionTags = card.infractionTags.split(",");}
              else{card.infractionTags = [];}
  
              card.infractionType =  displayInfraction(this.props.groupconfig.displayNameSet,card.infractionType);
              // set default name if one isn't known (DriverID first, if that is set)
              card.name = (card.name || card.driverID || "DriverID: Unavailable").replace('_', ' ');

              //Remove the ID of the video, need to link to the 24hr file:
              card.id = null;

              //Assign to the set:
              cardsByInfID[card.infractionID] = Object.assign(cardsByInfID[card.infractionID] || {}, card);
              if(!cardsByInfID[card.infractionID].name){cardsByInfID[card.infractionID].name = "DriverID: Unavailable";}

              

          }); //end loop through cards to sanitize data
          // console.log("Processed: ",new Date - this.state.timerDebug);
        }//end did we get notecard data check

        this.setState(filterStoryLine(this.state.storylineData,this.state.cardsByInfractionid,['Drowsiness','Severe Drowsiness']));  

        // console.log("No cards loaded, sooo set the loaded?")
        //3. Store the return in the local state variable.
        this.setState({cardsByInfractionid: cardsByInfID, cardsLoaded:true},
        // console.log('Cards loaded: ', this.state.cardsByInfractionid)
        );
      });//end handling returned notecards
  }
  fetchCalls(_callIDs){
    // console.log("fetch calls: ",_callIDs);
    let callPromise = Auth.currentSession().then(
      (auth) => {
        this.setState({currentUsername: auth.idToken.payload['cognito:username']});
        let apiName = "TrifectaAPI";
        let path = "/handleCallLog";
        let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                mode:'fetch-single',  
                infractionids: _callIDs

            }
        };
        return API.post(apiName, path, myInit);
      });       
    callPromise.catch( (error)=>{console.log("Failed to get calls: ",error);  this.setState({callsByInfractionid: {}, callsLoaded:true})});
    callPromise.then( (_data)=>{
      // console.log("calls returned:  ",_data)
      let callGroups = {};
      if(_data.calls){
        //Iterate over each call from the SQL table
        _data.calls.forEach(row => {
            
          //apply the site timezone to the callintime
          if(row.calltime){
            try {
              
              let timezoneRow = (this.props.possibleFilters.Sites || []).filter(row_=>{  return row_.site.toLowerCase() === row.site.toLowerCase() })
              timezoneRow = timezoneRow[0];
              //  console.log("Timezone: ",row.calltime,timezoneRow.timezone,moment(row.calltime),moment(row.calltime).tz(timezoneRow.timezone).format('YYYY-MM-DD HH:mm:ss'))
              //Convert the timestamp to the local time of the site:
              row.calltime = moment(row.calltime).tz(timezoneRow.timezone).format('YYYY-MM-DD HH:mm:ss');
            } catch (error) {
              
            }
          }
          
          row.key = row.id+"_"+row.infractionid+"-key";


          //Create the groupingid:
          let groupingid = row.subject + row.calltime;
          if(row.infractionid){
            groupingid = row.infractionid; //this will catch when calling for a card and no answer is chosen
          }
          //insert into an array if it doesn't already exist
          if(!callGroups[groupingid]){callGroups[groupingid]={
              subject: row.subject,
              asset: row.asset,
              username: row.username,
              cardData: row.cardData,
              site: row.site,
              timeofday: row.timeofday,
              calltime: row.calltime,
              callnotes: row.callnotes,
              key: row.key+"-group",
              set:[],
              contactname:row.contactname,
              infractionid:row.infractionid,
            }
            callGroups[groupingid].set.push(row);
          }//end insert into set
          else{ //add to the list
            callGroups[groupingid].set.push(row);
          }
            return row;
        }); //end processedData map

         this.setState(filterStoryLine(this.state.storylineData,callGroups,['Calls']));  
        // this.filterStoryLine(this.state.storylineData,callGroups,['Calls']);

        // console.log("Display Data: ",displayData);
        // this.setState({callsByInfractionid: callGroups, callsLoaded:false})
      }//end check if calls is avialable
      // console.log("Set calls loaded too");
      this.setState({callsByInfractionid: callGroups, callsLoaded:true})
      
    });//end handling returned call logs       
  }

  handleClick(_data){
    console.log("Click returned:", _data);
    let clickedMarker = JSON.parse(_data.event.data.name);
    let infractionid = null;
    try {
      infractionid = clickedMarker.infractionid;
    } catch (error) {
    }

    if(!infractionid){return;} //don't attempt to open an unknown notecard

    switch(clickedMarker.tag){
      case 'Calls':  return this.getCallCard(clickedMarker);      
      case 'NoCall':  return this.getCallCard(clickedMarker);      
      default:  return this.getNotecard(clickedMarker);
    }
  }

  getCallCard(_data){
    // 1f954dbd-c25c-42b1-98f1-04af445f1ac1,8d0f6520-5925-4de8-997d-fba15030db85
      // console.log("Click on call: ",_data,_data.infractionid,this.state.callsByInfractionid);

    let infractionid = _data.infractionid;
    if(!infractionid){return;} //don't attempt to open an unknown call



    //Run over the set:
    let preloadedCard = null;
    try {
      _data.set.forEach( call_ =>{
        let callGroup = this.state.callsByInfractionid[call_.infractionid];
        if(!callGroup){return;}
        // console.log("CallGroup from inf:" ,callGroup,call_.infractionid)
        if(!preloadedCard){
          // console.log("Set preload first?")
          preloadedCard = callGroup;
        }else{
          //  console.log("Combine: ",preloadedCard.infractionid, preloadedCard.set,callGroup.infractionid, callGroup.set)
           preloadedCard.set= ([...preloadedCard.set,...callGroup.set])
        }
      })
      
    } catch (error) {
      // console.log("Catch error? ",error);
      try {
        preloadedCard = this.state.callsByInfractionid[infractionid];
      } catch (error) {
        
      }  
    }

    
    if(!preloadedCard){return;} //don't attempt to open an unknown notecard

    // console.log("Loaded call: ",this.state.callsByInfractionid,preloadedCard)

    this.setState({callCard:preloadedCard});

  }

  getNotecard(_data){
    //  console.log("Notecard get called", _data);
    //Extract the infractionid
    let infractionid = _data.infractionid; //clicked on the bubble
    if(!infractionid){ //check if clicked on the notecard
      infractionid = _data.infractionID;
    }
    if(!infractionid){return;} //don't attempt to open an unknown notecard


    //Check if the card was already loaded
    let preloadedCard = null;
    try {
      preloadedCard = this.state.cardsByInfractionid[infractionid];
    } catch (error) {
      
    }

    if (preloadedCard) {
        this.setState({expandedCard: preloadedCard}); //pass the object to be viewed
      return; //early return, card was found
    }

    this.setState({loadingPlot:1});

    //Failed to find the notecards in the local set, fetch from the API:
    const realPromise = Auth.currentSession().then(
      (auth) => {
          let myInit = {
          body: {
              token: auth.idToken.jwtToken,
              apiName: "getCardFromInfractionID",
              mode: "fetch",
              infractionid: infractionid,
          }
          };
          return API.post("AuthLambda", "/apiRouter", myInit);
      });
    //   //When the data is returned arrange it into a basic card and open the card:
    realPromise.then(data => {
      // console.log("Notecard fetch returned: ",data,data.data);
      (data.data || []).forEach( (elem_) =>{
        // console.log("Notecard fetch returned: ",elem_);
        let infractionTagArray = elem_.infractiontags || [];
        if(elem_.infractiontags ){
            infractionTagArray = elem_.infractiontags.split(",");
        }
        // console.log("Tags: ",infractionTagArray);
        const card = {
            tag: elem_.tag,
            infractionType: elem_.infractiontype,
            infractionID: infractionid,
            status: 'FleetReview',
            severity: elem_.severity,
            cardID: elem_.videocardid,
            id: elem_.parentid,
            flags: elem_.flags,
            // vehicleID: feature.get("name"),
            siteID: elem_.siteid,
            notes: "",    
            name: elem_.driverid,
            infractionTags : infractionTagArray,
            timeReceived: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
            timeOfDay: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
          };

          this.setState(prevState => {
            const cardsByInfID = prevState.cardsByInfractionid;
            //Add to the set:
            cardsByInfID[card.infractionID] = Object.assign(cardsByInfID[card.infractionID] || {}, card);
            if(!cardsByInfID[card.infractionID].name){cardsByInfID[card.infractionID].name = "DriverID: Unavailable";}     
            return {cardsByInfractionid:cardsByInfID,expandedCard:card,loadingPlot:-1};
          });
        // this.setState({expandedCard:card});
      });
    });//end promise then
    realPromise.catch(error => {
      console.log("Failed to fetch notecard: ",error);
      this.setState({loadingPlot:-1});
    });

  }//end getNotecard

  onBack(){
    this.props.onClose();
  }
  onReport(){
    this.setState({reportCard:{
      date: this.state.storylineData.date,
      driverid: this.state.storylineData.driverid,
      starttime: this.state.storylineData.journeyStart,
      endtime: this.state.storylineData.journeyEnd,
      notecards: this.state.cardsByInfractionid,
      calls: this.state.callsByInfractionid,   
      photo:this.props.photo ,
    }})
  }
  /*
  * @brief Draw the photo and driverid
  */
  render() {
    // console.log("Props to render: ",this.props);
    if(!this.state.storylineData){return <div className="storyline-content" > No data loaded?</div>}

    let cardsToList = Object.values(this.state.cardsByInfractionid);

    let disableEdits = true;
    if(this.props.groupconfig && this.props.groupconfig.permissions && this.props.groupconfig.permissions.storylines && this.props.groupconfig.permissions.storylines.notecard){
      if(this.props.groupconfig.permissions.storylines.notecard==='write'){ disableEdits = false;}
    }
    // console.log("Disable edits? ", disableEdits);

    return(      
      <div className="storyline-content" >       
      
        <div className='titleSection'>
          <div> <span style={{fontWeight:"bold"}}> Journey on:</span> {this.state.storylineData.date}</div>
          <div> <span style={{fontWeight:"bold"}}> Driver:</span> {this.state.storylineData.driverid}</div>
          <div> <span style={{fontWeight:"bold"}}> Start Time:</span> {this.state.startTimeText} </div>
          <div> <span style={{fontWeight:"bold"}}> End Time:</span> {this.state.endTimeText} </div>
        </div>
        <div className='body'>
          <div className='left'>
            {this.state.loadingPlot === 1 ? <Spinner /> :
              <StemPlot 
                title='Title' dataTitle='data'
                class = 'stemplot'
                data={this.state.storylineData}
                onClick = {this.handleClick}
                interactive={true}
                delayRender = { {calls:this.state.callsLoaded,cards:this.state.cardsLoaded} }
                groupconfig = {this.props.groupconfig}
                dataUpdated = {this.state.storyUpdate}
                chartStyle={{
                  height: '100%',
                }}
              />
              
            }
            <div className = 'bottom'>
                <div className='summary'>
                  {/* <div className='title'>Summary</div> */}
                  <div> <span style={{fontWeight:"bold"}}> Severe Drowsiness events:</span> {this.state.storylineData['Severe Drowsiness']? this.state.storylineData['Severe Drowsiness'].length:'---'}</div>
                  <div> <span style={{fontWeight:"bold"}}> Drowsiness events:</span> {this.state.storylineData.Drowsiness?this.state.storylineData.Drowsiness.length:'---'}</div>              
                  <div> <span style={{fontWeight:"bold"}}> Calls made:</span> {this.state.storylineData.Calls? this.state.storylineData.Calls.length: '---'}</div>
                  <div> <span style={{fontStyle:"italic"}}>click on the bubbles to display the notecards</span></div>
                </div>
                <div className='report'>
                {/* {(this.state.callsLoaded && this.state.cardsLoaded )?
                  <button className='report-btn btn btn-success' onClick={this.onReport}>Report</button>
                : */}
                 <button className='report-btn btn btn-success' disabled={(!this.state.callsLoaded || !this.state.cardsLoaded )} onClick={this.onReport}>Report</button>
                {/* } */}
                </div>
                    
                
            </div>
          </div>
          <div className='right'>
            {(cardsToList.length<1 && !this.state.cardsLoaded)?<Spinner />:
                <CardListFunc cards={cardsToList}
                        onCardClick={this.getNotecard}
                        sortByCreated={false}
                        sortByReverse={true}
                        disableDrag={true}
                        groupconfig = {this.props.groupconfig}
              />
              }
          </div>
        </div>
        
        
        <div className="footer">
          <button className="btn btn-danger" onClick={this.onBack}>Back</button>
          
        </div>
        { this.state.expandedCard &&
            <ExpandedCard 
                            // handleClose={()=>{
                            //         this.setState({expandedCard:null})
                            //         }}
                            handleClose = {(_inCard)=>{
                                              unexpandCard(_inCard,this,'cardsByInfractionid');
                                              if(_inCard && _inCard.delete){
                                                setTimeout(() => {
                                                  this.setState(filterStoryLine(this.state.storylineData,this.state.cardsByInfractionid,['Drowsiness','Severe Drowsiness']));  
                                                }, 500);                                                
                                              }
                                              
                                            }}
                            {...this.state.expandedCard}
                            eventNotify={this.props.eventNotify||(()=>{console.log("no event callback set")})}
                            cardChange={ (_inCard) =>{cardChange(_inCard,this.props.cardChange,this,'cardsByInfractionid') }}
                            tagChange={ (_inCard) =>{cardTag(_inCard,this,'cardsByInfractionid') }}

                            scale={1}   
                            // noLink={this.props.groupconfig.group.toLowerCase().includes("bis")?false:true}                     
                            noLink={false}                     
                            noEdit={disableEdits}
                            filter={Object.assign(this.props.filter||{},{onlypublished:true})}
                            groupconfig = {this.props.groupconfig}
                            siteDetails = {(this.props.possibleFilters.Sites||[]).filter(filt_ => {return this.state.expandedCard && filt_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}                                    
            />
        }
        { this.state.callCard &&
            <StoryLineCall handleClose={()=>{
                                  this.setState({callCard:null})
                                  }}
                          {...this.state.callCard}
                          scale={1}   
                          groupconfig = {this.props.groupconfig}
                          possibleFilters = {this.props.possibleFilters}
                          cardsByInfractionid = {this.state.cardsByInfractionid}
            />
        }
        { this.state.reportCard &&
            <StoryReport handleClose={()=>{
                                  this.setState({reportCard:null})
                                  }}
                          {...this.state.reportCard}
                          scale={1}   
                          groupconfig = {this.props.groupconfig}
                          possibleFilters = {this.props.possibleFilters}
            />
        }
      </div>
    )
  }
}

// Remove entries from the storyline that aren't present in the download data: notecards or calls
export const filterStoryLine = (_storyLine, _filteredData, _setNames) => {
  //Sanitize the StoryLine data and remove any entry that does not have a notecard:
  const storylineData = JSON.parse(JSON.stringify(_storyLine));
  let dataArray = Object.entries(storylineData)||[];
  // 1. value only arrays by type
  dataArray.forEach(([name, value]) => {
    // console.log("Test: ",name,value)
    if(!Array.isArray(value)){return;}
    if(!_setNames.includes(name)){return;}
    // console.log("Data: ",name);
    let toDelete = [];
    value.forEach((entry_, idx)=> {
      // console.log("Data forEach: ",entry_);
      if(_filteredData[entry_.infractionid]){
        // console.log("Notecard found for entry: ",entry_.infractionid); 
        return;
      }
      //Notecard not found for the entry, needs to be removed from the data set:
      toDelete.push(entry_.infractionid); //add to array to delete from the set
    }); //end array loop
    //Filter out the values that need to be deleted.
    value = value.filter( (filter_)=>{return !toDelete.includes(filter_.infractionid)}) //remove from the set if the infractionid is in the toDelete array
    storylineData[name] = value;
    // console.log("After filter: ",_setNames,toDelete,value);
  });

  // console.log("Updated dataset?: ",_setNames,storylineData);
  //return an object to pass to setState()
  return {
    storylineData:storylineData,
    // storyUpdate: new Date(),
  }
}


export { StoryLine };