
import React from 'react';
import { Auth, API } from 'aws-amplify';
import { WebsocketInterface } from '../WebsocketInterface.js';
import { VideoCutsReviewNotecard } from './VideoCutsReviewNotecard.js';

/**
 * Child class of the Notifications class, serves as an element of the sub-menu
 */
export const VideoCutNotifications = ({onChange,userInfo,groupconfig,...props}) =>{

    const displayName = 'VideoCutNotifications';

    const [showVideoCutsReview,toggleShowVideoCutsReview] = React.useState(false); //toggle flag to show the list of videocuts
    const [renderedList,setRenderedCuts] = React.useState(); //a copy of the list used to trigger the render
    const video_cuts = React.useRef(null); //the actual list of videocuts that is tracked 

    /**
     * Set up the component on mount, registerd the websocket listener and initiate the 
     * fetch of the video cuts from the API
     */
    React.useEffect(()=>{
        // console.log(`[${displayName}] loaded props: `,props);

        //Register to listen for the new messages:
        WebsocketInterface.register({name:'VideoCutNotifications',onMessage:onMessage, type: 'videocut'});
        //Register a listener with the DOM, fire the callback when a mouse click is observed
        document.addEventListener("click", handleClickOutside ); 

        //Start a fetch of all the data registered to the current user
        fetchVideoCuts();
       
        //Handle the exit // Anything in here is fired on component unmount.
        return () => {
            //Releasethe Websocket 
            WebsocketInterface.release({name:'VideoCutNotifications'});
            //Release the mouse click listener
            document.removeEventListener("click",handleClickOutside);
            video_cuts.current = null;
        }
    },[]) //[] -> execute once 

    /**
     * Check if the click occured outside the notification menu, if so, close the menu
     * @param {*} event : the mouse click event
     */
    const handleClickOutside = (event) => {
        if(!props.notificationsRef || !props.notificationsRef.current){return;}
        //Check if the click was on the bell icon, if not then send the message to close
        //This is needed else the bell icon click would always be discarded and it couldn't be opened
        if (props.notificationsRef.current && !props.notificationsRef.current.contains(event.target)) {
             if(props.onClose){{props.onClose({name: displayName, data: false})}}
        }
    };//end handleClickOutside

    /**
     * Get the list of video cuts from the API
     * @param {*} _options All an object to merge with the request paramets (can change the query from all to unviewed/viewed)
     */
    function fetchVideoCuts(_options){
        // console.log("Get the video cuts from the API");
        Auth.currentSession().then(
            (auth) => {
                //Configure the API call
                let serviceName= "VideoCutAPI";//Set the servicename - this is the collection of lambdas
                let apiName= "/fetchVideoCuts"; //Set the specific lambda function to invoke
                //Configure the data to send to the Lambda
                let myInit = {};
                let body = {
                    token: auth.idToken.jwtToken
                    ,mode: 'all' //Specify a switch mode to indicate that all videocuts should be returned
                    ,username: userInfo.username
                };
                
                myInit.body = Object.assign(body,_options); //append the options to the query parameters
                
                //Call the API
                const apiResponse = API.post(serviceName,apiName, myInit);
                    apiResponse.then(_response=>{ //Response recieved from the API
                        // console.log(`[${displayName}] API returned: `,_response);
                        //Loop through the returned video cuts, get a count of the number of unreviewed
                        let receivedCuts = _response.data||[];
                        getUnviewedCount(receivedCuts);

                        formatDataSet(receivedCuts);
                        //Extract the videocuts and save them to the state variable - this will pass to the display child
                        // console.log("Push to state:" ,receivedCuts.length, receivedCuts)
                        video_cuts.current = receivedCuts;
                        setRenderedCuts(video_cuts.current); //Save the changes to the list to trigger a re-render
                    });
                    //Log error messages: to the console
                    apiResponse.catch(_error=>{console.log("Error fetchCuts return: ",_error);});
        
        }).catch( (error)=>{console.log("Failed to authenticate session",error)}) //authorization failed:

    }

    /**
     * Iterate over the received videocuts and make sure the data is formatted correctly to be shown in the table
     * @param {*} _videoCuts array fo videocuts 
     */
    function formatDataSet(_videoCuts){
        (_videoCuts||[]).forEach((cut_)=>{
            cut_.reactkey = `${cut_.infractionid}-${cut_.time_requested}-${cut_.status}`; //need to have a unique key for each entry to prevent warnings and re-renders known data
            if(cut_.thumbnail){
                //Is it tagged as a photo, this needs to be added to let the HTML know how to load the image
                if(!cut_.thumbnail.includes('data:image')){
                    //if no tag, assume this is a base64 encoded jpeg
                    cut_.thumbnail = 'data:image/jpeg;base64,'+cut_.thumbnail;
                }
            }
        });//end for loop
    }

    /**
     * Check the nubmer of video cuts that need to be viewed more
     * @param {*} _videocuts: array of video cut objects
     */
    function getUnviewedCount(_videocuts){
        //Iterate over the array:
        let unviewedcount = (_videocuts||[]).reduce(function(accumulator_, cut_) {
                                try {                
                                    accumulator_ += cut_.status==1?1:0
                                } catch (error) {console.log("Failed reduce:" ,error)}
                                return accumulator_; //return the accumulated value to be used in the next cycle
                            }, 0); //initialize the accumulated value to 0
        if(onChange){onChange({name: displayName,  unviewedcount: unviewedcount})}
    }

    /**
     * Websocket message returned (new clip to add to the list)
     * @param {*} _data: message from the websocket
     */
    const onMessage = React.useCallback((_data) => {
        // function onMessage(_data){
            if(!_data.message){return;}
            // console.log("Notification Message: ",_data);
            //Restrict the message to only show when compelte video cuts are received
            try { 
                if(_data.message.messagetype !== 'COMPLETE'){ 
                    console.log("Message type fail",_data.message.messagetype !== 'COMPLETE',_data.message.messagetype);
                    return;
                } 
            } catch (error) {console.log("Error on test: ",error)}
            //Received the infractionid, and the videoURL
            //Loop through the returned video cuts, get a count of the number of unreviewed
            let receivedCuts = video_cuts.current||[];
            receivedCuts.push(_data.message);
            //Update the view stats, review data format, and trigger state update:
            getUnviewedCount(receivedCuts); //check the status and update the notification icon if needed
            formatDataSet(receivedCuts); //review format to ready for table display
            setRenderedCuts(video_cuts.current);
      }, []);
    /**
     * Update received from the VideoCutsReviewNotecard 
     * (change in review status)
     * @param {*} _data 
     */
    function handleChange(_data){
        //Change the status of the video cut:
        try {
            switch(_data.type){
                //Handle the change to the viewed status:
                case 'status':{
                    //Update the status of the video cut on the local copy and the API:
                    let cuts = [...video_cuts.current];
                    for(const cut_ of cuts){
                        if(cut_.pk_id === _data.pk_id){
                            cut_.status = 2;
                        }
                    }
                    // console.log("HandleChange? ",cuts.length);
                    video_cuts.current = cuts||[];
                    updateCutStatus(_data.pk_id);//send the update to the API
                    getUnviewedCount(cuts||[]);        
                    setRenderedCuts(video_cuts.current);
                }break;
                // Request to delete the videocut 
                // Remove the object from the list of items to render (the API has already removed the videocut)
                case 'delete':{
                    video_cuts.current = (video_cuts.current||[]).filter(elem_=>elem_.pk_id!==_data.pk_id);
                    setRenderedCuts(video_cuts.current);
                }break;
            }
        } catch (error) {
            console.log("Failed to update onChange: ",handleChange)
        }
    }//end handleChange()
    /**
     * Send a message to the API to update the view status of the video cut
     * @param {*} _pk_id: primary key of the video cut in the SQL table
     */
    const updateCutStatus = async (_pk_id)=>{   
        const authReturn = await Auth.currentSession();
        //Configure the API call
        let serviceName= "VideoCutAPI";//Set the servicename - this is the collection of lambdas
        let apiName= "/fetchVideoCuts"; //Set the specific lambda function to invoke
        //Configure the data to send to the Lambda
        let myInit = {};
        myInit.body = {
            token: authReturn.idToken.jwtToken
            ,mode: 'updatestatus' //Specify a switch mode to indicate that all videocuts should be returned
            ,pk_id: _pk_id
            ,status: 2
        };
        
        //Call the API
        const apiResponse = API.post(serviceName,apiName, myInit);
        apiResponse.then(_response=>{
            // console.log("Status update response: ",_response);
        });
    }
    //Clicked on the sub-menu so open the list notecard
    function onClick(e){
        // console.log("Click on VideoCuts");
        // setIsComponentVisible(!isComponentVisible);
        toggleShowVideoCutsReview(true);
        e.preventDefault();
    }

    //Handle the request to close the notecard
    function onClose(_bool){
        toggleShowVideoCutsReview(_bool);
        if(props.onClose){props.onClose({name: displayName, data: _bool})}
    }

    //Help method to render the content of the component:
    function render(){
        return(
            <li className='VideoCutNofications' >
                
                {!showVideoCutsReview ?
                    <a href="#videocuts" onClick={onClick} >
                        <i className="ti-layout-sidebar-left"></i> VideoCuts
                    </a>
                    :
                    <VideoCutsReviewNotecard 
                        onClose={()=>{onClose(false);}}
                        onChange={handleChange}
                        videocuts={renderedList}
                        groupconfig={groupconfig}
                        userInfo={userInfo}
                    />
                }   
            </li>
        )
    }//end render()
    return render();
}//end of VideoCutNotificationsClass

