/** Define a class that will manage sending the objects/packets to the API. The objects to send are
 *  cached in internal queues that are sent in batches after the configured wait. This helps prevent the API
 *  and SQL server from being overloaded by individual connection requests and teardowns
 */
class DebounceQueueClass{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.pendingQueue={}; //Set of queues, each new queue is added to this set { queue_key1:{}, queue_key2:{}....}    
        this.progressQueue={}; //Set of queues, that hold the current tracked object
        this.apiFunctions={}; //Set of api function to call for each queue, { queue_key1:_api(), queue_key2:_api()....}        
        this.queueDepths={};  //Set configured depths for each queue, { queue_key1:5, queue_key2:10....}        
        // this.processedRequest=[]; //track what has been sent to prevent duplicates in the API request
        this.processedRequest={}; //Set of queues, that hold the current tracked object
    }
    /**
     * Add a new queue to the managed queues. The queue will be referenced by the key that is provided
     * @param {*} queue_key: name of the queue (required for all interactions)  
     * @param {*} depth: number of values to buffer until syncing with the API 
     */    
    addQueue({queue_key, depth,...props}){
        // console.log("create new queue: ",queue_key, depth);
        try {
            if(!props.type || props.type === 'object'){
                this.pendingQueue[queue_key] = Object.assign(this.pendingQueue[queue_key]||{},{}); //take no action, or assign an empty array
                this.progressQueue[queue_key] = Object.assign(this.progressQueue[queue_key]||{},{}); //take no action, or assign an empty array
            }else{
                this.pendingQueue[queue_key] = this.pendingQueue[queue_key]||[]; //take no action, or assign an empty array
                this.progressQueue[queue_key] = this.progressQueue[queue_key]||[]; //take no action, or assign an empty array
            }
            this.processedRequest[queue_key] = this.processedRequest[queue_key]||[]; //take no action, or assign an empty array
            this.queueDepths[queue_key] = depth || 5; //set the depth of the buffering, default to 5 if not specified
            return {status:"success"};
        } catch (error) {
            console.log("Failed to create: ",error);
            return {status:"fail"};
        }
    }
    /** Specify the API function that should handle the queue data, specifies how the data should be formatted and where it is sent
     * @param {*} queue_key: name of the queue (required for all interactions)  
     * @param {*} _apiFn: Function to execute when the queue data is to be processed
    */    
    linkAPI(queue_key, _apiFn){
        try {
            //Store the API function name referenced by the queue key
            this.apiFunctions[queue_key] = _apiFn;
            return {status:"success"};
        } catch (error) {
            console.log("Failed to create: ",error);
            return {status:"fail"};
        }
    }
    /**
     * Update the stored object values in the specified queue
     * @param {*} queue_key: name of the queue (required for all interactions)  
     * @param {*} _object: object data to add or update
     * @param {*} _callback: (optional) function to call after the queue'd object's values have been updated
     */
    updateObject(queue_key,_object,_options){
        // console.log("Update:" ,queue_key,_object);
        try {
            if(!this.pendingQueue[queue_key]){return {status:"fail"};}
            if(!this.progressQueue[queue_key]){return {status:"fail"};}

            //If the callback was defined, then notify the caller that the object was created/updated
            //Allow the caller to do additional configuration of the object if desired
            if(_options && _options.callback){ 

                //If there is no interaction currently tracked, then create one
                if(!this.progressQueue[queue_key][_object.key]){
                    this.progressQueue[queue_key][_object.key] = {..._object,status:0};//initiliaze the new interaction
                }
                //Get our current interaction to update:
                const currentInteraction = this.progressQueue[queue_key][_object.key]; 

                let callReturn =   _options.callback(currentInteraction,_object,_options.action); 
                if(_options.action === 'skip'){ //this interaction is finished
                    //Copy to the pending queue to push to API
                    if(!this.pendingQueue[queue_key][_object.key]){this.pendingQueue[queue_key][_object.key] = [];}                    
                    this.pendingQueue[queue_key][_object.key].push({...callReturn});

                    // console.log("Added to the pending: ",this.pendingQueue[queue_key][_object.key].length);

                    //And remove from the progressQueue to stop building
                    this.progressQueue[queue_key][_object.key]=null; //clear the progress queue to start the next interaction
                    
                }

                //If the status has been set, assume the object is valid for sending to the API
                try {
                    if(this.pendingQueue[queue_key][_object.key] && this.pendingQueue[queue_key][_object.key].length>0){
                        this.processQueue(queue_key);                     
                    }    
                } catch (e) {console.log("Process call fail? ",e)}
                // console.log("Updated:",_options.action,this.progressQueue[queue_key][_object.key],this.pendingQueue[queue_key][_object.key]);
            }else{ //immediately add to the pending queue to send
                

                if(!this.pendingQueue[queue_key]){this.pendingQueue[queue_key] = [];}                    
                if(this.pendingQueue[queue_key].includes(_object)){ 
                    return {status:"success"};
                }
                //Add to the pending list
                
                this.pendingQueue[queue_key].push(_object);
                // console.log("Updating in List: ",_object,this.pendingQueue[queue_key],this.pendingQueue[queue_key].length);
                try {
                    if(this.pendingQueue[queue_key] && this.pendingQueue[queue_key].length>0){
                        this.processQueueList(queue_key);                     
                    }    
                } catch (e) {console.log("Process call fail? ",e)}
            }

            
            
            return {status:"success"};
        } catch (error) {
            console.log("Failed to create: ",error,this.progressQueue[queue_key]);
            return {status:"fail"};
        }
    }
    /**
     * Send updated values to the API
     * @param {*} queue_key: name of the queue (required for all interactions)  
     */
    processQueue(queue_key,_options={}){
        // return ;
        try {
            if(!this.apiFunctions[queue_key]){return {status:"NoAPI"};}
            if(!this.pendingQueue[queue_key]){return {status:"NoData"};}
            if(!this.processedRequest[queue_key]){return {status:"NoTrackedData"};}
            //get the size of the current queue:
            let queueDepth = 0;//Object.keys(this.pendingQueue[queue_key]).length;
            let clipInteractions = Object.keys(this.pendingQueue[queue_key]);
            for(const interactions_ of clipInteractions){
                // console.log("In pending: ",this.pendingQueue[queue_key][interactions_]);
                queueDepth += this.pendingQueue[queue_key][interactions_].length;
            }
            //Get the depth threshold to be used to determine if an API call should be made
            //Prioritize the override value from _options, otherwise default to configured queue depth
            let depthThreshold = _options.depth || this.queueDepths[queue_key]; 
            
            // console.log("Pending items:",queueDepth,depthThreshold);
            if(queueDepth >= depthThreshold){

                //Accumulate all data that has a status set (ready to send)
                let objectsToSend = []; //flattened array to send to the API                
                for(const interactions_ of clipInteractions){
                    // console.log("Add to send queue: ",Object.values(this.pendingQueue[queue_key][interactions_]));
                    objectsToSend.push(...Object.values(this.pendingQueue[queue_key][interactions_]));
                }
                //Filter against previous sends
                objectsToSend = objectsToSend.filter(item =>{
                    let testVal = item.key+item.playstart
                    return !this.processedRequest[queue_key].includes(testVal)});
                //Track what we sent to the API
                this.processedRequest[queue_key].push( ...(objectsToSend||[]).map(object_=>{
                    return object_.key+object_.playstart
                }))
                // console.log("Send Queue: ",objectsToSend);
                // console.log("Send data: ",objectsToSend, this.pendingQueue[queue_key]);
                if(objectsToSend.length ===0 )return {status:"success"};
                //Send the data to the API function:
                this.apiFunctions[queue_key](objectsToSend,
                    (_response)=>{ //callback from the API function
                    // console.log("API returned complete on :" ,_response);
                    //Process the return list of successful keys:
                    for(const key_ of _response.keys){
                        //Remove the entry that matches the synced key:
                        try {
                            this.pendingQueue[queue_key][key_.key].splice(
                                this.pendingQueue[queue_key][key_.key].findIndex(item =>key_.key === item.key && key_.playstart === item.item)
                                ,1
                            );
                        } catch (error) {                            
                            console.log("Failed to match? ",error,key_);
                        }
                    }   
                    //  console.log("Processed API return: ",this.pendingQueue[queue_key])                                     
                });//end API response processing
            }
            return {status:"success"};
        } catch (error) {
            console.log("Failed process: ",error);
            return {status:"fail"};
        }
    }
    processQueueList(queue_key,_options={}){
        // return ;
        try {
            if(!this.apiFunctions[queue_key]){return {status:"NoAPI"};}
            if(!this.pendingQueue[queue_key]){return {status:"NoData"};}
            //get the size of the current queue:
            let queueDepth = 0;
            queueDepth = this.pendingQueue[queue_key].length;
            //Get the depth threshold to be used to determine if an API call should be made
            //Prioritize the override value from _options, otherwise default to configured queue depth
            let depthThreshold = _options.depth || this.queueDepths[queue_key]; 
            
            // console.log("Pending items:",queueDepth,depthThreshold);
            if(queueDepth >= depthThreshold){

                //Accumulate all data that has a status set (ready to send)
                let objectsToSend = [...this.pendingQueue[queue_key]]; //flattened array to send to the API                
                // console.log("TO Send: ",[...objectsToSend]);
                // Filter against previous sends
                objectsToSend = objectsToSend.filter(item =>{
                    let testVal = item;
                    return !this.processedRequest[queue_key].includes(testVal)
                });
                //Track what we sent to the API
                this.processedRequest[queue_key].push( ...objectsToSend||[]);
                // console.log("Send Queue (LIST): ",objectsToSend, this.processedRequest[queue_key]);
                // console.log("Send data: ",objectsToSend, this.pendingQueue[queue_key]);
                if(objectsToSend.length ===0 )return {status:"success"};
                //Send the data to the API function:
                this.apiFunctions[queue_key](objectsToSend,
                    (_response)=>{ //callback from the API function
                    // console.log("List API returned complete on :" ,_response);
                    //Process the return list of successful keys:
                    this.pendingQueue[queue_key] = [];
                });//end API response processing
            }
            return {status:"success"};
        } catch (error) {
            console.log("Failed process: ",error);
            return {status:"fail"};
        }
    }
    /**
     * Send all remaining data before shutdown
     * @param {*} queue_key: name of the queue (required for all interactions)  
     */
    emptyQueue(queue_key){
        if(Array.isArray(this.pendingQueue[queue_key])){
            this.processQueueList(queue_key,{depth:1});//set depth to one to trigger immediate sync
        }else{
            this.processQueue(queue_key,{depth:1});//set depth to one to trigger immediate sync
        }
    }
}

export const DebounceQueue = new DebounceQueueClass();
