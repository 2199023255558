import React, { Component } from 'react';

import { Auth } from 'aws-amplify';

import AWS from 'aws-sdk';

import { makeFilenameSafe } from './Util.js';

                            // 14 minutes in milliseconds, plus a second to try to avoid a common period
const TOKEN_REFRESH_TIMER = (14 * 60 + 1) * 1000;

AWS.config.update({
  region: 'us-west-2',
  credentials: new AWS.CognitoIdentityCredentials({
    // there are two of these because the documentation/examples differed in capitalization and I wasn't sure which
    identityPoolId: 'us-west-2:9473ca32-373c-41f8-9f5a-cda4a7b88271',
    IdentityPoolId: 'us-west-2:9473ca32-373c-41f8-9f5a-cda4a7b88271'
  })
});

/*
* @brief This is the component that actually does the upload and shows progress as it does
*
* It does the upload based on the passed in props and starts immediately as it's "mounted" in react terms
*/
class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.buildResult = this.buildResult.bind(this);
    this.reportResult = this.reportResult.bind(this);
    this.state = {
      progress: 0
    };
  }

  /*
  * @brief Helper function used during reporting the result up to controlling components
  */
  buildResult(data, filename) {
    return {"data": data, "filename": filename.split("\\").pop()};
  }

  /*
  * @brief Helper function, report the result up to controlling components
  */
  reportResult(err, data) {
    //console.log("down:", err, data);
    if (err) {
      this.props.result("Upload failed!", this.buildResult(data, this.props.file.value));
    } else {
      this.props.result(null, this.buildResult(data, this.props.file.value));
    }
  }

  /*
  * @brief Actually start the upload, this function is called when the component comes into existence
  */
  componentDidMount() {
    /*
    * This callback is called by the uploading process itself, it updates our progress bar to show the state
    */
    const progressUpdate = (event) => {
      var partDone = event.loaded / event.total;
      this.setState({progress: partDone});
    };
    /*
    * Get the credentials from the aws-amplify internals so we cas use them for the upload
    */

    Auth.currentCredentials().then(credentials => {
      Auth.currentUserPoolUser().then(user => {
        user.getSession((err, sess) => {
          user.refreshSession(sess.refreshToken, (err, tok) => {
            const key = Object.keys(credentials.params.Logins)[0];
            credentials.params.Logins[key] = tok.idToken.jwtToken;
            AWS.config.credentials = credentials;
            AWS.config.credentials.get(function(err) {
              if (err) {
                console.log("Get err:");
                console.log(err);
              }
            });
            var s3 = new AWS.S3({
              params: {Bucket: 'e3d-is2y4ihl25'},
              region: 'us-west-2',
              httpOptions: {
                timeout: 300000
              }
            });
            /* set up the key we should use on the server, note that the "group" part will be checked by
            *  the server itself so it cannot be set arbitrarily. The user only has permissions to write
            *  into a certain directory
            */
            var destFilename = (new Date()).toJSON() + "_edge3endtime_" + this.props.file.value.split('\\').pop();
            destFilename="WS-"+destFilename; //mark the new files to make it different from files upload in the upload tool.
            var keyToUse = (this.props.groupconfig.group || "unknown_group") + "/videos/" + makeFilenameSafe(destFilename);
            var upParams = {
              Key: keyToUse,
              Body: this.props.file.files[0]
            };
            /*
            * Start the upload, this is a multipart upload that splits up the file into chunks automatically
            * and handles sending them itself.
            */
            let refresher = null; // this stores the key we use to stop the token refresher interval once the upload
                                  // is done
            // this function will stop the refresher once it's no longer needed
            const cleanupRefresher = () => {
             // console.log("Cleaning up:", refresher);
              if (refresher) { // the usual case, when the upload takes a while we'll already have set 'refresher'
                window.clearInterval(refresher);
                refresher = null;
              } else { // otherwise, if the upload finished very quickly, check in a few seconds and stop the refresher
                window.setTimeout(() => {window.clearInterval(refresher); refresher = null;}, 10 * 1000);
              }
            }

            this.uploader = s3.upload(upParams, function(err, data) {
              cleanupRefresher();
              if (err) {
                console.log("up error");
                console.log(err);
              }
              console.log(data);
            });
            this.uploader.on('httpUploadProgress', progressUpdate) // this one is for upload progress, happens throughout upload
                         .send((err, data) =>
                                { // this happens at the end of the upload (error or success)
                                  cleanupRefresher();
                                  this.reportResult(err, data);
                                });
            // refresh the token every once in a while so the upload doesn't fail after an hour
            refresher = window.setInterval(() => {
              console.log("Uploader before:", this.uploader);

              Auth.currentCredentials().then(credentials => {
                Auth.currentUserPoolUser().then(user => {
                  user.getSession((err, sess) => {
                    user.refreshSession(sess.refreshToken, (err, tok) => {
                      const key = Object.keys(credentials.params.Logins)[0];
                      credentials.params.Logins[key] = tok.idToken.jwtToken;
                      this.uploader.service.config.credentials = credentials;

                      this.uploader.service.config.credentials.refresh((err) => {
                        console.log("Uploader after:", this.uploader);
                      });
                    }); // end of refresh callback
                  }); // end of session callback
                }); // end of user pool callback
              }); // end of credentials callback

            }, TOKEN_REFRESH_TIMER); // end of setInterval for refresher
          }); // end of refresh callback
        }) // end of session callback
      }) // end of user pool callback
    }); // end of credentials callback
  } // end of componentDidMount

  render() {
    const progress = this.state.progress;
    return (
      <div className="file-uploader">
        <p>Uploading {this.props.file.value.split('\\').pop()} ...</p>
        <progress value={progress} max="1"></progress>
      </div>
    );
  }
}


/*
* @brief This form is the one shown on the page that allows inputting a filename and clicking Upload
*
* This form should never actually be submitted, in the HTML/HTTP sense, we just use the value from its
* file input widget in our uploading internally.
*/
class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.onFileRemoved = this.onFileRemoved.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.state = {
      file: null,
    };
  }
  componentDidMount() {
    const filerSettings = {
      limit: 1,
      changeInput: true,
      showThumbs: true,
      addMore: false,
      onRemove: this.onFileRemoved,
      captions: {
        button: "Choose File",
        feedback: "Choose video file to upload",
        feedback2: "file was chosen",
      },
    };
    this.actualForm && window.$(this.actualForm).find('label input').filer(filerSettings);
  }
  onFileRemoved() {
    this.setState({file: null});
    this.props.fileUpdated(null);
  }
  onFileChanged(event) {
    this.setState({file: event.target});
    this.props.fileUpdated(event.target);
  }
  render() {
    return (
      <div className="upload-form-wrapper">
        <h5>Upload Portal</h5>
        <form className="uploadForm" method="post" encType="multipart/form-data" ref={(e) => {this.actualForm = e;}}>
          <label className="uploadBrowse"> 
            <input type="file" ref="file" name="file" onChange={this.onFileChanged} required />
          </label>
          {this.state.file &&
            <div className="uploadButton">
              <input type="button" name="upload" value="Upload Video" onClick={this.props.upload} className="btn btn-primary" />
            </div>
          }
        </form>
      </div>
    );
  }
}

export { FileUploader,UploadForm };