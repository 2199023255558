import moment from 'moment';
/**
 * Format the asset name for display
 * @param {*} _assetSet: a set of asset details including an alias
 * @param {*} _name: the name of the asset to format
 */
export const formatAssetName = (_assetSet,_name,_fallback)=>{
  try {
    //   console.log("Format the name: ",_name,_assetSet);
      let foundAsset =  (_assetSet||[]).filter((entry_)=>{ return entry_.asset===_name;});
    //   console.log("Found asset: ",foundAsset);
      if(foundAsset){
          let returnString = foundAsset[0].alias||foundAsset[0].asset;
          return returnString;
      }
      if(_fallback){return _fallback;}      
      return 'unknown';
  } catch (error) {
    //   console.log("Failed to format name ",error);
      if(_fallback){return _fallback;}      
      return "----"
  }
  
}


/**
 * Filter the list of all assets assigned to the client using a site or date, or combination of the two
 * @param {*} _assetSet : the list of all assets loaded for the client
 * @param {*} _date : the date range to filter by (object with start and end)
 * @param {*} _site : the name of the site to filter by
 */
export const filterAssetSet = (_assetSet,_date,_site,_groupconfig )=>{
//   console.log("Filter on set: ",_assetSet);
  //Filter the set of avialable assets based on the selected site and date range
  if(_site){ _site = _site.toLowerCase();}  

  let filteredSet = getFilteredSet(_assetSet,_date,_site);
  //Apply the regex to the asset's name if available:
  let regexAppliedSet = [];
  //  console.log("Apply names to: ",filteredSet);
  if(_groupconfig && _groupconfig.asset_regex ){
      // console.log("Apply against ", _groupconfig.asset_regex);
      regexAppliedSet =  (filteredSet||[]).map((entry_)=>{
          //Iterate over the history:
          for(const assignment_ of (entry_.history||[])){
              // console.log("eval assignment:" ,assignment_);                
              try {
                  if(!assignment_.siteid){continue;} //this should be present for all assignments, if not then it is an entry error from the table
                  let assignedSite = assignment_.siteid.toLowerCase(); //get the site name of the assignement                
                  let regex = _groupconfig.asset_regex[assignedSite];
                  // console.log("Regex to eval: ",entry_.asset,assignedSite,regex)    
                  if(!regex){
                      // console.log("No Regex? ",assignedSite)
                      continue;
                  } //exit the assignment if no regex is defined for the site
                  //Check if the site filter was applied, if yes, then only apply the alias update
                  //if the assignment matches
                  if(_site && _site !== assignedSite){ 
                      // console.log("No site match: ",_site, assignedSite);
                      continue;
                  }
                  //Apply the regex to the asset name to create the new alias:
                  entry_.alias = entry_.asset.replace(new RegExp(regex[0]),regex[1]);
                  // console.log("Updated alias: ",entry_.asset, entry_.alias,new RegExp(regex[0]),regex[1]);

              } catch (error) {
                  console.log("Name alias fail: ",error,assignment_,entry_);
              }
          }
          return entry_;
      });
  }else{ //no regex rules are defined for this group, list the asset's name as the alias
      regexAppliedSet =  (filteredSet||[]).map((entry_)=>{
          entry_.alias = entry_.asset; //add an alias for display
          return entry_;
      })
  }
//   console.log("Filtered Assets:",regexAppliedSet);
  return [...regexAppliedSet];
  // return filteredSet;
  
  
  // if(this.props.groupconfig && this.props.groupconfig.asset_regex ){
      //     console.log("Apply regex:" ,this.props.groupconfig.asset_regex,element.site,this.props.groupconfig.asset_regex[element.site])
      // }
}
/**
* Filter the list of all assets assigned to the client using a site or date, or combination of the two
* @param {*} _assetSet : the list of all assets loaded for the client
* @param {*} _date : the date range to filter by (object with start and end)
* @param {*} _site : the name of the site to filter by
*/
export const getFilteredSet = (_assetSet,_date,_site)=>{
//   console.log("Filter assets: ",_assetSet,_date,_site);

  try {
      if(!_assetSet){return null;}
      let allAssets = JSON.parse(JSON.stringify(_assetSet)); //make a copy so we don't modify the original
      let dateIsSet = _date && (_date.startDate || _date.endDate); //helper to check that a value for either start or end has been set
      
    
        //Reset changes to site in filter
        (allAssets||[]).forEach((entry_)=>{  if(entry_.current_site){  entry_.site = entry_.current_site;   }         });
        if(_site){ _site = _site.toLowerCase();}      
      
      //Handle the cases:
      if(_site && !dateIsSet){ //is the site defined
          // console.log("Filter by site alone")
          //Filter against the current assignement:
          //Only return ones where the asset's current site matches the selected site
          let filteredAssets = (allAssets||[]).filter((entry_)=>{
              let foundMatch = false;
              for(const assignment_ of (entry_.history||[])){
                  try {
                      if(!assignment_.siteid){continue;} //all assignments should have a siteid associated
                      //If the site filter has been selected, then the assignment must match the site filter
                      if( _site!==assignment_.siteid.toLowerCase()){
                          // console.log("Assigment no match: ",_site, assignment_.siteid.toLowerCase())
                          continue;
                      }
                      foundMatch= true;
                  }catch(error){
                      console.log("Failed to check site, without date")
                  }
              }
              
              return foundMatch;
              // return entry_.site.toLowerCase() === _site;
          });
          (filteredAssets||[]).forEach(asset_ => {asset_.site = _site;}); //update the current site 
        //   let updatedAssets = (filteredAssets||[]).map(asset_ => {asset_.site = _site; return asset_});

          return filteredAssets;
      }else if(dateIsSet){ //The date filter is defined
        //   console.log("Date is set?: ",dateIsSet,_date);
          let filteredSet =  (allAssets||[]).filter((entry_)=>{
              //Iterate over the history:
              let foundMatch = false;
              for(const assignment_ of (entry_.history||[])){
                  //Determine if the asset was active during the time window supplied by the selection
                  // - can start before or after the start date
                  // - is start date is set, the assignment must not start before the selected end date
                  // - if end date is set, the assignment must not end  before the selected start date
  
                  try {
                      if(!assignment_.siteid){continue;} //all assignments should have a siteid associated
                      //If the site filter has been selected, then the assignment must match the site filter
                      if(_site && _site!==assignment_.siteid.toLowerCase()){
                        //   console.log("with time, no site match ",entry_.asset, assignment_)
                          continue;
                      }
                      //All entries should at least have the start time recorded
                      let startMoment = moment(assignment_.start_date);
                      // console.log("Compare history: ",assignement_,moment(assignement_.start_date));
                      //Filter by both start and end?
                      if(_date.startDate && _date.endDate){
                          //Is the end date of the assignment defined?
                          if(assignment_.end_date){
                              let endMoment = moment(assignment_.end_date);
                              //Assignment start must occur before the selected end date
                              //Assignment end must occur after the selected date                                
                              if(startMoment.isSameOrBefore(_date.endDate) && endMoment.isSameOrAfter(_date.startDate)){
                                  foundMatch=true;
                              }
                          }else{
                              //The assignment start must occur before the selected end date
                              if(startMoment.isSameOrBefore(_date.endDate)){ foundMatch= true;}    
                          }
                      }
                      //else by only start
                      else if(_date.startDate && !_date.endDate){
                          //Is there an end date on the assignment?
                          if(assignment_.end_date){                                
                              // console.log("Start only, with end assign ",entry_.asset, assignment_)
                              let endMoment = moment(assignment_.end_date);
                              //the assignment end must occur after the selected start date
                              if(endMoment.isSameOrAfter(_date.startDate)){ foundMatch= true;}    
                          }else{
                              // console.log("Start only, no end assign ",entry_.asset, assignment_)
                              //Doesn't matter if the assignement start before or during the selected window: the asset was active
                              //during this time period
                              foundMatch= true;
                          }
                      }
                      //Filter by only end
                      else if(!_date.startDate && _date.endDate){
                          // console.log("Not start only end:" ,_date,entry_.asset);
                          
                          if(assignment_.end_date){
                              // if(entry_.asset == 'TK517'){
                              //     console.log("Not in? ",_date.endDate,startMoment,startMoment.isSameOrBefore(_date.endDate))
                              // }
                              // if(startMoment.isSameOrBefore(_date.endDate)){return true;}
                              //Doesn't matter ifthe assignment ended before or after the end date, as long as the assignment start
                              //before the end end date
                              // console.log("match on end ",entry_.asset, assignment_)
                              foundMatch= true;
                          } else{
                              //The assignment must start before the selected end date
                              if(startMoment.isSameOrBefore(_date.endDate)){
                                  // console.log("match on ",entry_.asset, assignment_)
                                  foundMatch= true;
                              }                            
                          }                       
                      }
                  } catch (error) {
                      console.log("Failed on date comparisons: ",error);
                  }
              }
              return foundMatch;
          });
        //   console.log("Filtered set: ",filteredSet);
          (filteredSet||[]).forEach(asset_ => {asset_.site = _site;}); //update the current site 
          return filteredSet;
      }else{
        //   console.log("No filter");
          return allAssets;
      }
   
  } catch (error) {
      console.log("Failed on filter of assets: ",error);
  }

}