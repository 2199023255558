import React,{useState,useEffect} from 'react';

import { Auth, API } from 'aws-amplify';

import { ALL_DRIVERS, SECONDS_IN_HOUR } from '../Util.js';
import { filterToTime } from '../Util.js';
import {UserPasswordVerify} from '../Authentication/FlatAuth.js'

import * as moment from 'moment';

import { DashboardRow1 } from './DashboardRow1.js';
import { DashboardRow2 } from './DashboardRow2.js';
import { DashboardRow3 } from './DashboardRow3.js';
import { DRIVER_FILTER_FIXED_OPTIONS, normalizeToRange } from '../Util.js';
import { useDashDownloader } from './useDashDownloader.js';
import { useBenchMark} from '../Util/benchmark.js';
import Dexie from 'dexie'

const DEFAULT_DRIVER_WIDGET_LIMIT = 3;

//Wrap the widgets with the loading animation logic
const inHours = (seconds) => {
    return seconds / SECONDS_IN_HOUR;
}

const roundDec = (val) => {
    return Math.round(val * 100) / 100;
}

const INFRACTION_FILTER_FIXED_OPTIONS =
[
    {text: "All", value: "no-infraction-selected"},
];

let QUERYTIME=0;

export const DashboardView = ({ 
                                eventNotify=()=>{console.log("Dashboad failed to link to eventNotify")}
                                ,filter
                                ,defaultFilter
                                ,possibleFilters
                                ,groupconfig
                                ,username
                                ,...rest}) => {

    const [downloadAllowed, setAllowDownload] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [liveDataReady, setLiveReady] = useState(false);
    const [lastFilterValues, setFilterValues] = useState(filter);
    const [resetCount, setResetCount] =useState(0);
    const [promptPassword, setPasswordPrompt] = useState(false);  

    const [drivers, setDrivers] = useState([]);
    
    
    const infractions_base = React.useRef({offline:{},live:{}}); //Ref doesn't trigger a render update when it changes
    const infractions_filtered = React.useRef({offline:{},live:{}}); //Ref doesn't trigger a render update when it changes
    const mSummaryData = React.useRef({offline:{},live:{}}); //Ref doesn't trigger a render update when it changes
    const polarData = React.useRef({offline:{},live:{}}); //Ref doesn't trigger a render update when it changes
    const [polarDataReady, setPolarReady] = useState(0);

    const db = React.useRef(); //Ref doesn't trigger a render update when it changes
    const queryCount = React.useRef(0); //Ref doesn't trigger a render update when it changes
    
    const { queryAPI,queryAPI_datecached } = useDashDownloader(db.current,()=>{console.log("Completed download?");});
    const {benchmark_query} = useBenchMark({markName:"dashboard",enabled:true},(_event)=>{
        console.log("Render called: ",_event);
        console.log("added: ",_event.renderSinceLogin - _event.render);

    });


    

    
    
    //Handle the effect of creating the component, executes one time
    useEffect(()=>{
        // console.log("Dashboard loaded: ",groupconfig,defaultFilter);
        window.performance.mark("dashboard-mount_start");
        configureIndexDB();
        window.performance.mark("dashboard-mount_end");
        const loadTest1 = window.performance.measure("loadtest","dashboard-mount_start");
        // console.log('Loadtest: ', loadTest1.duration);

    },[])

    /**
     * Configure the connection to the IndexedDB to use for caching
     */
    function configureIndexDB(){
        //Create a database that is specific to this tab's session number
        db.current = new Dexie('cachetable');
        db.current.on("blocked", function() {
          console.log ("Database upgrading was blocked by another window. Please close down any other tabs or windows that has this page open");
        });
        db.current.on("ready", function() {
          // console.log ("ready Please close down any other tabs or windows that have this page open");
        });
        db.current.on("versionchange", function() {
          console.log ("Version change Please close down any other tabs or windows that has this page open");
        });
    
        //define the list of tabled to add to the database
        let tables = {};
        tables['cache'] = "Key"
        //Create the connection to the Database:
        db.current.version(1).stores(tables) //set the tables into the database
        // console.log("Call open on DB: ",db)
        db.current.open().then( (db)=> {
          // console.log("Opened? ",db);
        }).catch (function (err) {     // Error occurred
            console.log("Failed to open db: ",err);
        });
    
      }// end configureIndexDB

    /**Once the infractionslist has been extracted, initiate the query for the timeline data */
    useEffect(()=>{
        if(!filterOptions || !filterOptions.infractionsList){return;}
        //only trigger this on the initial load:
        if(queryCount.current===0){
            fetchTimelineData();
            fetchTopDriverData();
        }
    },[filterOptions])

    /** 
     * Respond to changes in the filters, if the driverid has been changed then execute a new query
     * Otherwise update the displayed data based on the original data set
     */
    useEffect(()=>{
        // console.log("Filter change: ",filter.driver,lastFilterValues);
        // console.log("Filter received",filter,lastFilterValues);
        //Check if the selected DriverID has changed
        if(filter.driver != lastFilterValues.driver){
            fetchTimelineData(); //fetch new data from the API
            fetchTopDriverData();
            // setResetCount((prevCount) => prevCount + 1);
        }else{

            try {
                let updateTopDriver = false;
                //Check if the time has changed
                if(filter.time != lastFilterValues.time){
                    console.log("Changed time: ",filter.time);
                    updateSummaryData(infractions_base.current.live.type, filter.time,'live');
                    updateSummaryData(infractions_base.current.offline.type, filter.time,'offline');
                    updateTopDriver = true;
                }
                //Check if the selected infraction has changed
                if(filter.infraction != lastFilterValues.infraction){
                    updateInfractionSums( infractions_base.current.live.type,infractions_base.current.live.cards, "live", filter);
                    updateInfractionSums( infractions_base.current.offline.type,infractions_base.current.offline.data, "offline", filter);
                    updateTopDriver = true;
                }
                //Update the Top Driver display if the infraction changed, or the time span
                if(updateTopDriver){
                    fetchTopDriverData();
                }
            } catch (error) {
                console.log("Failed to respond to filter change: ",error,lastFilterValues.infraction);
            }
        }        
        //Store the new filter values for future comparison
        setFilterValues(filter);
        //Check if the filter is set to Speeding, then the download button should be available
        try {
            if(filter.infraction==='Speeding'){setAllowDownload(true);}
            else{
                if(isGroupPVM(groupconfig)){
                    setAllowDownload(true);
                }else{
                    setAllowDownload(false);
                }
            }
        } catch (error) {
        }
    },[filter])

     /**
      * Check that the groupconfig is known, if the group is "pintovalley" enable the download button
      */
     useEffect(()=>{
        if(isGroupPVM(groupconfig)){
            setAllowDownload(true);
        }
        // console.log("GroupConfig or PossibleFilters loaded: ",groupconfig,possibleFilters);
        
        let options = processGroupConfig(groupconfig);
        options = processPossibleFilters(possibleFilters,options);
        
        // console.log("Set possible filters options: ",options);
        // setFilterOptions(options);

        // console.log("Set the filter options: ",options);
        setFilterOptions(options);
        
    },[groupconfig,possibleFilters])

    function processGroupConfig(_groupConfig){
        if(!groupconfig.bLoaded){return;}
        // console.log("GroupConfig is loaded");
        
        // let options = Object.assign({},filterOptions);
        let options = {};
        
        //Combine the alerts and infractions together
        const possibleInfractions = (groupconfig.infractionTags || []).map(tag_ => {
            return {text: tag_.type, value: tag_.type};
        });
        //Get the list of alerts to display from the groupconfig alerttypes
        const possibleAlerts = (groupconfig.alerttypes || []).map(tag_ => {
            return {text: tag_, value: tag_};
        });
        options.infractions = INFRACTION_FILTER_FIXED_OPTIONS.concat(possibleInfractions).concat(possibleAlerts);
        options.infractionsList = (options.infractions||[]).map(value_=>{
            return value_.value;
        })

        return options;
    }
    function processPossibleFilters(_possibleFilters,options){
        //Add the possible drivers to the list of options:
        try {
            const possibleDrivers = (possibleFilters.DriverID || []).map(driverID => {
                return {text: driverID, value: driverID};
            });
            options.drivers = DRIVER_FILTER_FIXED_OPTIONS.concat(possibleDrivers);
        } catch (error) {
            
        }
        return options;
    }

    /**
     * Parse the possible filters to get the filter options to pass to the child components
     */
    // useEffect(()=>{
    //     let options = Object.assign({},filterOptions);
    //     const possibleDrivers = (possibleFilters.DriverID || []).map(driverID => {
    //         return {text: driverID, value: driverID};
    //     });
    //     options.drivers = DRIVER_FILTER_FIXED_OPTIONS.concat(possibleDrivers);
    //     console.log("Set possible filters options: ",options);
    //     setFilterOptions(options);
    // },[possibleFilters])

    /**
     * Helper function to clear the timeline data
     */
    function clearTimelineData(){
        infractions_base.current = {offline:{},live:{}};
        infractions_filtered.current = {offline:{},live:{}};
        // setLiveReady(false);
        // setOfflineReady(false);
    }

    /**
     * Start the query to fetch the Top driver component data, the API return is 
     * passed to the udpateTopDriverData() function
     * @param {*} _filter 
     */
    function fetchTopDriverData(_filter = filter){
        // console.log("Fetch top driver: ",_filter);
        setDrivers([]);
        //Handle the graph in offline
        try {
            if(filter.graph === 'offline'){
                // setAPIReturned(true);
                // this.setState({apiReturned:true,drivers:[]});//clear the results
                return new Promise((success) => { success([]); }) //return an empty promise
            }
        } catch (error) {
            
        }
        if(rest.loadingComplete){rest.loadingComplete(false);}
        Auth.currentSession().then(
        (auth) => {
            let apiName = "AuthLambda";
            let path = "/getDriversByInfraction";
            let queryBody= {
                token: auth.idToken.jwtToken,
                filter: filter,
                isWorst: true,
                limit: DEFAULT_DRIVER_WIDGET_LIMIT,
            }
            let queryid = `topdriver_${auth.idToken.payload['cognito:username']}`;
            if(_filter.graph){
                queryid+=`_${_filter.graph}`;
            }
            if(_filter.driver && _filter.driver != 'no-driver-selected'){
                queryid+=`_${_filter.driver}`;
            }
            if(filter.time!='all'){
                queryid+=`_${filter.time.startDate?filter.time.startDate:'0'}_${filter.time.endDate?filter.time.endDate:'0'}`
            }
            // Use the JSON parse/stringify to create a deep copy to prevent overwrites
            queryAPI(queryid,apiName, path,JSON.parse(JSON.stringify({body:queryBody})),updateTopDriverData);
        });
    }//end fetchTopDriverData

    /**
     * Takes care of updating the list with new data when we receive it
     */
    function updateTopDriverData(result) {
        // console.log("Top Driver update: ",result);    
        if(!result){return;}
        if(!result.data){return;}
        const data = result.data;

        const normalize = (value,total) => normalizeToRange(0, total, value);
        //get the total infractions
        let totalInfractions = 0; 
        data.map(driver => {
            totalInfractions += parseInt(driver.infractions,10);
        });

        let isWorst = true;

        //Compute the percent of infractions for each driver
        const driversMap = data.map(driver => {
            const normalized = normalize(driver.infractions,totalInfractions);
            const directedValue = isWorst ? normalized : 1 - normalized;
            driver.percent = 100 * directedValue;
            return driver;
        });
        //Check if the loadingComplete callback is defined, if found, report that the data has returned
        //Required to support the withLoadingAnimation wrapper
        if(rest.loadingComplete){rest.loadingComplete(true);}
        //Set the drivers to the state variable
        setDrivers(driversMap);
        // setAPIReturned(true);
    }//end updateTopDriverData


    /**
     * Fetch the data from the API to populate the timeline charts and the pie chart
     * @param {*} param0 
     */
    function fetchTimelineData({ _graphType = 'both', _filter = filter } = {}){
        queryCount.current = queryCount.current+1;
        // console.log("Fetch Timeline data: ",_graphType,_filter);
        benchmark_query({name:'timeline',type:'start'});
        QUERYTIME = new Date();
        // return;
        //Customize filter: (don't pass the selected infraction)
        let tmpFilter = Object.assign({},_filter);
        delete tmpFilter.infraction;
        // if(!tmpFilter.driverid)

        //Clear the previous data:
        clearTimelineData();

        let timeZone = moment().format('Z');
        Auth.currentSession().then(
            (auth) => {
                let apiName = "AuthLambda";
                let graphsToQuery = [_graphType];
                if(_graphType === "both"){graphsToQuery = ['offline','live'];}
                let infractionPath = "/getInfractionsOverTime"; //timelines
                let body= {
                        token: auth.idToken.jwtToken,
                        filter: tmpFilter,
                        timezone: timeZone,
                        assetlist:  possibleFilters ? possibleFilters.Assets.join(","): null,
                        infractionList: filterOptions.infractionsList,
                        binsMin: 5,
                    }
                //Iterate over the requested graphs:
                for(const graphType_ of graphsToQuery){
                    const queryBody = Object.assign(body,{graph:graphType_}); //add the graph type
                    let queryid = `timeline_${auth.idToken.payload['cognito:username']}_${graphType_}`;
                    if(tmpFilter.driver && tmpFilter.driver != 'no-driver-selected'){
                        queryid+=`_${tmpFilter.driver}`;
                    }
                    // Use the JSON parse/stringify to create a deep copy to prevent overwrites
                    queryAPI_datecached(queryid,apiName, infractionPath,JSON.parse(JSON.stringify({body:queryBody})),updateInfractionData)
                }
            });//end Auth request
    }//end fetchTimelineData

    /*
    * @brief Route the update based on the graph type
    */
    function updateInfractionData(_data) {  
        benchmark_query({name:'timeline'});
        window.performance.mark('data-received');
        // console.log("Time to fetch: ",new Date() - QUERYTIME);
        // console.log("Update data: ",_data);  
        if(_data.live){ updateInfractionData_live(_data.live); }
        if(_data.offline){ updateInfractionData_offline(_data.offline); }
        
    }

    /*
    * @brief Called to update the infraction graph data we're displaying
    */
    function updateInfractionData_live(data) {
        // console.log("Live Dash: ",data);
        
        //Parse the byType
        //Loop over all the infraction data from the API return
        let summaryData = {
            infractions:{
                totalinfraction:0,
            }
        };
        
        //Process the by type sorted data
        if(data.byType){
            window.performance.mark('update-summary-start')
            updateSummaryData(data.byType,filter.time,'live')
            // console.log("Update processed (summary): ",window.performance.measure('update-summary','update-summary-start').duration);
        }
        else{
            mSummaryData.current.live=summaryData
            // this.setState({summary_offline:summaryData});
        }

        let dataSet = data.dataCards;
        if(!data.dataCards){
            // console.log("No datacards");
            dataSet = data.data;

        }

        //Update the stored data:
        infractions_base.current.live['cards'] = dataSet;
        infractions_base.current.live['type'] = data.byType;

        infractions_filtered.current.live['data'] = data.data;
        infractions_filtered.current.live['cards'] = data.dataCards;
        infractions_filtered.current.live['type'] = data.byType;        

        // console.log("Update ref: ",data,infractions_filtered.current);
        //Update data to show on the timeline graph:
        window.performance.mark('update-sums-start')
        updateInfractionSums( data.byType,dataSet, "live", filter);
        // console.log("Update processed: ",window.performance.measure('update-sums','update-sums-start').duration);
        setLiveReady(true);
        
    }
    /*
    * @brief Called to update the infraction graph data we're displaying
    */
    function updateInfractionData_offline(data) {
        //   console.log("Offline dash: ",data,this.props);

        if(data.byType){
            
            // console.log("enter by type");
            const byDay = {};
            const byDayArray = [];
            //get list of displayed infractions:
            let infractionList = (filterOptions.infractions||[]).map(value_=>{
                return value_.value;
            })
    

            //Loop over all the infraction data from the API return
            //sum the totals per day  
            // console.log("Infracitonlist: ",infractionList)              
            for (const [type_, value] of Object.entries(data.byType)) {
                //   console.log("Entry: ",type_,this.state.mInfractionOptions,infractionList,infractionList.includes(type_));
                //only sum those values that are in the accepted list
                if(!infractionList.includes(type_)){
                    //  console.log("Not found: ",type_);
                     continue;
                }
                //Don't count the irrelevant
                if(type_.toLowerCase()==='irrelevant'){continue;}
                 (value || []).map(entry =>{
                    //  console.log("Date: ",entry);                        
                    let dateValue = entry.dayOnly;
                    if(!byDay[dateValue]){ byDay[dateValue]=0 }
                    // if(dateValue === '2021-10-13'){
                    //      console.log("Adding for 10-13: ",type_, entry,byDay[dateValue])
                    // }
                    byDay[dateValue] += entry.count;
                 });
                //  inf.dayOnly.toString())
            }
            //convert to the array format
            for (const [type_, value] of Object.entries(byDay)) {
                // console.log("Entry: ",type_,value);
                byDayArray.push({
                        dayOnly:type_,
                        countSum: value
                    }
                )
            }

            //Update the stored data:
            infractions_base.current.offline['data'] = [...byDayArray];
            infractions_base.current.offline['cards'] = data.dataCards;
            infractions_base.current.offline['type'] = data.byType;

            infractions_filtered.current.offline['data'] = byDayArray;
            infractions_filtered.current.offline['cards'] = data.dataCards;
            infractions_filtered.current.offline['type'] = data.byType;

                     
            updateInfractionSums( data.byType,byDayArray, "offline", filter);
        }
    
        //Check if the infraction filter is selected
        if( (filter.infraction && (filter.infraction !== 'all' && filter.infraction !== 'no-infraction-selected'))){
            
        } //end if filtered
        else{
            // console.log("Offline no infraction selected? ",data)
            //Create and empty structure to pass to the pie chart
            let summaryData = {
                infractions:{
                    totalinfraction:0,
                }
            };
            //Only update the pie chart when the infraction filter is not selected?
            //Process the data set broken by type
            if(data.byType){
                // this.updateSummaryData(data.byType,this.props.filter.time,'offline')    
            }else{
                // this.setState({summary_offline:summaryData});
            }
            if(data.byType){
                updateSummaryData(data.byType,filter.time,'offline')    
            }
            else{
                mSummaryData.current.offline=summaryData
                // this.setState({summary_offline:summaryData});
            }
        }
    }
     /*
    * @brief Filter the API return based on the selected infraction type
    */
    function updateInfractionSums(_byType,_data,_whichSource,_filter){
        // console.log("To update: ",_whichSource, _filter,_data,_byType);
        if(groupconfig.group.toLowerCase() !== 'bis' && _whichSource==='offline'){return;}
         let localData = JSON.parse(JSON.stringify(_data));    
         
        //update the _data source, to set the counts:
        try {
            // var startTime = performance.now()
            let byDate ={};
            //iterate through the array and set all entries to 0
            for(const row_ of localData){
                const dayOnly = moment(row_.dayOnly).utc().format('YYYY-MM-DD');
                if(!byDate[dayOnly]){byDate[dayOnly] = 0}
                byDate[dayOnly] = row_.countSum;
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    row_.countSum = 0;
                    byDate[dayOnly] = 0;
                }
            }
            // var endTime = performance.now()
            // console.log(`Call to reset took ${endTime - startTime} milliseconds`)
            
            // console.log("ByDate: ",byDate);
            var startTime2 = performance.now()
            // console.log("ByType: ",_byType);
            for (const [type_, set_] of Object.entries(_byType)) {
            //     // console.log("Test: ",type_,set_);
                //filter the type based on the current filter:
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    // console.log("Test: ",type_);
                    if(_filter.infraction !== type_){continue;}
                    // console.log("Process: ",type_);
                    // console.log("Test: ",type_,set_);
                    for(const row_ of set_){
                        if(row_.count > 0){
                            if(!byDate[row_.dayOnly]){byDate[row_.dayOnly] = 0}
                            byDate[row_.dayOnly] +=row_.count; 
                        }
                    }
                } //end if filtered
            }//end 

            //Place back into an array:
            let newData = [];
            try {
                for(const elem_ of Object.keys(byDate)){
                    // console.log("Elem: ",elem_);
                    newData.push({
                        dayOnly: elem_,
                        countSum : byDate[elem_]
                    })

                }    
            } catch (error) {
                console.log("Failed to parse: ",error);
            }
            
            // console.log(`Call to filter took ${endTime2 - startTime2} milliseconds`)
            //  console.log("ByDate: ",_whichSource,byDate,newData);
            if(_whichSource === 'live'){
                // var startTime3 = performance.now();
                let filteredByType = JSON.parse(JSON.stringify(_byType));
                //Need to filter the ByType?
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    filteredByType = {}
                    filteredByType[_filter.infraction] = _byType[_filter.infraction];
                }
                // console.log("ByType: ",filteredByType)    
    
                //update the local stored data:
                infractions_filtered.current.live['cards'] = newData;
                infractions_filtered.current.live['type'] = filteredByType;
                // console.log(`Call to filter took2 ${endTime3 - startTime3} milliseconds`)
            }
            else{
                let filteredByType = JSON.parse(JSON.stringify(_byType));
                //Need to filter the ByType?
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    filteredByType = {}
                    filteredByType[_filter.infraction] = _byType[_filter.infraction];
                }
                //  console.log("Offline: ",newData,filteredByType)    
    
                infractions_filtered.current.offline['cards'] = newData;
                infractions_filtered.current.offline['type'] = filteredByType;
            }
        } catch (error) {
            
        }
    }

     //Handle the data to display on the pie cahrt
    function updateSummaryData(_byType,_timefilter,_whichSource){
    
        // console.log("Call updateSummary with: ",_timefilter,_whichSource,_byType);

        let start = filterToTime(_timefilter);
        let endDate = null;
        if(!start){
            console.log("Try to use the date: ",_timefilter.startDate)            
            start =moment(_timefilter.startDate,'YYYY-MM-DD')
        }
        if(_timefilter.endDate){
            endDate=moment(_timefilter.endDate,'YYYY-MM-DD')
        }

        //Create and empty structure to pass to the pie chart
        let summaryData = {
            infractions:{
                totalinfraction:0,
            }
        };

        // console.log("Update date with date: ",start);
        //Iterate over the object by type
        // console.log("Start:" ,start);
        for (const [type_, days_] of Object.entries(_byType)) {
            summaryData.infractions[type_]= 0; //initialize the count to 0
            //Iterate over the days in the set
            // console.log("Type, days:" ,type_,days_)
            (days_ || []).map(day_ =>{
                //  console.log("Day to add:",day_.dayOnly)
                if(start.isSameOrBefore(day_.dayOnly, 'day')){
                    
                    if(endDate){
                        if(endDate.isAfter(day_.dayOnly, 'day')){
                            // console.log("Is after: ",day_.dayOnly)
                            //Sum the values per day:
                            summaryData.infractions[type_] += day_.count;
                            summaryData.infractions.totalinfraction += day_.count;
                        }
                    }else{
                    //Sum the values per day:
                    summaryData.infractions[type_] += day_.count;
                    summaryData.infractions.totalinfraction += day_.count;
                    }
                    
                }
            });//end map loop    
        }//end for loop
        

        switch(_whichSource){
            case 'live':
                mSummaryData.current.live = summaryData;
                // this.setState({summary_live:summaryData})
                break;
            case 'offline':
                mSummaryData.current.offline = summaryData;
                // this.setState({summary_offline:summaryData});
                break;
        }
        
    
    }

    /**
     * Helper method to check if the group name matches 'pintovalley'
     */
    const isGroupPVM =(_groupconfig)=>{
        if( !_groupconfig || !_groupconfig.group){return false;}
        //Allow the PVM user to always see the download button
        if(_groupconfig.group.toLowerCase()==='pintovalley'){return true;}
        return false;
    }

    

    /*
   * Fetch, format, and download the dashboard data when the download button is pressed.
   */
   function downloadClick(_isAuthenticated) {
        console.log("download clicked",_isAuthenticated);
        
        // let timeDebug = new Date();
        // console.log("Click on download: ",_isAuthenticated,this.props,this.state)

        //Create the filename for the CSV file
        let fileName = "Dashboard_";
        fileName+= moment().format('MMM-DD-YYYY')
        //Add time to the filename:
        if(filter.time !== 'all'){
            const start = filterToTime(filter.time);
            fileName+= "_"+start.format('MMM-DD-YYYY');
        }else{
            fileName+= "_all-time";
        }
        //Add the driverid selection to the filename:
        if(filter.driver!=='no-driver-selected'){
            fileName+= "_"+filter.driver;
        }
        //Add the infraction selection to the filename:
        if(filter.infraction!=='all' && filter.infraction!=='no-infraction-selected'){
            fileName+= "_"+filter.infraction;
        }
        //Set the filename type:
        fileName +=".csv";
        // console.log("FileName: ",fileName )

        
        //Was this called from the button press, the function is recalled after 
        //the password authentication with new data
        // console.log("Test it: ",_isAuthenticated)
        if(_isAuthenticated===undefined){
            //Execute a password validation:
            
            setPasswordPrompt(true);
            return;
        }
        if(!_isAuthenticated){ //authentication failed, password prompt was canceled
            return;
        }
        // return;
        
        //Execute a query to the AWS Lambda:
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                apiName: "getInfractionsOverTime_download",
                filter: filter,
                timezone: moment().format('Z'),
                assetlist:  possibleFilters ? possibleFilters.Assets.join(","): null,
            }
            };
            return API.post("AuthLambda", "/apiRouter", myInit);
        });

        //Wait the promise to return:
        realPromise.then(_return => {
            // console.log("Return from click: ",_return);
            if(_return.error){ //Did we receive an error message: failed to generate download link?
            console.log("returned error: ",_return);
            }
            else{ //data returned from lambda
                //place all data into a csv formatted string:
                let csvString = "data:text/csv;charset=utf-8,"; //create the header for the file
                
                //Iterate over the data and add it to the csv file string:
                if(groupconfig.group.toLowerCase() === 'pintovalley'){
                    //Add a header for the columns:
                    csvString += "Date,TimeOfDay,Type,DriverID" +"\r\n"; //add the end of line and append to the string
                    (_return.result.data ||[]).forEach(row_=>{
                        let rowData = moment.parseZone(row_.timestamp).format('YYYY-MM-DD')+","+moment.parseZone(row_.timestamp).format('HH:mm:ss')+","+row_.type+","+'="'+String(row_.driverid)+'"';
                        csvString += rowData +"\r\n"; //add the end of line and append to the string
                    })
                }else{
                    //Add a header for the columns:
                    csvString += "Date,TimeOfDay,Type,DriverID,Site,Speed" +"\r\n"; //add the end of line and append to the string
                    //Add the data into each row:
                    (_return.result.data ||[]).forEach(row_=>{
                        let rowData = moment.parseZone(row_.timestamp).format('YYYY-MM-DD');
                        rowData +=","+moment.parseZone(row_.timestamp).format('HH:mm:ss');
                        rowData +=","+row_.type;
                        let driverIDString = "Pending";
                        if(row_.driverid && row_.driverid.length === 5){
                            driverIDString = String(row_.driverid)
                        }
                        
                        rowData +=","+'="'+driverIDString+'"';
                        rowData +=','+row_.site;
                        // rowData +=','+row_.region;
                        rowData +=','+row_.metadata.speed;
                        csvString += rowData +"\r\n"; //add the end of line and append to the string
                    })
                }
                
                //Create the download function
                try {
                    // console.log("String to download: ",csvString);
                    const encodedURI = encodeURI(csvString);
                    const link = document.createElement('a'); //create a temporary link to use as the download
                    //Set the content and file name
                    link.href = encodedURI; //set the content of the file to download
                    link.download = fileName; //set the filename that will be shown to the user
                    document.body.appendChild(link); //add the temporary link to the document (webpage)
                    link.click(); //automatically click the link 
                    document.body.removeChild(link); //remove the temporary link used to download the file    
                    // console.log("Download",new Date() - timeDebug)   
                    // this.setState({downloadClicked:false});
                } catch (error) {
                    console.log("Failed to download: ",error);
                }
            }//end handle data return from lambda
        });//end handle promise return
    }//end downloadClick

    function onApply(_data){
        console.log("Apply triggered: ",_data)
        
        //Make sure we can reset the time stamp:
        // if(!res['time']){
        //     console.log("Time not set");
        //     res['time'] ='all';
        // }

        eventNotify({type: "filter", data: _data});
        // fetchTimelineData({_filter:_data});
    }

    /**
     * Fetch the data to display in the polar infraction plot
     * @param {*} _filter: current active filter to apply to the data
     */
    function fetchQuantizedData(_filter) {

        polarData.current.live.hour=[];
        // console.log("Fetch the quant data: ",_filter);
        let binMinWidth =  _filter.quant || 5;

        //Dispatch the update to the SQL table:
        Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/getQuantizedInfractionsOverTime";
                let queryBody= {
                        token: auth.idToken.jwtToken,
                        filter: _filter,
                        binsMin: binMinWidth,
                        graph: "live",
                        startDate: _filter.startDate? _filter.startDate:null,
                        endDate: _filter.endDate?_filter.endDate: null,
                    }
                //Define a unique cache name based on the selected filters
                let queryid = `polarplot_${auth.idToken.payload['cognito:username']}_${_filter.infractions}_${_filter.quant}`;
                try {
                    if(_filter.driver && _filter.driver != 'no-driver-selected'){
                        queryid+=`_${_filter.driver}`;
                    }    
                    if(_filter.startDate || _filter.endDate){ //this filter set doesn't include the time object
                        queryid+=`_${_filter.startDate?_filter.startDate.format('YYYY-MM-DD'):'0'}_${_filter.endDate?_filter.endDate.format('YYYY-MM-DD'):'0'}`;
                    }
                    if(_filter.sites ){
                        queryid+=`_${_filter.sites}`;
                    }
                } catch (error) {
                    console.log("error setting query: ",error, _filter);
                }
                // console.log("Queryid: ",queryid);
                // Use the JSON parse/stringify to create a deep copy to prevent overwrites
                queryAPI(queryid,apiName, path,JSON.parse(JSON.stringify({body:queryBody})),processQuantizedData);
            })
            .catch((error) => {
                console.error("getQuantizedInfractionsOverTime remove site Fail; ",error); 
            })
    }//end fetch API call

    /**
     * Process the return of the polar plots data query
     * @param {*} _data: API data resposne
     */
    function processQuantizedData(_data) {
        if(_data && _data.result){
            polarData.current.live.hour= _data.result;
            //Update the state to trigger a re-render
            setPolarReady(prevCount => prevCount + 1)
        }
    }//end processQuantizedData
    


    //Call Render (need to clean this up so that we aren't doing computation in the render loop - it is making UI sluggish)
    function render(){
        // console.log("Dash render: ", db.current);
        if(!groupconfig || !groupconfig.group){return (<div></div>);}

        //Return the formatted HTML:            
        return (
            <div className="main-dashboard">
                <DashboardRow1
                    groupconfig = {groupconfig}
                    downloadAllowed = {downloadAllowed}
                    options = {filterOptions}
                    filteredData = {infractions_filtered.current}
                    defaultFilter = {defaultFilter}
                    filter = {filter}
                    onApply={onApply}
                    downloadCallback={downloadClick}
                    liveDataReady={liveDataReady}
                />
                <DashboardRow2
                    groupconfig = {groupconfig}
                    options = {filterOptions}
                    filteredData = {infractions_filtered.current}
                    defaultFilter = {defaultFilter}
                    filter = {filter}
                    possibleFilters = {possibleFilters}
                    resetCount = {resetCount}
                    driverData = {drivers}
                    onApply={fetchQuantizedData}
                    polarData={polarData.current}
                    // dataReady = {polarDataReady}
                />
                 <DashboardRow3
                    groupconfig = {groupconfig}
                    summaryData = {mSummaryData.current}
                    filter = {filter}
                    onApply = {eventNotify}
                />
                {/* Prompt for the password to be re-entered before allowing the download */}
                { promptPassword &&
                    <UserPasswordVerify 
                        handleClose={(_data)=>{
                            console.log("CLosed: ",_data);
                            if(_data === false){                               
                                
                                // this.setState({downloadClicked:false})
                            }
                            setPasswordPrompt(false);
                            downloadClick(_data)
                        }}   
                        groupconfig = {groupconfig}
                        username = {username}
                    />
                }
            </div>
        );
    }

    //Wait until the groupconfig data is loaded from the login before starting to view
    if(groupconfig && groupconfig.bLoaded){
        return render();
    }else{
        return (<div></div>)
    }
    

    

}//end DashboardView