import React,{useState} from 'react';

//Wrap the components with the loading animation logic
import { undisplayInfraction } from '../Util.js';
import { withLoadingAnimation } from '../Wrappers/withLoadingAnimation.js';
import {InfractionChart} from '../DashboardWidgets.js'
const InfractionChartWithLoading = withLoadingAnimation(InfractionChart); //

  

/*@brief Sum the counts of two objects, this allows for unique keys in each set
*/
function sumObjectsByKey(...objs) {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k))
          a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
}

export const DashboardRow3 = ({ 
                                    onClick=()=>{console.log("Row3 failed to link to onClick")}
                                    ,groupconfig
                                    ,summaryData
                                    ,filter
                                    ,...rest}) => {

    const [graphType, setGraphType] = useState('live');       
    const [summary, setSummary] =useState(summaryData.live);

    
    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //  console.log("Row 3 loaded: ",groupconfig,filter);
    },[])

    React.useEffect(()=>{
        // console.log("Update based on graph type: ",filter.graph);
        let summary = {};
        switch(filter.graph){
            case 'live':
                summary = summaryData.live;
                break;
            case 'offline':
                summary = summaryData.offline;
                break;
            case 'both':        
                    //Set the summary based on the available data
                    try {
                        let bOfflineAvailable =false;
                        let bLiveAvailable = false;
                        // console.log("Both: ",this.state.summary_offline, this.state.summary_live)
                        try {
                            if(summaryData.offline&& summaryData.offline.infractions && summaryData.offline.infractions.totalinfraction>0){bOfflineAvailable = true;}
                            if(summaryData.live && summaryData.live.infractions && summaryData.live.infractions.totalinfraction>0){bLiveAvailable = true;}    
                        } catch (error) {
                        }
                        
                        
                        if(bOfflineAvailable && !bLiveAvailable){ //only offline data available
                            summary = this.state.summary_offline;
                        }
                        else if(!bOfflineAvailable&& bLiveAvailable){ //only live data available
                            summary = this.state.summary_live;
                        }else if(bOfflineAvailable && bLiveAvailable){ //both  available
                            //Combine the values from both sets
                            summary.infractions = sumObjectsByKey(summaryData.live.infractions, summaryData.offline.infractions)
                        }    
                    } catch (error) {
                        
                    }
                    
                break;
        }
        setSummary(summary);
   },[filter.graph])

   function handleClick(_infractionType){
    // console.log("Infraction click:", infractionType);
        //Don't allow clicking for pintovalley users:
        // return;
        if(groupconfig.group.toLowerCase() === 'pintovalley'){return;}
        if(groupconfig.group.toLowerCase() === 'demo_group'){return;}
        onClick({
            type: 'link',
            data: {
                href: 'video-drilldown',
                extra: {
                    groupconfig: groupconfig,
                    filter: {
                        driverID: filter.driver,
                        timePeriod: filter.time,
                        infractionType: undisplayInfraction(_infractionType),
                        siteIDs: filter.siteIDs,
                        role: filter.role,
                        graph: filter.graph,
                    }
                }
            },
        });
   }//end handle click

     //Call Render 
     function render(){
        // console.log("Polar render: ",{...filteredData});
        //Return the formatted HTML:            
        return(
            <div className="dashboard-row-overlap">
                <div className="col-xl-4">
                    <div className="card counter-card-1">
                        <div className="card-block-big infraction-prop-wrapper">
                            <InfractionChartWithLoading summary={summaryData.live} onClick={handleClick} groupconfig={groupconfig} />
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }

    return render();

}//end DashboardView