
import React from 'react';
import { Auth, API } from 'aws-amplify';
import * as moment from 'moment';

import { ExpandedCardBase } from '../Notifications/ExpandedCardBase.js';
import { getStreamURL} from '../Util.js';


import './VideoCutNotecard.css';

/**
 * VideoCutNotecard:
 *  - different video
 *  - different comments
 *  - different buttons
 *  - same size
 *  - same top left (no tags?)
 *  - same top right
 * 
*/

/* @Brief Compoent to render the VideoCut result 
*/
const VideoCutNotecard = ({details,handleClose,...props}) =>{

    const displayName = 'VideoCutNotecard';
    const notecardType = 'videocut';
    
    const [loadedCard, setCardLoaded] = React.useState(false);
    const [buttonSet, setAllowedButtonSet] = React.useState([]);
    
    React.useEffect(()=>{
        // console.log(`[${displayName}] loaded props: `,props);

        //Create a list of the buttons to allow for the footer:
        // AddComment, Download, Cut Video, GoTo Highlights, Irrelevat, Delete
        let footerButtons = [];
        footerButtons.push('addcomment');
        footerButtons.push('download');
        footerButtons.push('delete');
        setAllowedButtonSet(footerButtons);
        
        //Get the extra details for the notecard to display in the expanded view
        fetchCardDetails({infractionid:props.infractionid});

         //Handle the exit // Anything in here is fired on component unmount.
        return () => {
        }
    },[]) //[] -> execute once

    /**
     * Fetch the initial Infraction notecard details using the linked infractionid
     * Use these values to populate the default descriptions on the notecard
     * @param {*} _data : object holding the infractionid
     */
    const fetchCardDetails = async (_data) => {
        // console.log("Fetch photo for :",_driverID)
        const authReturn = await Auth.currentSession();
        //Set up the API request:
        let apiName = "AuthLambda";
        let path = "/apiRouter";
        let myInit = {
            body: {
                apiName: "getCardFromInfractionID",
                token: authReturn.idToken.jwtToken,                
                infractionid: _data.infractionid,
                mode: 'fetch',         
                assetid: true,  //indicate that we also need to get the assetid (typically not retured by this api call)
            }
        };
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            try {
                // console.log("Data return: ",apiReturn,props);
                // console.log("Notecard fetch returned: ",data,data.data);
                (apiReturn.data || []).forEach( (elem_) =>{
                    // console.log("Notecard fetch returned: ",elem_);
                    let infractionTagArray = elem_.infractiontags || [];
                    if(elem_.infractiontags ){
                        infractionTagArray = elem_.infractiontags.split(",");
                    }
                    // console.log("Tags: ",infractionTagArray);
                    const card = {
                        tag: elem_.tag,
                        infractionType: elem_.infractiontype,
                        infractionID: _data.infractionid,
                        status: 'FleetReview',
                        severity: elem_.severity,
                        cardID: elem_.videocardid,
                        id: elem_.parentid,
                        flags: elem_.flags,
                        vehicleID: elem_.assetid,
                        siteID: elem_.siteid,
                        notes: "",    
                        name: elem_.driverid,
                        infractionTags : infractionTagArray,
                        timeReceived: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                        timeOfDay: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                        videoURL: getStreamURL(props.video||props.videoURL),
                        dlurl: props.dlurl,
                        };
                    setCardLoaded(card);
                });
                
            } catch (error) {
                console.log("fetch error: ",error);
            }
        }//end API return check
    };//end fetchData()

    /**
     * Sync the new comment to the SQL table using the API call
     * @param {*} _data 
     */
    const saveComment = async (_data) => {
        // console.log("Fetch photo for :",_driverID)
        const authReturn = await Auth.currentSession();
        //Set up the API request:
        let apiName = "TrifectaCoreAPI";
        let path = "/saveComment";
        let myInit = {};
        let apiConfig = {
                            token: authReturn.idToken.jwtToken,                
                            type:notecardType
                        }
        //sanitize the object data to force all object keys to be lower case:
        let sanitizedObject = Object.fromEntries(
            Object.entries(_data).map(([key_, val_]) => [key_.toLowerCase(), val_])
          );
        // console.log("Save message:" ,sanitizedObject,_data);
        myInit.body= Object.assign(apiConfig,sanitizedObject); //merge the write and config paramters into a single object
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            try {
                console.log("Data return: ",apiReturn);
            } catch (error) { console.log("save comment error?: ",error);}
        }//end API return check
    };//end saveComment()
    /**
     * Updates made on the VideoCutCard, this is a new comment
     * or the request to delete the card was made
     * @param {*} _data 
     */
    function cardChange(_data){
        // console.log("received card change:" ,_data);        
        //request to delete is forwarded from the NC-Footer 
        //this needs to be reformatted to match the expected object structure
        if(_data && _data.delete){_data.changetype = 'delete'} 
        switch(_data.changetype){
            case 'add-comment': saveComment(_data); break;
            case 'delete': deleteNotecard({pk_id: props.pk_id}); break; //call the API to delete the card
        }
    }

    /**
     * Send a request to the API to delete a videocut notecard
     * On return the call to close the notecard will be forwarded to the parent component
     * @param {*} _data : object holding the infractionid
     */
    const deleteNotecard = async (_data) => {
        const authReturn = await Auth.currentSession();
        //Set up the API request:
        let apiName = "VideoCutAPI";
        let path = "/fetchVideoCuts";
        let myInit = {
            body: {
                token: authReturn.idToken.jwtToken,                
                pk_id: _data.pk_id,
                mode: 'delete',         
            }
        };
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn){
            try {
                // console.log("Data return: ",apiReturn,props);
                if(handleClose){handleClose({pk_id:_data.pk_id,delete:true});}    
            } catch (error) {
                console.log("API error: ",error);
            }
        }//end API return check
    };//end deleteNotecard()

    //Handle the exit of the notecard
    function onClose(_data){
        // console.log("Card close: ",_data);
        if(_data){} //if data was supplied then don't attempt to forward to the parent
        else{
            //Trigger the close function on the parent component 
            if(handleClose){handleClose();}
        }
        //Clear the loaded state to close the ExpandedCardBase
        setCardLoaded(false);//clear the local data
    }

    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        // console.log("Time Response:" ,completeTimeEstimate,showUserConfirm);
       //Return the HTML code to display:
       return (
        <React.Fragment>
            {loadedCard && 
                <ExpandedCardBase
                    groupconfig={props.groupconfig}
                    userInfo={props.userInfo}
                    handleClose={onClose}
                    infractionid = {props.infractionid}
                    {...loadedCard}
                    notecardtype={notecardType}
                    cardChange={cardChange}
                    allowedButtons={buttonSet}
                    className = "videocutnotecard"
                />
            }
        </React.Fragment>
       );
   };//end of render()
   //Function component is expecting the return, add return to the render() call
   return render();

}//end VideoCutNotecard

/**
 * Helper for the VideoCut notecard. Enables loading the notecard with only the pk_id from the videocut_dvr 
 * SQL table. The VideoCut notecard assumes that other data including a presigned URL for the video is available when 
 * the request to open the notecard is made
 * @param {*} props 
 *      pk_id: the primary key (unique identifier) for the video cut in the videocut_dvr sql table
 */
const VideoCutNotecardLoader = ({ ...props}) => {

    // const displayName = 'VideoCutNotecardLoader';
    
    const [infractionid, setInfractionid] = React.useState(null);
    const [videoURL, setVideoURL] = React.useState(null);
    const [dlURL, setDLURL] = React.useState(null);
    
    React.useEffect(()=>{
        // console.log(`[${displayName}] loaded props: `,props);
        //Get the extra details for the notecard to display in the expanded view
        fetchMissingData({pk_id: props.pk_id||props.videocardid});
         //Handle the exit // Anything in here is fired on component unmount.
        return () => {
        }
    },[]) //[] -> execute once

    /**
     * Fetch the remaining details needed to load the notecard
     * Missing: infractionid, video/videourl, dlurl
     * @param {*} _data : object holding pk_id of the videocut
     */
    const fetchMissingData = async (_data) => {
        // console.log("Fetch data for :",_data)
        //
        await Auth.currentSession();
        //Set up the API request:
        let apiName = "VideoCutAPI";
        let path = "/fetchVideoCuts";
        let myInit = {
            body: {
                pk_id: _data.pk_id,
                mode: 'extra_details',         
            }
        };
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            try {
                // console.log("Data return: ",apiReturn,props);
                if(apiReturn.data){
                    setVideoURL(apiReturn.data[0].videoURL);
                    setDLURL(apiReturn.data[0].dlrul);
                    setInfractionid(apiReturn.data[0].infractionid); //set the infractionid last (this triggers the loading of the VideoCutNotecard)
                }

            } catch (error) {
                console.log("fetch error: ",error);
            }
        }//end API return check
    };//end fetchData()

    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        // console.log("Load VideoCutNotecard:" ,infractionid);
        //Wait for the InfractionID to be known before loading the VideoCutNotecard
        return (
            <React.Fragment>
                {infractionid && <VideoCutNotecard  {...props}
                                                    infractionid={infractionid}
                                                    videoURL={videoURL} 
                                                    dlURL={dlURL}
                                                    />}
            </React.Fragment>
       );
   };//end of render()
   //Function component is expecting the return, add return to the render() call
   return render();

}//end VideoCutNotecardLoader

export {VideoCutNotecardLoader,VideoCutNotecard};