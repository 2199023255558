
import React from 'react';
import ReactModal from 'react-modal';

import './JourneyNotecard.css';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { median,formatSpeed } from './map-utils.js';
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from './map-utils.js'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-widgets/styles.css';


//OpenLayers
import {Map as olMap} from 'ol'; //need to change the name of the OpenLayers Map, or it conflicts with the standard type
import {Tile, Vector } from "ol/layer";
import {Vector as VectorSource, XYZ as XYZSource} from "ol/source";
import {transformExtent} from 'ol/proj';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import {defaults as defaultControls} from 'ol/control';
import {Stroke, Style } from 'ol/style';
import Feature from 'ol/Feature';

import {useDriverPhotoFetch} from '../Wrappers/fetchHooks.js';

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";

import blankAvatar from '../assets/avatar-blank.png';

//Helper methods to format the appearance of the cell in the table
const nameFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return "-----";
    }
    return _value;
}//end nameFormatter
const pictureFormatter = (_value, row, rowIndex, extraData) => {
    // console.log("Value: ",_value,row);
    if(!_value){
        return <img className="driverphoto-oc" src={blankAvatar} alt=''  /> ;
    }
    return <img className="driverphoto-oc" src={_value} alt=''  />;
}//end pictureFormatter
const driveridFormatter = (_value, row, rowIndex, extraData) => {
    try {
    if(_value.length!==5){
        return "Pending";
    }
    return _value;    
    } catch (error) {
        return "Pending";
    }
    
}//end driveridFormatter


/**
 * @brief Create a notecard that provides an overview of a Journey
 * @param {Object} props - input to the notecard describing the journey
 *   @param {string} props.id journeyid linked to the Journey
 *   @param {string} props.assetid ID of the asset
 *   @param {string} props.siteid ID of the site
 *   @param {string} props.name Recoded name for the Journey: ASSETID_SITE_STARTDATE-TIME
 *   @param {array} props.infractionSet array of the notecards linked to the Journey
 *   @param {array} props.speedArray array of all recorded speed data from the Journey
 *   @param {Object} props.time Contains timeStart, timeEnd, and timeDuration
 *   @param {string} props.startDate Starting date of the Journey
 *   @param {string} props.routes The OpenLayers geometry model to draw the Journey
 *   @param {array} props.drivers Array of the DriverIDs returned from the Journey_log SQL table * 
 * @param {reef} db - link to the driverphotos database
 */
export const JourneyNotecard = ({siteid,infractionSet,drivers,speedArray,db,...props}) =>{

    //Define the Top of the card statistics level statistics
    //--------------------
    const [speedStats, setSpeedStats] = React.useState({}); //initialize to an empty object
    const [journeyTime,setTimeStats] = React.useState({}); //initialize to an empty object
    const [siteName, setSiteName] = React.useState(siteid); //set the sitename, initialize to the SiteID (prefer to show the alias)
    const [siteDetails, setSiteDetails] = React.useState(); //create a variable to hold the SiteDetails once filtered from the input properties   

    //Driver data
    //--------------------
    const [driverList, setDriverList] = React.useState([]); //Create a list of the DriverIDs from the Journey and Notecards    
    const {driverphotos, fetchDriverPhoto } = useDriverPhotoFetch(db); //Fetch photos for the driverIDs, returned photos held in driverPhotos object
    const [driverDetails, setDriverDetails] = React.useState({data:[],cols:[]}); //Create an object with the expected data format for the display table

    //Infraction data    
    const [infractionCounts, setInfractionCounts] = React.useState({data:[],cols:[]}); //Create an object with the expected data format for the display table

    //Displayed table data    
    const [table,     setTableData] = React.useState({data:[],cols:[]}); //Create an object that is displayed to the current table

    //Store the current tab and trigger for changing the tab
    const [activeTab, setActiveTab] = React.useState('map'); //Set the default when the Journey Notecard opens

    //Define values used in the component that should not change, or that do not trigger the render cycle
    const tabsView =['map','infractions','driver']; //The available tabs on the bottom half of the Journey Notecard
    const mapRef = React.useRef(null); //a reference to the OpenLayers map 
    
    
    // Handle the initial conditions: 
    // - Get siteName from the loaded details
    // - Compute the statistics on the time values
    React.useEffect(() => {       
        //Get the site name from the sitedetails so that we can have the correct alias displayed
        if(props.siteDetails && props.siteDetails[0] && props.siteDetails[0].alias){
            setSiteName(props.siteDetails[0].alias);
            setSiteDetails(props.siteDetails[0]);
        }

        //Format the duration of the journey:
        let timeStats = {
            start: props.time.start || null,
            end: props.time.end ||null,
            duration: null
        }
        if(props.time && props.time.duration){
            let hours = props.time.duration / 3600;
            hours = Math.floor(hours);
            let mins = (props.time.duration - (hours*3600))/60;
            mins = Math.floor(mins);
            timeStats.duration = String(hours).padStart(2, '0')+":"+String(mins).padStart(2, '0')
        }
        //Configure time details:
        setTimeStats(timeStats)
    },[]) //run once when the Notecard is opened

    //Process the infractions attached to the Journey
    // Count the type and number of each type
    // Append the DriverIDs attached to each notecard to the overall list of DriverIDs in the Journey
    // Filter the DriverID list to remove duplicates, Pending, and Unavailable. Add Pending if the list is empty
    React.useEffect(() => {
        // console.log("InfractionSet: ",infractionSet);
        let infractionTypes = [];
        let driverIdSet = new Set(drivers);

        let infractionMap= new Map(); //use map to keep entries unique
        //Iterate over all input infractions, and place in a map, index by the infractionid
        //This ensures that the infractionid is unique
        (infractionSet||[]).forEach(set_ => {
             (set_||[]).forEach(inf_ => {
                 infractionMap.set(inf_.infractionid,inf_);
             });//end loop on infractions in set
        });//end loop on sets

        //Iterate over the unique infractions
        (infractionMap||[]).forEach(inf_=>{
            // console.log("Mapped Inf: ",inf_)
            //Process each tag
            ((inf_.infractiontags && inf_.infractiontags.split(',')) || []).forEach(type_=>{
                try {
                    // console.log("process:" ,type_);
                    infractionTypes[type_] = infractionTypes[type_] || {count:0};
                    infractionTypes[type_].count+=1;
                } catch (error) {
                    console.log("Failed on:" ,error);
                }
            }); //end parseing the types

            //Handle the driverIDs, add the DriverID attached to the infraction to the list of Drivers
            driverIdSet.add(inf_.driverid);
        });

        //Format the Infraction type/count for display in the table
        //---------------------------------------------------------
        //Convert into an array:
        let infractionData = [];
        for (const [type_, set_] of Object.entries(infractionTypes)) {
            infractionData.push({type: type_, count: set_.count,reactkey:`${type_}-reactkey`});
        }
        //Define the columns in the table:
        let infractionColumns = [
            {dataField: 'type', text: 'Infraction Type',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'count', text: 'Count',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
        ];
        //set the classnames for each column:
        infractionColumns.map(col => {col.classes = 'soc-' + col.dataField; return col; });
        //Store the formatted data in the infractionCounts state
        setInfractionCounts({data:infractionData, cols:infractionColumns});

        //Handle the DriverID filtering:
        //-----------------------------------------------
        //Filter out any duplicates from the DriverIDs after adding the notecards:
        if(driverIdSet.size===0){driverIdSet.add("Pending")}
        let filteredDriverIds = [...driverIdSet];
        //Filter the DriverIDs so Pending or Unavailable aren't shown in the table
        //Only show pending if no known drivers are recorded for the Journey
        filteredDriverIds = filteredDriverIds.filter(id_ => {return !id_.includes('Unavailable')}); //Don't ever include unavailable
        if(filteredDriverIds.length>1){//make sure Pending isn't added when we have a known driver
            filteredDriverIds = filteredDriverIds.filter(id_ => {return !id_.includes('Pending')});
        }
        //Load into an object array format:
        let tmpList = [];
        (filteredDriverIds||[]).forEach( driverid_=>{
            tmpList.push({driverid: driverid_,reactkey:driverid_+"-reactkey"});
        })

        //Store the filtered Drivers in the DriverList state
        setDriverList(tmpList); 

    },[infractionSet]) //listen for changes to the infractions array

    //Process the recieved speed values to extract the min,max,mean, and median speed
    React.useEffect(()=>{
        //Parse out the speed:
        try {
            if(speedArray && speedArray.length>0){
                //Define a temporary object to hold the stats
                let tmpSpeed={
                    min: 100,
                    max: -1,
                    mean: -1,
                    median: 0,
                }
                //Set tracking variables
                let sum=0;
                let count=0;
                //Iterate over the routes in the Speed array
                for(const elem_ of speedArray){
                    //Iterate over speed values in the route
                    for(const speed_ of elem_){
                        sum+=speed_; //Sum the speed
                        tmpSpeed.min = Math.min(tmpSpeed.min,speed_); //Find the min 
                        tmpSpeed.max = Math.max(tmpSpeed.max,speed_); //Find the max 
                        count++; //increment the count for the mean computation
                    }
                    tmpSpeed.median += median(elem_); //add the median of each route to a sum of medians
                }
                //Compute the mean and median values
                tmpSpeed.mean = sum/count; //Compute the overall mean value
                tmpSpeed.median /= speedArray.length; //set the median to the average of the sum of medians
                //Store the speed statistics in the speedStats state
                setSpeedStats(tmpSpeed); 
            }
        } catch (error) { console.log("Fail on speed overview: ",error);}
    },[speedArray]) //Listen for changes to the speedArray values


    //Process the list of Drivers to get the photos and names for each
    React.useEffect(() => {
        // console.log("DriverList: ",driverList);
        //Require both the DriverList and SiteDetails to be loaded before continueing
        if( (!driverList || driverList.length ===0) || !siteDetails){return;}
        // let idx = 0;
        //Fetch the Driver photo for each Driver in the list:
        (driverList||[]).forEach(driver_=>{
            // console.log("Driver to process:" ,driver_,siteDetails);
            if(!driver_.photo){
                fetchDriverPhoto(driver_.driverid,siteDetails); //call the fetchDriverPhoto from useDriverPhotoFetch
            }
            // setTimeout(() => { fetchDriverPhoto(driverid_.driverid,siteDetails);  }, idx++ * 1000);
        });
    },[driverList,siteDetails]) //Listen for changes to the DriverList and SiteDetails state variables

    //DriverList has updated, process the list of drivers to format the data into a table viewable array
    React.useEffect(() => {
        // console.log("Format DriverList: ",driverList);
        //Define the table columns:
        let driverColumns = [
            {dataField: 'driverid', text: 'DriverID',editable:false, sort:true,formatter: driveridFormatter,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'name', text: 'Name',editable:false, sort:true,formatter: nameFormatter, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},            
            {dataField: 'photo', text: 'Picture',editable:false, sort:true,formatter: pictureFormatter, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},            
        ];
        //set the classnames for each column:
        driverColumns.map(col => {col.classes = 'soc-' + col.dataField; return col;});
        //Save the data in the table format to be displayed
        setDriverDetails({data:driverList, cols:driverColumns});
    },[driverList]);//Listen for changes to the DriverList

    //DriverPhotos has returned with updated data from the useDriverPhotoFetch Hook
    //Merge this data back into the DriverList for display
    React.useEffect(() => {
        //Prevent empty updates to the state variables:
        if(!driverList || driverList.length===0){return;} //Don't process the merge if there are no drivers in the list
        if(!Object.keys(driverphotos).length===0){return;} //Don't process the merge if there are no driver photos
        // // console.log("DriverInfo change:" ,driverphotos);

        //Merge new data against the current list of drivers
        //Iterate through the driverList and update with driverPhotos
        (driverList||[]).forEach(driver_=>{
            //Get the new data from the driverPhotos:
            driver_ = Object.assign(driver_, driverphotos[driver_.driverid]);
        });
        // console.log("Merged:" ,Object.keys(driverphotos).length,[...driverList]);
        //Update the DriverList state
        setDriverList([...driverList]);
    },[driverphotos]);

    //Update wich table data is rendered to the displayed table based on the selected tab
    function switchTabTables(_selectedView){
        try {
            switch(activeTab){
                case 'driver':{ setTableData(driverDetails); }break;
                case 'infractions':{ setTableData(infractionCounts); }break;
            }    
        } catch (error) {
            console.log("Error on switch tab tables: ",error);
        }
    }//end switchTabTables

    //Update the selected table data if new data to dispay is received
    React.useEffect(() => {
        switchTabTables(activeTab);        
    },[driverDetails,infractionCounts]); //listen for changes to the table data for the Drivers or the Infractions
    
    //Process what happens when the selected tab changes
    //The Rendered map has to be shutdown and re-rendered when the map tab is opened or closed
    //to rebind it to the HTML element
    React.useEffect(() => {
        // console.log("TabView effect: ",activeTab);
        if(activeTab === 'map'){
             setTimeout(() => { loadMap();  }, 500); //reload the Map element
        }else{
            try {
                //If the map is currenlty loaded, then destroy it
                if(mapRef.current){
                    mapRef.current.setTarget(null);
                    mapRef.current = null;
                }
                //Update which table data is shown
                switchTabTables(activeTab);
            } catch (error) { console.log("Failed to release: ",mapRef,error);  }
        }
    },[activeTab]); //Listen for changes to the selected tab
  
    //Handle closing the notecard
    function closeCard(_notCancel){
        //Release the map
        try {
            if(mapRef.current){
                mapRef.current.setTarget(null);
                mapRef.current = null;
            }
        } catch (error) {}
        //Call the input close prop to shutdown the card render
        props.handleClose({},true);
    }//end closeCard()

    // Load the map, Draw the Journey path on the map and then 
    // fit the current view to the Journey
    const loadMap=()=> {
        if(mapRef.current){console.log("Map already exists");return;}

        //DEFINE the map tile sources
        //================================
        //Get data from HERE, the extent and minZoom constrain the region that is pulled from HERE
        var hereLayer = new Tile({
            source: new XYZSource({
                url: 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/png8?apiKey=YaibLoQqb4TyE5urE7yIl2JS4dnR8P0zbWMFrgFWGs8'
            }),
            extent :  transformExtent([119.72217825630148,-22.604643313893195, 120.08187458098014,-22.34583588618293], COORDTYPE_GPS, COORDTYPE_MERCATOR),
            minZoom: 13.5,
            visible:true,            
        });
        //Get data from Google, the default source for all the tiles
        var googleLayer = new Tile({
            source: new XYZSource({
                url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', //satellite hybrid (? why no api key?)
            }),
            visible:true,
        });

        //--------------------------------------------------------
        //Create layers to show geofenced regions:
        var overlaySource = new VectorSource({});
        var overlayLayer = new Vector({
            source: overlaySource,
            style: new Style({
                stroke: new Stroke({
                  width: 6,
                  color: [237, 212, 0, 0.8],
                }),
              }), 
            visible:true,         
        });
        //Add a mouse position to debug the location on the screen map
        var mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(6),
            projection: COORDTYPE_GPS,
            // projection: COORDTYPE_MERCATOR,
        });

        //Define the map:
        const map = new olMap({
            //  Display the map in the div with the id of map
            target: 'siteMap',
            layers: [
                googleLayer,
                hereLayer,
                overlayLayer
            ],
            // Add in the following map controls
            // controls: defaultControls().extend([mousePositionControl]), //debugging tool
        })

        //Draw the routes:
        if(props.route){
            //Iterate over all routes in the Journey
            for(const route_ of props.route){
                const routeFeature = new Feature({ type: 'route', geometry: route_});   
                overlaySource.addFeature(routeFeature); //add it to the overlay on the map
            }
            //Set the map's view to fit to the drawn Journey
            map.getView().fit(overlaySource.getExtent());
        }
        //Add reference to the Map to allow for release
        //Using the ref will not trigger a render request
        mapRef.current = map;
    }//end of loadMap()

    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        
        //=================Define the table formatting==================
        const rowClasses = (row, rowIndex) => { return 'soc-lister'; };
        // Set style for the rows in the table   
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){ rowcolor = '#00afed20' }
            return{backgroundColor: rowcolor}
        };
        
        //Pagination options for the infractions table       
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{text: '5', value: 5}] 
        };

        //Return the HTML code to display:
        return (
            <div className="journeynotecard" >
                <ReactModal isOpen={true} className="modal-dialog journeynotecard-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="journeynotecard-modal-content" >
                        <div className = 'sc-title'> {siteName } Journey Overview  </div>

                        {/* Define the top half of the notecard show the AssetID, Date,  Speed Stats, and Time Stats */}
                        <div className='journeyTop'>
                        <div className="report-grid">
                                    <div className="title-type">Asset:</div>
                                    <div className="value">{props.assetid}</div>

                                    <div className="title-type-r">Date:</div>
                                    <div className="value-r">{props.startDate}</div>
                            </div>
                            <div className="report-grid">
                                    <div className="title-type">Site:</div>
                                    <div className="value-wide">{siteName}</div>
                            </div>                            
                            <div className="report-grid">
                                    <div className="title-type">Speed Stats</div>
                                    <div className="value">Average: </div>
                                    <div className="units">{formatSpeed(siteDetails,speedStats.mean,{show:true,precision:1})}</div>  
                                    <div className="value">Min: </div>
                                    <div className="units">{formatSpeed(siteDetails,speedStats.min,{show:true,precision:1})}</div>  
                                    <div className="value">Max: </div>
                                    <div className="units">{formatSpeed(siteDetails,speedStats.max,{show:true,precision:1})}</div>  
                                    <div className="value">Median: </div>
                                    <div className="units">{formatSpeed(siteDetails,speedStats.median,{show:true,precision:1})}</div>  
                            </div>
                            <div className="report-grid">
                                    <div className="title-type">Time</div>
                                    <div className="value">Start:</div>
                                    <div className="units">{journeyTime.start}</div> 
                                    <div className="value">End:</div>
                                    <div className="units">{journeyTime.end}</div> 
                                    <div className="value">Duration:</div>
                                    <div className="units">{journeyTime.duration}</div> 
                            </div>
                        </div>
                        {/* Define the bottom half of the Notecard rendered as three tabs: map, infractions, driver*/}
                        <div className='journeyBottom'>
                            {/* Set the tabs and link the setActiveTab to change the rendered data based on which is chosen */}
                            <div className="viewTabs">
                                <Tabs className='viewSelection'
                                    defaultActiveKey={activeTab} unmountOnExit={true} mountOnEnter={true} 
                                    id='uncontrolled-tab-example' 
                                    activeKey={activeTab} 
                                    onSelect={(k)=>setActiveTab(k)}
                                >
                                    {/* Loop over the list of tabs and create one for each */}
                                    {tabsView.map((type) => {
                                        return(  <Tab key={type} eventKey={type} title={type}/> )
                                    })} 
                                </Tabs>
                            </div>
                            {/* Render the map if the tab is selected */}
                            {activeTab ==='map'?
                                <div id='siteMap' className = "site-olMap" ></div>
                            :
                                //Map tab not selected: Render the BootStrap table
                                //The table.data and table.columns hold the data to show on the table
                                //These are set based on which tab is selected    
                                <div className="journeyTable">
                                    {(table.data&&table.data.length>0) && 
                                        <BootstrapTable 
                                            keyField='reactkey' // a react specific thing that sets the 'key' for each row in the table
                                                                // react uses keys to keep track of identity when things change
                                            data={table.data} // <-- IMPORTANT: this is the actual data being displayed
                                            columns={table.cols}
                                            defaultSorted={[
                                                            {dataField: 'type', order: 'asc'}, // how things should be sorted by
                                                            {dataField: 'driverid', order: 'asc'}
                                                            ]} // default when first displayed
                                            striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                            // keep track of what data belongs to what row when scanning across
                                            rowStyle={ rowStyle}
                                            hover={true}   // sets a hover effect, so the background color of a row changes when the
                                            //                  // mouse is over it. This signals to the user that the row is clickable
                                            classes={"journey-lister"} // sets a CSS class so we can style this table specifically
                                            rowClasses={rowClasses}
                                            bootstrap4 = {true}
                                            detailView={true}
                                            detailViewIcon={false}
                                            detailViewByClick={true}
                                            pagination={ activeTab==='driver'? null : paginationFactory(paginationOptions) }
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {/* Add the cancel and submit buttons to the bottom of the Notecard */}
                        <div className="sc-footer">
                                <button className="btn btn-danger"  onClick={ ()=>closeCard(false)}>Cancel</button>
                                <button className="btn btn-primary" onClick={ ()=>closeCard(true) }>Submit</button>
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}// end JourneyNotecard component


