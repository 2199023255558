import React, { PureComponent } from 'react';

import './StoryTile.css';
import ReactEcharts from 'echarts-for-react';
import { STORYLINE_DATE_FORMAT} from '../Util.js';
import moment from 'moment';

const phoneIcon = 'path://M451,374c-15.88-16-54.34-39.35-73-48.76C353.7,313,351.7,312,332.6,326.19c-12.74,9.47-21.21,17.93-36.12,14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48,5.41-23.23,14.79-36c13.22-18,12.22-21,.92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9,44,119.9,47,108.83,51.6A160.15,160.15,0,0,0,83,65.37C67,76,58.12,84.83,51.91,98.1s-9,44.38,23.07,102.64,54.57,88.05,101.14,134.49S258.5,406.64,310.85,436c64.76,36.27,89.6,29.2,102.91,23s22.18-15,32.83-31a159.09,159.09,0,0,0,13.8-25.8C465,391.17,468,391.17,451,374Z';
const noCallIcon = `path://m 143.23408,204.94362 c -4.9199,-1.04082 -11.09462,-3.70148 -18.90294,-8.1452 -12.58056,-7.15961 -19.55082,-12.30447 -30.897399,-22.80589 l -2.322576,-2.14957 -7.531833,7.70582 c -4.142508,4.23821 -10.554955,10.78777 -14.249882,14.55458 l -6.718049,6.84875 -2.28287,-2.28287 -2.282869,-2.28287 2.259505,-2.31692 c 1.242728,-1.27431 7.43231,-7.61567 13.754627,-14.09191 6.322316,-6.47624 11.720315,-12.04063 11.995553,-12.3653 l 0.500431,-0.59031 -1.825308,-1.9222 c -7.608011,-8.01188 -13.422298,-16.1888 -20.274331,-28.51283 -6.012503,-10.81405 -8.560133,-19.77097 -7.314931,-25.71771 1.044384,-4.98769 6.18783,-10.02693 14.132369,-13.846061 4.615449,-2.218756 4.774655,-2.216209 7.488837,0.119816 4.252637,3.660135 10.349279,12.349955 14.361701,20.470395 2.189403,4.43096 2.737466,6.1372 2.493629,7.76323 -0.172241,1.14859 -0.767702,2.1295 -4.630425,7.62775 -0.711499,1.01275 -1.437463,2.35508 -1.613252,2.98293 -0.738905,2.63911 0.320918,6.09523 3.470312,11.31685 1.752663,2.90586 4.962112,7.30317 5.44767,7.46392 0.165944,0.0549 10.988281,-10.82967 24.049641,-24.18802 13.06136,-13.35834 23.82178,-24.31832 23.91205,-24.3555 0.0903,-0.0372 1.17162,0.94515 2.40301,2.18297 l 2.23889,2.25059 -2.26331,2.31494 c -1.24482,1.27322 -10.16245,10.41119 -19.81697,20.30661 -9.65452,9.89541 -19.46816,19.93501 -21.8081,22.31021 l -4.25443,4.31855 1.5875,1.47222 c 5.15895,4.78429 11.8564,9.15851 16.72161,10.92115 1.26669,0.45891 2.05023,0.57113 3.43959,0.49261 2.1925,-0.12391 3.19109,-0.61024 6.95859,-3.38892 3.84042,-2.83245 4.75771,-3.26851 6.5938,-3.13455 1.75616,0.12812 5.2439,1.71894 10.63415,4.85042 8.7336,5.07382 15.29899,10.1485 17.57054,13.58104 0.94355,1.4258 0.83595,2.051 -1.01607,5.90357 -3.84732,8.00318 -8.61789,12.89426 -13.93867,14.29077 -2.11535,0.55521 -5.57217,0.57531 -8.06979,0.0469 z`


//Find the timestamp to set for the group icon
const addMarker = (_name,_entry,_marker) => {
  try {

    //The name data is written into the name field, this is passed back on callback by the plot library
    let nameData = {
      infractionid: _entry.infractionid, //linked to notecard
      tag: _name, //infraction tag
      set: _marker.set, //all infractions represented by this marker
    }
    //Configure the marker for the phone
    let markerToPush =  { name:  JSON.stringify(nameData),
                          xAxis:_marker.xAxis, yAxis:_marker.yAxis,
                        };
    //Shift the phone icon up if the marker label is present, needs to set above
    if(_marker.value>1){markerToPush.symbolOffset= ['-30%', '-260%'] }

    return markerToPush;
   
  } catch (error) {
    return null;
  }
}//end computeGroupTime

const processMarkerData = (_dataSet,_timeArray, _props) => {
  try {
    let noCallPermissionSet = false;
    if(_props.groupconfig && _props.groupconfig.permissions && _props.groupconfig.permissions.storylines && _props.groupconfig.permissions.storylines.nocall){
      noCallPermissionSet = true;
    }
    //Split data into display sets:
    let dataSet = Object.entries(_dataSet)||[];
    //Set up the Droswiness and Severe Drowsiness marker sets first so we can scan against them for the Callins
    // 1. value only arrays by type
    dataSet.forEach(([name, value]) => {
      if(name==='Calls'){return;}
      if(name==='NoCall'){return;}
      // console.log("Name,Val",name, value);      
      value.times = value.data.map(entry_=>{return entry_.value[0]});
      value.values = value.data.map(entry_=>{return entry_.value[1]});
      //need to inject this into the nulled array
      value.events = Array(_timeArray.length).fill(null);
      //Look over each pair in the dataset:
      value.data.forEach(entry_ =>{
        
      //Need to make sure we are rounding to the same time as the time array
      let mTime = moment.parseZone(entry_.value[0])
      let roundTime = mTime.minute() || mTime.second() ? mTime.add(1, 'minute').startOf('minute') : mTime.startOf('minute');
      let compareString = roundTime.format('YYYY-MM-DDTHH:mm:ss');
      //Find the time in the timeArray
      let findReturn = _timeArray.findIndex((time_) => time_ === compareString);
      if(findReturn>=0){ //found a time entry on the xAxis
        // console.log("Found match, inserting at: ",findReturn,name,entry_.value[1]);
        value.events[findReturn] = entry_.value[1]} //add to events (render on yAxis)
        //Pass the infractionid in the name to allow for interaction with notecards          
        // value.marker.data.push({name: entry_.infractionid, xAxis:findReturn, yAxis: entry_.value[1]}); //add to markers (render the icons)
        value.marker.data.push({  name: JSON.stringify({infractionid: entry_.infractionid, type: name}),
                                  xAxis:findReturn, yAxis: entry_.value[1],
                                  value:entry_.set.length,
                                  label:{ show:entry_.set.length>1,
                                          position:'top',
                                          distance: 1,
                                          color: value.color,//'#000', //set the label to the same color as the marker
                                          fontSize: 16,
                                          align:'center',
                                        },
                                  set: entry_.set,
                                }); //add to markers (render the icons)
      })
    });
    //Add the calls:
    dataSet.forEach(([name, value]) => {
      // if(name!='Calls'){return;} //only look at calls
      switch(name){
        case 'Calls':{
          value.data.forEach(entry_ =>{ //look at each call, these will be grouped like the notecards
        
            //Scan through the Drowsiness and SevereDrowsiness to find the matching infractionid
            dataSet.forEach(([scanName, scanValue]) => {
              if(scanName==='Calls'){return;} //don't look at calls
              if(scanName==='NoCall'){return;} //don't look at calls
              //Check each of the markers:
              scanValue.marker.data.forEach(marker_=>{  //the existing marker
                let entryName = JSON.parse(marker_.name);
    
                //Need to check against the set IDs:           
                let findReturn = marker_.set.findIndex((set_) => {
                  // console.log("Compare: ",set_.infractionid, entryName.infractionid,set_.infractionid===entry_.infractionid )
                  return set_.infractionid === entry_.infractionid});              
    
                if(findReturn<0){return;} //No Match found

                  //Check if the NoCall flag is set, if so we don't add to the Call set:
                  if(noCallPermissionSet&& (marker_.set||[]).findIndex(entry_ =>{ return (entry_.flag && entry_.flag==='NoCall')})>=0){
                      // console.log("Found NoCall in: ",marker_.set)
                      return;
                  }
                  
                  let markerToPush = addMarker (name,entry_,marker_);
                  if(markerToPush){value.marker.data.push(markerToPush);}
    
                  // //The name data is written into the name field, this is passed back on callback by the plot library
                  // let nameData = {
                  //   infractionid: entry_.infractionid, //linked to notecard
                  //   tag: name, //infraction tag
                  //   set: marker_.set, //all infractions represented by this marker
                  // }
                  // //Configure the marker for the phone
                  // let markerToPush =  { name:  JSON.stringify(nameData),
                  //                       xAxis:marker_.xAxis, yAxis:marker_.yAxis,
                  //                     };
                  // //Shift the phone icon up if the marker label is present, needs to set above
                  // if(marker_.value>1){markerToPush.symbolOffset= ['-30%', '-260%'] }
                  // //Add this back to the top level marker
                  // value.marker.data.push(markerToPush); //add to markers (render the icons)
              });//end scan of markers
            });//end non call scan
          })//end looping through the calls
        }break;
        case 'NoCall':{
          value.data.forEach(entry_ =>{ //look at each call, these will be grouped like the notecards
        
            //Scan through the Drowsiness and SevereDrowsiness to find the matching infractionid
            dataSet.forEach(([scanName, scanValue]) => {
              if(scanName==='Calls'){return;} //don't look at calls
              if(scanName==='NoCall'){return;} //don't look at calls
              //Check each of the markers:
              scanValue.marker.data.forEach(marker_=>{  //the existing marker
                let entryName = JSON.parse(marker_.name);
    
                //Need to check against the set IDs:           
                let findReturn = marker_.set.findIndex((set_) => {
                  // console.log("Compare: ",set_.infractionid, entryName.infractionid,set_.infractionid===entry_.infractionid )
                  return set_.infractionid === entry_.infractionid});              
                if(findReturn<0){return;}
                  // console.log("Found Match in: ",marker_.set)

                let markerToPush = addMarker (name,entry_,marker_);
                if(markerToPush){value.marker.data.push(markerToPush);}
              });//end scan of markers
            });//end non call scan
          })//end looping through the NoCall
        }

      }
            
    });//end calls scan
  } catch (error) {  }
  return _dataSet;
}//end processMarkerData

//Find the timestamp to set for the group icon
const computeGroupTime = (_set,_flag) => {
  // console.log("Start grouptime: ",_set,_flag);

  if(!_set){return null;}
  try {
    let timeMethod = 'average';
    if(_flag){
      timeMethod = _flag;
    }
    // console.log("timeMethod: ",timeMethod);

    let setSize = _set.length;
    let mStart = moment.parseZone(_set[0].timestamp.replace("_","T"))

    //set the data point to the average time
    switch(timeMethod){
      case 'average':
      case 'default':{
        
        let accumulatedTime = 0;
        for(let i=1; i<setSize; i++){
          let mNext = moment.parseZone(_set[i].timestamp.replace("_","T"));
          let timeDiffSecs = moment.duration(mNext.diff(mStart)).asSeconds();
          accumulatedTime = accumulatedTime+timeDiffSecs;
        }
        
        let mAverage = moment(mStart).add(accumulatedTime/setSize,'seconds');
        // console.log("Time average: ",mStart.format('YYYY-MM-DDTHH:mm:ss'), accumulatedTime, moment(mStart).add(accumulatedTime/setSize,'seconds').format('YYYY-MM-DDTHH:mm:ss'));
        
        //Find the event that is closest to the average:
        let closestEntry = null;
        let closestTime = null;
        _set.forEach(entry_=>{
          let timeDiffSecs = Math.abs(moment.duration(mAverage.diff(moment.parseZone(entry_.timestamp.replace("_","T"))) ).asSeconds());
          if(!closestEntry){
            closestEntry = entry_;
            closestTime = timeDiffSecs;
            return;
          }                
          if(timeDiffSecs < closestTime){
            closestEntry = entry_;
            closestTime = timeDiffSecs;
            return;
          }
        });
        
        let returnObj= {
          infractionid : closestEntry.infractionid,
          time: mAverage.format('YYYY-MM-DDTHH:mm:ss'),
        };
        return returnObj;
  
  
      }
      case 'middle':{

        // console.log("Set: ",_set);
        let middleIndex = setSize/2;

        if(!Number.isInteger(middleIndex)){
          middleIndex = Math.ceil(middleIndex); //if 3.5 -> return 4;
        }
        //Decrease by 1 to be 0 indexed:
        middleIndex -= 1;
        // console.log(`Middle index of ${setSize} is: `,middleIndex);

        let returnObj= {
          infractionid : _set[middleIndex].infractionid,
          time: moment.parseZone(_set[middleIndex].timestamp.replace("_","T")).format('YYYY-MM-DDTHH:mm:ss'),
        };
        // console.log("Return obj:" ,returnObj);
        return returnObj;

  
      }
    }//end switch
  } catch (error) {
    console.log("Failed on group time: ",error);
    return null;
  }
}//end computeGroupTime
//Add events into the data set:
const addEventsByType = (_dataSet,_props) => {
  try {
    let noCallPermissionSet = false;
    if(_props.groupconfig && _props.groupconfig.permissions && _props.groupconfig.permissions.storylines && _props.groupconfig.permissions.storylines.nocall){
      noCallPermissionSet = true;
    }

    let dataSet = Object.entries(_dataSet)||[];
    // 1. value only arrays by type
    dataSet.forEach( ([name, value])=> {
      if(name==='NoCall'){return;}//no data so can't be processed
      //  console.log("Value: ",name,value,_props,_props.data[name])
      //Scan the input for arrays
      let type = value;
      if(!type.marker.symbol){type.marker.symbol='circle'}
      if(!type.marker.symbolSize){type.marker.symbolSize=20;}
      // _props.data[name].forEach((type_, idx)=> {
      //   type.data.push({time: type_.timestamp.replace("_","T"), value: [type_.timestamp.replace("_","T"),type.height], infractionid: type_.infractionid})
      // });
      //Process each group of data from the hour sets:
      Object.entries(value.byHourGrouping).forEach( ([hour,hourData]) => {
          
            let addObject = null;
            if(hourData.set.length>1){
              // console.log("ON hour group: ",hour, hourData, hourData.set);

              let groupTime = computeGroupTime(hourData.set,'middle');
              if(!groupTime){return;}
              //Add the closest entry as the infractionid to attach to the marker
              addObject = {  time: groupTime.time, 
                              value: [groupTime.time,type.height],
                              infractionid: groupTime.infractionid,
                              set: hourData.set
                          };
            }else{
              addObject = { time: hourData.set[0].timestamp.replace("_","T"), 
                            value: [hourData.set[0].timestamp.replace("_","T"),type.height], 
                            infractionid: hourData.set[0].infractionid,
                            set: hourData.set
                          };
            }

          if(!addObject){return;}

          //Check if the NoCall flag is set:
          if(noCallPermissionSet &&  (hourData.set||[]).findIndex(entry_ =>{ return (entry_.flag && entry_.flag==='NoCall')})>=0){
              // console.log("Found NoCall in: ",hourData.set)
              addObject.flag = true;
              //Can we add a new type here?
              _dataSet['NoCall'].data.push(addObject);
          }

          type.data.push(addObject)
      });

    });

  } catch (error) {
    console.log("Failed to parse data: ",error);
  }
  return _dataSet;
}//end addEventsByType

const groupByTime = (_dataSet,_props) => {
  try {
    
    //Handle the droswy events:
    Object.entries(_dataSet).forEach( ([name, value])=> {
      if(name==='NoCall'){return;} //no data so can't be processed
      // console.log("Value: ",name,value,_props,_props.data[name])
      let mStart = moment.parseZone(_props.data.journeyStart.replace("_","T")).startOf('day');
      let byHourGrouping = {}; //add an object to hold each hour as a set

      //Scan the input for arrays
      _props.data[name].forEach((event_, idx)=> {

        // console.log("Eval: ",event_.timestamp);
        
        let mTime = moment.parseZone(event_.timestamp.replace("_","T"))
        let timeElapsed = moment.duration((moment(mTime).startOf('day')).diff(mStart)).asDays();
        timeElapsed = Math.floor(timeElapsed); //round down to hour
        let hourVal = mTime.hours() + (timeElapsed*24);

        //  console.log("hourval: ",mTime.hours(), timeElapsed, hourVal);
         if(!byHourGrouping[hourVal]){byHourGrouping[hourVal] = {set:[]}}
         byHourGrouping[hourVal].set.push(event_);
      });
      value.byHourGrouping = byHourGrouping;//add the hour grouping to the set:
    });

  } catch (error) {
    console.log("Failed to parse data: ",error);
  }
  return _dataSet;
}//end groupByTime

/* Initialize the bar render object */
const initializeObject = (_height) => {
  // console.log("Update datset: ",_dataSet);
  if(!_height){ return  {data:[],marker:{data:[],itemStyle:{emphasis:{}}}}; }
  return {data:[],marker:{data:[],itemStyle:{emphasis:{}}}, height: _height};  
}
/* Set the color for the render type */
const setColor = (_dataSet,_color) => {
  // console.log("Update datset: ",_dataSet);
  _dataSet.marker.itemStyle.emphasis.color = _color;
  _dataSet.color = _color;
  _dataSet.marker.itemStyle.emphasis.borderColor = _color;
  return _dataSet;
}

/*
  * @brief Render a stem plot
*/
export class StemPlot extends PureComponent {
  constructor(props) {
      super(props);
      this.chartClick = this.chartClick.bind(this);
      this.updateActualChart = this.updateActualChart.bind(this);
      this.chartHover = this.chartHover.bind(this);
      this.chartEvents = {
          'click': this.chartClick,
          'updateaxispointer': this.chartHover,
      };

      this.state = {
        startTime: this.props.data.journeyStart.replace("_","T"),
        endTime: this.props.data.journeyEnd.replace("_","T"),
        timeArray: [],
      }
  }

  componentDidMount(){

     //Take total time of the set:
     let timeResolution = 1000*60; //1 min in ms;
     let timeElapsed = new Date(this.state.endTime) - new Date(this.state.startTime);//returns in ms
     timeElapsed = timeElapsed/timeResolution;

     //Build out the time array using a stepped value starting at the startTime of the journey
     let startMoment = moment.parseZone(this.state.startTime);
     let timeArray = [];     
     timeArray.push(startMoment.format('YYYY-MM-DDTHH:mm:ss'));
     for(let timeIdx =0; timeIdx<timeElapsed; timeIdx++){
         timeArray.push(startMoment.add(1,'minutes').format('YYYY-MM-DDTHH:mm:ss'));
     }
     this.setState({timeArray: timeArray});
  }

  /*
  * @brief handler for getting the reference to the actual chart object
  *
  * We use this to add a click handler, so we can handle clicks that aren't directly on
  * the graph line.
  */
  updateActualChart(chart) {
      if (chart) {
          const instance = chart.getEchartsInstance();
          this.actualChart = instance;
          chart.echartsElement.addEventListener('click', this.chartClick);
      }
  }

  /*
  * @brief handler for the event when the user clicks the chart
  *
  * When they click exactly on the graph line, we get a nice click event automatically with the data
  * index, otherwise we take the information from whatever data index is currently hovered over, if any.
  */
  chartClick(event, chart) {
      // console.log("Clicked on chart: ",event,this.hoveredIndex);
      if(!this.props.onClick){ return;}
      if(!event.componentType || event.componentType!='markPoint'){return;}
      if (event.dataIndex !== undefined || this.hoveredIndex !== undefined) {
          const index = event.dataIndex === undefined ? this.hoveredIndex : event.dataIndex;
          this.props.onClick({index: index, event:event, chart:chart});
      }
  }

  /*
  * @brief handler for the event when the user hovers over the chart, records what datapoint is hovered over
  */
  chartHover(event, chart) {
      // console.log("Update hover: ",event.dataIndex, event)
      this.hoveredIndex = event.dataIndex;
      this.hoveredChart = chart;
  }

  
  /*
  * @brief Each time the data is updated call render to re-generate the plot
  */
  render() {

      //take the data and place it into an array of [key,value]      
      let data2 = {};
      Object.entries(this.props.data).forEach( ([name, value])=> {
        if(Array.isArray(this.props.data[name])){
          switch(name){
            case 'Drowsiness':{
                data2[name] = initializeObject(2);
                data2[name].marker.symbol='circle'
                data2[name].marker.symbolSize=20;
                data2[name].marker.symbolOffset = [0, '-50%'];                
                data2[name].marker.itemStyle.emphasis.borderWidth= 5;
                setColor(data2[name],'#ffE100FF');
                
            }break;
            case 'Severe Drowsiness':{
              data2[name] = initializeObject(5);
              // console.log("Returned object: ",data2[name])
              data2[name].marker.symbolOffset = [0, '-50%'];
              // data2[name].marker.itemStyle.emphasis.borderColor= '#fF4B00FF';
              data2[name].marker.itemStyle.emphasis.borderWidth= 5;
              setColor(data2[name],'#fF4B00FF');
            }break;
            case 'Calls':{
              data2[name] = initializeObject();
              setColor(data2[name],'rgba(0,120,212,1)');
              // data2[name] = {data:[],marker:{data:[],itemStyle:{ emphasis: {color:'rgba(0,120,212,1)'}}},color:'#000000FF'};
              data2[name].marker.symbol=phoneIcon;
              data2[name].marker.symbolSize=20;
              // data2[name].marker.itemStyle.color= data2[name].marker.itemStyle.emphasis.color;
              data2[name].marker.symbolOffset = ['-30%', '-190%'];
              data2[name].marker.symbolRotate = '90';
              // data2[name].marker.itemStyle.emphasis.borderColor= data2[name].marker.itemStyle.emphasis.color;
              data2[name].marker.itemStyle.emphasis.borderWidth= 3;

            }break;
          }//end switch:
        }
      });
      //Manually add the NoCall      
      data2['NoCall'] = initializeObject();
      setColor(data2['NoCall'],'rgba(128,128,128,1)');
      data2['NoCall'].marker.symbol=noCallIcon;
      data2['NoCall'].marker.symbolSize=20;
      data2['NoCall'].marker.symbolOffset = ['-30%', '-190%'];
      data2['NoCall'].marker.symbolRotate = '90';
      data2['NoCall'].marker.itemStyle.emphasis.borderWidth= 1;

     

      data2 = groupByTime(data2,this.props);
      // console.log("Group by time: ",data2);

      //Add events into the data set:
      data2 = addEventsByType(data2,this.props);
      // console.log("Data2: ",data2);
    
      //Format the display sets, take data -> marker
      //  data2 = formatToChartTypes(data2,this.state.timeArray);      
      data2 = processMarkerData(data2,this.state.timeArray,this.props);
      // console.log("Render data: ",data2,this.state.timeArray );

      let enableAnimation = false;
      if(this.props.delayRender!==undefined){
        
        if((new Date() - this.props.dataUpdated) > 1000 && (!this.props.delayRender)){
          // console.log("Time since update: ",new Date() - this.props.dataUpdated)
          enableAnimation = true;
        }
      }else{
        // console.log("delay flag: ",this.props.delayRender)
      }

      let timeArray = this.state.timeArray;
      let timeSize = timeArray.length;

      const echartOpts = {
        title: {
            show:false
        },
        tooltip : {
          trigger: 'item',
          show: this.props.interactive,
          position: function (pos, params, dom, rect, size) {
            try {
              let iconLocation = [rect.x + rect.width/2,rect.y + rect.height/2];
              // console.log("position: ",pos,params,rect,size,iconLoc);
              var popupPosition = pos;
              popupPosition = [pos[0]+20,pos[1]+20]; //offset down and to the right;
              if((size.contentSize[0] + iconLocation[0]) > size.viewSize[0]*0.84){ //are we running out of screen?
                // console.log("Close to edge: ",pos,size.contentSize,size.viewSize, size.viewSize[0]*0.85 );
                popupPosition = [pos[0]-20-size.contentSize[0],pos[1]+20]; //offset down and to the left;
              }
              return popupPosition;  
            } catch (error) {
              return pos;
            }
            
          },
          formatter: function (value, index) {
              // console.log("Tooltip: ", value, index,timeArray[value.data.xAxis],timeArray[value.data.xAxis-1]);
            try {
              let xIndex = Math.max(0,value.data.xAxis-1);
              let timeVal = timeArray[xIndex];//get the timestamp from the xAxis data  
              try {
                timeVal = moment(timeVal).format(STORYLINE_DATE_FORMAT)
              } catch (error) {
              }
              return timeVal;                
              
            } catch (error) {
              return ''; 
            }
        }
        },
        xAxis : [
          {
              type : 'category',
              // type : 'time',
              // boundaryGap : true,
              splitLine: {
                show: false
              },
              data : this.state.timeArray,              
              axisLabel:{
                showMinLabel: true,
                showMaxLabel: true,
                rotate: this.props.interactive? 45: 0,
                interval: function (index, value) {
                  // Format to pad as the same length, this allows the yAxis to line up on both graphs
                  try {
                    // if(moment(value))
                    //  console.log("Axis interval: ",index,value, moment(value).minute() );
                    if(moment(value).minute() === 0){
                      return true;
                    }
                    return false;
                  } catch (error) {
                    return false; //return original value if our parsing failed;
                  }
                },
                formatter: function (value, index) {
                    // Format to pad as the same length, this allows the yAxis to line up on both graphs
                    try {
                      // if(moment(value))
                      // console.log("Axis label: ",value, moment(value).minute() );
                      // if(moment(value).minute() === 0){
                        let timeOnly = value.substring(value.indexOf('T')+1);
                        return timeOnly.substring(0,timeOnly.lastIndexOf(':'));
                      // }
                      
                    } catch (error) {
                      return value; //return original value if our parsing failed;
                    }
                }
              }
          }
        ],
        yAxis : [
          {
            max: 10,
            type : 'value',
            show: false,
            splitLine: {
                show: false,
            },
          }
        ],
        series: [],
        animation: enableAnimation,
        // Configure the margins around the plot added by echarts
        grid:{
           bottom:'25%',
           left:'5%',
           right:'5%',
        }
      };
      echartOpts.series = [];
      // console.log("Set tooltip: ",this.props.interactive)
      Object.entries(data2).forEach(([name, value]) => {
        if(this.props.delayRender){
          // console.log("Render? ",name, value);
          if(name === 'Calls' && !this.props.delayRender.calls){return;}
          if(name === 'Drowsiness' && !this.props.delayRender.cards){return;}
          if(name === 'Severe Drowsiness' && !this.props.delayRender.cards){return;}
          
        }
        // if(name === 'NoCall'){return;}
        // console.log("Name,Val",name, value);
        echartOpts.series.push(
          {
            name: name,
            type: "bar",
            showSymbol: false,
            data: value.events,
            barWidth: 3,
            markPoint: value.marker,
            itemStyle:{color : value.color},            
          }
        )
      });

      return (
          <div className={this.props.class}>
            <ReactEcharts option={echartOpts}
                          onEvents={this.chartEvents}
                          notMerge={true}
                          ref={this.updateActualChart}
                          style={this.props.chartStyle}
            />
          </div>
          
      )
  }
}
