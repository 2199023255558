
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';

import './DriverTab.css';

import * as moment from 'moment';

import { Auth, API } from 'aws-amplify';

import 'react-widgets/styles.css';

import {Commenter,Comment} from '../NoteCard-Comments.js'
import hamburger from '../assets/hamburger-menu-icon-small.png';


const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;


const roleFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return "unassigned";
    }
    return _value;
}//end roleFormatter
const statusFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return "enabled";
    }
    return _value;
}//end statusFormatter
/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/
export class DriverTab extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        // this.onLoadingState = this.onLoadingState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.setComment = this.setComment.bind(this);
        this.updateComment = this.updateComment.bind(this);
        this.cancelComment = this.cancelComment.bind(this);
        this.submitComment = this.submitComment.bind(this);
        this.handleCommentDelete = this.handleCommentDelete.bind(this);

        this.state = {
            shown: true,
            addingComment: null,
            comments: [],
            driverid: this.props.driverid,
            driverPhoto:this.props.photo,
            currentUsername:null,
            driverName: null,
            ein:null,
        };
    }
    /*
    * A handler used by the ApiCaller
    * 
    // * Currently this state is not used
    // */
    // onLoadingState(state) {
    //     this.setState({loadingState: state});
    // }
    componentDidMount() {
        //  console.log("Open Driver Details: ",this.props,this.state );
        this.getApiCall();
    }

    
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        // console.log("Fetch overview: ",this.props);
        const realPromise = Auth.currentSession().then(
        (auth) => {
            this.setState({currentUsername: auth.idToken.payload['cognito:username']});


            let apiName = "TrifectaAPI";
            let path = "/handleDriverInfo";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    clientid: this.props.clientid,
                    driverid: this.props.driverid,
                    photo: this.props.photo,
                    mode: 'fetch',              
                }
            };
            return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("UserManagent api call; ",error); 
        }); 
        realPromise.then(data => {
            this.updateData(data);
        });
        const notePromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleNote";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    driverid: this.props.driverid,
                    mode: 'fetch',              
                }
            };
            return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("UserManagent api call; ",error); 
        }); 
        notePromise.then(data => {
            this.updateData(data);
        });
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        // console.log("DriverInfo : ",data);
        if(data.driverinfo ){
            //Extract the photo
            let stateReturn={}
            if(data.driverinfo.photo){
                let photoData = data.driverinfo.photo;
                if(!photoData.includes('data:image')){            //Is it tagged?
                    console.log("Attach tag")
                    photoData = 'data:image/jpeg;base64,'+data.driverinfo.photo;
                }
                stateReturn.driverPhoto = photoData;
            }

            stateReturn.driverName = data.driverinfo.name;
            stateReturn.driverNameOriginal = data.driverinfo.name;
            stateReturn.ein = data.driverinfo.ein;
            
            this.setState(stateReturn);
        }
        if(data.notes){            
            //Iterate over the returned SQL data, format it to match the display
            let notes  = (data.notes||[]).map(note_=>{
                return({
                    text:note_.text,
                    timestamp:note_.created,
                    username:note_.username,
                    noteid:note_.noteid,
                })
            })
             this.setState({comments:notes});
            
        }
    }
    
    //callback when a new action has been added
    handleSubmit(){
        //collect the data that needs to be saved:

        //Check if the name has been changed
        if(this.state.driverNameOriginal !== this.state.driverName){
            console.log("Name changed: ",this.state.driverName)
        }

         //Send the comment to the Database
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleDriverInfo";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    clientid: this.props.clientid,
                    driverid: this.props.driverid,
                    drivername: this.state.driverName,
                    ein:this.state.ein,
                    mode: 'updateName',              
                }
            };
            console.log("Send: ",myInit);
            return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("Submit comment api call; ",error); 
        })
        .then( _data =>{
            console.log("Submit returned ",_data)
            this.props.handleClose(this.state,true);
        });
        return;
    }

     //callback when a comment is deleted
     handleCommentDelete(_data){
        //collect the data that needs to be saved:
        console.log("Comment deleted: ",_data)

        //Get the comments from the the current state
        const comments = this.state.comments;
        //remove the current referenced comment:
        let filtered = (comments || []).filter(comment_=>{ return comment_.noteid !== _data.noteid})

        this.setState({comments:filtered});

        //Remove the comment from the database
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleNote";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    noteid: _data.noteid,
                    mode: 'delete',              
                }
            };            
            return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("Delete comment failed api call; ",error); 
        }); 
        return;
    }
    

    /*
    * @brief A callback for when the currently-being-added comment is updated
    */
    setComment(comment) {  this.setState({addingComment: comment});  }
    /*
    * @brief A callback for when the currently-being-added comment is updated
    */
    updateComment(_comment) {
        return;
    }
    /*
    * @brief A callback for when the currently-being-added comment is cancelled
    */
    cancelComment() {
        this.setState({addingComment: null});
    }
    
    /*
    * @brief A callback for when the currently-being-added comment is finished
    */
    submitComment() {

        const toAdd = this.state.addingComment && this.state.addingComment.trim();
        if (toAdd) {
            const comments = this.state.comments.slice();
            if(this.state.currentUsername){
                comments.push({text: toAdd, timestamp: moment().utc().format(),username: this.state.currentUsername});
            }else{
                comments.push({text: toAdd, timestamp: moment().utc().format()});
            }
            console.log("Comments:" ,comments);
            this.setState({addingComment: null, comments: comments});

            //Send the comment to the Database
            const realPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleNote";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        text: toAdd,
                        timestamp: moment().utc().format(),
                        driverid: this.props.driverid,
                        mode: 'submit',              
                    }
                };
                console.log("Send: ",myInit);
                return API.post(apiName, path, myInit);
            }
            )
            .catch((error) => {
                console.error("Submit comment api call; ",error); 
            }); 


        } else {
            this.setState({addingComment: null});
        }
        var that = this;
        window.setTimeout(() => {         that.setState({timestamp:moment()});          }, 250);

        

        return;
    }
    
    /*
    * @brief Render the content of the card:
    */
    render() {
       
               
        //Handle what happens when the user click off the card
        const confirmClose = () => {
            this.props.handleClose(this.state,false);
        }//end confirmClose
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }


        //Get a list of comments:
        let disableEdits = true;

        let comments = this.state.comments.map((comment,index_) =>             
            <Comment {...comment} key={index_} cardid={this.props.cardID} infractionID={this.props.infractionID} updateComment={this.updateComment} currentUser={this.state.currentUsername} 
                        commentindex={index_} totalCount={this.state.comments.length} disableEdits={disableEdits} deleteCallback={this.handleCommentDelete}/>
        ).reverse();    
        
        let bAllowEdits = true;
        let driverPhoto = <img className="driverphoto" src={this.state.driverPhoto } alt=''  /> 
        // let driverPhoto = <img className="gpsmap" src={blankAvatar} alt=''  /> 

        //Determine what is shown for the driver name 0-nothing, 1-read only, 2-read/write
        let driverNameStyle = 2;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.drivername ==='read'){driverNameStyle=1;}
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.drivername ==='write'){driverNameStyle=2;}
        
        // console.log("DriverNameStyle: ",driverNameStyle);
        return (
            <div className="drivercard" style={style}>
                    <div className="drivertab-content" style={style} >
                        <div className = 'sc-title'>
                            Driver {this.props.driverid}
                        </div>
                        <img className="hamburger" src={hamburger}  alt=''/>
                        <div className='dc-top'>
                            
                            <div className='dc-tl'>
                                <div className="soc-spec">
                                    
                                    {/* <div className="title-wide">Driver Ranking</div>
                                    <input type="text" id="regionInput" className='entry-field-wide' placeholder="PENDING"
                                        value={this.state.region}
                                        onChange = {(e)=>this.setState({region:e.target.value})}
                                        // disabled={this.state.mode==='edit'?true:false}
                                        disabled={true}
                                    />                             */}
                                </div>
                                {driverNameStyle > 0?                            
                                    <div className="soc-spec">
                                        <div className="title-wide">Assigned Name</div>
                                        <input type="text" id="regionInput" className='entry-field-wide'
                                            value={this.state.driverName||''}
                                            onChange = {(e)=>this.setState({driverName:e.target.value})}
                                            disabled={driverNameStyle<2?true:false}
                                            autoComplete="off"
                                        />               
                                    </div>
                                    :
                                    <div className="soc-spec"></div>                                
                                }
                                {driverNameStyle > 0?                            
                                    <div className="soc-spec">
                                        <div className="title-wide">Employee Number</div>
                                        <input type="text" id="regionInput" className='entry-field-wide'
                                            value={this.state.ein||''}
                                            onChange = {(e)=>this.setState({ein:e.target.value})}
                                            disabled={driverNameStyle<2?true:false}
                                            autoComplete="off"
                                        />               
                                    </div>
                                    :
                                    <div className="soc-spec"></div>                                
                                }

                                { comments.length > 0 && 
                                    <div>
                                        <div className="comments-title">Notes</div>
                                        <div className="comments">
                                            {comments}
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className='dc-tr'>
                                    {driverPhoto}   
                            </div>
                        </div>

                        
                        <div className="dc-footer">
                                <button className="btn btn-danger" onClick={
                                    confirmClose}>Cancel</button>
                                <button className="btn btn-primary" 
                                    onClick={this.handleSubmit}
                                >Submit</button>
                            {/* {this.state.addFormOpen?null:<div className="blank-spacer"  />} */}
                        </div>

                        <Commenter comment={this.state.addingComment}
                                disableEdits={this.props.noEdit}
                                setComment={this.setComment}
                                cancelComment={this.cancelComment}
                                submitComment={this.submitComment}
                                addLabel = 'Add Note'
                                submitLabel = 'Submit Note'
                                cancelLabel = 'Cancel Note'
                        />
                              
                    </div>
            </div>
        );
    }
}//end DriverCard
