import React, { Component } from 'react';

import { Auth, API } from 'aws-amplify';
import axios from 'axios';

import {Spinner } from '../ApiCaller.js';

import { displayStatus,OTHERHWTAGS} from '../Util.js';
import { CallinFiltersView } from '../Filters/CallinFilter.js';
import { setupPerf } from '../Perf.js';

import * as moment from 'moment';
import './CallinsView.css';
// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';


import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { Card } from '../VideoCard.js';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import {siteFormatter,notecardFormatter,noteFormatter,dateFormatter,CallFetchingDialog} from './CallinsUtility.js'
import { formatAssetName } from '../Util-asset.js';
import { setupBenchmark,perfQuery } from '../Util/benchmark.js';

const REFRESHTIME = 30*1000;

/*
* @brief A compenent that can list all calls made in a table
*/
class CallinsView extends Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.executeQuery = this.executeQuery.bind(this);
    this.onLoadingState = this.onLoadingState.bind(this);
    this.formatSecondsTime = this.formatSecondsTime.bind(this);

    this.cardChange = this.cardChange.bind(this);
    this.cardTag = this.cardTag.bind(this);
    this.expandCard = this.expandCard.bind(this);

    this.onFilterSelected = this.onFilterSelected.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.sanitizeData = this.sanitizeData.bind(this);

    this.getNoTable = this.getNoTable.bind(this);
    this.processData = this.processData.bind(this);
    this.workerMessage = this.workerMessage.bind(this);

    

    this.state = {
        mTableData: [],
        mTableHeaders:[],
        retryCount: 0,
        expandedCard: null,
        activeView: 'callin',
        tabViews: [{key:'callin', name:'All'},{key:'status', name:'Camera + IR Status Call-ins'}],
        refreshTimeoutID:null,
        startDate:this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-7, 'days'),
        endDate:null,
        filters: {
          // sites: new Set(this.props.possibleFilters.gpsSites),
          // asset: new Set((this.props.possibleFilters.AssetsTrifecta||[]).map(elem_=>{return elem_.asset})),
          asset: new Set(this.props.possibleFilters.AssetsTrifecta||[]),
          driver: new Set((this.props.possibleFilters.DriverID||[])),
        },
        selectedFilters: {
          startDate:this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-7, 'days'),
        },
        activeFilters: {
          startDate:this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-7, 'days'),
          // asset:'TK518'
        },
        filterKeys : {
          'asset': {
              'title': 'Asset',
              order: 1
          },
          'infraction': {
              title: 'Clip',
              order: 2
          },
          'hwtags': {
              title: 'Hardware',
              order: 3
          },          
          'site': {
            'title': 'Site',
            order: 4
          },
          'e3person':{
            'title': 'EDGE3 Person',
            order: 5
          }
        },
        pageLoad: new Date(),
        timeSinceQuery: new Date(),
        apiReturned: false,
        displayData: [],
        callGroups: {},
        waitingForDownload: false,
        filterUpdateTime: new Date(),
        callinWorker: new Worker('../workers/processCallIns.js', { type: 'module' }),
        unfulfilledPromises: [],
        daysToFetch: 0,
        daysFetched: 0,
        fetchingState: 0,
        queryTimeOuts:[],
    };
    setupBenchmark(this, 'CallinsView');
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }

  componentDidMount(){
    
    //Update the worker:
    if(this.state.callinWorker){
      this.state.callinWorker.onmessage = this.workerMessage;
      this.state.callinWorker.onerror = (error)=>{console.log("Failed to process worker error: ",error)};
    }


    this.setState({refreshInterval:setInterval(() => {

          if((new Date() - this.state.timeSinceQuery) > REFRESHTIME){
            if(this.state.unfulfilledPromises.length===0 && (this.state.daysFetched >= this.state.daysToFetch)){
              // console.log("need to refresh the page: ");
              this.setState({timeSinceQuery: new Date(),retryCount: this.state.retryCount+1},this.getApiCall);
            }
          }
        },REFRESHTIME*2) 
    });

    // if(this.state.unfulfilledPromises.length===0 && (this.state.daysFetched >= this.state.daysToFetch)){
    //   console.log("Set up timer to refresh current date 1");
    //   toPost.refreshTimeoutID= setTimeout(() => {this.setState({timeSinceQuery: new Date(),retryCount: this.state.retryCount+1},this.getApiCall);}, REFRESHTIME)
    // }

    //Trigger our first API call: 
    this.getApiCall();


    let allCols = {};

    let cols = [
      {dataField: 'cardData', text: 'Notecard',editable:false,sort:true,formatter: notecardFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'subject', text: 'Subject',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'asset', text: 'Assets',editable:false, sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},            
      {dataField: 'username', text: 'EDGE3 Person',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'contactname', text: 'Contact',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'site', text: 'Site',editable:false,sort:true,formatter: siteFormatter,formatExtraData:this.props.possibleFilters,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},      
      {dataField: 'timeofday', text: 'Notecard Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'calltime', text: 'Call Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'callnotes', text: 'Notes',editable:false,sort:true,formatter: noteFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
    ];
    let colsStatus = [
      {dataField: 'cardData', text: 'Notecard',editable:false,sort:true,formatter: notecardFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'subject', text: 'Subject',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'asset', text: 'Assets',editable:false, sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},            
      {dataField: 'username', text: 'EDGE3 Person',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'contactname', text: 'Contact',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'site', text: 'Site',editable:false,sort:true,formatter: siteFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},      
      {dataField: 'timeofday', text: 'Notecard Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'calltime', text: 'Call Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      {dataField: 'callnotes', text: 'Notes',editable:false,sort:true,formatter: noteFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
    ];

    allCols['callin'] = cols;
    allCols['status'] = colsStatus;


    this.setState({mTableHeaders:allCols});
  }
  componentWillUnmount(){
    if(this.state.refreshTimeoutID){clearTimeout(this.state.refreshTimeoutID);}
    if(this.state.refreshInterval){clearInterval(this.state.refreshInterval);}
    if(this.state.callinWorker){this.state.callinWorker.terminate();}    
  }

  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  executeQuery(_setData,_siteList) {

    // console.log("Execute query on: ",new Date() - this.state.pageLoad,_setData);
    Auth.currentSession().then(
      (auth) => {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
          let apiName = "TrifectaAPI";
          let path = "/handleCallLog";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  Sites: _siteList.length>0?_siteList.join(','):null,
                  clientid: this.props.groupconfig.group,
                  mode:'fetch',  
                  filters:_setData,   
                  possibleInf:this.state.filters.infraction?[...this.state.filters.infraction]:null,             
                  possibleHWT:this.state.filters.hwtags?[...this.state.filters.hwtags]:null,             
              }
          };
          let apiPromise= API.post(apiName, path, myInit);
          //Record the promise in the list of waiting to complete
          this.setState(prevState => {
            const promises = prevState.unfulfilledPromises;
            promises.push(apiPromise);
            return{unfulfilledPromises:promises}
          });  
            

          // this.setState({apiReturned:false, timeSinceQuery: new Date()})
          apiPromise.then(data => {
            this.setState(prevState => {
              // console.log("Promises: ",prevState.unfulfilledPromises)
              const promises = prevState.unfulfilledPromises;
              const index = (promises||[]).indexOf(apiPromise);
              if (index > -1) { // only splice array when item is found
                // console.log("Found the promise: ",index)
                promises.splice(index, 1);
              }
              return{unfulfilledPromises:promises}
            }); 
            this.updateData(data)
          });
          apiPromise.catch(err => {
            if (API.isCancel(err)) {
              console.log("API canceled: ", err.message); // "my message for cancellation"
              // handle user cancellation logic
            }else{console.log("error on return: ",err)}
            return;
          });

    });//end auth promise

  }
 
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
    perfQuery(this,"CallLogs","start");
    // console.log("Calling API with: ",this.state.retryCount,this.state.activeFilters)
    let siteList = [];
    //Check if the site are restricted: 
    if(this.props.possibleFilters && this.props.possibleFilters.Sites 
        && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
        //Extract the name of the sites from the site,gps pair
        (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } else{ //otherwise use the allowed sites from the client
      if(this.props.possibleFilters && this.props.possibleFilters.Sites){
        //Extract the name of the sites from the site,gps pair
        (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } 

    }

    //Define the filters to use in the API query (copy from the selected filters)
    let filtersToPass = JSON.parse(JSON.stringify(this.state.activeFilters));
    //Is this the first query, or have the filters changed? (retryCount should be 0)
    if(this.state.retryCount!==0){ //if it is not 0, then this is a short refresh query
      if(filtersToPass.endDate){
        this.setState({timeSinceQuery:new Date()});
        return;//don't continue the refresh query
      }
      filtersToPass.startDate = moment().subtract(1, 'days');
    }

    //Don't allow the end date to be defined without the start date
    if(!filtersToPass.startDate && filtersToPass.endDate){      
      // console.log("No Start date: ");
      let mTemp = this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-7, 'days');
      filtersToPass.startDate = mTemp.toISOString();
    }

    let mStartDate = moment(filtersToPass.startDate);
    let mEndDate = null;

    //Sanity check the dates:
    if(filtersToPass.startDate && filtersToPass.endDate){
      mEndDate = moment(filtersToPass.endDate);

      if(!mStartDate.isBefore(mEndDate)){
        // console.log("Dates are wrong: ",filtersToPass);
        //swap the dates
        let tmpVal = filtersToPass.endDate;
        filtersToPass.endDate = filtersToPass.startDate;
        filtersToPass.startDate = tmpVal;

        //Update the start and end dates in the moments
        mStartDate = moment(filtersToPass.startDate);
        mEndDate = moment(filtersToPass.startDate);

      }
    }

    //Reset the filters states: (only do this if this query was triggered by the apply button)
    if(this.state.retryCount===0){ //if it is not 0, then this is a short refresh query
      this.setState({ activeFilters: JSON.parse(JSON.stringify(filtersToPass)),
        selectedFilters: JSON.parse(JSON.stringify(filtersToPass)),
        startDate:moment(filtersToPass.startDate),
        endDate:filtersToPass.endDate?moment(filtersToPass.endDate):null,
      });
    }
    



    if(!mEndDate){ mEndDate = moment();  }
    mStartDate.startOf('day');
    mEndDate.startOf('day');
    // console.log("Filters to pass: ",filtersToPass);
    let daysInFilter = moment.duration((moment(mEndDate)).diff(mStartDate)).asDays();
    // console.log("Days in filter: ",daysInFilter,filtersToPass,mStartDate,mEndDate);
    filtersToPass.daysInFilter = daysInFilter;

    let setsToProcess=[];
    //Check groups:
    if(daysInFilter > 7){
      this.setState({daysToFetch:daysInFilter, daysFetched:0}); //set the days to fetch to display the progress bar

      setsToProcess=[];
      let daysPerSet = 1;
      let setCount = 3; //default to 3 sets
      if(daysInFilter > 14){
        setCount = Math.ceil(daysInFilter/7); //max of 7 days per set
      }
      daysPerSet = Math.ceil(daysInFilter/setCount);
      //Create sets:
      let endSetDate = moment(mStartDate);
      let setStartDate = moment(mStartDate);
      
      while(endSetDate.isBefore(mEndDate)){        
        endSetDate = moment(setStartDate).add(daysPerSet-1, 'days');
        // console.log("Consider dates: ",moment(setStartDate).toISOString(),moment(endSetDate).toISOString() )
        
        let newSet = JSON.parse(JSON.stringify(filtersToPass));
        newSet.startDate = setStartDate.toISOString();
        // let newSet = Object.assign(filtersToPass,{startDate:setStartDate.toISOString()})
        // let newSet = {startDate:setStartDate.toISOString()}
        //Check if the step overshot the desired end date:
        
        if(endSetDate.isSameOrAfter(mEndDate)){
          newSet.endDate = mEndDate.toISOString();
          newSet.daysInFilter = moment.duration((moment(mEndDate)).diff(setStartDate)).asDays()+1;
        }else{
          newSet.endDate = endSetDate.toISOString();
          newSet.daysInFilter = moment.duration((moment(endSetDate)).diff(setStartDate)).asDays()+1;
          //Update to start next loop
          setStartDate = moment(endSetDate).add(1,'days');          
        }
        //Add to the sets to pass to the API:
         
        setsToProcess.push(newSet);
      }
      // console.log("Sets: ",daysPerSet,setsToProcess);
    }else{
      setsToProcess.push(filtersToPass);
    }



    //Run through the set of dates in reverse, to fetch the most recent first:
    setsToProcess = setsToProcess.reverse();
    
    let queries = setsToProcess.map((set_,idx) => {
      //Fire off the timeouts one at a time, to prevent overloading the API call, or the webworker
      let value = setTimeout(() => {this.executeQuery(set_,siteList); }, 1250*idx)  ;      
      return value;
    });//end the map
    
    this.setState({queryTimeOuts:queries,timeSinceQuery:new Date()});
    //  console.log("Queries: ",queries);

  }


  expandCard(_inCard) {
      
      // console.log("Expand click: ",_inCard);
     
      const realPromise = Auth.currentSession().then(
        (auth) => {
            let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                apiName: "getCardFromInfractionID",
                mode: "fetch",
                infractionid: _inCard.infractionID || _inCard.infarctionid,
            }
            };
            return API.post("AuthLambda", "/apiRouter", myInit);
        });
        //When the data is returned arrange it into a basic card and open the card:
        realPromise.then(data => {
            // console.log("Notecard fetch returned: ",data,data.data);
            (data.data || []).forEach( (elem_) =>{
                // console.log("Notecard fetch returned: ",elem_);
                let infractionTagArray = elem_.infractiontags || [];
                if(elem_.infractiontags ){
                    infractionTagArray = elem_.infractiontags.split(",");
                }
                // console.log("Open card: ",elem_)
                const card = {
                    tag: elem_.tag,
                    infractionType: elem_.infractiontype,
                    infractionID: _inCard.infractionID || _inCard.infarctionid,
                    status: 'FleetReview',
                    severity: elem_.severity,
                    // timeReceived: moment(this.props.parentVideo.received),
                    // timeCreated: moment(),
                    cardID: elem_.videocardid,
                    id: elem_.parentid,
                    flags: elem_.flags,
                    vehicleID: _inCard.vehicleID,
                    siteID: elem_.siteid,
                    notes: "",    
                    name: (elem_.name || elem_.driverid || "DriverID: Unavailable").replace('_', ' '),
                    // name: elem_.driverid,
                    infractionTags : infractionTagArray,
                    timeReceived: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                    timeOfDay: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                  };
                this.setState({expandedCard:card});
            });
        });
  }


  //Parse a row of the API data return, format the row for table display
  parseRow(_row,_callGroups){
    // console.log("Row to parse: ",_row);
    try {

      //apply the site timezone to the callintime
      if(_row.calltime){
        try {
          
          let timezoneRow = (this.props.possibleFilters.Sites || []).filter(row_=>{  return row_.site.toLowerCase() === _row.site.toLowerCase() })
          timezoneRow = timezoneRow[0];
          //  console.log("Timezone: ",row.calltime,timezoneRow.timezone,moment(row.calltime),moment(row.calltime).tz(timezoneRow.timezone).format('YYYY-MM-DD HH:mm:ss'))
          //Convert the timestamp to the local time of the site:
          _row.calltime = moment(_row.calltime).tz(timezoneRow.timezone).format('YYYY-MM-DD HH:mm:ss');
          _row.timezone = timezoneRow.timezone; 
        } catch (error) {
          
        }
      }

      //Create the notecard data
      if(_row.timeofday){
        _row.notecard = <Card  
          infractionID={_row.infractionid}
          name={(_row.name || _row.driverid || "DriverID: Unavailable").replace('_', ' ')}
          timeofday={_row.timeofday}
          severity={_row.severity}
          notes={_row.notes}
          vehicleID = {_row.asset}
          metadata ={_row.metadata}
          photo={_row.thumbnail}
          status={_row.status}
          infractionTags={_row.infractiontags?_row.infractiontags.split(','):[]}
          tag={_row.tag}
          groupconfig={this.props.groupconfig}
          onDoubleClick={this.expandCard}            
        />  
      }
      // if(!_row.infractiontags &&_row.timeofday){
      //   console.log("Missing infractiontags: ",_row.infractiontags,_row)
      // }
      

      _row.key = _row.id+"_"+_row.infractionid+"-key";


      //Create the groupingid:
      let groupingid = _row.subject + _row.calltime;
      if(_row.infractionid){
        groupingid = _row.infractionid + _row.callnotes; //this will catch when calling for a card and no answer is chosen
      }

      
      //insert into an array if it doesn't already exist
      if(!_callGroups[groupingid]){
        _callGroups[groupingid]={
          subject: _row.subject,
          asset: _row.asset,
          username: _row.username,
          notecard: _row.notecard,
          site: _row.site,
          timeofday: _row.timeofday,
          calltime: _row.calltime,
          callnotes: _row.callnotes,
          key: _row.key+"-group",
          set:[],
          contactname:_row.contactname,
          infractiontags:_row.infractiontags?_row.infractiontags.split(','):[],
        }
        _callGroups[groupingid].set.push(_row);
      }//end insert into set
      else{ //add to the list
        _callGroups[groupingid].set.push(_row);
      }


      return _row;
      
    } catch (error) {
      console.log("Failed to parse row: ",error, _row);
      return {};
    }
    

  }

  //Parse a row of the API data return, format the row for table display
  processsCallGroups(key_, row_){
    try {
      //set the call-time based on the first entry:
      row_.groupid = key_;
      if(row_.set && row_.set.length>1){
        //sort the times:
        row_.set = row_.set.sort((a, b) => {
          if (a.calltime < b.calltime) {  return -1;  }
          if (a.calltime > b.calltime) { return 1;}
          return 0;
        });
        // console.log("Group entry: ",row_);
        //Select the earliest call attempt to display          
        row_.calltime = row_.set[0].calltime;

        //Look at the time between calls:        
        for (let i = 1; i < row_.set.length; i++) {
          try {
            //Compate the time between calls:
            let timeDiff = moment.duration(moment(row_.set[i].calltime).diff(moment(row_.set[i-1].calltime))).asSeconds();
            // console.log("Time diff: ",timeDiff,row_.set[i].calltime,row_.set[i-1].calltime)
            if(timeDiff > (7*60)){ //15 minutes to mark as red
              row_.set[i].alertflag =1;
            }
            // console.log("Time diff: ",timeDiff,row_.set[i].calltime)  
          } catch (error) {
            console.log("Fail on time compare: ",error);
          }
        } //end loop over set
      }//end check if the set is defined
            
      return row_;
    } catch (error) {
      return {};
    }
  }

  processData(_data,_bFromFile){

    if(_bFromFile){console.log("Load data from file: ",_data.length);}

    this.setState(prevState => {          

      try {
        let callGroups = Object.assign({}, prevState.callGroups);
        // let displayData = [...prevState.displayData];

        // let callGroups = {};
        let displayData = [];
  
        //Iterate over each call from the SQL table
        // const processedData = data.data.map(row => {
          let startParse = new Date();
        _data.forEach(row => {
          this.parseRow(row,callGroups); //process the raw API data, data is grouped into the callgroups
        });
        console.log("Parse update: ",new Date() - startParse);
  
        // console.log("Call Groups: ",callGroups,Object.keys(callGroups).length);
  
        let startGroup = new Date();
        //Iterate over the call groups, extract the call-time of the earliest entry:
        // const groupedData = (callGroups||[]).map(row_ => {
        for(const [key_, row_] of Object.entries(callGroups)){    
          displayData.push(this.processsCallGroups(key_,row_)); //flatten the set into an array
        }
        console.log("Group update: ",new Date() - startGroup);


        let startStatus = new Date();
        let otherHWTagsType = OTHERHWTAGS.map(a => a.type);
        //Filter the data that should be shown on the hardwware tab:
        const statusData = (displayData||[]).filter(elem_=>{
          //Get tags that aren't in the infraction set
          try {
            if(this.props.groupconfig && this.props.groupconfig.infractionTags.length>0){
              //Get the list of tags
              let tagArray =  elem_.infractiontags;//elem_.infractiontags?elem_.infractiontags.split(','):[];
              // let infTags = this.props.groupconfig.infractionTags.map(value_ =>{return value_.type})
              //  console.log("Tags:" ,elem_,tagArray)
  
              let matchList = tagArray.filter(tag_=>{
                // console.log("Test tag: ",tag_,tag_.length,infTags,!infTags.includes(tag_));
                return otherHWTagsType.includes(tag_)
              });
              // console.log("Other tags:" ,test1);
              return matchList.length>0;
            }  
          } catch (error) {
              console.log("Error? ",error,elem_);
          }
        })
        // console.log("Status array: ",displayData,statusData);
        console.log("Status update: ",new Date() - startStatus);

  
  
        let tableData = {};
        tableData['callin']=displayData;
        tableData['status']=statusData;
        // // console.log("Set table:" ,tableData)
        // this.setState({mTableData:tableData});  
        //Update the state variable so that we can append with new query:
        
        if(_bFromFile){
          return{displayData:displayData, callGroups:callGroups,mTableData:tableData,waitingForDownload:false}
        }else{
          return{displayData:displayData, callGroups:callGroups,mTableData:tableData}
        }
        
      } catch (error) {
        console.log("Failed to process data: ",error);
      }

      
      
    }, ()=>{
       console.log("Data returned and processed: ",new Date() - this.state.timeSinceQuery)
    });  

  }

  // The worker has returned from processing the call-ins data returned by the API
  workerMessage(_data){
    let msgData = _data.data;
    // console.log("Worker message returned: ",msgData);
    // console.log("Worker total time: ",new Date() - new Date(msgData.timeStart));
    // console.log("  --get: ",msgData.timeRecv - new Date(msgData.timeStart));
    // console.log("  --process: ",msgData.timeEnd - msgData.timeRecv);
    // console.log("  --send: ",new Date() - msgData.timeEnd);

    //Update the cards:
    //  let cardStart = new Date();
    (Object.values(msgData.table)||[]).forEach( (table_) =>{
      try {
        
        //Update formatting on each row
        (table_ || []).forEach( row_ =>{
          //Create a card for each entry that has card data:
          
          if(row_.notecardData){
            if(row_.notecardData.timeOfDay){ //format the timestamp if found
              row_.notecardData.timeOfDay = moment.parseZone(row_.notecardData.timeOfDay);
            }
            // if(row_.cardData){
            //   console.log("Already exists? ", row_, table_.length);
            // }else{
              row_.cardData = <Card {...row_.notecardData}  groupconfig = {this.props.groupconfig} onDoubleClick = {this.expandCard}/>
            // }
            
          }
        });
      } catch (error) {
        console.log("Error in table: ",error, table_);
      }
        
    });//end processing each element of the table
    //  console.log("Add cards to set: ",new Date() - cardStart,msgData.table);

    //Update the local state with the new data:
    //Create the JSON structure to send to update the state
    let toPost ={
      callGroups: msgData.callGroups,
      mTableData: msgData.table,
      apiReturned: true,            
    }

    

    //Clear the flag if processing the file data:
    if(msgData.bFromFile){toPost.waitingForDownload= false}

    if(this.state.waitingForDownload){//Split query was executed..
      if(msgData.bFromFile){ //only update state after the file download has completed
        this.setState(toPost);
      }
    }else{
      this.setState(toPost); 
    }
    
  }

  sanitizeData(_data){

    for(const data_ of (_data||[])) {
      // console.log("Entry: ",data_);
      try {
        data_.asset = formatAssetName(this.props.possibleFilters.AssetList,data_.asset,data_.asset);  
      } catch (error) {}
      
    }
    return _data;
  }
 
  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(data) {
    //  console.log("Callin Data: ",data,this.props);
    // console.log("Data returned: ",new Date() - this.state.timeSinceQuery,data,data.data.length)
    if(this.state.fetchingState!==0){this.setState({fetchingState:0});}//Data is starting to return

    try {
      //Pass the data from to populate the available filters
      // let startFilter = new Date();
      this.updateFilters(data.data);
      // console.log("Update filters: ",new Date() - startFilter);

      let toSend = {
        data: this.sanitizeData(data.data),      
        bFromFile: false,
        callGroups: this.state.callGroups,
        timeStart: new Date(),
        otherHWTagsType: OTHERHWTAGS.map(a => a.type),
        groupconfig: this.props.groupconfig,
        sites: this.props.possibleFilters.Sites,
      }
      // console.log("Send updated post to worker: ",this.state.callinWorker);
      this.state.callinWorker.postMessage(JSON.stringify(toSend));  

      //Handle data received from the file:
      if(data.file){
          //Need to notify the class that we are still waiting for a file to download and process (waitingForDownload)
          this.setState({waitingForDownload:true})
          axios.get(data.file)
          .then(res => {
            console.log("Process file data: ",res.data.length);
            let toSend = {
              data: this.sanitizeData(res.data),      
              bFromFile: true,
              callGroups: this.state.callGroups,
              timeStart: new Date(),
              otherHWTagsType: OTHERHWTAGS.map(a => a.type),
              groupconfig: this.props.groupconfig,
              sites: this.props.possibleFilters.Sites,
            }
            this.state.callinWorker.postMessage(JSON.stringify(toSend));
              // this.processData(res.data,true);
          })
      }

    } catch (error) {
      // console.log("Failed to ",error);
    }
    //Update the progress bar:
    if(data.filters && data.filters.daysInFilter && this.state.daysToFetch > 0){
      let splitCount = Math.floor(data.filters.daysInFilter/2);
      setTimeout(() => {
        this.setState(prevState => {
          const daysFetched = prevState.daysFetched;
          return{daysFetched:daysFetched+splitCount}
        });  
      }, 250);
      setTimeout(() => {
        this.setState(prevState => {
          const daysFetched = prevState.daysFetched;
          return{daysFetched:daysFetched+(data.filters.daysInFilter-splitCount)}
       });//end call to setState()
      }, 1000);      
    }
    
   
    perfQuery(this,"CallLogs","end");
    // console.log("Waiting: ",this.state.unfulfilledPromises);
  }//end updateData

  

  updateFilters(_data){
    // console.log("Load filter update: ",_data);
    const filters = {...this.state.filters}
    // console.log("Loaded filters: ",filters,this.state.filters,{...this.state.filters});
    try {
      // console.log("Returned sites: ",this.state);
      filters['site'] = filters['site'] || new Set();
      filters['e3person'] = filters['e3person'] || new Set();
      for (var row_ of (_data||[])) {
          if(row_.site){
            //Match against the sitedetails list:
            try {
              if(this.props.possibleFilters.Sites){
                let siteDetail = (this.props.possibleFilters.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === row_.site.toLowerCase()})
                // console.log("Site detail: ",siteDetail,siteDetail[0].alias);
                if(siteDetail){
                  //scan the set and compare the objects, add if not found in the set
                  let uniqueSite = true;
                  for (const entry_ of filters['site']) {
                    if (entry_.site && entry_.site=== siteDetail[0].site) { uniqueSite = false;  break; }
                  }
                  //If not in the set already, then add to the set:
                  if(uniqueSite){   filters['site'].add(siteDetail[0]);  }
                  
                }
              }  
              else{
                //scan the set and compare the objects, add if not found in the set
                let uniqueSite = true;
                for (const entry_ of filters['site']) {
                  if (entry_.site && entry_.site=== row_.site) {  uniqueSite = false;   break;  }
                }
                //If not in the set already, then add to the set:
                if(uniqueSite){ filters['site'].add({site: row_.site, alias: row_.site});    }
              }
            } catch (error) {
              
            }
            
            
            filters['e3person'].add(row_.username);  
          }
          
      }

      // console.log("Sites:" ,filters);

      //Add the infraction types:
      // const INFRACTION_FILTER_FIXED_OPTIONS =
      // [
      //     {text: "All", value: "no-infraction-selected"},
      // ];
        
      const possibleInfractions = (this.props.groupconfig.infractionTags || []).map(tag_ => {
          return {text: tag_.type, value: tag_.type};
      });
      //Get the list of alerts to display from the groupconfig alerttypes
      // const possibleAlerts = (this.props.groupconfig.alerttypes || []).map(tag_ => {
      //     return {text: tag_, value: tag_};
      // });
      // //Get the list of hardware types:
      // const hwtags = (OTHERHWTAGS || []).map(tag_ => {
      //     return {text: tag_.type, value: tag_.type};
      // });
      // // Combine the alerts and infractions together
      // const infractionOptions = possibleInfractions.concat(hwtags);
      // filters['infraction']= new Set((infractionOptions||[]).map(elem_ => {return elem_.value}));
      filters['infraction']= new Set((possibleInfractions||[]).map(elem_ => {return elem_.value}));

      //Fill in the hwtags:
      try {
        filters['hwtags'] = filters['hwtags'] || new Set();
        filters['hwtags'].add("None");    
        for (const tag_ of OTHERHWTAGS) {
            if(tag_.type!==" "){
                // filters['hwtags'].add({text: tag_.type, value: tag_.type});    
                filters['hwtags'].add(tag_.type);    
            }
          }
      } catch (error) {
      }

      //  console.log("Filters to update:",filters)
      this.setState({filters:filters});


    } catch (error) {
      console.log("Failed to fetch site? ",error);
    }
  }

  cardChange(_inCard,_noShow) {
    // console.log("Card change: ",_inCard,_noShow);
    this.props.cardChange(_inCard);
  }
  
  
  /* @brief handle the callback when a tag is changed in the notecard.
  * This refreshes the displayed cards in the list
  */
  cardTag(_inCard,_noShow) {
    // console.log("cardTag: ",_inCard);
  }

  applyFilters(){
    // console.log("Apply button pressed: ",this.state.selectedFilters)
    //cancel the timeout:
    if(this.state.refreshTimeoutID){clearTimeout(this.state.refreshTimeoutID);}
    //set the selected filters to active:
    // console.log("Apply filters:", JSON.parse(JSON.stringify(this.state.selectedFilters)))
    this.setState({ activeFilters:JSON.parse(JSON.stringify(this.state.selectedFilters)),retryCount: 0,
                      apiReturned:false, timeSinceQuery:new Date(), fetchingState:1,
                      callGroups:{}, mTableData:[],
                  },()=>{this.getApiCall()});
  }

  onFilterSelected(name, value){
    // console.log("Filter selected: ",name, value)
    try{
      let toSet = value.value;
      switch(name){
          case 'asset':
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{asset: toSet})});
          break;
          case 'infraction':
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{infraction: toSet})});
          break;
          case 'hwtags':
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{hwtags: toSet})});
          break;
          case 'driver':
              if(value.value === 'no-driver-selected'){toSet=null}
              this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{driver: toSet})});
          break;
          case 'date':{ //handle changes to either start or end
            let start_date = value.value.start;
            let end_date = value.value.end;
            let datesToSet = {startDate: start_date, endDate: end_date,selectedFilters: Object.assign(this.state.selectedFilters,{startDate: start_date, endDate: end_date})}
            this.setState(datesToSet);
          }break;
          case 'e3person':
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{e3person: toSet})});
          break;              
          case 'site':
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{site: toSet})});
          break;   
          default:break;           
      }//end switch
  }catch(e){
      console.log("Error with filters: ",e);            
  }
  }
  /*
  * @brief convert the ms to hh:mm:ss
  */
  formatSecondsTime(_seconds){
    var dur = moment.duration({'seconds':_seconds});
    var days = Math.floor(dur.asDays());
    var hours = Math.floor(dur.asHours()) -days *24; 
    // var hours = Math.floor(dur.asHours()); 
    var mins  = Math.floor(dur.asMinutes()) -days *24*60 - hours * 60;
    var sec   = Math.floor(dur.asSeconds()) -days *24*60*60 - hours * 60 * 60 - mins * 60;
    
    var daysTXT = ("0" + days).slice(-2);
    var hoursTXT = ("0" + hours).slice(-2);
    if(hours >= 100){hoursTXT=("0" + hours).slice(-3);}
    if(hours >= 1000){hoursTXT=("0" + hours).slice(-4);}
    if(hours >= 10000){hoursTXT=("0" + hours).slice(-5);}
    if(hours >= 100000){hoursTXT=("0" + hours).slice(-6);}
    
    var minsTXT = ("0" + mins).slice(-2);
    var secTXT = ("0" + sec).slice(-2);
    var result = hoursTXT + ":" + minsTXT + ":" + secTXT;
    if(days > 0){
      result = daysTXT +":"+hoursTXT + ":" + minsTXT + ":" + secTXT;
    }
    
    return result;
  }
  
  


  getNoTable(tableData){

    if(!this.state.apiReturned){
    // if(this.state.fetchingState===1){
      return <Spinner/>;
    }

    return <div className='nodata'>No data available, please update the filter selection</div>

  }
  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {
    
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // console.log("Clicked on row:" ,row);
        if(!row.infractionid){
          return;
        }
        return;
        
      }
    };
    const rowClasses = (row, rowIndex) => {
      return 'table-row row-status-' + displayStatus(row.status).toLowerCase();
    };

    /*
      Set style for the rows in the table     
    */
    const rowStyle = (row, rowIndex) => {
      
      let rowcolor = '#00afed05' 
      if(rowIndex%2 ===0){
        rowcolor = '#00afed20'
      }
      return{
        backgroundColor: rowcolor,
      }
    };

    let scale = 1;
    if (this.state.winWidth && this.state.divWidth) {
        scale = this.state.winWidth / this.state.divWidth;
    }
    

    let tableColumns=[];
    let tableData = [];
    try {
        tableColumns = this.state.mTableHeaders[this.state.activeView] || [];
        tableData = this.state.mTableData[this.state.activeView] || [];
        //  console.log("Tables:" ,this.state.mTableData,tableData);
    } catch (error) {
        // console.log("Fail on switch? ",error,this.state);
    }
    
    if(!tableColumns || tableColumns.length===0){
      //  console.log("Headers are empty?");
      return(null);  
    }

    
    //Hide the table when there is no available data:
    let bNoData = false;
    if((!tableData || tableData.length===0) ){
        bNoData = true;
    }else{
      // console.log("Table Data: ",new Date() - this.state.timeSinceQuery,tableData,this.state.apiReturned)
    }

    // console.log("Call-in filters: ",this.state.filters)

    return (
      <div className="callin-lister">
        <CallinFiltersView  className = "callin-filters" filters={this.state.filters} onFilterSelected={this.onFilterSelected} onApply={this.applyFilters} 
                            startDate={this.state.startDate} endDate={this.state.endDate} 
                            filterKeys={this.state.filterKeys} modifiedTime = {this.state.filterUpdateTime}
                            />

        <div className="viewTabs">
            <Tabs className='viewSelection'
                defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                id='uncontrolled-tab-example' 
                    activeKey={this.state.activeView} 
                    onSelect={(k)=> this.setState({activeView:k})}
            >
                {this.state.tabViews.map((type) => {
                    return(  <Tab key={type.key} eventKey={type.key} title={type.name}/> )
                })} 
            </Tabs>
        </div>

       
        {(bNoData)? this.getNoTable(tableData):
          <React.Fragment>
            <BootstrapTable keyField='key' // a react specific thing that sets the 'key' for each row in the table
                  // react uses keys to keep track of identity when things change
              data={tableData} // <-- IMPORTANT: this is the actual data being displayed
              columns={tableColumns}
              striped={false} // sets every other row a different shade, makes it easier for the eye to
              rowStyle={ rowStyle}
              hover={true}   // sets a hover effect, so the background color of a row changes when the
              // mouse is over it. This signals to the user that the row is clickable
              classes={"callin-table"} // sets a CSS class so we can style this table specifically
              // cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
              rowClasses={rowClasses}
              bootstrap4 = {true}
              rowEvents={rowEvents} // set what events we want to happen on rows, right now just the click
              defaultSorted={[
              {dataField: 'calltime', order: 'desc'}, // how things should be sorted by
              ]} // default when first displayed
            />
            <div className='table_total'>
              <div>Total Call-ins: </div> {tableData.length}              
            </div>
            {/* <div className = "search-results">
                    <div>Total Notecards: </div>
                    {cardCount}
                </div>  */}
          </React.Fragment>
        }
        
        
        { this.state.expandedCard &&
                    <ExpandedCard handleClose={()=>{
                                            this.setState({expandedCard:null})                                            
                                            }}
                                    {...this.state.expandedCard}
                                    cardChange={this.cardChange}
                                    tagChange={this.cardTag}
                                    eventNotify={this.props.eventNotify}
                                    // newDetails= {this.onCardCreate}
                                    scale={scale}   
                                    noLink={this.props.groupconfig.group.toLowerCase().includes("bis")?false:true}                     
                                    // noEdit={disableEdits}
                                    filter={this.props.filter}
                                    groupconfig = {this.props.groupconfig}
                                    siteDetails = {(this.props.possibleFilters.Sites||[]).filter(elem_ => {return this.state.expandedCard && elem_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}
                    />
          }
          
          <CallFetchingDialog toFetch = {this.state.daysToFetch} fetched= {this.state.daysFetched} dialogState = {this.state.fetchingState}
                stringType = {'days'} progressBar= {true}
                //  fetchDetails = {}
                onApply={()=>{ 
                    console.log("Apply returned from CallFetchingDialog: ");
                    setTimeout(this.setState({daysToFetch:0,daysFetched:0,fetchingState:0}),500);
                    // console.log("Apply state to 0")
                    // this.setState({mFetchingEventState:0})
                }}
                onCancel={()=>{ 
                   console.log("Cancel clicked")
                    //Trigger the API above on unfullfilledPromises:
                    const promises = this.state.unfulfilledPromises;
                    promises.forEach(promise_=>{
                      if(API.cancel(promise_, "Cancel clicked, abort current queries")){ //did we cancel the promise, if yes, then remove it from the unfulfilled list
                        this.setState(prevState => {          
                          const promises = prevState.unfulfilledPromises; 
                          const index = (promises||[]).indexOf(promise_);
                          if (index > -1) { // only splice array when item is found
                            promises.splice(index, 1);
                          }
                          return{unfulfilledPromises:promises}
                        });  
                      }
                    });
                    this.state.queryTimeOuts.forEach( timeout_=>{
                      clearTimeout(timeout_);
                    });
                    setTimeout(this.setState({daysToFetch:0,daysFetched:0,fetchingState:0}),500);
                }}
            />
          
          
        

      </div>
    );
  }
}

export { CallinsView };