import React,{useState,useEffect} from 'react';

import * as moment from 'moment';
import { Auth, API } from 'aws-amplify';
import { filterToTime } from '../Util.js';


import { DashboardFiltersView} from '../BaseComponents.js';
//Wrap the components with the loading animation logic
import { withLoadingAnimation } from '../Wrappers/withLoadingAnimation.js';
import {InfractionGraphClass} from '../DashboardWidgets.js';
const InfractionGraphWithLoading = withLoadingAnimation(InfractionGraphClass);
  


export const DashboardRow1 = ({ 
                                    onFilterSelected=()=>{console.log("Timeline failed to link to eventNotify")}
                                    ,onApply=()=>{console.log("Timeline failed to link to onApply")}
                                    ,filter
                                    ,defaultFilter
                                    ,possibleFilters
                                    ,groupconfig
                                    ,username
                                    ,options
                                    ,filteredData
                                    ,downloadCallback
                                    ,...rest}) => {

    const [downloadButton, setDownloadButton] = useState(false);  
    const [downloadClicked, setDownloadClicked] = useState(false);  
    

    const [filters, setFilters] = useState({});
    const [activeFilters, setActiveFilters] = useState({});
    
    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //  console.log("TimeLineGraphs loaded: ");
    },[])

    React.useEffect(()=>{
        let tmp = {
            infractions: new Set((options.infractions||[]).map(elem_ => {return elem_.value})),
            driver: new Set((options.drivers||[]).map(elem_ => {return elem_.value})),
        }
        // console.log("Set tmp:" ,tmp);
        setFilters(tmp);
   },[options])

   //React to changes of the permission to download, or the state of the download button
   React.useEffect(()=>{
    // console.log("Download:" ,rest.downloadAllowed,downloadClicked);    
    if(rest.downloadAllowed){
        let tmpButton = <button  className={downloadClicked?"btn download-timeline-clicked":"btn download-timeline"}
                            onClick={()=>{
                                if(!downloadClicked){ //disable the button if already clicked
                                    console.log("Click on button");
                                    setDownloadClicked(true);
                                    downloadCallback()
                                }}
                            }//end  click handle
                        > Download</button>
        setDownloadButton(tmpButton);
    }//end check on download allowed

   },[rest.downloadAllowed,downloadClicked])

   

   function onFilterChange(kind, value, _whichFilter){
        console.log("FIlter selected: ",kind,value,_whichFilter);
        switch(kind){
            case 'sites':
                if(value.value === 'All'){value.value = null;}
                break;
            case 'driver':
                if(value.value === 'All'){value.value = null;}
                break;
            default:
                break;
        }

        if(!_whichFilter){
            const filtersCopy = Object.assign({}, activeFilters);
            filtersCopy[kind] = value.value;
            if (!value.value) {
                delete filtersCopy[kind];
            }
            console.log("Set active filters to: ",filtersCopy);
            setActiveFilters(filtersCopy)
        }
   }

   function timelineDayClicked(day, _serviceType, chart) {
        console.log("Timeline clicked: ",day,_serviceType,chart);
        // //Don't allow clicking on the graph for pintovalley
        // if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){return;}
        // if(this.props.groupconfig.group.toLowerCase() === 'demo_group'){return;}
        // const date = moment(day);
        // // console.log("Timeline click:", day, _serviceType, date.clone().format("YYYY-MM-DD"),date.clone().date());
        // // console.log("Timeline click:", day, _serviceType, date.clone().utc().format(),date.clone().utc().date());
        // this.props.eventNotify({
        //     type: 'link',
        //     data: {
        //         href: 'video-drilldown',
        //         extra: {
        //             groupconfig: this.props.groupconfig,
        //             filter: {
        //                 driverID: ALL_DRIVERS,
        //                 timePeriod: {
        //                     startDate: date.clone().format("YYYY-MM-DD"),
        //                     endDate: date.clone().add(1, 'day').format("YYYY-MM-DD"),
        //                 },                        
        //                 siteIDs: this.props.filter.siteIDs,
        //                 role: this.props.filter.role,
        //                 serviceType: _serviceType,
        //             }
        //         }
        //     },
        // });
    }

    function clickApply(){
        const res = {};
        //Iterate over the current selections of the filter dropdowns
        Object.entries(activeFilters).forEach(([name, value]) => {
            // console.log("Apply: ",name,value)
            try{
                switch(name){
                    case 'vehicle':
                        break;
                    case 'quant':
                        res['quant'] = value;                        
                        break;
                    case 'infractions':
                        // this.updateInfractionVisibility(value.value,this.state.activeAsset,this.state.mSelectedDriver)
                        // this.setState({selectedInfraction: value.value})
                        res['infraction'] = value;
                        // this.props.eventNotify({type: "filter", data: res});
                    break;
                    case 'driver':
                        res['driver'] = value;                        
                        break;
                    case 'startDate':
                         console.log("Apply startDate");
                        res['time'] = res['time'] || {}
                        res['time'].startDate = value.format("YYYY-MM-DD");
                        break;
                    case 'endDate':
                        res['time'] = res['time'] || {}
                        res['time'].endDate = value.format("YYYY-MM-DD");
                        break;
                    default:
                        break;
                }
            
            }catch(error){
                console.log("Error? ",error);
            }            
        });//end the entry iteration loop

        //Make sure we can reset the time stamp:
        if(!res['time']){
            res['time'] ='all';
        }

        // console.log("onClick: ",res);
        onApply(res);
    }

     //Call Render (need to clean this up so that we aren't doing computation in the render loop - it is making UI sluggish)
     function renderGraphs(){
        //  console.log("RenderGraphs: ", rest.liveDataReady);
        //Return the formatted HTML:            
        return (
            <div>
                <DashboardFiltersView  
                    className='dashboard-filters-download' 
                    filters={filters}
                    activeFilters = {activeFilters}
                    onFilterSelected={onFilterChange} 
                    groupconfig={groupconfig} 
                    startDate={rest.startDate} 
                    endDate={rest.endDate} 
                    onApply={clickApply}
                    button = {downloadButton}                    
                />
                <div className="dashboard-row">

                    
                {rest.liveDataReady && <InfractionGraphWithLoading serviceType='TRIFECTA' filter={filter} groupconfig={groupconfig} 
                    infractions={(filteredData.live.cards||[])} 
                    infractionsByType = {(filteredData.live.type||[])} 
                    infractionsToDisplay = {options.infractionsList||[]}
                    onDayClick={timelineDayClicked}
                />}
                </div>

            </div>
        );
    }
     //Call Render (need to clean this up so that we aren't doing computation in the render loop - it is making UI sluggish)
     function renderBISGraphs(){
        // console.log("Render data: ",filteredData)
        //Return the formatted HTML:            
        return (
            <div>
                <DashboardFiltersView  
                    className='dashboard-filters' 
                    filters={filters}
                    activeFilters = {activeFilters}
                    onFilterSelected={onFilterChange} 
                    groupconfig={groupconfig} 
                    startDate={rest.startDate} 
                    endDate={rest.endDate} 
                    onApply={()=>{onApply(activeFilters)} }
                />

                <div className="dashboard-row">
                        <InfractionGraphWithLoading    serviceType='CloudDETECT' filter={filter} groupconfig={groupconfig} 
                            infractions={(filteredData.offline.cards||[])} 
                            infractionsByType = {(filteredData.offline.type||[])}  
                            infractionsToDisplay = {options.infractionsList||[]}
                            onDayClick={timelineDayClicked} 
                        />
                </div>
                {(filteredData.live.cards && filteredData.live.cards.length >0)?
                    <div className="dashboard-row">
                        <InfractionGraphWithLoading serviceType='TRIFECTA' filter={filter} groupconfig={groupconfig} 
                            infractions={(filteredData.live.cards||[])} 
                            infractionsByType = {(filteredData.live.type||[])} 
                            infractionsToDisplay = {options.infractionsList||[]}
                            onDayClick={timelineDayClicked}
                        />
                        {/* <InfractionGraphWithLoading serviceType='TRIFECTA' infractions={(filteredData.live.cards||[])} 
                            infractionsByType = {(filteredData.live.type||[])} filter={filter} groupconfig={groupconfig}
                            infractionsToDisplay = {options.infractionsList||[]}
                            onDayClick={timelineDayClicked}
                        /> */}
                    </div>
                :null
                } 
            </div>
        );
    }

    if(groupconfig && groupconfig.group && groupconfig.group==='bis'){
        return renderBISGraphs();
    }else{
        return renderGraphs();
    }
    
    

    // return render(); //return from the compoennt

}//end DashboardView