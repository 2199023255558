import React from 'react';
import ReactDOM from 'react-dom';
// import {render} from 'react-dom';
// import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App/AuthedApp.js';
import registerServiceWorker from './registerServiceWorker';
import {cleanOldTabRecordsFromStorage, TAB_STALE_TIME, TAB_EXPIRE_TIME, } from './Util.js'



// If we have found another tab that is active
let otherTabExists = false;

// Check each key in localStorage, if they are a tab record, see if they are in the timerange
// we expect for recently live tabs
let tabCheckReturn = cleanOldTabRecordsFromStorage(window.sessionStorage,TAB_STALE_TIME,TAB_EXPIRE_TIME, true);
otherTabExists = tabCheckReturn.staleTabsPresent;
//Delete old tabs from the local storage
cleanOldTabRecordsFromStorage(window.localStorage,TAB_STALE_TIME,TAB_EXPIRE_TIME, true);

// If no other tabs were found, clear the stored login information so that another login is required
if (!otherTabExists) {
    // this is distinct from 'storage', this refers to the storage that is used by AWS amplify to store logins
    // (which may or may not be the same thing as 'storage', if that logic is changed)
    // window.localStorage.clear();
}

// A helper function to record our current tab in storage
// const updateRecord = () => {
//     console.log("Update tab record");
//     storage.setItem('tab-record-' + TAB_NUMBER, Date.now());
//     storage.setItem('SessionNumber',TAB_NUMBER);
//     window.localStorage.setItem('tab-record-'+TAB_NUMBER,Date.now()); //Keep this recorded in the local storage, shared between all tabs
// };

// Record our tab manually once
// updateRecord();
// // And then automatically on a periodic timer
// window.setInterval(updateRecord, TAB_RECORD_DELAY * 60 * 1000);

// ReactDOM.render(
//         <React.StrictMode>
//             <App />
//         </React.StrictMode>
//         , document.getElementById('root')
//             );
// const container = document.getElementById('app');
// console.log("Container? :",container);

// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);

ReactDOM.render(
    <App />
    , document.getElementById('root')
);
registerServiceWorker();