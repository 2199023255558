
import React from 'react';

import './VideoReviewer.css';
import './VideoClipPlayer.css';

import {VideoClipPlayerCollection} from './VideoClipPlayerCollection.js'
import {VideoClipPlayerStream} from './VideoClipPlayerStream.js'


/*
* @brief Functional component to wrap the class call
*/
const VideoClipPlayer = ({  currentClip,groupconfig, filter,cardsByType,bookmarkLoad,chosen,ejectSets,ejectedIDs,ejectSetName
                            ,username,filename,playerKey,bMemoryOptimized,handleLoad,loadComplete
                            ,markViewed, overlayButtonClick, roiSelectClicked, ejectClick, undoEjectClick, currentlyPlaying,bLoadAllowed
                            ,refreshTrigger,ref,type,interruptTimer }) => {
  //  console.log("Received VCP: ",currentClip);

  let player = null;
  {(currentClip.type && currentClip.type==='playlist')?
    player = <VideoClipPlayerCollection
        ref = {ref}               
        currentClip={currentClip}                  
        chosen={chosen}                
        filter={filter}
        groupconfig= {groupconfig}
        cardsByType={cardsByType}
        bookmarkLoad = {bookmarkLoad}
        ejectSets={ejectSets}
        ejectedIDs = {ejectedIDs}
        ejectSetName = {ejectSetName}
        username = {username}  
        filename = {filename}
        playerKey={playerKey}

        markViewed={markViewed} 
        overlayButtonClick = {overlayButtonClick}
        roiSelectClicked = {roiSelectClicked}                              

        ejectClick = {ejectClick}
        undoEjectClick = {undoEjectClick}

        currentlyPlaying = {currentlyPlaying}  
        bLoadAllowed = {bLoadAllowed}
        loadComplete = {loadComplete}
        bMemoryOptimized = {bMemoryOptimized}      
        handleLoad = {handleLoad} 

        refreshTrigger = {refreshTrigger}
        type ={type}
        interruptTimer = {interruptTimer}
        />

  :
    // player = <div></div>
    player = <VideoClipPlayerStream
          ref = {ref}               
          currentClip={currentClip}                  
          chosen={chosen}                
          filter={filter}
          groupconfig= {groupconfig}
          cardsByType={cardsByType}
          bookmarkLoad = {bookmarkLoad}
          ejectSets={ejectSets}
          ejectedIDs = {ejectedIDs}
          ejectSetName = {ejectSetName}
          username = {username}  
          filename = {filename}
          playerKey={playerKey}

          markViewed={markViewed} 
          overlayButtonClick = {overlayButtonClick}
          roiSelectClicked = {roiSelectClicked}                              

          ejectClick = {ejectClick}
          undoEjectClick = {undoEjectClick}

          currentlyPlaying = {currentlyPlaying}  
          bLoadAllowed = {bLoadAllowed}
          loadComplete = {loadComplete}
          bMemoryOptimized = {bMemoryOptimized}      
          handleLoad = {handleLoad} 

          refreshTrigger = {refreshTrigger}
          type ={type}
          interruptTimer = {interruptTimer}
          />
  }//end type check

  return player;
};
export { VideoClipPlayer };
    
 