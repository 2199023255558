import React, { Component } from 'react';

import { doPerformanceOutput } from '../Util.js';

import logoE3 from '../assets/e3_logo.png';
import logoTrifecta from '../assets/TRIFECTA_ONLY.svg';
import logos from '../logos.js';
import IconAlert    from '../assets/alert-horn.png'
import './navbar.css';
import './userprofile.css'
import { Notifications } from '../Notifications/Notifications.js';
import {UserProfileMenu} from './NavBar-user.js'


/*
* @brief The bar that displays the EDGE3 logo and, if they are signed in, the client logo
*
* Includes the menu that allows the user to log out
*/
class FlatLogoBar extends Component {

    //  
    /**
     * Pull the new infraction alert icon out of the render block to improve readability
     */
    messageAlert(){
        return(
            <li key="alertkey" className="header-notification">
                {this.props.newMessage && 
                    <img type='image' src={IconAlert} className="alert-icon fade-icon"  /> 
                } 
            </li>
        )
    }

    render() {
        // console.log("Logo bar :", this.props)
        let platformLogo = logoTrifecta;//logoCloudDETECT;

        let renderNotifications = false; //assume the notification shouldn't be shown
        try {
            //Enable the notifications if the groupconfig is loaded (this will be false when logged out)
            if(this.props.groupconfig && this.props.groupconfig.bLoaded){renderNotifications = true;}
        } catch (error) {

        }
        return (
            <nav className="navbar header-navbar pcoded-header"
                 onClick={doPerformanceOutput} >
                <div className="navbar-wrapper">
                    <div className="navbar-logo">
                        <a className="mobile-menu" id="mobile-collapse" href="#!">
                            <i className="ti-menu"></i>
                        </a>
                        <a href="/">
                            <img className="img-fluid main-logo" src={logoE3} alt="EDGE3 Technologies, Inc." />
                        </a>
                        <a className="mobile-options">
                            <i className="ti-more"></i>
                        </a>
                    </div>
                    <div className="navbar-cloud-logo">
                        <a href="/">
                            <img className="img-fluid  main-logo" src={platformLogo} alt="EDGE3 CloudDETECT" />
                        </a>
                    </div>

                    <div className="navbar-container container-fluid">
                        <div>
                            <ul className="nav-right client-menu">
                                {this.messageAlert()}                                    
                                {/* Only show the Notification bell, if renderNotifications is true*/}
                                {renderNotifications && <Notifications key="notifkey" groupconfig={this.props.groupconfig} userInfo={this.props.userInfo}/>}
                                <AuthClientLogo key="clientlogokey" groupconfig={this.props.groupconfig} />                            
                                <UserProfileMenu key="userprofilekey" username={this.props.username} onSignOut={this.props.onSignOut} />
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

/*
* @brief A widget for displaying a specific company logo based on the group of the logged in user
*/
class AuthClientLogo extends Component {
  render(){
    // console.log("Group: ",this.props);
    var img = logos[this.props.groupconfig.group];
    if (!img) {
        return null;
    }
    return (
        <li>
            <img className="client-logo" src={img} alt={this.props.groupconfig.group + " logo"} />
        </li>
    );
  }
}



// Only exporting the FlatNavBar, the other pieces are considered internal details
export { AuthClientLogo, FlatLogoBar };
