import getBrowserFingerprint from 'get-browser-fingerprint';
import {API} from 'aws-amplify';
import axios from 'axios';
import { getCookie } from '../Util-access';


/**
 * Execute a browser fingerprint collection, get device resolution details, and attempt to acquire the location
 * of the browser using the ip address
 * All data is collected and sent to the handleLogin API method to record the login attempt
 * @param {*} _data: _response from the Auth.currentSession() - cognitio details
 * @param {*} _versions: local and cloud versions, added to report with the API message
 * @returns 
 */
export function authenticateBrowser(_data,_versions) {
     var promise = new Promise( (resolve, reject) => {

        let username = null;
        try {
            const login = _data.idToken.payload;
            username = login['cognito:username']    
        } catch (error) {
            console.log("Failed to get username: ",error);
        }

        //Collect details about the current device for debugging and Qaulity testing
        let deviceDetails = getDeviceDetails();
        //If the versions of the service are know, then add them to the device details
        if(_versions){
            deviceDetails=Object.assign(deviceDetails,_versions);
        }

        //Execute the fingerprinting on the device to try to confirm the same computer is being used
        const t0 = new Date();
        const fingerprint = getBrowserFingerprint({ enableWebgl: true, debug: false});
        const t1 = new Date();
        // console.log("Fingerprint: ",fingerprint, t1-t0);


        // Get the IP address of the browser:
        axios({ //don't specify the response type -> returns the JSON object
            url: 'https://geolocation-db.com/json/',
            method: 'GET',
            // responseType: 'blob', // important
        })
        .then(res => {
            // console.log("IP axios",res.data);
            let ipreturn = { address :null, lat :null, lon :null };//define an empty ip address object
            //Try to populate the ip address details using the return message
            try {
                ipreturn.address = res.data.IPv4;
                ipreturn.lat = res.data.latitude;
                ipreturn.lon = res.data.longitude;
            } catch (error) {}

            //Send the login details to the API, record the session details and the successful login
            let apiName = "TrifectaCoreAPI";
            let path = "/handleLogin";
            let myInit = {
                body: {
                    fingerprint: fingerprint,
                    username: username,
                    ipaddress: ipreturn.address,
                    sessionnumber: window.sessionStorage.getItem('SessionNumber'),
                    token: getCookie('registered-token'),
                    details: deviceDetails //attach the device details to the API message
                }
            };

            let apiPromise = API.post(apiName, path, myInit);
            apiPromise.then((data) => {
                // console.log("Broswer Auth return: ",data);
            });
            apiPromise.catch((error) => {
                console.error("Failed on broswer auth; ",error); 
            }); 
            resolve("Promise resolved successfully");
        })
        .catch(error => {
            //  console.error("Promise rejected",error);
            //Send the login details to the API, record the session details and the successful login
            let apiName = "TrifectaCoreAPI";
            let path = "/handleLogin";
            let myInit = {
                body: {
                    fingerprint: fingerprint,
                    username: username,
                    sessionnumber: window.sessionStorage.getItem('SessionNumber'),
                    token: getCookie('registered-token'),
                    details: deviceDetails //attach the device details to the API message
                }
            };
            console.log("Send handleLogin on error");

            let apiPromise = API.post(apiName, path, myInit);
            apiPromise.then((data) => {
                console.log("Browser Auth return: ",data);
            });
            apiPromise.catch((error) => {
                console.error("Failed on broswer auth; ",error); 
            }); 
            resolve("Promise resolved successfully");
        })
      
    });
    
    return promise;
}//end authenticateBrowser

/**
 * Collect details about the screen resolution, window size, and dpi 
 * @returns JSON object with screen details
 */
const getDeviceDetails=()=>{

    try {
        //Define the metric to collect
        let deviceDetails = {
            dpi: window.devicePixelRatio
            ,screen_width: window.screen.width
            ,screen_height: window.screen.height
            ,avail_width: window.screen.availWidth
            ,avail_height: window.screen.availHeight
            ,window_width: window.innerWidth
            ,window_height: window.innerHeight
        }
        return deviceDetails;
    } catch (error) {
        console.log("Failed to gather device details: ",error);
        return {};
    }

}



