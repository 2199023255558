import React, {  } from 'react';
// import '../AdminTab/AdminUserManagement.css';
import './TrainControl.css';

import { StatefulButton } from '../VideoReview/vid-util';

/*
* @brief 
*/
export const TrainDriver = ({   
                                onChange = ()=>{console.log("TrainDriver onChange not defined")}
                            }) => {

    function handleStart(){
    }
    //Define the returned rendered HTML:
    function render(){
        return (
            <div className='train-driver'>
                <StatefulButton className="btn btn-primary v-center" onClick={handleStart}> Train DriverID </StatefulButton>
            </div>
        );
        // {driverCandidates? 
        //     <React.Fragment>
        //         <div className='driverDisplay'>
        //                                 {driverCandidates.map((driver_) =>  {return(driver_)})}
        //         </div>
        //         {/* Add a checkbox showing that there are no matches in the list of drivers  */}
        //         <div>
        //             <input type="checkbox"  value="NoMatch" id='nomatch-eval'  onChange={handleNoMatchConfirm} />
        //             <label for="nomatch-eval">No visual matches found in DriverID set</label>
        //         </div>
                
        //     </React.Fragment>
        // :
        //     
            
        // } 
    }


    return render();

};



