import { Auth, API } from 'aws-amplify';
import React, { PureComponent } from 'react';
import * as moment from 'moment';
import EventIcons from '../markers/iconset.js';
import axios from 'axios';



//OpenLayers imports:
import { fromLonLat, get,transformExtent,transform } from 'ol/proj';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';


import { Circle as CircleStyle, Icon } from 'ol/style';
import { getCompassDirection,getGreatCircleBearing,isPointInPolygon,getCenterOfBounds,getDistance,getCenter } from 'geolib';

import {Polygon} from 'ol/geom';
import {Draw,Modify,Snap}  from 'ol/interaction';
import { getRegionDesc,gpsStringToCoords,gpsCoordsToString,useImperial } from '../UtilOpenLayers.js';
import {unByKey} from 'ol/Observable';

var LatLon = require('geodesy').LatLonSpherical;
// Debug helpers for testing the API call logic


export class MapSiteBoundary extends PureComponent {
    constructor(props) {
        super(props);
        this.addMapLayers = this.addMapLayers.bind(this);
        this.updateSiteBoundary = this.updateSiteBoundary.bind(this);

        this.state = {
            siteConfig: null,
        };
    }
    /*
    * @brief Called when first launched
    */
    componentDidMount() {
        this.addMapLayers();

        //Set up the interactions:
        let modifyRegions = new Modify({source: this.props.overlaySource['boundary']});


       
        let modifyedFeatures = [];
        let geometryChangeListener;
        modifyRegions.on('modifystart', (e) => {
            modifyedFeatures.length = 0;
            const features = e.features;
            //Add a listener to each of the features
            features.forEach((feature) => {
                geometryChangeListener = feature.getGeometry().on('change', () => {
                    //Only add the feature to the list if its geometry has changed:
                    if(modifyedFeatures.indexOf(feature) === -1){
                        modifyedFeatures.push(feature);
                    }
                });
            });
        });

        modifyRegions.on('modifyend', (evt) => {
            //Remove the change listener
            if (geometryChangeListener) {
               unByKey(geometryChangeListener);
               geometryChangeListener = null;
            }
        //    console.log("Modified:" ,modifyedFeatures[modifyedFeatures.length-1])
            let feature = null;
            try {
               feature = modifyedFeatures[modifyedFeatures.length-1];
            } catch (error) {
            }
            try {
                if(feature){
                    let regionDesc = getRegionDesc(feature,'polygon');
                    // console.log("Extracted Modified: ",regionDesc);                    
                    let dataToSend = {
                        name: feature.get('site'),                        
                        boundary: regionDesc.coordinates,
                    }   

                    // console.log("Data to send: ",dataToSend);
                    
                    //Send the updates to the API:
                    const realPromise = Auth.currentSession().then(
                        (auth) => {
                            let apiName = "TrifectaAPI";
                            let path = "/handleSites";
                            let myInit = {
                                body: {
                                    token: auth.idToken.jwtToken,
                                    mode: 'editboundary', 
                                    data: dataToSend,
                                    clientid: this.props.groupconfig.group  
                                }
                            };
            
                            console.log("Send handleSite editsite",myInit.body)
                            return API.post(apiName, path, myInit);
                        }
                        )
                        .catch((error) => {
                            console.error("Site managment api call; ",error); 
                        }); 
            
                    realPromise.then((_return) => {
                        //  console.log("Returned from API: ",_return)
                        // this.setState({siteConfigCard:null,retryCount:this.state.retryCount+1});
                    });



                }else{
                    console.log("No feature?: ",evt,evt.features.getArray());
                }
            } catch (error) {}
       });


        // //Handle the event that is fired when the modification stops:
        // modifyRegions.on('modifyend',evt=>{
        //     //Extract the feature from the event
        //     console.log("End modify: ",evt);
        //     console.log("Pixel: ", evt.target.lastPixel_)
        //     console.log("Pixel-1: ", evt.target)
        //     console.log("Pixel-1: ", evt.target.lastPointerEvent_)
            
        //     let features =  this.props.map.getFeaturesAtPixel(evt.target.lastPixel_);
        //     console.log("Features:" ,features);
        //     let feature = null;
        //     // try{
        //     //     if(evt.feature){feature = evt.feature;  }
        //     //     else{
        //     //         let features =  evt.features.getArray();
        //     //         feature = features[0];
        //     //     }
        //     // }catch(e){}
        //     //get the new region definition from the modified feature
    
            
        // })



        this.props.map.addInteraction(modifyRegions);
    }

    /*
    *  @brief Add the new layer to render the journey and set up the styles that render the lines
    */
    addMapLayers(){
        var min;
        min = 0;

        const styles = {
            'boundary': [ new Style({
                stroke: new Stroke({
                    color: 'white',
                    lineDash: [8],
                    width: 4,
                }),
            })],
          };
      
        if(this.props.overlayLayers["boundary"]){
            // console.log("Set boundary?",this.props);
            this.props.overlayLayers["boundary"].setStyle( function(feature, resolution) {
                // console.log("Check on feature: ",feature);   
                let styleReturn =  styles[feature.get('type')]
                return styleReturn;
            });
        }
    }

   

    UNSAFE_componentWillReceiveProps(newProps) {
        // console.log(" new props?: ",newProps);
            if(JSON.stringify(this.props.sites)!==JSON.stringify(newProps.sites)){
                // console.log(" new props?: ",newProps);
                //Iterate through the site and update the map:
                for(const site_ of newProps.sites){
                    this.updateSiteBoundary(site_);
                }
            }
    }//end will receive props

    updateSiteBoundary(_site){
        //Create a new polygon feature

        try {
            //Get the boundary features:
            let boundarySet = null;
            try {
                boundarySet = this.props.overlaySource["boundary"].getFeatures();    
            } catch (error) {
                console.log("Failed to get features:" ,error,_site);
            }
            
            let boundaryFeature = null;
            
            // console.log("Compare with: ",_asset,_driverid,_infraction);
            (boundarySet || []).forEach( (elem_) =>{
                if(elem_.site === _site.site) {
                    boundaryFeature = elem_;
                }
            })
            // console.log("Boundary found: ",boundaryFeature, _site.boundary);
            if(!boundaryFeature && _site.boundary){
                
                let transformedCoords = [];

                // map.getView().fit(ol.proj.transformExtent([swLong, swLat, neLong, neLat], 'EPSG:4326', map.getView().getProjection()), { size: map.getSize() });
                let bl={lat:null, lon:null}
                let tr={lat:null, lon:null}
                for(const coord_ of JSON.parse(_site.boundary)){
                    let transformed = fromLonLat([coord_.lon,coord_.lat]);
                    transformedCoords.push(transformed);
                    //Get the bounding arear of the boundary
                    //find the corners:
                    if(!bl.lat){bl.lat = coord_.lat};
                    if(!bl.lon){bl.lon = coord_.lon};
                    if(!tr.lat){tr.lat = coord_.lat};
                    if(!tr.lon){tr.lon = coord_.lon};
                    //Find the min max of the bounding region:
                    if(coord_.lon < bl.lon){bl.lon = coord_.lon}
                    if(coord_.lon > tr.lon){tr.lon = coord_.lon}
                    if(coord_.lat < bl.lat){bl.lat = coord_.lat}
                    if(coord_.lat > tr.lat){tr.lat = coord_.lat}
                }
                
                let test = [];
                test.push(transformedCoords);

                let tmpPolygon = new Polygon(test);                
                let newFeature=new Feature(tmpPolygon);
                newFeature.set('type','boundary');
                newFeature.set('site',_site.site);
                // console.log("Set boundary: ",_site, newFeature);
                //Add to the map to render
                // console.log("Add feature: ",newFeature);
                this.props.overlaySource['boundary'].addFeature(newFeature);
                // console.log("Site Boundary:",fromLonLat([bl.lon,bl.lat]),fromLonLat([tr.lon,tr.lat]),_site.site,newFeature);
            }
        } catch (error) {
            console.log("Failed to set boundary: ",error, _site);
        }
    }

    
    /*
    * @brief Render the content of the card:
    */
    render() {
        return null;
    }
} //end mapJourney Class


/*
* @brief Class to handle the interaction with the infractions and path of the Journey
*/




