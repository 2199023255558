
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';

import './NotecardDriverID.css';

import blankAvatar from '../assets/avatar-blank-large.png';

import { ApiCaller,Spinner } from '../ApiCaller.js';

import { Auth, API } from 'aws-amplify';

import { StatefulButton } from '../VideoReview/vid-util';
import { DriverDetail } from './DriverDetails.js';

// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

/*
* @brief This is an modal dialog to be opened when addding or editing a user
*/
export class NotecardDriverID extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            shown: false,
            timestamp:null,
            selected: null,            
        };

        this.videoRef =  React.createRef(null);
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        // console.log("Open notecard driverid: ",this.props);
        window.setTimeout(() => {
            this.setState({shown: true});
        }, EXPANDED_CARD_SHOW_LATENCY);
     
    }
    /*
    * @brief Run on exit, clean up the video player 
    */
    componentWillUnmount(){
        //Release the video file:
        try {
            if(this.videoRef && this.videoRef.current){
                this.videoRef.current.src = null;
                this.videoRef.current.load();
            }    
        } catch (error) {}
    }

  
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        // let returnData = {};        
        // console.log("Handle submit: ",JSON.stringify(this.state.selected));

        //Dispatch the update to the SQL table:
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDriverInfo";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        driverid: this.state.selected,
                        infractionid: this.props.infractionid,
                        mode: 'updateDriverID',              
                    }
                };
                return API.post(apiName, path, myInit);
        })

        if(this.props.onClose){this.props.onClose({driverid: this.state.selected});}

    }//end handleSubmit
    /*
    * @brief handle closing the card with submit
    */
    handleCancel(_data){
        // console.log("Cancel clicked")
         if(this.props.onClose){
            // console.log("Call onClose")
            this.props.onClose();
        }
    }//end handleSubmit

    
    /*
    * @brief Handle clicking on the driver photo
    */
    handleClick(_data){
        // console.log("clicked ",_data)
        this.setState({selected:_data})
    }//end handleSubmit

    /*
    * @brief Render the content of the card:
    */
    render() {
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        let drivers = [];

        // console.log("Props: ",this.props);
        //Iterate over the top 3 candidates and add them to the list of displayed assets:
        if(this.props.top3 && this.props.top3.candidates){
            for(const driver_ of this.props.top3.candidates){
                // console.log("Driver_: ",driver_,)
                let confidenceDisplay = driver_.confidence;
                confidenceDisplay = Math.floor(confidenceDisplay/10);
                if(driver_.id === 'Pending'){continue;}
                drivers.push(<DriverDetail className='driverdetail'
                                driverid = {driver_.id}
                                confidence = {confidenceDisplay}
                                selected = {this.state.selected}
                                onClick = {this.handleClick}
                                asset = {this.props.asset}
                                key={'nc-driverid-'+driver_.id}
                            />)
            }
        }
        //Pad so that only drivers are on the top row
        if(drivers.length > 0 && drivers.length<3){
            let runLength = 3-drivers.length;
            for (let i = 0; i < runLength; i++) { 
                drivers.push(<div className='driverblank'></div>)
            }
        }
        
        //Push on the empty guesses:
        drivers.push(<DriverDetail className='driverdetail'
                        driverid = 'Pending'
                        selected = {this.state.selected}
                        onClick = {this.handleClick}
                        key={'nc-driverid-'+'Pending'}
                    />)
        drivers.push(<DriverDetail className='driverdetail'
                        driverid = 'Unavailable'
                        selected = {this.state.selected}
                        onClick = {this.handleClick}
                        key={'nc-driverid-'+'Unavailable'}
                    />)

        return (
            <div className="notecarddriverid-card" style={style} >
                {/*Create a modal window to pop up on top of the page*/}
                <ReactModal isOpen={this.state.shown} className="modal-dialog notecarddriverid-card-modal"
                            shouldCloseOnOverlayClick={false} ariaHideApp={false}
                >
                    <div className="notecarddriverid-modal" style={style} >
                        {/*Set the title of the card*/}
                        <div className = 'title'> DriverID Select </div>

                        {/*Add the an array of driver option photos and the video to the top of the card*/}
                        <div className='topDisplay'>
                            <div className='driverDisplay'>

                                {drivers.map((driver_) =>{
                                    return(driver_)
                                })}
                                
                            </div>
                            {/*Load the video*/}
                            <video className='notecard-video' 
                                ref = {this.videoRef}
                                id='notecard-video'
                                muted="muted"
                                preload="auto"
                                controls
                                controlsList="nodownload" //block the in player download request
                            >
                                {this.props.video && <source src= {this.props.video} type="video/mp4"></source>}
                            </video>
                        </div>
                        
                        {/*Add the Cancel and Submit buttons to the footer */}
                        <div className="footer">
                                <StatefulButton className="btn btn-danger" 
                                     onClick={this.handleCancel}
                                >Cancel</StatefulButton>

                                <div className='notecardRef'> Notecard reference: <span>{this.props.infractionid}</span> </div>
                                {/*Pass the selection state to the submit button to set the disabled attribute  */}
                                <StatefulButton className="btn btn-primary" disabled={this.state.selected?false:true}
                                    onClick={this.handleSubmit}
                                >Submit</StatefulButton>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end NotecardDriverID

