

/* @brief handle the callback when a tag is changed in the notecard.
*  This refreshes the displayed cards in the list
*/
export const cardTag = (_inCard,_this,_thisName) => {
    // console.log("Card Tag:" ,_inCard);
    //Update the card
    _this.setState(state => {
        const cardsByInfID = state[_thisName];
        cardsByInfID[_inCard.infractionID] = Object.assign(cardsByInfID[_inCard.infractionID]|| {}, _inCard);
        // console.log("CardTag: ",cardsByInfID)
        
        return {_thisName:cardsByInfID,expandedCard: cardsByInfID[_inCard.infractionID]}
        //Does this need the noShow flag? // return {cardsByInfractionID:cardsByInfID}
    });
}

/* @brief handle the callback when the DriverID is changed on a notecard
*/
export const changeDriverID = (_inCard,_this,_thisName) => {
    try {
       console.log("Change driver id: ",_inCard)
      _this.setState(state => {
        const cardsByInfID = state[_thisName];
        cardsByInfID[_inCard.infractionID] = Object.assign(cardsByInfID[_inCard.infractionID] || {}, _inCard);
        // if(_inCard.driverID){copy.driverID = _inCard.driverID;}
        // if(_inCard.name){copy.name = _inCard.name;}
        return {_thisName:cardsByInfID}
      });
    } catch (error) {
      console.log("Failed to set driverid on cards: ",error);
    }
  }


  /* @brief Callback when a card is updated
  */
export const cardChange = (_inCard, _parentProp,_this,_thisName) => {
    //  console.log("Card change: ",_inCard,_thisName);
    if(_parentProp){_parentProp(_inCard)} //pass back along to the top level callback, to pass to the SQL table
    
    _this.setState(state => {
        const cardsByInfID = state[_thisName];
        // console.log("Cards: ",cardsByInfID,state);
        cardsByInfID[_inCard.infractionID] = Object.assign(cardsByInfID[_inCard.infractionID] || {}, _inCard);
        // console.log("CardChange: ",cardsByInfID)
        // 
        return {_thisName:cardsByInfID, expandedCard: cardsByInfID[_inCard.infractionID]}
        //Does this need the noShow flag? // return {cardsByInfractionID:cardsByInfID}
    });
}


/* @brief Callback when a card is closed
*/
export const unexpandCard = (_inCard,_this,_thisName) => {
    //  console.log("Card change: ",_inCard,_thisName);
    if(_inCard && _inCard.delete){

        _this.setState(state_ => {
            let cardsByInfId = state_[_thisName];
            delete cardsByInfId[_inCard.infractionID];      
            return {_thisName:cardsByInfId}; 
        },()=>{ //handle after the display update
            _this.setState({expandedCard: null});
        });

    }else{
        _this.setState({expandedCard: null});
    }
}