import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import './StoryReport.css';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { FaFileDownload } from 'react-icons/fa';
import { blankAvatar,LoadedSpinner } from '../RiskTab/RiskUtils';
import {STORYLINE_DATE_FORMAT} from '../Util.js'
import moment from 'moment';
import logoE3 from '../assets/e3_logo.png';


import  jsPDF  from "jspdf"; 
import html2canvas from 'html2canvas';

async function downloadComponentInPDF(_element,_docName) {
  let startGen = new Date();
  console.log("Capture on window: ",window.outerWidth, window.innerWidth,window,_element.scrollHeight,_element.offsetHeight)
  await html2canvas(_element,
    { scale:2,	
      // scrollY: -window.scrollY,
      // height: _element.scrollHeight,
      // windowHeight: _element.scrollHeight,
    }
  
  ).then((canvas) => {
    //Debugging (don't delete) - save the canvas to a PNG to check against PDF rendering
    // var a = document.createElement('a');
    // a.href = canvas.toDataURL('image/png')
    // a.download = 'reportImg.png';
    // a.click();

    const imgData = canvas.toDataURL('image/png') //convert the canvas to data, format as PNG
    const pdf = new jsPDF("p", "px",[canvas.width, canvas.height]); //create the PDF, p: portrait, px: measure in pixels, []: width,height to set

    const imgProperties = pdf.getImageProperties(imgData);

    // console.log("Captured: ",imgProperties.width,imgProperties.height)

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); //add the image to the PDF
    // console.log("Getting PDF sizes: ",pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),pdfWidth,pdfHeight);

    pdf.save(_docName) //save the PDF
    console.log("Report save time: ",new Date() - startGen);
  })
}

/*
* @brief 
*/
class StoryReport extends PureComponent {
    
  constructor(props) {
      super(props);
      this.onBack = this.onBack.bind(this);
      
      this.state = {
          shown: true,
          notecards: [],
          calls: [],
          lastNotecard: null,
          firstNotecard: null,
          siteDetails: null,
          hasSD: false,
          assetid: null,
          pdfGenerate: false,
      };
  }

  componentDidMount(){
    //  console.log("Report mount: ",this.props);    

     let notecards = Object.values(this.props.notecards);
     let calls = Object.values(this.props.calls);
     let hasSD = this.state.hasSD;

     notecards.forEach( card_ =>{
      if(card_.infractionTags.includes("Severe Drowsiness")){hasSD = true;}

      //Filter out other tags than Drowsiness and Severe Drowsiness, they are not needed o nthe report
      try {
        card_.infractionTags = card_.infractionTags.filter( tag_=>{return (tag_||"").includes("Drowsiness");})
      } catch (error) {
        
      }

      //Check if a call was made
      card_.didCall = false;
      let found = calls.findIndex( call_ => call_.infractionid === card_.infractionID);
      if(found>=0){
        card_.didCall = true;
      }


     });

     notecards.sort( (a,b)=> {
      try {
        return moment(a.timeOfDay).isBefore(moment(b.timeOfDay))? -1:1;
      } catch (error) {
        return 0;
      }
     });
     calls.sort( (a,b)=> {
      try {
        return moment(a.calltime).isBefore(moment(b.calltime))? -1:1;
      } catch (error) {
        return 0;
      }
     });

     //get the first and last notecards by timeofday:
     let firstNotecard = null; 
     let lastNotecard = null; 
     try {
      firstNotecard = notecards[0];
      lastNotecard = notecards[notecards.length-1];
     } catch (error) {}

     let siteDetails = null;
     try {
      siteDetails = (this.props.possibleFilters.Sites||[]).filter(filt_ => {return firstNotecard && filt_.site.toLowerCase()===firstNotecard.siteID.toLowerCase()})
      siteDetails = siteDetails[0];
     } catch (error) {
      
     }

     let assetid = null;
     try {
      assetid = firstNotecard.vehicleID;
     } catch (error) {}

    //  console.log("Notecards to display: ",notecards);
     
    //  console.log("SiteDetails: ",siteDetails,this.props.possibleFilters.Sites,firstNotecard)
     this.setState({notecards: notecards, calls: calls, lastNotecard: lastNotecard, firstNotecard:firstNotecard,
                      siteDetails:siteDetails,hasSD:hasSD, assetid:assetid })

  }

 
  onBack(){
    this.props.handleClose();
  }

  
  /*
  * @brief Render a table of call data
  */
  render() {


    const generatePDF = () => {
      let docName = 'Severe Drowsiness Storyline-'+this.state.assetid+"-"+this.props.driverid+"-";
      this.setState({pdfGenerate:true})
      try{
        if(this.state.calls.length>0){
          // console.log('Name: ',this.state.calls);
          let calltime = moment(this.state.calls[0].timeofday);
          docName = docName+ calltime.format("MM0DD0YYYY0HHmm")
        }else{
          docName = docName+ this.state.firstNotecard.timeOfDay.format("MM0DD0YYYY0HHmm");
        }
      }catch(error){

      }
      
      docName = docName +'.pdf';
      //Capture the HTML into a canvas and print to PDF:
      let downloadPromise =  downloadComponentInPDF(document.querySelector('.storyreport-modal-content'),docName);
      //Wait for the download to complete
      downloadPromise.then( ()=>{
        this.setState({pdfGenerate:false}); //returned, disable the loading animation
      });
      downloadPromise.catch( (error)=>{
        console.log("PDF generate failed? ",error);
        this.setState({pdfGenerate:false}); //returned, disable the loading animation
      });
      
    }//end generate PDF
      
    let firstNotecardTime = '---';
    let lastNotecardTime = '---';
    try {
      firstNotecardTime = this.state.firstNotecard.timeOfDay.format(STORYLINE_DATE_FORMAT);
      lastNotecardTime = this.state.lastNotecard.timeOfDay.format(STORYLINE_DATE_FORMAT)
    } catch (error) {
      
    }

    return(
      <ReactModal isOpen={this.state.shown} className="modal-dialog storyreport-modal"
                          shouldCloseOnOverlayClick={true} 
                          onRequestClose={ ()=>{
                            // console.log("Requested closed")
                            this.props.handleClose()
                          }}
      >
      {this.state.pdfGenerate && <LoadedSpinner modal={true} class = "genreport" titletext='Saving to PDF' />}
      <div className='scrolling-report'>
        <FaFileDownload className='downloadicon' onClick={generatePDF}/>      
        <div className="storyreport-modal-content" > 
          <div className="top">
            
            <div className= 'header'>
              <img className="photo" src={this.props.photo || blankAvatar} alt=''  />
              <img className="e3-logo" src={logoE3} alt="EDGE3 Technologies, Inc." />  
            </div>
            
            <div ><span style={{fontWeight:'bold'}}>DriverID: </span>{this.props.driverid}</div>
            <div ><span style={{fontWeight:'bold'}}>AssetID: </span>{this.state.assetid}</div>
            <div ></div>                                        
            <div >Analysis from {moment.parseZone(this.props.starttime).format(STORYLINE_DATE_FORMAT)} - {moment.parseZone(this.props.endtime).format(STORYLINE_DATE_FORMAT)}</div>
            <div >All times in {this.state.siteDetails?this.state.siteDetails.timezone:"Site"} time.</div>
          </div>
          <div className="events">
            <div className='title'>Timeline</div>
            <div><span style={{fontWeight:'bold'}}>First Notecard: </span> {firstNotecardTime}</div>
            <div><span style={{fontWeight:'bold'}}>Last Notecard: </span> {lastNotecardTime}</div>
            <ul className='notecardlist'>
              {this.state.notecards.map( (card_,idx)=>{
                return <li className='listelem' key={card_.infractionID+"-cardlist-"+idx}> 
                          <div>{card_.timeOfDay.format(STORYLINE_DATE_FORMAT)} -- {card_.infractionTags[0]} {card_.didCall?'(Called-in)':''}</div>
                        </li>
              })}
            </ul>
            {this.state.calls.length!==0 && <div className='notecardsfoot'>Please see Table 1 below for more details on the Call-ins </div> }
          </div>
          {this.state.calls.length!==0 &&
            <div className="calls">
              <table className ="reportcalls-table">
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th>DriverID</th>
                    <th>Notecard Date</th>
                    <th>Notecard Time</th>
                    <th>Call Time</th>                  
                    <th>Call Subject</th>
                    <th>EDGE3 Staff</th>
                    <th>Contact</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.calls.map( (call_,idx_) => {
                    //  console.log("Call: ",call_);
                    return (
                      <tr key={"calllist-"+idx_}>
                        <td>{ call_.asset }</td>
                        <td>{this.props.driverid}</td>
                        <td>{ moment.parseZone(call_.timeofday).format('MMM DD, YYYY') }</td>
                        <td>{ moment.parseZone(call_.timeofday).format('HH:mm:ss') }</td>
                        <td>{ moment(call_.calltime).format('HH:mm:ss') }</td>
                        <td>{ call_.subject }</td>
                        <td>{ call_.username }</td>
                        <td>{ call_.contactname }</td>
                        <td>{ call_.callnotes.replace("|",", ") }</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className='tablefoot'>Table 1 Call-ins to {this.state.siteDetails?this.state.siteDetails.alias:"site"} for Driver {this.props.driverid}  </div>
            </div>
          }

          <div className="conclusion">
            <div className='title'>Conclusion</div>
            <div className='body'>It is our conclusion that the driver was getting more drowsy {this.state.hasSD&& "and severly drowsy"} as the journey progressed during these time frames.</div>
          </div>

          <div className="bottom">
            <div >EDGE3 Technologies Inc.</div>
            <div >Confidential information enclosed</div>
          </div>


          </div>
      </div>
        
      
    </ReactModal>
    )
  }
}


export { StoryReport };