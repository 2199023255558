import React, { Component } from 'react';
import ReactModal from 'react-modal';

// The timings, in seconds, that control when the "Network Delay" modal pops up
// and when it switches from thinking it's just a delay to allowing the API call to be retried
const DEFAULT_SHORT_OVERLONG_TIME = 20; //6
const DEFAULT_LONG_OVERLONG_TIME = 30; //12

// Default texts that correspond to the states previously configured
// const DEFAULT_SHORT_OVERLONG_TEXT = "A network request seems to be taking longer than expected. We'll keep trying.";
const DEFAULT_SHORT_OVERLONG_TEXT = "Please wait";
const DEFAULT_LONG_OVERLONG_TEXT = "A network request seems to have failed. Would you like to retry?";

// Delay between allowed clicks on the Retry button
const RETRY_COOLDOWN = 5;

/*
* @brief This is a moving spinner widget that signals to the user that something is going on
*
* The current display is an 'inchworm' effect with 4 dots moving vertically in a row
*/
export class Spinner extends Component {
    render() {
        return (
            <div className="spinner-wrapper preloader3 loader-block">
                <div className="circ1 loader-info"></div>
                <div className="circ2 loader-info"></div>
                <div className="circ3 loader-info"></div>
                <div className="circ4 loader-info"></div>
            </div>
        )
    }
}

/*
* @brief A compenent that can perform a delegate API call and handle issues that may arise
*
* @param isNotModal toggle to turn off the modal effect (takes over the entire viewport)
*/
export class ApiCaller extends Component {
    constructor(props) {
        super(props);
        // set the times and messages from defaults or our inputs
        this.shortOverlongTime = (this.props.shortOverlongTime || DEFAULT_SHORT_OVERLONG_TIME) * 1000;
        this.longOverlongTime = (this.props.longOverlongTime || DEFAULT_LONG_OVERLONG_TIME) * 1000;
        this.shortOverlongText = this.props.shortOverlongText || DEFAULT_SHORT_OVERLONG_TEXT;
        this.shortOverlongMsg = this.props.shortOverlongMsg || "Processing";
        this.longOverlongText = this.props.longOverlongText || DEFAULT_LONG_OVERLONG_TEXT;
        this.retryCount = 0;
        
        // set up methods to keep their 'this' value consistent
        this.onShortOverlong = this.onShortOverlong.bind(this);
        this.onLongOverlong = this.onLongOverlong.bind(this);
        this.onApiResult = this.onApiResult.bind(this);
        this.setLoadingState = this.setLoadingState.bind(this);
        this.retry = this.retry.bind(this);

        // set up our state
        this.state = {
            apiState: LoadingState.IDLE,
            justRetried: false,          
            startedOnce: false,
            _short: null,
            _long: null,
        };
        this.mounted = false;
        // do a required setup for the ReactModal library
        ReactModal.setAppElement('.amplify-container');
    }
    /*
    * @brief A helper function for setting our loading state and signalling to our parent as well
    */
    setLoadingState(state) {
        this.setState({apiState: state});
        if(this.props.onLoadingState){  this.props.onLoadingState(state);}
    }
    /*
    * @brief Do the actual API call and any other setup
    */
    componentDidMount() {
        // console.log("API Caller: mounting");
        this.setLoadingState(LoadingState.LOADING);
        this.retry()
        this.setState({startedOnce: true})
        // this.startedOnce = true;
        this.mounted = true;
    }
    componentWillUnmount() { //Called on exit
        this.state._short && clearTimeout(this.state._short);
        this.state._long && clearTimeout(this.state._long);
    }
    
    UNSAFE_componentWillReceiveProps(newProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(newProps) && this.state.startedOnce) {
            //Don't refresh the time if it is a change to the message of timeout
            if(  (this.props.shortOverlongMsg !== newProps.shortOverlongMsg)){
                this.shortOverlongMsg  = newProps.shortOverlongMsg;
                // console.log("Don't update based on the msg");
            }
            if(  (this.props.shortOverlongTime !== newProps.shortOverlongTime)){
                this.shortOverlongTime  = newProps.shortOverlongTime;
                // console.log("Don't update based on the TIME");
                clearTimeout(this.state._short);
            }
            if(this.retryCount !== newProps.retryCount){
                //  console.log("Passed new retryCount");
                this.retryCount = newProps.retryCount;
                // console.log("Passed: ",this.props,newProps);
                this.retry();
            }
            
        }
    }
    /*
    * @brief A handler for the timeout that happens after the 'short' configured time
    */
    onShortOverlong() {
        // console.log("ShortOverlong!");
        if (this.state.apiState !== LoadingState.FAILED) {
            this.setLoadingState(LoadingState.OVERLONG);
        }
    }
    /*
    * @brief A handler for the timeout that happens after the 'long' configured time
    */
    onLongOverlong(err) {
        console.log("Long overlong!", err);
        if(this.props.notifyOnLong){this.props.onApiResult(null);return;}
        this.setLoadingState(LoadingState.FAILED);
        this.setState({justRetried: false});
        
    }
    /*
    * @brief A handler for when the API result we're waiting for comes in
    */
    onApiResult(result) {
        clearTimeout(this.state._short);
        clearTimeout(this.state._long);
        this.setLoadingState(LoadingState.IDLE);
        this.props.onApiResult(result);
    }
    /*
    * @brief This starts the actual API call running and resets our waiting callbacks
    * 
    * Despite the name, it's called on the initial try as well as handling the Retry button logic.
    */
    retry() {

        this.state._short && clearTimeout(this.state._short);
        this.state._long && clearTimeout(this.state._long);
        this._apiCall = this.props.apiCall();
        this._apiCall.then((data)=>{
            if(this.mounted) {
                this.onApiResult(data)
            }
        }
        ).catch(this.onLongOverlong);
        
        // console.log("Set timeout on : ",this.shortOverlongTime);
        this.state._short = setTimeout(this.onShortOverlong, this.shortOverlongTime);
        this.state._long = setTimeout(this.onLongOverlong, this.longOverlongTime);
        this.setState({justRetried: true});
        setTimeout(() => {this.setState({justRetried: false});}, RETRY_COOLDOWN * 1000);

    }
    render() {
        const shouldDisplay = isApiFailure(this.state.apiState);
        const displayStyle = shouldDisplay ? {} : {display: 'none'};
        const isRetryable = shouldDisplay && this.state.apiState === LoadingState.FAILED;
        const isTrying = this.state.apiState !== LoadingState.FAILED || this.state.justRetried;
        // console.log("isTrying: ",isTrying,this.state.apiState,this.state.justRetried);
        var messageText = "Loading"; // default text for when no other state applies, shouldn't get shown
        var titleText = "Network Delay"
        if (isApiFailure(this.state.apiState)) {
            if (this.state.apiState === LoadingState.FAILED) {
                messageText = this.longOverlongText;
            } else {
                messageText = this.shortOverlongText;
                titleText = this.shortOverlongMsg;
                // console.log("Set titleText: ",titleText, this.shortOverlongTime);
            }
        }
        const actualContent =
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{titleText}</h5>
                </div>
                <div className="modal-body">
                    <p className={"api-caller-message api-caller-state-" + this.state.apiState}>
                        {messageText}
                    </p>
                </div>
                <div className="modal-footer justify-content-center">
                    {isTrying ? <Spinner /> : <span></span>}
                    {isRetryable &&
                        <button className="btn btn-success"
                                onClick={ this.retry} disabled={this.state.justRetried}>
                            {this.state.justRetried ? 'Retrying...' : 'Retry'}
                        </button>
                    }
                </div>
            </div>;
        // If we're supposed to be modal, wrap in a ReactModal,
        // otherwise leave that part out and just display our content as-is in context
        if (this.props.isNotModal) {
            return (
                <div className="api-caller" style={displayStyle}>
                    {actualContent}
                </div>
            );
        } else {
            return (
                <div className="api-caller" style={displayStyle}>
                    <ReactModal isOpen={shouldDisplay} className="modal-dialog">
                        {actualContent}
                    </ReactModal>
                </div>
            );
        }
    }
}

/*
* The states that an API call can be in
*/
export const LoadingState = {
    IDLE: 'idle',
    LOADING: 'loading',
    OVERLONG: 'overlong',
    FAILED: 'failed',
};

/*
* @brief A helper for the logic of when the 'Network Delay' modal should be displayed
*/
export function isApiFailure(loadingState) {
    return loadingState !== 'idle' && loadingState !== 'loading';
}