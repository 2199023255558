
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { Auth, API } from 'aws-amplify';
import * as moment from 'moment';
import '../VideoCard.css';
import '../NoteCard-Comments.css'

// import '../VideoCard.css';

import '../ExpandedCard.css';
import '../ExpandedNotecard/NoteCard-Expanded.css';
// import './ExpandedCardBase.css';



import { SEVERITIES, getStreamURL,OTHERHWTAGS,reviewGroups } from '../Util.js';
import {NCTitle, NCLeftBottom, NCLeftTop, NCVideo, NCComments, NCFooter, NCBack, getTypeAndTagText} from '../ExpandedNotecard/NoteCard-Components.js'



// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

// See https://momentjs.com/docs/#/displaying/ for formatting syntax
const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';


/**
 * Generalize the Expanded notecard so that it can be used by both the infraction and videocut notecards
 * This is currenlty only used by videocut notecards
 */
export const ExpandedCardBase = ({style,details,...props}) =>{

    const displayName = 'ExpandedCardBase';

    //General card controls
    const [showModal, setShowModal] = React.useState(true);
    const [showFront, toggleShowFront] = React.useState(true); 
    

    //State variables tracking the returned data from the API fetch
    const [fetchMapTimeID, setFetchMatchTime] = React.useState(null);
    const [comments, setComments]= React.useState([]);
    const [gpsmap,setGPSMap]= React.useState(null);
    const [lat, setLat]= React.useState(null);
    const [lon, setLon]= React.useState(null);
    const [speed, setSpeed]= React.useState(null);
    const [dlLink, setDLLink]= React.useState(null);
    const [metadata, setMetaData]= React.useState(null);
    const [timeOfDay, setTimeOfDay]= React.useState(null);
    const [driverid,setDriverID]= React.useState(null);
    const [name,setName]= React.useState(null);
    const [videoURL, setVideoURL] = React.useState(props.videoURL);
    const [linkedVidID, setLinkedVidID] = React.useState(null);
    const [infractionTags, setInfractionTags] = React.useState(props.infractionTags||[]);
    const [severity, updateSeverity] = React.useState("LOW");
    const [startTime, setStartTime] = React.useState(props.openTimer || new Date());

    //First execution 
    React.useEffect(()=>{
        // console.log(`[${displayName}] loaded props: `,props);
        //Check if we have a cacheDB defined for the card
        if(!props.cacheDB){
            //Delay opening/showing the notecard
            window.setTimeout(() => {
                setShowModal(true);
            }, EXPANDED_CARD_SHOW_LATENCY);
        }
        //Execute API request to get more details
        fetchExpandedDetails();
         //Handle the exit // Anything in here is fired on component unmount.
        return () => {
        }
    },[]) //[] -> execute once
    
    /*
    * @brief The definition of the API call that we need to do to display this card
    */
    const fetchExpandedDetails = async (_data) => {
        // console.log("Load card: ",new Date() - startTime);
        // console.log("Get notecard data for: ",props.infractionid);
        // Create the API call + promise wrapper that will get the card details
        
        // console.log("Get card data with: ",this.props);
        let typeAndTag = getTypeAndTagText(props)
        const authReturn = await Auth.currentSession();
        let apiName = "AuthLambda";
        let path = "/getExpandedCardData";  
        let myInit = {
            body: {
                token: authReturn.idToken.jwtToken,
                card:{
                    id: props.cardID,
                    infractionid: props.infractionid,
                    typeAndTag: typeAndTag,
                    timeofday: props.timeOfDay,
                    cardtype:props.notecardtype,
                }
            }
        };
        //Send the API request and wait for a return:
        const apiReturn = await API.post(apiName, path, myInit);
        //if the API returned check for the response
        if(apiReturn ){
            // console.log("Data return: ",apiReturn);
            processDetailReturn(apiReturn)
        }
    }


   /**
    * Process the return from the API request
    * @param {*} _data : return from the API reqeust
    * @returns 
    */
    function processDetailReturn(_data){
        if(!_data){return;}

        if(!_data.gpsmap && _data.lat && _data.lon){         
            // setFetchMatchTime(setTimeout(() => {   getMapCall() }, 6000));
        }
        // Group all updated state variables together
        setComments(_data.comments);
        setGPSMap(_data.gpsmap);
        setLat(_data.lat); setLon(_data.lon); setSpeed(_data.speed);
        
        setMetaData(_data.metadata);
        setTimeOfDay(moment.parseZone(_data.timeofday));
        // only add the driver ID to the state if it is defined
        if(_data.driverid != null){setDriverID(_data.driverid); setName(_data.driverid);}

        // If the video wasn't loaded from cache, then link it from the API return
        let streamURL = getStreamURL(_data.video);
        if(!videoURL){ setVideoURL(streamURL); }else{
            // console.log("Already has videoURL");
        }
        setDLLink(props.dlurl||_data.dlurl);

        // Set the linked video ID if it is not known
        if(!linkedVidID && _data.parentid){ setLinkedVidID(_data.parentid);}
    }
    
    /**
     * Handle the callback for the button/close action
     * @param {*} _data 
     */
    function confirmClose(_data){
        if(props.handleClose()){props.handleClose();}
    }
    //Handle what to do if the hamburger button is pressed
    function hamburgerToggle(){
    }
    /**
     * The commenter has returned a comment added/edited
     * Update the stored comments in the local copy and pass the new comment on 
     * to be added to the cloud     
     * @param {*} _comment : object (action,text,originaltext)
     * @returns 
     */
    function updateComment(_comment){
        console.log("updated comment:",_comment);
        if(!_comment){return;}
        if(!_comment.action){return;}
        //handle both adding and editing comments
        try {
            switch(_comment.action){
                case 'add':{
                    const newComments = comments.slice(); //copy the current array of comments
                    
                    //Set up the object to add to the current comments                    
                    let objToAdd = {text: _comment.text, timestamp: moment().utc().format()}
                    if(props.userInfo && props.userInfo.username){ //add the username if we have it
                        objToAdd.username = props.userInfo.username
                    }

                    //Add the new comment to the exisiting comments
                    newComments.push(objToAdd)
                    //Save the comments to the class state
                    setComments(newComments)
                    // this.setState({comments:newComments})
                }break;
                case 'edit':{
                    //Iterate over the comments and build a new array (map give us the new array)
                    //Need to have a new array so that the state will change to trigger the render 
                    //The previous version modified the array in place and the relied on a window timeout to change another parameter
                    //This is bad appraoch and is incompatible with the new components that are listening for the array change
                    let newComments = (comments||[]).map( (comment_)=>{
                        //Compare what is currently in the comments array against the new data we want to change
                        if(comment_.text === _comment.originalText){ //Find the commment we are modifying
                            comment_.text = _comment.text; //update the comment
                        }
                        return comment_; //Return the element to put in the new array
                    });
                    setComments(newComments)
                    // this.setState({comments:newComments}); //save the update to the class state
                }break;
            }//end of switch
            //Pass the new comment along to the calling class
            if(_comment.action==='add' && props.cardChange){                
                props.cardChange({
                    comment:  _comment.text, //must include to pass the valid card change check in App.js                     
                    cardID: props.cardID, //must include to pass the valid card change check in App.js
                    tag: props.tag,
                    infractionID:props.infractionID,
                    infractionType: props.infractionType,
                    username: props.userInfo.username,
                    changetype:'add-comment'
                });
            }
        } catch (error) {
            console.log("Failed to update comment: ",error);
        }
        
    }
    //Handle what happens when infraction tags are changed
    function infractionTagChanged(){
    }
    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        // console.log("ExpandedCardBase Render:" ,props);
       //Define the classname of the modal notecard, allow the input props to add an additional tag for specific formatting
       let className = `modal-dialog expanded-card-modal ${props.className}`;
       //Return the HTML code to display:
       return (
        <div className="expanded-card-base" id='expanded-cardbase-id' >
            <ReactModal isOpen={showModal} className={className} 
                        shouldCloseOnOverlayClick={true}
                        //  onRequestClose={ this.props.handleClose || (() => {console.log("Requested close");})}
                        onRequestClose={confirmClose}
            >
                <div className="modal-content" with_hw= {'display'} style={style}>
                    <div className="modal-body expanded-card">
                        {showFront? 
                            <React.Fragment>
                                <NCTitle props = {props} timeOfDay= {null} severity={severity} onClick={hamburgerToggle}/>
                                <div className="card-upper">
                                    <div className="card-basics">  
                                        <NCLeftTop  props = {props} driverName={props.name} top3Data={null} infractionTags={null}
                                                    videoURL = {videoURL} timeOfDay= {null} noPhone={true}/>
                                        {/* <NCLeftBottom props = {this.props} infractionTags={this.state.infractionTags} infractionTagList={this.state.infractionTagsList}
                                                        onTagChange={this.infractionTagChanged} /> */}
                                        
                                    </div>
                                    <NCVideo props = {props} videoURL = {videoURL} startTime={startTime}/>                                   
                                </div>
                                <NCComments props = {props} comments = {comments} username={props.userInfo.username} onUpdate={updateComment}/> 
                                <NCFooter props = {props} downloadLink={dlLink} linkedVidID={linkedVidID} infractionTags={infractionTags}
                                            onTagChange={infractionTagChanged} onUpdateComment={updateComment} allowedButtons={props.buttonSet}/>
                                
                            </React.Fragment>
                            :
                            <div>back</div>
                        }
                    </div>
                    
                </div>{/* end of the content div*/}
            </ReactModal>
        </div>
       );
   };//end of render()

   //Function component is expecting the return, add return to the render() call
   return render();

}

