import React, { Component } from 'react';

import './navbar.css';
import './userprofile.css'


/*
* @brief The menu bar with the main navigation links for the site
*/
class FlatMenuBar extends Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const menu_items = (this.props.authed ? this.props.authed_items : this.props.unauthed_items) || [];
        const make_link = (item,index) => {
            const clicked = () => {
                this.props.onLinkClicked(item);
            }
            return <FlatMenuLink key = {index} text={item.text} href={item.href} icon={item.icon} onLinkClicked={clicked} />
        };
        const menu_links = menu_items.map(make_link);
        return (
        <nav className="pcoded-navbar is-hover main-menu-bar">
            <div className="pcoded-inner-navbar">
                <ul className="pcoded-item pcoded-left-item pcoded-left-align" item-border="true" item-border-style="none" subitem-border="true">
                    {menu_links}
                </ul>

            </div>
        </nav>
        );
    }
}

/*
* @brief An individual link in the menu bar
*/
class FlatMenuLink extends Component {
    render() {
        const icon = this.props.icon;
        return (
        <li className="pcoded-hasmenu is-hover noicon">
            <a href={"#" + this.props.href} id = {this.props.href} onClick={(e) => {e.preventDefault(); this.props.onLinkClicked();}} >
                {icon && <span className="pcoded-micon"><i className={icon} aria-hidden="true"></i></span>}
                <span className="pcoded-mtext">{this.props.text}</span>
                <span className="pcoded-mcaret"></span>
            </a>
        </li>
        );
    }
}


// Only exporting the FlatNavBar, the other pieces are considered internal details
export { FlatMenuLink, FlatMenuBar };
