import * as moment from 'moment';

import { MapFetchingDialog,getColor,getSpeedFromCoordinates, getTimeFromCoordinates } from './map-utils.js';
import {processJourney,formatSpeed,distEuclid } from './map-utils.js';
import { EDGE3_PRIMARY_COLORS } from '../Util.js';

//OpenLayers imports:
import {Point} from 'ol/geom';
import {Polyline} from 'ol/format';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import {containsCoordinate} from 'ol/extent';
import { Circle as CircleStyle, Icon, RegularShape } from 'ol/style';

// import FlowLine from 'ol-ext/style/FlowLine';
import FlowLine from './MultiFlowLine.js';
import { ol_coordinate_offsetCoords } from 'ol-ext/geom/GeomUtils';
import Hover from "ol-ext/interaction/Hover";

const marker_img = 'http://openlayers.org/en/latest/examples/data/dot.svg'
export const arrow_img2 = 'https://e3d-is2y4ihl25.s3.us-west-2.amazonaws.com/public/black-arrow-small.png'
export const arrow_img = 'https://e3d-is2y4ihl25.s3.us-west-2.amazonaws.com/public/gray-arrow-small.png'


const ROUTELINE_COLOR_DEFAULT = [237, 212, 0, 0.8];//default yellow
const ROUTELINE_COLOR_OUTLINE_DEFAULT = [149, 134, 0, 1];//default yellow (down shaded)

// const EDGE3BLUE_COLOR = [0,175,237,1];//edge3 colors (blue)
// const EDGE3BLUE_COLOR_OUTLINE = [0,100,136,1];//google edge3 color (blue down shaded)

// const BING_COLOR = [54,168,255,1];//bing blue
// const GOOGLE_COLOR = [15,83,255,0.8];//google
// const GOOGLE_COLOR_OUTLINE = [15,51,248,1];//google edge3 color

const ROUTELINE_COLOR = ROUTELINE_COLOR_DEFAULT;
const ROUTELINE_COLOR_OUTLINE = ROUTELINE_COLOR_OUTLINE_DEFAULT;


export const journeyStyles = {
    //Define the style for the normal route 
    'route': [ new Style({
      stroke: new Stroke({
        width: 10,                
        color: ROUTELINE_COLOR,
      }),
    })],
    'route_outline': [ new Style({
        stroke: new Stroke({
          width: 10,                
          color: ROUTELINE_COLOR_OUTLINE,
        }),
      })],
    //Define the Style for the route with the speed gradient overlay
    'route_speed': [
        new Style({
            fill: new Fill({
                color: ROUTELINE_COLOR,
            }),
            stroke: new Stroke({
                color: 'rgba(0,0,0,0.01)', // Non transparent stroke so that feature is detected in foreachFeatureAtPixel
                width: 5
            })
        }),
        new FlowLine({
   
            visible: false,
            lineCap: 'round',
            // color: [220, 212, 0, 0.8], //Debug - Single color for the Flowline
            // color: function(f, step,_lineIdx){ //Debug -Change the line color for each line
            //     // console.log("Line to draw: ",_lineIdx);
            //      switch(_lineIdx%9){
            //         case 0: {return [255,0, 0, 0.8];}break;
            //         case 3: {return [255, 0, 120, 0.8];}break;
            //         case 6: {return [255, 120, 0, 0.8];}break;

            //         case 1: {return [0, 255, 0, 0.8];}break;
            //         case 4: {return [0, 255, 120, 0.8];}break;
            //         case 7: {return [120, 255, 0, 0.8];}break;

            //         case 2: {return [0, 0, 250, 0.8];}break;
            //         case 5: {return [0, 120, 250, 0.8];}break;
            //         case 8: {return [120, 0, 250, 0.8];}break;
            //         // default:{return [250, 0, 250, 0.8];}break;
            //     }
            // },
            //Define the color function that is called by the FlowLine, this is used to choose the 
            //color based on the speed of the asset at the given segment of the Journey
            color: function(f, step,_lineIdx){
                let min =0;
                var seg = [];
                let time_1 = new Date();
                try {

                    let lineIdx = Math.max(_lineIdx,0);
                    //Get the values from the line that will be used to extract the segment
                    //And the associated speed from the segment
                    let coords,distArray,speedArray,maxSpeed,lineLength;
                    let routes = f.get('routeData');
                    coords = routes[lineIdx].coords; //each GPS point along the line
                    distArray = routes[lineIdx].distArray; //precomputed interpoint distances
                    speedArray = routes[lineIdx].speedArray; //recorded speed at each point
                    maxSpeed = f.get('maxSpeed');                    
                    lineLength = routes[lineIdx].lineLength; //total length of the projected line

                    if(speedArray){
                        try {
                            //Don't pass the line here, the line is expensive to retrieve, use the precomputed line length and coordinates
                            var timeToGet = getCoordinateAtSegNoLine(step*lineLength,lineLength, seg,coords,distArray)
                            //Set the speed of the segment to the average of the before and after speed of the segment
                            var speed1 = speedArray[seg[0][2]];
                            var speed2 = speedArray[seg[1][2]];
                            var segmentSpeed = (speed1+speed2)/2;
                            //Convert the speed value into a hue, use the normalized speed value of 0-1 and map onto 0-230 hue
                            var speedHue = 230*(segmentSpeed-min)/(maxSpeed-min);  //map to purple to orange
                            if(segmentSpeed > maxSpeed){ //Check if the speed is over the speed limit, is so set to full red
                                speedHue = 255;
                            }
                            let colorVal = getColor(speedHue);
                            // console.log("Render: ",new Date - time_1);
                            return colorVal;
                        } catch (error) {
                            console.log("Fail on speed check: ",error);
                            return ROUTELINE_COLOR;    
                        }
                    }else{
                        return ROUTELINE_COLOR;
                    }
                }catch(error){
                    console.log("Coloring fail: ",error,_lineIdx);
                    return ROUTELINE_COLOR;
                }
                
            },
            splits: 128,
            width: 7,
            geometry: function (f) {return f.getGeometry();}
        })
    ],
    'icon': new Style({
      image: new Icon({
        color: '#ED7C1C',                  
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: [0.5,0.5],
        src: marker_img
        }),
    }),
    'geoMarker': new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: 'black'}),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    }),
   
};

/** Define the action to take when a style is set on the Journey Layer
 *  This applies the Start and End Journey markers, modifies the Line widths,
 *  and addds the direction arrows
 */
export const journeyStyler = (feature, resolution)=>{

    // console.log("Check on feature: ",feature);   

    let featureType = feature.get('type');
    if(!featureType){return new Style();}

    let styleReturn =  journeyStyles[featureType];

    if(!featureType.includes('arrows' )){return styleReturn;}
    //Handle the routes with the arrows:
    styleReturn = [];

    // let lineWidth = 4 * (1/resolution); //Original resoltion
    let lineWidthScalar = 5;
    let lineWidth = lineWidthScalar * (1/resolution); //thicker lines
    let lineWidthOutline = lineWidth*1.25;

    // let lineWidth = 10 * (1/Math.pow(resolution,0.4)); //thicker lines
    // let lineWidthOutline = 14 * (1/Math.pow(resolution,0.4)); //thicker lines

    let arrowScale = (0.075) * ((2/resolution)/2) * 0.5;
    let maxResolutionLevel = 0.3;

    if(resolution < maxResolutionLevel){ //Reverse the scale:
        arrowScale = (0.075) * (1/resolution)/4;
        lineWidth = (2) *(2/maxResolutionLevel) * (.2/.3)
    }
    // console.log("Resolution: ",resolution,lineWidth);

    switch(featureType){
        case 'route_speed_arrows':{
            let clickPath = journeyStyles['route_speed'][0];
            let flowLine = journeyStyles['route_speed'][1];
            
            // if(resolution > maxResolutionLevel){
            clickPath.getStroke().setWidth(lineWidth);
            // flowLine.getStroke().setWidth(lineWidth);    
            flowLine.setWidth(lineWidth)    
            // }
            styleReturn.push(clickPath);
            styleReturn.push(flowLine);
            
        }break;
        case 'route_arrows':{
            let outlineStyle = journeyStyles['route_outline'][0];
            let tempStyle = journeyStyles['route'][0];


            if(resolution >= maxResolutionLevel){
                outlineStyle.getStroke().setWidth(lineWidthOutline);    
                styleReturn.push(outlineStyle); 
            }
            
            
            tempStyle.getStroke().setWidth(lineWidth);    
            styleReturn.push(tempStyle);
            
        }break;
    }
    //Add the start and end markers:
    try {
        let isSingleView = feature.get('singleview') || false;
        if(isSingleView){ //Only show the markers when a single Journey is selected
            //Check if the marker view has been enabled on the Journey:                            
            if(feature.get('startpoint')){ 
                //if enabled then extract the location
                let startCoord = feature.get('startLoc');
                //Create a new Circle at the location and set the color to green
                let startPointStyle = new Style({
                    geometry: new Point([startCoord[0],startCoord[1]]),
                    image: new CircleStyle({
                        radius: 10,
                        fill: new Fill({color: 'green'}),
                    }),
                });
                startPointStyle.getImage().setRadius(lineWidth*1.15);
                //Add the new circle to the style set to render
                styleReturn.push(startPointStyle);//start style push                        
            }
            if(feature.get('endpoint')){
                //if enabled then extract the location
                let endCoord = feature.get('endLoc');
                //Create a new Circle at the location and set the color to red
                let endPointStyle = new Style({
                    geometry: new Point([endCoord[0],endCoord[1]]),
                    image: new CircleStyle({
                        radius: 12,
                        fill: new Fill({color: 'red'}),
                        }),
                });
                endPointStyle.getImage().setRadius(lineWidth*1.15);
                //Add the new circle to the style set to render
                styleReturn.push(endPointStyle);//end style push
            }
        }//End singleView check
    } catch (error) {
        console.log("Fail on start/end points:" ,error);
    }

    //Render the direction arrows:
    if(resolution < 1.5){
        // var size = 64;
        // console.log("Arrow size: ",(0.075) * ((4/resolution)/2),((4/resolution)/2),resolution)
        let minSegmentLength = Math.max(arrowScale*(200*resolution)*2,14);
        function applySplitPoints(_splitPoints,_styleReturn) {
            //Compute a polygon expansion to create the offset coordinates:                
            let offsetCoord = _splitPoints;
            try{
                if(featureType === 'route_speed_arrows' && _splitPoints.length > 2){
                    offsetCoord = ol_coordinate_offsetCoords(_splitPoints,((lineWidth) * resolution));;
                }else{
                    offsetCoord = ol_coordinate_offsetCoords(_splitPoints,((lineWidth/4) * resolution));;
                }
            }catch(error){}

            _splitPoints.forEach( function(point,idx) {
                if(offsetCoord && offsetCoord[idx]){
                    let arrowStyle = null;
                    if(featureType === 'route_speed_arrows'){
                        arrowStyle = new Style({
                            geometry: new Point([offsetCoord[idx][0],offsetCoord[idx][1]]),
                            image: new Icon({
                                src: arrow_img2,
                                scale: 0.075,
                                rotation: point[2],
                                // opacity: 0.5,
                            })
                        });
                    }
                    else{
                        arrowStyle = new Style({
                            geometry: new Point([offsetCoord[idx][0],offsetCoord[idx][1]]),
                            image: new Icon({
                                src: arrow_img,
                                scale: 0.075,
                                rotation: point[2],
                                opacity: 0.5,
                            })
                        })
                    }
                    
                    if(resolution > maxResolutionLevel){
                        arrowStyle.getImage().setScale(arrowScale)
                    }
                    _styleReturn.push(arrowStyle);
                }
            });
        }
        
        let geomType = feature.getGeometry().getType();
        let splitPoints;
        switch(geomType){
            case 'LineString':{
                splitPoints = splitLineString(feature.getGeometry(),0,feature,minSegmentLength, {alwaysUp: true, midPoints: true});
                applySplitPoints(splitPoints,styleReturn)
            }break;
            case 'MultiLineString':{
                let lineStrings = feature.getGeometry().getLineStrings();
                for(let idx=0; idx<lineStrings.length; idx++){
                    try {
                        splitPoints = splitLineString(lineStrings[idx],idx,feature,minSegmentLength, {alwaysUp: true, midPoints: true});
                        applySplitPoints(splitPoints,styleReturn)
                    } catch (error) {
                        console.log("Error mls splits:" ,error);
                    }
                    
                }//end loop over line geometries            
            }break;
        }
    };//end resolution check
    return styleReturn;
}


/** Get the coordinate at a distance from the start (modified from the ol-ext library)
        * @param {number} r distance from the start 
        * @param {number} _length projected length of the LineString
        * @param {Array<Array<coordinate>>} seg if provided fill the segment concerned
        * @param {Array<ol.coordinate>} _coords The GPS coordinates along the line
        * @param {Array<number>} _distArray precomputed distances between consecutive points
        * @return {ol.coordinate} 
        */        
const getCoordinateAtSegNoLine = function (r,_length, seg,_coords,_distArray) {
    var c, d;
    
    if (r < 1e-10) {
        if (seg)  {
            c = _coords;
            seg[0] = c[0];
            seg[1] = c[1];
            seg[0][2] =0;
            seg[1][2] =1;
        }
        // console.log("1: ",r);
        return _coords[0];
    }
    if (_length-r < 1e-10) {
        if (seg) {
            c = _coords;
            seg[0] = c[c.length-2];
            seg[1] = c[c.length-1];
            seg[0][2] =c.length-2;
            seg[1][2] =c.length-1;
        }
        // console.log("2:",this.getLength()-r,this.getLength(),r);
        return _coords[_coords.length-1];
    }
    if (!seg) seg=[];
    var s = 0;
    
    var coord = _coords;//this.getCoordinates();
    
    // console.log("Coordinates: ",coord);
    let dist2dTime = 0;
    for (var i=1; i<coord.length; i++) {
        d= _distArray[i];
        if (s+d >= r) {
            // console.log("Larger than r: ",r,s,d,i)
            var p0 = seg[0] = coord[i-1];
            var p1 = seg[1] = coord[i];
            seg[0][2] = i-1;
            seg[1][2] = i;
            // var startTime = performance.now()
            d = distEuclid(p0,p1)
            // var endTime = performance.now()
            let returnval = [
                p0[0] + (r-s) * (p1[0]-p0[0]) /d,
                p0[1] + (r-s) * (p1[1]-p0[1]) /d
            ];
            // dist2dTime +=endTime-startTime;
            return ;
            
            // return endTime-startTime;
        }
        s += d;
        
    }
};


        
/** Split the LineString into multiple segments based on length, this is used to draw the 
 *  direction arrows at regular intervals (taken from the ol-ext library)
 */
export const splitLineString = (geometry,_lineIdx,_feature, minSegmentLength, options) =>{
    
    let routes = _feature.get('routeData');
    let coordsArray = routes[_lineIdx].coords;//
    let bearingArray = routes[_lineIdx].bearingArray;
    let timeArray = routes[_lineIdx].timeArray;
    let distArray = routes[_lineIdx].distArray;
    // console.log("routes:" ,routes);
    
    function calculateSplitPointCoords(startNode, nextNode, distanceBetweenNodes, distanceToSplitPoint) {
        try{
            var d = distanceToSplitPoint / distanceBetweenNodes;
            var x = nextNode[0] + (startNode[0] - nextNode[0]) * d;
            var y = nextNode[1] + (startNode[1] - nextNode[1]) * d;
            return [x, y];
        }catch(error){
            // console.log("Error on split: ",distanceToSplitPoint,distanceBetweenNodes,startNode,nextNode)
            return null;
        }
        
    };
    
    var splitPoints = [];
    var coords = coordsArray;
    
    var coordIndex = 0;
    var startPoint = coords[coordIndex];
    var nextPoint = coords[coordIndex + 1];
    var angle = bearingArray[coordIndex];
    
    var timeDelta = timeArray[coordIndex + 1] - timeArray[coordIndex];
    timeDelta /=1000;
    if(timeDelta > 5){ return splitPoints; }
    
    var n = Math.ceil(geometry.getLength()/minSegmentLength);
    var segmentLength = geometry.getLength() / n;
    var currentSegmentLength = options.midPoints ? segmentLength/2 : segmentLength;
    // console.log("Length: n:",n,segmentLength,minSegmentLength,geometry.getLength());
    for (var i = 0; i <= n; i++) {
        var distanceBetweenPoints = distEuclid(startPoint, nextPoint);
        currentSegmentLength += distanceBetweenPoints;
        // console.log("Segment: ",currentSegmentLength);
        if (currentSegmentLength < segmentLength) {
            coordIndex++;
            if(coordIndex < coords.length - 1) {
                
                var timeDelta = timeArray[coordIndex + 1] - timeArray[coordIndex];
                timeDelta /=1000;
                if(timeDelta > 5){
                    return splitPoints;
                }
                startPoint = coords[coordIndex];
                nextPoint = coords[coordIndex + 1];
                angle = bearingArray[coordIndex];
                i--;
                continue;
            } else {
                if (!options.midPoints) {
                    var splitPointCoords = nextPoint;
                    if (!options.extent || containsCoordinate(options.extent, splitPointCoords)) {
                        splitPointCoords.push(angle);
                        splitPoints.push(splitPointCoords);
                    }
                }
                // console.log("Break at:", i,coords,coords.length,coordIndex);
                break;
            }
        } else {
            var distanceToSplitPoint = currentSegmentLength - segmentLength;
            var splitPointCoords = calculateSplitPointCoords(startPoint, nextPoint, distanceBetweenPoints, distanceToSplitPoint);
            // console.log("Split: ",splitPointCoords,startPoint,nextPoint,distanceBetweenPoints, distanceToSplitPoint);
            if(splitPointCoords){
                startPoint = splitPointCoords.slice();
                if (!options.extent || containsCoordinate(options.extent, splitPointCoords)) {
                splitPointCoords.push(angle);
                splitPoints.push(splitPointCoords);
                }
                currentSegmentLength = 0;
            } 
        }
    }//end for loop       
    // console.log("Split points return: ",splitPoints);   
    return splitPoints;
};//end splitLineString

