import icon_eyesClosed from './drowsypin.png'; 
import icon_eyesOffRoad from './look_away_pin.png';
import icon_phone from './phone_pin.png'; 
import icon_eating from './eating_pin.png';
import icon_unauthorized_occupant from './Unauthorized_Occupant_pin.png';
import icon_pin from './mapIcon.svg';




export default {
    EyesClosed: icon_eyesClosed,
    EyesOffRoad: icon_eyesOffRoad,
    Phone: icon_phone,
    Eating: icon_eating,
    UnauthOccupant:icon_unauthorized_occupant,
    MapPin: icon_pin,
}