import axios from 'axios';

/**
 * Reference code: https://gist.github.com/matthewsuan/2bdc9e7f459d5b073d58d1ebc0613169
 */

const MAX_REQUESTS_COUNT = 4; //set the maximum number of concurrent downloads
const INTERVAL_MS = 50; //set the polling rate to check if files have completed downloading
let PENDING_REQUESTS = 0; //log the number of requests that are currently downloading
let REQUESTS_MADE = 0; //log the total number of requests processed

// create new axios instance
const api = axios.create({});
let timeSince =null; //Add a debugger timer to log how long the downloads take to complete

/**
 * Axios Request Interceptor: Intercept a request made to this instance to download a new file. If the maximum number of requests
 * are already being handled this will stall and try to fulfill the promise again on the next interval (INTERVAL_MS). This process is transparent
 * to the calling function, from their perspective the file is downloading and they are waiting to receive the promise fulfillment
 */
api.interceptors.request.use(function (config) {
	return new Promise((resolve, reject) => {
		let interval = setInterval(() => {
      
		if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
			PENDING_REQUESTS++;
			//Debugging - leave for future benchmarks
        // if(!timeSince && PENDING_REQUESTS>0){
        //   console.log("Start downloads");
        //   timeSince = new Date();
        // }
	        REQUESTS_MADE++;
				clearInterval(interval)
				resolve(config)
			} 
		}, INTERVAL_MS)
	})
});//end of request interceptor

/**
 * Axios Response Interceptor: Intercept the response from Axios that the file has been downloaded. Update the pending count so the next file
 * that is waiting to start downloading can begin.
 */
api.interceptors.response.use(function (response) {
	PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);//decrement the number of files that are processing, this allows the next to begin
  //Debug timer to test time to download files
//   if(REQUESTS_MADE%50===0 && timeSince){   console.log("Time to download: ",PENDING_REQUESTS,REQUESTS_MADE,new Date() - timeSince); }
	return Promise.resolve(response);//return that the promise was completed
}, function (error) {
	PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1); //error, decrement the number of files that are processing, this allows the next to begin
	return Promise.reject(error);// send back the error catch on the promise 
});//end of response interceptor

export default api;//export the instance of the Axios