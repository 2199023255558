
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
// import './VideoCard.css';
import './ArchiveDialog.css';
import { setupPerf } from './Perf.js';
import { ApiCaller } from './ApiCaller.js';
import { Auth, API } from 'aws-amplify';
import { motion } from "framer-motion"
import * as moment from 'moment';
/*
* @brief Present a Modal dialog to the user to choose to restore the Archived footage
*/
export class ArchiveDialog extends PureComponent{
    constructor(props) {
        super(props);
        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.state = {
            restoreClicked: false,
        };
        this.perfName = 'ArchiveDialog';
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    
    componentDidMount() {
    }

     /*
    * A handler used by the ApiCaller
    */
    onLoadingState(state) {   this.setState({loadingState: state});  }

    /*
    * @brief Handle the user clicking on restore
    */
    onRestoreClick(){
        this.setState({restoreClicked:true});
         try {
            // console.log("Sending command to restore video: ",this.props.id);
            const restorePromise = Auth.currentSession().then(
                (auth) => {
                  let myInit = {
                    body: {
                      token: auth.idToken.jwtToken,
                      apiName: "restoreArchivedVideo",                 
                      mode: "Process",
                      id: this.props.id,
                    }
                  };     
                  return API.post("AuthLambda", "/apiRouter", myInit);
                });       
            // const res = await Promise.all(restorePromise); //wait for this response
            // console.log("Returned from restore request: ",res)

            restorePromise.then(data => {
                // console.log("Returned from restore request: ",data)
                this.props.handleClose();
                
            })//process the returned data
        } catch (error) {
            console.log("highlights link error: ",error);
        }
        
    }//end onProcessClick

 
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        //Handle what happens when the user click off the card
        const confirmClose = () => {
            // console.log("Declined restore: ");
            this.props.eventNotify({
                type: 'link',
                data: {
                    href: 'analytics',
                    extra: {
                        groupconfig: this.props.groupconfig,
                        video: null,
                        filter: this.props.filter,
                    }
                },
            });
            // this.props.handleClose();
        }//end confirmClose
        let roiUpdateStyle = {visibility:"hidden"};      
        if(this.state.roi){
            roiUpdateStyle.visibility = "visible"; 
        }
        return (
            
            

            <div className="archive-dialog">      
                {/* <ReactModal isOpen={this.state.shown} className="modal-dialog archive-dialog-modal"
                            // shouldCloseOnOverlayClick={true}
                            onRequestClose={confirmClose}
                > */}
                <div className='archive-dialog-modal'>
                    <div className="modal-content">
                        <h5 className="archivetitle">Video Data Archived - Data is older than 30 Days </h5>
                        <div >Would you like to retrieve the data for review </div>
                        <i >(Data will be available in approximately 12 hours)</i>
                        
                        <div className="archivebuttons">
                            <motion.button   className="btn restore-button"  onClick={this.onRestoreClick}  whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}} >
                            {this.state.restoreClicked?<Spinner className="restore-spinner" /> : <span>Restore</span>} 
                            </motion.button>                                             
                            <motion.button   className="btn decline-button"  onClick={confirmClose}  whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}} >No</motion.button>                                             
                        </div>
                    
                            
                    </div>
                </div>
                {/* </ReactModal> */}
            </div>
        );
    }
}

/*
* @brief A simple component to render a countdown timer given a timestamp
*/
export class ArchivedRetrieval extends PureComponent {
    constructor(props) {
      super(props); 
      this.state = {
        time: this.props.time,
        localTime: new Date().toLocaleString(),
      };
    }
    /*
    * @brief Called when the component is loaded
    */
    componentDidMount() {
      this.intervalID = setInterval(  () => this.tick(), 1000   );
    }
    /*
    * @brief Called when the component is destroyed
    */
    componentWillUnmount() {
          clearInterval(this.intervalID);
    }
    /*
    * @brief Helper method called at the specified interval to update the render
    */
    tick() {
      this.setState({
        localTime: new Date().toLocaleString()
      });
    }
    /*
    * @brief Render the time to a simple object
    */
    render() {
      const timeavailable = moment.utc(this.state.time);
      const currentTime = moment.utc();
  
      //Handle the estimated available time being wrong:
      let diplayCountdown = <div className="available-countdown">
        Estimated time remaining: { moment.utc(timeavailable.diff(moment.utc())).format("HH:mm:ss")}
      </div>
      if(timeavailable.isBefore(currentTime)){ //time has passed
        diplayCountdown = <div className="available-countdown">
          Data retrieval is taking longer than expected: + 
          <span style={{color:"#FF0000"}}>{ moment.utc(currentTime.diff(timeavailable)).format("HH:mm:ss")}</span>
        </div>
      }
      
      return (
           <div className="no-highlights-message">
                <h5 className="archivetitle">Video Data being retrieved</h5>
                <div className="available-countdown">Estimated availability: {moment(this.state.time).format("YYYY-MM-DD HH:mm:ss")}</div>
                {diplayCountdown}  
          </div>  
  
        
      );
    }
  } 
  export class Spinner extends PureComponent {
    render() {
        return (
            <div className="restore-spinner preloader-archive loader-block">
                <div className="circ1 loader-info"></div>
                <div className="circ2 loader-info"></div>
                <div className="circ3 loader-info"></div>
                <div className="circ4 loader-info"></div>
            </div>
        )
    }
}