import React, { Component,PureComponent } from 'react';

import './WorstTile.css';

import {severityStyle, percentageToColor, pictureFormatter, radialFormatter, noteFormatter} from './RiskUtils.js'
import { Spinner } from '../ApiCaller';

/*
* @brief A component to show all details on a worst table
*/
class WorstTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
        shown: true,
    };
}

componentDidMount(){
  // console.log("Tile loaded: ",this.props);
}
/*
* @brief Draw the photo and driverid
*/
render() {

  const onClick = (() => {
    //  console.log("Click recived",this.props)
    if(this.props.onClick){this.props.onClick(this.props)}
  });

  //  console.log("Render Tile loaded: ",this.props);
  //  console.log("Render severity: ",severityStyle(null,this.props));

  if(this.props.loadAllowed){
    return(
      <div className="worsttile-content" key={"worsttile-"+ this.props.idx} > 
          <div className="tiletable" onClick={onClick}> 
              <div className="tile-severity" style={severityStyle(null,this.props)}></div>
              <div className="driverphoto"> {pictureFormatter(this.props.photo)}</div>
              <div>{this.props.driverid}</div>
              <div>{this.props.infcount}</div>
              <div className="radialdiv">
                {radialFormatter(this.props.radial)}
              </div>
              
              {noteFormatter(this.props.interventions)}
          </div>
      </div>
  
    )
  }else{
    return(
      <div className="worsttile-content" key={"worsttile-"+ this.props.idx} > 
          <Spinner/>
      </div>
  
    )


  }

  
  
}
}


export { WorstTile };