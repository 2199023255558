import React  from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './DateFilter.css'


/* @Brief Component handle the input of a time field
*/
export const DateFilter = ({className,startDate,endDate,onChange,...props}) =>{

    const [start_date, setStartDate] = React.useState(startDate);
    const [end_date, setEndDate] = React.useState(endDate);

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        // console.log("Mount on DateFilter?", new Date());
    },[]) //[] -> execute once

    React.useEffect(()=>{
        // console.log("Update startdate", startDate,start_date);
        setStartDate(startDate);
    },[startDate]) //
    React.useEffect(()=>{
        // console.log("Update enddate", endDate);
        setEndDate(endDate);
    },[endDate]) //

    React.useEffect(()=>{
        if(onChange){onChange('endDate',{value:end_date})}
        if(onChange){onChange('date',{value:{start: start_date, end: end_date}})}
    },[end_date]) //
    React.useEffect(()=>{
        if(onChange){onChange('startDate',{value:start_date})}
        if(onChange){onChange('date',{value:{start: start_date, end: end_date}})}
    },[start_date]) //
   
    /**
     * 
     * @param {*} _data string
     * @param {*} _type name of the current component (pre/post)
     */    
    function handleChange(_data,_type){
        // console.log("onChange:" ,_type,{value:_data});
        if(onChange){onChange(_type,{value:_data})}
        
    }

    // Callbacks for the startDate DatePickers being changed
    function handleStartDateChange(date) {

        
        let returnDate = null;
        if(date){ returnDate = moment(date); } //only update with a new moment object if the date is set
        
        //Make sure the start date comes before the end date:
        if(end_date && date){ //is the end date set?
            if(!date.isBefore(end_date)){ //is the new selected value not before the end date?
                //Swap the entries if out of order:
                returnDate= moment(end_date);    
                setEndDate(date);
                setStartDate(returnDate);
            }else{
                setStartDate(date);
            }
        }else{
            setStartDate(date);
        }
        // handleChange(returnDate,'startDate');
        
    }
     // Callbacks for the endDate DatePickers being changed
     //Make sure the start date comes before the end date:
     function handleEndDateChange(date) {
        //Set a date value to return after completing
        let returnDate = null;
        if(date){ returnDate = moment(date); } //only update with a new moment object if the date is set

         if(start_date && date){ //is the end date set?
            if(date.isBefore(start_date)){ //is the new selected value not before the end date?
                //Swap the entries if out of order:
                returnDate = moment(start_date);    
                setEndDate(returnDate);                                
                setStartDate(date);
                
            }else{
                setEndDate(date);
            }
        }else{
            setEndDate(date);
        }
        // handleChange(returnDate,'endDate');
    }
 
    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        //Return the HTML code to display:
        return (
            
            <div className={`dateselect ${className}`} >
                <div className={`content vertically-center`}>
                    {/* <div className='NoCollisionTest'> */}
                    {/* Label */}
                    <div className="label-text ">Date</div>
                    {/* Start date section */}
                    <DatePicker selected={start_date}
                                selectsStart
                                startDate={start_date}
                                endDate={end_date}
                                onChange={handleStartDateChange}
                                placeholderText="Start date"
                                isClearable={true}
                                className="date-pick date-start"
                                dateFormat="DD/MM/YYYY"
                                // popperPlacement="bottom-end"
                                popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: true }, hide: { enabled: false } }} 
                    />
                    {/* end date section */}
                    <DatePicker selected={end_date}
                                selectsEnd
                                startDate={start_date}
                                endDate={end_date}
                                onChange={handleEndDateChange}
                                placeholderText="End date"
                                isClearable={true}
                                className="date-pick date-end"
                                dateFormat="DD/MM/YYYY"
                                // popperPlacement="bottom"
                                popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: true }, hide: { enabled: false } }} 
                    />                                
                </div>
            </div>
            
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}//end of the CutVideoNotecard

