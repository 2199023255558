import React from 'react';
import { Auth, API } from 'aws-amplify';

/**
 * Custom hook to fetch the Driver Photos given a list of DriverIDs. The Driver photos are returned from the 
 * locally cached photos in the photos database table. 
 * @param {*} _db Reference to the local Driver photo database (Dexie type)
 * @returns {{}} driverphotos - The combined list of the driverphotos that were retrieved for the requested list
 * @returns {{}} fetchDriverPhoto - Trigger a request for a single driver photo the returned photo will be added to the list driverphotos
 */
export const useDriverPhotoFetch = (_db) => {
    //maintain a list of the driverphotos, as each is returned the set is updated. The calling component will receive the updated set each time its
    //contents are changed
    const [driverphotos, setData] = React.useState({}); //initialize to empty set
    const requests = React.useRef([]); //Keep a log of the requests to prevent duplicated requests

    //Add the returned data from a driver to the driverPhotos 
    function addAndSet(_driverDetails){
        //Update the driverPhotos state varaiable
        setData(prevState => { //Get the previous state of the driverPhotos set
            let drivers = JSON.parse(JSON.stringify(prevState)); //make a copy of the previous state so there is no shallow references
            //Merge the received details with any existing date, or add if not found
            drivers[_driverDetails.driverid] = Object.assign(drivers[_driverDetails.driverid] || {},_driverDetails);
            return drivers;
          }) 
    }//end of addAndSet()

    //Update the local cache Database with the missing photo data returned from the API
    const addPhotoToCache = async (_driverDetails) =>{
         //Add the photo to the local database:
         //Define the data to add to the entry
         await _db.photos.add({
            driverid : _driverDetails.driverid, //set the driverid
            photo: _driverDetails.photo, //set the photo
            name: _driverDetails.name,//set the name
            date: new Date() //Add a timestamp, the database will be cleaned by expiring entries after a set time (7 days default)
        });        
    }//end addPhotoToCache

    //Fetch the DriverPhoto, attempts to get the photo from the Cached photos first and then falls back on the API
    //if the photo is not found in the local database
    const fetchDriverPhoto = (_driverID,siteDetails) => {

        //Track which photos have been requested, prevent the same request from making multiple load calls
        if(requests.current.includes(_driverID)){
            // console.log("Already requested:",_driverID); 
            return;}
        else{ requests.current.push(_driverID); } //new request - add it to the cached list of requests

        // console.log("Fetch called on :",_driverID,siteDetails);
        if (!_driverID){ console.log("No DriverID specified:" ,_driverID); return;}

        //Helper function to check the local cache using the driverid as the key value
        const checkCache = async (_driverID) =>{
            if(!_db){ console.log("Cache DB not connected"); return null;}
            //Scan the database and return all entries matching the DriverID 
            let localData = await _db.photos.where('driverid').equals(_driverID).toArray();

            //Check if a match was returend:
            if(localData && localData.length>0){
                //Update the date accessed, so that this one is not removed by the cleaning functions
                _db.photos.where('driverid').equals(_driverID).modify({date: new Date()});
                //Define a return object with the data found in the cache
                let driverDetails = {
                    driverid: _driverID,
                    photo:localData[0].photo,
                    name: localData[0].name
                }
                // console.log("Cache hit:" ,_driverID);
                //Add the cache return to the result state:
                addAndSet(driverDetails);                
                return({error:false});
            }else{return null;}
        }//end checkCache
        
        //Helper function to fetch the Driver photo and name from the API
        const fetchData = async (_driverID,siteDetails) => {
            // console.log("Fetch photo for :",_driverID)
            const authReturn = await Auth.currentSession();
            //Set up the API request:
            let apiName = "TrifectaAPI";
            let path = "/handleDriverInfo";
            let myInit = {
                body: {
                    token: authReturn.idToken.jwtToken,
                    clientid: (siteDetails&& siteDetails.clientid)||'bis',
                    driverid: _driverID,
                    mode: 'fetch',              
                }
            };
            //Send the API request and wait for a return:
            const apiReturn = await API.post(apiName, path, myInit);
            //if the API returned check for the response
            if(apiReturn ){
                try {
                    let driverInfo = apiReturn.driverinfo;
                    let driverDetails = {} //set up an object to hold the returned data
                    driverDetails.photo = driverInfo.photo; //copy the image from the return
                    //Is it tagged as a photo, this needs to be added to let the HTML know how to load the image
                    if(!driverDetails.photo.includes('data:image')){            
                        //if no tag, assume this is a base64 encoded jpeg
                        driverDetails.photo = 'data:image/jpeg;base64,'+driverInfo.photo;
                    }
                    //Add the name and driverid to the object
                    driverDetails.name = driverInfo.name;
                    driverDetails.driverid = driverInfo.driverid;
                    //Add the new data to the results
                    addAndSet(driverDetails);
                    // console.log("Add to cache")
                    //Add the new data to the local cache
                    await addPhotoToCache(driverDetails);
                } catch (error) {
                    // console.log("fetch error: ",error, _driverID);
                }
            }//end API return check
        };//end fethcData()

        //Execute the request with the given Driverid
        //First check if it is available in the local cached photos
        let cachePromise = checkCache(_driverID)
        cachePromise.then(return_ => { //wait for the cache checking to return
            if(return_){ return;} 
            // console.log("Not found in cache, fetch from API: ",_driverID);
            //Cache return a null value, so we need to fetch from the API
            fetchData(_driverID,siteDetails);    
        });
        cachePromise.catch(return_ => {console.log("Cache check error: ",return_)});
    };//end fetchDriverPhoto

    //Expose the result state and the fetchDriverPhoto function to the calling component
    return { driverphotos,fetchDriverPhoto };
}; //end useDriverPhotoFetch