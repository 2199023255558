
import React, {  PureComponent } from 'react';
// import { setupPerf, perfDidClick  } from '../Perf.js';
import { unmountComponentAtNode } from "react-dom"
import { displayInfraction, SEVERITY, generateUniqueID, filenameAlphaStripper, VIDEO_STATUS } from '../Util.js';
import { VIDEOREVIEW_NOREPORT } from '../Util-access.js';
import { driveGroups,allowedGroups,VideoReviewGroups } from '../Util.js';
import { CardListFunc} from '../VideoCard.js';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import { ROISelector } from '../ROISelector.js';
import { Auth, API } from 'aws-amplify';
import { ReviewTabs } from './ReviewTabs.js';

import * as moment from 'moment';
import './VideoReviewer.css';
import axios from 'axios';

import IconRepeat     from '../assets/repeat-icon.png';
import IconProcessing from '../assets/processing-gears.png'
import { VideoClipPlayer } from './VideoClipPlayer.js';

import { StatefulButton } from './vid-util.js';
import {getMetaDataByID, getTotalLessEjections, getButtonCSSClass, getButtonDisplayname, getCard} from './vid-util.js'
import {dvrdeleted_message} from '../VideoReview/video-messages.js';

/*
* @brief A component for displaying multiple videos that can be switched between for review
*/
export class VideoReviewerClass extends PureComponent {
  constructor(props) {
    super(props);
    // setupPerf(this, 'VideoReviewer', () => true);
    this.pauseVideos = this.pauseVideos.bind(this);
    this.shouldInitiallySend = this.shouldInitiallySend.bind(this);

    this.expandCard = this.expandCard.bind(this);
    this.unexpandCard = this.unexpandCard.bind(this);
    this.cardComment = this.cardComment.bind(this);
    this.cardTag = this.cardTag.bind(this);
    this.cardChange = this.cardChange.bind(this);
    this.changeDriverID = this.changeDriverID.bind(this);

    this.getVideoUpdate = this.getVideoUpdate.bind(this);
    this.refreshVideoUpdate = this.refreshVideoUpdate.bind(this);
    this.updateButtons = this.updateButtons.bind(this);
    this.handleReprocessStatus = this.handleReprocessStatus.bind(this);
    this.getButtonDataByType = this.getButtonDataByType.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.markViewed = this.markViewed.bind(this);
    this.updateProgressAPI = this.updateProgressAPI.bind(this);
    this.handleLeave  = this.handleLeave.bind(this);
    this.overlayButtonClick = this.overlayButtonClick.bind(this);
    this.onCardCreate = this.onCardCreate.bind(this);
    this.ejectClick = this.ejectClick.bind(this);
    this.undoEjectClick = this.undoEjectClick.bind(this);
    this.removeTaggedInfraction = this.removeTaggedInfraction.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.openROI = this.openROI.bind(this);
    this.closeROI = this.closeROI.bind(this);
    this.currentlyPlaying = this.currentlyPlaying.bind(this);
    this.initProgressTally = this.initProgressTally.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleLoadComplete = this.handleLoadComplete.bind(this);

    this.confirmPublish = this.confirmPublish.bind(this);
    this.clickUnusable = this.clickUnusable.bind(this);
    this.clickReprocess = this.clickReprocess.bind(this);
    this.clickTypeButton = this.clickTypeButton.bind(this);
    this.confirmReviewPublish = this.confirmReviewPublish.bind(this);
    this.confirmReviewComplete = this.confirmReviewComplete.bind(this);
    
    this.state = {
      // If there are no highlights, start with the Original Video, otherwise start with the
      // highlights
      
      play_states: {},
      workaround_playing: {},
      shouldPause:[],
      cardsByType: this.props.cardsByType || {},
      videos: this.props.videos,      
      // chosen: this.props.videos.highlights ? "highlights" : "original",
      chosen:null,
      activeTabKey: 'infractions',
      expandedCard: null,
      roiselector: null,
      firstload: true,
      playerRef:{},
      rejectionList:[],
      rejectionByType:{},
      updateTimer:null,
      skipClickTimer:moment(),
      playReady:[],
      processInfractionVideoTime:null,
      ejectSets:{},
      ejectionIds:{},
      playerKey:0,
      published:false,
      processState: 'processed',
      m_bReturned:false,
      m_bSubmitted: false,
      markedUnusable:this.props.video.status===VIDEO_STATUS.UNUSABLE,
      progressTally:{},
      progressTallyInFlight:new Set(),
      tagsreviewed:0,
      totalClips: 0,
      bookmark:{},
      bookmarkLoad:{},
      buttonData:[],
      highlightTabCount:0,
      m_initiallySent: new Set(),
      hasResults: false,
      tabs: [ {eventKey:"infractions",set: null, last:null, title:'Clips'}
              ,{eventKey:"highlights",set: null, last:null, title:'Highlights'}
              ,{eventKey:"driverfacing",set: null, last:null, title:'DriverFacing'}],
      currentPlayingID: null,
      playerRef:[],
      loadedReturns:[],
      timeDebug: new Date(),
      cardOpenTimer: new Date(),
      forcePlayerRefresh: 0,
      ejectedClipsInFlight: new Set(),
      videoClientID:null,

      refreshTimeoutID: null, //timeout was set to trigger a refresh
    };

  }

  /* Lifecyle method called when new data is recieved before it has run to the render method*/
  UNSAFE_componentWillReceiveProps(newProps) {
    //Did the loadTime change? This is updated when VideoHighlights gets new data from the API
    if(newProps.loadTime !== this.props.loadTime){
      //Pass the new data into the updateButtons, if we use the this.props it will be old data 
      //and the buttons will not update
      this.updateButtons(newProps); //refresh the button data
    }
  }

  /* @brief Break out the update method for populating the button data.
  * Process the videos member variable passed from VideoHighlights.
  */
  updateButtons(_props) {
    // console.log("UpdateButtons: ",_props);
    const videos = _props.videos;
    const byType = videos.byType;
    const clips = videos.clips;

    let hasResults = false;
    try{

      if(Object.entries(videos.clips).length !== 0){
        hasResults = true;
      }
    }catch(e){ }
    

    let totalInfractions = 0;
    // console.log("UpdateButtons(Statetype):",this.state.videos.byType);
    // console.log("UpdateButtons: ",this.state.videos);

    var progressTally = {};
    var infractionData = [];
   

  // Build a Set containing all infractions in the individual highlights table
  // console.log("Types: ",_props.groupconfig);
    //Loop through the defined infraction set and add it to the total if in the seen set
    let iLoadIdx = _props.groupconfig.infractionTags.length;
    _props.groupconfig.infractionSet.forEach(inf => {
        infractionData.push({
          classification: inf,
          count: 0,
          streamURL: null,
          flag:0,
          loadOrder: iLoadIdx++,
          loadComplete : false,
        });
    });
    //add the ejected type for review:    
    if(this.state.videos.byType && this.state.videos.byType['ejected']){
      infractionData.push({
        classification: 'ejected',
        count: 0,
        streamURL: null,
        flag:0,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    

    //Loop through the defined driver facing infraction and set them to placeholders:
    let classIdx = 0;
    _props.groupconfig.driverInfractionSet.forEach(inf => {
        infractionData.push({
          classification: inf,
          label: "Class "+classIdx++,
          count: 0,
          streamURL: null,
          flag: 2,
          loadOrder: iLoadIdx++,
          loadComplete : false,
        });
    });
    if(this.state.videos.byType && this.state.videos.byType['IngressClip']){
      infractionData.push({
        classification: 'IngressClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    if(this.state.videos.byType && this.state.videos.byType['EgressClip']){
      infractionData.push({
        classification: 'EgressClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }
    if(this.state.videos.byType && this.state.videos.byType['OtherHighGClip']){
      infractionData.push({
        classification: 'OtherHighGClip',
        label: "Class "+classIdx++,
        count: 0,
        streamURL: null,
        flag:2,
        loadOrder: iLoadIdx++,
        loadComplete : false,
      });
    }

    // console.log("InfractionData: ",infractionData);

    //Loop through the defined tagged infraction and set them to placeholders:
    let testArray = [];
    iLoadIdx =0;
    (_props.groupconfig.infractionTags || []).forEach(_tag=>{
      // console.log(_tag);
      if(_tag.type === 'Irrelevant'){ return;}
      let tmpObj = {
        set: [],
        classification: _tag.type,            
        count: 0,
        type: 'playlist',streamURL: 'playlist',
        loadOrder: iLoadIdx++,
        loadComplete : false,
      };
      infractionData.push(tmpObj);
    });  
    // console.log("InfractionData: ",infractionData)
    
    
    // console.log("ByType: ",byType);
    //Iterate over byType
    Object.values(byType||[]).forEach(v => {
      if(v.count ===0){
        //Handle a non returned count from the /ListVideos call
        if(clips[v.classification]){
          v.count =clips[v.classification].length;
        }
      }  
      if(v.set && v.count !== v.set.length){
        // console.log("Fix the count");
        v.count = v.set.length;
      }
      var index = -1;
      infractionData.forEach((row,idx) => {
        if(row.classification.toLowerCase() === v.classification.toLowerCase()){ //find the matching classification
          
          if(v.type === 'playlist'){ //these don't have the flag?
            // console.log("Row classification: ",idx,v,row);
            index = idx;
          }
          else{
            if(row.flag === v.flag){
              // console.log("Row classification: ",idx,v,row);
              index = idx;
            } 
          }
          if(row.classification === 'ejected'){
            // console.log("V: ",v);
             v.count = v.metadata.length;
          }
        }
        
      });
      if(index!==-1){
        // console.log("Set with byt")
        infractionData[index] = Object.assign(infractionData[index],v);
      } 
    });
    

    // return;

    infractionData.forEach(row => {
      //  console.log("Row count:" ,row.classification, row.count,row);
      if(row.type!=='playlist'){
        totalInfractions += row.count || 0;
      }      
    });
    // console.log("Videos: ",videos);
    // console.log("Infractiondata: ",infractionData);
    // Fill in a minimal highlights row so the greyed out button can be displayed for it
    const emptyHighlights = {
      classification: "Common",
    }


    if(_props.review24Hr){
      //Loop through the clips, add them to the driverfacing types:
      Object.values(( clips || {})).forEach(typeArray => {
        // console.log("Clip type:" ,typeArray)
        typeArray.forEach(clip_=> {
          try {
            let typeRef = (infractionData||[]).filter(inf_ =>{return inf_.classification === clip_.classification});
            typeRef = typeRef[0];
            // console.log("REf: ",typeRef,clip_.classification,typeRef.count);
          if(typeRef.count ===0){ //make sure we load the count
              if(clips[typeRef.classification]){
                typeRef.count =clips[typeRef.classification].length;
              }
          }    
          } catch (error) {
            // console.log("Failed on: ",clip_.classification)  
          }
        })
      })
    }
   



    // console.log("infractionData ", infractionData);
    let buttonData = infractionData;
    buttonData.unshift(videos.highlights || emptyHighlights);
    
    if(driveGroups.includes(_props.groupconfig.group) && !videos.original){
    // if( !videos.original){
    }else{
      
        if(videos.original){buttonData.unshift(videos.original);}
        //force the original video to load on the 24Review page
        else if(_props.video.vehicleType === '24Review'){
          buttonData.unshift({
            classification: 'source',
            type: 'original',
            count: 0,
            streamURL: null,
            flag: 0,
            loadOrder: iLoadIdx++,
            loadComplete : false,
          });
        }
    }

    let loadedReturns = [];
    const tabs = this.state.tabs;
    buttonData.forEach(row => {
      // console.log("Row to load: ",row);
      let chosenName;
      let displayName;
      try{ 
        if (row.type === "original") {
          chosenName = row.type;
          displayName = "Original Video";
          row.loadComplete = this.props.videos.originalDeleted;
          //  row.loadComplete = false;
          row.loadOrder = 51;

        } else if (row.classification === "Common") {
          chosenName = "highlights";
          displayName = "Overall Highlights";
          row.count = totalInfractions;
          row.loadComplete = false;
          row.loadOrder = 50;
        } else {
          chosenName = row.classification;
          //Change the display name for the infractions based on the loaded translation:
          displayName = displayInfraction(_props.groupconfig.displayNameSet,row.classification)
        }
        if(row.label){displayName = row.label}
        // if(row.label === 'EgressClip'){displayName = row.label}


        row.chosenName = chosenName;
        row.displayName = displayName;

        //Set a default button to select for each tab group:
        if(row.type === 'playlist' && row.count > 0){
          tabs[0].last = chosenName;
          loadedReturns.push(chosenName);
        }
        if(row.type !== 'playlist' && row.flag===0 && row.count > 0){
          tabs[1].last = chosenName;
        }
        if(row.flag===2 && row.count > 0){
          tabs[2].last = chosenName;
        }


      }catch(e){}
    });

    

    // console.log("Shouldenter: ",this.state.progressTally)
     if(!this.state.progressTally.length){
      //  console.log("Not set entered progress tally init")
       try {
        let progressTemp = JSON.parse(_props.reviewStatus[0].tagsviewed);
        // console.log("Resume: ",progressTemp)
        Object.keys(progressTemp).forEach(function(key) {
          // console.log("Key: ",key);
          if(!progressTally[key]){progressTally[key]={viewed:new Set(progressTemp[key].viewed)}}
        });
        // progressTally = JSON.parse(_props.reviewStatus[0].tagsviewed) 
       } catch (error) {
        // console.log("Failed to resume: ",error);
        Object.values(( clips || {})).forEach(typeArray => {
          typeArray.forEach(clip => {
            let metadataPair = getMetaDataByID(buttonData,clip.infractionID);
            if(metadataPair){
              if(!progressTally[metadataPair.type]){progressTally[metadataPair.type]={viewed:new Set()}}
            }
          });
        }); 
       }//end catch
      
      this.state.progressTally = progressTally;
      //this.setState({progressTally:progressTally},console.log("New state: ",this.state));
    }

    //Create the return object to set the new state
    let returnObject = {
      buttonData:buttonData,
      tabs:tabs,
      hasResults:hasResults, 
      updateTimer: moment(),
      cardsByType: _props.cardsByType|| {},
      loadedReturns: loadedReturns,
      playerKey: this.state.playerKey+1,
    };

    //Scan through the objects and populate the eject sets based on the status:
    let ejectionSets = this.state.ejectSets;
    
    Object.values(( clips || {})).forEach(typeArray => {
      typeArray.forEach(clip => {
        if(clip.status){
          if(clip.status==='ToEject' ){
            let metadataPair = getMetaDataByID(buttonData,clip.infractionID);
            if(metadataPair){
              if(this.state.processState==='processed'){ console.log("set canProcess: line 447");returnObject.processState = 'canProcess'}
              // console.log("Add to list: ",metadataPair,clip)
              let tmpName = metadataPair.type
              if(clip.flag && clip.flag == 2 ){
                tmpName += "-DF";
              }
              if( !ejectionSets[tmpName] ) {ejectionSets[tmpName]={ejectList:[], processing:false, processTime: moment()}} //create the default structure
              if(!ejectionSets[tmpName].ejectList.includes(metadataPair.data)){
                ejectionSets[tmpName].ejectList.push(metadataPair.data);
                // console.log("Add the metadata pair: ",metadataPair.data);
              }

              if(metadataPair.type!=='highlights'){
                if( !ejectionSets['highlights'] ) {ejectionSets['highlights']={}; 
                ejectionSets['highlights'].processing=false; 
                ejectionSets['highlights'].processTime=moment();
                ejectionSets['highlights'].ejectList=[]; } //create the default structure
                ejectionSets['highlights'].ejectList.push( metadataPair.data);
              }
            }//end null check
          }//end ToEject check
        }        
      });
    });
    // console.log("EjectSets: ",ejectionSets);
    // Do we need to update the processState here?
    returnObject.ejectSets=ejectionSets;

    // console.log("Set ButtonData: ",buttonData);
    if(_props.reviewStatus && _props.reviewStatus[0]){
      try {
        if(_props.reviewStatus[0].bookmark  ){          
          returnObject.bookmarkLoad  = JSON.parse(_props.reviewStatus[0].bookmark);
          // this.state.bookmarkLoad = JSON.parse(_props.reviewStatus[0].bookmark);
          // console.log("BookMark: ",this.state.bookmarkLoad);
          // if(this.state.bookmarkLoad.tag){
          //   this.state.chosen = this.state.bookmarkLoad.type;
          // }
          if(returnObject.bookmarkLoad.tag){
            returnObject.chosen = returnObject.bookmarkLoad.type;
          }
        }
      } catch (error) {
        console.log("Bookmark load error: ",error);
      }
    }
    //Set the state with the new values
    this.setState(returnObject);
  }//end of UpdateButtons


  handleLoad(_details,_bLoad){
    // return;
        // console.log("Load: ",_details,_bLoad);
        let buttonData = [...this.state.buttonData];
        buttonData.forEach(row => {
          if(row.streamURL === 'playlist' && row.chosenName === _details.name){

            let foundElem = row.set.find(obj => obj.InfractionID === _details.InfractionID);
            if(!_bLoad){
              foundElem.loaded = true;
              foundElem.loading = true;
              this.setState({buttonData: buttonData})
            }else{
              if(foundElem && (!foundElem.blob )){
                  // console.log("Loading: ",_details,foundElem,row.chosenName)
                  // console.log("Clip: ",foundElem,foundElem.blob);
                //Check if this file is handled as a DASH mpd, if so then don't attempt to download it:
                if(foundElem.streamURL.substring(0,4)==='blob'){
                  foundElem.blob = foundElem.streamURL;
                  foundElem.loaded = true;
                  this.setState({buttonData:buttonData,skipClickTimer:moment()});
                }else{// the url is for the full stream - trigger download:
                  foundElem.blob = 'pending';
                  foundElem.loading = true;
                  this.setState({buttonData:buttonData});
                  // console.log("Found elem ",foundElem);
                  if(foundElem.InfractionID === '40ac0179-283c-48a5-a777-30827f815bf6'){
                    console.log("Found elem ",foundElem);
                  }
                  axios({
                        url: foundElem.streamURL,
                        method: 'GET',
                        responseType: 'blob', // important
                        data: {
                          infractionid: foundElem.InfractionID,
                          streamURL: foundElem.streamURL,                          
                        },
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          // 'Content-Type': 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded'
                        },
                      })
                      .catch(error =>{
                        try {
                          let configData = JSON.parse(error.config.data);
                          // if(_details.name ==='Headphones'){ console.log("Axios return E ?", configData)}
                           foundElem.blob = configData.streamURL;
                           foundElem.loaded = true;
                           this.setState({buttonData:buttonData,skipClickTimer:moment()}); //is this failing to trigger the next stage on Safari?
                        } catch (error) {
                          
                        }
                      })
                      .then(res => {
                        if(res && res.data){
                          if(foundElem.InfractionID === '40ac0179-283c-48a5-a777-30827f815bf6'){ console.log("Axios return1 ?", res.config)}
                          // if(_details.name ==='Headphones'){ console.log("Axios return1 ?", res.config)}
                          const url2 = window.URL.createObjectURL(new Blob([res.data]));
                          // console.log("URL failed to create?")
                          foundElem.blob = url2;
                          // foundElem.blob = foundElem.streamURL;
                          foundElem.loaded = true;
                          // console.log("Update video: ",foundElem.InfractionID)
                          this.setState({buttonData:buttonData,skipClickTimer:moment()}); //is this failing to trigger the next stage on Safari?
                        }       
                      });
                }//end handle non blob downloads
              }//end found element check
            }
          }//end found the correct category
        });//end iterate over all button data
  }

  /* @brief Run once when the class is loaded
  */
  componentDidMount() {
    console.log("VideoReviewer VIDEOTYPE:" ,this.props.videoType);
    // console.log("Start cards:" ,JSON.parse(JSON.stringify(this.props.cardsByType)));
    // console.log("VR props: ",this.props);
    this.updateButtons(this.props);
    //Scan through video: which should be set as chosen:
    let chosen = null
    let activeTabKey = 'infractions'
    // console.log(this.props.videos.byType);
    let clipCount = 0;
    Object.values(this.props.videos.byType||[]).forEach(v => {        
      if(v.set && v.set.length>0){
        if(!chosen){
          chosen = v.classification;
        }
      }            
      clipCount += v.count;
    });
    if(!chosen){
      chosen=this.props.videos.highlights ? "highlights" : "original"
      if(this.props.video.vehicleType === '24Review'){
        activeTabKey = 'infractions'
      }else{
        activeTabKey = 'highlights'
      }
      
      if(this.state.bookmarkLoad && this.state.bookmarkLoad.tag){
        // chosen=this.state.bookmarkLoad.type;
      }
    } 
    //Redirect to the correct button, needs to be delayed to allow
    // the icons to load correctly
    window.setTimeout(() => {
      // console.log("Redirecting to :" ,chosen, activeTabKey);
      this.setState({chosen:chosen,activeTabKey:activeTabKey});
    } , 1000); 

    this.setState({chosen:null,activeTabKey:activeTabKey,totalClips:clipCount});


    //Retrieve the video's clientid:
    let realPromise = Auth.currentSession().then(
      (auth) => {
      this.setState({currentUsername: auth.idToken.payload['cognito:username']});
      let apiName = "TrifectaAPI";
      let path = "/getVideoOwner";
      let myInit = {
          body: {
              token: auth.idToken.jwtToken,              
              videoid: this.props.parentID,
          }
      };
      return API.post(apiName, path, myInit);
    });
    realPromise.then(_data=>{ //process the owner based on the assetid
      //  console.log("Returned: ",_data);
      if(_data && _data.data){
        let clientid = null;
        for(const entry_ of _data.data){
          if(entry_.clientid){
            if(entry_.clientid.toLowerCase()!=='devgroup'){clientid =entry_.clientid.toUpperCase() }
            else{ if(!clientid) {clientid =entry_.clientid.toUpperCase()}   }
          }          
        }
        // console.log("Set video owner: ",clientid);
        if(clientid){this.setState({videoClientID:clientid});}
      }
    })
   } //end compenentDidMount

  /* @brief On exit from page, trigger a bookmark update
  */
  handleLeave(){

    if(this.state.refreshTimeoutID){clearTimeout(this.state.refreshTimeoutID)}

    // this.props.refreshList();
    if(this.state.published){return;}
    let bookmarkString = JSON.stringify(this.state.bookmark);
    Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/updateReviewProgress";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            review: {
              id: this.props.parentID,
              username: auth.idToken.payload['cognito:username'],
              bookmark: bookmarkString,
              
            }
          }
        };
        return API.post(apiName, path, myInit);
      });
  }


  UNSAFE_componentWillMount() { 
    this._isMounted = true;
  }
  /* @brief Catch when the class is leaving, navigating away
  */
  componentWillUnmount() { //catches back button and back to analytics
    this._isMounted = false;
    this.handleLeave();
    const videos = this.state.videos;
    const cards = this.state.cardsByType;
    // console.log("Unmount cards: ",this.state.cardsByType);
    
    
    if(videos.original && videos.original.streamURL){URL.revokeObjectURL(videos.original.streamURL)}
    if(videos.highlights && videos.highlights.streamURL){URL.revokeObjectURL(videos.highlights.streamURL)}
    Object.values(( videos.clips || {})).forEach(typeArray => {
      typeArray.forEach(clip => {
        if(clip.streamURL){URL.revokeObjectURL(clip.streamURL)}
      });
    }); 
    
    Object.values(( videos.byType || {})).forEach(type => {
      if(type.set ){
        (type.set || []).forEach(elem=>{
          if(elem.streamURL){URL.revokeObjectURL(elem.streamURL)}
        })
      }else{
        if(type.streamURL){URL.revokeObjectURL(type.streamURL)}
      }
    }); 
    videos.original = null;
    videos.highlights = null;
    videos.clips = null;
    videos.byType = null;
    videos.byID = null;
    videos.byDriver = null;

    //Release any url blobs on the cards:
    Object.keys(cards).forEach(_key => {
      Object.values(cards[_key]).forEach(_card => {
        if(_card && _card.photo){_card.photo =null;}
        if(_card && _card.video){URL.revokeObjectURL(_card.video)}
      });
    });

    //Remove blobs from the buttonData:
    for(const row of this.state.buttonData){
      if(row.type === 'playlist' ){
        for(const elem_ of row.set){
          if(elem_ && elem_.blob){
            // console.log("Remove blob?: ",elem_.blob);
            try { URL.revokeObjectURL(elem_.blob) } catch (error) {console.log("Failed to remove blob? ",error)}
          }
        }
      }
    }


    try{
        // var es = document.getElementsByClassName("video-reviewer-player");
        var es = document.getElementsByClassName("video-reviewer");
        // console.log("ES: ",es);
        [...es].forEach(e=>{
          // console.log("Elem: ",e)
          var child = e.lastElementChild;  
          while (child) { 
              e.removeChild(child); 
              child = e.lastElementChild; 
          } 
        });
        
    }catch(e){
      console.log("Error on VR release: ",e);
    }

    this.setState({videos:videos, cardsByType: cards});
    this.setState({videos:null, cardsByType:null});
    this.setState({playerRef: {}});
  }
  /* @brief Send updated stats of the review progress to the AQL table
  */
  updateProgressAPI(){
    if(VIDEOREVIEW_NOREPORT){return;}
    //  return;
    // console.log("Progress API called", this.state.progressTally, this.state.published);
    if(this.state.published){return;}
    // console.log("Progress API called", this.state.progressTally);
    //get the number of tags from the set:
    const progressTally = this.state.progressTally;
    
    let countTotal = 1;
    Object.keys(progressTally).forEach(function(key) {
      countTotal = countTotal + progressTally[key].viewed.size;
    });

    let progessAsObject = {};
    
    Object.keys(progressTally).forEach(function(key) {
      if(!progessAsObject[key]){progessAsObject[key]={viewed: Array.from(progressTally[key].viewed)}}
    });

    let bookmarkString = JSON.stringify(this.state.bookmark);
    let inflightIds = null;
    try {
      inflightIds = JSON.stringify(Array.from(this.state.progressTallyInFlight))  ;  
    } catch (error) {
      console.log("Failed to get inflight: ",error);  
    }

    if(this._isMounted){this.setState({progressTallyInFlight: new Set()});}

    // return;
    Auth.currentSession().then(
      (auth) => {
        try {
          let apiName = "AuthLambda";
          let path = "/updateReviewProgress";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              review: {
                id: this.props.parentID,
                username: auth.idToken.payload['cognito:username'],
                tagsreviewed: countTotal,
                tagsviewed: progessAsObject,
                bookmark: bookmarkString,
                inflightids: inflightIds,
              }
            }
          };
          return API.post(apiName, path, myInit);
        } catch (error) {
          console.log("Failed to send API request: ",error);
        }
      
      });
  }

  initProgressTally(){
    let clips = this.props.videos.clips;
    const progressTally = this.state.progressTally;
    Object.values(( clips || {})).forEach(typeArray => {
      typeArray.forEach(clip => {
        let metadataPair = getMetaDataByID(this.state.buttonData,clip.infractionID);
        if(metadataPair){
          if(!progressTally[metadataPair.type]){progressTally[metadataPair.type]={viewed:new Set()}}
        }
      });
    });
    if(this._isMounted){this.setState({progressTally:progressTally});}
  }
  /* @brief Track which highlight clips have been viewed 
  */
  markViewed(_type,_tag) {
    //  console.log("mark viewed: ",_type,_tag);
    // if(!this.state.progressTally[_type]){ console.log("don't count tagged infractions ",this.state.progressTally);return;} //don't mark viewed on the tagged infraction videos?

    const progressTally = this.state.progressTally;
    let progressTallyInFlight_ = this.state.progressTallyInFlight;
    try {
      if(_tag && _tag.InfractionID && _tag.Tag){
        progressTallyInFlight_.add(_tag.InfractionID);
        if(progressTally[_type]){
          progressTally[_type].viewed.add(_tag.Tag);
          // this.setState({progressTally:progressTally,progressTallyInFlight:progressTallyInFlight_}, console.log("InFlight",this.state.progressTallyInFlight.size));
          if(this._isMounted){this.setState({progressTally:progressTally,progressTallyInFlight:progressTallyInFlight_});}
        }else{
          this.initProgressTally();
          // console.log("Failed to add type to progressTally: ",progressTally,_type)
        }
      }
    } catch (error) {
      console.log("tally update error: ",error);
    }

    //update the bookmark with the current:
    if(this._isMounted){this.setState({bookmark:{type:_type,tag:_tag}});}

    try {
     //if(this.state.tagsreviewed %5 == 0){
    
    let threshold = 5;
    if(this.state.totalClips < 10 ){threshold =2;}
    if(this.state.firstload){
      // console.log("Mark viewed tally firstload: ",this.state.firstload,this.state.progressTallyInFlight.size);
      if(this._isMounted){this.setState({firstload:false});}
      threshold = 0;
    }
    // console.log("mark viewed tally: ",this.state.progressTallyInFlight.size,threshold)
    if(this.state.progressTallyInFlight.size>=threshold){
        this.updateProgressAPI();
     }
    } catch (error) {
      console.log("tally size error: ",error);
    }

  }

  /* @brief Parse through the buttonData set and return the button data associated with an infraction type
  */
  getButtonDataByType(_buttonData,_type) {
    let buttonReturn = null;
    _buttonData.forEach(row => {
      if(_type.includes("-DF")){
        if(row.chosenName === _type.replace("-DF","") && row.flag===2){
          buttonReturn = row;
        }
      }else{
        if( (row.chosenName === _type && row.flag===0) || (row.chosenName === _type && row.chosenName==='highlights' )){
          // if(row.s3key){buttonReturn = row;}
          buttonReturn = row;
        }  
      }
    });
    return buttonReturn;
  }

  /* @brief Expand a given card for user viewing and editing
  */
  expandCard(cardData) {
      // perfDidClick('expand-card-click');
      cardData.openTimer = new Date();
      // console.log("Opened notecard: ",cardData);
      // this.setState({expandedCard: cardData});      
      this.openNotecard(cardData);
  }

  /* @brief Process the openROI request
  */
  openROI(_details) {
    console.log("Got details on roi: ",_details);
    _details.id = this.props.parentID;
    this.setState({roiselector: _details});
  }
  /* @brief Process the closeROI request
  */
  closeROI(_details) {
    this.setState({roiselector: null});
  }
  /* @brief Return the infractionID of the currently playing clip
  */
  currentlyPlaying(_details) {
    
    // console.log("Currently Playing:" ,_details);
    if(_details.chosenName === this.state.chosen){ //make sure the correct clip is returned
      //  console.log("Currently Playing:" ,_details);
       if(_details && _details.meta){
        if(this._isMounted){this.setState({currentPlayingID: _details.meta.InfractionID});} //extract the ID
       }
      
    }
  }

  removeTaggedInfraction(_tagToRemove,_infractionID,_cardID){
    this.setState(state => {
      
      // const videos = Object.assign({}, state.videos);
      let cardsByType = Object.assign({}, state.cardsByType);
      // console.log("Cards in delete: ",cardsByType);
        if(_tagToRemove === 'Irrelevant'){return;}

        // const thisType = Object.assign({}, videos.byType[_tagToRemove] || {});
        // if(thisType.set){ 
        //   //Remove from the video set (this is local, won't force refresh of players)
        //   // thisType.set.splice(thisType.set.findIndex(item => item.InfractionID === _infractionID), 1)
        //   thisType.count =thisType.set.length;
        // }

        const thisCardType = Object.assign({}, cardsByType[_tagToRemove] || {});
        if(thisCardType){
          Object.keys(thisCardType).forEach(key => {
            if (thisCardType[key].cardID === _cardID){
              delete thisCardType[key];
            }
            else{
              // console.log("No match: ",thisCardType[key].cardID,inCard.cardID);
            }
          });
          cardsByType[_tagToRemove] = thisCardType;
          // delete foundElem;
          // thisCardType.splice(thisCardType.findIndex(item => item.cardID === inCard.cardID), 1)
        }
      return {cardsByType:cardsByType};
      // return {cardsByType:cardsByType,videos:videos};
    });
  }

  /* @brief Close the expanded card
  */
  unexpandCard(_inCard) {

    // this.closeNotecard();        
      try {
        let inCard = Object.assign({}, _inCard);
      
        // perfDidClick('unexpand-card-click');
        if(inCard && inCard.delete){
          
          this.setState(state => {

              if(inCard.toRemove){inCard.infractionTags.push(inCard.toRemove)}
              // console.log("unexpand delete card: ",inCard);

              const buttons = state.buttonData;
              const videos = Object.assign({}, state.videos);
              let cardsByType = Object.assign({}, state.cardsByType);
              // console.log("Cards in delete: ",cardsByType);
              (inCard.infractionTags||[]).forEach(_infTag=>{ //iterate over all tags in the card
                // console.log("Delete inftag: ",_infTag);
                if(_infTag === 'Irrelevant'){return;}
                const thisType = Object.assign({}, videos.byType[_infTag] || {});
                if(thisType.set && !inCard.ejected){ 
                  // console.log("Card not ejected")
                  //Remove from the video set
                  let foundButton2 = buttons.find(obj => obj.chosenName === _infTag);
                  thisType.set.splice(thisType.set.findIndex(item => item.InfractionID === inCard.infractionID), 1)
                  thisType.count =thisType.set.length;
                  foundButton2 = thisType; //update the button
                }

                //remove from the cardsbytype (local set populating the infractions tab)
                // let cardsByType = this.removeCardFromCardsByType(cardsByType,inCard);
                const thisCardType = Object.assign({}, cardsByType[_infTag] || {});
                // console.log("Cards filtered by type: ",thisCardType,_infTag)
                if(thisCardType){
                  
                  Object.keys(thisCardType).forEach(key => {
                    // console.log("compare: ",thisCardType[key].cardID,thisCardType[key].infractionID,inCard.cardID,inCard.infractionID)
                    if (thisCardType[key].cardID === inCard.cardID){
                      // console.log("Found card: ",key,inCard)
                      delete thisCardType[key];
                    }
                    else{
                      // console.log("No match: ",thisCardType[key].cardID,inCard.cardID);
                    }
                    if (thisCardType[key].infractionID === inCard.infractionID){
                      // console.log("Found card infractionid: ",key,inCard)
                      // delete thisCardType[key];
                    }
                    else{
                      // console.log("No match: ",thisCardType[key].cardID,inCard.cardID);
                    }
                  });

                  cardsByType[_infTag] = thisCardType;
                  // console.log("CardsbyType, ",_infTag,cardsByType[_infTag]);

                  try{
                    if(this.state.playerRef[_infTag]){
                      // console.log("Found a player for ",_infTag,this.state.playerRef[_infTag]);
                      this.state.playerRef[_infTag].findUnHiddenClip();
                    }else{
                      // console.log("No ref: ",this.state.playerRef,_infTag);
                    }
                  }catch(error){
                    // console.log("Find unhidden fail: ",error);
                  }

                }
              }); //end iterate over all tag types
              
              //  return {videos: videos, buttonData:buttons, cardsByType:cardsByType, playerKey: this.state.playerKey+1};
              // console.log("Unexpand: ",videos,buttons,cardsByType);
              return {videos: videos, buttonData:buttons, cardsByType:cardsByType};
              // return {videos: videos, cardsByType:cardsByType};
          },()=>{ //handle after the display update
            this.closeNotecard();            
          });
          
        } //end delete handle
        else{
          this.closeNotecard();        
        }
        
      } catch (error) {
        this.closeNotecard();        
      }
      
     
  }
  /* @brief add the id of cards that need to be submitted
    */
  shouldInitiallySend(id) {
    const ret = !this.state.m_initiallySent.has(id);
    this.state.m_initiallySent.add(id);
    return ret;
  }

  /* @brief Callback when the overlay button is clicked, this is passed up from the players
  */
  overlayButtonClick(_details){
    
    // console.log("Click on button: ",_details);

    let disableButton = false;
    (this.props.filter.role || []).forEach(role=>{
      if(role==='SiteManager'){disableButton=true;}
    })
    if(disableButton){return;}
    
    //  console.log("Props: ",_details,this.props);
    // console.log("Button Click: ",_details);

    if(_details.type === 'Combined'){
      // this.props.pauseVideos([_details.chosenName]);
          try{
            const cardsByType = Object.assign({}, this.state.cardsByType);
            const thisType = Object.assign({}, cardsByType[_details.chosenName] || {});
            
            //Check if this card already exists
            if (thisType[_details.infractionID]) {
              this.openNotecard(thisType[_details.infractionID])
              // this.setState({expandedCard: thisType[_details.infractionID]});
              return;
            }

            //Don't allow access to create a card if in read-only
            if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "read"){
              return;
            }
  
            //Doesn't exist: start creating a card:
            this.renderMap(_details.infractionID);
            //Set up the driverID, prefere the tagged infraction, default to the global video id
            let driver = this.props.videos.byDriver[_details.infractionID]
            if(!driver && this.props.driver && this.props.driver.driverID){ driver =" DriverID: " + this.props.driver.driverID}
            if(!driver || driver.includes(',')){ driver ="DriverID: Unavailable"} //everything is null?
            driver = driver.replace('_', ' ');
            
            const card = {
              tag: _details.Tag,
              infractionType: _details.chosenName,
              infractionID: _details.infractionID,
              status: 'FleetReview',
              severity: SEVERITY.MEDIUM,
              timeReceived: moment(this.props.parentVideo.received),
              timeCreated: moment(),
              cardID: generateUniqueID(),
              parentID: this.props.parentID,
              vehicleID: this.props.video.vehicleId,
              siteID: this.props.video.siteid,
              notes: "",    
              name: driver,
              infractionTags : [],
              live: this.props.review24Hr
            };
            this.cardChange(card);
          } catch (error) {
            console.log("New card error: ",error);  
          }
    } //end handle combined
    else if(_details.type === 'playlist'){
      try{
        const cardsByType = Object.assign({}, this.state.cardsByType);
        const thisType = Object.assign({}, cardsByType[_details.chosenName] || {});
        //Check if this card already exists
        let foundCard = null;
        Object.values(thisType||[]).forEach(obj => {        
          if(obj.infractionID === _details.infractionID){foundCard=obj;}
        });
        if(!foundCard){ //card doesn't exist: so create?

           //Set up the driverID, prefere the tagged infraction, default to the global video id
           let driver = this.props.videos.byDriver[_details.infractionID]
           if(!driver && this.props.driver && this.props.driver.driverID){ driver =" DriverID: " + this.props.driver.driverID}
           if(!driver || driver.includes(',')){ driver ="DriverID: Unavailable"} //everything is null?
           driver = driver.replace('_', ' ');
           
           const card = {
             tag: _details.Tag,
             infractionType: _details.chosenName,
             infractionID: _details.infractionID,
             status: 'FleetReview',
             severity: SEVERITY.MEDIUM,
             timeReceived: moment(this.props.parentVideo.received),
             timeCreated: moment(),
             cardID: generateUniqueID(),
             parentID: this.props.parentID,
             vehicleID: this.props.video.vehicleId,
             siteID: this.props.video.siteid,
             notes: "",    
             name: driver,
             infractionTags : [],
             live: this.props.review24Hr
           };
           this.cardChange(card);

        }else{
          // console.log("Found card: ",foundCard);
          this.openNotecard(foundCard);          
        }

        return;
      } catch (error) {
        console.log("New card error: ",error);  
      }
    }

    // signal a performance mark for the button click, so we can use that as the starting
    // point for a timing event
    // perfDidClick("infraction-overlay-button");
    return;
    
  }//end overlay button click
 /* @brief Tell the backend to generate a map for the notecard
  */
  renderMap(_infractionID){
    Auth.currentSession().then(
        
      (auth) => {
        // console.log("Got Auth: ",auth);
        let apiName = "AuthLambda";
        let path = "/triggerMapGenerataion";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            review: {
              id: this.props.parentID,            
              infractionid: _infractionID,
            }
          }
        };
        return API.post(apiName, path, myInit);
      });
  }

  /* @brief Callback when a card is updated
  */
  cardChange(_inCard) {
    this.props.cardChange(_inCard);
    this.setState(state => {
      const cardsByType = Object.assign({}, state.cardsByType);
      const thisType = Object.assign({}, cardsByType[_inCard.infractionType] || {});      
      thisType[_inCard.infractionID] = _inCard;
      cardsByType[_inCard.infractionType] = thisType;
      return {cardsByType: cardsByType};
    },()=>{
        let cardToOpen = getCard(this.state.cardsByType,_inCard);
        if(cardToOpen){ this.openNotecard(cardToOpen.card);}
    });
  }//end cardChange

  //A request to open the notecard has been made
  openNotecard(_inCard){
    // console.log("Open the card: ",_inCard);
    this.setState({expandedCard: _inCard, cardOpenTimer: new Date()});
  }
  //A request to close the notecard has been made
  closeNotecard(){
    // console.log("Close the opened card: ");    
    this.setState({expandedCard: null});
    // console.log("Card still loaded (before)? ",document.getElementById('expanded-card-id'))
    // let element = document.getElementById('expanded-card-id');
    // console.log("Card still loaded (before)? ",element);
    // if(element){
    //   unmountComponentAtNode(element);
    // }
    // console.log("Card still loaded (after)? ",document.getElementById('expanded-card-id'))
  }

  /*
  * @brief handle the response to the confirmed request to publish
  */
  handlePublish(_data){
    //Initiate a refresh on the video list
    // let promiseReturn = this.props.onRefresh(_data);
    // if(this.props.onRefresh){this.props.onRefresh(_data);}
  }

  /*
  * @brief Handle the response from getVideoUpdate, if the reprocessing has complete, execute a refresh, 
  * otherwise recall the polling method in 5 seconds.
  */
  handleReprocessStatus(_data){
    try {
      //  console.log("Handle Reprocess State: ",_data,_data.result.length);       
      // if(_data.result.length <1){return;}
      if(_data.result.length >0 &&  _data.result[0].status === 'processing'){ //is the concat finished?
        let type = _data.result[0].type;
        if(type==='Common'){type='highlights';}
        if(!this.state.ejectSets[type]){  type = _data.result[0].type+"-DF"; }//Check if this is a driver facing type

        var elapsedDuration = moment.duration(moment().diff(this.state.ejectSets[type].processTime));
        let elapsedSecs = elapsedDuration.asSeconds();
        if(elapsedSecs<600){ //max lambda time:
          var that = this;
          // console.log("Not Complete, try again: ?",elapsedSecs)
          window.setTimeout(() => { that.getVideoUpdate(type);  }, 5000); //retry with a timeout
        }

      }else{
        // console.log("Finished, call refresh update ", _data.result[0].type)
        this.refreshVideoUpdate(_data.result[0]);
      }
    } catch (error) {
      console.log("Handle reprocess error: ",error,_data);  
    }
  }
 /*
  * @brief Poll the Highlight SQL table to determine when the reprocessing request has completed
  */
  getVideoUpdate(_data){
    // console.log("Check video update:" ,_data);
    //Check if there are pending updates for any of the types:
    try {
        let ejectionSets = this.state.ejectSets || {};
        var that = this;
        if(_data){
          // console.log("Check on status for single",_data)
          const realPromise = Auth.currentSession().then(
            (auth) => {
              let apiName = "AuthLambda";
              let path = "/getReprocessStatus";
              let type_ = _data;
              type_ = type_.replace("-DF","");
              if(type_ === 'highlights'){type_ = 'Common';}
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  rejectList: {              
                    parentID: that.props.parentID,
                    type: type_,              
                  }
                }
              };
              return API.post(apiName, path, myInit);
            }
          ); //end promise definition
          realPromise.then(data => that.handleReprocessStatus(data))
          realPromise.catch(function(error) {
            console.log(error);
          });
          
        }else{ //otherwise get for everyone:
          Object.keys(ejectionSets).forEach(function(key) {
          
            if(ejectionSets[key].processing){ //If the state is set as processing - check for update:
                //Need to query:
                // console.log("Check on status for ",key)
                const realPromise = Auth.currentSession().then(
                  (auth) => {
                    let apiName = "AuthLambda";
                    let path = "/getReprocessStatus";
                    let type_ = key.replace("-DF","");
                    if(type_ === 'highlights'){type_ = 'Common';}
                    let myInit = {
                      body: {
                        token: auth.idToken.jwtToken,
                        rejectList: {              
                          parentID: that.props.parentID,
                          type: type_,              
                        }
                      }
                    };
                    return API.post(apiName, path, myInit);
                  }
                ); //end promise definition
                realPromise.then(data => that.handleReprocessStatus(data))
                realPromise.catch(function(error) {
                  console.log(error);
                });
            }//end check if processing
          });//end loop
        }
    }catch(err){
      console.log("video Update error: ",err);
    }
  }

  /*
  * @brief Trigger a refresh request on the parent class (VideoHighlight). The
  * Highlight data is reloaded given the current unique ID.
  */
  refreshVideoUpdate(data){
    // console.log("Refresh video update");
    //Complete returned: update eject sets:
    this.setState(prevState => {
      try{
        
        let type = data.type;
        if(type === 'Common'){type = 'highlights';}
        const ejectionSets = Object.assign({}, prevState.ejectSets);
        if(!ejectionSets[type]){  type = data.type+"-DF"; }//Check if this is a driver facing type
        ejectionSets[type].processing=false;
        ejectionSets[type].ejectList=[];
        ejectionSets[type].processTime= moment();
        return {ejectSets:ejectionSets};
      }catch(err){
        console.log("Reject click error: ",err,data);
      }
    }); 

    //Are we ready to refresh? -everyone check in?
    try {
      let ejectionSets = this.state.ejectSets;
      var processingCount = 0;
      Object.keys(ejectionSets).forEach(function(key) {
        if(ejectionSets[key].processing){ //If the state is set as processing - check for update:
          processingCount++;
        }
      }); 
      // console.log("Processing Count: ",processingCount);
      if(processingCount === 0){
        this.setState({processState:'processed'});
        this.props.onRefresh(data);
      }
    } catch (error) {
      console.log("Refresh on eject fail: ",error);
    }
    
  }

  /*
  * @brief A helper to pause videos by name (the chosenName used to refer to each video)
  * 
  * This is plural because each video you're going to pause at one time should be done in a group,
  * with a single call to this function. There is an issue with some of the pauses not happening
  * otherwise.
  */
  pauseVideos(names) {
    // console.log("Should pause names: ",names);
    this.setState(prevState => {
      let clonedStates = Object.assign({}, prevState.play_states);
      let clonedWorkaround = Object.assign({}, prevState.workaround_playing);
      if (names) {
        names.forEach(name => {
          if (clonedStates[name] !== undefined) {
            delete clonedStates[name];
          }
          if (clonedWorkaround[name] !== undefined) {
            delete clonedWorkaround[name];
          }
        });
      } else {
        clonedStates = {};
        clonedWorkaround = {};
      }
      return {play_states: clonedStates, workaround_playing: clonedWorkaround, shouldPause:[]};
    });
  }


  /* @brief Handler for when a card receives a change
  *
  * This happens when the user:
  *   - enters a comment on the expanded card
  *   - deletes a card
  *   - sets the severity in an expanded card
  */
  cardComment(_inCard) {
    let inCard = Object.assign({}, _inCard);
    if(inCard && inCard.infractionTags && Array.isArray(inCard.infractionTags)){
      inCard.infractionTags = inCard.infractionTags.toString();
    }
    // console.log("Card comment:" ,inCard);  
    this.props.cardChange && this.props.cardChange(inCard);
    if (inCard.delete) {
      // console.log("delete called from cardComment:" ,inCard);
      this.setState(state => {
        let cardsByType = Object.assign({}, state.cardsByType);
        //Delete the card:
        // console.log("Call remove by cardID:")
        cardsByType = this.removeCardFromCardsByCardID(cardsByType,inCard);
        return {cardsByType: cardsByType};
      }, this.closeNotecard());
    } else {
      this.setState(state => {

        const cardsByType = Object.assign({}, state.cardsByType);
        //Get the card from the current set
        let cardReturn = getCard(cardsByType,inCard);
        let thisCard = cardReturn.card;
        let cardKey = cardReturn.key;
        //update the stored card details        
        if(thisCard.infractionID && thisCard.cardID){
          if(inCard.comment){   thisCard.notes    = inCard.comment;}
          if(inCard.severity){  thisCard.severity = inCard.severity;}
          if(inCard.notes){     thisCard.notes    = inCard.notes; }
          cardsByType[cardKey] = cardsByType[cardKey] || {};
          cardsByType[cardKey][inCard.infractionID] = thisCard;
        }
        
        //Update the values on the opened card
        const retVal = {cardsByType: cardsByType};
        if (state.expandedCard) {
          const expandedCard = Object.assign({}, state.expandedCard);
          if(inCard.severity){expandedCard.severity = inCard.severity;}
          if(inCard.notes){   expandedCard.notes    = inCard.notes;}
          if(inCard.comment){ expandedCard.notes    = inCard.comment;}
          retVal.expandedCard = expandedCard;
        }
        return retVal;

      });
    }
  }

  changeDriverID(_inCard) {
    // console.log("Change driver id: ",_inCard)
    this.setState(state => {

      const cardsByType = Object.assign({}, state.cardsByType);
      //Get the card from the current set
      let cardReturn = getCard(cardsByType,_inCard);
      let thisCard = cardReturn.card;
      let cardKey = cardReturn.key;
      // console.log("This Card: ",cardReturn)
      //update the stored card details        
      if(thisCard.infractionID && thisCard.cardID){
        
        if(_inCard.driverID){   thisCard.driverID    = _inCard.driverID;}
        if(_inCard.name){  thisCard.name = _inCard.name;}
        // console.log("Update card: ",thisCard.driverID, _inCard.driverID,cardsByType,cardKey,_inCard.infractionID)
        cardsByType[cardKey] = cardsByType[cardKey] || {};
        cardsByType[cardKey][_inCard.infractionID] = thisCard;
      }


      //Handle the tagged infractions:
      const videos = Object.assign({}, state.videos);
      const buttons = state.buttonData;
      (thisCard.infractionTags||[]).forEach(_infTag=>{

        // console.log("Tags: ",_infTag);
        if(_infTag === 'Irrelevant'){return;}
        if(!videos.byID[_inCard.infractionID]){return;}

        const thisType = Object.assign({}, videos.byType[_infTag] || {});
        // console.log("ByType: ",thisType,videos);
        //Update the button data and videos.byType set with the new byType data:
        let foundButton = buttons.find(obj => obj.chosenName === _infTag);
        // console.log("FoundButton: ",foundButton,thisType);
        if(foundButton){
          foundButton = thisType;
        }
        // console.log("buttons: ",buttons);
        //Add the card to the list:
        // console.log("Update tag: ",_infTag,thisCard);
        cardsByType[_infTag] = cardsByType[_infTag] || {};
        cardsByType[_infTag][_inCard.infractionID] = thisCard;
        
      }); //end adding new infraction tags
      

      
      //Update the values on the opened card
      // const retVal = {cardsByType: cardsByType};
      const retVal = {videos: videos,cardsByType: cardsByType, buttonData:buttons};
      // console.log("CardsByType: ",cardsByType);
      return retVal;
    });
    
}


  /* @brief Callback when a card is created, this passes back the streamURL for the tagged infractions
  */
  onCardCreate(_inCard){
    // console.log("Card Create: ",_inCard);
    this.setState(state => {
      const videos = state.videos;
      videos.byID[_inCard.infractionID] = _inCard.streamURL;
      return {videos:videos}
    });
    

  }

  

  /* @brief handle the callback when a tag is changed in the notecard.
  * This refreshes the displayed cards in the list
  */
  cardTag(_inCard) {
    // console.log("Card tag: ",_inCard);
    this.setState(state => {
      //Update the notecard
      let cardsByType_ = Object.assign({}, state.cardsByType);
      //Get the card
      let cardReturn = getCard(cardsByType_,_inCard);
      let thisCard = cardReturn.card;
      let cardKey = cardReturn.key;
      // console.log("Card: ",cardKey,cardsByType_);
      //Add the card to the set
      cardsByType_[cardKey] = cardsByType_[cardKey] || {};
      cardsByType_[cardKey][_inCard.infractionID] = thisCard;
      

      const videos = Object.assign({}, state.videos);
      const buttons = state.buttonData;
      
      //Handle the tagged infractions:
      (_inCard.infractionTags||[]).forEach(_infTag=>{

        if(_infTag === 'Irrelevant'){return;}
        if(!videos.byID[_inCard.infractionID]){return;}

        const thisType = Object.assign({}, videos.byType[_infTag] || {});
        // console.log("ByType: ",thisType,videos);
        //Is the infraction already in the set?
        if(thisType.set){ //is this type defined?
          let foundElem = thisType.set.find(obj => obj.InfractionID === _inCard.infractionID);
          if(!foundElem){
            // console.log("Didn't find set")
            thisType.set.push({
              InfractionID: _inCard.infractionID,
              Tag: thisType.set.length+1,
              TimeOffset: 0,
              streamURL: videos.byID[_inCard.infractionID],
            });
            if(!videos.byID[_inCard.infractionID]){
            }
            thisType.count =thisType.set.length;
          }
        }

        //Update the button data and videos.byType set with the new byType data:
        let foundButton = buttons.find(obj => obj.chosenName === _infTag);
        // console.log("FoundButton: ",foundButton,thisType);
        if(foundButton){
          foundButton = thisType;
        }else{ //not currently in set, so add:
          videos.byType[_infTag]={ //define a generic detail to hold the cards?
            set: [], classification: _infTag,            
            type: 'playlist', streamURL: 'playlist',
            cards:{}, count: 0,
            chosenName:_infTag, displayName:_infTag,
            loadCount :1,
            loadComplete: false,
          };   
          // console.log("Set load order: ",_infTag,videos.byType[_infTag])
          
          videos.byType[_infTag].set.push({
            InfractionID: _inCard.infractionID,
            Tag:  videos.byType[_infTag].count+1,
            TimeOffset: 0,
            streamURL: state.videos.byID[_inCard.infractionID],
          });
          if(!state.videos.byID[_inCard.infractionID]){
            // console.log("No video by id found?",_inCard.infractionID);
          }
          videos.byType[_infTag].count +=1;  

          buttons.push( videos.byType[_infTag]);
        }
        // console.log("buttons: ",buttons);
        //Add the card to the list:
        // console.log("Add card to list: ",thisCard);
        cardsByType_[_infTag] = cardsByType_[_infTag] || {};
        cardsByType_[_infTag][_inCard.infractionID] = thisCard;

        //Notify the video clip player that a new clips is available
        try{
          if(this.state.playerRef[_infTag]){
            // console.log("Found a player for ",_infTag,this.state.playerRef[_infTag]);
            this.state.playerRef[_infTag].findUnHiddenClip();
          }
        }catch(error){
          // console.log("Find unhidden fail: ",error);
        }
        
        
      }); //end adding new infraction tags

      //Handle the tagged infractions:
      if(_inCard.toRemove){
        // console.log("Sent remove command: ",_inCard);
        const thisType = Object.assign({}, videos.byType[_inCard.toRemove] || {});
        //Is the infraction already in the set?
        if(thisType.set){ //is this type defined?
          let foundElem = thisType.set.find(obj => obj.InfractionID === _inCard.infractionID);
          if(foundElem){
            // console.log("Remove from ",thisType.set);
            let foundButton2 = buttons.find(obj => obj.chosenName === _inCard.toRemove);
            thisType.set.splice(thisType.set.findIndex(item => item.InfractionID === _inCard.infractionID), 1)

            thisType.count =thisType.set.length;
            foundButton2 = thisType; //update the button
          }
          // console.log("Button post remove: ",buttons);
        }//end type check
        //Update the infraction tags in the other sets:
        for(const key_ of Object.keys(cardsByType_)){
          const thisCardID = Object.assign({}, cardsByType_[key_][_inCard.infractionID]);
          if(thisCardID.infractionID && thisCardID.cardID){
            cardsByType_[key_][_inCard.infractionID].infractionTags = _inCard.infractionTags;
          }
        }
        //Remove the card based on the type
        cardsByType_ = this.removeCardFromCardsByType(cardsByType_,_inCard);

      } //end removing a tag
      return {videos: videos,cardsByType: cardsByType_, buttonData:buttons};
      // ,playerKey: this.state.playerKey+1
    });
  }


  /* @brief Helper function to remove a note card from a single type of list
    */
  removeCardFromCardsByType(_cardsByType, _inCard){
    if(!_cardsByType){return Object.assign({}, _cardsByType);}
    if(!_inCard){return Object.assign({}, _cardsByType);};
    // console.log("Remove card:" ,_cardsByType,_inCard);
    const thisCardType2 = Object.assign({}, _cardsByType[_inCard.toRemove] || {});
    // console.log("In set: ",thisCardType2);
    if(thisCardType2){
      Object.keys(thisCardType2).forEach(key => {
        if (thisCardType2[key].cardID === _inCard.cardID){
          // console.log("Found to delete: ",key);
          delete thisCardType2[key];
        }
      });
      // console.log("Update: ",thisCardType2)
      _cardsByType[_inCard.toRemove] = thisCardType2;
    }else{
      // console.log("Not found in cardsByType")
    }
    // console.log("CardsbyType, ",_inCard.toRemove,_cardsByType[_inCard.toRemove]);
    return _cardsByType;
  }

  /* @brief Helper function to remove a note card from all lists
    */
  removeCardFromCardsByCardID(_cardsByType, _inCard){

    if(!_cardsByType){return Object.assign({}, _cardsByType);}
    if(!_inCard){return Object.assign({}, _cardsByType);};
    // console.log("Remove  by id:" ,_cardsByType,_inCard);
    // const thisCardType2 = Object.assign({}, _cardsByType[_inCard.toRemove] || {});
    Object.keys(_cardsByType).forEach(typeKey_ => {
      Object.keys(_cardsByType[typeKey_]).forEach(propKey_ => {
        if (_cardsByType[typeKey_][propKey_].cardID === _inCard.cardID){
          // console.log("Found to delete")
          delete _cardsByType[typeKey_][propKey_];  
        }
      });
    });
    return _cardsByType;
  }
  

   /* @brief Handle the eject button press, add current tag to list
    */
   ejectClick(_data){
    try{
      //  console.log("Eject received: ",_data);
      if(_data.toRemove){
            
            if(_data.infractionTags.length === 0){
              // console.log("Empty just delete:")
              _data.delete = true;
            }
            //This from an eject request,not a delete card request
            if(!_data.metaData){ //ejected from the infraction clips, not from the highlights (highlights will have metadata)
              //this flag is passed to preserve the set order, if eject removes this then the skip breaks the play order
              //in the videoClipPlayer 
              _data.ejected = true; 
            }  

            if(_data.delete ){
              // console.log("delete card");
              this.cardTag(_data);
              this.cardComment(_data);  //card change
              this.unexpandCard(_data); //handle close
            }else{ //not deleting the card, just removing a tag
              //  console.log("Eject clip");
              this.cardTag(_data);
              this.cardComment(_data);
            }

          this.setState(prevState => {
            try{
                // console.log("EjectIDS: ",_data.hiddenIDs,_data.toRemove,_data.ejectedIDs);
              const ejectionIds = Object.assign({},prevState.ejectionIds);
              if( !ejectionIds[_data.toRemove] ) {ejectionIds[_data.toRemove]={ids:[],infractionIds:[]} }//create the default structure
              ejectionIds[_data.toRemove].ids = _data.hiddenIDs;
              if(_data.ejectedIDs){
                ejectionIds[_data.toRemove].infractionIds = _data.ejectedIDs
              }
              // console.log("Set EjectIDS:",ejectionIds);
              return {ejectionIds:ejectionIds};            
            }catch(err){
              // console.log("eject ids udpate error: ",err);
            }
           
          }); //end set state:
        // return;
      }
      else{
       
      }

      //Is a clip from an offline highlights video being ejected?
      const metadataValue = _data.metaData;      
      if(metadataValue){
        // console.log("Process Metadatavalue ", metadataValue);
        // Send request to modify the SQL table:
        if(VIDEOREVIEW_NOREPORT){
          console.log("Eject skipped");
        }else{
          Auth.currentSession().then(
            (auth) => {
              let apiName = "AuthLambda";
              let path = "/updateHighlightClipStatus";
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  review: {
                    id: this.props.parentID,
                    infractionid: metadataValue.InfractionID,
                    username: auth.idToken.payload['cognito:username'],
                    status: "ToEject",
                  }
                }
              };
              return API.post(apiName, path, myInit);
            }
          );
        }
        //Update the ejectSets to record the ejected clip in the state variable
        this.setState(prevState => {
          try{
           // console.log("Remove setState: ",_data);
           const name = _data.name;
           const ejectionSets = Object.assign({}, prevState.ejectSets);
           if( !ejectionSets[name] ) {ejectionSets[name]={ejectList:[], processing:false, processTime: moment()}} //create the default structure
           if(!ejectionSets[name].ejectList.includes(metadataValue)){
             ejectionSets[name].ejectList.push(metadataValue);
           }
 
           //Add it to the highlights, but don't if it is driver facting:
           if(name!=='highlights' && !name.includes("-DF")){
             if( !ejectionSets['highlights'] ) {ejectionSets['highlights']={}; 
             ejectionSets['highlights'].processing=false; 
             ejectionSets['highlights'].processTime=moment();
             ejectionSets['highlights'].ejectList=[]; } //create the default structure
             ejectionSets['highlights'].ejectList.push(metadataValue);
           }
 
           //Track the ejected clips between tabs using the infractionids:
           const ejectionIds = Object.assign({},prevState.ejectionIds);
          //  console.log("Set for all tags?");
           (_data.infractionTags||[]).forEach(_infTag=>{
               if( !ejectionIds[_infTag] ) {ejectionIds[_infTag]={infractionIds:[],ids:[]} }//create the default structure
               ejectionIds[_infTag].infractionIds.push(_data.infractionID);
           });
           // The default return to set:
           let returnObject = {ejectSets:ejectionSets, ejectionIds:ejectionIds}
           //If we are in the 'processed' state, upgrade to 'canProcess' to enable the button
           if(prevState.processState==='processed'){
            returnObject.processState = 'canProcess';
           }
 
           return returnObject;
         }catch(err){
           console.log("Reject click error: ",err);
         }
       }); //end set state:
        
      } //end check for metadata
    } catch (error) {   }
} //end eject
/* @brief Mark the clip as available, remove the ejection
*/
undoEjectClick(_data){
  try{
      //Need to undo on current:
    const metadataValue = _data.metaData;
    if(metadataValue){
      // Send request to modify the SQL table:
      Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda";
          let path = "/updateHighlightClipStatus";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              review: {
                id: this.props.parentID,
                infractionid: metadataValue.InfractionID,
                username: auth.idToken.payload['cognito:username'],
                status: null,
              }
            }
          };
          return API.post(apiName, path, myInit);
        });
    }

    this.setState(prevState => {
        try{
        const name = _data.name;
        const ejectionSets = Object.assign({}, prevState.ejectSets);
        if( !ejectionSets[name] ) {ejectionSets[name]={ejectList:[], processing:false, processTime: moment()}} //create the default structure
        //Loop through the list and remove the current:
        for( var i = 0; i < ejectionSets[name].ejectList.length; i++){ 
          if ( ejectionSets[name].ejectList[i].InfractionID === metadataValue.InfractionID) { //is this the one we want to undo?
            ejectionSets[name].ejectList.splice(i, 1); 
          }
        }
        for( var j = 0; j < ejectionSets['highlights'].ejectList.length; j++){ 
          if ( ejectionSets['highlights'].ejectList[j].InfractionID === metadataValue.InfractionID) { //is this the one we want to undo?
            ejectionSets['highlights'].ejectList.splice(j, 1); 
          }
        }
        return {ejectSets:ejectionSets};
      }catch(err){
        console.log("Reject click error: ",err);
      }
    }); //end set state:
  } catch (error) { console.log("Erorr on undo",error)  }
} //end undo eject

handleLoadComplete(_data){
  
    // console.log("Complete: ",_data);
    if(this._isMounted){
      this.setState(prevState => {
        let buttonDataTmp = [...prevState.buttonData];
        let button = buttonDataTmp.find(elem_ => (elem_.chosenName === _data.name && elem_.type == _data.type));
        if(button.loadComplete===false){
          // console.log("load complete",_data,new Date() - this.state.timeDebug)
        }
        button.loadComplete = true;
        return({buttonData: buttonDataTmp});
         
      });
    }
    
  
}

/*
* @brief Callback for the publish button, prompt the user to enter the ClientID and confirm desire to publish
*/
confirmPublish() {
  //  console.log("Publish: ",this.props,this.state.videoClientID)
  return new Promise((resolve, reject) => {
    // if(this.props.groupconfig.group ==="reviewgroup"){
    if(VideoReviewGroups.includes(this.props.groupconfig.group)){
      if(window.confirm("Submit video?")){
        //Configure the destination group:
        let directedDestination = "TESTINGGROUP";
        // let directedDestination = "BETAGROUP"; //debug testing
        if(this.props.review24Hr){
          directedDestination = "DEVGROUP";
        }
        //Set up the Publish API request
        const realPromise = Auth.currentSession().then(
          (auth) => {
            let apiName = "AuthLambda";
            let path = "/publishVideo";
            let myInit = {
              body: {
                token: auth.idToken.jwtToken,
                id: this.props.parentID,
                client: directedDestination,
                from: this.props.groupconfig.group,   
                type: "24review",
                // mode: 'test'
              }
            };
            return API.post(apiName, path, myInit);
          }
        ); //end promise definition
        realPromise.then(data => {
          this.handlePublish({id:this.props.parentID})
          // console.log("Publish returned: ",data)
          if(data && data.error && data.error === true ){             
            window.confirm("Request interrupted, please check your network connection and try again. \n\n") 
          }else{
            this.setState({published: true, m_bSubmitted:true});
          }
          resolve(data);
        })
        realPromise.catch(function(error) {            
          window.confirm("Request interrupted, please check your network connection and try again.")
          console.log(error);
          reject({error:true})
        });
      }
      else{ //canceled
        // return {error: false};
        resolve({error: false});
      }

    }else{
      //Prompt the user to enter the ID, default to BIS 
      let suggestedDestination = this.state.videoClientID || "BIS";      
      // let suggestedDestination = "BIS";      
      var promptReturn = window.prompt("ClientID to publish to:",suggestedDestination);
      if (promptReturn) { //if anything was entered
        //Ask the user to confirm that the clientid is correct and they want to publish
        promptReturn = promptReturn.toUpperCase();
        if(window.confirm("Publish to "+promptReturn)){

          let queryBody = {
            token: null,
            id: this.props.parentID,
            client: promptReturn,  
            from: this.props.groupconfig.group, 
            type: "24review",
            // mode: 'test'
          }
          // console.log("Starting publish")
          const realPromise = Auth.currentSession().then(
            (auth) => {
              queryBody.token = auth.idToken.jwtToken;
              let apiName = "AuthLambda";
              let path = "/publishVideo";
              let myInit = {
                body:queryBody
              };
              return API.post(apiName, path, myInit);
            }
          ); //end promise definition
          realPromise.then(data => {
            // console.log("Publish returned: ",data)
            this.handlePublish({id:this.props.parentID})
            if(data && data.error && data.error === 'true' ){              
              window.confirm("Request interrupted, please check your network connection and try again. \n\n")
            }else{
              this.setState({published: true, m_bSubmitted:true});
            }
            resolve(data)
          })
          realPromise.catch(function(error) {
            //auto resend if the connection is missed:
            console.log("Resending publish request", error);
            const realPromise2 = Auth.currentSession().then(
              (auth) => {
                queryBody.token = auth.idToken.jwtToken;
                let apiName = "AuthLambda";
                let path = "/publishVideo";
                let myInit = {
                  body: queryBody
                };
                return API.post(apiName, path, myInit);
              }
            ); //end promise definition
            realPromise2.then(data => {
              console.log("Publish (2) returned: ",data);
              this.handlePublish({id:this.props.parentID})
              this.setState({published: true, m_bSubmitted:true});
              resolve(data)
            })
            realPromise2.catch(function(error2) {
              window.confirm("Request interrupted, please check your network connection and try again. \n\n"+error)
              console.log(error2);
              reject({error:true})
            });
          });
        }
      }//end prompt return
      else{ //canceled
        // return {error: false};
        resolve({error: false});
      }
    }//end group else 
  }); //end promise return
}//end 

 /*
  * @brief Callback for the publish button, prompt the user to enter the ClientID and confirm desire to publish
  */
 clickUnusable(){
  // console.log("Click unusable")
  //Add a promise for the StatefulButton - allow the button to stay disabled while the API processes
  return new Promise((resolve, reject) => {
    let statusToSet = this.state.markedUnusable? 200: 201;
    
    // setTimeout(()=>{ //timeout for debugging to make sure the button is disabled
      //Set up the API call
      const realPromise = Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda"; //define the service
          let path = "/updateStatus"; //define the API function
          let myInit = {
            body: {
              token: auth.idToken.jwtToken, //pass the authentication toke
              id: this.props.parentID, //ID of the video to load from Jobs SQL table
              status: statusToSet,  //The new status to set for the video file
            }
          };
          return API.post(apiName, path, myInit); //Send to the API
        }
      ); //end promise definition
      //Handle the return from the API
      realPromise.then(data => { //Success Return
        this.setState({markedUnusable: !this.state.markedUnusable}); //update the tracked state to change the button text
        resolve({error:false}); //send resolve to end the promise
      })
      realPromise.catch(function(error) { //Error/Fail return
        console.log(error);
        reject({error:false}); //Send reject to end the promise
      });

    // },2000);
    
  });//end of callback promise
}//end 

/*
* @brief Callback for the reprocesss button click, return a promise to allow for disable states in the Stateful button
*/
clickReprocess(){
  return new Promise((resolve, reject) => {
    try{
      // console.log("Reprocess: ",this.props.parentID,this.state.ejectSets,this.state.processState);
      var localThis = this;
      let processingCount = 0;
      let apiPromises = []
      //Get the state at the moment of call and operate on this data: (snapshot of the state object)
      localThis.setState(prevState => {
        //Local reference to the ejection sets:
        const ejectionSets = Object.assign({}, prevState.ejectSets);
        //Iterate over the ejectionSets object (pass the name(type) to each iteration)
        Object.keys(ejectionSets).forEach(function(key) {
          if(!ejectionSets[key].processing){ //If the state is not processing, then run:
            processingCount++;
            //Get the data assigned to the button
            let localButtonData = localThis.getButtonDataByType(prevState.buttonData,key);
            // console.log("localButtonData: ",localButtonData, buttonData,key);
            let rejectList = {}
            let type_ = key.replace("-DF", "");
            if(type_ === 'highlights'){type_ = 'Common';}
            //Create a list of clips to eject to be sent to the processing
            rejectList.rejectionList = ejectionSets[key].ejectList;
            rejectList.parentID= localThis.props.parentID;
            rejectList.type=type_;
            rejectList.metadata= localButtonData.metadata;
            rejectList.s3key= localButtonData.s3key;
            //  console.log("RejectList to send: ",rejectList);
            // Invoke lambda, called once for each type of Highlight video that needs to be reprocessed
            // This will call concatHighlightVideo and pass the list of clips to eject            
            // The concatHighlighVideo can run for 900 seconds but can't be triggered from a API gateway call (30 second limit)
            Auth.currentSession().then(
              (auth) => {
                let apiName = "AuthLambda";
                let path = "/reprocessHighlightVideo";
                let myInit = {
                  body: {
                    token: auth.idToken.jwtToken,
                    rejectList: rejectList,
                  }
                };
                apiPromises.push( API.post(apiName, path, myInit));
            });
  
            //Update the processsing flag on each ejection set        
            try{
              ejectionSets[key].processing=true;
              ejectionSets[key].processTime= moment();
            }catch(err){
              console.log("Reject click error: ",err);
            }
  
          }//end not processing check
        });  //end loop
        //Update the ejectionSets values once all the API has been triggered for each requested video
        let returnState = {ejectSets:ejectionSets};
        return returnState; //update the state variables
      }, ()=>{ //once the eject sets are updated, run the rest:

        let allReturn = Promise.all(apiPromises) //wait for all the API responses to return
        allReturn.then( (_data)=>{ 
          let returnState = {}
          //Check the current state, if we are in CanProcess then upgrade to Processsing
          if(this.state.processState === 'canProcess'){
            returnState.processState = 'processing'
          }
          //Trigger the state change and set the button promise to resolved
          this.setState(returnState,()=>{
            resolve({error:false }); //send resolve to end the promise
          });

        });
        allReturn.catch( (_err)=>{ //handle error response from the triggered APIs
          console.log("promise return failed: ",_err);
          reject({error:true, msg: 'fail on api promises'}); //Send reject to end the promise
        });

        // console.log("ProcessCount: ",processingCount);
        //Update the state , record the timeoutID so that the refresh polling can be canceled on exit
        let returnState = {processInfractionVideoTime:moment()};  
        if(processingCount>0){
          returnState.refreshTimeoutID = setTimeout(() => { localThis.getVideoUpdate();  }, 15000); //retry with a timeout
        }        
        this.setState(returnState);
      }
      ); //end set state:
    
    } catch (error) { 
      console.log("Reprocess error: ",error);  
      reject({error:true}); //Send reject to end the promise
    }
  });
} //end reprocessClick

/* @brief The type button was clicked, update the chosen name  */
clickTypeButton = (_row, _chosenName) => {
  // console.log("Clicked: ",_chosenName);
  // Pause the video, and then set in the state that we've chosen a new video
  if (_row.streamURL) {
    // console.log("Clicked on button: ",chosenName);
    this.setState({chosen: _chosenName});
    // perfDidClick("video-reviewer-choice-button");
  }
}

/* @brief Callback to confirm that the video should be published    */
confirmReviewPublish(){

  if(window.confirm("Publish to REVIEWGROUP")){
    const realPromise = Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/publishVideo";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            id: this.props.parentID,
            client: "REVIEWGROUP",  
            from: this.props.groupconfig.group,    
          }
        };
        return API.post(apiName, path, myInit);
      }
    ); //end promise definition
    realPromise.then(data => {
      this.handlePublish({id:this.props.parentID})
      this.setState({published: true, m_bReturned:true});
    })
    realPromise.catch(function(error) {
      console.log(error);
    });
  }//end window confirm
}//end confirmReviewPublish

/* @brief callback when the completed button is pressed
*/
confirmReviewComplete(){
  if(window.confirm("Mark the video review as Complete?")){
    Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/updateReviewProgress";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,                        
            review: {
              id: this.props.parentID,
              username: auth.idToken.payload['cognito:username'],
              status: "300",      
            }
          }
        };
        return API.post(apiName, path, myInit);
      }
    ); //end promise definition        
  }//end of if       
}//end confirmReviewComplete


  /* @brief Render the content of the current page, automatically called when react determines the page needs refreshing.
  */
  render() {
    // console.log("Triggered Render");
    // const buttonData = this.m_buttonData;
    const buttonData = this.state.buttonData
    const chosen = this.state.chosen;
    // const clips = this.m_clips || {};
    const byID = this.state.videos.byID;
    // console.log("Render: ",this.state,this.props);
   
    let arbitraryStreamURL = null;
    Object.values(this.props.videos.clips||[]).forEach(rowArray => {
      rowArray.forEach(row => {
        if (row.streamURL) {
          arbitraryStreamURL = row.streamURL;
        }
      });
    });
    if (!arbitraryStreamURL) {
      buttonData.forEach(row => {
        try{
          if (row.streamURL) {
            arbitraryStreamURL = row.streamURL;
          }
        }catch(e){}
        
      });
    }
    
    let players = null;
    
    // buttonData holds each row of video that we want to create a button for,
    // each one also has an associated video player for it (though only one video player
    // will be shown at a time)

    
    const tabs = this.state.tabs;

    //Find the next set to load:
    let iInfractionLoadIndex = 100;
    let iTotalBlobs = 0;

    for(const row of buttonData){
      // console.log("Test: ",row.chosenName,row.loadComplete)
      if(row.loadComplete===false){
        if(row.type === 'playlist' ){
          // console.log("TEsting: ",row.chosenName, row.loadOrder, row.set.length);
          if(row.set.length > 0){
            // iInfractionLoadIndex = Math.min(iInfractionLoadIndex,row.loadOrder);        
            if(row.loadOrder < iInfractionLoadIndex){
              // console.log("TEsting: ",row.chosenName, row.loadOrder, row.set.length);
              iInfractionLoadIndex = row.loadOrder;
            }
          }
        }else if(row.streamURL){
          // console.log("TEsting2: ",row.chosenName);
          // iInfractionLoadIndex = Math.min(iInfractionLoadIndex,row.loadOrder);        
          if(row.loadOrder < iInfractionLoadIndex){
            // console.log("TEsting2: ",row.chosenName, row.loadOrder);
            iInfractionLoadIndex = row.loadOrder;
          }
        }
        
      }
      //Sum all the loaded cache files
      if(row.type === 'playlist' ){
        for(const elem_ of row.set){
          if(elem_.blob){
            iTotalBlobs+=1;
          }
        }
      }
    }
    /* Create the video players for each button */
    // console.log("Play_state: ",this.state.play_states);
    // console.log("workaround_playing: ",this.state.workaround_playing);
    // console.log("To load: ",iInfractionLoadIndex,iTotalBlobs);
    // console.log("ButtonData: ",buttonData,this.state.chosen,this.state.activeTabKey);
    if(buttonData && tabs){
      try {
        players = buttonData.map( (row,idx) => { // Set video for restored cards by their infraction ID
          //  console.log("Test load: ",row.chosenName,row.loadOrder,iInfractionLoadIndex, row.loadOrder? row.loadOrder===iInfractionLoadIndex : true)
          Object.values(row.cards || {}).forEach(card => {
              card.video = byID[card.infractionID];
          });
          
          //Only pass the relevant eject set to the player:
          let tmpSetName = row.chosenName;
          if(row.flag===2){ tmpSetName=tmpSetName+"-DF"; }

          if(row.streamURL === 'playlist'){
            row.count = row.set.length; //don't modify, this is used to determine the number of available clips, impacts the arrows.
            // row.count = getTotalLessEjections(this.state.ejectionIds[row.chosenName],row.set,this.state.ejectionIds)
            // console.log("Tagged row: ",row,row.count);
          }
          //Get a reference to the VideoClipPlayer so we can execute updates on the child methods
          const ref = player => {
                this.state.playerRef[row.chosenName] = player
          }//end ref handle
          // if(row.chosenName === "Seatbelt"){
          //   console.log("Player row:",row,this.state);
          // }
          //check if the infraction clips have had chance to queue into the load sequence, prioritize them first
          if(this.state.loadedReturns.length>0 && row.streamURL !== 'playlist'){
          // if(row.streamURL !== 'playlist'){
            // console.log("Wait for large videos?", this.state.loadedReturns);
            // return; //don't create the player for the larger videos yet
            
          }
          // if(row.streamURL !== 'playlist'){
          //   return;
          // }
          //Don't pass the empty buttons to the VideoClipPlayer - this will create empty video player references
          if(row.streamURL === 'playlist' && row.set.length ===0){
            // console.log("Non playlist row length: ",row);
            return;
          }
          //Don't pass empty buttons to the videoClipPlayer - this rejects non infraction/clip players
          if(row.streamURL !== 'playlist' && !row.streamURL){
            //  console.log("Non playlist row: ",row);
            return
          }
          //  console.log("Load Allowed: ",this.state.chosen,row.loadOrder? row.loadComplete ? true: row.loadOrder===iInfractionLoadIndex : true);

            // console.log("Row: ",row,this.state.chosen)
            if(row.type==='original' && this.props.videos && this.props.videos.originalDeleted===true){
              if(this.state.chosen === 'original'){
                  return dvrdeleted_message();
              }
            }else{
              return (
                <VideoClipPlayer  className={"VideoClipPlayer"}
                                // ref={ref}  
                                currentClip={row}                               
                                key={row+idx}
                                filter={this.props.filter}
                                cardsByType={this.state.cardsByType}
                                bookmarkLoad = {this.state.bookmarkLoad}
                                ejectSets={this.state.ejectSets}
                                ejectedIDs = {this.state.ejectionIds}
                                ejectSetName = {tmpSetName}
                                markViewed={this.markViewed} playerKey={this.state.playerKey}
                                chosen={this.state.chosen}
                                play_states={this.state.play_states}
                                overlayButtonClick = {this.overlayButtonClick}
                                roiSelectClicked = {this.openROI}                              
                                groupconfig= {this.props.groupconfig}
                                pauseVideos={this.pauseVideos}
                                ejectClick = {this.ejectClick}
                                undoEjectClick = {this.undoEjectClick}
                                username = {this.props.username}  
                                currentlyPlaying = {this.currentlyPlaying}  
                                filename = {this.props.filename}
                                type = {this.props.video.vehicleType}
                                
                                bLoadAllowed ={row.loadOrder? row.loadComplete ? true: row.loadOrder===iInfractionLoadIndex : true}   
                                // bLoadAllowed = {true}
                                loadComplete = {this.handleLoadComplete}
                                // bMemoryOptimized = {true}      
                                // bMemoryOptimized = {iTotalBlobs > 150?true:false}    
                                bMemoryOptimized = {false}      
                                handleLoad = {this.handleLoad} 
                                // refreshCount = {this.state.forcePlayerRefresh}   
                                refreshTrigger = {this.state.skipClickTimer}
                                interruptTimer = {this.state.expandedCard? this.state.cardOpenTimer:null}
                />  
              )
            }
            
          
                
        }); 
      
      } catch (error) {
        
      }

      // Helper method to format the type buttons, otherwise this has to be repeated on each tab
      const generateTypeButton = (_row, _chosen, _ejectIds, )=>{
        // Get the internal and displayed name (been set up already in the constructor)
        let displayName = getButtonDisplayname(_row,_ejectIds);
        // CSS classes for the button
        let classes = getButtonCSSClass(_row.chosenName,_chosen,_row);
        return (
                <button className={classes.join(" ")} key={_row.classification + "-" + _row.type}
                  onClick={()=>{this.clickTypeButton(_row,_row.chosenName)}} >
                  {displayName}
                </button>);
      }///end generateTypeButton

      try{
        /* Create the buttons for each tab: */

        //Tab with the clips and notecards
        tabs[0].set = buttonData.filter(row=>{ //Apply a filter first to the returned list
          if( (row.type === 'playlist' && row.flag!=2) ||  row.type === 'playlist' && row.count===0){
                return row;
          }
          //If we are only doing one column then add the original when it is available:
          if(this.props.video.vehicleType ==='24Review'){
            if(row.type === 'original'){return row;}
          }
        }).map(row=>{ return generateTypeButton(row,chosen,this.state.ejectionIds) }); //end button creation/map

        //Tab with the highlight videos
        tabs[1].set = buttonData.filter(row=>{ //Apply a filter first to the returned list
          if(row.type !== 'playlist' && row.flag===0 ){
                return row;
          }
        }).map( row=>{ return generateTypeButton(row,chosen,this.state.ejectionIds) }); //end button creation/map

        // Driver facing infractions - (deprecated)
        tabs[2].set = buttonData.filter(row=>{ //Apply a filter first to the returned list
          if(row.flag===2){
                return row;
          }
        }).map(row=>{ return generateTypeButton(row,chosen,this.state.ejectionIds) }); //end button creation/map
      }
      catch (error) {
      }
      
    }//end check if buttondata is available
   
    //Configure display flags and visibility styles for the buttons and notecards
    const displayCards = chosen !== 'original' && chosen !== 'highlights';
    
    let publishStyle = {visibility:"hidden"};      
    // let ejectVideoStyle = {visibility:"hidden"};      
    let publishText = "Publish";    
    if(VideoReviewGroups.includes(this.props.groupconfig.group)){publishText = "Submit";}


    let reviewStyle = {visibility:"hidden"}
    let completeStyle = {visibility:"visible"}
    
    //Update the text on the Review button
    let reviewText = "Return";
    if(this.state.m_bReturned){reviewText = "Returned";}
    //Update the text on the publish button
    if(this.state.m_bSubmitted){
      if(VideoReviewGroups.includes(this.props.groupconfig.group)){   publishText = "Submitted";   }      
      else{publishText = "Published";}
    }
    //Hide the complete button based on the allowed groups
    if(allowedGroups.includes(this.props.groupconfig.group)){
      if(this.props.groupconfig.group!=="drive_test_group"){
        completeStyle = {visibility:"hidden"};
      }
    }
    //Toggle the review and publish button visibilty based on group
    if(allowedGroups.includes(this.props.groupconfig.group)){
      if(this.props.groupconfig.group!=="reviewgroup" && this.props.groupconfig.group!=="drive_test_group"){
        reviewStyle = {visibility:"visible"};      
      }
      //Fade out the publish button while the video is processing
      if(this.state.processState.includes('processing') || this.state.processState.includes('canProcess')){// if(isProcessing||canReprocess){
        publishStyle = {visibility:"visible",opacity: "0.5"};               
      }else{
        publishStyle = {visibility:"visible",opacity: "1"};
      }
      if(this.props.groupconfig.group=="reviewgroup"){
        publishStyle = {visibility:"visible",opacity: "1"};
      }
    }

     //Check the roles assigned to the user:
     let disableEdits = false;
     (this.props.filter.role || []).forEach(role=>{
       if(role==='SiteManager'){disableEdits=true;}
     })

    /*@brief helper method to set which tab has been selected
    */
    const setActiveKey = (_key) => {
      const chosenName = this.state.chosen;
      if(_key ==='highlights'){
         if(this.state.highlightTabCount===0 && this.state.bookmarkLoad && this.state.bookmarkLoad.tag){
          this.setState({chosen:this.state.bookmarkLoad.type,hightlightTabCount: 1})
         }
      }
      const lastActiveTab = this.state.activeTabKey;
      const tabs = this.state.tabs;
      //Remember where we were last on the tab set:
      for(const tab of tabs){
        if(lastActiveTab === tab.eventKey){
          tab.last = chosenName;          
        }        
      }
      //Switch back to the last location
      for(const tab of tabs){
        if(_key === tab.eventKey){
          if(tab.last){            
            this.setState({chosen:tab.last});
          }      
        }        
      }
      this.setState({activeTabKey:_key, tabs:tabs})
    }

    let fileNameTitle = this.props.filename;
    if(this.props.groupconfig.group && this.props.groupconfig.group==="reviewgroup"){fileNameTitle = filenameAlphaStripper(this.props.filename);}

    
    let playerStyle ={};
    
    //Resize the video player for the PM1251 DVR Event AI files:
    if(this.props.filename.toLowerCase().includes("pm1251")){ 
      let playingFileFlag = 0;
      if(this.state.currentPlayingID){
        try{
          playingFileFlag = this.props.videos.byFlag[this.state.currentPlayingID];
        }catch(e){
        }
      }
      if(playingFileFlag != 2){
        playerStyle.maxWidth = "50%";
      }
    } //end resize player check
    // Create the main components of the Reviewer, which are the chooser buttons, the players, and the CardLists
    // console.log("Cards to display: ",this.state.chosen,this.state.cardsByType[this.state.chosen])

    //Configure the view of the control buttons
    let bAllowControl = true;
    if(this.props.groupconfig.permissions){
      bAllowControl = false;
      if(this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "write"){
        bAllowControl = true;
      }
      //Check if the user permission is read=only
      if(this.props.groupconfig.permissions.analytics && this.props.groupconfig.permissions.analytics === "read"){
        disableEdits = true;
      }      
    }
    
    //Configure the processing icon's img and visibility based on the state
    let reprocessStyle = {visibility:"hidden"}; 
    let procIcon = null;
    let allowPublish = false;
    if(bAllowControl){
      // console.log("Render processing button: ",this.state.processState);
      switch(this.state.processState){
        case 'processing':
          procIcon = IconProcessing;
          if(this.props.groupconfig.group!=="reviewgroup"){    reprocessStyle.visibility="visible"; }
          break;
        case 'canProcess':
          procIcon = IconRepeat;
          if(this.props.groupconfig.group!=="reviewgroup"){    reprocessStyle.visibility="visible"; }
          if(this.props.groupconfig.group === 'reviewgroup'){allowPublish= true}
          break;
        case 'processed':
        default:
          procIcon = IconProcessing;
          reprocessStyle.visibility="hidden"; 
          allowPublish = true;
          break;
      }
      
    }
    // console.log("Allow Control:" ,bAllowControl, allowPublish);

    // console.log("Render called: ",this.state.expandedCard)
    //Create the combined HTML return: 
    return (
      <div className="video-reviewer">
        <div className="video-reviewer-top">
          {/* Load the tabs with the buttons*/}
          <ReviewTabs  tabContent={tabs}  videoType={this.props.videoType} groupconfig={this.props.groupconfig} 
            reviewerName={this.props.reviewerName} username = {this.props.username}
            activeTabKey={this.state.activeTabKey} onTabChange={setActiveKey}
          />
          {/* Load the video player region */}
          <div className="video-reviewer-players" style={playerStyle}>
            {this.props.filename && <h5 className="video-filename">{fileNameTitle}</h5>}
            {players}
          </div>
          {/* Display the loaded notecards on the right column */}
          { displayCards ?
           <CardListFunc cards={Object.values(this.state.cardsByType[this.state.chosen] || {})}
                     onCardClick={this.expandCard}
                     sortByCreated={true}
                     disableDrag={true}
                     groupconfig = {this.props.groupconfig}
           /> :
           <CardListFunc style={{opacity: '0'}} />
          }
        </div> 
        {/* Add the control buttons to the bottom of the page*/}
        <div className="video-reviewer-bottom">
          {bAllowControl?
            <div className="controlbuttons">

              <StatefulButton className={"reprocess-button"} style={reprocessStyle} tagged={this.state.processState} 
                onClick={this.state.processState!=='processing' && this.clickReprocess} > <img src={procIcon}/> </StatefulButton>
              
              <button className="complete-button"  onClick={this.confirmReviewComplete} style={completeStyle}> Review Complete </button> 
              <StatefulButton 
                className="publish-button"   style={publishStyle} 
                onClick={this.state.published ? ()=>{console.log("Already processed")} 
                          : (allowPublish)? this.confirmPublish: ()=>{}  
                        }
                
              > 
                  {publishText} 
              </StatefulButton>              
              <button className="review-button"  onClick={this.state.published ? ()=>{} : this.confirmReviewPublish} style={reviewStyle} > {reviewText}  </button>               
              <StatefulButton className="unusable-button"  onClick={ this.clickUnusable} style={reviewStyle}> {this.state.markedUnusable?"Mark Available":"Mark Unusable"} </StatefulButton> 
              
            </div>  
            :null          
          }
          
         
        </div>
        {/* Load the expanded notecard if this.state.expandedCard is set */}
        { this.state.expandedCard &&
          <ExpandedCard 
                        handleClose={this.unexpandCard}
                        {...this.state.expandedCard}
                        cardChange={this.cardComment}
                        tagChange={this.cardTag}
                        driverChange ={this.changeDriverID}
                        // driverChange ={()=>{console.log("Driver change requested")}}
                        newDetails= {this.onCardCreate}
                        scale={1}   
                        noLink={true}                     
                        noEdit={disableEdits}
                        // filter={this.props.filter}
                        groupconfig = {this.props.groupconfig}
                        siteDetails = {(this.props.possibleFilters.Sites||[]).filter(filt_ => {return this.state.expandedCard && filt_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}                                    
          />
        }
        {/* Load the ROI selector if this.state.roiselector is set */}
        { this.state.roiselector &&
          <ROISelector  handleClose={this.closeROI}
                        {...this.state.roiselector}
                        hasResults = {this.state.hasResults}                        
                        groupconfig = {this.props.groupconfig}
          />
        }
      </div> //end video-reviewer div definition
    );
  }
}

/*
* @brief Create a functional component wrapper for the class call
*/
const VideoReviewer = ({  video, videos, filename, filter, possibleFilters, groupconfig, cardsByType
                          ,reviewStatus,parentID,refreshList,onRefresh,cardChange,tagChange,updateVideos
                          ,driver,username,reviewerName,review24Hr,loadTime,videoType}) => {
  // console.log("Received:" ,video,groupconfig, filter)
  return <VideoReviewerClass
          // video = {{
          //     status: VIDEO_STATUS.AVAILABLE,
          //     uniqueKey: 'ad4ef557-349c-47bf-b6c7-bb220ada7755'
          // }}
          video = {video}
          videos = {videos}
          videoType = {videoType}
          filename = {filename}
          groupconfig= {groupconfig}
          filter = {filter}
          possibleFilters = {possibleFilters}
          cardsByType = {cardsByType}
          parentVideo={video}

          reviewStatus={reviewStatus}
          parentID={parentID} 
          refreshList= {refreshList }
          onRefresh={onRefresh}
          cardChange={cardChange}                             
          tagChange = {tagChange}
          vidChange = {updateVideos}
          driver={driver}
          username = {username}
          reviewerName = {reviewerName}
          review24Hr = {review24Hr}
          loadTime = {loadTime}
          
  />
 };

export { VideoReviewer };
 
