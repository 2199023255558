import React, { Component,PureComponent } from 'react';

import { Auth, API } from 'aws-amplify';

import * as moment from 'moment';
import './StorylinesTab.css';

import { StoryTile } from './StoryTile.js';
import { StoryLine } from './StoryLine.js';
import { Spinner } from '../ApiCaller';



/*
* @brief Display the card opened when clicking on a risk profile
*/
class StorylinesTab extends PureComponent {
    
  constructor(props) {
      super(props);

      this.onClick = this.onClick.bind(this);
      // this.fetchStoryLines = this.fetchStoryLines.bind(this);

      this.state = {
          shown: true,
          comments:[],
          currentUsername:null,
          // storylines: [],
          // loading: -1,
          selectedStoryLine: -1,
      };
  }

  componentDidMount(){
    //  console.log("StoryLine props: ",this.props);
  }

  /*
  * @brief Clicked on a row to select the storyline
  */
  onClick(_data){
    // console.log("Clicked on: ",_data);
    this.setState({selectedStoryLine:_data.idx});
  }

  /*
  * @brief Draw the photo and driverid
  */
  render() {

    let storylines = this.props.storylines;

    let storytiles = [];
    ( storylines||[]).forEach( (story_,idx) =>{
      storytiles.push( 
                        <StoryTile data={story_} total = {this.props.totalInfractions} onClick = {this.onClick} idx ={idx} key ={"storytiles-"+idx} groupconfig = {this.props.groupconfig}/>
                      );
    });//end drivertile populate

    // console.log("Selected storyline: ",storylines,this.state.selectedStoryLine);
    let storyInsert = <div className='nodata'>No data available, please select a StoryLine from a different driver</div>;
    if(storytiles.length>0){
      storyInsert = <div className='storytable'>{storytiles}</div>;
    }
    return (
        <div className='storylinestab'>
        
          {(this.state.selectedStoryLine>=0)?
            <StoryLine
              data={storylines[this.state.selectedStoryLine]}
              groupconfig = {this.props.groupconfig}
              possibleFilters = {this.props.possibleFilters}
              interactive = {true}
              onClose = {()=>{  //console.log("Close storyline called"); 
                                this.setState({selectedStoryLine:-1});}}
              photo={this.props.photo}
              cardChange={this.props.cardChange}
              eventNotify={this.props.eventNotify}              
            /> 
          :
            <div className="storylines-content" > 
              <div className='storyTop'>
                  
              </div>
              <div className='storyBottom'> 
                <div className="title" >Storylines for driver: {this.props.driverid}</div> 
                  {this.props.loadingStoryLines === 1 ? <Spinner /> :<div className='bottomcontent'>{storyInsert}</div>}
              </div>
            </div>
          }
        </div>              
        
    )
  }
}

export { StorylinesTab };