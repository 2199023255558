import React from 'react';

import '../VideoCard.css';
import './NoteCard-Expanded.css';

import * as moment from 'moment';

import blankMap from '../assets/map_unavail.jpg';

import hamburger from '../assets/hamburger-menu-icon-small.png';
import { formatSpeed } from '../Map/map-utils';


/* @Brief Component handle rendering the back of the notecard, this includes location , gforce, timeofday and map
*/
export const NCBack = ({props, timeOfDay ,gpsLoc, metadata, speed, infractionTags, gpsmap, onToggle}) =>{

    //define local state varaibles with initial values
    const [gpsLocation, setGPS] = React.useState('');
    const [speedDisplay, setSpeed] = React.useState('');
    const [gForceDisplay, setGForce] = React.useState(null);
    const [metadataDisplay, setMetadataDisplay] = React.useState(<div className="card-basics"><br /> </div>);
    const [mapDisplay, setMap] = React.useState(<img className="gpsmap" src={gpsmap || blankMap} alt=''  />);

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
    },[]) //[] -> execute once

    //Update GPS location when data is downloaded
    React.useEffect(()=>{
        let locationString = gpsLoc.lat +", "+ gpsLoc.lon;
        if(gpsLoc.lat==null){
            locationString="";
        }else{
            locationString = parseFloat(gpsLoc.lat,10).toFixed(4) +", "+ parseFloat(gpsLoc.lon,10).toFixed(4)
        }
        setGPS(locationString);
        
        //Define what is shown in the map area, either map or waiting message
        let backMap = <img className="gpsmap" src={gpsmap || blankMap} alt=''  />    
        //Make sure the lat and lon are not at null
        if(gpsLoc.lat && gpsLoc.lon){
            //Check to make sure the GPS entries are valid, so we don't display a bad map
            if(Math.abs(0-gpsLoc.lat)<0.25 && Math.abs(0-gpsLoc.lon)<0.25){
                backMap = (<div className="gpsmap-empty ">
                    <div className="gpsmap-text3">Invalid GPS coordinates found</div>
                    <div className="gpsmap-text3">Map Unavailable</div>
                </div>); 
            }else{
                if(!gpsmap){ //map hasn't been generated yet:   
                    backMap = (<div className="gpsmap ">
                        <div className="gpsmap-spacer"></div>
                        <div className="gpsmap-text">Generating Map...</div>
                        <div className="gpsmap-text2">It may take up to 7 seconds to generate map.</div>
                        <div className="gpsmap-text2">Please hit refresh button after that if you do not view it.</div>
                        {/* <PosedButton  className="gpsrefresh"  onClick={this.getMapCall } > Refresh </PosedButton> */}
                    </div>); 
                }
            }
        }else{ //no lat/lon:
            //Set the back the map display to read unavailable
            backMap = (<div className="gpsmap-empty ">
                            <div className="gpsmap-text3">Map Unavailable</div>
                        </div>); 
        }
        //Update the map element in the render
        setMap(backMap);

    },[gpsLoc]) //listen for changes on the gpsLocation input

    //HeartRate animation is not currently in use, would need to be addded to this component
    // React.useEffect(()=>{
    //     // let backHeart = null; 
    //     // //If the heartRate is available, replace the map on the back of the notecard:
    //     // if(this.state.metadata && this.state.metadata.heartrate){
    //     //     backHeart =  ( <div className='heartbeat-card'>
    //     //                             <div className="type"> {'Current Heart rate: '+ (parseFloat(this.state.metadata.heartrate)).toFixed(1) +' bpm'}  </div>    
    //     //                             {/* <div className="type"> {'Baseline: 72.6 bpm'}  </div>     */}
    //     //                             <div className="type"> {'HRV: '}<span style={{color:'red'}}>{'+/- '+(parseFloat(this.state.metadata.hrv)).toFixed(1)+' bpm!'}</span>  </div>    
    //     //                             <div className = 'heartbeat-plot'>
    //     //                                 <HeartTest id='heart-plot'/>
    //     //                             </div>            
    //     //                         </div>);
    //     // } //end the heartrate formatting        
    // },[])

    //Configure the GForce display
    React.useEffect(()=>{
        try {
            //Do we have Geforce data:
            if(metadata && metadata.acc){
                // console.log("Acc: ",this.state.metadata.acc);
                let value = Math.abs(metadata.acc);
                // console.log("Tags:" ,this.state.infractionTags)
                let tags = infractionTags.join(',');
                if(tags.includes('Swerve')){
                    // console.log("Found Swerve")
                    value = Math.abs(metadata.swerve);
                }
                let displayVal = <div className="assetid">
                                    {"G-Force: "+value.toFixed(2)}                
                                </div>
                setGForce(displayVal);
            }    
        } catch (error) { }

       
        try {
           let metadataBlock= ( <div className="card-basics"><br /> </div> ) ; //need to create an empty place holder to prevent auto arrangement
           //Handle adding statistics on a per infraction basis
           if(metadata){
               if(metadata.Duration){            
                //Create the display data
                    metadataBlock= ( <div className="card-basics">            
                                            <br /> 
                                            <div className="type"> {metadata.Duration? "Duration: "+parseInt(metadata.Duration,10)+"ms":""} </div>                                            
                                        </div>  
                                    );
               }else if(metadata.Percent){ //Handle the PERCLOS stats
                    let timeStart = null;
                    let durationMS = (metadata.Stop - metadata.Start);
                    try {
                        //Subtract the length of the event from the stop time of the infraction                
                        timeStart = moment(metadata.EndTime,"YYYY-MM-DD_HH-mm-ss-SSS").subtract(durationMS,'milliseconds').format("HH:mm:ss")                
                    } catch (error) {
                    }
                    //Create the display data
                    metadataBlock= ( 
                        <div className="card-basics">            
                            <br /> 
                            <div className="type"> {metadata.Percent? "Percent: "+parseFloat(metadata.Percent*100,10).toFixed(2)+"%":""} </div>                
                            <div className="type"> {durationMS ? "Duration: "+(parseInt(durationMS,10)/1000).toFixed(2)+"s":null}   </div>                      
                            <div className="type"> {timeStart ? "Time: "+timeStart:null}   </div>                      
                        </div>  
                    );
                    
               }
               else if(metadata.cas)
                //Do we have CAS data:
                    try {
                        //Check the siteDetails to see if the Alias is available
                        let siteDetails = null;
                        if(props.siteDetails && props.siteDetails[0]){ siteDetails = props.siteDetails[0] }
                
                        metadataBlock =<div className="card-basics">
                                            <div className="type"> {metadata.cas.ttc ?      "Time To Collision: "+(parseFloat(metadata.cas.ttc)).toFixed(2)+"s":null}   </div>                      
                                            <div className="type"> {metadata.cas.speed?     "Oncoming Speed: "+formatSpeed( siteDetails,parseFloat(metadata.cas.speed),{show:true, precision:1}):""} </div>                
                                            <div className="type"> {metadata.cas.distm ?    "Oncoming distance : "+(parseFloat(metadata.cas.distm)).toFixed(1)+"m":null}   </div>                 
                                        </div>
                    } catch (error) { }
            
           }//end if metadata check
           setMetadataDisplay(metadataBlock);
        } catch (error) {
            
        }
    },[metadata]) //listen for Metadata to update the Gforce details

    React.useEffect(()=>{
        let siteDetails = null;
        if(props.siteDetails && props.siteDetails[0]){ siteDetails = props.siteDetails[0] }

        try{
            let speedDisplay = "";
            if(speed){
                let speedValue = parseFloat(speed);
                speedValue = Math.max(0,speedValue);
                speedDisplay = formatSpeed( siteDetails,speedValue,{show:true, precision:1})
            }
            setSpeed(speedDisplay);
        } catch (error) { }
    },[speed]) //listen for speed values


    return (
        <React.Fragment>
            <img className="hamburger-back" src={hamburger} alt='' onClick={onToggle}  /> 
                <div className="notecard-back card-upper">     
                    <div className="card-basics">            
                        {timeOfDay && <div className="type">{props.timeReceived && ('Time of Day: '+timeOfDay.format("HH:mm:ss"))}</div>}
                        <div className="type"> {'Location: '+gpsLocation}  </div>    
                        <div className="type"> {'Speed: '} {speedDisplay} </div>    
                        {gForceDisplay}
                        {/* <div className="type"> {'Event: '+ displayInfraction(this.props.groupconfig.displayNameSet,this.props.infractionType)}  </div>     */}
                    </div>    
                    {/* Second column to display metadata */}
                    {metadataDisplay}
                </div>
            
            <div className='back-bottom'>

                {/* {backHeart}*/}
                {mapDisplay} 
            </div>
        </React.Fragment>
        
    )
}//end of the NCBack component

