
// === LiveTab - return the number of clips loaded for the asset
export const cleanDBCache = (_db, _options) => {  
    //Check on the age of the photos:
    let startTime = new Date();
    let isLocalPromise = _db[_options.table].toArray();
    isLocalPromise.then(_data=>{
      // console.log("Returned array? ",_data.length, new Date() - startTime);
      // console.log("Photo request returned: ",_data,_inParams.row.driverid)
      if(!_data){return;}

      //sort by date added:      
      _data.sort( (a,b) =>{   return a.date>b.date?-1:1  }); //sort in desc order
      
      //Make sure the array is not growing above 100 entries
      let maxArraySize = _options.maxSize||100000;
      let scanDate = _data.slice(0,maxArraySize);
      let autoDelete = _data.slice(maxArraySize);
      // console.log("Data: ",autoDelete);
      //Remove anything above the max array size
      autoDelete.forEach( entry_ =>{
        _db[_options.table].where(_options.key).equals(entry_[_options.key]).delete().then(_result=>{})
      });//end delete above index

      let maxTime = _options.maxTime || 48;
      //Remove anything that hasn't been viewed for more than 7 days
      scanDate.forEach( entry_ =>{
        try {
          let timeElapse = new Date() - entry_.date;
          timeElapse = timeElapse /(1000*60*60); //convert to hours
          // console.log("Time elapsed: ",entry_.driverid,timeElapse);
          if(timeElapse>=maxTime){            
            _db[_options.table].where(_options.key).equals(entry_[_options.key]).delete().then(_result=>{
              // console.log("Deleted entry: ",entry_.driverid)
            })
          }
        } catch (error) {
        }
      });//end loop
    }); //end then processing
    isLocalPromise.catch(error => {
      console.log("Failed to fetch with error: ",error);
    })
}