
import React, {  PureComponent } from 'react';

import {getNodePosition} from '../Util.js'

export const ENABLE_PLAYBACK_PROFILE = false; //add the export so that the Child classes can also use this flag

export class VideoClipPlayerBase extends PureComponent {
  constructor(props) {
      super(props);

      this.windowResized = this.windowResized.bind(this);
      this.updatePosition = this.updatePosition.bind(this);

      this.getNextPosition = this.getNextPosition.bind(this);
      this.getPreviousPosition = this.getPreviousPosition.bind(this);
      this.getInfractionIDIndex = this.getInfractionIDIndex.bind(this);

      
      this.vidPlayerClassRef = React.createRef();
      

      this.state = {
          refreshCount:0,            
          winSize : {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
          },
          mPosition:{prev:null, curr: null, next: null},//track the previous, current, and next locations in the stream. Will create .prev .curr .next attributes
          displayedInfraction:null,
          mLoadingWindowSize: 15,
          canvasSet:  null,

      };
      this._isMounted = false;
  }

    /*
  * @brief A handler for the event when the browser window is resized
  * 
  * Used to update the current window width, for scaling the page contents to fit
  */
  windowResized() {
    console.log("Window resized?")
    this.setState({
      winWidth: document.documentElement.clientWidth - 40,
      winSize : {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight
      },
      canvasSet: null,
    })
  }

  UNSAFE_componentWillMount(){this._isMounted = true;}


  /* Helper method to return the current tag from the metadata*/
  setCurrentPosition(_set, _current,_afterSet){
    // if(this._isMounted){
    //   this.props.markViewed(this.props.currentClip.chosenName,_currentTag);
    // }
    if(this._isMounted){
      
      this.setState(
        prevState => {
          let position = prevState.mPosition||{};

          let currentPos = _current;
          
          //Current clip is not found, behavior: skip forward, skip back, start at beginning



          //Set the current
          let currIdx = this.getInfractionIDIndex(_set,_current);
          if(currIdx<0){ //Desired current clip is missing            
            // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Current requested is missing from set");}

            //do we have a valid next clip?
            if(position.next && (this.getInfractionIDIndex(_set,position.next)>=0)){         
              currentPos = JSON.parse(JSON.stringify(position.next));
              // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Current from next: ",JSON.parse(JSON.stringify(currentPos)));}
            }
            else if(position.next && (this.getInfractionIDIndex(_set,position.prev)>=0)){//Or the next from previous
              currentPos = JSON.parse(JSON.stringify(position.prev));            
              // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Current from prev: ",JSON.parse(JSON.stringify(currentPos)));}
            }else{//Default to first element in set
              currentPos = _set[0];
              // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Current from [0]: ",JSON.parse(JSON.stringify(currentPos)));}
            }
            // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Set from: ",JSON.parse(JSON.stringify(currentPos)));}
            position = this.updatePosition('curr',position,currentPos);
          }else{
            position = this.updatePosition('curr',position,currentPos);
          }

          // console.log("Update the position:",JSON.parse(JSON.stringify(_current)));

          //Find and set previous index in the aray:
          let prevVal = this.getPreviousPosition(_set,currentPos);
          position = this.updatePosition('prev',position,prevVal);
          

          //Find and set the next index
          let nextVal = this.getNextPosition(_set,currentPos);
          // console.log("Next returned: ",_set,nextVal);
          position = this.updatePosition('next',position,nextVal);
          
      
          let displayedInfraction = {};
          if(position && position.curr){
              displayedInfraction = position.curr || {};
          }

          // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Set current position: " ,JSON.parse(JSON.stringify(_set)),JSON.parse(JSON.stringify(position)));}

          // return{displayedInfraction: displayedInfraction,mPosition:JSON.parse(JSON.stringify(position))}
          return{displayedInfraction: displayedInfraction,mPosition:Object.assign(position)};
        }, _afterSet);  
    }
  }//end class definition
  
  updatePosition(_which, _positionObj, _current){
    // if(this.props.currentClip.chosenName === 'Severe Drowsiness'){console.log("Setting position: ",_which,JSON.parse(JSON.stringify(_current)));}
    if(!_current || _current===undefined){return _positionObj;}
    if(!_positionObj[_which]){ _positionObj[_which] = []; }
    _positionObj[_which] = Object.assign(_current);
    return _positionObj;
  }
 

  /* Helper method to return the next tag from the metadata*/
  getNextPosition(_set, _current){
    // console.log("GetNext: ",_set, _current);

    if(!_set){return null;}
    if(!_current){return null;}
    //Get the location of the current value:
    let index = this.getInfractionIDIndex(_set,_current); 
    if(index===-1){ return null;} //Current tag not found in the set
    if(index+1 > (_set.length-1)){ return _set[index] }//at the end of the set
    return _set[index+1];

  }
  /* Helper method to return the previous tag from the metadata*/
  getPreviousPosition(_set, _current){
    if(!_set){return null;}
    if(!_current){return null;}
    //Get the location of the current value:

    let index = this.getInfractionIDIndex(_set,_current); 
    if(index===-1){ return null;} //Current tag not found in the set
    if(index-1 < 0){ return _set[index] }//at the end of the set
    return _set[index-1];    
  }

  /* Find the index of the array that matches the infractionid*/
  getInfractionIDIndex(_set,_current){
    if(!_set){ return -2;}
    if(!_current){return -3;}    
    let index = (_set||[]).findIndex( (elem_)=> {return _current.InfractionID === elem_.InfractionID});    
    if(index===-1){
      // console.log("Test ID: ",_current.InfractionID)
    }
    return index;
  }

} //end of VideoClipPlayerBase class  
 
