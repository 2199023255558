import { Auth, API } from 'aws-amplify';
import React, { PureComponent } from 'react';
import * as moment from 'moment';
import axios from 'axios';
import EventIcons from '../markers/iconset.js'; //needed to hide the marker when in speed hover mode

import { MapFetchingDialog,getColor,getSpeedFromCoordinates, getTimeFromCoordinates } from './map-utils.js';
import {processJourney,formatSpeed,distEuclid } from './map-utils.js';

//OpenLayers imports:
import {Point} from 'ol/geom';
import {Polyline} from 'ol/format';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import {containsCoordinate} from 'ol/extent';
import { Circle as CircleStyle, Icon, RegularShape } from 'ol/style';

import { journeyStyler, journeyStyles } from './journey-renderutils.js';

// import FlowLine from 'ol-ext/style/FlowLine';
 import FlowLine from './MultiFlowLine.js';
import { ol_coordinate_offsetCoords } from 'ol-ext/geom/GeomUtils';
import Hover from "ol-ext/interaction/Hover";






export class MapJourney extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.query = this.query.bind(this);
        this.cancelQuery = this.cancelQuery.bind(this);
        this.clearJourneys = this.clearJourneys.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.getInfractionAPI = this.getInfractionAPI.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        

        this.addMapLayers = this.addMapLayers.bind(this);
        this.fetchGPSData = this.fetchGPSData.bind(this);
        this.bindInfractionsToJourneys = this.bindInfractionsToJourneys.bind(this);

        this.updateRouteVisibility = this.updateRouteVisibility.bind(this);
        this.updateSiteSpeed = this.updateSiteSpeed.bind(this);        

        this.state = {
            siteConfig: null,
            queryPromise: null,
            cancelProcessing: null,
            groupedInfractions: null, //infraction data grouped by Journey
        };
    }
    /*
    * @brief Currently this state is not used
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    /*
    * @brief Called when first launched
    */
    componentDidMount() {
       this.addMapLayers();
    }

    /*
    *  @brief Add the new layer to render the journey and set up the styles that render the lines
    */
    addMapLayers(){
        
        //set the style for the Journeys layer
        this.props.overlayLayers["journey"].setStyle(journeyStyler);

        //Define a point to display on lines:
        // var markerPt = new Feature(new Point([13350040.695071135, -2566082.0988535658]));
        var markerPt = new Feature();
        
        markerPt.set('type','journeyMarker'); //need to tell the journey styler that this is not an arrow line
        var markerPtStyle = new Style({
            zIndex:1,
            image: new RegularShape({
                radius: 10,
                radius2: 5,
                points: 5,
                fill: new Fill({ color: 'blue' })
            })
        });
        this.props.overlaySource["All"].addFeature(markerPt);


        //Add the hover interactions:
        var journeyHover = new Hover({ 
            cursor: "pointer" ,
            hitTolerance: 5,            
            layers: [this.props.overlayLayers['journey']],
        });
        journeyHover.setActive(false); //initialize to false, won't trigger
        this.props.map.addInteraction(journeyHover); //add the interaction listener to the map
        this.props.interactions['journeyHover'] = journeyHover; //Story the reference to the interaction for later activation
        //Define what happens when the mouse first starts hovering over the line (open a popup and display the speed value)
        journeyHover.on("enter", e => {
            if(!this.props.popUp){return;}
            let speedReturn = getSpeedFromCoordinates(e.feature,e.coordinate )
            if(speedReturn.speed > 0){
                 try {
                    let content = `<div>Speed: ${formatSpeed(this.props.siteConfig[0],speedReturn.speed,{show:true, precision:1})}</div>`;
                    this.props.popUp.show(e.coordinate, content);
                    markerPt.setGeometry(new Point(speedReturn.coord));
                    markerPt.setStyle(markerPtStyle);
                 } catch (error) {
                    console.log("Erorr on point: ",error);
                 }
            }
        });
        //Define what happens if the mouse moves while still on the line (Update the popup with new speed value)
        journeyHover.on("hover", e => {
            if(!this.props.popUp){return;}
            let speedReturn = getSpeedFromCoordinates(e.feature,e.coordinate )
            if(speedReturn.speed > 0){
                try {
                    let content = `<div>Speed: ${formatSpeed(this.props.siteConfig[0],speedReturn.speed,{show:true, precision:1})}</div>`;
                    this.props.popUp.show(e.coordinate, content);
                    markerPt.setGeometry(new Point(speedReturn.coord));
                    markerPt.setStyle(markerPtStyle);
                 } catch (error) {
                     console.log("Erorr on point: ",error);
                 }
            }
        });
        //Define what happens when the mouse moves away from the line (Close the popup)
        journeyHover.on("leave", e => {
            if(!this.props.popUp){return;}
            if(!this.props.popUp.getVisible()){return;}
            try {
                this.props.popUp.hide();
            } catch (error) {}
        });
    }

    /*
    *  @brief trigger the fetch of the journey API request
    */
    query(){
        // console.log("Query requested",this.props )

        if(this.props.startDate){ //require that at least the start date is set to start the query:

            let daysSince = null;
            //Compute time diff between start and end date
            if(this.props.endDate){
                daysSince =  moment(this.props.endDate).diff(moment(this.props.startDate),'days');                
            }else{
                daysSince =  moment().diff(moment(this.props.startDate),'days');                
            }
            // console.log("Days since: ",daysSince);
            if(daysSince > 14){
                this.props.mapCallback({daterange: daysSince}); //Return no journeys    
                return null;
            }

            //Trigger the ask for the path data:
            this.setState({cancelProcessing:null});
            return this.getApiCall();
        }else{
            this.props.mapCallback([]); //Return no journeys
        }
    }
    /*
    *  @brief Remove all the journeys from the current map, with their associated infractions and alerts
    */
    clearJourneys(){
        if(this.props.overlaySource){
            // console.log("Clear called?")
            // this.memberVars.pathSource.clear();
            this.props.clearSet("journey");
            this.props.mapCallback({clear:true});
            this.props.clearSet("infractions");
            this.props.clearSet("alerts");
            this.setState({groupedInfractions:null});
        }else{
            console.log("No overlay source set");
        }
    }
    /*
    *  @brief Cancel the current journey retrieval operation
    */
    cancelQuery(){
        
        if(this.state.queryPromise){
            // console.log("Cancel query");
            API.cancel(this.state.queryPromise,'user canceled');
            if(this.state.queryDriverPromise){API.cancel(this.state.queryDriverPromise,'user canceled')}
            if(this.state.queryInfractionPromise){API.cancel(this.state.queryInfractionPromise,'user canceled');}
        }else{
            // console.log("Cancel processing");
            this.setState({cancelProcessing:true})
            if(this.state.queryInfractionPromise){API.cancel(this.state.queryInfractionPromise,'user canceled');}
            if(this.state.queryDriverPromise){API.cancel(this.state.queryDriverPromise,'user canceled')}
            
            //Set a timeout to clear the map layer:
            // setTimeout(()=>{  
            this.clearJourneys();
            this.props.canceledQuery();
            // },250);
            
            
            
        }
        
    }
    /*
    * @brief Request more details from the SQL server, get the path and events
    */
    getApiCall(){
        // console.log("Get API: ",this);
        this.clearJourneys();
        //  console.log("Query journey", this.props.activeFilters, Object.assign(this.props.filters,this.props.activeFilters))

        //  let combinedFilters = Object.assign(this.props.filters,this.props.activeFilters);
        let combinedFilters = Object.assign({},this.props.activeFilters);
         //Also pass the start/end dates with the filters:
         combinedFilters.startDate = this.props.startDate;
         combinedFilters.endDate = this.props.endDate;

        const eventPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaMapAPI";
                let path = "/getJourney";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        // clientid: this.props.groupconfig.group,
                        mode: 'fetch',
                        site: this.props.currentSite,
                        // filters: this.props.activeFilters,
                        filters: combinedFilters
                    }
                };
                let apiPromise = API.post(apiName, path, myInit);
                apiPromise.catch(error=>{
                    // console.log("API error: ",error, error.message);
                    if (API.isCancel(error)) {
                        console.log("Api promise is canceled");
                    }
                })
                // API.cancel(apiPromise,'user canceled');
                this.setState({queryPromise:apiPromise})
                return apiPromise;
            });
        
        // console.log("Promise?: ",eventPromise, API);
        //Handle response:
        eventPromise.then(data => {

            this.setState({queryPromise:null})
            //  console.log("dataReturn ",eventPromise,)
            this.updateData(data)
        })// rest of script
        eventPromise.catch(error => {
            // console.log("dataReturn error",error,error.message)
            
        })// rest of script
        
        // console.log("Promise 2?: ",eventPromise);
        // this.setState({queryPromise:eventPromise})
        return eventPromise;
    }
    /*
    * @brief Get the infractions for the selected journeys
    */
    getInfractionAPI(_data){
        //Get a list of the journeys
        let journeys = []
        for(const journey_ of _data){
            journeys.push(journey_.journeyid);
        }
        //  console.log("Query journey infractions ", journeys);
        // this.setMemberVar({lastUpdateTime: moment()})
        const eventPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaMapAPI";
                let path = "/getJourneyInfractions";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        // clientid: this.props.groupconfig.group,
                        mode: 'fetch',
                        filters: this.props.activeFilters,
                        journeylist: journeys.join(','),
                    }
                };
                let apiPromise = API.post(apiName, path, myInit);
                apiPromise.catch(error=>{
                    if (API.isCancel(error)) {
                        console.log("Api promise is canceled");
                    }
                });
                this.setState({queryInfractionPromise:apiPromise})
                return apiPromise;
            });

        //Handle response:
        eventPromise.then(data => {
            this.setState({queryInfractionPromise:null})
            this.updateData(data)
        })// rest of script
        eventPromise.catch(error => {
            // console.log("dataReturn error",error,error.message)
        })// rest of script

    }

    /*
    * @brief Helper method to group an array based on a common type
    */
    groupBy(arr, property) {
        return arr.reduce(function(memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }
    /*
    * @brief Handle the GPS fetch and return for each Journey
    */
    fetchGPSData(_journey){
        // console.log("Fetch journey:" ,_journey);

        axios({ //don't specify the response type -> returns the JSON object
            url: _journey.gpsdata,
            method: 'GET',
            // responseType: 'blob', // important
        })
        .then(res => {
            if(this.state.cancelProcessing){return;}

            try {
                this.props.mapCallback({journeyFetched: _journey});
                //Injest the GPS entries and add the points to the 
                //segments - return as journeySegments to display on map
                let journeysToAdd = processJourney(res.data,_journey);
                // console.log("Returned journeys:" ,journeysToAdd.length,journeysToAdd)

                //Add each Journey to the map:
                //Set details for the current journey 
                for(const journeyToAdd_ of journeysToAdd){
                    try {
                        journeyToAdd_.set('journeyid',_journey.journeyid);
                        journeyToAdd_.set('journeyname',_journey.srclocation);
                        journeyToAdd_.set('assetid',_journey.assetid);
                        journeyToAdd_.set('siteid',_journey.siteid);
                        journeyToAdd_.set('driverids',[]);                
                        // console.log("Set max speed?: ",this.props)
                        if(this.props.siteConfig && this.props.siteConfig[0] && this.props.siteConfig[0].speedlimit){
                            // console.log("Set max speed?: ",this.props.siteConfig[0].speedlimit)
                            journeyToAdd_.set('maxSpeed',this.props.siteConfig[0].speedlimit);
                        }else{journeyToAdd_.set('maxSpeed',100);}
                        
                        //Bind the known infractions to the Journeys (if the infractions are available)
                        this.bindInfractionsToJourneys(_journey.journeyid,journeyToAdd_);
                        //Add the journey as a feature to the map:
                        this.props.overlaySource["journey"].addFeature(journeyToAdd_);
                        // console.log("Add Journey to overlay: ",_journey.journeyid);

                        
                    } catch (error) {
                        console.log("No journey segments? ",journeysToAdd);
                    }
                }//end processing the journeys
            } catch (error) {
                console.log("Failed to process journeys:" ,error);
            }

            if(this.props.mapCallback){this.props.mapCallback({loaded:true});}
            

            //Journey has been added to the map - trigger detail download:
             //Trigger the DriverID request:
            const eventPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaMapAPI";
                let path = "/getJourney";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        // clientid: this.props.groupconfig.group,
                        mode: 'drivers',
                        journeylist: _journey.journeyid,
                            
                    }
                };
                let apiPromise = API.post(apiName, path, myInit);
                apiPromise.catch(error=>{
                    if (API.isCancel(error)) {
                        console.log("Api promise is canceled");
                    }
                });
                this.setState({queryDriverPromise:apiPromise});
                return apiPromise;
            });
    
            //Handle response:
            eventPromise.then(data =>{
                this.setState({queryDriverPromise:null});
                this.updateData(data)// rest of script
            })
            eventPromise.catch(error => {})// rest of script
        });
    }

    //Bind the Infractions to the Journey feature on the map:
    bindInfractionsToJourneys(_journeyid, _feature){
        //Check if the infractions are avialable to bind to the journey, if not then exit right away
        if(!this.state.groupedInfractions){
            // console.log("Infractions not available, ",_journeyid);
            return;}
        try{
            //Handle two cases: Infraction finish downloading first, or the Journeys finish downloading first

            //Case 1: Bind a single journey to the already loaded infractions
            if(_feature){
                // console.log("Bind infractions to single Journey:" ,_journeyid);
                //Only bind one journey's infractions:
                _feature.set('infractionSet',this.state.groupedInfractions[_journeyid])
            }//end case 1
            //Case 2: Bind all the journeys to the recently recieved infractions            
            else{
                //Get a link to all the loaded journeys on the Map:
                let journeys = null;
                try {
                    journeys = this.props.overlaySource["journey"].getFeatures();    
                } catch (error) { console.log("Failed to get features:" ,error); }
                // console.log("Fetched Journeys:" ,journeys);

                //Iterate through the journeys and link the infractions to the journey's feature
                (journeys || []).forEach( (elem_) =>{
                    try {
                        let journeyid = elem_.get('journeyid');
                        // console.log("Test on journey: ",journeyid,this.state.groupedInfractions[journeyid])
                        if(this.state.groupedInfractions[journeyid]){  elem_.set('infractionSet',this.state.groupedInfractions[journeyid]) }
                        else{  elem_.set('infractionSet',[]) }    
                    } catch (error) {}
                });
            }//end case 2
        }catch(e){
            console.log("Fail to bind: ",e,_journeyid);
        }
    }//end bindInfractionsToJourneys
    
    /*
    * @brief Handle the return of the API requests
    */
    updateData(_data){
        //  console.log("Journey update: ",_data);
        if(_data.error){console.log("Error found: ",_data.error); return;}
        // console.log("Data received: ",_data );
        
        //Get the infractions:
        if(_data.journeys && !this.state.cancelProcessing){
            // console.log("Journeys returned; ",[..._data.journeys]);
            this.props.mapCallback(_data.journeys);
            this.getInfractionAPI(_data.journeys);

            //Iterate over each of the journeys:
            for(const journey_ of _data.journeys){
                if(this.state.cancelProcessing){console.log("Cancel processing...");continue;}
                // console.log("Call fetch on: ",journey_);
                if(!journey_.gpsdata){ console.log("Missing gpsdata file for: ",journey_.journeyid); break;}
                this.fetchGPSData(journey_);
                // return;
            } //end journey loop
        }
        //handle infractions?
        if(_data.infractions && !this.state.cancelProcessing){

            //Sort the infractions by journey
            var byJourney = this.groupBy(_data.infractions, 'journey');
            // console.log("Journey group:", byJourney);

            //Store the grouped infractions in the groupedInfraction state varilabe, once stored
            //Call the bindInfractionsToJourneys() to make sure they are linked to all journeys
            this.setState({groupedInfractions:byJourney},()=>{
                this.bindInfractionsToJourneys();
            });
          
            //Iterate over each infraction:
            if(this.props.addMarker){
                for(const infraction_ of _data.infractions){
                    // console.log("Add infraction: ",infraction_)
                    this.props.addMarker(infraction_,'marker',this.props.overlaySource);                            
                }
            }
            this.props.mapCallback({infractions: 'loaded'}); //Return no journeys 
        }//End processing the infractions API return

        if(_data.drivers && !this.state.cancelProcessing){
            // console.log("Driver data return?: ",_data );

            //Get the journies from the map:
            let journeySet = null;
            try {
                journeySet = this.props.overlaySource["journey"].getFeatures();    
            } catch (error) {
                 console.log("Failed to get features:" ,error,this.props.overlaySource);
            }

            //Compare against the journeylist:
            for(const journey_ of _data.journeyids){
                (journeySet || []).forEach( (elem_) =>{
                    if(elem_.get('journeyid') === journey_){
                        //get the current list of driverid:
                        let driverids = elem_.get('driverids');
                        for(const driver_ of _data.drivers){
                            driverids.push(driver_.driverid);
                        }
                        // console.log("Set drivers:" ,[...new Set(driverids)],driverids)
                        elem_.set('driverids', [...new Set(driverids)])
                        // console.log("Added drivers: ",elem_.get('driverids'));    
                    }
                    
                })
            }//end journey list
        }//end drivers check
    }

    /*
    * @brief Listen for any updates to the received properties
    */
    UNSAFE_componentWillReceiveProps(newProps) {
        // console.log("New Props: ",newProps,this.props);

            if(this.props.selectedJourney!==newProps.selectedJourney){
                // console.log(" new props?: ",newProps);
                this.updateRouteVisibility(newProps.selectedJourney);
            }
            if(this.props.activeFilters.journeys!==newProps.activeFilters.journeys){
                // console.log(" new props?: ",newProps);
                this.updateRouteVisibility(newProps.activeFilters.journeys);
            }
        
    }//end will receive props

    /**
    * @brief Helper method to update the rendered Journeys, called to hide the other
    * not selected journeys   
    */
    updateRouteVisibility(_journey){

        let journeyName = _journey;        
        //Get the set of all current Journeys
        let journeySet = null;
        try {
            journeySet = this.props.overlaySource["journey"].getFeatures();    
        } catch (error) {
            console.log("Failed to get journeys:" ,error,journeyName);
        }
        (journeySet || []).forEach( (journey_) =>{
            let bVisible = false;
            if(journeyName === 'All'||!journeyName) {
                journey_.set('singleview',false)
                bVisible = true;
            }else{ //JourneyName is specified
                if(journey_.get('journeyname') === journeyName){
                    journey_.set('singleview',true); //set as the single view
                    bVisible = true;
                }
            }
            //Set the feature's style to adjust visibility
            if(bVisible){
                journey_.setStyle(); //reset to the default style
            }else{
                journey_.setStyle(new Style({})); //set to an empty style to hide
            }
        })//end for loop
    }//end updateRouteVisiblity
    /**
    * @brief Helper method to update the recorded speed limit for each Journey. When the speed limit is 
    *   changed on the site the existing Journeys must be updated to change the rendering of their speed gradients
    */
    updateSiteSpeed(_site){
        //Iterate the markers and set their styles:
        //  console.log("Update speed:",_site)
        //Get the vehicle by name:
        let journeySet = null;
        try {
            journeySet = this.props.overlaySource["journey"].getFeatures();    
        } catch (error) {
            console.log("Failed to get features:" ,error,_site);
        }
        //Iterate through all Journeys and set the maxSpeed attribute
        (journeySet || []).forEach( (elem_) =>{
            elem_.set("maxSpeed",_site.speedlimit);
        })
    }//end updateSiteSpeed
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        return null;
        {/* Declare a modal dialog box to open when fetching data */}
        return (

            <MapFetchingDialog toFetch = {10} fetched= {1} dialogState = {1}
                stringType = {'journeys'} progressBar= {true}
                onApply={()=>{ 
                    this.setState({fetchingDialogState:0})
            }}
        />

        )
        
        // return null;
    }
} //end mapJourney Class


/*
* @brief Class to handle the interaction with the infractions and path of the Journey
*/




