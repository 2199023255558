import React from 'react';

import './Notifications.css';

import { VideoCutNotifications} from '../VideoCut/VideoCutNotifications.js'
/**
 * Create a notifications class to handle receiving data for the user
 * Sources included login and Websocket messages
 * @param {*} param0 
 * @returns 
 */
export const Notifications = ({className,type,label,onValidate,onChange,...props}) =>{

    const [showMenu, toggleShowMenu] = React.useState(false);

    const [notificationList, updateNotificationList] = React.useState([]);

    const [totalPending, updateTotalPending] = React.useState(0);
    const [showPending, toggleShowPending] = React.useState(false);

    const ref = React.useRef(null);

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //Add destroy on exit 
        return () => {};
    },[]) //[] -> execute once

    //Userpermission received: link the classes to be sub-menu items
    React.useEffect(()=>{
        if(!props.groupconfig || !props.userInfo){return;}
        if(!props.groupconfig.bLoaded){return;} //wait until the groupconfig is loaded

        linkNotificationChild({ name: 'VideoCutNotifications', 
                                component: <VideoCutNotifications
                                                onChange={notificationsUpdated} 
                                                onClose={notificationClosed} 
                                                groupconfig={props.groupconfig} userInfo={props.userInfo}
                                                key ='videocutnotifykey'
                                                notificationsRef = {ref}
                                            />
                            });
        
    },[props.groupconfig,props.userInfo]) // wait until all the data is available 

    /**
     * Update the state of the rendered bell icon based on the number of 
     * Pending notifications
     */
    React.useEffect(()=>{
        // console.log("Total Pending notifications: ",totalPending);
        if(totalPending>0){toggleShowPending(true);}
        else{toggleShowPending(false);}
    },[totalPending]) //[] -> execute once

    //Add the entry to the list and make sure they are unique
    function linkNotificationChild(_data){
        let linkedList = [...notificationList];
        let foundIdx = linkedList.findIndex(entry_=>entry_.name===_data.name);
        if(foundIdx<0){            
            linkedList.push(_data);
        }else{
            // console.log("Blocked duplicate child entry");
        }
        updateNotificationList(linkedList);
    }

    /**
     * Callback when the bell icon is clicked
     * @param {*} e: event returned from the click
     */
    function onMenuClick(e){
        // console.log("On MenuClick:" ,e);
        toggleShowMenu(!showMenu);//Toggle the state value        
        e.preventDefault();
    }

    /**
     * Recieve updates from the linked child components, upate the total
     * number of unviewed notifications
     * @param {*} _data 
     */
    function notificationsUpdated(_data){
        // console.log("Update from:" ,_data);
        switch(_data.name){
            case "VideoCutNotifications":{
                updateTotalPending(totalPending+_data.unviewedcount);
            }break;
        }
        
    }
    /**
     * Recieve updates that the notification child has closed
     * @param {*} _data: object with the name of the sender and a data field
     */
    function notificationClosed(_data){
        // console.log("Closed from:" ,_data);
        if(_data && _data.data){ //Use the direction from the child
            // console.log("Call: ",_data.data);
            toggleShowMenu(false); //close the dropdown menu
        }else{ //if not specified, then assume false
            toggleShowMenu(false); //close the dropdown menu
        }
    }//end notifcationClose()

    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        // console.log("List to render:" ,showMenu,[...(notificationList||[]).map(entry_=>{return entry_.component})]);
        //Return the HTML code to display:
        let notificationClasses = "notifications header-notification";
        notificationClasses += (showMenu ? " shown-menu" : "")
        // notificationClasses += (showPending ? " showpending" : "")
        notificationClasses += (!showMenu && showPending ? " shake-icon" : "")
        const subClass = "show-notification profile-notification" + (showMenu ? " shown-menu" : "");
        return (
            <li key='notifications' className={notificationClasses} 
            >
                <a href="#!" onClick={onMenuClick} >                    
                    <i className={"ti-bell "+ (showPending?"showpending ":"")}  ref={ref}></i>
                </a>
                {/* Render the sub-menus */}
                <ul className={subClass}>
                    {
                        [...(notificationList||[]).map(entry_=>{return entry_.component})]
                    
                    }                 
                </ul>
            </li>
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}//end of the Notifications