
import {useCallback, useRef} from 'react';
import { useState, useEffect } from 'react';
/**
 * 
 * @param {*} callback: callback that is invoked at the specified duration or `onEndLongPress` (defaults to do nothing)
 * @param {*} ms: long press duration in milliseconds (default to 300ms)
 * @returns 
 */
export function useLongPress(callback = () => {console.log("no callback")}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}