/*
* @brief This module contains helper functions for measuring performance of widgets
*/

import { DO_PERFORMANCE_TIMING, SCROLL_AS_PERFORMANCE_MARK } from './Util-access.js';

/*
* @brief Setup the performance measurement for a widget, must be called before all others
*
* @param that The widget itself
* @param name A unique name that should be used for the output performance counters (marks/measures)
*/
export function setupPerf(that, name, hasData) {
  if (!DO_PERFORMANCE_TIMING) {
    return;
  }
  that.perfName = name;
  // that._origUNSAFE_componentWillMount = that.UNSAFE_componentWillMount;
  // that._origComponentWillUpdate = that.componentWillUpdate;
  that._origComponentDidMount = that.componentDidMount;
  that._origComponentDidUpdate = that.componentDidUpdate;

  that.componentDidMount = function() {
    that._origComponentDidMount && that._origComponentDidMount();
    perfDidMount(that, hasData);
  }.bind(that);

  that.componentDidUpdate = function() {
    that._origComponentDidUpdate && that._origComponentDidUpdate();
    perfDidUpdate(that, hasData);
  }.bind(that);
}

/*
* @brief Call when the widget will mount, in ReactJS lifecycle terms
*
* @param that The widget itself
// */
// export function perfWillMount(that) {
//   window.performance.mark(that.perfName + '-will-mount');
// }

/*
* @brief Call when the widget will update, in ReactJS lifecycle terms
*
* @param that The widget itself
*/
// export function perfWillUpdate(that) {
//   window.performance.mark(that.perfName + '-will-update');
// }

let clickFilter = null;

/*
* @brief Filter what 'click' events are currently interesting for click-to-rendered timing
*
* @param which The name of the 'click' events, the same as what is passed to perfDidClick
*/
export function perfFilterClicks(which) {
  clickFilter = which;
}

/*
* @brief Call when the widget mounted or updated, in ReactJS lifecycle terms
*
* @param that The widget itself
* @param hasData A query function that can tell if the widget has data or not, used to record different measurements
* @param which Either 'mount' or 'update', this is to unify logic between mount and update flows, since the code is the same
*/
function _perfDidMountOrUpdate(that, hasData, which) {
  const clickName = clickFilter ? clickFilter + '-link-clicked' : 'link-clicked';
  const name = that.perfName;
  window.performance.mark(name + '-did-' + which);
  // window.performance.measure(name + '-' + which + 'ing', name + '-will-' + which, name + '-did-' + which);
  window.performance.measure(name + '-' + which + 'ing', name + '-did-' + which);
  if (hasData()) {
    window.performance.mark(name + '-did-' + which + '-with-data');
    // window.performance.measure(name + '-' + which + 'ing-with-data', name + '-will-' + which, name + '-did-' + which + '-with-data');
    window.performance.measure(name + '-' + which + 'ing-with-data', name + '-did-' + which + '-with-data');
    if (window.performance.getEntriesByName(clickName, 'mark').length > 0) {
      window.performance.measure(name + '-link-to-rendered', clickName, name + '-did-' + which + '-with-data');
    }
  }
}

/*
* @brief Call when the widget mounted, in ReactJS lifecycle terms
*
* @param that The widget itself
* @param hasData A query function that can tell if the widget has data or not, used to record different measurements
*/
export function perfDidMount(that, hasData) {
  _perfDidMountOrUpdate(that, hasData, 'mount');
}

/*
* @brief Call when the widget updated, in ReactJS lifecycle terms
*
* @param that The widget itself
* @param hasData A query function that can tell if the widget has data or not, used to record different measurements
*/
export function perfDidUpdate(that, hasData) {
  _perfDidMountOrUpdate(that, hasData, 'update');
}

/*
* @brief Call when a button is pressed that we might want to measure timings from
*
* @param description The click type
*/
export function perfDidClick(description) {
  window.performance.mark('link-clicked');
  window.performance.mark(description + '-link-clicked');
}

if (DO_PERFORMANCE_TIMING && SCROLL_AS_PERFORMANCE_MARK) {
  window.alert("Warning: scrolling performance marks are enabled, publication of this version is not recommended.");
  window.addEventListener('scroll', () => {
    perfDidClick("scrolling");
  });
}
