import shaka from 'shaka-player/dist/shaka-player.ui';
// import 'shaka-player-react/dist/controls.css';
import React from 'react';
// import './VideoShaka.css'


/**
 * A React component for shaka-player.
 * @param {string} src
 * @param {shaka.extern.PlayerConfiguration} config
 * @param {boolean} autoPlay
 * @param {number} width
 * @param {number} height
 * @param ref
 * @returns {*}
 * @constructor
 */
function ShakaPlayer({ src, config, chromeless, className, videoClassName, onReady, ...rest }, ref) {
  const uiContainerRef = React.useRef(null); //Unused reference for the Shaka UI
  const videoRef = React.useRef(null); //Create a reference to the Shaka Player
  

  const [player, setPlayer] = React.useState(null); //make a varaiable to hold a reference to the video player
  const [ui, setUi] = React.useState(null); //Initialize the UI for Shaka - we disable this to save memory
  const [loadTime, updateTimer] = React.useState(new Date()); //Set a timer when the component first mounts
  const [loaded, setClipReady] = React.useState(null);

  // Effect to handle component mount & unmount.
  // Not related to the src prop, this hook creates a shaka.Player instance.
  // This should always be the first effect to run.
  React.useEffect(() => {
    const player = new shaka.Player(videoRef.current);
    setPlayer(player);

    let ui;
    //UI changes from the other videos and leaks memory on release
    //Disable 
    // if (!chromeless) {
    //   const ui = new shaka.ui.Overlay(
    //     player,
    //     uiContainerRef.current,
    //     videoRef.current
    //   );
    //   setUi(ui);
    // }

    return () => {
      // console.log("Destroying player: ",player,videoRef,videoRef.current);
      try {
        videoRef.current.src = null;
        videoRef.current.load();
        // console.log("Released ShakaPlayer")
      } catch (error) {
        // console.log("failed to release on Shaka ",videoRef);
      }

      player.destroy();
      if (ui) {
        ui.destroy();
      }
    };
  }, []);

  // Keep shaka.Player.configure in sync.
  React.useEffect(() => {
    if (player && config) {
      // console.log("Set config: ",config);
      player.configure(config);
    }
  }, [player, config]);

  // Load the source url when we have one.
  React.useEffect(() => {
    if (player && src) {
      // console.log("Load src: ",src,player.getConfiguration());
      // player.load(src);
      player.load(src, null, 'application/dash+xml')
    }
  }, [player, src]);

  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(
    ref,
    () => ({
      get player() {return player; },
      get ui() { return ui;  },
      get videoElement() { return videoRef.current;}
    }),[player, ui]);

  //Monitor that the video player has loaded, and that the class name
  //When the classname changes it is switching from hidden to the fixed-player
  React.useEffect( ()=>{
    if(!loaded){return;}
    updateTimer(new Date());
    try {
      // console.log("The ref was set: ",className)
      
      let cWidth = videoRef.current.offsetWidth;
      //check if the element is loaded yet
      if(cWidth <=0){return;} //dont continue with computation if not loaded 
  
      //Get the remaining dimensions of the parent and player elements
      let pWidth = videoRef.current.parentNode.offsetWidth;
      let cHeight = videoRef.current.offsetHeight;
      let pHeight = videoRef.current.parentNode.offsetHeight;
      //Compute a scale, we want to rescale the video to fit into the parent container
      //This is would be handled automatically by the CSS property object-fit: fill
      //We would use that if Safari could follow standards
      let scaleX = pWidth/cWidth;
      let scaleY = pHeight/cHeight;

      // console.log("Scalex: ",scaleX, cWidth, pWidth);
  
      //Set custom CSS attributes so that our style can alter the element:
      videoRef.current.style.setProperty('--vidScaleX',scaleX); //scale horizontally
      videoRef.current.style.setProperty('--vidScaleY',scaleY); //scale vertically
      
      // console.log("Shaka from Ref: ",videoRef,pWidth,cWidth,scaleX,scaleY);
    } catch (error) {
      console.log("failed to derive video size from parent");      
    }

  },[videoRef,className,loaded]) //we need to have the videoRef that we want to rescale, and know when the class is not hidden


  return (
    <div ref={uiContainerRef} className='VideoShaka'>
      {/* Pass the controls to the video player to use the default controls from HTML <video>, should be disabled if Shaka UI is used? */}
      <video
        className='shakavideo'
        controls 
        ref={videoRef}
        onCanPlay = {()=>{
          // console.log("On ready fired")
          setClipReady(true);
          onReady()
        }}
        // onPlaying={()=>{console.log("Started Playing: ",new Date() - loadTime);}}
        {...rest}
      />
    </div>
  );
}

export default React.forwardRef(ShakaPlayer);
