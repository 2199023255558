
import React, { PureComponent } from 'react';

import './VideoCard.css';


import { displayInfraction, displayWorkflow, SEVERITIES, severityCompare, delayPromise, 
        getAlertIcon,OTHERHWTAGS } from './Util.js';

// import { setupPerf } from './Perf.js';

import { List } from 'react-virtualized';


import blankAvatar from './assets/avatar-blank.png';

import hamburger from './assets/hamburger-menu-icon-small.png';

import { DragSource, DropTarget } from 'react-dnd';




// A debug helper function to populate CardLists with many Cards
// Causes each Card in the Cardlist to appear many times
const DEBUG_MULTIPLY_CARD_COUNTS = false;

// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 10; // in seconds

// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

// Card and CardList constants
const CARD_HEIGHT = 87; // size of a Card
const CARD_WIDTH = 210; // in pixels

const CARD_LIST_FULL_COUNT = 5; // the maximum number of Cards to show in a CardList before scrolling
const CARD_LIST_SCROLL_WIDTH = 17; // the extra space to give the scrollbar in the CardList

// See https://momentjs.com/docs/#/displaying/ for formatting syntax
const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

const g_cardDragListeners = {};

/*
* @brief Register a callback to be called when a Card is dragged and dropped
* @param key An arbitrary key, should be the same key that is passed to the unregisterCardDragListener function
* @param f The function to call
*/
export const registerCardDragListener = (key, f) => {
    g_cardDragListeners[key] = f;
}

/*
* @brief Unregister a callback that was registered with registerCardDragListener
* @param key The key of the listener to unregister
*/
export const unregisterCardDragListener = (key) => {
    delete g_cardDragListeners[key];
}

/*
* @brief Notify all registered CardDragListeners (if any) of a Card drag event
*/
const notifyCardDragListeners = (event) => {
    // Only call the listeners if the drag is successful
    // (eg if it was dragged to a CardList, not any other arbitrary part of the page or outside)
    if (event.result) {
        Object.values(g_cardDragListeners).forEach(f => {
            f(event);
        });
    }
}

/*
* @brief Defines the parameters of using a Card as a drag source
* This is largely boilerplate for the react-dnd library
*/
const cardDragSource = {
    beginDrag(props) {
        return props;
    },
    endDrag(props, monitor) {
        notifyCardDragListeners({item: monitor.getItem(), result: monitor.getDropResult()});
    },
    /*
    * @brief A callback that is used to tell if the Card can be dragged
    * 
    * We use it to disallow dragging the 'placeholder' card that is shown
    * when there are no Cards in a CardList
    */
    canDrag(props) {
        return !props.placeholder && !props.disableDrag;
    },
};

/*
* @brief Boilerplate for the react-dnd library defining a CardList drag target
*/
const cardListDragTarget = {  drop(props) {    return { workflow: props.workflow };  }, }

/*
* @brief format the type name displayed on the notecard, prioritize naming using the tagged infractions when present
* @param __props: pass the properties object to extract the values passed to the class on creation
*/
function getTypeAndTagText(_props) {
    // console.log("typeandtag: ",_props, _props.infractionTags,OTHERHWTAGS);
    let typeAndTag ="";
    if(_props.infractionTags && _props.groupconfig && _props.infractionTags.length>0){
        try {
            //Tag the infractions with their severities:
            let sortedInfraction = _props.infractionTags.map(elem=>{
                return _props.groupconfig.infractionTags.filter(s => s.type === elem)[0];
            });
            //Also look at the tagged OTHER HW Tags:
            const otherTags = _props.infractionTags.map(elem=>{
                return OTHERHWTAGS.filter(s => s.type === elem)[0];
            });
            
            sortedInfraction= sortedInfraction.concat(otherTags);
            // console.log("Other tags: ",otherTags,sortedInfraction);

            //Sort the tagged infractions based on severity
            sortedInfraction.sort((a, b) => {
                const sortBy = (x) => (SEVERITIES.indexOf(x));
                if (sortBy(a.severity) < sortBy(b.severity)) {
                    return -1;
                }
                if (sortBy(a.severity) > sortBy(b.severity)) {
                    return 1;
                }
                return 0;
            });

            //choose the name to include the infraction tag with the highest severity
            try{
                // console.log("Sorted: ",sortedInfraction[0],sortedInfraction)
                typeAndTag = sortedInfraction[0].type;
            }catch(e){
                // console.log("Unable to read: ",sortedInfraction);
                typeAndTag ="";
            }
        } catch (error) {
            console.log("Fail to sort: ",error);
        }
        
        
        
    }else{ // No infraction tags found: use the detected base type for the name
        typeAndTag = displayInfraction(_props.displayNameSet,_props.infractionType);
        if(_props.groupconfig){
            typeAndTag = displayInfraction(_props.groupconfig.displayNameSet,_props.infractionType);
        }
        typeAndTag = null;
    }
    if (typeAndTag && _props.tag) {
        typeAndTag += " - " + _props.tag;
    }

    return typeAndTag;
}

/*
* @brief A notecard for an infraction
*
* Currently used on the Video Highlights and HR Review pages
*/
export class CardInternal extends PureComponent {
    constructor(props) {
        super(props);
        // setupPerf(this, 'Card', () => true);
    }
    
    render() {
        
        const { connectDragSource } = this.props;        
        let typeAndTag = getTypeAndTagText(this.props)
        let nameProp = String(this.props.name).replace("_"," ");
        
        //  console.log("Time: ",this.props.timeOfDay,this.props.timeReceived)
        // console.log("Card internal: ",this.props);
        // <div className="date">{}</div>
        // console.log("Date:", this.props,this.props.timeReceived,this.props.timeOfDay)
        let displayDate = this.props.timeReceived && this.props.timeReceived.format("MMM D");
        try {
            displayDate = this.props.timeOfDay && this.props.timeOfDay.format("MMM D");
        } catch (error) {}

        // return (
        //     <div>test</div>
        // )
        //Change the card type if we are in a restricted permissions flow        
        let cardCSSDefault = " review-card-placeholder";
         if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.reporting){
                let tmpFlows = Object.keys(this.props.groupconfig.permissions.reporting);
                if(!tmpFlows.includes(this.props.flow)){
                    cardCSSDefault = " review-flow-restricted"
                }
         }
        
        let returnDiv = <div className={"review-card" + (this.props.placeholder ? cardCSSDefault : "")}
                            onDoubleClick={this.props.placeholder ? null : ()=>this.props.onDoubleClick(this.props)}
                            style = {this.props.styleOverride}
                    >
                        <div className="ncard-title">
                            <div className={"card-severity card-severity-" + this.props.severity}></div>
                            <div className="audioalert">
                                {/* {this.props.metadata? <img src={getAlertIcon(this.props.metadata,'audio')} alt=""></img>:null} */}
                                {getAlertIcon(this.props.metadata,'audio')? <img src={getAlertIcon(this.props.metadata,'audio')} alt=""></img>:null}
                            </div>
                            <div className="vibrationalert">
                                {getAlertIcon(this.props.metadata,'vibe')? <img src={getAlertIcon(this.props.metadata,'vibe')} alt=""></img>:null}
                            </div>
                            <div className='date-ham-pair'>
                                <div className="date">{displayDate}</div>
                                <img className="hamburger" src={hamburger}  alt=''/>
                            </div>
                            
                        </div>
                        <div className="card-upper">
                            <div className="card-basics">
                                <div className="name">{nameProp.includes('DriverID:')?nameProp: "DriverID: "+nameProp}</div>
                                {typeAndTag?<div className="type">{typeAndTag}</div>:null}
                                <div className="workflow">{displayWorkflow(this.props.status)}</div>                                
                                {this.props.groupconfig && this.props.groupconfig.driverid? null : <div className="notes">{this.props.notes}</div> }
                            </div>
                            <div className="card-date-avatar">
                                <img className="avatar" src={this.props.photo || blankAvatar} width={35} height={35}  alt=''/> 
                            </div>

                            
                        </div>
                    </div>

        if(connectDragSource){
            return connectDragSource(returnDiv);
        }else{
            return (returnDiv);
        }
    }
}

export const Card = DragSource('card',
                               cardDragSource,
                               (connect, monitor) => ({
                                    connectDragSource: connect.dragSource(),
                                    isDragging: monitor.isDragging(),
                               })
                              )(CardInternal);

/*
* @brief Compare two cards for sorting order
*
* This is an implementation detail of the CardList.
*/
const cardCompare = (a, b) => {
    // sort by severity first
    const severity = severityCompare(a, b);
    if (severity !== 0) {
        return severity;
    }

    if (a.timeReceived && b.timeReceived) {
        // then by date
        if (a.timeReceived.format() < b.timeReceived.format()) {
            return -1;
        }
        if (a.timeReceived.format() > b.timeReceived.format()) {
            return 1;
        }
    }

    // then by infractionID (as a tiebreaker, only so our sorting will always
    // be 'stable', since infractionIDs are unique)
    if (a.infractionID < b.infractionID) {
        return -1;
    }
    if (a.infractionID > b.infractionID) {
        return 1;
    }
    return 0;
};

const cardCompareReverse = (a, b) => {
    // sort by severity first
    const severity = severityCompare(a, b);
    if (severity !== 0) {
        return severity;
    }

    if (a.timeReceived && b.timeReceived) {
        // then by date
        if (a.timeReceived.format() > b.timeReceived.format()) {
            return -1;
        }
        if (a.timeReceived.format() < b.timeReceived.format()) {
            return 1;
        }
    }

    // then by infractionID (as a tiebreaker, only so our sorting will always
    // be 'stable', since infractionIDs are unique)
    if (a.infractionID < b.infractionID) {
        return -1;
    }
    if (a.infractionID > b.infractionID) {
        return 1;
    }
    return 0;
};
const cardCompareTODReverse = (a, b) => {
    
    // sort by severity first
    const severity = severityCompare(a, b);
    if (severity !== 0) {  return severity; }

    // console.log("CompareTODReverse: ",a.timeOfDay,b.timeOfDay)

    if (a.timeOfDay && b.timeOfDay) {
        // then by date
        if (a.timeOfDay.isBefore( b.timeOfDay)){ return 1; }
        return -11;
    }

    // then by infractionID (as a tiebreaker, only so our sorting will always
    // be 'stable', since infractionIDs are unique)
    if (a.infractionID < b.infractionID) { return 1; }
    if (a.infractionID > b.infractionID) { return -1;  }
    return 0;
};

/*
* @brief Compare two cards for sorting order by their creation time
*
* This is an implementation detail of the CardList.
*/
const cardCompareCreated = (a, b) => {
    try {
        if (a.timeCreated.format() < b.timeCreated.format()) { return 1;  }
        if (a.timeCreated.format() > b.timeCreated.format()) { return -1; }    
    } catch (error) {
        console.log("Failed on card created: ",a,b,error);
        return 0;
    }
        
    return 0;
}
const cardCompareTOD = (a, b) => {

    const severity = severityCompare(a, b);
    if (severity !== 0) { return severity; }

    // console.log("CompareTOD: ",a.timeOfDay,b.timeOfDay)

    if(a.timeOfDay && b.timeOfDay){
        try {
            if (a.timeOfDay.isBefore( b.timeOfDay)){ return -1; }
            return 1;
        } catch (error) {
            console.log("Failed on card TOD: ",a,b,error);
            return 0;
        }
    }else{
        try {
            if (a.timeCreated.format() < b.timeCreated.format()) { return -1;}
            if (a.timeCreated.format() > b.timeCreated.format()) {return 1;}    
        } catch (error) {
            // console.log("Failed on card TOD: ",a,b,error);
            return 0;
        }
    }
    
        
    return 0;
}

export const CardListFunc = ({ cards, groupconfig, onCardClick, sortByCreated, sortByReverse }) => {
    //  console.log("Received:" ,cards,groupconfig)
    const type = "Notecards";
    if(!cards){return null;}
    // const cardValues = Object.values(cards);

    // cards.sort(sortByCreated ? cardCompareTOD : cardCompare);
    cards.sort(sortByCreated ? cardCompareCreated
        : sortByReverse? cardCompareTODReverse: cardCompareTOD);

    
    let isPlaceholder = false;

    if (cards.length < 1) {
        cards = [{placeholder: true}];
        isPlaceholder = true;
    }

    
    const cardCount = cards.length * ((!isPlaceholder && DEBUG_MULTIPLY_CARD_COUNTS) ? 1000 : 1);

    //Reset the rowHeight based on card height and a padding
    let vhMeasure =  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    vhMeasure /= 100;
    const rowHeight = 8*vhMeasure +.5*vhMeasure;
    // const rowHeight = CARD_HEIGHT;
    const height = Math.max(Math.min(cardCount, CARD_LIST_FULL_COUNT), 1) * rowHeight;

    const renderCard = ({ key, index, isScrolling, isVisible, style }) => {
        const card = cards[index % cards.length];
        if(card.clientid && card.clientid.toLowerCase().includes("bis")){
            return (
                <div style={style} key={key + card.infractionID} className="card-list-card-wrap">
                    {/* <Card {...card} styleOverride = {{backgroundColor:'rgba(250, 104, 30,0.25)'}} onDoubleClick={() => {onCardClick(card);}}  groupconfig={groupconfig} /> */}
                    <CardInternal {...card}  onDoubleClick={() => {onCardClick(card);}} groupconfig={groupconfig} /> 
                </div>
            );
        }else{
            return (
                <div style={style} key={key + card.infractionID} className="card-list-card-wrap">
                    <CardInternal {...card}  onDoubleClick={() => {onCardClick(card);}} groupconfig={groupconfig} /> 
                    {/* <Card {...card} onDoubleClick={() => {onCardClick(card);}}  groupconfig={groupconfig} /> */}
                </div>
            );
        }
      
    };
    
    
    return  <div className={"review-card-list review-card-list-"+type}  >
                <div className="review-card-list-title">{displayWorkflow(type)}</div>
                <List 
                    rowCount={cardCount}
                    rowRenderer={renderCard}
                    height={height}
                    width={CARD_WIDTH + CARD_LIST_SCROLL_WIDTH}
                    rowHeight={rowHeight}
                />
            </div>
   };


class CardListInternal extends PureComponent {
    /* @brief A structured list of Cards
    *
    * Handles displaying the individual Cards in a windowed/virtualized list
    * 
    * Currently used on the HR Review page.
    */
    constructor(props) {
        super(props);
        // setupPerf(this, 'CardList', () => !!this.props.cards);
    }
    render() {
        // console.log("Props:" ,this.props);
        const { connectDropTarget, canDrop, isOver } = this.props;
        const isActive = canDrop && isOver
        const type = this.props.workflow || "Notecards";
        let cards = this.props.cards || [];

        let isPlaceholder = false;

        if (cards.length < 1) {
            cards = [{placeholder: true}];
            isPlaceholder = true;
        }

        cards.sort(this.props.sortByCreated ? cardCompareCreated : this.props.sortByReverse? cardCompareReverse: cardCompare);
        // cards.sort(this.props.sortByCreated ? cardCompareCreated
        //                                     : this.props.sortByReverse? cardCompareTODReverse: cardCompareTOD);

        const cardCount = cards.length * ((!isPlaceholder && DEBUG_MULTIPLY_CARD_COUNTS) ? 1000 : 1);

        //Reset the rowHeight based on card height and a padding
        let vhMeasure =  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        vhMeasure /= 100;
        const rowHeight = 8*vhMeasure +.5*vhMeasure;
        // const rowHeight = CARD_HEIGHT;
        const height = Math.max(Math.min(cardCount, CARD_LIST_FULL_COUNT), 1) * rowHeight;

        const renderCard = ({ key, index, isScrolling, isVisible, style }) => {
            const card = cards[index % cards.length];
            if(this.props.fromHrReview && card.clientid && card.clientid.toLowerCase().includes("bis")){
                return (
                    <div style={style} key={key + card.infractionID} className="card-list-card-wrap">
                        <Card {...card} styleOverride = {{backgroundColor:'rgba(250, 104, 30,0.25)'}} onDoubleClick={() => {this.props.onCardClick(card);}} disableDrag={this.props.disableDrag} groupconfig={this.props.groupconfig} flow={type} />
                    </div>
                );
            }else{
                return (
                    <div style={style} key={key + card.infractionID} className="card-list-card-wrap">
                        <Card {...card} onDoubleClick={() => {this.props.onCardClick(card);}} disableDrag={this.props.disableDrag} groupconfig={this.props.groupconfig} flow={type}/>
                    </div>
                );
            }
            
        };

        let dragDropCss = " ";
        if (canDrop) {
            dragDropCss += "can-drop ";
        }
        if (isActive) {
            dragDropCss += "is-over ";
        }

        let scrollCss = " no-scroll ";
        if (cardCount > CARD_LIST_FULL_COUNT) {
            scrollCss = " has-scroll ";
        }

        return connectDropTarget &&
               connectDropTarget (
            <div className={"review-card-list review-card-list-" + type + dragDropCss + scrollCss} style={this.props.style} >
                <div className="review-card-list-title">{displayWorkflow(type)}</div>
                    <List 
                        rowCount={cardCount}
                        rowRenderer={renderCard}
                        height={height}
                        width={CARD_WIDTH + CARD_LIST_SCROLL_WIDTH}
                        rowHeight={rowHeight}
                    />
            </div>
        );
    }
}



export const CardList = DropTarget('card', cardListDragTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(CardListInternal);
