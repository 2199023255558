
//OpenLayers
import { Layer, Tile, Vector } from "ol/layer";
import { fromLonLat, get,transformExtent,transform,toLonLat } from 'ol/proj';
import { isPointInPolygon } from 'geolib';
import {Circle, Polygon} from 'ol/geom';
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from './Map/map-utils.js'

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import { Feature } from "ol";


/**
 * Transform MERCATOR coordinate array into GPS coordinate array
 * Also applies wrap check that occurs when the map is spun one of more times
 * @param {*} _olCoord 
 * @returns Transnformed points 
 */
const transformOLGPSCoord=(_olCoord)=>{
    let gpsCoord = transform(_olCoord, COORDTYPE_MERCATOR, COORDTYPE_GPS);
    //Sanity check the coordinate:
    return sanitizeGPS(gpsCoord);
    
}

/**
 * Run a sanity check on the GPS coordinate, make sure the Longitude is with -180 to 180 degrees
 * If not, then apply a modulus to wrap the coordinates to the -180 to 180 range
 * @param {*} _coord 
 * @returns 
 */
const sanitizeGPS=(_coord)=>{
    //Sanity check the coordinate:
    if(_coord[0]<-180){_coord[0]= 180-(Math.abs(_coord[0])%180);} 
    if(_coord[0]>180){_coord[0]= _coord[0]%180 }
    return _coord;
}

/**
 * Draw a polygon give an array of GPS points
 * @param {*} _coordinates array of lat lon points
 * @returns feature to draw 
 */
export function drawPolygon(_coordinates,_feature){

    //Convert to the mercator projection to display on the map
    let coordArray = [];
    for(const coordinate_ of (_coordinates||[])){
        coordArray.push(fromLonLat(sanitizeGPS([coordinate_.lon,coordinate_.lat])));
    }
    //Wrap coordinates in an array for polygon initialization 
    let wrapArray = [];        
    wrapArray.push(coordArray);
    let tmpPolygon = new Polygon(wrapArray);                
    //Create a feature to draw
    if(_feature){
        return _feature.setGeometry(tmpPolygon);
    }
    return new Feature(tmpPolygon);
}//end drawPolygon
/**
 * Use a region description to create or update a feature to draw
 * @param {*} _regionDesc 
 * @param {*} _feature 
 * @returns 
 */
export function setFeatureGeom(_regionDesc,_feature){
    switch(_regionDesc.type){
        case 'circle':
        {
            return drawCircle(JSON.parse(_regionDesc.coordinates),_feature);
        }
        case 'polygon':
        {
            return drawPolygon(JSON.parse(_regionDesc.coordinates),_feature);
        }
    }
}//setFeatureGeom
/**
 * Draw a circle feature using center coordintes and a radius
 * @param {*} _coordinates 
 * @param {*} _feature 
 * @returns 
 */
export function drawCircle(_coordinates,_feature){

    // console.log("Process circle:" ,_coordinates)
    //Convert to the mercator projection to display on the map
    let circGeom = null;
    if(_coordinates.center){ //if the data set includes the radius in the coordinates object it is a region description
        circGeom = new Circle(fromLonLat(sanitizeGPS([_coordinates.center.lon,_coordinates.center.lat])),_coordinates.radius );
    }else{ //other wise it is a return from the SQL table
        circGeom = new Circle(fromLonLat(sanitizeGPS([_coordinates.lon,_coordinates.lat])),_coordinates.radius );
    }
    //Create a feature to draw
    if(_feature){
        return _feature.setGeometry(circGeom);
    }
    return new Feature(circGeom);
}

//Extract 
/**
 * Extract the coordinates from the OpenLayer feature and convert to GPS 
 * Return a description of the feature including its type (circle vs polygon)
 * @param {*} _feature: OpenLayers feature to test
 * @param {*} _type: type of features (circle or polygon)
 * @returns 
 */
export function getRegionDesc(_feature,_type){
    // console.log("get desc:" ,_type);
    let regionDesc = {};
    switch(_type){
        case 'Circle':
        case 'circle':
        {
            //get the center and radius of the circle:
            let gpsCoord = transformOLGPSCoord(_feature.getGeometry().getCenter());
            let radius = _feature.getGeometry().getRadius();
            //add them to a region desc:
            regionDesc = {
                type: 'circle',
                coordinates: JSON.stringify({
                    lat:gpsCoord[1],
                    lon:gpsCoord[0],
                    radius: radius,
                }),
                center: {
                    lat:gpsCoord[1],
                    lon:gpsCoord[0],
                }
            }
        }break;
        case 'Polygon':
        case 'polygon':
        {
            regionDesc = {
                type: 'polygon',
                coordinates:[],
            }
            
            let gpsCoord = _feature.getGeometry().getCoordinates();
            
            // console.log("Polygon: ",gpsCoord[0])
            let latSum = 0;
            let lonSum= 0;
            let ptCount =0;
            for(const val of gpsCoord[0]){
                //Convert the coordinate to GPS
                let pt = transformOLGPSCoord([val[0], val[1]]);
                //Add to the list of coordinates
                regionDesc.coordinates.push({
                    lat: pt[1],
                    lon: pt[0],
                })
                latSum += pt[1];
                lonSum += pt[0];                    
                ptCount++;
            }
            regionDesc.coordinates = JSON.stringify(regionDesc.coordinates);
            try {
                regionDesc.center ={
                    lat : latSum / ptCount,
                    lon : lonSum / ptCount,
                }    
            } catch (error) {}
        } break;
    }
    return regionDesc;
}//getRegionDesc

/**
 * Convert GPS comman separated string to coordinates 
 * @param {*} _string 
 * @returns GPS coordinates
 */
export function gpsStringToCoords(_string){
    try {
        let gpsParts = _string.split(',');
        let position = {lat:null, lon:null};
        position.lat = gpsParts[0];
        position.lon = gpsParts[1];
        return position    
    } catch (error) {
        return {lat:null, lon:null}
    }
}//gpsStringToCoords

/**
 * Convert a GPS coordinate into a comma separated string
 * @param {*} _gps 
 * @returns 
 */
export function gpsCoordsToString(_gps){
    try {
        let stringReturn = _gps.lat+","+_gps.lon;
        return stringReturn    
    } catch (error) {
        return "";
    }
}//end gpsStringToCoords

/**
 * Check if the imperial measurements should be used by testing the GPS coordinates against an
 * approximate polygon of the US
 * @param {*} _gps 
 * @returns 
 */
export function useImperial(_gps){
    let bUseImperial = false;
    try {
      bUseImperial = isPointInPolygon({ latitude: parseFloat(_gps.lat), longitude: parseFloat(_gps.lon) },
        [
          {latitude:32.45738931074884,longitude:-117.09617726183413},
          {latitude:31.73388404292764,longitude:-106.14432037844533},
          {latitude:26.018804317535626,longitude:-97.25221639514342},
          {latitude:25.11243474391759,longitude:-80.72395531883647},
          {latitude:44.91082763773349,longitude:-66.80806377434696},
          {latitude:47.415444322811084,longitude:-67.96353491342009},
          {latitude:41.80715198619686,longitude:-82.88418397014703},
          {latitude:45.40677225549578,longitude:-82.18085371158078},
          {latitude:48.158023983353246,longitude:-88.96296691918394},
          {latitude:48.989005971212094,longitude:-95.0919877438327},
          {latitude:48.95602877837055,longitude:-122.72281933036409},
          {latitude:48.458751183529785,longitude:-124.83281010606284},
          {latitude:40.36839743335642,longitude:-124.38066922555596},
          {latitude:35.456980650241036,longitude:-122.22044057424533},
          {latitude:32.45738931074884,longitude:-117.09617726183413}]           
        );
    } catch (error) {
      console.log("Failed to test in USA: ",error);
    }
    return bUseImperial
} //end useImperial

