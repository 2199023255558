
import { Auth, API } from 'aws-amplify';

/**
 * Define a function to link to the DebounceQueue, this will be called when the queue triggers an update to the 
 * API, the callback allows the API response to be sent to the Queue to remove objects that have been processed
 * @param {*} _data: Object data from the DebounceQueue
 * @param {*} _callback: Return callback to notify the DebounceQueue that the API completed
 */
export const clipReviewAPI = (_data,_callback)=>{
  //------------------------------------------------------
  //Get a current token to pass to the API call
  Auth.currentSession().then(
    (auth) => {
      //Configure the API call
      let serviceName= "TrifectaCoreAPI";//Set the servicename - this is the collection of lambdas
      let apiName= "/trackClipReview"; //Set the specific lambda function to invoke
      //Configure the data to send to the Lambda
      let myInit = {
        body: {
          token: auth.idToken.jwtToken, //Provide the authentication token to validate that the user is signed in
          mode: 'clip', //Specify a switch mode to indicate that this is to update the review trackers for the clips
          data: _data, //Send the JSON object containing all the data to process
        }
      };
      //Call the API
        const sendAPIUpdate = API.post(serviceName,apiName, myInit);
        sendAPIUpdate.then(_response=>{ //Respons recieved from the API
        if(_callback){_callback(_response);} //If the callback was defined, then forward this data to the calker
        });
        //Log error messages: to the console
        sendAPIUpdate.catch(_error=>{console.log("Error clipReviewAPI return: ",_error);});
  }).catch( (error)=>{console.log("Failed to authenticate session")}) //authorization failed:
}//end clipReviewAPI

/**
 * Define a function to link to the DebounceQueue, this will be called when the queue triggers an update to the 
 * API, this is called for the markView tracking that updates the dms_alertclips SQL table
 * @param {*} _data: set of infractionids to update
 * @param {*} _callback: call back to DebounceQueue to notify completion
 */
export const markViewedAPI = (_data,_callback)=>{
  try {
  let inflightIds = null;
  
    inflightIds = JSON.stringify(Array.from(_data))  ;  
    // console.log("Send ids:" ,inflightIds);
    //------------------------------------------------------
    //Get a current token to pass to the API call
    Auth.currentSession().then(
      (auth) => {
        //Configure the API call
        let serviceName= "AuthLambda";//Set the servicename - this is the collection of lambdas
        let apiName= "/apiRouter"; //Set the specific lambda function to invoke
        //Configure the data to send to the Lambda
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            apiName: "reviewedAlertClip",
            siteid: "Tempe",
            ids: inflightIds,
          }
        };
        //Call the API
          const sendAPIUpdate = API.post(serviceName,apiName, myInit);
          sendAPIUpdate.then(_response=>{ //Respons recieved from the API
          if(_callback){_callback(_response);} //If the callback was defined, then forward this data to the calker
          });
          //Log error messages: to the console
          sendAPIUpdate.catch(_error=>{console.log("Error markViewedAPI return: ",_error);});
    }).catch( (error)=>{console.log("Failed to authenticate session")}) //authorization failed:

  } catch (error) {
    console.log("Failed to get inflight: ",error);  
  }
}//end clipReviewAPI
