'use strict';

/**
 * Simple timer class to accumulate the total time over multiple start/stop iterations 
 *  Records in millisecond accuracy using the javascript Date() class
 */
export default class AccumulatedTime{
    /**
     * Initialize the member variables 
     */
    constructor(){
        this.time_total= 0; //The total tracked time
        this.time_start= 0; //The last recorded start time       
    }
    // start the timer, record the ms when the function is called
    start(){
        this.stop();//call stop first to accumulate in case of repetitive calls to start
        this.time_start= new Date();
    }
    // get the elapsed time since the start was called
    // Add the elapsed time to the total tracked time
    stop(){
        if(this.time_start===0){return;}
        this.time_total += new Date() - this.time_start;
        this.reset(); //clear the start timer
    }
    //Clear the recorded start value
    reset(){
        this.time_start = 0;
    }
    //Get the total elapsed time, this includes all previously logged time
    // and the current elapsed time since the last recorded start
    getTime(){
        if(this.time_start===0){return this.time_total;} //Don't try to eval on an empty time_start
        let currentTime = new Date() - this.time_start;
        // console.log("Get time:",this.time_total,this.TotalTime + currentTime);
        //Return the running total including historical
        return this.time_total+currentTime;
        
    }
    
}


