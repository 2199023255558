import React, { Component,PureComponent } from 'react';
import ReactModal from 'react-modal';
import {Spinner} from '../ApiCaller.js';
import blankAvatar from '../assets/avatar-blank-large.png';
import {PolarGraph } from '../BaseComponents.js';
import { Chart } from 'react-google-charts';


import { getColorByType } from '../Util.js';

    /*
* @brief Format what is displayed in the Notes section
*/
export const severityFormatter = (data, row, rowIndex, extraData) => {
  try {
    return ''
  } catch (error) {
    return '---';
  }
  
};
export const severityStyle = (cell, row, rowIndex, colIndex) => {
  // console.log("Cell style: ",cell, row, rowIndex, colIndex, 1- row.score/10)
  try {
    let percentage = row.percentage || 1;
    return {backgroundColor: percentageToColor(percentage,70,0),width:"3vw"}
  } catch (error) {
    return {backgroundColor: '#00afed05',width:"3vw"}
  }
};

export const percentageToColor = (percentage, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue;
  return `hsl(${hue}, 100%, 50%)`;
}

export const pictureFormatter = (data, row, rowIndex, extraData) => {
  try {
    // console.log("Loaded picture with data: ",data);
    return <img className="photo" src={data || blankAvatar} alt=''  /> ;
  } catch (error) {
    return '---';
  }
  
};

export const radialFormatter = (data, row, rowIndex, extraData) => {
  try {
    //  console.log("Radial process");
    if(data && data.infractionsbyhour ){
      return <PolarGraph   dataTitle={"Infractions"} legend={"Severe Drowsiness"}
      data={data.infractionsbyhour}  xLabels={data.thumblabels}
      onDataClick={(_data)=>{console.log("Clicked? ",_data)}} maxValue={data.maxValue}
      // filterCount={this.props.filterCount}
      
    />
    }else{
      return <Spinner />
    }
    
    
  } catch (error) {
    console.log("Error ",error);
     return '---';
  }
};

export const noteFormatter = (data, row, rowIndex, extraData) => {
  try {
    if(data && data.length> 0){
      // console.log("Notes:" ,data);

      let notes = <div className="riskNotes">
                    <table >
                      <tbody >
                        {data.map(note_ => {
                          return (
                            <tr >
                              <td className='notetd'>{ note_.text }</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

      return notes;
    }else{
      return <div className="riskNotes">---</div>;
    }
    
    
  } catch (error) {
    
  }
};

export const getTopNum = (_input, _count) => {
  try {
    let topCount = Object.entries(_input).filter(([key,val])=>{
      // console.log("Val: ",key,val)
      return val.idx<10
    })
    //Stuff into an object:
    let returnObj = {};
    topCount.forEach(([key,val])=>{
      // console.log("Key,Val",key,val)
      returnObj[key] = val;
    })
    return returnObj;

  } catch (error) {
    return {};
  }
};

export class PieChart extends PureComponent {
  constructor(props) {
      super(props);
  }
 
  render() {
      // console.log("Colors:" ,EDGE3_TERTIARY_COLORS.concat(EDGE3_SECONDARY_COLORS.concat(EDGE3_PRIMARY_COLORS)));
      const summary = this.props.summary;
      // console.log("Summary: ",summary);
      var chartProportions = [['Type', 'Count']];

      let chartTotal = summary.total || 0;
     
      if(summary.type){
          // console.log("keys: ",summary.type, Object.keys(summary.type));
          //Loop over the infractiosn and push them onto a list of name,count
          summary.type.forEach(elem_=> {
            //  console.log("elem: ",elem_, )
            // chartProportions.push([Object.keys(elem_)[0],Object.values(elem_)[0]])
            chartProportions.push([elem_.label,elem_.count]);
          });
          
          chartTotal = summary.total || 0;
          // console.log("Found total infractions; ",totalInfractions)
      }
      
      // const totalInfractions = summary.totalInfractions || 0;
      // console.log("Total", totalInfractions)
      if (chartTotal === 0) {
          // console.log("ADD NONE?")
          chartProportions.push(['None', 1]);
      }
      const titleTextStyle = {
          color: "#757575",
          fontSize: 16,
          bold: true,
      };

      let titleText= 'Overall Infraction Proportions';

      // console.log("Data: ",chartTotal,chartProportions)
      // console.log("Pie data: ",chartProportions[1])

      //Format the slices in the pie to show the fixed colors:
      let slices = {};
      let offsetIndices = [1,3,5,7,8,11,13,15,17];
      for (let [index, value] of chartProportions.entries()) {
          
          // console.log("Slice? ",index,summary.type[index])
          if(summary.type[index]){
            slices[index] = summary.type[index].slice;
          }else{
            if(index === 0){continue;}
            let indexToWrite = index -1;
              // console.log("Values",index,value);
            if(offsetIndices.includes(indexToWrite)){
                
                slices[indexToWrite] = {
                    color: getColorByType(chartProportions[index][0]),
                    offset: 0.2,
                }
            }else{
                slices[indexToWrite] = {color: getColorByType(chartProportions[index][0])}
            }
          }
      }
      // console.log("Slices: ",slices)

      let classTitle = "";
      if(this.props.titleText){
        classTitle = this.props.titleText.replaceAll(' ','-');
      }
      let graphId = this.props.graph_id +" "+ classTitle;
      


      return (
        // <div className="col-xl-4">
            <div className="card counter-card-1">
                <div className="card-block-big infraction-prop-wrapper">
                    <h5 className={this.props.graph_id+"-title"}>{this.props.titleText}</h5>
                    <div className = {graphId}>
                      <Chart 
                          chartType="PieChart"
                          data={chartProportions}
                          titleText = {titleText}
                          options={{  
                                      legend: {textStyle: {fontSize: 20}, alignment:'center'},
                                      slices: slices,
                                      titleTextStyle: titleTextStyle,
                                      pieStartAngle: 285,
                                    }
                                  }
                          // graph_id={"infraction-proportions"+" "+classTitle}
                          graph_id={graphId}
                          width={this.props.width}
                          height={this.props.height}
                      />
                    </div>
                    
                </div>
            </div>
      );
  }
}

export {blankAvatar}


export class LoadedSpinner extends PureComponent {
  constructor(props) {
      super(props);
  }
 
  render() {
    
      if(this.props.modal){
        let modalClassName = "modal-dialog";
        let contentClass = "modal-content"
        if(this.props.class){
          modalClassName = this.props.class +"-modal-dialog";
          contentClass = this.props.class +"-content"
        }

        return (
          <ReactModal isOpen={true} className={modalClassName}>
            {this.props.titletext?
              <div className={contentClass}>
                  <div className='title'>{this.props.titletext}</div>
                  <Spinner />     
              </div>
            :
              <Spinner />     
            }
            
          </ReactModal>                
        );
      }
      else{
        return (<Spinner /> );
      }
  }
}


  