import React, { PureComponent } from 'react';
import * as moment from 'moment';
import { motion } from "framer-motion"
import iconSettings from '../assets/settings.png';
import { Filters } from '../BaseComponents.js';
import { GroupsHelpPopup } from './map-utils.js';

import helpIcon from '../assets/help.svg'
import { StatefulButton } from '../VideoReview/vid-util.js';

const { JourneyFiltersView } = require('./map-filters-journey.js');

// export default MapDisplayView;
const filterKeys = {
    
    'infractions': {
        'title': 'Clip',
        // 'defaultValue': 'None'
        // 'defaultValue': 'Severe Drowsiness'
    },
    'vehicle': {
        'title': 'Asset',
    },
    'drivers': {
        'title': 'Driver',
    },
    'sites':{
        'title': 'Site',
    },
    'regions':{
        'title': 'Region',
    },
    'groups':{
        'title': 'Group',
    },
    'journeys':{
        'title': 'Journey',
    }
};
const UNSET = 'unset';

/**
 * Convert the mapFiltersView class into a functional component
 * The class view had problems accepting/updating when the asset filters changed
 * @param {*} param0 
 * @returns 
 */
const MapFiltersView = ({...props}) => {

    const [allowApplyButton, toggleApply] = React.useState(props.allowApply);
    const [filterData, setFilterData] = React.useState({});

    React.useEffect(()=>{
        // console.log("Filters:", props);
        filterUpdated();
     },[])
     React.useEffect(()=>{
         filterUpdated();
     },[props.activeFilters])

     React.useEffect(()=>{
        toggleApply(props.allowApply);
    },[props.allowApply])
    

    const filterUpdated=()=>{
        // console.log("Props: ",this.props);
        const filters = props.filters || {};

        const filterData = {};
        Object.entries(filters).forEach(([name, elementSet]) => {
            try {
                 // console.log("?: ",name,elementSet)
                 let elements = Array.from(elementSet.values());
     
                 
                 //Current site has been chosen:
                 if(props.currentSite !== 'None'){
                     //Apply a site filter to the driver dropdown:
                     if(props.currentSite && name === 'drivers'){
                         //Filter the list of elements, only return ones where the asset's site matches the selected site
                         elements = elements.filter(value_ => {
                             try {
                                 return value_.site.toLowerCase() === props.currentSite.toLowerCase()
                             } catch (error) {
                                 return false;
                             }
                             
                         });
                     }
                 }
                 
                 if(name === 'journeys'){return;}
                 if (elements.length > 0) {
                     
                     const { display, title, sortKey, defaultValue } = filterKeys[name];
                     let options = elements.map(element => {
                         // create the 'text' and 'value' for each option in the dropdown
                         // The 'text' is what is displayed, the value is an implementation detail in HTML
                         switch(name){
                            case 'vehicle':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.alias||element.asset);
                                let value = element === null ? UNSET : element.asset;
                                
                                return {
                                    text: text,
                                    value: value,
                                };
                            }
                            case 'drivers':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.driverid);
                                let value = element === null ? UNSET : element.driverid;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            }
                            default:{
                                let text = (display || ((x) => x))(element === null ? UNSET : element);
                                let value = element === null ? UNSET : element;
        
                                return {
                                    text: text,
                                    value: value,
                                }; 
                            }
     
                         }
                         
                     });
                     // Sort the options in the desired order by the sortKey, if any is specified
                     options.sort((a, b) => {
                         const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                         if (sortBy(a) < sortBy(b)) {
                             return -1;
                         }
                         if (sortBy(a) > sortBy(b)) {
                             return 1;
                         }
                         return 0;
                     });
                     // Add an 'All' option
                     if(name ==='infractions'){
                         options.push({text: 'Geofence Speed', value: 'GF_Speed',});
                         options.push({text: 'Geofence Dwell', value: 'GF_Dwell',});
                         options.push({text: 'Geofence Enter', value: 'GF_Enter',});
                         options.push({text: 'Geofence Exit', value: 'GF_Exit',});
                         options.push({text: 'Asset Count', value: 'GF_AssetCount',});
                         options.push({text: 'Speeding', value: 'Speeding',});
                         options.unshift({text: 'Clear Clips', value: 'Clear',});
                         options.unshift({text: 'None', value: 'None',});
                         
                         
                     }
                     options.unshift({text: 'All', value: 'All',});
                     // And then create the actual object that the Filters component will expect
                     // console.log("options:" ,options,defaultValue,name );
     
     
                     
                     filterData[name] = {
                         options: options,
                         default: options[0],
                         title: title || name,
                     }
                     if(defaultValue){
                         let tmpDefault = options.filter(item => item.text === defaultValue)
                         // console.log("tmpDefault: ",tmpDefault);
                         if(tmpDefault && tmpDefault.length>0){
                             filterData[name].default= tmpDefault[0].text;
                         }
                     }
                 }
     } catch (error) {
        console.log("Fail on filter iterate: ",filters,name,elementSet,error)   
     }
        });//end of foreach loop

        //Apply the default values:
        if(props.activeFilters){
            // console.log("Active filters: ",Object.keys(this.props.activeFilters))
            for(const key_ of Object.keys(props.activeFilters)){
                // console.log("Value: ",key_,this.props.activeFilters[key_])
                //The names are differet, so translate them
                switch(key_){                    
                    case 'asset':{
                        if(filterData['vehicle']){
                            filterData['vehicle'].default = props.activeFilters[key_];
                        }
                    }break;
                    case 'infraction':{
                        if(filterData['infractions']){
                            filterData['infractions'].default = props.activeFilters[key_];
                        }
                    }break;
                    case 'driver':{
                        if(filterData['drivers']){
                            filterData['drivers'].default = props.activeFilters[key_];
                        }
                    }break;
                    default:{}break
                }
            }
        }//end of active filter apply
        setFilterData(filterData);
    }//end of filterUpdated

    /**
     * Wrap the render call to simplify the organization of the component
     */
    const render=()=>{
        //  console.log('filterdata', filters, filterData);
        //  console.log('filter', filters,filters.vehicle);
        //  console.log('props', this.props);
        return (
            <div className={props.className}>

                
                <StatefulButton className="apply-filters"  onClick={props.onApply} 
                    disabled={!allowApplyButton} 
                    > {"Apply"} 
                </StatefulButton> 
                                
                <Filters filters={filterData} 
                        onFilterSelected={props.onFilterSelected} sort={false} 
                />
                
            </div>
        );
    }
    return render(); //trigger the render call
}



const MapControls = ({...props}) =>{
   
    const [filterData, setFilterData] = React.useState({});
    const [showGroupsHelpPopup, toggleHelpPopup] = React.useState(null);
 
    
    React.useEffect(()=>{
       filterUpdated();
    },[])
    React.useEffect(()=>{
        //  console.log("updated filters? ",new Date());
        filterUpdated();
    },[props.activeFilters])

    const filterUpdated=()=>{
       
        // console.log("Update site filters?",props);
        
        const filters = props.filters || {};
        // console.log("Filters in: ",filters);
        const filterData = {};
        Object.entries(filters).forEach(([name, elementSet]) => {
            if(name === 'groups'){return;}
            let elements = Array.from(elementSet.values());

            
            // console.log("?: ",name,elementSet,elements)
            if (elements && elements.length > 0) {
                
                const { display, title, sortKey, defaultValue } = filterKeys[name];
                
                const options = elements.map(element => {
                    // create the 'text' and 'value' for each option in the dropdown
                    // The 'text' is what is displayed, the value is an implementation detail in HTML
                    switch(name){
                        case 'vehicle':{
                            let text = (display || ((x) => x))(element === null ? UNSET : element.alias||element.asset);
                            let value = element === null ? UNSET : element.asset;
                            return {
                                text: text,
                                value: value,
                            };
                        }
                        case 'sites':{
                            let text = (display || ((x) => x))(element === null ? UNSET : element.alias);
                            const value = element === null ? UNSET : element.site +","+element.gps;
                            //Change the displayed name in the dropdown when in the demo_group
                            if(props.groupconfig.group === 'demo_group'){
                                if(text === 'PintoValley'){text = 'Mine 1'}
                            }
                            return {
                                text: text,
                                value: value,
                            };
                        }
                        case 'regions':{
                            const text = (display || ((x) => x))(element === null ? UNSET : element.name);
                            const value = element === null ? UNSET : element.name +","+element.id;
                            return {
                                text: text,
                                value: value,
                            };
                        }


                        default:{
                            const text = (display || ((x) => x))(element === null ? UNSET : element);
                            const value = element === null ? UNSET : element;
                            return {
                                text: text,
                                value: value,
                            };
                        }break;
                    }//end switch 
                    
                });
                // console.log("Options: ",options);
                // // Sort the options in the desired order by the sortKey, if any is specified
                options.sort((a, b) => {
                    const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                    if (sortBy(a) < sortBy(b)) {
                        return -1;
                    }
                    if (sortBy(a) > sortBy(b)) {
                        return 1;
                    }
                    return 0;
                });
                // // Add an 'All' option
                switch(name){
                    case 'vehicle':{
                        options.unshift({text: 'All', value: 'All',});
                        options.unshift({text: 'None', value: 'None',});
                    }break;
                    case 'regions':{
                        options.unshift({text: 'All', value: 'All',});
                    }break;
                    case 'sites':{
                        options.unshift({text: 'None', value: 'None',});
                    }break;
                    default:{}break
                }
                // options.unshift({text: 'All', value: 'All',});
                // // And then create the actual object that the Filters component will expect
                // // console.log("options:" ,options,defaultValue,name );
                
                filterData[name] = {
                    options: options,
                    default: options[0],
                    title: title || name,
                    autoset:null,
                    iteration:0,
                }

              
                if(defaultValue){
                    let tmpDefault = options.filter(item => item.text === defaultValue)
                    // console.log("tmpDefault: ",tmpDefault);
                    if(tmpDefault && tmpDefault.length>0){
                        filterData[name].default= tmpDefault[0].text;
                    }
                }
            }
        });
       
        if(props.activeFilters && props.activeFilters.sites){
            if(props.activeFilters.sites){
                // console.log("site props: ",props.activeFilters ,props.activeFilters,!props.activeFilters.geofencing)
                filterData['geofencing'] = {
                    options: [
                        {text: "Hide Regions", value: "hide"},
                        {text: "Show Regions", value: "show"},
                    ],
                    default: 'show',
                    title: 'Geofencing:',
                }
                //Check the user permissions:
                if(props.groupconfig.permissions && props.groupconfig.permissions.livemap){
                    if(props.groupconfig.permissions.livemap.geofencing &&
                        props.groupconfig.permissions.livemap.geofencing==='write'){
                        filterData['geofencing'].options.push({text: "Create Region", value: "create"});
                        filterData['geofencing'].options.push({text: "Create Polygon", value: "polygon"});
                        filterData['geofencing'].options.push({text: "Edit Regions", value: "edit"});
                    }
                }else{ //if permissions are not defined, then default to allowed to write
                    filterData['geofencing'].options.push({text: "Create Region", value: "create"});
                    filterData['geofencing'].options.push({text: "Create Polygon", value: "polygon"});
                    filterData['geofencing'].options.push({text: "Edit Regions", value: "edit"});
                }

                if(!props.activeFilters.geofencing || props.activeFilters.geofencing==='show'){
                    // console.log("Site is set: ",props.activeFilters.sites);
                    //Add geofencing rules:
                    filterData['geofencing'].autoset = moment();
                    filterData['geofencing'].iteration += 1;
                    filterData['sites'].default = props.activeFilters.sites;

                    //Add the Group dropdown
                    filterData['group'] = {
                        options: [
                            {text: "Hide Groups", value: "hidegroups"},
                            {text: "Show Groups", value: "showgroups"},
                            {text: "List Groups", value: "listgroups"},
                            // {text: "Configure Groups", value: "configuregroups"},
                            // {text: "Add to Group", value: "addtogroup"}
                        ],
                        //Add a help icon to the title of the groups dropdown
                        title:  <div className='richDropTitle'>
                                    <img className='helpIcon' src={helpIcon} onClick={()=>{                                       
                                        // setState({ showGroupsHelpPopup: true});
                                        toggleHelpPopup(true)
                                    }}/>
                                    <div className = "titleText">Groups:</div>                                    
                                </div>,
                    }
                    //Check the user permissions, assign if write is allowed:
                    if(props.groupconfig.permissions && props.groupconfig.permissions.livemap){
                        if(props.groupconfig.permissions.livemap.geofencing &&
                            props.groupconfig.permissions.livemap.geofencing==='write'){
                            filterData['group'].options.push({text: "Configure Groups", value: "configuregroups"});
                            filterData['group'].options.push({text: "Add to Group", value: "addtogroup"});
                        }
                    }else{ //if permissions are not defined, then default to allowed to write
                        filterData['group'].options.push({text: "Configure Groups", value: "configuregroups"});
                        filterData['group'].options.push({text: "Add to Group", value: "addtogroup"});
                    }
                    

                    filterData['group'].default = "hidegroups";
                    //Add the groups to show:
                    // console.log("Add the set to the filter: ",filterData['group'],props.filters.groups)
                    if(props.filters.groups){
                        //Add the name of the group to the bottom of the groups action list:
                        (props.filters.groups || []).forEach(group_=>{                            
                            filterData['group'].options.push({text: group_.name, value: group_.name});
                        })
                    }

                }else{

                }
                 
            }
        }else{
            // console.log("Sites not set: ");
            //Add geofencing rules:
            filterData['geofencing'] = {
                options: [
                    {text: "Hide Regions", value: "hide"},
                    {text: "Show Regions", value: "show"},
                ],
                default: 'hide',
                title: 'Geofencing:',
                autoset: null,
            }
        }
        if(props.activeFilters && props.activeFilters.geofencing){
            
            if(props.activeFilters.geofencing==='show' && (props.activeFilters.geofencing==='create' || props.activeFilters.geofencing==='polygon')){
                // console.log("Update geofence control: ")
                filterData['geofencing'].default = 'show';
                filterData['geofencing'].autoset=moment();
                filterData['geofencing'].iteration += 1;
            }            
        }

        //Check on asset: (if the active filter has an asset id set, then reset it in the filter data)
        if(props.activeFilters && props.activeFilters.vehicle){
            if(filterData['vehicle']){
                filterData['vehicle'].default = props.activeFilters.vehicle;
            }
            
        }
        // console.log("Active: ",props.activeFilters, filterData);
      
        // //Update the order of the filters, without specifying order they will be sorted alphabetically
        if(filterData['group']){filterData['group'].order=2;}
        if(filterData['geofencing']){filterData['geofencing'].order=3;}
        if(filterData['regions']){filterData['regions'].order = 4;}
        if(filterData['sites']){filterData['sites'].order=5;}
        if(filterData['vehicle']){filterData['vehicle'].order=1;}
        // console.log("Update filterData: ",filterData);
        setFilterData(filterData);
        // setState({filterData:filterData,startedOnce: true});
    }
    
    
    

    
    const render= ()=>{

        //  console.log("Props: ",this.props)
        let bAllowSiteSettings = false;
        let settingsStyle = {opacity: "0.2"}
        if(props.activeFilters.sites && !props.activeFilters.sites.includes("None")){
            settingsStyle = {opacity: "1"};
            bAllowSiteSettings = true;
        }
        //check the permissions:
        if(props.groupconfig.permissions && props.groupconfig.permissions.livemap){
            if(props.groupconfig.permissions.livemap.map && props.groupconfig.permissions.livemap.map!=='write'){
                bAllowSiteSettings = false;
                settingsStyle = {opacity: "0.2"}
            }
        }
        // console.log("Settings Style: ",settingsStyle);
        
        return (
            <div className={props.className}>
                
                <Filters filters={filterData} onFilterSelected={props.onFilterSelected} sort={false} />  
                
                <motion.img  src={iconSettings} className="site-settings" style={settingsStyle}  
                    onClick={()=>{
                        //Allow click with permissions and site select
                        if(bAllowSiteSettings){
                            //Site is seletect?
                            if(props.activeFilters.sites && !props.activeFilters.sites.includes("None")){
                               props.onFilterSelected("settings",{value:null});}
                            }
                        }
                    }  
                whileHover ={{scale:1.05}}  whileTap ={{scale:0.9}} /> 

                { showGroupsHelpPopup &&
                    <GroupsHelpPopup 
                        handleClose={()=>{ toggleHelpPopup(false);}}   
                        groupconfig = {props.groupconfig}
                    />
                }
            </div>
        );
    }

    return render();
}


//Export the functions from the top level export 
export { 
    MapFiltersView 
    ,MapControls
    ,JourneyFiltersView
 }


