import React, { Component } from 'react';

import { Auth, API } from 'aws-amplify';

import { ApiCaller } from './ApiCaller.js';

import { VIDEO_STATUS, delayPromise, displayInfraction, displayStatus,filenameAlphaStripper } from './Util.js';

import { setupPerf } from './Perf.js';

import * as moment from 'moment';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ProgressBar from 'react-bootstrap/ProgressBar'


// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 7; // in seconds

/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class AdminReview extends Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.onLoadingState = this.onLoadingState.bind(this);
    this.state = {
        reviewersData: [],
    };
    setupPerf(this, 'AdminReview', () => {return this.state.videos && this.state.videos.length > 0;});
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }
 
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
    // console.log("Calling API as: ",this.props.groupconfig)
    const realPromise = Auth.currentSession().then(
      (auth) => {
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            apiName: "handleReviewers",
            mode: "fetch",
          }
        };

        try {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
        } catch (error) {
        }

        return API.post("AuthLambda", "/apiRouter", myInit);
      });
    if (DEBUG_DELAY_API_RESPONSE) {
      return delayPromise(realPromise, DEBUG_DELAY * 1000);
    } else {
      return realPromise;
    }
  }

 
  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(data) {
    if(!data.result){return;}
    const processedData = data.result.map(row => {
        return row;
    });
    this.setState({reviewersData:processedData});

  }
  
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
    
  }
  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {

    
    
    
    
    // Compare the lenght of the videos, sort function is a simple LT GT comparison
    const lengthSorter = (a, b, order) => {
      // Sort statuses by their displayed value
      let res = 0;
      if (a < b) {  res = 1; } else if (a > b) {    res = -1;   }
      return order === 'asc' ? res : -1 * res;
    };
    
    /* Describe the columns of the table
    * 'dataField' is the name of the key in 'data' list that it will read from
    * 'text' is what it will display on the webpage for the column header
    * 'formatter' is a custom formatting function it will use for that column to transform from
    *             the data in the list to what it displays
    * 'sort' (set in the loop below cols) sets if you should be able to sort by that column
    * 'hidden' is if the column should be hidden from view, this is useful so we can get a
    *          react 'key' from a column that we don't actually want to show to the user
    */
    const reviewersData = this.state.reviewersData;
    let cols = [
        {dataField: 'username', text: 'UserName'},
        {dataField: 'dailylimit', text: 'Daily Limit',
            validator: (newValue, row, column) => { //validate the input to the field, display messages when bad values are entered
                if(isNaN(newValue)){ return { valid: false, message: 'Invalid entry, not a number' } }
                if(parseInt(newValue,10) < 0){ return { valid: false, message: 'Value must be positive' } }
                if(parseInt(newValue,10) > 100000){ return { valid: false, message: 'Value too large: [0,100000]' } }
                return true;
            },
            type: 'number'         
        },
        {dataField: 'assignedhighlights', text: 'Assigned Clips',
            validator: (newValue, row, column) => { //validate the input to the field, display messages when bad values are entered
                if(isNaN(newValue)){ return { valid: false, message: 'Invalid entry, not a number' } }
                if(parseInt(newValue,10) < 0){ return { valid: false, message: 'Value must be positive' } }
                return true;
            },
            type: 'number',
            editable:false         
        },      
        {dataField: 'reviewedclips', text: 'Clips Reviewed',editable:false},
        {dataField: 'pendingpayment', text: 'Running Total',editable:false},      
      
    ];

    /*@brief handle the click on the commit button
    */
    const commitClick = (e,cell,row) => {
      try{
        e.stopPropagation(); //console.log("Click on button",e,cell,row);
        let promptString = "Commit change to SQL table?"
        
          // Ask the user to confirm that the new user is correct
          if(window.confirm(promptString)){
            Auth.currentSession().then(
              (auth) => {
                let myInit = {
                  body: {
                    token: auth.idToken.jwtToken,
                    apiName: "handleReviewers",                    
                    data: row,                    
                  }
                };
                return API.post("AuthLambda", "/apiRouter", myInit);
              }
            ); //end promise definition
          }
      }catch(e){
        // console.log("Click handle error: ",e);
      }
    }
    
    /*@brief Create the format of the assign cell
    */
    const assignFormatter = (cell,row) => {
      let displayVal =<div>
        <button type="button" className="btn btn-outline-success btn-sm ml-2 ts-buttom" size="sm" onClick={(e)=>commitClick(e,cell,row)}>
                 Commit
             </button>
        </div>        
      return displayVal
    };
    
    cols = cols.concat([
      {dataField: 'submit', text: 'Submit', formatter: assignFormatter,editable:false},
    ]);

    

    cols.map(col => {
      col.classes = 'vid-' + col.dataField;
    });

    for (var i = 0; i < cols.length; ++i) { // set all the columns as sortable
      cols[i].sort = true;
      cols[i].headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => {
        if (isLastSorting) {
          return "sorted-by sorted-" + sortOrder;
        }
        return "";
      };
    }
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //   console.log("Row clicked: ",e,row,rowIndex);
        //  this.props.videoClicked(row);
      }
    };
    //  {/* <ProgressBar className="testpb" striped variant="success" now={35} key={1} label={`30%`}  /> */}
    const rowClasses = (row, rowIndex) => {
      return 'videolist-row row-status-' + displayStatus(row.status).toLowerCase();
    };

    /*
      Set style for the rows in the table     
    */
    const rowStyle = (row, rowIndex) => {
      // let rowcolor = '#DEFCB4'
      let rowcolor = '#00afed05'
      if(rowIndex%2 ===0){
        // rowcolor = '#F1F7E9'
        rowcolor = '#00afed20'
      }
      return{
        backgroundColor: rowcolor,
      }
      //return { ... };
    };

    return (
      <div className="video-lister">
        {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}
        <BootstrapTable keyField='username' // a react specific thing that sets the 'key' for each row in the table
                                             // react uses keys to keep track of identity when things change
                        data={reviewersData} // <-- IMPORTANT: this is the actual data being displayed
                        columns={cols}
                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                        rowStyle={ rowStyle}
                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                                       // mouse is over it. This signals to the user that the row is clickable
                        classes={"video-table"} // sets a CSS class so we can style this table specifically
                        cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                        // rowEvents={rowEvents} // set what events we want to happen on rows, right now just the click
                        rowClasses={rowClasses}
                        bootstrap4 = {true}
                        
        />
      </div>
    );
  }
}

export { AdminReview };