
import React from 'react';
import ReactModal from 'react-modal';

import { StatefulButton } from '../VideoReview/vid-util.js';

import '../CSS/modalcard.css';
import './UserConfirm.css';

/* @Brief Component to handle a general message with a Yes/No return
*/
export const UserConfirm = ({message,title,onClose,type,...props}) =>{

    //define local state varaibles with initial values
    const [showModal] = React.useState(true);
    const [affirmativeLabel, setAffirmativeLabel] = React.useState("Yes");
    const [negativeLabel, setNegativeLabel] = React.useState("No");


    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        // console.log("Called the UserConfirm")
    },[]) //[] -> execute once

    /**
     * Handle the change to the type (this is used to track what message is being displayed in the box)
     */
    React.useEffect(()=>{
        switch(type){
            case 'UNAVAILABLE':
                setNegativeLabel("close");
                setAffirmativeLabel(false);
            break;
            default:
                setAffirmativeLabel("Yes");
                setNegativeLabel("No");
            break;

        }
    },[type]) //listen for changes 

    //Handle the action taken when the buttons are clicked to close the card
    function closeCard(_choice){
        let closeMsg = {type: type, selected:_choice };
        // console.log("Close called on confirm")
        if(onClose){onClose(closeMsg)}
    }
    //Wrap up the returned render call for organization
    //Define the layout of the data rendered to the Notecard
    const render= ()=>{
        //Return the HTML code to display:
        return (
            <div className="userconfirm" >
                <ReactModal isOpen={showModal} className="modal-dialog userconfirm-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="content-modal" >
                        {/* Title */}
                        <div className='title'>{title || ''}</div>
                        {/* Render the message */}
                        <div className='message'>{message}</div>
                       
                        {/* Add the cancel and submit buttons to the bottom of the Notecard */}
                        <div className="footer">
                                {negativeLabel&&<StatefulButton className="btn btn-danger"  onClick={ ()=>closeCard(false)}>{negativeLabel}</StatefulButton>}
                                {affirmativeLabel&&<StatefulButton className="btn btn-primary" onClick={ ()=>closeCard(true) }>{affirmativeLabel}</StatefulButton>}
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    };//end of render()

    //Function component is expecting the return, add return to the render() call
    return render();

}//end of the CutVideoNotecard

