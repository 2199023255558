
import { Tile, Group } from "ol/layer";
import { XYZ , OSM} from "ol/source";
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from './map-utils.js'
import { transformExtent } from 'ol/proj';

const HEREAPIKEY='giQwHJsQGzo-ZLLLR_l3lRIBvYJo97Mv_A45Vib79RY';

//DEFINE the map tile sources
//================================

//Get data from HERE, the extent and minZoom constrain the region that is pulled from HERE
const tiles_HERE = new Tile({
    source: new XYZ({
        url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=satellite.day&apiKey=${HEREAPIKEY}`
    }),
    extent :  transformExtent([119.72217825630148,-22.604643313893195, 120.08187458098014,-22.34583588618293], COORDTYPE_GPS, COORDTYPE_MERCATOR),
    minZoom: 13.5,
});
//Get data from Google, the default source for all the tiles
const tiles_GOOGLE = new Tile({
    source: new XYZ({
        url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', //satellite hybrid (? why no api key?)
        // url: 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/png8?apiKey=YaibLoQqb4TyE5urE7yIl2JS4dnR8P0zbWMFrgFWGs8'
    }),
    // baseLayer: true,
    // visible:true,
});

//Get the topographical map from arcgisonline.com
const tiles_TOPO = new Tile({
    title: 'Topo',
    source: new XYZ({
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.jpg', 
    }),
    baseLayer: true,
    visible:false,
});

//Get a simple road map from OSM
const tiles_OSM = new Tile({
    title: 'OSM',
    type: 'base',
    visible: false,
    source: new OSM(),
    baseLayer: true,
});

//Combine the google and here layers into a single view
//The maps swich based on the defined extent/zoom constraint on the HERE map
const tilegroup_Satellite = new Group({
    layers:[  tiles_GOOGLE,tiles_HERE ],
    // layers:[tiles_HERE ],
    title: 'Satellite',
    baseLayer: true,
    visible: true,
});
//Export the group, topo, osm for the map to load
export {tilegroup_Satellite, tiles_TOPO, tiles_OSM }



