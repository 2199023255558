import React, { Component } from 'react';

import { Auth, API } from 'aws-amplify';

import { ApiCaller } from './ApiCaller.js';

import { delayPromise, displayStatus,PERIPHERAL_TIMEOUT,CHECKIN_TIMEOUT,OFFLINE_TIMEOUT} from './Util.js';

import { setupPerf } from './Perf.js';

import * as moment from 'moment';
import './AssetReview.css';
// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import alertIcon from './assets/Exclamation_flat_icon.svg'


// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 7; // in seconds

/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class AssetReview extends Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.onLoadingState = this.onLoadingState.bind(this);
    this.formatSecondsTime = this.formatSecondsTime.bind(this);
    this.state = {
        assetData: [],
        retryCount: 0,
        siteCount:0,
    };
    setupPerf(this, 'AssetReview', () => {return this.state.videos && this.state.videos.length > 0;});
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }
 
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
    // console.log("Calling API as: ",this.props.groupconfig)
    let siteList = [];
    if(this.props.possibleFilters && this.props.possibleFilters.Sites 
        && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
        //Extract the name of the sites from the site,gps pair
        (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } 
    // console.log("Fetch with: ",siteList,this.props.possibleFilters,this.props.userInfo);

    const realPromise = Auth.currentSession().then(
      (auth) => {
        let serviceName= "TrifectaAPI";            
        let apiName= "/getAssetCheckins";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            mode: "fetch",
            Sites: siteList.length>0?siteList.join(','):null,
          }
        };

        try {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
        } catch (error) {
        }

        return API.post(serviceName, apiName, myInit);
      });
    if (DEBUG_DELAY_API_RESPONSE) {
      return delayPromise(realPromise, DEBUG_DELAY * 1000);
    } else {
      return realPromise;
    }
  }

 
  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(data) {
    // console.log("Data: ",data, this.props);
    if(!data.checkins){return;}

    let siteSet = new Set();
    
    const processedData = data.checkins.map(row => {

        //Match against the asset list to get display the alias
        try {
          let asset = (this.props.possibleFilters.AssetList||[]).filter(asset_=>{ return asset_.asset.toLowerCase() === row.assetid.toLowerCase()});
          row.assetid = asset[0].alias || asset[0].asset
        } catch (error) {
          // console.log("Fail to apply list: ",error);
          row.assetid = row.assetid.toUpperCase();  
        }
        
        //Make sure the returned values are numeric, if null is returned for an unassigned value, set it to 0;
        row.gpstime = 0;
        row.cabincameratime = 0;
        row.drivercameratime = 0;
        row.bAlertIcon = false;

        //Match against the site details
        try {
          let siteDetail = (this.props.possibleFilters.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === row.site.toLowerCase()})
          //  console.log("Site detail: ",siteDetail,siteDetail[0].alias);
          // console.log("Site detail: ",siteDetail);
          if(siteDetail && siteDetail[0] && siteDetail[0].alias){
            row.site = siteDetail[0].alias;    //replace the site name with the Alias?
          }  
        } catch (error) {
          
        }

        siteSet.add(row.site);
        // extract the time since disconnect for GPS
        try{
          row.gpstime = row.gps.time;
        }catch(e){}
        // extract the time since disconnect for cabin camera
        try{
          row.cabincameratime = row.cabincamera.time;
        }catch(e){}
        // extract the time since disconnect for the Driver camera
        try{
          row.drivercameratime = row.drivercamera.time;
        }catch(e){}

        //Don't show the system warning icon if the asset is marked as offline
        if(row.status && row.status !== 'offline'){
          //Check if the cameras are not connected, but the asset is running
          if(row.cabincameratime && row.drivercameratime){
            let timeSinceCabin =  moment(row.lastupdate).diff(moment(row.cabincameratime),'seconds');   //get time since checkin on the cabin camera
            let timeSinceDriver =  moment(row.lastupdate).diff(moment(row.drivercameratime),'seconds');  //get time since checkin on the driver camera
            if((timeSinceCabin >PERIPHERAL_TIMEOUT)&&(timeSinceDriver >PERIPHERAL_TIMEOUT)){ //are both cameras offline
              row.bAlertIcon = true; //if yes, then set the alert icon to on for this row.
            }
          }
        }

        

        row.bAlert = false;        
        // Trigger alert if the asset hasn't checked in for a long time
        if(row.sinceupdate >CHECKIN_TIMEOUT ){
            row.bAlert = true;
        }
        // Trigger alert if any of the peripherals haven't connected for a long time
        if( row.gpsdowntime > PERIPHERAL_TIMEOUT || row.obddowntime > PERIPHERAL_TIMEOUT || row.dvrdowntime > PERIPHERAL_TIMEOUT ||
            row.cameradowntime > PERIPHERAL_TIMEOUT){
                row.bAlert = true;
        }

        return row;
    });
    this.setState({assetData:processedData,siteCount:siteSet.size});


    setTimeout(() => {this.setState({retryCount: this.state.retryCount+1});}, 10*1000);

  }

  /*
  * @brief convert the ms to hh:mm:ss
  */
  formatSecondsTime(_seconds){
    var dur = moment.duration({'seconds':_seconds});
    var days = Math.floor(dur.asDays());
    var hours = Math.floor(dur.asHours()) -days *24; 
    // var hours = Math.floor(dur.asHours()); 
    var mins  = Math.floor(dur.asMinutes()) -days *24*60 - hours * 60;
    var sec   = Math.floor(dur.asSeconds()) -days *24*60*60 - hours * 60 * 60 - mins * 60;
    
    var daysTXT = ("0" + days).slice(-2);
    var hoursTXT = ("0" + hours).slice(-2);
    if(hours >= 100){hoursTXT=("0" + hours).slice(-3);}
    if(hours >= 1000){hoursTXT=("0" + hours).slice(-4);}
    if(hours >= 10000){hoursTXT=("0" + hours).slice(-5);}
    if(hours >= 100000){hoursTXT=("0" + hours).slice(-6);}
    
    var minsTXT = ("0" + mins).slice(-2);
    var secTXT = ("0" + sec).slice(-2);
    var result = hoursTXT + ":" + minsTXT + ":" + secTXT;
    if(days > 0){
      result = daysTXT +":"+hoursTXT + ":" + minsTXT + ":" + secTXT;
    }
    
    return result;
  }
  
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
  }
  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {
    
    //format how the date looks in the table    
    const dateFormatter = (cell, row, rowIndex, extraData) => {
      //  console.log("Time formatter: ",cell,row,rowIndex,extraData)
      let className = "AssetOnline";

      if(parseInt(row.sinceupdate,10) >CHECKIN_TIMEOUT){        
        className ='AssetAlert';
      }
      if(parseInt(row.sinceupdate,10) >OFFLINE_TIMEOUT){       
        className ='AssetOffline';
      }

      let formattedTime = moment(cell).format("MMM DD,YYYY HH:mm:ss")
      if(!cell){
        formattedTime ='-----';
      }
      let returnVal = <div ><span className={className}>{formattedTime}</span>  </div>
      return returnVal;
      // return this.formatSecondsTime(row.sinceupdate);
    };
    //format what the peripherals display in the table
    const peripheralDateFormatter = (cell, row, rowIndex, extraData) => {
      
      //compute time since last checkin
      let timeSince =  moment(row.lastupdate).diff(moment(cell),'seconds');
     
      //set the class - determines the color of the background
      let className = "AssetOnline";
      if(timeSince >PERIPHERAL_TIMEOUT){        
        className ='AssetAlert';
      }else{ //if asset isn't checking in, but the hardware is ok-> set it to gray
        if(parseInt(row.sinceupdate,10) >CHECKIN_TIMEOUT){       
          className ='AssetOffline';
        }
      }
      if(parseInt(row.sinceupdate,10) >OFFLINE_TIMEOUT){       
        className ='AssetOffline';
      }
      //Format the time to write out the month abbreviated
      let formattedTime = moment(cell).format("MMM DD,YYYY HH:mm:ss")
      if(!cell){
        formattedTime ='-----';
        className = ""
      }
      let returnVal = <div ><span className={className}>{formattedTime}</span>  </div>
      return returnVal;
      // return this.formatSecondsTime(row.sinceupdate);
    };
   
    // Format the display of the assetid
    const assetFormatter = (cell, row, rowIndex, extraData) => {
      
      let className = "AssetOnline";
      if(parseInt(row.sinceupdate,10) >CHECKIN_TIMEOUT){        
        className ='AssetAlert';
      }
      if(parseInt(row.sinceupdate,10) >OFFLINE_TIMEOUT){       
        className ='AssetOffline';
      }
      //Break this into a stacked div so we can also render the exclamation mark
      return  <div className='connectivity-Asset'> 
                {row.bAlertIcon? <img className='alertMark' src={alertIcon}/>:null}
                <div className='asset-name'>
                  <span className={className}>{cell}</span>
                </div>
                
              </div>;
    };

    // Format the display of the site
    const siteFormatter = (cell, row, rowIndex, extraData) => {
      
      //compute time since last checkin
      let timeSince =  moment(row.lastupdate).diff(moment(cell),'seconds');
     
      //set the class - determines the color of the background
      let className = "AssetOnline";

      if(parseInt(row.sinceupdate,10) >CHECKIN_TIMEOUT){        
        className ='AssetAlert';
      }
      if(parseInt(row.sinceupdate,10) >OFFLINE_TIMEOUT){       
        className ='AssetOffline';
      }

      //Format the time to write out the month abbreviated
      let siteName = cell;
      if(!cell){
        siteName ='-----';
        className = ""
      }
      let returnVal = <div ><span className={className}>{siteName}</span>  </div>
      return returnVal;

    };

    
 
    
    /* Describe the columns of the table
    * 'dataField' is the name of the key in 'data' list that it will read from
    * 'text' is what it will display on the webpage for the column header
    * 'formatter' is a custom formatting function it will use for that column to transform from
    *             the data in the list to what it displays
    * 'sort' (set in the loop below cols) sets if you should be able to sort by that column
    * 'hidden' is if the column should be hidden from view, this is useful so we can get a
    *          react 'key' from a column that we don't actually want to show to the user
    */
    const assetData = this.state.assetData;
    let cols = [
        {dataField: 'assetid', text: 'AssetID',formatter:assetFormatter},
        {dataField: 'site', text: 'Site',formatter:siteFormatter},

        {dataField: 'lastupdate', text: 'Compute Box Checkin',editable:false, formatter:dateFormatter},
        {dataField: 'drivercameratime', text: 'Driver Camera Checkin',editable:false,formatter:peripheralDateFormatter},
        {dataField: 'cabincameratime', text: 'Cabin Camera Checkin',editable:false,formatter:peripheralDateFormatter },
        {dataField: 'gpstime', text: 'GPS Checkin',editable:false,formatter:peripheralDateFormatter },
      
    ];

    if(this.state.siteCount<2){
      // console.log("Set count: ",this.state.siteCount);
      cols = cols.filter( val_=>{ return val_.dataField==='site'? false:true})
    }
    // cols


    //assign class names to the columns, this formats the text size
    cols.map(col => {
      col.classes = 'vid-' + col.dataField;
    });
    
    // set all the columns as sortable
    for (var i = 0; i < cols.length; ++i) { 
      cols[i].sort = true;
      cols[i].headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => {
        if (isLastSorting) {
          return "sorted-by sorted-" + sortOrder;
        }
        return "";
      };
    }
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //  this.props.videoClicked(row);
      }
    };
    const rowClasses = (row, rowIndex) => {
      return 'videolist-row row-status-' + displayStatus(row.status).toLowerCase();
    };

    /*
      Set style for the rows in the table     
    */
    const rowStyle = (row, rowIndex) => {
      
      let rowcolor = '#00afed05' 
      if(rowIndex%2 ===0){
        rowcolor = '#00afed20'
      }
      return{
        backgroundColor: rowcolor,
      }
    };

    return (
      <div className="video-lister">
        {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} retryCount = {this.state.retryCount} />:null}
        <BootstrapTable keyField='assetid' // a react specific thing that sets the 'key' for each row in the table
                                             // react uses keys to keep track of identity when things change
                        data={assetData} // <-- IMPORTANT: this is the actual data being displayed
                        columns={cols}
                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                        rowStyle={ rowStyle}
                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                                       // mouse is over it. This signals to the user that the row is clickable
                        classes={"video-table"} // sets a CSS class so we can style this table specifically
                        cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                        rowClasses={rowClasses}
                        bootstrap4 = {true}
                        defaultSorted={[
                          {dataField: 'assetid', order: 'asc'}, // how things should be sorted by
                        ]} // default when first displayed
                        
        />
      </div>
    );
  }
}

export { AssetReview };