//OpenLayers imports:
import {Stroke, Style,Fill, Icon ,Circle as CircleStyle} from 'ol/style';
import { ol_coordinate_offsetCoords } from 'ol-ext/geom/GeomUtils';
import {Point} from 'ol/geom';
import {splitLineString,arrow_img} from '../journey-renderutils.js'

// Gray
const ROAD_COLOR_GRAY = [128,150,128,.5];//edge3 colors 
const ROAD_COLOR_OUTLINE_GRAY = [89,100,100,.75];//darker shade with tranparency for effect
// Yellow
// const ROAD_COLOR_YELLOW = [136,127,51,.5];
// const ROAD_COLOR_OUTLINE_YELLOW = [89,81,32,.75];//darker shade with tranparency for effect

const ROAD_COLOR_YELLOW = '#ffe200'; //Yellow color of the inactive assets
const ROAD_COLOR_OUTLINE_YELLOW = '#fffce6';
//Choose road color
const ROAD_COLOR = ROAD_COLOR_YELLOW;
const ROAD_COLOR_OUTLINE = ROAD_COLOR_OUTLINE_YELLOW;

//Load road sign images:
const stop_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/icons/stop.svg'
const turnleft_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/icons/turn_left.svg'
const fourway_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/4way.svg'
const slowdown_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/icons/slowdown.svg'


/**
 * Define a set of styles to apply to the Roads,
 * Configure the color and size, keyed to the name of the road type
 */
export const roadStyles = {
    //Define the style for the normal route 
    'road': [ new Style({
      stroke: new Stroke({
        width: 10,                
        color: ROAD_COLOR,
      }),
    })],
    'road_outline': [ new Style({
        stroke: new Stroke({
          width: 10,                
          color: ROAD_COLOR_OUTLINE,
        }),
      })],
};

/** 
 * Define the style function for the roads, this determine how the road Feature is rendered
 * @param {*} _feature Feature to render on the Layer
 * @param {*} _resolution current resolution of the map 
 */
export const roadStyler = (feature, resolution)=>{
  return renderRoads(feature,resolution);
}

export const signStyler = (feature, resolution)=>{
  return renderTrafficSign(feature,resolution);
}

const renderRoads = (feature, resolution)=>{
  let styleReturn = [];
  try {
    let lineWidthScalar = 10;
    let lineWidth = lineWidthScalar * (1/resolution); //thicker lines
    let lineWidthOutline = lineWidth*1.25;

    //Get the two line styles to stack together
    let outlineStyle = roadStyles['road_outline'][0];
    let tempStyle = roadStyles['road'][0];

    //Set the width of the line 
    outlineStyle.getStroke().setWidth(lineWidthOutline);    
    styleReturn.push(outlineStyle); 
    
    //Set the width of the line on top (smaller to give a gradient view)
    tempStyle.getStroke().setWidth(lineWidth);    
    styleReturn.push(tempStyle);

    // //Render the direction arrows:
    if(resolution < 1.5 && true){
        let arrowScale = (0.075) * ((2/resolution)/2) * 0.5;
        let maxResolutionLevel = 0.3;
        // var size = 64;
        // console.log("Arrow size: ",(0.075) * ((4/resolution)/2),((4/resolution)/2),resolution)
        
        
        let minSegmentLength = Math.max(arrowScale*(200*resolution)*6,50);
        // console.log("Arrow: ",arrowScale, minSegmentLength,resolution,lineWidth)
        function applySplitPoints(_splitPoints,_styleReturn) {
            //Compute a polygon expansion to create the offset coordinates:                
            let offsetCoord = _splitPoints;
            // try{ 
            //     // offsetCoord = ol_coordinate_offsetCoords(_splitPoints,((lineWidth/4) * resolution));
            // }catch(error){
            // }

            // console.log("Points: ",_splitPoints.length,_splitPoints[0]);
            _splitPoints.forEach( function(point,idx) {
                if(offsetCoord && offsetCoord[idx]){
                    let arrowStyle = null;
                    arrowStyle = new Style({
                        geometry: new Point([offsetCoord[idx][0],offsetCoord[idx][1]]),
                        image: new Icon({
                            src: arrow_img,
                            scale: 0.075,
                            rotation: point[2],
                            opacity: 0.5,
                        })
                    })
                    if(resolution > maxResolutionLevel){
                        arrowStyle.getImage().setScale(arrowScale)
                    }
                    _styleReturn.push(arrowStyle);
                }else{
                  // console.log("escaped on:",offsetCoord, idx,offsetCoord[idx]);
                }
            });
        }
        
        let geomType = feature.getGeometry().getType();
        let splitPoints;
        // console.log("GeoType:", geomType,feature);
        switch(geomType){
            case 'LineString':{
                splitPoints = splitLineString(feature.getGeometry(),0,feature,minSegmentLength, {alwaysUp: true, midPoints: true});
                // console.log("Split points returned:",splitPoints);
                applySplitPoints(splitPoints,styleReturn)
            }break;
            case 'MultiLineString':{
                let lineStrings = feature.getGeometry().getLineStrings();
                for(let idx=0; idx<lineStrings.length; idx++){
                    try {
                        splitPoints = splitLineString(lineStrings[idx],idx,feature,minSegmentLength, {alwaysUp: true, midPoints: true});
                        applySplitPoints(splitPoints,styleReturn)
                    } catch (error) {
                        console.log("Error mls splits:" ,error);
                    }
                    
                }//end loop over line geometries            
            }break;
        }
    };//end resolution check
    // console.log("style returned:" ,styleReturn);

    //Return the style to the render function
    return styleReturn;
  } catch (error) {
    console.log("Road render fail: ",error);
    return roadStyles['road'][0];
  }
}
const renderTrafficSign = (feature, resolution)=>{
  let classification = feature.get('classification');
  let styleReturn = [];
  // console.log("Render sign: ",classification, resolution);

  if(!classification){
    return styleReturn;
    //Get the size:
    // let clusteredFeatures = feature.get('features');
    // if(feature.get('features').length===1){
    //   return renderTrafficSign(clusteredFeatures[0],resolution);
    // }
  
    // //Clustered group
    // styleReturn= signStyles['cluster'][0];  
    // return styleReturn;
  }

  styleReturn= signStyles[classification][0];

  //Return a fixed size thumbnail if above a set height/resolution
  if(resolution > .75){
    //Need to repel the features from each other?
    return [];
  }

  //Set the scalar size based on the image asset
  switch(classification){
    case 'stop':{
        let scaleVal = .0125 * (1/resolution); 
        styleReturn.getImage().setScale([scaleVal,scaleVal])
    }break;
    case 'turn_left':{
        let scaleVal = .05 * (1/resolution); 
        styleReturn.getImage().setScale([scaleVal,scaleVal])
    }break;
    case 'turn_right':{
        let scaleVal = .05 * (1/resolution); 
        styleReturn.getImage().setScale([-scaleVal,scaleVal])
    }break;
    case 'fourway':{
        let scaleVal = .05 * (1/resolution); 
        styleReturn.getImage().setScale([scaleVal,scaleVal])
    }break;
    case 'slowdown':{
      let scaleVal = .05 * (1/resolution); 
      styleReturn.getImage().setScale([scaleVal,scaleVal])
  }break;
  }
  return styleReturn;
}

/**
 * Define a set of styles for the traffic signs 
 */
export const signStyles = {
  //Define the style for the stop sign
  'stop': [ new Style({
              image: new Icon({
                  color: '#ffffff', //set the color                   
                  anchor: [0.5, 0.5],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  scale: [0.0125,0.0125],
                  // src:'data:image/svg+xml;base64,'+stop_img ,
                  src: stop_img, //use a static image for the marker (svg to allow for coloring)
                  // declutterMode: 'obstacle'
              })                        
          })
  ],
  //Define the style for turn left sign
  'turn_left': [ new Style({
                    image: new Icon({
                      color: '#ffffff', //set the color     
                        anchor: [0.5, 0.5],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        scale: [0.125,0.125],
                        src: turnleft_img, //use a static image for the marker (svg to allow for coloring)
                        // declutterMode: 'obstacle'
                    })                        
                })
  ],
  //Define the style for turn right sign
  'turn_right': [ new Style({
                    image: new Icon({
                      color: '#ffffff', //set the color     
                        anchor: [0.25, 0.25],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        scale: [-0.125,0.125],
                        src: turnleft_img, //use a static image for the marker (svg to allow for coloring)
                        // declutterMode: 'obstacle'
                    })                        
                })
  ],
  //Define the style for 4 way intersection sign
  'fourway': [ new Style({
                  image: new Icon({
                    color: '#ffffff', //set the color     
                      anchor: [0.5, 0.5],
                      anchorXUnits: 'fraction',
                      anchorYUnits: 'fraction',
                      scale: [0.05,0.05],                      
                      src: fourway_img, //use a static image for the marker (svg to allow for coloring)
                      // declutterMode: 'obstacle'
                  })                        
                })
  ],
  //Define the style for slowdown sign
  'slowdown': [ new Style({
      image: new Icon({
        color: '#ffffff', //set the color     
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: [0.05,0.05],                      
          src: slowdown_img, //use a static image for the marker (svg to allow for coloring)
          // declutterMode: 'obstacle'
      })                        
    })
  ],
  'cluster':[
    new Style({
      image: new CircleStyle({
          radius: 4,
          fill: new Fill({color: '#ccffcc'}),
      }),                     
    })
  ]
};//edn sign styles