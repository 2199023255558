import React, { PureComponent } from 'react';

import { Filters } from '../BaseComponents.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateFilter } from './DateFilter.js';

import {  toTitleCase, severityNumber } from '../Util.js';

import './CallinFilter.css';

//Displayed filters:
export const filterKeys = {
    'siteID': {
        'title': 'Site',
    },
    'severity': {
        'display': toTitleCase,
        'title': 'Severity',
        'sortKey': severityNumber,
    },
    'vehicleID': {
        'title': 'Asset',
    },
    'asset': {
        'title': 'Asset',
    },
    'infractionType': {
        'title': 'Clip',
    },
    'driverID': {
        'title': 'Driver',
    },
    'vehicle': {
        'title': 'Asset',
    },
    'driver': {
        'title': 'Driver',
    },
    'site':{
        'title': 'Site',
    },    
    'e3person':{
        'title': 'EDGE3 Person',
    },    
    'infraction': {
        'title': 'Clip',
        // 'defaultValue': this.props.activeFilters['infractions'] ,
        // 'defaultValue': 'Severe Drowsiness'
    },
};

// A special value used in dropdowns when the 'null' value is a valid filter, displaying 'null'
// to the user wouldn't be understandable.
const UNSET = 'unset';

export class CallinFiltersView extends PureComponent {
    constructor(props) {
        super(props);
        // keep track of the start and end date specially because the DatePickers
        // need to know (so they can display a range)
        this.state = {
            startedOnce: false,
            showGroupsHelpPopup: false,
            currentSite:null,
            modifiedTime: new Date(),
        };
    }

    // Execute on startup, set the initial values from the properties
    componentDidMount(){
    }

    //Listen for changes to the properties, so we can set the updated start date when the time filter is selected.
    UNSAFE_componentWillReceiveProps(newProps) {
        if(this.props.modifiedTime !== newProps.modifiedTime){
            // console.log("modified time: ",newProps);              
            this.setState({modifiedTime: newProps.modifiedTime})
        }
    }//end will receive props

    /* @brief A component to handle the Filter dropdowns on the HR Review page
    *
    * Note: this component is currently not effective in filtering the HR Review page,
    *       it has been implemented to get feedback on the look and feel of the HR Review
    *       page in total.
    */
    render() {       
        const filters = this.props.filters || {};

        

        const filterData = {};
        let iOrder = 1;
        //  console.log("Filters: ",filters)
        Object.entries(filters).forEach(([name, elementSet]) => {            
            let elements = Array.from(elementSet.values());

            
            //Apply a site filter to the vehicle dropdown:
            if(this.props.currentSite && name === 'vehicle' && this.props.currentSite !== 'None'){
                //Filter the list of elements, only return ones where the asset's site matches the selected site
                elements = elements.filter(value_ => {
                    return value_.site.toLowerCase() === this.props.currentSite.toLowerCase()
                });
            }
            
            try {
                elements = (elements||[]).filter(elem_ => !elem_.includes('no-')); 
            } catch (error) {}

            
            try{
                if (elements.length > 0) {
                    let filterKeysLocal = this.props.filterKeys || filterKeys;
                    const { display, title, sortKey, defaultValue,order,disabled=false} = filterKeysLocal[name];
                    
                    let options = elements.map(element => {
                        // create the 'text' and 'value' for each option in the dropdown
                        // The 'text' is what is displayed, the value is an implementation detail in HTML
                        
                        switch(name){
                            case 'asset':
                            case 'vehicle':{
                                let text = (display || ((x) => x))(element === null ? UNSET : (element.alias || element.asset));
                                let value = element === null ? UNSET : element.asset;
                                
                                return {
                                    text: text,
                                    value: value,
                                };
                            }

                            case 'driver':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.driverid);
                                let value = element === null ? UNSET : element.driverid;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            }

                            case 'site':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.alias);
                                const value = element === null ? UNSET : element.site;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            }

                            default:{
                                let text = (display || ((x) => x))(element === null ? UNSET : element);
                                let value = element === null ? UNSET : element;
        
                                if(name === 'quant'){
                                    text += ' mins'
                                }
                                return {
                                    text: text,
                                    value: value,
                                }; 
                            }
                        }
                        
                    });

                    // Do not sort the hardware tags or bin
                    if(name!=='hwtags' && name!=='bin'){
                        options.sort((a, b) => {
                            const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                            if (sortBy(a) < sortBy(b)) {
                                return -1;
                            }
                            if (sortBy(a) > sortBy(b)) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    switch(name){
                        case 'quant':
                        case 'bin':
                            break;   

                        case 'driver':
                            options = (options||[]).filter(elem_=>elem_.text!=='TOOBIG'); //remove place holders for empty arrays
                            options = (options||[]).filter(elem_=>elem_.value!== undefined); //no empty spaces
                            options.unshift({text: 'All', value: 'no-driver-selected',});
                            break;                        
                        case 'infraction':
                            options.unshift({text: 'None', value: 'None',});                                    
                            options.unshift({text: 'All', value: 'All',});                                
                            break;
                        default:
                            options.unshift({text: 'All', value: 'All',});
                            break;
                    }
                    
                    filterData[name] = {
                        options: options,
                        default: defaultValue || options[0],
                        title: title || name,
                        order: iOrder++,    
                        disabled: disabled,                
                    }
                    if(order){
                        filterData[name].order = order;
                    }
                  
                    
                    if(defaultValue){
                        let tmpDefault = options.filter(item => item.value === defaultValue)
                        if(tmpDefault && tmpDefault.length>0){
                            filterData[name].default= tmpDefault[0].value;
                        }
                    }

                    if(this.props.selectedFilters){
                        // console.log("Selected:" ,this.props.selectedFilters[name])
                        if(this.props.selectedFilters[name]){
                            filterData[name].selected = this.props.selectedFilters[name];
                        }
                    }


                }else{
                    // console.log("empty element set: ",name, elementSet)
                }
            }catch(error){}
            
        });

        if(filterData.quant){
            filterData.quant.order=4
        }
      
        let journeyCount = null;
        try {
            if(filterData['journeys']){
                journeyCount = filterData['journeys'].options.length;
            }    
        } catch (error) {}
        
        //  console.log('filterdata', this.props.filterKeys || filterKeys, filterData, this.props.selectedFilters);
        //    console.log('filters to display', filters);
        //  console.log('props', this.props);
        return (
            <div className={this.props.className}>
                
                <div className='section-apply'>
                    <button className="apply-filters"  onClick={this.props.onApply} > {"Apply"} </button>  
                </div>
                {/* Use the DateFilter component, with start and end date already formatted */}
                <DateFilter startDate={this.props.startDate} endDate={this.props.endDate} onChange={this.props.onFilterSelected} /> 
                
                <div className='section-dropdowns'>
                    <Filters filters={filterData} onFilterSelected={this.props.onFilterSelected} sort={false} button={this.props.button}/>
                </div> 
                
                

                
                
                {journeyCount && <div className = "journey-count">
                                    <div>Journeys: </div>
                                    {journeyCount}
                                 </div>        
                }

                
                
            </div>
        );
    }
}