
import React, { useRef, useState } from 'react';

import { Auth, API } from 'aws-amplify';

import './CameraControlInterface.css';

import {useOutsideClick} from '../Util/useOutsideClick.js'; 
import { StatefulButton } from '../VideoReview/vid-util.js';

/**
 * Create a opad UI to enable camera controls
 */
export const CameraControlInterface = ({    style,
                                            details,
                                            assetid, 
                                            disabled,
                                            controlCallback=()=>{console.log("Camera control click callback not defined")},
                                            ...props}) =>{

    const displayName = 'CameraControlInterface';

    const componentRef = useRef(null); //keep a reference to the toplevel HTML element to monitor the outside click
    useOutsideClick(componentRef,onOutsideClick); //register a hook to listen for clicking outside the component
    const [hasBeenClick, setClickState] = useState(false);

    //State variables tracking the returned data from the API fetch

    //First execution 
    React.useEffect(()=>{
        return () => {}
    },[]) //[] -> execute once
   
    /**
     * Handle the callback for the button/close action
     * @param {*} _data 
     */
    function confirmClose(_data){
        if(props.onClose()){props.onClose();}
    }

    /** Close the popup if the user clicks outside the component */
    function onOutsideClick(_data){
        confirmClose();
    }
    /**
     * Handle the actions taken when a button is clicked on the control pad
    * @param {*} _e: event from the mouse   
    * @param {*} _data 
     */
    function onClick(_e,_buttonName){
        if(hasBeenClick){return;}
        setClickState(true);
        console.log("Clicked: ",_buttonName);
        try {
            _e.stopPropagation(); //don't let the mouse get handled by any other elements 
        } catch (error) {}
        switch(_buttonName){
            case 'up':{
                updateAPI('move',{direction:_buttonName, amount: 100});                
            }break;
            case 'down':{
                updateAPI('move',{direction:_buttonName, amount: 100});
            }break;
            case 'left':{
                updateAPI('move',{direction:_buttonName, amount: -200});
            }break;
            case 'right':{
                updateAPI('move',{direction:_buttonName, amount: -200});
            }break;
            case 'focus':{
                updateAPI('move',{direction:_buttonName, amount: -1});
            }break;
        }//end switch statement
        
        
        //Delay the closing of the interface to allow feedback from the button press
        setTimeout(()=>{
            controlCallback(); //Signal back that the camera controller was clicked to the parent component
            confirmClose();//automatically close the camera control after the click
        },500);//set the time to delay before execution
        
    }//end onClick()

    /**Send the command to the API */
    function updateAPI(_command,_payload){
        //------------------------------------------------------
        //Get a current token to pass to the API call
        Auth.currentSession().then(
            (auth) => {
            //Configure the API call
            let serviceName= "TrifectaCoreAPI";//Set the servicename - this is the collection of lambdas
            let apiName= "/handleCameraControl"; //Set the specific lambda function to invoke
            //Configure the data to send to the Lambda
            let body={
                token: auth.idToken.jwtToken, //Provide the authentication token to validate that the user is signed in
                assetid: assetid, //pass the assetid
                mode: _command, //Specify a switch mode to indicate that this is to update the review trackers for the clips
                username: auth.idToken.payload['cognito:username']
            }
            let myInit = { body: Object.assign(body,_payload)}; //merge the statid body with the input object
            //Call the API
            const sendAPIUpdate = API.post(serviceName,apiName, myInit);
            sendAPIUpdate.then(_response=>{ });//Response recieved from the API
            //Log error messages: to the console
            sendAPIUpdate.catch(_error=>{console.log("Error clipReviewAPI return: ",_error);});
        }).catch( (error)=>{console.log("Failed to authenticate session")}) //authorization failed:
    }
    
    /**
     * Wrap up the returned render call for organization 
     */
   const render= ()=>{
    //Return the HTML code to display:
    return (
        <div className="camera-control" disabled={disabled} ref={componentRef}>
                <div className="body">
                    <div className="control-set">
                        {/* Add the 4 directions for the opad */}
                        <nav className="o-pad" onClick={(e)=>{onClick(e,'focus')}} >
                            <a className="up"       href="#" onClick={(e)=>{onClick(e,'up')}}      ></a>
                            <a className="right"    href="#" onClick={(e)=>{onClick(e,'right')}}   ></a>
                            <a className="down"     href="#" onClick={(e)=>{onClick(e,'down')}}    ></a>
                            <a className="left"     href="#" onClick={(e)=>{onClick(e,'left')}}    ></a>  
                        </nav>
                    </div>
                </div>
        </div>
    );
};//end of render()

   //Function component is expecting the return, add return to the render() call
   return render();

}  