import React, { useState, useEffect} from 'react';
import alertIcon from '../assets/Exclamation_flat_icon.svg'
import offlineIcon from '../assets/offline-dino.png'
import { PhoneInterface } from '../ExpandedNotecard/PhoneInterface.js';

export const ButtonWarnings = ({  onClick
                                  ,config
                                  ,assetIndex
                                  ,warningSet=[]
                                  ,offlineSet=[]
                                  ,onCall
                                  ,groupconfig
                                  ,...rest}) => {

  const [isOffline, setOffline] = useState(false);
  const [isWarning, setWarning] = useState(false);
  const [offlineClass, setOfflineClass] = useState('offline-mark');
  const [offlineStyle, setOfflineStyle] = useState({visibility:"hidden"});
  const [warningStyle, setWarningStyle] = useState({visibility:"hidden"});
  const [phoneCall, setPhoneCall] = useState(null);

  useEffect(() => {
    // console.log("warningSet",warningSet,assetIndex )
    let matchIdx = (warningSet||[]).findIndex(elem_ => {return elem_.asset === assetIndex});
    if(matchIdx >=0){
      setWarning(true);
      setWarningStyle({visibility:"visible"});
    }else{
      setWarning(false);
      setWarningStyle({visibility:"hidden"});
    }
  }, [warningSet]);
  useEffect(() => {
    // console.log("Offline",offlineSet,assetIndex )
    //Find the warning matches
    let matchIdx = (offlineSet||[]).findIndex(elem_ => {return elem_.asset === assetIndex});
    if(matchIdx >=0){
      setOffline(true);
      setOfflineStyle({visibility:"visible"});
    }else{
      setOffline(false);
      setOfflineStyle({visibility:"hidden"});
    }
  }, [offlineSet]);

  function onIconClick(_type){
    console.log("Clicked: ",_type);
    //Get a list of all offline assets:
    let assetids = [];
    switch(_type){
      case 'Offline':{
        assetids = (offlineSet||[]).filter(elem_ => {return elem_.site === config.siteid});
      }break;
      case 'System Warning':{
        assetids = (warningSet||[]).filter(elem_ => {return elem_.site === config.siteid});
      }break;
    }
    assetids = assetids.sort((a, b) => a.asset > b.asset ? 1 : -1);
    console.log(`${_type} Click: `,assetids);
    let assetString='';
    try {
      for(const asset_ of assetids){
        assetString +=asset_.asset;
        assetString +=',';
      }  
    } catch (error) {}
    //remove trailing comma:
    assetString = assetString.slice(0, -1);

    if(onCall)(onCall());

    setPhoneCall({      
                    assetid: assetString,
                    siteid: config.siteid,
                    tags: [_type]                              
                  })     

  }

  //2 Render 
  return (
    <div className='warningblock' key={'warningblock'+assetIndex}>
      {isOffline>=0? <img className= {offlineClass} key={'asset-button-off'+assetIndex} src={offlineIcon} style={offlineStyle}
                        onClick={(e) =>{onIconClick('Offline')}}
      />:null}
      {isWarning? <img className= 'alert-mark' key={'asset-button-warn'+assetIndex} src={alertIcon} style={warningStyle}
                        onClick={(e) =>{onIconClick('System Warning')}}
      />:null}
      { phoneCall && 
        <PhoneInterface
          groupconfig = {groupconfig}            
          onClose={()=>{setPhoneCall(null)}}
          {...phoneCall}
          siteDetails = {config.siteDetails}
        />
      }
    </div>
  );
}
