

//OpenLayers imports:
import { Fill, Stroke, Style } from 'ol/style';
import { Circle as CircleStyle, Icon } from 'ol/style';
import {Polygon,Point} from 'ol/geom';
import {toContext} from 'ol/render';

//Standard circle dot used for the infractions
const marker_img = 'http://openlayers.org/en/latest/examples/data/dot.svg'

//Constant colors for fast global changes
const EDGE3GREEN = '#7eb238';//'#8dc63f'; //The green color used by EDGE3 sidebar (CAS)
const EDGE3GREEN_background = '#f4f9eb';

const EDGE3RED = '#ed1c24'; //The red color used by EDGE3 sidebar (CAS_AEB)
const EDGE3RED_background = '#fde7e8';

const ASSET_INACTIVE = '#ffe200'; //Yellow color of the inactive assets
const ASSET_INACTIVE_stroke = '#fffce6';

const ASSET_ACTIVE = '#0000e0'; //Blue color of the active assets
const ASSET_ACTIVE_stroke = '#ccccff';
const ASSET_SIMULATED = '#008700';//'#00e200'; //Green color of the active assets
const ASSET_SIMULATED_stroke = '#ccffcc';
const ASSET_INREGION = '#00ff1e'; //Color of the asset inside a Geofence region
const ASSET_INREGION_stroke = '#ccffcc';



/**
 * Define a set of styles to apply to the Infractions,
 * Configure the color and size, key to the name of the alert
 */
export const infractionStyles = {
    //Define the style for the normal infractions
    'infraction': [ new Style({
                image: new Icon({
                    color: '#ed1c24', //set the color                   
                    anchor: [0.5, 0.5],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    scale: [0.5,0.5],
                    src: marker_img //use a static image for the marker (svg to allow for coloring)
                })                        
            })
    ],
    //Define the style for the CAS alert infraction
    //2 concentric circles
    'CAS-AEB': [ 
            new Style({
                    image: new CircleStyle({
                        radius: 11,
                        fill: new Fill({color: EDGE3GREEN_background}),
                    }),                     
            }), 
            new Style({
                    image: new CircleStyle({
                        radius: 6,
                        stroke: new Stroke({
                            color: 	EDGE3GREEN,
                            width: 2,
                        }),
                    }),                     
            }),
            new Style({
                image: new CircleStyle({
                    radius: 9,
                    stroke: new Stroke({
                        color: EDGE3GREEN,
                        width: 2,
                    }),
                }),                     
            })
    ],
    //Define the style for the CAS-AEB alert infraction
    //3 concentric circles
    'CAS': [ new Style({
                    image: new CircleStyle({
                        radius: 12,
                        fill: new Fill({color: EDGE3RED_background}),
                    }),                     
                }), 
                new Style({
                    image: new CircleStyle({
                        radius: 4,
                        stroke: new Stroke({
                            color: 	EDGE3RED,
                            width: 2,
                        }),
                    }),                     
                }),
                new Style({
                    image: new CircleStyle({
                        radius: 7,
                        stroke: new Stroke({
                            color: EDGE3RED,
                            width: 2,
                        }),
                    }),                     
                }),
                new Style({
                    image: new CircleStyle({
                        radius: 10,
                        stroke: new Stroke({
                            color: EDGE3RED,
                            width: 2,
                        }),
                    }),                     
                })                     

    ],


};
/**
 * Define the style function for the infractions/alerats, this determine how the asset Feature is rendered
 * @param {*} _feature Feature to render on the Layer
 * @param {*} _resolution current resolution of the map 
 */
export const setInfractionStyle = (feature,resolution)=>{
    let featureType = null;
    if(feature){featureType = feature.get('type');}
    
    switch(featureType){
        case 'infraction':{ 
            let classification = null;
            if(feature){classification = feature.get('classification');}            
            // if(classification){console.log("Found: ",classification);}
            //Check if the classification is set for the infraction
            // if found, use this to apply unique styles to the infraction type (CAS in this case)           
            switch(classification){
                default: {return infractionStyles['infraction'];} //Return the default style if no classification is set
                case 'CAS':{ return infractionStyles['CAS'];} //Grab the styles for the CAS type
                case 'CAS-AEB':{return infractionStyles['CAS-AEB'];} //Grab the styles for the CAS-AEB type
            }//end classification switch
        }//end type switch
        case 'alert':{ 
            // console.log("Info: ",feature, featureType);
            return alertStyles['alert'];}
        default:{
            // console.log("Info: ",feature, featureType);
            return new Style({}); //unknown feature, set the style to empty (renders not visible)
            //JourneyMarker is rendered here?
        }
        
        // case 'alert'
    }//end switch
    
    // let styleReturn =  journeyStyles[featureType];
}

/**
 * Define a set of styles to apply to the Alerts,
 * Configure the colro and size, key to the name of the alert
 */
export const alertStyles = {
    'alert': [ new Style({
        image: new Icon({ //Create an icon
            color: '#ED7C1C', //set the color                  
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            scale: [0.5,0.5],
            src: marker_img //use a static image
        })                        
    })
    ],
}



/**
 * Scale the supplied coordinates by a defined _scale
 */
const scaleFunction = function (coordinate,_scale) {
    return [coordinate[0] * _scale, coordinate[1] * _scale];
};
/**
 * Define a simple polygon to draw an arrow for the asset
 */
const symbol = [
    [4, 0],
    [6, 5],
    [4, 3],
    [2, 5],
    [4, 0] 
];

/**
 * Create a canvas to draw the icon
 * @param {*} _size : size of the icon to draw
 * @param {*} _color : color of the icon to draw
 * @returns 
 */
const createAssetCanvas=(_size,_color )=>{
    let scale = _size / 10;
    let canvas = document.createElement('canvas');
    //Set a context to draw to (using 2D only)
    var vectorContext = toContext(canvas.getContext('2d'), {
        size: [_size, _size],
        pixelRatio: 1,
    });
    //Configure the render, using a polygon with fill and stroke 
    //An outline can be configured by setting the stroke color different than the fill    
    vectorContext.setStyle(
                        new Style({
                            fill: new Fill(
                                {color: _color.fill}
                            ),
                            stroke: new Stroke(
                                {color: _color.fill, width: 1}
                            ),
                        })
                    );
    //Draw onto the context to create the icon
    vectorContext.drawGeometry(new Polygon([symbol.map(n => scaleFunction(n,scale))]));    
    return canvas; //return this canvas (stored in the cache to prevent creating many cavnas elements)
}

/**
 * Get the color of the asset based on the state saved in the feature
 * @param {*} _state : current state of the asset
 */
const getStateColor=(_state)=>{
    switch(_state){
        default:
        case 'active':{return {fill:ASSET_ACTIVE,stroke:ASSET_ACTIVE_stroke};}
        case 'inactive':{return {fill:ASSET_INACTIVE,stroke:ASSET_INACTIVE_stroke};}
        case 'inregion':{return {fill:ASSET_INREGION,stroke:ASSET_INREGION_stroke};}        
        case 'simulated':{return {fill:ASSET_SIMULATED,stroke:ASSET_SIMULATED_stroke};}        
    }
}
/**
 * Define the style function for the Assets, this determine how the asset Feature is rendered
 * @param {*} _feature Feature to render on the Layer
 * @param {*} _resolution current resolution of the map 
 * @param {*} _color color to set the asset based on the state (deprecated)
 * @param {*} _cache A cache to prevent the need to repeteadly re-create the canvas for the asset
 */
export const setAssetStyle = (_feature,_resolution,_color,_cache)=>{
    let featureType = null;
    let bearing = null
    let state = 'active';
    let type = null;
    let styleCache = _cache||{};
    //Read details from the feature
    if(_feature){
        featureType = _feature.get('type');
        bearing = _feature.get('bearing');
        state = _feature.get('state');
        type = _feature.get('asset_type');
        //Check if the asset is simulated, if it is then override the color codes
        if(type && type === 'simulated'){  state = 'simulated';    }
        
    }
    let size = 45; //fixed size of the asset icon, this is scaled down when creating the canvas
    

    
    if(!styleCache[size]){//no canvas found for the size 
        styleCache[size] = {}; //create an empty object to hold the cache
        //Add an entry for the current state of the feature
        styleCache[size][state] = createAssetCanvas(size,getStateColor(state));
    }else{
        //Check if a cachec copy of the state exists at this size, if not, add one
        if(!styleCache[size][state]){ styleCache[size][state]= createAssetCanvas(size,getStateColor(state));}
    }
    //Fetch the canvas from the style cache
    let canvas = styleCache[size][state];
    let returnStyle = [];
    //Add the start/end points:
    //Debugging of the animation path, leave false to prevent rendering start and end points of the path
    if(_feature.mPath&&false){
        let startPointStyle = new Style({
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({color: 'green'}),
                stroke: new Stroke({
                  color: 'white',
                  width: 2,
                }),
              }),
            geometry: new Point(_feature.mPath.getFirstCoordinate()),
        });
        returnStyle.push(startPointStyle); //add the start point to the render style
        let endPointStyle = new Style({
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({color: 'red'}),
                stroke: new Stroke({
                  color: 'white',
                  width: 2,
                }),
              }),
            geometry: new Point(_feature.mPath.getLastCoordinate()),
        });
        returnStyle.push(endPointStyle); //add the end point to the render style
    } //end animation path debugging markers
    //Add the default style of the asset to the render style
    returnStyle.push(
        new Style({
            image: new Icon({ //create an icon to render the asset
                img: canvas, //draw the arrow image for the asset
                imgSize: [size, size], //set the size
                rotation: bearing, //set the rotation of the icon
                anchor: [0.5, 0.5], //anchor the point to the center of the icon
                anchorXUnits: 'fraction', //allow for fractional units for more accuracy
                anchorYUnits: 'fraction',
            }),
        })
    );
    //return the render style
    return returnStyle;
}




