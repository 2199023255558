
import React, {  PureComponent } from 'react';
import { setupPerf, perfDidClick  } from '../Perf.js';

import '../VideoReview/VideoReviewer.css';


/* @brief get the total count for each button
  */
const getTotalLessEjections = (_ejectedIDs, _row, allEjects)=>{
  let count = 0;
  
  let ejectedIds = [];//(_ejectedIDs.infractionIds)||[];
  let nonEjected =[];
  try {
    if(_ejectedIDs){
      ejectedIds = _ejectedIDs.infractionIds
    }  
  } catch (error) {
    
  }
  //  console.log("process: ",_ejectedIDs,_row.set,_row,allEjects);
  //Find values not in the ejectedIDS set:
  try {
    // console.log("Check: ",_set, ejectedIds);
    nonEjected =  _row.set.filter(set_ => {
      //  console.log("in set: ",set_.InfractionID);
       return (ejectedIds.includes(set_.InfractionID))? false: true;
      });
    //  console.log("_set: ",_row.set, nonEjected);  
  } catch (error) {
    console.log("Error on filter: ",error);
  }
  
  return nonEjected.length;
}


/* @brief get the css classes to apply to the button
  */
function getButtonCSSClass (_chosenName,_chosen,_row) {
  // Including CSS classes depending on the state
    let classes = [
      "btn",
      "video-choice",
      "video-choice-" + _chosenName,
    ];
    if (_chosenName === _chosen) {
      classes.push("chosen");
      classes.push("btn-primary");
      if(_row.loadComplete===false){classes.push("is-loading");}
    } else if (!_row.streamURL) {
      // console.log("Disable? ",_chosenName,_row)
      classes.push("btn-disabled btn-outline-disabled disabled");
    } else if (_row.set && _row.set.length===0) {
      classes.push("btn-disabled btn-outline-disabled disabled");
    } 
    else if(_row.loadComplete===false){
      // classes.push("btn-warning");
      classes.push("btn-inverse btn-outline-inverse");
      classes.push("is-loading");
    }
    else {
      classes.push("btn-inverse btn-outline-inverse");
    }
    // if(_chosenName === 'MicroSleep'){
    //   console.log("Button Classes: ",_chosenName, classes,_row);
    // }
    
    
    return classes;
  
}; //end getButtonCSSClass


/* @brief Create the name for the button
*/
function getButtonDisplayname(_row, _ejectionIds)
{
  const chosenName = _row.chosenName;
  let displayName = _row.displayName;
  // Add in the infraction count for the relevant buttons
  if (chosenName !== "original") {
    //This is a workaround to address the count prop not updating correctly
    let tmpCount = _row.count;
    
    if(_row.set){
        tmpCount = _row.set.length ;
        try {
          tmpCount = getTotalLessEjections(_ejectionIds[_row.chosenName],_row);
        } catch (error) {
          
        }
    }
    else if(_row.metadata){ tmpCount = _row.metadata.length;}
    if(chosenName === 'highlights'){tmpCount = _row.count;} //highlights should show the combined total of all the clips

    tmpCount = Math.max(tmpCount,0);
    displayName += " ( " + tmpCount + " )"; 
    
    try {
      // console.log("Button: ",displayName,_row.set.length);  
    } catch (error) { }
    
  }
  return displayName
}

/*
* @brief A component for displaying multiple videos that can be switched between for review
*/
export class VideoReviewerButton extends PureComponent {
  constructor(props) {
    super(props);
    setupPerf(this, 'VideoReviewer', () => true);
    
    this.state = {
    
    };

  }
  

  /* @brief Run once when the class is loaded
  */
  componentDidMount() {
    // console.log("Button mount:" ,this.props);
  
  } //end compenentDidMount

  /* @brief Render the content of the current page, automatically called when react determines the page needs refreshing.
  */
  render() {
     // Get the internal and displayed name (been set up already in the constructor)        
     const chosenName = this.props.data.chosenName;

     let displayName = getButtonDisplayname(this.props.data,this.state.ejectionIds);

     // CSS classes for the button
     let classes = getButtonCSSClass(chosenName,this.props.chosen,this.props.data);

     if(this.props.ejectSet && this.props.ejectSet.length>=1){
    //  if(this.state.ejectSets && this.state.ejectSets[this.props.data.chosenName] && this.state.ejectSets[this.props.data.chosenName].ejectList.length >= 1 ){ 
        if(this.props.groupconfig.group!=="reviewgroup"){
      //    reprocessStyle.visibility="visible"; 
      //    canReprocess = true;
        }
       
        if(this.props.ejectSet.processing){
      //    procIcon = IconProcessing;
      //    isProcessing = true;
        }
     }

     /*
     * @brief A helper function for what happens when a chooser button is pressed
     */
     const buttonClick = () => {
      this.props.onClick(this.props.data.chosenName);
     }

    //  console.log("Keys on buttons:",this.props.data.classification + "-" + this.props.data.type)

     return (
             <button className={classes.join(" ")} key={this.props.data.classification + "-" + this.props.data.type}
             onClick={buttonClick}
             >
               {displayName}
             </button>);
  }
}



// export { VideoReviewerType };
 
