import React, { Component,PureComponent } from 'react';
import ReactModal from 'react-modal';
import { Auth, API } from 'aws-amplify';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import './CombinedCard.css';
// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { RiskTab } from './RiskTab';
import { StorylinesTab } from './StorylinesTab';
import { DriverTab } from './DriverTab';
import * as moment from 'moment';


/*
* @brief Display the card opened when clicking on a risk profile
*/
class CombinedCard extends PureComponent {
    
  constructor(props) {
      super(props);

      this.fetchStoryLines = this.fetchStoryLines.bind(this);

      this.state = {
        shown: true,
        currentUsername:null,
        activeView: 'overview',
        tabViews: [{key:'overview', name:'Overview'},{key:'storylines', name:'Storylines'},{key:'risk', name:'Risk Profile'}],
        loadingStoryLines:-1,
        storylines: [],
      };
  }

  componentDidMount(){
    //  console.log("Combined: ",this.props);

     this.setState({loadingStoryLines:1});
     this.fetchStoryLines();
  }

  fetchStoryLines(){
    const storyPromise = Auth.currentSession().then((auth) => 
    {
        let apiName = "TrifectaAPI";
        let path = "/handleStoryLines";
        let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                mode: 'fetch',
                driverid: this.props.driverid,
                // driverid: '00C1C-test3',
            }
        };
        return API.post(apiName, path, myInit);
    });
    storyPromise.catch( (error)=>{console.log("Failed to get stories: ",error)});
    storyPromise.then( (_data)=>{
    //    console.log("Stories returned:  ",_data)
      // const cardsByInfID = this.state.cardsByInfractionid;

      if(_data.stories){
          //loop over the stories
          let storylist = _data.stories.map(story_=>{
            try {
                //format the data
                return {
                    date: moment.parseZone(story_.date).format('MMM DD, YYYY'),
                    
                    driverid: story_.driverid,
                    journeyStart: story_.starttime,
                    journeyEnd: story_.endtime,
                    Drowsiness:story_.drowsiness||[],
                    'Severe Drowsiness':story_.severedrowsiness||[],
                    Calls:story_.calls||[],
                }    
            } catch (error) {
               console.log("Error on story: ",error,story_) 
            }
          });//end iteration over stories


          
            //Present table in reverse chronological order:
        try {
            storylist= storylist.sort( (a,b) => {
                // console.log("Compare: ",a,b);
                try {
                    let momentA = moment(a.date);
                    let momentB = moment(b.date);
                    if(momentA.isSame(momentB)){
                    // console.log("Same date");
                    if(moment(a.journeyStart).isBefore(moment(b.journeyStart))){return 1;}
                    return -1;  
                    }else{
                    if(momentA.isBefore(momentB)){return 1;}
                    return -1;
                    }
                } catch (error) {
                    console.log("Error on compare: ",a,b);
                    return 0;
                }
            })  
        } catch (error) {
            
        }
        
        // console.log("StoryLine list:" ,storylist);

          //set to the state to trigger render
          this.setState({storylines: storylist});
          setTimeout(() => {this.setState({ loadingStoryLines:-1});}, 250);

      }//end valid data check
    });//end handling returned notecards
  }//end fetchStoryLines
     
  /*
  * @brief Draw the photo and driverid
  */
  render() {

    const style = {};
    if (this.props.scale && this.props.scale !== 1) {
        style.transform = 'scale(' + this.props.scale + ')';
    }
    // console.log("Storylines state: ",this.state.storylines)

    return (
      <ReactModal isOpen={this.state.shown} className="modal-dialog combinedcard-modal"
                          shouldCloseOnOverlayClick={true} ariaHideApp={false}
                          onRequestClose={ ()=>{
                            // console.log("Requested closed")
                            this.props.handleClose({
                                                    driverid: this.props.driverid,
                                                    notes:this.state.comments
                                                  })
                          }}
      >
        <div className="combinedcard-modal-content" style={style} > 
                <div className='toprow'>
                    <div className="viewTabs">
                        <Tabs className='viewSelection'
                            defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                            id='uncontrolled-tab-example' 
                                activeKey={this.state.activeView} 
                                onSelect={(k)=> this.setState({activeView:k})}
                        >
                            {this.state.tabViews.map((type) => {
                                return(  <Tab key={type.key} eventKey={type.key} title={type.name}/> )
                            })} 
                        </Tabs>
                    </div>
                </div>
                <div className='tabcontent'>
                    {this.state.activeView==='risk' && 
                        <RiskTab 
                            {...this.props}                        
                        />
                    }
                    {this.state.activeView==='storylines' && 
                        <StorylinesTab 
                            {...this.props} 
                            storylines= {this.state.storylines}
                            loadingStoryLines = {this.state.loadingStoryLines}                             
                        />
                    }
                    {this.state.activeView==='overview' && 
                        <DriverTab 
                            {...this.props}                        
                        />
                    }
                </div>
        </div>
      </ReactModal>
    )
  }
}

export { CombinedCard };