import { getDistance,getAreaOfPolygon,getCenterOfBounds } from 'geolib';
import Feature from 'ol/Feature';
import {Polygon} from 'ol/geom';
import { fromLonLat} from 'ol/proj';

/** Draw a bounding box to the OpenLayers boundary layer
 * @param {Array<coordinate>} _boundGPSArray bounding box (GPS coordinates)
 * @param {string} _name name to add to the drawn feature
 * @param {Array<ol.VectorSource>} _olSources reference to the stored vector sources for the map's layers
 */         
export const drawBoundingBox = (_boundGPSArray,_name,_olSources)=>{
    //Transform the GPS into the project pixel coordinates for the map
    let transformedCoords = [];
    transformedCoords.push(fromLonLat([_boundGPSArray[0],_boundGPSArray[1]]));
    transformedCoords.push(fromLonLat([_boundGPSArray[2],_boundGPSArray[1]]));
    transformedCoords.push(fromLonLat([_boundGPSArray[2],_boundGPSArray[3]]));
    transformedCoords.push(fromLonLat([_boundGPSArray[0],_boundGPSArray[3]]));
    //Create a polygon geometry to draw the bounding box
    let tmpPolygon = new Polygon([transformedCoords]);
    //Add the geometry to a Feature to add to the Map
    let newFeature=new Feature(tmpPolygon);
    //Set common features
    newFeature.set('type','boundary');
    newFeature.set("name",_name);
    //Add the region to the map:
    _olSources['boundary'].addFeature(newFeature);
}

/** Compute the area of a bounding box in square meters using the geolib library. Convert
 *   the input array of GPS coordinates into  the notation needed for geolib
 * @param {Array<coordinate>} _box bounding box (GPS coordinates)
 */         
const getBoundingBoxArea = (_boundingGPSArray)=>{
    return getAreaOfPolygon([
        [_boundingGPSArray[0], _boundingGPSArray[1]],
        [_boundingGPSArray[2], _boundingGPSArray[1]],
        [_boundingGPSArray[2], _boundingGPSArray[3]],
        [_boundingGPSArray[0], _boundingGPSArray[3]]
    ]);
}
/** Find the height in meters of the of the bounding box
 * @param {Array<coordinate>} _box bounding box (GPS coordinates)
 */
const getBoxHeight = (_box)=>{
    return getDistance( {latitude: _box[0], longitude: _box[1]},
                        {latitude: _box[0], longitude: _box[3]});
}
/** Find the width in meters of the of the bounding box
 * @param {Array<coordinate>} _box bounding box (GPS coordinates)
 */
const getBoxWidth = (_box)=>{
    return getDistance( {latitude: _box[0], longitude: _box[1]},
                        {latitude: _box[2], longitude: _box[1]});
}

/** Find the intersection of the bounding boxes, return the GPS coordinates of their intersection
 * @param {Array<coordinate>} _box1 first bounding box
 * @param {Array<coordinate>} _box2 second bounding box
 */
const getIntersectingBox = (_box1, _box2)=>{
    //Declare a return type to return in case of error
    let returnObj={ intersect: true,area:1}

    try {
        //Find the intersection of the boxes
        returnObj.rect = [   
            Math.max(_box1[0], _box2[0]),   //largest longitude is the x1 value
            Math.max(_box1[1], _box2[1]),   //largest lattitude is the y1 value
            Math.min(_box1[2], _box2[2]),   //smallest longitude is the x2 value
            Math.min(_box1[3], _box2[3]),   //smallest lattitude is the y2 value
        ];    
        //Check to make sure these values are not inverted, if so then they are not forming a box
        //This indicates that the two input boxes do not overlap
        if((returnObj.rect[3] > returnObj.rect[1]) && (returnObj.rect[2] > returnObj.rect[0])){
            returnObj.intersect = false; //set the intersection attribute to false
        }

        //Compute the area:
        returnObj.area = getBoundingBoxArea(returnObj.rect);

    } catch (error) {}
    finally{
        return returnObj; //return the details about the intersection
    }
    
}


/** Compute the overlap and coverage between two bounding boxes
 * @param {Array<coordinate>} _journeyBounds Bounding box of the Journey View
 * @param {Array<coordinate>} _mapBounds Bounding box of the map view
 */
export const getBoundingBoxOverlap = (_journeyBounds, _mapBounds)=>{
    
    let overlap = 0; 
    //Define a simple type to hold common values
    const boxDefine={
        area: 0, //area of a bounding box 
        center:null, //the center coordinates of the bounding box
        overlap:0, //the percentage of overlap relative to the bounding box
        coverage:null, // percentage of area compared to the other bounding box
    }     
    let journeyBox = Object.create(boxDefine); //create a type to describe the journey's bounding box
    let mapBox = Object.create(boxDefine); //create a type to describe the map view's bounding box

    // Compute the intersection of the two boxes
    // Return the GPS coordinates of the intersecting box
    let intersection = getIntersectingBox(_journeyBounds,_mapBounds); 
    // console.log("Intersect:",intersection);

    // Compute the area of the bounding box, returned in square meters
    journeyBox.area = getBoundingBoxArea(_journeyBounds);
    mapBox.area = getBoundingBoxArea(_mapBounds);
    //Compute the overlapping area of the two boxes
    let unionArea = journeyBox.area + mapBox.area - intersection.area;

    // Compute the height of the bounding box, returned in meters
    journeyBox.height = getBoxHeight(_journeyBounds);
    journeyBox.width =getBoxWidth(_journeyBounds);

    // Compute the width of the bounding box, returned in meters
    mapBox.height = getBoxHeight(_mapBounds);
    mapBox.width = getBoxWidth(_mapBounds);

    //Compute the overlap of the two boxes if they intersect    
    if(intersection.intersect === false){
        overlap = (intersection.area/unionArea)*100; 
        journeyBox.overlap = (intersection.area/journeyBox.area)*100;
        mapBox.overlap = (intersection.area/mapBox.area);
    }
    
    // console.log("Areas:",journeyBox.area,mapBox.area,intersectionArea,unionArea);

    //Get the center of the Journey bounding box:
    journeyBox.center = getCenterOfBounds([
        {latitude: _journeyBounds[0], longitude: _journeyBounds[1]},
        {latitude: _journeyBounds[2], longitude: _journeyBounds[1]},
        {latitude: _journeyBounds[2], longitude: _journeyBounds[3]},
        {latitude: _journeyBounds[0], longitude: _journeyBounds[3] }
    ]);

    //Combine data into a return object:
    return {
        journey: journeyBox,
        map: mapBox,
        widthPercent: journeyBox.width/mapBox.width,
        heightPercent: journeyBox.height/mapBox.height,
        percentMin : Math.min(journeyBox.width/mapBox.width,journeyBox.height/mapBox.height),
        percentMax : Math.max(journeyBox.width/mapBox.width,journeyBox.height/mapBox.height),
        overlap: overlap,
        intersectBox: intersection.rect,
    }
}//end of getBoundingBoxOverlap

