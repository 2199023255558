
import React from 'react';
import ReactModal from 'react-modal';
import { StatefulButton } from '../VideoReview/vid-util.js';

import './VideoCutsReviewNotecard.css'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-widgets/styles.css';
import { Spinner } from '../ApiCaller.js';

import blankAvatar from '../assets/avatar-blank.png';
import { VideoCutNotecard } from './VideoCutNotecard';
import moment from 'moment';

//Format the status in the table
const statusFormatter = (_value, row, rowIndex, extraData) => {
    try {
        switch(_value){
            case 0:{ return 'Pending' }
            case 1:{ return 'Complete' }
            case 2:{ return 'Reviewed' }
        }
        return _value;    
    } catch (error) {
        return "Pending";
    }
}//end statusFormatter
//format the date in the table
const dateFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){return '---';}
    try {
        return moment(_value,'YYYY-MM-DD[T]HH:mm:ss.SSS').format('MMM DD, YYYY HH:mm:ss');
    } catch (error) {
        return '---';
    }
}//end statusFormatter
//Format the length in the table
const lengthFormatter = (_value, row, rowIndex, extraData) => {
    //Recieved in seconds:
    //Convert to HH:MM:SS
    if(!_value){return '---'}
    try {
        return new Date(_value*1000).toISOString().substring(11, 19);
    } catch (error) {
        return '---'
    }
}//end lengthFormatter
//Format the thumbnail in the table
const imgFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return <img className="tableimg" src={blankAvatar} alt=''  /> ;
    }
    return <img className="tableimg" src={_value} alt=''  />;
}//end pictureFormatter

/**
 * Notecard to display the table showing all VideoCuts that have been made by a user
 * @param {*} onChange: callback to trigger when a change to the notecard occurs (status,delete)
 * @param {*} onClose : callback to trigger when close is requested
 * @param {*} videocuts: an aray of videocut objects
 * @param {*} props : general properties 
 */
export const VideoCutsReviewNotecard = ({onChange,onClose,videocuts,...props}) =>{

    const displayName = 'VideoCutsReviewNotecard';

    const [showModal, setShowModal] = React.useState(true);
    const [table, updateTable] = React.useState({data:null,cols:[],sortOrder:[]});
    const [showNotecard, toggleShowCard] = React.useState(false);
    

    React.useEffect(()=>{
        // console.log(`[${displayName}] loaded props: `,props);
         //Handle the exit // Anything in here is fired on component unmount.
         return () => {
        }

    },[]) //[] -> execute once

    /**
     * Process the videocuts when available to display in the table
     */
    React.useEffect(()=>{
        //Define the columns in the table:
        let columnDetails = [
            {dataField: 'status', text: 'Status',editable:false,formatter: statusFormatter, sort:true,headerStyle: () => {return { width: "15%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'thumbnail', text: 'Thumbnail',editable:false,formatter: imgFormatter, sort:true,headerStyle: () => {return { width: "20%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'timeofday', text: 'Time of Day',editable:false,formatter: dateFormatter, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'time_completed', text: 'Completed',editable:false,formatter: dateFormatter, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'cut_length', text: 'Length',editable:false,formatter: lengthFormatter, sort:true,headerStyle: () => {return { width: "15%",whiteSpace:"center",textAlign:"center"};}},
            
        ];
        //set the classnames for each column:
        columnDetails.forEach(col => {col.classes = 'soc-' + col.dataField; return col; });

        let sortOrder = [
            {dataField: 'status', order: 'asc'}, // how things should be sorted by
            {dataField: 'time_completed', order: 'asc'}
            ]

        //Store the formatted data in the infractionCounts state
        updateTable({data:videocuts, cols:columnDetails, sortOrder:sortOrder});

        //Handle the exit // Anything in here is fired on component unmount.
        return () => {
        }

    },[videocuts]) //run when new videocuts are available

    /**
     * Receive the click action on the row
     * @param {*} e : Click event
     * @param {*} row : Row data supplied to the table
     * @param {*} rowIndex : Indext of the row in the table
     */
    function onRowClick(e, row, rowIndex){
        e.preventDefault();
        //Get the details to pass to the Notecard:
        let cardDetails = Object.assign(row);
        toggleShowCard(cardDetails);
        //Trigger a status update on the notecard:
        if(row.status ===1){
            if(onChange){onChange({type:'status',pk_id:row.pk_id})}
        }
        
    }

    /**
     * Close the VideoCuts table     
     */
    function closeCard(){
        setShowModal(false);
        let closeMsg = {};
        if(onClose){onClose(closeMsg)}
    }

    /**
     * Receive the close message from the VideoCutNotecard
     * Check if this card's data should be removed from the list 
     * @param {*} _data 
     */
    function onCloseNotecard(_data){
        try {
            // console.log("Close notecard:",_data);
            //Check if the delete command was attached to this close request
            if(_data && _data.delete){  
                //Remove the data from the list of videocuts
                if(onChange){onChange({type:'delete',pk_id:_data.pk_id})}
            }
        } catch (error) {}
        toggleShowCard(false);
    }

    /**
     * Apply styling to a row in the Table
     * @param {*} row : the row data from the table
     * @param {*} rowIndex : the index of the row
     * @returns 
     */
    const rowStyle = (row, rowIndex) => {
        //Set the alternating colors based on the row index
        let rowcolor = '#00afed05'
        if(rowIndex%2 ===0){ rowcolor = '#00afed20' }
        //configure the rowstyle, set the inline style properties
        let rowStyle={ backgroundColor: rowcolor}
        //If the status is set to REVIEWED, then fade the row out
        if(row.status === 2){
            rowStyle.opacity = 0.5; //add a 50% opacity to the row
        }
        return rowStyle
    };
    
    //Wrapper for the render to organize the call
    function render(){
        //=================Define the table formatting==================
        const rowClasses = (row, rowIndex) => { return 'soc-lister'; };
        
        //Pagination options for the infractions table       
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{text: '5', value: 5}] 
        };

        return(
            <ReactModal isOpen={showModal} className="modal-dialog videocutreview-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=> closeCard()}
            >
                <div className="content-modal" >
                    <div className='title'>VideoCuts</div>
                    {!(table.data)?<Spinner/> :                         
                        <React.Fragment>
                            {(table.data.length===0)?
                                <div className='nodata'> 
                                    No video cuts available. New video cuts can be requested from the notecards.
                                </div>
                                 : 
                                <div className="cutsTable">
                                    <BootstrapTable 
                                        keyField='reactkey' // a react specific thing that sets the 'key' for each row in the table
                                                            // react uses keys to keep track of identity when things change
                                        data={table.data} // <-- IMPORTANT: this is the actual data being displayed
                                        columns={table.cols}
                                        defaultSorted={table.sortOrder} // default when first displayed
                                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                        // keep track of what data belongs to what row when scanning across
                                        rowStyle={ rowStyle}
                                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                                        //                  // mouse is over it. This signals to the user that the row is clickable
                                        classes={"videocutsreview-table"} // sets a CSS class so we can style this table specifically
                                        rowClasses={rowClasses}
                                        bootstrap4 = {true}
                                        detailView={true} detailViewIcon={false} detailViewByClick={true}
                                        pagination={ paginationFactory(paginationOptions) }
                                        rowEvents={{
                                            onClick:onRowClick} // set what events we want to happen on rows, right now just the click
                                        }
                                    />
                                </div>
                            } 
                        </React.Fragment>
                    }
                    
                    {/* Add the cancel and submit buttons to the bottom of the Notecard */}
                    <div className="footer">
                            <StatefulButton className="btn btn-danger" onClick={ ()=>closeCard() }>Close</StatefulButton>
                            <div></div>
                    </div>
                            
                </div>
                {showNotecard&&<VideoCutNotecard 
                                {...showNotecard}
                                groupconfig={props.groupconfig}
                                userInfo={props.userInfo}
                                handleClose={onCloseNotecard}
                            />}
            </ReactModal>
        )
    }//end render()

    return render();
}//end of VideoCutNotificationsClass

