

// Add a way to turn off performance timing globally
export const DO_PERFORMANCE_TIMING = false; //should be false for release
// Set to true to disable notecard changes from the VideoReview and disable reporting review progress
export const VIDEOREVIEW_NOREPORT = false;  //should be false for release
// Set to true to allow loading a video even though the original file is archived
export const VIDEOREVIEW_BYPASSARCHIVE = false;  //should be false for release
// And a configuration for if scrolling is an interesting start-of-measurement
// This should usually be turned off, as scrolling is a very spammy event and
// is likely to itself have performance significance, as well as making it difficult
// to measure other timings.
export const SCROLL_AS_PERFORMANCE_MARK = false; //should be false for release


/* 
* @brief Helper method to parse through the permissions object to check if a permission is set
*         The permissions are stored as a json object in the groupconfig
*
* @param _groupConfig: the groupConfiguration property that contains the permissions
* @param _permissionKey: the top level attribute to check in the permissions object
* @param _permissionValue: the desired value to match. 
*             - This can be null if the intended call was to check if a key is set/exists in the object. example: "notecards": *
*             - This can be true/false as a direct key,value match, example: "permissionkey":true
*             - This can be a single string or number as a direct key,value match, example: "permissionkey":"write"
*             - Specify and object {key:value} to match an attribute of an object, example: "notecards":{"addtag":true}
*                 will match with {addtag:true}
*             - If the permission is an array of values, then specifying a single value in the _permissionValue will return
*                 true if the value is found in the array example: "notecards":["value1","value2",...]
* @return true|false
*/
export const isPermissionSet = (_groupConfig,_permissionKey,_permissionValue)=>{
  //Example chain: if(props.groupconfig && props.groupconfig.permissions && props.groupconfig.permissions.drowsyremove){
  try {
    //Are permissions configured for the user?
    if(_groupConfig && _groupConfig.permissions){
      //Is the key found in the permission set
      if(!_groupConfig.permissions[_permissionKey]){return false;}
      //is the permissionValue is not specified, we are just checking any permissions are set?
      if(_permissionValue === undefined || _permissionValue === null){ return true;}
      //Try to match the value:
      let permissionEntry = _groupConfig.permissions[_permissionKey];
      //Check the type of entry to match against:
      switch(typeof(permissionEntry)){
        //Is it a simple key:value pair with a value of string,number, or boolean:
        case "string":
        case "number":
        case "boolean":{ return permissionEntry === _permissionValue;}
        //Is the value an object that 
        case "object":{ //includes arrays, and objects
          //Check if the permission object is an array[] or an object {}
          if(Array.isArray(permissionEntry)){//The permissions are an array of single values
            let matchFound = false;
            for(const key_ of permissionEntry){
              matchFound |= key_ === _permissionValue;
            }
            return matchFound;
          }else{ //the permission entry is an object
            // console.log("Permission is an object ",permissionEntry,_permissionValue)
            try {
              //Need to compare an object entry, must have a key:value pair
              if(typeof(_permissionValue)!=='object'){return false;} //return false if a key:value pair is not supplied              
              //Check if the key is an attribute of permission entry:   
              let entries = Object.entries(_permissionValue);
              //Extract to local key,value variables for readability
              let key = entries[0][0];
              let value = entries[0][1];
              //Test the key and value
              if(!permissionEntry[key]){ return false;} //if the key doesn't exist, return false
              if(!permissionEntry[key]===value){  return false;} //if the value doesn't match, return false
              //Debugging entries with log statements
              // if(!permissionEntry[key]){ console.log("Did not find key entry: ",key,permissionEntry); return false;}
              // if(!permissionEntry[key]===value){ console.log("Did not match value: ",value,permissionEntry[key]); return false;}
              return true;
            } catch (error) {
              console.log("Fail on object permission: ",error);
              return false;
            }
          }//end of object parsing
        }//end of object type
      }//end type switch
      //All matching passed, return the value comparison against the key:
      return _groupConfig.permissions[_permissionKey] === _permissionValue;
    }else{return false; }
  } catch (error) { return false;}
}


/** Helper to extract the specified name from the cookie store */
export const getCookie = (name) => {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

