
import {  API } from 'aws-amplify';
import moment from 'moment';


const ENABLE_CACHING = true;
const CACHE_EXPIRATION_TIME =  120*24*60*60*1000;//(days*hours*mins*seconds*ms) 120 days
// const CACHE_EXPIRATION_TIME =   10*60*1000;// 10 minute testing value
const CACHE_EXPIRATION_TIME_SHORT = 30*60*1000; //30 minutes


/**
 * Manage fetching the Dashboard query data, queries are automatically broken down by year and previous year's data are 
 * cached in the IndexedDB storage
 * @returns 
 */
export function useDashDownloader(  dbInstance
                                    ,callback= () => {console.log("Warning no callback defined for download complete")}
                                  ){

  /**
   * Save a key,value pair to the IndexedDB
   * @param {*} _key: key to save the data in
   * @param {*} _value: value to save to the key
   * @param {*} _expireTimeMS: numbe of milliseconds to keep the data before forcing a refetch
   */
  const setDBWithExpiration = (_key,_value,_expireTimeMS) => {
    //get the current time:
    const currentTime = new Date().getTime();
  
    //Wrap the value into a new object and attach the expiration time:
    let toStore = {
      value: _value,
      expires: currentTime + _expireTimeMS
    }
    dbInstance.cache.add({
      Key: _key,
      Value:JSON.stringify(toStore),
    })
  }

  /**
   * Get the item from the IndexedDB
   * @param {*} _key: the key used to reference the value
   * @returns: the value stored in the database (null if not found or expired)
   */
  const getDBWithExpiration  = async (_key) => {
      //Retrieve the item from storage
      let retrievedData =  await dbInstance.cache.where('Key').equals(_key).toArray();
      try {
        
        if(retrievedData && retrievedData.length>0){ //was something returned?
          //extract the value from the returned data:
          let storedObject = JSON.parse(retrievedData[0].Value);//get the object out of the return
          // console.log("_data: ", storedObject);  
          //get the current time:
          const currentTime = new Date().getTime();
          //check if the value has expired, if yes then it needs to be deleted
          if(currentTime > storedObject.expires){
            // console.log("Object expired: ",_key);
            dbInstance.cache.delete(_key);
            return null;//return null since the object had expired
          }//end expiration check
          return storedObject.value; //return the cached value
        }else{
          // console.log("None Returned: ",_key,retrievedData);
          return null; //nothing found in the database
        }
      } catch (error) {
        console.log("Error extracting from DB: ",error);        
      }
  }

  /**
   * Helper function to merge an array of objects, merges based on value type:
   *  Object: Call mergeSum recursively
   *  Array: use Array.concat() to merge the arrays
   *  Other: use += to sum the two elements    
   * @param {*} _objects: Input array of objects to merge
   * @returns merged single object
   */
  const mergeSum = (_objects) => {
    const result = {}; //define a return object
    //Assumed that the objects have properties of type array
    _objects.forEach(object_ => { //loop over the input objects
      for (let [key_, value_] of Object.entries(object_)) { //loop over the attributes in the object
        if (result[key_]) { //Check if this is already known
          if(Array.isArray(value_)){
            // console.log("Value to merge: ",key_,value_);
            result[key_] = result[key_].concat(value_); //concatenate the value if it is an array
          }else{
            if(typeof(value_) === 'object'){
              //example: mergeSum([{count: 1},{count: 3}]);
              result[key_] = mergeSum([result[key_],value_]); //call recusive to handle another object
            }else{
              result[key_] += value_; //add it to the existing data
            }
          }
        } else { //not already known
          result[key_] = value_; //set to the new value
        }
      }
    });
    return result; //(7)
  };//end mergeSum
      
  /**
   * Helper function to merge the parts of the API query return
   * @param {*} _merged: merged object
   * @param {*} _toMerge: value to merge to the merged object
   */
  const mergeResults = async (_merged,_toMerge)=>{
    // console.log("Merge results: ",_toMerge.graph, _merged);
      //Set up graph type in the merge results
    if(!_merged[_toMerge.graph]){
      _merged[_toMerge.graph] = {graph:_toMerge.graph, data:[],alerts:[],byHour:{},byType:{},dataCards:null};
    }
    //Merge the data based on the graph type
    //=====================================
    //Merge the data array
    if(_toMerge.data){
      _merged[_toMerge.graph].data = _merged[_toMerge.graph].data.concat(_toMerge.data);
    }
    //merge the alerts array
    if(_toMerge.alerts){
      _merged[_toMerge.graph].alerts = _merged[_toMerge.graph].alerts.concat(_toMerge.alerts);
    }
    //merge the dataCards array
    if(_toMerge.dataCards){
      if(!_merged[_toMerge.graph].dataCards){_merged[_toMerge.graph].dataCards = [];}
      _merged[_toMerge.graph].dataCards = _merged[_toMerge.graph].dataCards.concat(_toMerge.dataCards);
    }

    //merge the byType:
    _merged[_toMerge.graph].byType = mergeSum([_merged[_toMerge.graph].byType,_toMerge.byType]);

    //merge the byHour:
    _merged[_toMerge.graph].byHour = mergeSum([_merged[_toMerge.graph].byHour,_toMerge.byHour]);

    _merged[_toMerge.graph].graph = _toMerge.graph;

    return _merged;
  }

  /**
   * Execute an API query
   * @param {*} _queryName: name of the API query
   * @param {*} _apiName: which API to be called
   * @param {*} _apiPath: path on the API that will be called
   * @param {*} _apiParams: parameters to pass to the API query
   */
  const queryAPI_datecached = async (_queryName, _apiName, _apiPath, _apiParams,_callback) => {

    // console.log("New query:" ,_queryName)

    //Split the query by years: current YTD, previous year, all prior
    let queries = [];
    
    let currentYearStart = moment().startOf('year').format('YYYY-MM-DD');
    //Define the first query range:
    //Current year
    queries.push({
      date:{
        start: currentYearStart
      }  
    });
    //Previuos year
    queries.push({
      date:{
        start: moment(moment().subtract(1,'year')).startOf('year').format('YYYY-MM-DD')
        ,end: moment(moment().subtract(1,'year')).endOf('year').format('YYYY-MM-DD')
      }  
    });
    //All prior
    queries.push({
      date:{          
        end: moment(moment().subtract(2,'year')).endOf('year').format('YYYY-MM-DD')
      }  
    });

    //Declare the final returned data structure from the API
    // mergedResult.current = {};
    let mergedResult = {};

    // console.log(`${_apiParams.body.graph} Queries: `,queries);
    //Send the queries to the API:
    let apiQueryPromises = [];
    for(const query_ of queries){
      
      //Check if the query results already exists in the cache
      let cacheKey = `${_queryName}_${query_.date.end}`;
      let cachedObject = null;
      if(ENABLE_CACHING && dbInstance){
        cachedObject = await getDBWithExpiration(cacheKey);
        // console.log("Cache return: ",cacheKey,cachedObject);
      }

      if(cachedObject){
        //  console.log("Already in cache: ",cacheKey,cachedObject);
        //Load the data into the merged result
        await mergeResults(mergedResult,cachedObject);
      }else{
        //Not found in cache so query from the API:
        //configure the api query
        _apiParams.body.filter.date = query_.date;
        // console.log("Pass query: ",_apiParams.body,query_);
        // console.log("Query: ",cacheKey);
        apiQueryPromises.push(API.post(_apiName, _apiPath, _apiParams));
      }
    }; //end loop over the queries
    //Wait for all queries to return
    let queriesReturned = Promise.all([...apiQueryPromises]);

    
    queriesReturned.then(_returnData=>{
      for(const data_ of _returnData){
        try {
          //Data return:
          // - alerts:[]
          // - byHour:{}
          // - byType:{type:[]}
          // - data: []
          
          //Save the results of the non-current query to the local storage:
          if(data_.result.date && data_.result.date && data_.result.date.end){ //only the previous fetches have an end date
            if(ENABLE_CACHING && dbInstance){
              let cacheKey = `${_queryName}_${data_.result.date.end}`;
              // console.log("Old data needs to be cached: ",cacheKey)
              //cache the data from the previous years:
              setDBWithExpiration(cacheKey,data_.result,CACHE_EXPIRATION_TIME);
            }
          }
  
          //Merge the returned data into a single data object
          mergeResults(mergedResult,data_.result);
          // console.log("Queries returned: ",data_.result.data.length,data_);
        } catch (error) {
          console.log("Failed on processing return: ",error,data_);
        }
      }//end loop over query returns
    
      //Completed merge
      // console.log("Merged: ",mergedResult);
      // for(const result_ of mergeResults)
      _callback(mergedResult); //send the merged data back to the caller
    });
    queriesReturned.catch(error=>{console.log("Failed to query: ",_queryName,error);});
  
  };

  const queryAPI = async (_queryName, _apiName, _apiPath, _apiParams,_callback) => {

    // console.log("New query:" ,_queryName,_apiParams.body, dbInstance)
    //Check if the query results already exists in the cache
    let apiQueryPromises = [];
    let cacheKey = _queryName;
    let cachedObject = null;
    if(ENABLE_CACHING && dbInstance){
      cachedObject = await getDBWithExpiration(cacheKey);
      // console.log("Cache return: ",cachedObject);
    }
    if(cachedObject){
      //  console.log("Already in cache: ",cacheKey,cachedObject);
      //Return the cached API return
      _callback(cachedObject); //send the merged data back to the caller
    }else{//Send the queries to the API:
      // console.log("Not found in cache?: ",cacheKey);
      //Not found in cache so query from the API:
      //configure the api query      
      // console.log("Pass query: ",_apiParams.body,query_);
      apiQueryPromises.push(API.post(_apiName, _apiPath, _apiParams));
    }
    //Wait for all queries to return
    let queriesReturned = Promise.all([...apiQueryPromises]);
    queriesReturned.then(_returnData=>{
        try {
          // console.log("Returned: ",_queryName,_returnData);
          //Data return:
          // - alerts:[]
          // - byHour:{}
          // - byType:{type:[]}
          // - data: []
          
          //Save the results of the non-current query to the local storage:
          if(ENABLE_CACHING && dbInstance){
            // console.log("Old data needs to be cached: ",cacheKey)
            //cache the data from the previous years:
            setDBWithExpiration(cacheKey,_returnData[0],CACHE_EXPIRATION_TIME_SHORT);
          }
  
          _callback(_returnData[0]); //send the merged data back to the caller
          // console.log("Queries returned: ",data_.result.data.length,data_);
        } catch (error) {
          console.log("Failed on processing return: ",error,_returnData);
        }
    });//end then handler
    queriesReturned.catch(error=>{console.log("Failed to query: ",_queryName,error);});
  };

  return {
    queryAPI_datecached,
    queryAPI    
  };


}