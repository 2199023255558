import React from 'react';
import './App.css';
import '../Filters/filters.css'

import { App} from './App.js';
import { filterAssetSet } from '../Util-asset.js';

import { RegisteredLogin } from '../Authentication/RegisteredLogin.js';


/**
 * Wait for the filter and group config to load
 */
export const AppWrap = ({...props}) =>{

  const [possible_filters, setPossibleFilters] = React.useState(props.possibleFilters);
  const [group_config, setGroupConfig] = React.useState(props.groupconfig);

  React.useEffect(()=>{
    // console.log("Update filters:" ,props.possibleFilters);
    applyNameFilters(group_config,props.possibleFilters);
  },[props.possibleFilters]) //
  React.useEffect(()=>{
    // console.log("Group config updated:" ,props.groupconfig)
    applyNameFilters(props.groupconfig,possible_filters);  
    // console.log("Update the config: ",JSON.parse(JSON.stringify(props.groupconfig))) ;
    setGroupConfig(props.groupconfig?{...props.groupconfig}:null);
  },[props.groupconfig]) //

  /**
   * Apply the name aliases to the list of Trifecta Assets
   * @param {*} groupconfig 
   * @param {*} possibleFilters 
   * @returns 
   */
  const applyNameFilters=(groupconfig,possibleFilters)=>{
      //Only apply the name filtering if the possibleFilters and groupC onfig are loaded
      if(!groupconfig.bLoaded || !possibleFilters.bLoaded){
        setPossibleFilters(possibleFilters?{...possibleFilters}:null); //clear the possible filters if a null value is input
        return null;
      }
      // console.log("Both filters set: ")
    //  //Apply a filter to the assets
      let filteredAssets = filterAssetSet(possibleFilters.AssetsTrifecta,{startDate:null, endDate:null},null, groupconfig);      
      if(filteredAssets){
        possibleFilters.AssetsTrifecta = filteredAssets;
      }else{
        console.log("No return",filteredAssets);
      }
      possibleFilters.AssetList = mergeAssetSets(possibleFilters);
      // console.log("Set the filters:",possibleFilters);
        
        // setTimeout(() => { 
        //   setPossibleFilters(possibleFilters?{...possibleFilters}:null);  
        //   }, 5000); //Delay for debug testing

      setPossibleFilters(possibleFilters?{...possibleFilters}:null);  
      // ;
      
  }
  /**
   * Merge the two sets of assets together into a single list (all entries are an object)
   * @param {*} possibleFilters 
   */
  const mergeAssetSets=(possibleFilters)=>{

    // console.log("Merge the asset: ",possibleFilters)
    let assetList = [];                    
    if(possibleFilters.AssetsTrifecta){assetList.push(...possibleFilters.AssetsTrifecta)}
    //Iterate over the Assets (name only and don't allow a duplicate entry)
    for (const asset_ of (possibleFilters.Assets||[])){
      //Check if the assets is already in the list:
      let findReturn =  (assetList||[]).findIndex(elem_=>{return elem_.asset === asset_ || elem_.asset === asset_.asset})
      if(findReturn<0)//Not Found
      {
        //Add to the list:
        assetList.push({ asset:asset_ })
      }
    }//end for loop interations
    (assetList||[]).forEach(asset_=>{ if(asset_.site){  asset_.current_site = asset_.site;  }
    });


    // console.log("merged filters: ",possibleFilters,assetList);
    return assetList;
  }

  const render= ()=>{
    //Return the HTML code to display:
    return (
      <RegisteredLogin
          {...props}
          child={<App
                  linkDataExtra={props.linkDataExtra}
                  navEvents={props.registerNavEventListener}
                  eventNotify={props.navEvent}
                  messageReceived={props.messageReceived}
                  cardChange={props.addVideoCardChange}
                  mainView={props.mainView} 
                  userInfo={props.userInfo}
                  filter={props.filter}  
                  defaultFilter={props.filter}
                  saving={props.saving}
                  possibleFilters={possible_filters}
                  groupconfig={group_config}
                  authState = {props.authedState}
                  username = {props.username}
                  popup = {props.popup}
              />}
      />
        
    );
  };//end of render()

  
  // console.log("Loaded?:" ,props);
  try {
    if(props.loadingComplete){ //with loading animation wrapper support
      let loadingStateUpdate = false;
      //Identify the loaded condition:
      if(group_config.bLoaded && possible_filters.bLoaded ){
        // console.log("Set true on load: ",group_config.bLoaded, possible_filters.bLoaded);
        loadingStateUpdate = true;
      }
      //Handle the reset of the loading animation when the user logs out
      if(props.authedState==='signedOut'){
        loadingStateUpdate = false;
      }
      //Trigger the update to the withLoadingAnimation HOC
      if(props.loadingComplete){props.loadingComplete(loadingStateUpdate);}

      // console.log("hideRender: ",props.hideRender);
      //Hide until complete
      return (
          props.hideRender?
            <div >Loading user data</div>
            :
            render()
      )
    }else{ //without loading animation wrapper support
      return (group_config.bLoaded && possible_filters.bLoaded )? render() : (<div className='box mainApp'>Waiting on config</div>);  
    }
    
  } catch (error) {
    console.log("Error: ",error);
    return (<div></div>);
  }
  

}; //end of wrapped APP call

