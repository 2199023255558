import React, { Component, useState, useEffect} from 'react';
import './TrainDrivers.css';

import { useCallback } from 'react';
import { useDropzone} from 'react-dropzone';
import { TrainControl } from './TrainControl';


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const TrainDrivers = ({ 
                            groupconfig
                            ,userInfo
                            ,possibleFilters
                            ,...rest
                          }) => {

  const [imgPreview, setImgPreview] = useState(null);  

  const { getRootProps, getInputProps, isDragActive,acceptedFiles  } = useDropzone({
    // onDrop:(data=>{console.log("Dropped: ",data)}),
    // onDrop:onDropCall,
    // onDrop
    // maxFiles:1
  });

  // const files = acceptedFiles.map((file) => (
  //   console.log("Files: ",file) 
  //   // <li key={file.path}>
  //   //   {file.path} - {file.size} bytes
  //   // </li>
  // ));

  function onDrop(_acceptedFiles){
  // const onDropCall =(acceptedFiles)=>{
    const file = new FileReader;
    //Define what happens when the file load completes
    file.onload = function() {
      console.log("Load ended")
      setImgPreview(file.result);
    }
    //Load the file into the file reader
    file.readAsDataURL(acceptedFiles[0])

    console.log("Returned: ",_acceptedFiles);
  }

  useEffect(()=>{
    console.log("Files uploaded: ",acceptedFiles);
    if(acceptedFiles.length===0){return;}
    const file = new FileReader;
    //Define what happens when the file load completes
    file.onload = function() {
      console.log("File: ",file);
      setImgPreview(file.result);
    }
    //Load the file into the file reader
    file.readAsDataURL(acceptedFiles[0])
  },[acceptedFiles])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  /**
   * Layout the render, this is executed everytime the state changes
   * Logic and computations should be avoided in this loop
   */
  const render= ()=>{
    return (
      <div className='train-drivers'>
        <div className='title'>Train Drivers</div>
        <div className='train-drivers-layout'>
            
            {imgPreview?
              <p><img src={imgPreview} alt="Upload preview" /></p>
              :
              <div className='upload'>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Drop the file here ...</p> :
                      <p>Drag 'n' drop the Driver photo here, or click to select the file</p>
                  }
                </div>
              </div>
            }
            
            <div className='content'>
              <TrainControl groupconfig={groupconfig}
                            userInfo={userInfo}
                            possibleFilters={possibleFilters}
                            imgData={imgPreview}
                />
            </div>
        </div> 
      </div>
      
    );
  }
  //Function component is expecting the return, add return to the render() call
  return render();
  
}
