
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';
import { Auth, API } from 'aws-amplify';
import { generateUniqueID } from './Util.js';
import './GeofenceGroupSettings.css';
import './SpeedPolicySettings.css';

import { Dropdownlist } from './Wrappers/Dropdownlist';


import IconAdd from './assets/add.png'
import helpIcon from './assets/help.svg'
import helpImg  from './assets/speed-policy.PNG';
import EditableLabel from 'react-inline-editing';

// this just displays a dash instead of empty cells when there's no data
const actionTypeFormatter = (thing, row, rowIndex, extraData) => {
    if (thing === true ) {
        return <span>&#10003;</span>;        
    }else{
        return <span>&mdash;</span>;        
    }
}

/*
* @brief Modal popup to configure the setting for the geofence grou
*/
export class SpeedPolicySettings extends PureComponent {
    constructor(props) {
        super(props);
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
        this.evalSpeedConflict = this.evalSpeedConflict.bind(this);

        this.testEscalationRules = this.testEscalationRules.bind(this);

        this.state = {
            shown: true,
            group:null,
            groupList:  this.props.groupList,
            prevAssigned: null,
            speedpolicies:[],
            groupid:null,
            showHelpPopup:false,
            groupName: this.props.group,
            mGroupConflict: 0,
            mPolicyConfict: 0,
            mPolicyConfictDetail: [],
        }
    }

    testEscalationRules(_policies){
        // console.log("Test rules: ",_policies);
        let bConflictFound = false;
        let conflicts = [];
        

        //need to sort the policies based on category:        
        let sortedPolicies = {};
        (_policies || []).forEach(policy_=>{
            // console.log("Cats: ",policy_.label)
            if(sortedPolicies[policy_.label]){
                // console.log("Found double")
                this.setState({mPolicyConfict:4}); bConflictFound=true;
                conflicts.push({category:policy_.label,type:'category'});
            }else{
                sortedPolicies[policy_.label] = policy_;
            }
        });
        // //First eval if we have issues with the categories
         if(bConflictFound){
             this.setState({mPolicyConfictDetail:conflicts});
             return bConflictFound;
         }

        for(const [idx_,policy_] of Object.entries(sortedPolicies)){
            // console.log("Cat: ",idx_,policy_);
            try {
                if(sortedPolicies[idx_-1]){
                    let currentSpeed = parseInt(sortedPolicies[idx_].deltaspeed,10);
                    let compareSpeed = parseInt(sortedPolicies[idx_-1].deltaspeed,10);
                    // console.log("CMP: ",currentSpeed , compareSpeed)
                    if( currentSpeed <= compareSpeed ){
                        this.setState({mPolicyConfict:3}); bConflictFound=true;
                        conflicts.push({category:idx_,type:'speeddelta'});
                    }    
                }else{
                    // console.log("no policy for ",idx_-1,sortedPolicies[idx_-1] )
                }
            } catch (error) {
                console.log("Fail: ",error);
            }
           
        }
        //First eval if we have issues with the categories
        // if(bConflictFound){
        this.setState({mPolicyConfictDetail:conflicts});         
        // }
        return bConflictFound;
    }

    evalSpeedConflict(){
        let bConflictFound = false;
        let conflicts = [];

        //Get the unique set names:
        let vehicleType = new Set();
        (this.state.speedpolicies || []).forEach(policy_=>{
            vehicleType.add(policy_.type);
        })
        //Split into groups based on the types:
        for(const type_ of [...vehicleType]){
            console.log("Types: ",type_);
            let policies = (this.state.speedpolicies || []).filter(policy_=>{return type_===policy_.type})
            bConflictFound = this.testEscalationRules(policies);
        }


        
        
        
        // console.log("Policies: ",this.state.speedpolicies,sortedPolicies,sortedPolicies[1],sortedPolicies[1].time);
        //compare the policy the value beneath:
        
        



        (this.state.speedpolicies || []).forEach(policy_=>{
            // console.log("Policy: ",policy_,this.state.mPolicyConfict);
            if(!policy_.label){console.log("no label");this.setState({mPolicyConfict:1}); bConflictFound=true;}
            if(!policy_.deltaspeed){console.log("no speed");this.setState({mPolicyConfict:2}); bConflictFound=true;}
            
        })
        //If no conflicts then reset the policy flag
        if(!bConflictFound){
            this.setState({mPolicyConfict:0}); 
        }
        // this.setState({mPolicyConfictDetail:conflicts});
        return bConflictFound;
    }
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        // console.log("Called submit",this.state,this.props);
        //Confirm all policies have  the need category and speed values
        if(this.evalSpeedConflict()){
            return;
        }

        let policyToSubmit = (this.state.speedpolicies || []).filter(elem_=> elem_.deltaspeed);    
        //console.log("Policy to Submit: ",policyToSubmit)

        switch(this.props.type){
            case 'site':{
                const regionPromise = Auth.currentSession().then(
                    (auth) => {
                        let apiName = "TrifectaAPI";
                        let path = "/handleSites";
                        let myInit = {
                            body: {
                                token: auth.idToken.jwtToken,
                                mode: "editspeedpolicy",
                                groupname: this.props.group,
                                site: this.props.site,                                
                                speedpolicy: JSON.stringify(policyToSubmit),
                            }
                        };                        
                        return API.post(apiName, path, myInit);
                     })
                    .catch((error) => {
                     console.error("Failed on call to set group update; ",error); 
                    })
                    .then((data) => {            
                        this.props.handleClose({policy: policyToSubmit});
                    });    
            }break;
            default:{
                console.log("Send update settings: ",this.state.groupName);
                const regionPromise = Auth.currentSession().then(
                    (auth) => {
                        let myInit = {
                            body: {
                                token: auth.idToken.jwtToken,
                                apiName: "handleGeoFence",
                                mode: "setGroupSettings",
                                groupname: this.state.groupName,
                                speedpolicies: JSON.stringify(policyToSubmit),
                                id: this.state.groupid,
                                previousgroup: this.props.group,
                            }
                        };
                        return API.post("AuthLambda", "/apiRouter", myInit);
                     })
                    .catch((error) => {
                     console.error("Failed on call to set group update; ",error); 
                    })
                    .then((data) => {            
                        this.props.handleClose({priorName:this.props.group, newName: this.state.groupName});
                    });    
            }break;
        }


        
        
    }//end handleSubmit

    handleFocusOut(text) {
        // console.log("Title Changed: ",text);
        let groupName = this.state.groupName;

        try {
            if(this.state.groupName!==text){ //Were changes made
                console.log("Changed name:" ,text);
                //Check if the values is in the list of known sites:
                 if(this.props.groupList.includes(text.toLowerCase())){
                      console.log("Found in list");
                      this.setState({mGroupConflict:1}); //Indicate that we are in conflict on the site name
                      return;
                 }
                 if(text.length > 1){
                     // console.log("Setting new title: ",text);
                     this.setState({groupName:text,mRegionConflict:0});            
                 }else{
                    this.setState({groupName:this.state.regionName,mGroupConflict:0});
                 }
            }
            this.setState({mGroupConflict:0}); // reset the warning message
        } catch (error) {
        }
    }

    componentDidMount(){
        //get the data for the group:
        let apiPromise = this.getApiCall();
        apiPromise.then(data => {
            this.updateData(data);
        });
    }

     /*
    * @brief The definition of the API call that we need to do to display this list
    */
     getApiCall() {
        // console.log("Get Group details: ",this.props);
        //define the list of allowed sites to return:
        switch(this.props.type){
            case 'site':{
                console.log("Query for the site ",this.props);

                this.setState({speedpolicies:this.props.speedpolicies||[]},()=>{this.evalSpeedConflict()});

                return Promise.resolve();
            }break;
            default:{
                const regionPromise = Auth.currentSession().then(
                    (auth) => {
                        let myInit = {
                            body: {
                                token: auth.idToken.jwtToken,
                                apiName: "handleGeoFence",
                                mode: "fetchGroupSettings",
                                groupname: this.props.group,                                
                            }
                        };
                        return API.post("AuthLambda", "/apiRouter", myInit);
                     })
                     .catch((error) => {
                        console.error("Failed on call to get regions; ",error); 
                      }); 
                     return regionPromise;        
            }break;
        }
 
        
    }
  
   
    /*
    * @brief Takes care of updating the list with new data when we receive it
    */
    updateData(data) {
        console.log("Update Data: ",data);
        try {
            //did we get data about the group
            if(data.groups[0]){
                let speedpolicies=[];
                try {
                    speedpolicies = data.groups[0].speedpolicies.map(zone_=>{
                        //make sure the type is set
                        if(!zone_.type){zone_.type = "Heavy";}
                        return zone_;
                    })   
                } catch (error) { console.log("Error on zone map: ",error)  }
                 
                // console.log("Update zonees:" ,speedpolicies);
                this.setState({speedpolicies:speedpolicies,groupid:data.groups[0].id},()=>{this.evalSpeedConflict()})
            }else{ //no settings configured for this group:
                this.setState({groupid:generateUniqueID()}) //set a new unique id to insert into the SQL table

            }


        } catch (error) {console.log("error on update: ",error)}
    }

     /*
    * @brief Render the content of the card:
    */
     render() {
        // console.log("Render assign: ",this.props, this.state.prevAssigned);

        const style = {"--policyCount":0 };
           
        
        const speedpolicies = [];
        if (this.state.speedpolicies && this.state.speedpolicies.length>0) {
            
            style["--policyCount"] = this.state.speedpolicies.length; //resize the modal box to fit a longer message
            
            (this.state.speedpolicies||[]).forEach(speedpolicy_ => {
                speedpolicies.push(<SpeedpolicyDisplay      data={speedpolicy_} 
                                                            conflicts={this.state.mPolicyConfictDetail}
                                                            onDelete={(_data)=>{
                                                                // console.log("Delete: ",_data)
                                                                this.setState({speedpolicies: this.state.speedpolicies.filter(zone_=>{return zone_.zoneid===_data?false:true})})
                                                            }} //remove a member from the list of zones
                                                            onEdit={(_data)=>{
                                                                try {
                                                                    //look through the zones
                                                                    let updateZones = ([...this.state.speedpolicies]||[]).map(zone_=>{
                                                                        //Edit the selected zone:
                                                                        if(zone_.zoneid === _data.zoneid){
                                                                            zone_[_data.type] = _data.value; //update the edited value
                                                                        }
                                                                        return zone_;
                                                                    })
                                                                    this.setState({speedpolicies:updateZones}, ()=>{this.evalSpeedConflict()})
                                                                } catch (error) {}
                                                        

                                                    }}//callback when the value is changed in the field
                                />);
            });    
        }
        //console.log("Set style:" ,style);

        let titleText = 'Configure group settings for: ';//+this.props.group;
        if(this.props.type ==='site'){
            titleText = 'Site speed policy for '+this.props.site
        }

        // console.log("Eval conflicts: ",this.state.mPolicyConfict)
        let warningDiv = <div></div>;
        if(this.state.mGroupConflict>0){
            if(this.state.mGroupConflict===1){ warningDiv = <div className = 'sc-title-warning'>Duplicate group name, please change</div>}
        }else{
            if(this.state.mPolicyConfict===1){ warningDiv = <div className = 'sc-title-warning'>Please set the policy category</div>}
            if(this.state.mPolicyConfict===2){ warningDiv = <div className = 'sc-title-warning'>Please set the policy speed delta</div>}
            if(this.state.mPolicyConfict===3){ warningDiv = <div className = 'sc-title-warning'>Delta speed must be greater than previous category </div>}
            if(this.state.mPolicyConfict===4){ warningDiv = <div className = 'sc-title-warning'>Please select unique policy category numbers </div>}
        }
        

        return (
            <div className="geofence-group-settings" >
                <ReactModal isOpen={this.state.shown} className="modal-dialog geofence-group-settings-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{ 
                                this.props.handleClose()
                            }  }
                >
                    <div className="geofence-group-settings-content" style={style} >
                        <div className = 'group-header'>
                            
                            <img className='helpIcon' src={helpIcon} onClick={()=>{
                                console.log("Click on help");
                                this.setState({ showHelpPopup: true});
                            }}/>
                            {this.props.type ==='site'?
                            <div className = "geoTitle2">{titleText}</div>
                            :
                            <div className='stackedTitle'>
                                <div >{titleText}</div>
                                <EditableLabel 
                                    text={this.state.groupName}
                                    labelClassName='groupEditTitle'
                                    inputClassName='groupEditTitle'
                                    inputWidth='100%'
                                    inputHeight='auto'
                                    inputMaxLength={50}
                                    labelFontWeight='bold'
                                    inputFontWeight='bold'
                                    // onFocus={this._handleFocus}
                                    onFocusOut={this.handleFocusOut}
                                /> 
                            </div>
                            
                            }
                        </div>
                        
                        <div className = 'speedpolicyTitle'>
                            <div className = 'title'/>
                            <div className = 'title'>Category</div>
                            <div className = 'title'>Vehicle Type</div>
                            <div className = 'title'>Speed Delta</div>
                            <div className = 'title'>Time Threshold (s)</div>

                        </div>
                        {speedpolicies}
                        <div className='speedpolicyFooter'>
                            {(this.state.speedpolicies && this.state.speedpolicies.length < 6) &&
                                    <img src={IconAdd} className="addZone" onClick={(e)=>{
                                        
                                        this.setState({speedpolicies:[...this.state.speedpolicies,
                                            {//add an empty to the end of the list
                                                zoneid:generateUniqueID(),
                                                type:"Heavy"//set default type to heavy
                                            }
                                        ]},()=>{this.evalSpeedConflict()}); 
                                    }}/> 

                            }
                        </div>

                        <div className="gfs-footer">
                            <div className="adding-buttons">
                                <button className="btn btn-danger" 
                                        onClick={ ()=>{this.props.handleClose({policy: this.state.speedpolicies})}}
                                >Cancel</button>

                                
                                {warningDiv}

                                {(this.state.mGroupConflict===0 && this.state.mPolicyConfict===0) ?
                                    <button className="btn btn-primary" 
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                    :<button className="btn btn-secondary" disabled
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                }
                                
                            </div>
                        </div>
                        
                    </div>
                </ReactModal>
                { this.state.showHelpPopup &&
                    <HelpPopup 
                        handleClose={()=>{this.setState({showHelpPopup:false})}}   
                        groupconfig = {this.props.groupconfig}
                    />
                }
            </div>
        );
    }
}//end of SpeedPolicySettings 

/*
* @brief Modal popup to show the help img
*/
class HelpPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }
     /*
    * @brief Render the content of the card:
    */
     render() {
        return (
            <div className="gfs-help-popup" >
                <ReactModal isOpen={this.state.shown} className="modal-dialog gfs-help-popup-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{
                                this.props.handleClose()
                            }  }
                >
                    <div className="gfs-help-popup-content" >
                        <img className="helpimg" src={helpImg} />
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end of UserRoleHelpPopup 


/*
* @brief Display the values for the speed zone (handles the renderig and callback triggeres per row)
*/
class SpeedpolicyDisplay extends PureComponent {
    
    
    render() {
        //  console.log("Props: ",this);
        // <input type="text" className="entry-field" value={this.props.data.label}  placeholder="Breach Category"
        //         onChange={(e) => {this.props.onEdit({type:"label", zoneid:this.props.data.zoneid, value:e.target.value})}}
        //     />


      let speedClassName = 'speeddelta';  
      let timeClassName = 'timeentry';  
      let catClassName = 'categorydrop dropDown';  
      for(const conflict_ of this.props.conflicts){
        console.log("Eval conflict: ",conflict_);
        if(this.props.data.label === conflict_.category){
            //  console.log("Conflict found")
            // if(conflict_.type === 'speeddelta'){speedClassName += ' conflict'; console.log("update speed name");}
            switch(conflict_.type){
                case 'speeddelta':{speedClassName += ' conflict';  break}
                case 'time':{timeClassName += ' conflict';  break}
                case 'category':{catClassName += ' conflict';  break}
                default: {console.log("No match: ",conflict_.type);break}
            }
        }
      }
      

      return (
        <div className="speedpolicy-display">
            <div></div>
            
            <Dropdownlist   className = {catClassName}
                            defaultValue={this.props.data.label}
                            data={["1", "2","3"]}
                            placeholder="Select"
                            onChange={(value) => { this.props.onEdit({type:"label", zoneid:this.props.data.zoneid, value:value?value.value:null})}} 
            />
            
            <Dropdownlist   className = "dropDown" 
                            defaultValue={this.props.data.type}
                            data={["Heavy", "Light"]}
                            onChange={(value) => {this.props.onEdit({type:"type", zoneid:this.props.data.zoneid, value:value?value.value:null})}} 
            />
            <div className={speedClassName}>
                <input type="text" className="entry-field" value={this.props.data.deltaspeed}  placeholder="Delta Speed"                
                    onChange={(e) => {
                        if(e.target.value){
                            // console.log("Value: ",e.target.value);
                            if(!Number(e.target.value)  ){ 
                                if(e.target.value!=='0'||e.target.value!==0){ // console.log("not a number: ",e.target.value); 
                                 }else{
                                    return;
                                }
                            } //reject characters only accept numbers
                        }
                        
                        this.props.onEdit({type:"deltaspeed", zoneid:this.props.data.zoneid, value:e.target.value})} //send the updated value back to the calling class
                    }
                />
            </div>
            
            <div className={timeClassName}>
                <input type="text" className="entry-field" value={this.props.data.time}  placeholder="Time Threshold"                
                    onChange={(e) => {
                        if(!Number(e.target.value)  ){ 
                            if(e.target.value!=='0'||e.target.value!==0){ // console.log("not a number: ",e.target.value); 
                             }else{
                                return;
                            }
                        } //reject characters only accept numbers
                        this.props.onEdit({type:"time", zoneid:this.props.data.zoneid, value:e.target.value})} //send the updated value back to the calling class
                    }
                />
            </div>
            
            <button type="button" className="deleteRow" 
                onClick={(e)=>{
                    if(window.confirm("Please confirm that you wish to delete this speed zone")){
                        this.props.onDelete(this.props.data.zoneid)
                    }
                }}>
                X
            </button>
        </div>
      );
    }
  }
