import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import './StoryLineCall.css';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {siteFormatter,notecardFormatter,noteFormatter,dateFormatter} from '../Callins/CallinsUtility.js'
import { Spinner } from '../ApiCaller';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import { Card } from '../VideoCard.js';

import moment from 'moment';
import { Auth, API } from 'aws-amplify';

/*
* @brief 
*/
class StoryLineCall extends PureComponent {
    
  constructor(props) {
      super(props);

      this.expandCard = this.expandCard.bind(this);

      this.state = {
          shown: true,
          mTableHeaders:[],
          expandedCard: null,
      };
  }

  componentDidMount(){
    // console.log("StoryCall mount: ",this.props);
    try{

      let cols = [
        {dataField: 'cardData', text: 'Notecard',editable:false,sort:true,formatter: notecardFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'subject', text: 'Subject',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'asset', text: 'Asset',editable:false, sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},            
        {dataField: 'username', text: 'EDGE3 Person',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'contactname', text: 'Contact',editable:false,sort:true,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'site', text: 'Site',editable:false,sort:true,formatter: siteFormatter,formatExtraData:this.props.possibleFilters,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},      
        {dataField: 'timeofday', text: 'Notecard Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'calltime', text: 'Call Time',editable:false,sort:true,formatter: dateFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
        {dataField: 'callnotes', text: 'Notes',editable:false,sort:true,formatter: noteFormatter,headerStyle: () => {return { whiteSpace:"center",textAlign:"center"};}},
      ];

      //Data:

      this.props.set.forEach(row=>{
        
          //Create the notecard data
          if(row.timeofday){
            row.cardData = <Card  
              infractionID={row.infractionid}
              name={(row.name || row.driverid || "DriverID: Unavailable").replace('_', ' ')}
              timeofday={row.timeofday}
              severity={row.severity}
              notes={row.notes}
              vehicleID = {row.asset}
              metadata ={row.metadata}
              photo={row.thumbnail}
              status={row.status}
              infractionTags={row.infractiontags.split(',')}
              tag={row.tag}
              groupconfig={this.props.groupconfig}   
              onDoubleClick={this.expandCard}                      
            />  
          }
      });


      let tableData = this.props.set;

      // console.log("Load call table: ",this.props.set);


      this.setState({mTableHeaders:cols,mTableData: tableData})
    } catch (error) {
      console.log("Failed to process in mount: ",error);
    }
  }

  expandCard(_inCard) {
    // console.log("Expand: ",_inCard)
    //Check if the card was already loaded
    let infractionid = _inCard.infractionID || _inCard.infractionid;
    let preloadedCard = null;
    try {
      preloadedCard = this.props.cardsByInfractionid[infractionid];
    } catch (error) {
      
    }

    if (preloadedCard) {
        this.setState({expandedCard: preloadedCard}); //pass the object to be viewed
      return; //early return, card was found
    }
      
    // console.log("Expand click: ",_inCard);
   
    const realPromise = Auth.currentSession().then(
      (auth) => {
          let myInit = {
          body: {
              token: auth.idToken.jwtToken,
              apiName: "getCardFromInfractionID",
              mode: "fetch",
              infractionid: infractionid,
          }
          };
          return API.post("AuthLambda", "/apiRouter", myInit);
      });
      //When the data is returned arrange it into a basic card and open the card:
      realPromise.then(data => {
          // console.log("Notecard fetch returned: ",data,data.data);
          (data.data || []).forEach( (elem_) =>{
              // console.log("Notecard fetch returned: ",elem_);
              let infractionTagArray = elem_.infractiontags || [];
              if(elem_.infractiontags ){
                  infractionTagArray = elem_.infractiontags.split(",");
              }
              // console.log("Open card: ",elem_)
              const card = {
                  tag: elem_.tag,
                  infractionType: elem_.infractiontype,
                  infractionID: _inCard.infractionID || _inCard.infarctionid,
                  status: 'FleetReview',
                  severity: elem_.severity,
                  // timeReceived: moment(this.props.parentVideo.received),
                  // timeCreated: moment(),
                  cardID: elem_.videocardid,
                  id: elem_.parentid,
                  flags: elem_.flags,
                  vehicleID: _inCard.vehicleID,
                  siteID: elem_.siteid,
                  notes: "",    
                  name: (elem_.name || elem_.driverid || "DriverID: Unavailable").replace('_', ' '),
                  // name: elem_.driverid,
                  infractionTags : infractionTagArray,
                  timeReceived: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                  timeOfDay: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                };
              this.setState({expandedCard:card});
          });
      });
}

  /*
  * @brief Render a table of call data
  */
  render() {

    let tableColumns = this.state.mTableHeaders;
    let tableData = this.state.mTableData;

    let scale = 1;
    if (this.state.winWidth && this.state.divWidth) {
        scale = this.state.winWidth / this.state.divWidth;
    }


    const rowClasses = (row, rowIndex) => { return 'storycall-row';  };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // console.log("Clicked on row:" ,row);
        return;
      }
    };
    /*
      Set style for the rows in the table     
    */
    const rowStyle = (row, rowIndex) => {
      let rowcolor = '#00afed05' 
      if(rowIndex%2 ===0){    rowcolor = '#00afed20'   }
      return{   backgroundColor: rowcolor, }
    };
    //  console.log("Props to render: ",this.props, this.state);
    if(!tableData){return <div className="story" >
      <Spinner></Spinner>
    </div>}

    return(
      <ReactModal isOpen={this.state.shown} className="modal-dialog storycall-modal"
                          shouldCloseOnOverlayClick={true} ariaHideApp={false}
                          onRequestClose={ ()=>{
                            // console.log("Requested closed")
                            this.props.handleClose({
                                                    driverid: this.props.driverid,
                                                    notes:this.state.comments
                                                  })
                          }}
      >
        <div className="storycall-modal-content" > 
      
          <BootstrapTable keyField='key' // a react specific thing that sets the 'key' for each row in the table
                                                // react uses keys to keep track of identity when things change
                            data={tableData} // <-- IMPORTANT: this is the actual data being displayed
                            columns={tableColumns}
                            striped={false} // sets every other row a different shade, makes it easier for the eye to
                            rowStyle={ rowStyle}
                            hover={true}   // sets a hover effect, so the background color of a row changes when the
                                          // mouse is over it. This signals to the user that the row is clickable
                            classes={"callin-table"} // sets a CSS class so we can style this table specifically
                            rowClasses={rowClasses}
                            bootstrap4 = {true}
                            rowEvents={rowEvents} // set what events we want to happen on rows, right now just the click
                            defaultSorted={[
                              {dataField: 'calltime', order: 'asc'}, // how things should be sorted by
                            ]} // default when first displayed
                            
          />

          { this.state.expandedCard &&
                    <ExpandedCard handleClose={()=>{
                                            this.setState({expandedCard:null})                                            
                                            }}
                            {...this.state.expandedCard}
                            cardChange={this.cardChange}
                            tagChange={this.cardTag}
                            eventNotify={this.props.eventNotify}
                            // newDetails= {this.onCardCreate}
                            scale={scale}   
                            noLink={this.props.groupconfig.group.toLowerCase().includes("bis")?false:true}                     
                            // noEdit={disableEdits}
                            filter={this.props.filter}
                            groupconfig = {this.props.groupconfig}
                            siteDetails = {(this.props.possibleFilters.Sites||[]).filter(elem_ => {return this.state.expandedCard && elem_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}
                    />
                }
        
      </div>
    </ReactModal>
    )
  }
}


export { StoryLineCall };