import React from 'react';
import Select from 'react-select'
import './Dropdownlist.css'

/**
 * Wrap the react-select Select component to allow for simpler intergration into the code stack
 * The input parameters will accept the same format as the react-widgets DropdownList 
 * All changes to make the values compatible will be handle automatically
 * @param className: className to apply to the outer component
 * @param data: Array of options to display in the dropdown
 * @param options: alternative name for the data
 * @param value: the displayed (selected) value on the list
 * @param onChange: the callback function to notify the caller that the selection has changed
 * @param props: All other properties that are passed directly without inspection to the Select
 */
export const Dropdownlist = ({className, classNamePrefix, data, dataKey, value, defaultValue, textField , onChange, options, ...props}) => {

    /**
     * Review the input data set to show in the dropdown menu
     * Make sure that the data is formatted as an array of objects
     * to pass to the Select component
     * @param {*} _data Input array of options to display in the dropdown menu
     * @returns An array of objects to display: [{value: , label: },{},...]
     */
    function checkInputData(_data,_which){
        // console.log("Check: ",_data,_which);
        if(!_data){return undefined;} //must be undefined, this will prevent the value property from loading on the Select component
        try {
            let optionsToDisplay = [];
            //Check if the input data in formatted as an array
            if(!Array.isArray(_data)){
                //Not an array, handle based on what type of data was received
                switch(typeof(_data)){
                    case 'object':{
                        optionsToDisplay= [_data]; //wrap an object into an array
                    }break;
                    case 'string':{
                        optionsToDisplay= [{value:_data, label:_data}] //add a string in to an object array
                    }
                    default:{
                        optionsToDisplay= [{value:_data, label:`${_data}`}] //convert the data to a string type
                    }break;
                }//end switch
                // console.log("converted input return: ",optionsToDisplay, _which);
                return optionsToDisplay;
            }else{
                //The input data needs to be formatted as an array of objects
                //Check the input data and convert the values to objects if not set
                optionsToDisplay = (_data||[]).map((elem_)=>{

                    try {
                        //convert to an object
                        if(typeof(elem_) !== 'object'){
                            // console.log("Not object? ",elem_, typeof(elem_))
                            return{value: elem_,label: elem_};
                        }
                        //Already an object: make sure the key/value pairs are correct
                        let foundValue = elem_.value;
                        let foundLabel = elem_.label;
                        // console.log("Default: ",{value:foundValue, label:foundLabel})
                        //If the dataKey is defined, then use this to identify the value
                        if(dataKey){foundValue = elem_[dataKey];}
                        //If the textFiled is defined, then use this to identify the value
                        if(textField){foundLabel = elem_[textField];}
                        //Return the values
                        // console.log("Updated the return: ",{value:foundValue, label:foundLabel})
                        return Object.assign(elem_,{value:foundValue, label:foundLabel});
                    } catch (error) {
                        console.log("Failed to parse option: ",error, elem_);
                    }

                    
                })//end iterate over the input data array
                // console.log("Display options: ",optionsToDisplay,_which);
                return optionsToDisplay;
            }
        } catch (error) {
            console.log("Failed to type check the input data", error);
            return [];
        }
    }//end checkInputData

    /**
     * Simpler wrapper to organize the code. This contains the rendered elements
     */
    const render=()=>{
        //  console.log("Render:",checkInputData(value,'value'));
        return (
            <Select 
                className={className}
                classNamePrefix={classNamePrefix||'dropdownlist'}
                options={checkInputData((data||options),'options')}
                value={checkInputData(value,'value')}
                defaultValue={checkInputData(defaultValue,'default')}
                onChange={onChange}
                isClearable={props.isClearable||true}
                {...props}
            />
        )
    }
    //actual return of the function component
    return render();
}; //end Dropdownlist