
import React, {  PureComponent } from 'react';
import {getNodePosition} from '../Util.js'

export class VideoClipPlayerBase extends PureComponent {
  constructor(props) {
      super(props);

      this.windowResized = this.windowResized.bind(this);
      this.didMount = this.didMount.bind(this);

      this.vidPlayerClassRef = React.createRef();
      

      this.state = {
          winSize : {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
          },
          hideTimeout:null,
          canvasSet:  null,
          chosenName: this.props.currentClip.chosenName,
      };
      this._isMounted = false;
  }

    /*
  * @brief A handler for the event when the browser window is resized
  * 
  * Used to update the current window width, for scaling the page contents to fit
  */
  windowResized() {
    this.setState({
      winWidth: document.documentElement.clientWidth - 40,
      winSize : {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight
      }
    })
  }

  UNSAFE_componentWillMount(){ 
    // console.log("Base mounted?");
  }

  
  didUnMount(){
    // console.log("Unmount triggered in base");
    this._isMounted=false;
  }
  /* method to call from the inherited class, common operations from child's componentDidMount*/
  didMount(){this._isMounted = true;} //end didMount
  
} //end of VideoClipPlayerBase class  
 