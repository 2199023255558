import React from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { isPermissionSet } from '../Util-access';

/*
* @brief Create a simple view with the selection buttons, based on button selected change the called class.
*/
export const FilterSelectionView = ({  groupconfig, onFilterViewSelected,currentSite, assets}) => {

    //Set the state variables
    const [activeView, setView] = React.useState('users'); //Which view is enabled
    const [defaultViews] = React.useState(['Site','Infraction']); //The default views to return to
    const [viewTypes, setViews] = React.useState(['Site','Infraction']); //The currently active view
    const tabViews = React.useRef([{text:'Site',disabled:false,allowed:true},{text:'Infraction',disabled:false,allowed:true},{text:'Journey',disabled:false,allowed:false}])

    //The filters changed, update the filter set with the view
    React.useEffect(() => { onFilterViewSelected({view: activeView});    }, [activeView]);

    // React.useEffect(() => { console.log("Tab Views:" ,tabViews)    }, []);

    
    //Updates based on the changing of the currentSite selection:
    React.useEffect(() => {
        tabViews.current[2].allowed=false; //set the default to no permission on journey view
        tabViews.current[1].disabled=false; //set default to be able to interact
        tabViews.current[2].disabled=false; //set default to be able to interact
        let viewUpdate = [...defaultViews]; //copy the values, don't assign or the variables will be linked

        //Is a site selected? If not, then disable the infractions and Journeys
        if(!currentSite || currentSite === 'None' ){
            tabViews.current[1].disabled=true;
            tabViews.current[2].disabled=true;
        }
        

        //Check the permissions and assets available to the user:
        //Determine if the Journeys view should be shown:
        //---------------------------------------------------------
        //Check if the current user has permission to view the journeys:
        if(isPermissionSet(groupconfig,"livemap",{journeyview:true})){
            //Add the Journey view if there are assets available at the site:
            tabViews.current[2].allowed=true; //allowed to see the tab
            if(assets.length>0){ viewUpdate.push("Journey"); }
        }//end permission check
        else{  
            viewUpdate = defaultViews;   
        } //if permission aren't set, just include the default views

         //Check the asset set, if no assets are available then disable the infractions or Journey tabs:        
         if(assets.length<=0){ 
            // console.log("Set views to site only");
            tabViews.current[1].disabled=true;
            tabViews.current[2].disabled=true;
            setView('Site'); //Force the tab selection back to the site
            return;
        }

        //If a site isn't selected then reset the view state to the default views
        if(!currentSite || currentSite === 'None' ){
            if(!defaultViews.includes(activeView)){ setView(defaultViews[0]); } //If we are currently on a view that was removed, reset the view to the first view
            return;
        }
        // console.log("Update view:" ,defaultViews,viewUpdate)
        //Fix non available views:
        if(!viewUpdate.includes(activeView)){ setView(defaultViews[0]); } //If we are currently on a view that was removed, reset the view to the first view
        setViews(viewUpdate);

    }, [currentSite,assets]); //end listen for changes to assets list or current site selection

    //Define the returned rendered HTML:
    return (
        <div className='FilterSelection'>
            <Tabs className='viewSelection'
                defaultActiveKey={activeView} unmountOnExit={true} mountOnEnter={true} 
                id='uncontrolled-tab-example' 
                    activeKey={activeView} 
                    onSelect={k => setView(k)}
            >
                {tabViews.current.map((tab_) => {
                    if(!tab_.allowed){return null;}
                    return(  <Tab key={tab_.text} eventKey={tab_.text} title={tab_.text} disabled={tab_.disabled}/> )
                })} 
            </Tabs>
        </div>
    );
};