

var logo_edge3 = require('./assets/e3_logo.png');
var logo_santaclara = require('./assets/santa_clara.png');
var logo_gotech = require('./assets/go_tech.png');
var logo_zonedefense = require('./assets/zone_defense.png');
var logo_bis = require('./assets/bis_logo_ex.png');
var logo_nycta = require('./assets/nycta.png');
var logo_m2m = require('./assets/m2m.png');
var logo_rsclabs = require('./assets/rsc-labs_bg.png');
var logo_firstgroup = require('./assets/firstgroup.png');
var logo_vale = require('./assets/vale_logo.png');
var logo_faurecia = require('./assets/faurecialogorvb.png');
var logo_capstone = require('./assets/Logos/capstone.png');
var logo_macmahon = require('./assets/Logos/macmahon.png');
var logo_royhill = require('./assets/Logos/royhill-icon.png');
var logo_hvo = require('./assets/Logos/HVO_logo.jpg');
var logo_pvm = require('./assets/Logos/CapstoneCopper_logo.png');



export default {
    edge3: logo_edge3,
    bis: logo_bis,
    bis_drive: logo_bis,
    // devgroup: logo_bis,
    gotech: logo_gotech,    
    zone_defense: logo_zonedefense,
    nycta: logo_nycta,
    santa_clara: logo_santaclara,
    'm2m-in-motion': logo_m2m,
    'rsc_labs': logo_rsclabs,
    firstgroup: logo_firstgroup,
    vale: logo_vale,
    faurecia_drive: logo_faurecia,
    macmahon: logo_macmahon,
    capstone: logo_capstone,
    royhill: logo_royhill,
    huntervalleyoperations: logo_hvo,
    // pintovalley:logo_pvm,
    // pintovalleymine:logo_pvm,

}
    
  